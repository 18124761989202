import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { CSS_MEDIA } from '../../utils/media';

interface AboutInfoProps {
  title: string;
  left?: boolean;
}

export const AboutInfo: React.FC<AboutInfoProps> = ({ title, left }) => {
  return (
    <WrapperS>
      <ElementS>i</ElementS>
      {title && (
        <InfoBoxS isLeft={left}>
          <TextS dangerouslySetInnerHTML={{ __html: title }}></TextS>
        </InfoBoxS>
      )}
    </WrapperS>
  );
};

const TextS = styled.p`
  font-size: 14px;
  margin: 0;
  ${CSS_MEDIA.maxLg} {
    font-size: 12px;
  }
`;
const ElementS = styled.span`
  font-size: 12px;
  width: 16px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(151, 151, 151, 0.7);
  color: white;
`;
const InfoBoxS = styled.div<{ isLeft?: boolean }>`
  min-width: ${({ isLeft }) => (isLeft ? '200px' : '431px')};
  padding: 12px 16px;
  display: none;
  z-index: 99999;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  bottom: 29px;
  right: ${({ isLeft }) => (isLeft ? '100px' : '50%')};
  transform: translateX(50%);
  color: #3c3c3c;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.24);

  &::after {
    content: ' ';
    position: absolute;
    right: ${({ isLeft }) => (isLeft ? '8%' : '50%')};
    transform: translateX(50%);
    bottom: -10px;
    border-top: 10px solid white;
    ${({ isLeft }) => (isLeft ? '' : 'border-right: 15px solid transparent;')};
    border-left: 15px solid transparent;
    border-bottom: none;
    ${CSS_MEDIA.maxLg} {
      transform: none;
      right: 0;
    }
  }
  ${CSS_MEDIA.maxLg} {
    min-width: 230px;
    transform: none;
    border-radius: 10px 10px 0 10px;
    right: 50%;
    bottom: 0;
  }
`;

const WrapperS = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  cursor: help;
  margin-left: 8px;
  align-self: center;
  display: flex;
  &:hover {
    ${InfoBoxS} {
      display: block;
    }
    ${ElementS} {
      background: ${COLORS.accent};
    }
  }
`;
