import { SelectItemType } from '../../ui/selects/DefaultSelect';
import { ALL_ASS, ALL_GROUPS, ALL_PARTICIPANTS } from '../../../utils/consts';

export const getIds = (
  selectItems: SelectItemType[],
  isAss?: boolean
): (string | number)[] => {
  const ids: string[] = [];
  selectItems.forEach((itm) => {
    if (isAss && itm.value !== ALL_ASS.value) ids.push(itm.value);

    if (
      !isAss &&
      itm.value !== ALL_PARTICIPANTS.value &&
      itm.value !== ALL_GROUPS.value
    )
      ids.push(itm.value);
  });
  return ids;
};

export const isActiveAll = (selectItems: SelectItemType[]): boolean => {
  const allIt = selectItems.find((it) => it.value === 'all');

  return !!allIt;
};
