import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { PlusIcon } from '../svgs';
interface AddMonitorCardProps {
  onClick: () => void;
}
export const AddMonitorCardButton: React.FC<AddMonitorCardProps> = ({
  onClick,
}) => {
  return (
    <WrapperS onClick={onClick}>
      <IconInnerWrapperS>
        <PlusIcon />
      </IconInnerWrapperS>
    </WrapperS>
  );
};
const WrapperS = styled.div`
  width: 174px;
  height: 174px;
  margin: 0 24px 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.secondary};
  border-radius: 12px;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    border: 1px solid ${COLORS.accentHover};
    div {
      background-color: ${COLORS.accentHover};
    }
  }
  &:active {
    border: 1px solid ${COLORS.accentActive};
    div {
      background-color: ${COLORS.accentActive};
    }
  }
`;
const IconInnerWrapperS = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${COLORS.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  svg {
    width: 16px;
    height: 16px;
    g {
      fill: white;
    }
  }
`;
