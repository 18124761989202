import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { UnarchiveAssessment } from '../../store/assessments/actions';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { TInnerCat } from '../Assessments/CatList';
import { CloseIcon } from '../svgs';
import { Alert, DefaultSelect, Loader } from '../ui';
import { ButtonLinkS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
interface UnarchiveAssessmentMoadlProps {
  closeModal: () => void;
  id: string;
  teamId: string;
}

export const UnarchiveAssessmentModal: React.FC<UnarchiveAssessmentMoadlProps> =
  ({ closeModal, id, teamId }) => {
    const refInnWrapper = React.useRef<HTMLDivElement>(null);
    const [errors, setErrors] = React.useState('');
    const [cats, setCats] = React.useState<TInnerCat[]>([]);
    const [loading, setLoading] = React.useState(true);
    const { Assessments } = useSelector((store: AppStore) => store);
    const dispatch = useDispatch();
    React.useEffect(() => {
      (async () => {
        try {
          const data = await callApi({
            path: API_ROUTE_PATH.categories.gList + '/' + teamId,
            method: 'get',
          });

          setCats(data);
        } catch (error) {
          setErrors(`${error || 'Some Error'}`);
        } finally {
          setLoading(false);
        }
      })();
    }, [teamId]);
    const unarchiveHandler = (catId: string) => {
      const callBack = (success: boolean) => {
        if (!success) return null;
        const newArchived = Assessments.archivedData.filter(
          (itm) => itm.id !== id
        );
        dispatch(UnarchiveAssessment.success(newArchived));
        closeModal();
      };
      dispatch(
        UnarchiveAssessment.request({
          assId: `${id}`,
          catId,
          callBack,
        })
      );
    };
    if (loading) {
      return <Loader isGreen />;
    }
    return (
      <>
        <Styles.ModalOuterWrapperS>
          <Styles.ModalInnerWrapperS
            style={{ maxWidth: '500px', overflow: 'visible' }}
            ref={refInnWrapper}
            className={AnimatedClasses.zoomIn}
          >
            <BtnCloseS
              style={{ zIndex: 1 }}
              className="closeModalBtn"
              onClick={closeModal}
            >
              <CloseIcon />
            </BtnCloseS>
            <Styles.ModalHeaderS>
              <Styles.ModalTileS>
                Please, choose the category to unzip this assessment into “My
                Library”
              </Styles.ModalTileS>
            </Styles.ModalHeaderS>
            <Styles.ModalBodyS style={{ overflow: 'visible' }}>
              {cats && cats[0] ? (
                <div
                  style={{
                    maxWidth: '300px',
                  }}
                >
                  <DefaultSelect
                    data={cats.map((c) => ({
                      title: c.name,
                      value: c.id,
                      id: c.id,
                    }))}
                    selected={null}
                    onChange={(itm) => unarchiveHandler(`${itm.id}`)}
                    placeHolder="Choose the category"
                  />
                </div>
              ) : (
                <div style={{ padding: '20px' }}>
                  <Alert
                    type="warning"
                    text="Please create a category before duplicate assessment"
                  />
                </div>
              )}
              {errors && <Alert text={errors} />}
            </Styles.ModalBodyS>
          </Styles.ModalInnerWrapperS>
        </Styles.ModalOuterWrapperS>
      </>
    );
  };
const BtnCloseS = styled(ButtonLinkS)`
  position: absolute;
  opacity: 0.5;
  right: 20px;
  top: 20px;
`;
