import React from 'react';
import styled from 'styled-components';
import { CloseIcon, SearchIcon } from '../../svgs';

interface InputSearchProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  results: [];
  placeholder?: string;
  clearHandler?: () => void;
  alignRight?: boolean;
}

export const InputSearch: React.FC<InputSearchProps> = ({
  onChange,
  value,
  placeholder,
  clearHandler,
  alignRight,
}) => {
  return (
    <WrapperS className="input-search-wrapper" alignRight={alignRight}>
      <input
        tabIndex={1}
        type="text"
        onChange={onChange}
        value={value}
        placeholder={placeholder ? placeholder : 'Search'}
      />
      <IconWrapperS alignRight={alignRight}>
        <SearchIcon />
      </IconWrapperS>
      {clearHandler && value && (
        <CleanIconWrapper onClick={clearHandler}>
          <CloseIcon />
        </CleanIconWrapper>
      )}
    </WrapperS>
  );
};

const WrapperS = styled.div<{ alignRight: boolean }>`
  position: relative;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  input {
    padding: ${({ alignRight }) => (alignRight ? '0 30px 0 0' : '0 0 0 30px')};
    min-width: 220px;
  }
`;

const IconWrapperS = styled.div<{ alignRight: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ alignRight }) => (alignRight ? 'right: 10px;' : 'left: 10px;')};
  height: 16px;
  width: 16px;
`;

const CleanIconWrapper = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  height: 16px;
  width: 16px;
  border: 0;
  padding: 0;
  opacity: 0.3;
  cursor: pointer;
  line-height: 16px;
  &:hover {
    opacity: 0.6;
    background: transparent;
  }
  &:active {
    opacity: 0.8;
  }
`;
