import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TParticipant, TParticipantsData } from './types';

function* getPaginationParticipants(
    action: ReturnType<typeof Actions.GetPaginationParticipants.request>
): Generator {
  const { workSpaceId, sortBy, sortType, searchString, limit, offset, callBack } = action.payload as Actions.TGet_ALL_R;

  let success = true;
  const dataReq  = {
    sortBy: sortBy,
    sortType: sortType,
    searchString: searchString,
    isAll: false,
    limit: limit,
    offset: offset,
  }
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.participants.gAllByWorkspace + '/' + workSpaceId,
      data: dataReq,
    })) as {
      participants: TParticipant[]
      has_more:boolean
      next_limit: number
      next_offset: number
      is_first_load: boolean
    };

    yield put(Actions.GetPaginationParticipants.success({
      participants: resp.participants,
      has_more: resp.has_more,
      next_limit: resp.next_limit,
      next_offset: resp.next_offset,
      is_first_load: resp.is_first_load
    } || {
      participants: [],
      has_more: false,
      next_limit: resp.next_limit,
      next_offset: resp.next_offset,
      is_first_load: resp.is_first_load
    }));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetPaginationParticipants.error(e));
  } finally {
    callBack && callBack(success);
  }
}

function* getParticipants(
  action: ReturnType<typeof Actions.GetAllParticipants.request>
): Generator {
  const { workSpaceId, sortBy, sortType, searchString,  callBack } = action.payload as Actions.TGet_ALL_R;

  let success = true;
  const dataReq  = {
    sortBy: sortBy,
    sortType: sortType,
    searchString: searchString,
    isAll: true,
    offset: 0,
  }

  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.participants.gAllByWorkspace + '/' + workSpaceId,
      data: dataReq,
    })) as {
      participants: TParticipant[]
      has_more:boolean
    };
    yield put(Actions.GetAllParticipants.success({participants: resp.participants, has_more: false} || {participants: [], has_more: false}));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllParticipants.error(e));
  } finally {
    callBack && callBack(success);
  }
}

function* createParticipants(
  action: ReturnType<typeof Actions.CreateParticipants.request>
): Generator {
  const { newParticipants, callBack } = action.payload as Actions.TCreate_R;
  if (newParticipants && newParticipants[0] === undefined)
    throw new Error('No Participants');
  // let success = true;
  try {
    const data = (yield call(callApi, {
      method: 'post',
      path:
        API_ROUTE_PATH.participants.cMultiply +
        '/' +
        newParticipants[0].workspace_id,
      data: newParticipants,
    })) as TParticipantsData;
    yield put(Actions.CreateParticipants.success(data));
    callBack && callBack(!data.fail[0], data);
  } catch (e) {
    yield put(Actions.CreateParticipants.error(e));
  } finally {
  }
}

function* deleteParticipant(
  action: ReturnType<typeof Actions.DeleteParticipant.request>
): Generator {
  const { id, callBack } = action.payload as Actions.TDelete_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.participants.path + '/' + id,
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));

    yield put(Actions.DeleteParticipant.error(e));
  } finally {
    callBack && callBack(success);
  }
}

function* bulkDeleteParticipant(
    action: ReturnType<typeof Actions.BulkDeleteParticipant.request>
): Generator {
  const { id, callBack } = action.payload as Actions.TBulkDelete_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.participants.path + '/bulk-delete',
      data: id,
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));

    yield put(Actions.BulkDeleteParticipant.error(e));
  } finally {
    callBack && callBack(success);
  }
}

function* moveToGroupParticipant(
  action: ReturnType<typeof Actions.MoveToGroupParticipant.request>
): Generator {
  const { data, callBack } = action.payload as Actions.TMoveToGroup_R;

  const { relation, group_id, participant_id } = data;
  let success = true;
  try {
    yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.participants.genM2M(participant_id, group_id),
      data: {
        relation,
      },
    });
  } catch (e) {
    success = false;
    yield put(Actions.MoveToGroupParticipant.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* editParticipant(
  action: ReturnType<typeof Actions.EditParticipant.request>
): Generator {
  const { data, callBack, participantId } =
    action.payload as Actions.TEdit_Participant_R;
  // const { relation, group_id, participant_id } = data;
  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.participants.path + '/' + participantId,
      data,
    })) as TParticipant;
  } catch (e) {
    success = false;
    yield put(Actions.EditParticipant.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_PAGE_R, getPaginationParticipants);
  yield takeEvery(ActionTypes.GET_ALL_R, getParticipants);
  yield takeEvery(ActionTypes.CREATE_R, createParticipants);
  yield takeEvery(ActionTypes.DELETE_R, deleteParticipant);
  yield takeEvery(ActionTypes.BULK_DELETE_R, bulkDeleteParticipant);
  yield takeEvery(ActionTypes.MOVE_R, moveToGroupParticipant);
  yield takeEvery(ActionTypes.EDIT_R, editParticipant);
}

export default function* participantsSaga() {
  yield all([fork(watchFetchRequest)]);
}
