import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import { useUploadImage } from '../../../hooks/useUplodImage';
import { AppStore } from '../../../store/applicationState';
import { UpdateUser } from '../../../store/user/actions';
import { TEXT } from '../../../utils/textConst';
const NAME = 'name';
const LAST_NAME = 'lastName';
export const useMyAccountInfo = () => {
  const dispatch = useDispatch();
  const {
    image,
    onChangeUploadHandler,
    removeImageHandler,
    imageChanged,
    imageError,
    refInputUpload,
    setImage,
  } = useUploadImage();
  const [warning, setWarning] = React.useState('');
  const { User } = useSelector((store: AppStore) => store);
  const consultant = User.data;
  const [errors, setErrors] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const { form, onChangeHandler, updateForm } = useForm({
    [NAME]: {
      value: consultant!.first_name,
    },
    [LAST_NAME]: {
      value: consultant!.last_name,
    },
  });

  React.useEffect(() => {
    setErrors(`${imageError}`);
  }, [imageError]);
  React.useEffect(() => {
    if (User.data?.profile_picture_url)
      setImage({
        src: User.data?.profile_picture_url,
        file: null,
      });
    // eslint-disable-next-line
  }, [User.data.profile_picture_url]);
  const getVal = (name: string) => {
    return form[name].value;
  };
  const getError = (name: string) => {
    return form[name].errorText;
  };
  const saveHandler = () => {
    const first_name = getVal(NAME);
    const last_name = getVal(LAST_NAME);
    let hasErrors = false;
    if (!first_name) {
      hasErrors = true;
      updateForm(NAME, { value: '', errorText: TEXT.isRequired });
    }
    if (!last_name) {
      hasErrors = true;
      updateForm(LAST_NAME, { value: '', errorText: TEXT.isRequired });
    }

    if (hasErrors) return null;

    setLoading(true);

    editUserHandler(first_name, last_name, false, image.file);
    setLoading(false);
  };

  const clickUploadHandler = () => {
    if (!refInputUpload.current) return null;
    dispatch(UpdateUser.error(''));
    refInputUpload.current!.click();
  };
  const updateImageHandler = (file: File | null, src: string) => {
    setImage({ src, file });
    dispatch(
      UpdateUser.request({
        data: {
          first_name: getVal(NAME),
          last_name: getVal(LAST_NAME),
          is_profile_picture_changed: true,
          file,
        },
        src,
        id: User.data!.id,
      })
    );
  };
  const editUserHandler = (
    first_name: string,
    last_name: string,
    is_profile_picture_changed: boolean,
    file: File | null
  ) => {
    setWarning('');
    if (!consultant) return setWarning('No current member');

    consultant.first_name = first_name;
    consultant.last_name = last_name;
    consultant.profile_picture_url = image.src;

    const callBack = (success: boolean) => {
      if (success) {
        dispatch(UpdateUser.success(consultant));
      }
    };
    return dispatch(
      UpdateUser.request({
        data: {
          first_name,
          last_name,
          is_profile_picture_changed,
          file,
        },
        src: image.src,
        id: User.data!.id,
        callBack,
      })
    );
  };
  return {
    NAME,
    LAST_NAME,
    warning,
    imageChanged,
    refInputUpload,
    updateImageHandler,
    onChangeUploadHandler,
    editUserHandler,
    onChangeHandler,
    clickUploadHandler,
    removeImageHandler,
    getError,
    getVal,
    saveHandler,
    loading,
    image,
    setImage,
    errors,
  };
};
