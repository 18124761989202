import React from 'react';
import styled from 'styled-components';
import { useForm } from '../../hooks/useForm';
import { TUser } from '../../store/user/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { delay } from '../../utils/helpers';
import { TEXT } from '../../utils/textConst';
import { Alert, ButtonSave, Input } from '../ui';

interface PasswordProps {
  user: TUser;
}

const CUR_PASS = 'password';
const NEW_PASS = 'new password';
const REPEAT_PASS = 'repeat password';

export const Password: React.FC<PasswordProps> = ({ user }) => {
  const [loading, setLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const { form, onChangeHandler, updateForm } = useForm({
    [CUR_PASS]: {
      value: '',
    },
    [NEW_PASS]: {
      value: '',
    },
    [REPEAT_PASS]: {
      value: '',
    },
  });

  // const dispatch = useDispatch();

  const getVal = (name: string) => {
    return form[name].value;
  };
  const getError = (name: string) => {
    return form[name].errorText;
  };

  const saveHandler = async () => {
    const password = getVal(CUR_PASS);
    const newPassword = getVal(NEW_PASS);
    const repeatPassword = getVal(REPEAT_PASS);
    setErrorText('');
    let hasErrors = false;
    if (!password) {
      hasErrors = true;
      updateForm(CUR_PASS, { value: '', errorText: TEXT.isRequired });
    }
    if (!newPassword) {
      hasErrors = true;
      updateForm(NEW_PASS, { value: '', errorText: TEXT.isRequired });
    }
    if (!repeatPassword) {
      hasErrors = true;
      updateForm(REPEAT_PASS, { value: '', errorText: TEXT.isRequired });
    }

    if (newPassword !== repeatPassword) {
      hasErrors = true;
      updateForm(REPEAT_PASS, {
        value: '',
        errorText: 'New password is incorrect',
      });
    }

    if (hasErrors) return null;

    if (newPassword.length < 5) {
      hasErrors = true;
      return updateForm(REPEAT_PASS, {
        value: '',
        errorText: 'New password should be longer than 5 characters',
      });
    }

    setLoading(true);

    try {
      await callApi({
        path: API_ROUTE_PATH.users.changePass,
        //subPath: '/auth',
        method: 'post',
        data: {
          id: user.id,
          old_password: password,
          new_password: newPassword,
        },
      });
      setLoading(false);
      updateForm(REPEAT_PASS, {
        value: '',
      });
      updateForm(CUR_PASS, {
        value: '',
      });
      updateForm(NEW_PASS, {
        value: '',
      });
      setSuccess(true);
      await delay(5000);
      setSuccess(false);
    } catch (e) {
      setErrorText(`${e}`);
    } finally {
      setLoading(false);
    }

    return console.log(password, newPassword);
  };

  return (
    <WrapperS>
      <Input
        label="Current Password:"
        name={CUR_PASS}
        value={getVal(CUR_PASS)}
        errorText={getError(CUR_PASS)}
        onChange={onChangeHandler}
        type="password"
      />
      <Input
        label="New Password:"
        name={NEW_PASS}
        value={getVal(NEW_PASS)}
        errorText={getError(NEW_PASS)}
        onChange={onChangeHandler}
        type="password"
      />
      <Input
        label="Repeat new Password:"
        name={REPEAT_PASS}
        value={getVal(REPEAT_PASS)}
        errorText={getError(REPEAT_PASS)}
        onChange={onChangeHandler}
        type="password"
      />
      <ButtonSave onClick={saveHandler} loading={loading} />
      {errorText ? <Alert text={errorText} /> : null}
      {success ? (
        <Alert text="Password was changed successfully" type="success" />
      ) : null}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;
