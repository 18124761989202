import React from 'react';
import { Alert } from '../components/ui';
import { MainWrapperS } from '../globalStyle';

interface DataStoryViewIsAuthProps {}

export const DataStoryViewIsAuth: React.FC<DataStoryViewIsAuthProps> = () => {
  return (
    <MainWrapperS>
      <Alert
        type="warning"
        text="You should Log out to be able to see the Data Story view page"
      />
    </MainWrapperS>
  );
};
