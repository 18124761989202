import React from 'react';

import { getCutText } from '../../utils/helpers';
import {
    ButtonArrow,
} from '../ui';
import * as Styles from './styles/GroupSectionStyles';
import {TGroup} from "../../store/groups/types";
import {DeleteGroup} from "../../store/groups/actions";
import {GroupSection} from "./GroupSection";
import {useDispatch, useSelector} from "react-redux";
import {AppStore} from "../../store/applicationState";
import {COLORS} from "../../utils/colors";
import {TEditGroupInfo} from "./types/groupTypes";
import {useGroupOriginSourceSection} from "./hooks/useGroupOriginSourceSection";
import styled from "styled-components";

interface OriginSourceSectionProps {
    title: string;
    originSourceID: string;
    groupsD: TGroup[],
    openNewGroupHandler: (data?: TEditGroupInfo) => void;
    positionMap: Map<string, number>;
    removeGroupFromParticipants: (success: boolean, id: string) => void;
    filterGroups: (id: string, position: number, originSourceId: string) => TGroup[];
}

export const OriginSourceSection: React.FC<OriginSourceSectionProps> = ({
      title,
    originSourceID,
      groupsD,
      openNewGroupHandler,
    positionMap,
    removeGroupFromParticipants,
    filterGroups,
  }) => {

    const { Participants } = useSelector((store: AppStore) => store);
    const dispatch = useDispatch();

    const {
        isVisible,
        setIsVisible,
    } = useGroupOriginSourceSection();

    // const removeGroupFromParticipants = (success: boolean, id: string) => {
    //     if (!success) return console.error('success', false);
    //     if (!Participants.data) return console.error('Could not find groups');
    //     const newParticipants = deepClone(Participants.data) as TParticipant[];
    //
    //     newParticipants.forEach((participant) => {
    //         if (!participant.groups[0]) return null;
    //         participant.groups = participant.groups.filter((pg) => pg.id !== id);
    //     });
    //
    //     return dispatch(EditParticipant.success(newParticipants));
    // };

    const cutTitle = getCutText(title, 20);

    const groups = React.useMemo(() => {
        if (groupsD && Array.isArray(groupsD)) {
            groupsD.sort((g1, g2) => g1.name.localeCompare(g2.name));
        }

        if (!isVisible) return <></>;
        return (
            groupsD &&
            groupsD!.map(
                ({ name, description, id, participants, check_for_deletion, origin }, i) => {
                    return (
                        <GroupSection
                            id={id}
                            title={name}
                            groupPosition={positionMap.get(id)}
                            groupIsLoading={Participants.loading}
                            key={id}
                            data={participants}
                            isCustom={false}
                            isNotDeletable={check_for_deletion}
                            groupIsDefault={origin === 'default'}
                            removeGroup={() =>
                                dispatch(
                                    DeleteGroup.request(
                                        { id, newGroups: filterGroups(id, i, originSourceID) },
                                        (s) => removeGroupFromParticipants(s, id)
                                    )
                                )
                            }
                            editGroup={() => {
                                openNewGroupHandler({
                                    title: name,
                                    description,
                                    id,
                                    position: i,
                                    participants,
                                })
                            }}
                        />
                    );
                }
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        groupsD,
        isVisible,
        dispatch,
        openNewGroupHandler,
        Participants,
    ]);

    return (
        <Styles.WrapperS>
            <Styles.HeaderS>
                <TitleS>{cutTitle}</TitleS>
                <ButtonArrow
                    onClick={() => setIsVisible(!isVisible)}
                    active={isVisible}
                />
            </Styles.HeaderS>
            <Styles.BodyS>
                {groups}
            </Styles.BodyS>
        </Styles.WrapperS>
    );
};

export const TitleS = styled.h3`
  flex: 1;
  color: ${COLORS.accent};
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;