import { Reducer } from 'redux';
import ActionTypes, { TDataStoryState } from './types';

export const initialState: TDataStoryState = {
  loading: true,
  data: null,
  errors: null,
  link: null,
  linkErrors: null,
  linkLoading: false,
};

const reducer: Reducer<TDataStoryState> = (
  state = initialState,
  action
): TDataStoryState => {
  switch (action.type) {
    case ActionTypes.STORIES_UPDATE:
      return { ...state, data: action.payload };
    case ActionTypes.CREATE_LINK_R:
      return { ...state, linkErrors: null };
    case ActionTypes.CREATE_SNAPSHOT_R:
    case ActionTypes.CREATE_FILE_SNAPSHOT_R:
    case ActionTypes.GET_STORIES_R:
    case ActionTypes.GET_LINK_R:
      return { ...state, linkErrors: null };
    case ActionTypes.GET_STORIES_BY_LINK_R:
    case ActionTypes.EDIT_STORIES_R:
      return { ...state, loading: true };
    case ActionTypes.EDIT_SNAPSHOT_R:
      return { ...state };
    case ActionTypes.DEACTIVATE_LINK_R:
      return { ...state, linkErrors: null };
    case ActionTypes.DELETE_SNAPSHOT_R:
      return { ...state };
    case ActionTypes.CREATE_LINK_S:
      return {
        ...state,
        loading: false,
        link: action.payload,
        data: { ...state.data, link: action.payload.story_link },
      };
    case ActionTypes.CREATE_FILE_SNAPSHOT_S:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          snapshots: [...state.data.snapshots, action.payload],
        },
      };
    case ActionTypes.CREATE_SNAPSHOT_S:
    case ActionTypes.GET_STORIES_S:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.GET_LINK_S:
      return { ...state, loading: false, link: action.payload };
    case ActionTypes.GET_STORIES_BY_LINK_S:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.EDIT_STORIES_S:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          link: state.data.link,
          link_expiration: state.data.link_expiration,
        },
      };
    case ActionTypes.EDIT_SNAPSHOT_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.DEACTIVATE_LINK_S:
      return {
        ...state,
        loading: false,
        link: null,
        data: { ...state.data, link: null },
      };
    case ActionTypes.DELETE_SNAPSHOT_S:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.CREATE_LINK_E:
      return { ...state, loading: false, linkErrors: action.payload };
    case ActionTypes.CREATE_SNAPSHOT_E:
    case ActionTypes.CREATE_FILE_SNAPSHOT_E:
    case ActionTypes.GET_STORIES_E:
    case ActionTypes.GET_LINK_E:
      return { ...state, loading: false, linkErrors: action.payload };
    case ActionTypes.GET_STORIES_BY_LINK_E:
      return { ...state, loading: false, errors: action.payload };
    case ActionTypes.EDIT_STORIES_E:
    case ActionTypes.EDIT_SNAPSHOT_E:
    case ActionTypes.DEACTIVATE_LINK_E:
    case ActionTypes.DELETE_SNAPSHOT_E:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export { reducer as DataStoryReducer };
