import { TDelayedMessagesGroups } from '../store/packages/types';

export const idsToDelayedGroups = (groupIds: string[]) => {
  const delayedGroups: TDelayedMessagesGroups[] = [];
  groupIds.forEach((groupId) => {
    delayedGroups.push({
      id: '',
      group: {
        id: groupId,
        name: '',
        created_at: '',
      },
    });
  });
  return delayedGroups;
};
export const delayedGroupsToSelectedIds = (
  delayedGroups: TDelayedMessagesGroups[]
) => {
  const groupIds: string[] = [];
  delayedGroups.forEach((delayedGroup) => {
    groupIds.push(delayedGroup.group.id);
  });
  return groupIds;
};
