import React from 'react';
import { EditIcon } from '../../svgs/EditIcon';
import { WrapperItemSelectS } from './styles';

interface EditSelectItemProps {
  title?: string;
}

export const EditSelectItem: React.FC<EditSelectItemProps> = ({ title = 'Edit' }) => {
  return (
    <WrapperItemSelectS>
      <EditIcon />
      {title}
    </WrapperItemSelectS>
  );
};
