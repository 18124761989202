import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ButtonLinkS } from '../ui/buttons/styles';
import { ParticipationRate } from './ParticipationRate';
import {
  EChartType,
  ETypeGraph,
  TAnalyticsRow,
  TChartFilter,
  TDatum,
  TGraphOptions, TLineScatter,
  TMetaData,
  TScatterData,
  TScatterGraphOptions,
  TStackedData,
} from '../../store/analytics/types';
import {AddDataStoryIcon, CheckIcon, DeleteIcon, DownloadIcon, InfoIcon} from '../svgs';
import { ButtonS } from '../ui/buttons/ButtonArrow';
import { deepClone } from '../../utils/deepClone';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { DeleteChartCurrent } from '../../store/analytics/actions';
import { callApi } from '../../utils/callApi';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { GetAllAssessmentsByTeam } from '../../store/assessments/actions';
import { DeleteSnapshot } from '../../store/dataStory/actions';
import { ALL_QUESTIONS } from '../../utils/consts';
import {TSnapshot} from "../../store/dataStory/types";
import html2canvas from "html2canvas";
import axios from "axios";
import {RenderImg} from "../../pages/RenderImg";

interface ChartHeaderProps {
  isStored: boolean;
  isPreview: boolean;
  title: string;
  id: string;
  description: string;
  version: string;
  isInFav?: boolean;
  graphType: ETypeGraph;
  snapshotId: string | null;
  isCycles: boolean;
  leftSideComponent?: ReactElement;
  chartType: 'default' | 'custom';
  chartView: EChartType;
  chartData: TDatum[] | TStackedData[] | TScatterData[] | TLineScatter[];
  chartSaved: boolean;
  chartPosition: number;
  selectedСhartQuestions:
    | {
        x_axis_questions: string[];
        y_axis_questions: string[];
      }
    | string[];
  allChartQuestions: TGraphOptions[] | TScatterGraphOptions;
  dataStoryChartSettings: TChartFilter | null;
  activeRuns: string[];
  chartMetaData: TMetaData;
  isParticipationRateActive?: boolean;
  reference: React.MutableRefObject<HTMLDivElement>;
}

export const ChartHeader: React.FC<ChartHeaderProps> = ({
  title,
  id,
  leftSideComponent,
  description,
  version,
  chartType,
  graphType,
  chartData,
  chartSaved,
  chartPosition,
  isStored,
  isPreview,
  dataStoryChartSettings,
  chartView,
  snapshotId,
  activeRuns,
  chartMetaData,
  isParticipationRateActive = true,
  reference,
  selectedСhartQuestions,
  allChartQuestions,
}) => {
  const { Analytics, Workspaces, Assessments, DataStory, Groups } = useSelector(
    (store: AppStore) => store
  );
  const [loadImg, setLoadImg] = React.useState(false);
  const [current, setCurrent] = React.useState<TSnapshot>(null);
  const [addedToStory, setAddedToStory] = React.useState(false);
  const dispatch = useDispatch();
  const deleteChartHandler = () => {
    const callBack = (success: boolean) => {
      if (!success) return null;
      if (!Analytics.data) {
        console.warn('Block does not exist');
        return null;
      }
      const newRows = deepClone(Analytics.data) as TAnalyticsRow[];

      newRows.forEach((itm) => {
        if (itm.charts.find((curChart) => curChart.id === id)) {
          const index = itm.charts.findIndex((curChart) => curChart.id === id);
          itm.charts.splice(index, 1);
        }
      });

      dispatch(DeleteChartCurrent.success(newRows));
      if (Assessments.existingData) {
        const curExistingData = deepClone(
          Assessments.existingData
        ) as typeof Assessments.existingData;
        const names: string[] = [];
        Analytics.data.forEach((itm) =>
          itm.charts.forEach((chart) => {
            names.push(chart.name);
          })
        );

        if (names.filter((itm) => itm === title).length === 1) {
          const newExistingData = curExistingData.filter(
            (itm) => itm.name !== title
          );
          dispatch(GetAllAssessmentsByTeam.success(newExistingData));
        }
      }
    };

    dispatch(DeleteChartCurrent.request({ id, callBack }));
  };


  const downloadSimpleImgHandler = (
      ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    if (current) {
      if (current.type === 'file') {
        setTimeout(
            () => downloadFile(current),
            current.data &&
            (current.data.type === ETypeGraph.type1 ||
                current.data.type === ETypeGraph.type4 ||
                current.data.type === ETypeGraph.type3)
                ? 1000
                : 0
        );
      } else {

        setTimeout(
            () => convertToCanvasSimple(ref, current),
            current.data &&
            (current.data.type === ETypeGraph.type1 ||
                current.data.type === ETypeGraph.type4 ||
                current.data.type === ETypeGraph.type3)
                ? 1000
                : 0
        );
      }
    }
  };

  const convertToCanvasSimple = async (
      ref: React.MutableRefObject<HTMLDivElement>,
      snapshot: TSnapshot
  ) => {
    html2canvas(ref.current, {
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight
    }).then((canvas) => {
      const dpi = 300; // Желаемое значение DPI
      const desiredWidth = 1280;
      const desiredHeight = 720;

      const newCanvasWidth = (desiredWidth / 72) * dpi;
      const newCanvasHeight = (desiredHeight / 72) * dpi;

      const newCanvas = document.createElement('canvas');
      newCanvas.width = newCanvasWidth;
      newCanvas.height = newCanvasHeight;
      const ctx = newCanvas.getContext('2d');
      ctx.fillStyle = '#ffffff'; // Используйте нужный вам белый цвет
      ctx.fillRect(0, 0, newCanvasWidth, newCanvasHeight);
      const screenshotWidth = canvas.width;
      const screenshotHeight = canvas.height;

      const scale = Math.min(
          newCanvasWidth / screenshotWidth,
          newCanvasHeight / screenshotHeight
      );
      const scaledWidth = screenshotWidth * scale;
      const scaledHeight = screenshotHeight * scale;

      const x = (newCanvasWidth - scaledWidth) / 2;
      const y = (newCanvasHeight - scaledHeight) / 2;

      ctx.drawImage(
          canvas,
          0,
          0,
          screenshotWidth,
          screenshotHeight,
          x,
          y,
          scaledWidth,
          scaledHeight
      );

      const dataUrl = newCanvas.toDataURL();
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${snapshot.position}_${snapshot.name}.jpg`;
      link.click();
      setLoadImg(false)
    });
  };

  const downloadFile = async (snapshot: TSnapshot) => {
    try {
      const resp = await axios.request({
        method: 'get',
        url: snapshot.url.url,
        responseType: 'blob'
      });
      const data = new Blob([resp.data], { type: 'image/jpg' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = `${snapshot.position}_${snapshot.name}.jpg`;
      link.click();
      setLoadImg(false)
    } catch (e) {
      console.log(e);
    }
  };

  const deleteFromDataStoryHandler = (snapshotId: string) => {
    const callBack = (success: boolean) => {
      if (!success) return null;
      const currentDataStory = deepClone(
        DataStory.data
      ) as typeof DataStory.data;
      const index = currentDataStory.snapshots.findIndex(
        (itm) => itm.id === snapshotId
      );
      currentDataStory.snapshots.splice(index, 1);
      dispatch(DeleteSnapshot.success(currentDataStory));
    };
    dispatch(DeleteSnapshot.request({ snapshotId: snapshotId, callBack }));
  };
  
  const getGroupsNames = (runs: string[], activeIds: string[]) => {
    const someArray: string[] = [];
    if (!activeIds[0]) {
      runs.forEach((itm) => {
        const itmArray = itm.split('/');
        if (!someArray.includes(itmArray[3])) {
          someArray.push(itmArray[3]);
        }
      });
    } else if (!runs[0]) {
      activeIds.forEach((itm) => {
        someArray.push(Groups.data.find((group) => itm === group.id).name);
      });
    } else {
      runs.forEach((itm) => {
        const itmArray = itm.split('/');
        if (!someArray.includes(itmArray[3])) {
          someArray.push(itmArray[3]);
        }
      });
      return someArray;
    }
    if (
      someArray.length === Groups.data.length ||
      (activeIds[0] && activeIds.length === Groups.data.length)
    )
      return ['All Groups'];
    return someArray;
  };
  const getSelectedQuestions = (
    selectedQuestionIds:
      | {
          x_axis_questions: string[];
          y_axis_questions: string[];
        }
      | string[],
    allQuestions: TGraphOptions[] | TScatterGraphOptions
  ) => {
    const questionsBodys: string[] = [];
    const questionsBodysXY: {
      x_axis_questions: string[];
      y_axis_questions: string[];
    } = { x_axis_questions: [], y_axis_questions: [] };
    if (selectedQuestionIds === undefined) return null;
    if (Array.isArray(selectedQuestionIds)) {
      selectedQuestionIds.forEach((id) => {
        questionsBodys.push(
          (allQuestions as TGraphOptions[]).find(
            (question) => question.id === id
          ).body
        );
      });
      return questionsBodys;
    } else {
      if (
        selectedQuestionIds.x_axis_questions.length ===
        (allQuestions as TScatterGraphOptions).x_axis_questions.length
      ) {
        // questionsBodysXY.x_axis_questions.push(ALL_QUESTIONS.title);
      } else
        selectedQuestionIds.x_axis_questions.forEach((id) => {
          questionsBodysXY.x_axis_questions.push(
            (allQuestions as TScatterGraphOptions).x_axis_questions.find(
              (question) => question.id === id
            ).body
          );
        });
      if (
        selectedQuestionIds.y_axis_questions.length ===
        (allQuestions as TScatterGraphOptions).y_axis_questions.length
      ) {
        // questionsBodysXY.y_axis_questions.push(ALL_QUESTIONS.title);
      } else
        selectedQuestionIds.y_axis_questions.forEach((id) => {
          questionsBodysXY.y_axis_questions.push(
            (allQuestions as TScatterGraphOptions).y_axis_questions.find(
              (question) => question.id === id
            ).body
          );
        });
      return questionsBodysXY;
    }
  };
  const addToDataStory = async () => {
    try {
      await callApi({
        method: 'post',
        path: API_ROUTE_PATH.snapshot.byWorkspace + '/' + Workspaces.current.id,
        data: {
          snapshot: {
            id,
            name: title + ' ' + genVer(version),
            type: graphType,
            view: chartView,
            assessments_ids: Analytics.filters.params.assessments_ids,
            position: chartPosition,
            is_saved: chartSaved,
            origin: chartType,
            meta_data: chartMetaData,
            settings: {
              ...Analytics.filters.params,
              groupsNames: getGroupsNames(
                activeRuns,
                Analytics.filters.params.groups_ids
              ),
              activeRuns: activeRuns,
              questions_ids: getSelectedQuestions(
                selectedСhartQuestions,
                allChartQuestions
              ),
            },
            data: chartData,
          },
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };
  const genDesc = (description: string) => {
    const newDesc = description.split('\n');
    newDesc.forEach((d, i) => {
      if (!d) newDesc.splice(i, 1);
    });
    const descJoin = newDesc.join('<br/> <br/>');
    return descJoin;
  };
  const genVer = (version: string) => {
    const newVer = version.split('\n');
    newVer.forEach((d, i) => {
      if (!d) newVer.splice(i, 1);
    });
    const verJoin = newVer.join(' ');
    return verJoin;
  };
  const firstLetterToUpperCase = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  const genSettingsDesc = (settings: TChartFilter) => {
    const stroke: string[] = [];
    const questions: string[] = [];
    const questionsXY: {
      x_axis_questions: string[];
      y_axis_questions: string[];
    } = { x_axis_questions: [], y_axis_questions: [] };
    if (settings.is_all_participants) stroke.push('All Participants');
    if (settings.groupsNames[0] && !settings.is_all_participants)
      settings.groupsNames.forEach((itm) => stroke.push(itm));
    if (settings.period_of_answer_date) {
      if (settings.period_of_answer_date.is_all_time) stroke.push('All Time');
      if (settings.period_of_answer_date.start_date)
        stroke.push(
          `from ${settings.period_of_answer_date.start_date} to ${settings.period_of_answer_date.end_date}`
        );
      if (settings.period_of_answer_date.is_last_year) stroke.push('Last Year');
      if (settings.period_of_answer_date.is_last_quarter)
        stroke.push('Last Quarter');
      if (settings.period_of_answer_date.is_last_month)
        stroke.push('Last Month');
    } else {
      if (settings.is_all_time) stroke.push('All Time');
      if (settings.start_date)
        stroke.push(`from ${settings.start_date} to ${settings.end_date}`);
      if (settings.is_last_year) stroke.push('Last Year');
      if (settings.is_last_quarter) stroke.push('Last Quarter');
      if (settings.is_last_month) stroke.push('Last Month');
    }
    if (settings.activeRuns[0]) {
      settings.activeRuns.forEach((itm) => {
        const itmArray = itm.split('/');
        stroke.push(`${itmArray[0]} (${itmArray[2]})`);
      });
    } else if (chartView === EChartType.cycles) stroke.push('All Cycles');

    if (settings.questions_ids) {
      if (Array.isArray(settings.questions_ids)) {
        settings.questions_ids.forEach((body) => {
          questions.push(body);
        });
      } else {
        if (
          settings.questions_ids.x_axis_questions[0] === ALL_QUESTIONS.title &&
          settings.questions_ids.y_axis_questions[0] === ALL_QUESTIONS.title
        )
          stroke.push(ALL_QUESTIONS.title);

        if (settings.questions_ids.x_axis_questions[0] === ALL_QUESTIONS.title)
          questionsXY.x_axis_questions.push(ALL_QUESTIONS.title);

        if (settings.questions_ids.y_axis_questions[0] === ALL_QUESTIONS.title)
          questionsXY.y_axis_questions.push(ALL_QUESTIONS.title);

        if (
          !!settings.questions_ids.x_axis_questions[0] &&
          settings.questions_ids.x_axis_questions[0] !== ALL_QUESTIONS.title
        )
          settings.questions_ids.x_axis_questions.forEach((body) => {
            questionsXY.x_axis_questions.push(body);
          });
        else questionsXY.x_axis_questions.push(ALL_QUESTIONS.title);

        if (
          !!settings.questions_ids.y_axis_questions[0] &&
          settings.questions_ids.y_axis_questions[0] !== ALL_QUESTIONS.title
        )
          settings.questions_ids.y_axis_questions.forEach((body) => {
            questionsXY.y_axis_questions.push(body);
          });
        else questionsXY.y_axis_questions.push(ALL_QUESTIONS.title);

        if (
          questionsXY.x_axis_questions[0] === ALL_QUESTIONS.title &&
          questionsXY.y_axis_questions[0] === ALL_QUESTIONS.title
        )
          stroke.push(ALL_QUESTIONS.title);
      }
    } else stroke.push(ALL_QUESTIONS.title);

    const getQuestionDesc = () => {
      if (settings.questions_ids) {
        if (Array.isArray(settings.questions_ids))
          return ' | Questions:<br>' + questions?.join('<br>');
        else {
          if (
            questionsXY.x_axis_questions[0] === ALL_QUESTIONS.title &&
            questionsXY.y_axis_questions[0] === ALL_QUESTIONS.title
          )
            return '';
          return (
            '<br>X Questions:<br>' +
            questionsXY.x_axis_questions?.join('<br>') +
            '<br>Y Questions:<br>' +
            questionsXY.y_axis_questions?.join('<br>')
          );
        }
      } else return '';
    };
    return (
      stroke.join(', ') +
      ` | ${chartView && firstLetterToUpperCase(chartView)}` +
      getQuestionDesc()
    );
  };
  return (
    <HeaderSnapshotS ref={reference}>
      <WrapperTitleSelectS>
        <SettingsDescWrapper>
          <h5>
            {title} {genVer(version)}
            {!isStored && (
              <InfoBlock>
                <InfoIcon />
                <InfoDescS
                  className={'hasTopTriangle'}
                  dangerouslySetInnerHTML={{ __html: genDesc(description) }}
                />
              </InfoBlock>
            )}
          </h5>
          {dataStoryChartSettings && (
            <p
              style={{
                overflowWrap: 'break-word',
                padding: 0,
                margin: 0,
              }}
              dangerouslySetInnerHTML={{
                __html: genSettingsDesc(dataStoryChartSettings),
              }}
            ></p>
          )}
        </SettingsDescWrapper>
      </WrapperTitleSelectS>
      {isParticipationRateActive &&
        Analytics.filters.params.is_all_participants &&
        chartData[0] &&
        !isStored && <ParticipationRate chartId={id} />}
      {leftSideComponent && leftSideComponent}
      {!isStored && (
        <DataStoryChartButtonS
          onClick={() => {
            addToDataStory();
            setAddedToStory(true);
            setTimeout(() => {
              setAddedToStory(false);
            }, 2000);
          }}
        >
          <span>
            <AddTooltipWrapper>
              {addedToStory ? (
                <>
                  <CheckIcon />
                  <span>Chart added</span>
                </>
              ) : (
                <span>Add this graph to data story</span>
              )}
            </AddTooltipWrapper>
            <AddDataStoryIcon />
          </span>
        </DataStoryChartButtonS>
      )}

      {!isPreview && isStored && (
          <DataStoryChartButtonS
              onClick={() => {
                const snapshot = DataStory.data.snapshots.find((ds) => ds.id === snapshotId)
                setCurrent(snapshot)
                setLoadImg(true);
              }}
          >

          <span>
            <AddTooltipWrapper>
              <span>Download this graph from Data Story</span>
            </AddTooltipWrapper>

           <DownloadIcon />
          </span>
          </DataStoryChartButtonS>
      )}

      {loadImg && (
          <RenderImg
              current={current}
              downloadHandler={downloadSimpleImgHandler}
              currentImg={1}
              imgGap={1}
          />
      )}

      {!isPreview && isStored && (
        <DataStoryChartButtonS
          onClick={() => deleteFromDataStoryHandler(snapshotId)}
        >
          <span>
            <AddTooltipWrapper>
              <span>Delete this graph from Data Story</span>
            </AddTooltipWrapper>

            <DeleteIcon />
          </span>
        </DataStoryChartButtonS>
      )}

      {chartType === 'custom' && !isStored && (
        <DeleteChartButtonS onClick={() => deleteChartHandler()}>
          <span>
            <DeleteIcon />
          </span>
        </DeleteChartButtonS>
      )}
    </HeaderSnapshotS>
  );
};
const SettingsDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const AddTooltipWrapper = styled.div`
  position: absolute;
  font-size: 14px;
  bottom: 25px;
  right: -5px;
  background: white;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  min-width: 225px;
  padding: 5px;
  z-index: 100;
  display: none;
`;
const DataStoryChartButtonS = styled(ButtonS)`
  position: relative;
  margin-left: 16px;
  span {
    svg {
      path {
        fill: rgba(128, 128, 128, 0.42);
      }
    }
  }
  :hover {
    span {
      div {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
const DeleteChartButtonS = styled(ButtonS)`
  margin-left: 16px;
  span {
    svg {
      g {
        fill: rgba(128, 128, 128, 0.42);
      }
    }
  }
`;
const WrapperTitleSelectS = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .ov-select {
    width: 150px;
  }

  .ov-select-wr {
    height: 30px;
    padding: 0;
  }
`;

const InfoDescS = styled.span`
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 380px;
  padding: 20px 20px 0;
  background: white;
  font-size: 13px;
  border-radius: 10px;
  box-shadow: 0 -2px 9px rgba(0, 0, 0, 0.22);
  z-index: 9;
  font-weight: 400;
  line-height: 1.5em;
`;

const InfoBlock = styled.span`
  margin-left: 10px;
  position: relative;
  svg {
    cursor: help;
    opacity: 0.5;

    &:hover {
      path {
        fill: red;
      }
    }
  }

  &:hover {
    ${InfoDescS} {
      visibility: visible;
    }
  }
`;

const HeaderSnapshotS = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;

  h5 {
    padding-right: 10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #111111;
    margin: 0;
  }

  ${ButtonLinkS} {
    position: relative;

    span {
      position: absolute;
      bottom: 100%;
      background: white;
      right: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      transform: translateX(50%);
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      color: black;
      display: none;
    }

    &:hover {
      span {
        display: block;
      }
      svg path {
        fill: #fbc000;
      }
    }
    &:active {
      span {
        display: none;
      }
      svg path {
        fill: #c39705;
      }
    }
  }
`;
