import styled from 'styled-components';
import { COLORS } from '../../../utils/colors';

export const WrapperItemSelectS = styled.span<{ isRed?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  white-space: nowrap;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  ${({ isRed }) =>
    isRed
      ? `
  color: ${COLORS.danger}
  `
      : null}
`;
