import { Reducer } from 'redux';
import ActionTypes, { TCollaborationsState } from './types';

export const initialState: TCollaborationsState = {
  data: null,
  loading: true,
  notesLoading: false,
  participantLoading: true,
  additionalLoading: true,
  participantInfo: null,
  filters: {
    is_all_assessments: true,
    is_all_participants: true,
    period_of_answer_date: {
      is_all_time: true,
      is_last_month: false,
      is_last_quarter: false,
      is_last_year: false,
      start_date: '',
      end_date: '',
    },
    period_of_hiring_date: {
      from_0_to_30_days: false,
      from_31_to_60_days: false,
      from_61_to_90_days: false,
      from_0_to_90_days: false,
      from_91_to_180_days: false,
      from_0_to_1_years: false,
      from_1_to_3_years: false,
      from_3_to_6_years: false,
      from_6_years: false,
    },
    assessments_ids: [],
    groups_ids: [],
    questions_ids: [],
  },
  additionalInfo: null,
  errors: undefined,
};

const reducer: Reducer<TCollaborationsState> = (
  state = initialState,
  action
): TCollaborationsState => {
  switch (action.type) {
    case ActionTypes.UPDATE_COLLAB_PARTICIPANT_NOTE_R:
    case ActionTypes.DELETE_COLLAB_PARTICIPANT_NOTE_R:
      return { ...state };
    case ActionTypes.ADD_COLLAB_PARTICIPANT_NOTE_R:
      return { ...state, notesLoading: true };
    case ActionTypes.ADD_COLLAB_PARTICIPANT_NOTE_S:
      return {
        ...state,
        notesLoading: false,
        participantInfo: {
          ...state.participantInfo,
          notes: [action.payload, ...(state.participantInfo.notes || [])],
        },
      };
    case ActionTypes.GET_COLLAB_L:
      return { ...state, loading: action.payload };
    case ActionTypes.GET_COLLAB_R:
      return { ...state, loading: true };
    case ActionTypes.GET_SCORE_R:
      return { ...state, additionalLoading: true };
    case ActionTypes.GET_SCORE_S:
      return {
        ...state,
        additionalLoading: false,
        additionalInfo: action.payload,
      };
    case ActionTypes.GET_COLLAB_S:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.GET_COLLAB_PARTICIPANT_INFO_R:
      return { ...state, participantLoading: true };
    case ActionTypes.GET_COLLAB_PARTICIPANT_INFO_S:
      return {
        ...state,
        participantLoading: false,
        participantInfo: action.payload,
      };

    case ActionTypes.UPDATE_COLLAB_PARTICIPANT_NOTE_S:
    case ActionTypes.DELETE_COLLAB_PARTICIPANT_NOTE_S:
      return {
        ...state,
        participantInfo: {
          ...state.participantInfo,
          notes: action.payload,
        },
      };
    case ActionTypes.UPDATE_COLLAB_PARTICIPANT_NOTE_E:
    case ActionTypes.DELETE_COLLAB_PARTICIPANT_NOTE_E:
    case ActionTypes.ADD_COLLAB_PARTICIPANT_NOTE_E:
    case ActionTypes.GET_COLLAB_PARTICIPANT_INFO_E:
    case ActionTypes.GET_SCORE_E:
    case ActionTypes.GET_COLLAB_E:
      return {
        ...state,
        loading: false,
        participantLoading: false,
        notesLoading: false,
        errors: action.payload,
      };
    case ActionTypes.SET_COLLAB_FILTERS:
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};

export { reducer as CollaborationsReducer };
