import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import { AppStore } from '../../../store/applicationState';
import { forgotPassR } from '../../../store/user/actions';
import { TSubmitForm } from '../../../types/form';
import { delay, validateEmail } from '../../../utils/helpers';
import { TEXT } from '../../../utils/textConst';

export const useForgotPass = (EMAIL: string) => {
  const [successSent, setSuccessSent] = React.useState(false);
  const [warning, setWarning] = React.useState('');
  const { User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { form, onChangeHandler, updateForm } = useForm({
    [EMAIL]: {
      value: '',
    },
  });

  const setErrors = {
    email: (text: string) => {
      updateForm(EMAIL, {
        value: form[EMAIL].value,
        errorText: text,
      });
    },
  };

  const submitHandler = async (event: TSubmitForm) => {
    event.preventDefault();
    if (User.loading) {
      setWarning(TEXT.endRequest);
      await delay(1500);
      return setWarning('');
    }
    const email = form[EMAIL].value;
    if (!email) {
      setErrors.email(TEXT.isRequired);
      return null;
    }
    const isEmail = validateEmail(email);
    if (!isEmail) return setErrors.email(TEXT.incorrectEmail);
    const data = {
      email,
      callBack: () => setSuccessSent(true),
    };
    return dispatch(forgotPassR(data));
  };

  return {
    form,
    onChangeHandler,
    submitHandler,
    successSent,
    warning,
  };
};
