import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateAssInPackage } from '../../../hooks/useUpdateAssInPackage';
import { AppStore } from '../../../store/applicationState';
import { AddAssToPack } from '../../../store/assessments/actions';
import { TCatAssessment } from '../../../store/assessments/types';
import { TPackage } from '../../../store/packages/types';
import { deepClone } from '../../../utils/deepClone';
import { DropResult, ItemTypes } from '../types/columnNames';

export const useDnd = (data: { ass: TCatAssessment }) => {
  const { Packages } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { updateAssInPackage } = useUpdateAssInPackage();
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: data,
      end(item: any, monitor) {
        const dropResult = monitor.getDropResult() as DropResult;
        if (item && dropResult) {
          const isDropAllowed = true;
          // dropResult.allowedDropEffect === 'any' ||
          // dropResult.allowedDropEffect === dropResult.dropEffect;

          if (isDropAllowed) {
            const callBack = (
              success: boolean,
              data: TCatAssessment | null
            ) => {
              if (!success || !data) return null;

              if (!Packages.data || !Packages.data[0]) return null;
              const newPackages = deepClone(Packages.data) as TPackage[];
              const pack = newPackages.find(
                (packItem) => packItem.id === dropResult.id
              );
              if (!pack) {
                return console.error('Package does not exist');
              }
              pack.assessments.push({
                ...item.ass,
                relation_created_at: '',
              });
              console.log(item.ass);
              updateAssInPackage(null, newPackages);
              console.log(success, data);
            };
            dispatch(
              AddAssToPack.request({
                data: {
                  relation: true,
                  assessment_id: `${item.ass.id}`,
                  package_id: dropResult.id,
                },
                callBack,
              })
            );
          } else {
            // alertMessage = `You cannot ${dropResult.dropEffect} an item into the ${dropResult.name}`;
          }
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
      }),
    }),
    [data]
  );

  return {
    drag,
    opacity,
  };
};
