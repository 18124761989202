import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { Alert, Loader } from '../ui';
import { ConsultantItem } from './ConsultantItem';
import { useWorkspaceConsultants } from './hooks/useWorkspaceConsultants';

interface ConsultantsProps {}

export const WorkspaceConsultants: React.FC<ConsultantsProps> = () => {
  const { Consultants, User, checkBoxHandler, Workspaces, loadingObj } =
    useWorkspaceConsultants();

  if (Consultants.loading && !Consultants.data) return <Loader isGreen />;

  return (
    <div className={AnimatedClasses.fadeIn}>
      <WrapperS>
        {Consultants.data?.map((consultant, i) => {
          if (consultant.id === User.data?.id) return null;
          return (
            <ConsultantItem
              key={consultant.id}
              src={consultant.profile_picture_url}
              checkHandler={(isChecked) =>
                checkBoxHandler({
                  relation: isChecked,
                  consultant_id: consultant.id,
                  position: i,
                })
              }
              removeHandler={() => null}
              name={`${consultant.first_name} ${consultant.last_name}`}
              isLoading={loadingObj[consultant.id]}
              isActive={
                Array.isArray(consultant.workspaces_ids)
                  ? consultant.workspaces_ids.includes(
                      Workspaces.current?.id || ''
                    )
                  : false
              }
            />
          );
        })}
      </WrapperS>
      {Consultants.errors ? <Alert text={Consultants.errors} /> : null}
    </div>
  );
};

const WrapperS = styled.ul`
  margin-top: 16px;
  max-width: 504px;
  padding: 0;
  list-style: none;
`;
