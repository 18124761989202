import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { AppStore } from '../../store/applicationState';
import { CreateWorkspace } from '../../store/workspaces/actions';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { TEXT } from '../../utils/textConst';
import { Alert, Input, Loader, Radio } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import { RadioItemType } from '../ui/inputs/Radio';
import {
  DataGeoStructure,
  DataHowBig,
  DataOrgLocation,
  DataRemoteWork,
  DataTypeOfGroup,
  DataWhatIndustry,
  DataWSFor,
} from './data/workspaceData';

interface NewWorkspaceProps {
  closeHandler: () => void;
}

const workspaceName = 'Workspace Name';
const WS_NAME = workspaceName.replace(/ /g, '_').toLowerCase();
export const NewWorkspace: React.FC<NewWorkspaceProps> = ({ closeHandler }) => {
  const [activeTypeOfGroup, setActiveTypeOfGroup] =
    React.useState<RadioItemType['id']>(0);
  const [activeWSFor, setActiveWSFor] = React.useState<RadioItemType['id']>(0);
  const [activeHowBigGroup, setActiveHowBigGroup] =
    React.useState<RadioItemType['id']>(0);
  const [activeHowBigOrg, setActiveHowBigOrg] =
    React.useState<RadioItemType['id']>(0);
  const [activeWhatIndustry, setActiveWhatIndustry] =
    React.useState<RadioItemType['id']>(0);
  const [activeGeoStructure, setActiveGeoStructure] =
    React.useState<RadioItemType['id']>(0);
  const [activeRemoteWork, setActiveRemoteWork] =
    React.useState<RadioItemType['id']>(0);
  const [activeOrgLocation, setActiveOrgLocation] =
    React.useState<RadioItemType['id']>(0);
  const { Workspaces, User, Groups } = useSelector((store: AppStore) => store);
  const { form, updateForm, onChangeHandler } = useForm({
    [WS_NAME]: {
      value: '',
    },
  });

  const dispatch = useDispatch();
  const refInnWrapper = React.useRef<HTMLDivElement>(null);

  const closeModalHandler = () => {
    dispatch(CreateWorkspace.error(''));
    if (refInnWrapper.current)
      closeModal(refInnWrapper.current, closeHandler).then(() => null);
  };
  React.useEffect(() => {
    // dispatch(setGroupsLoading(false));
  }, [Groups.loading, dispatch]);
  React.useEffect(() => {
    dispatch(CreateWorkspace.error(''));
  }, [
    activeTypeOfGroup,
    activeWSFor,
    activeHowBigOrg,
    activeWhatIndustry,
    activeGeoStructure,
    activeRemoteWork,
    activeOrgLocation,
    dispatch,
  ]);
  const submitHandler = () => {
    if (Workspaces.loading) return null;
    const name = form[WS_NAME].value;
    if (!name) {
      return updateForm(WS_NAME, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }

    if (!User.data)
      return dispatch(
        CreateWorkspace.error('User does not exist, try to reload page')
      );
    if (!User.data.team_id)
      return dispatch(
        CreateWorkspace.error('User does not have team_id, try to reload page')
      );
    if (!User.data.id)
      return dispatch(
        CreateWorkspace.error('User does not have id, try to reload page')
      );
    if (!activeWSFor)
      return dispatch(CreateWorkspace.error('Workspace type is required'));
    if (activeWSFor === 1 && !activeTypeOfGroup) {
      return dispatch(CreateWorkspace.error('Type of group is required'));
    }
    if (
      (activeWSFor === 2 &&
        (!activeHowBigOrg ||
          !activeWhatIndustry ||
          !activeGeoStructure ||
          !activeRemoteWork ||
          !activeOrgLocation)) ||
      (activeWSFor === 1 && !activeHowBigGroup)
    ) {
      return dispatch(CreateWorkspace.error('All statements are required'));
    }

    const formationObj = DataWSFor.find((d) => d.id === activeWSFor);
    const typOfObj = DataTypeOfGroup.find((d) => d.id === activeTypeOfGroup);
    const orgSizeObj = DataHowBig.find((d) => d.id === activeHowBigOrg);
    const groupSizeObj = DataHowBig.find((d) => d.id === activeHowBigGroup);
    const industryObj = DataWhatIndustry.find(
      (d) => d.id === activeWhatIndustry
    );
    const geoStructObj = DataGeoStructure.find(
      (d) => d.id === activeGeoStructure
    );
    const remoteWorkObj = DataRemoteWork.find((d) => d.id === activeRemoteWork);
    const locationObj = DataOrgLocation.find((d) => d.id === activeOrgLocation);

    return dispatch(
      CreateWorkspace.request({
        data: {
          name,
          team_id: User.data.team_id,
          created_by_id: User.data.id,
          additional_info: {
            formation_type: formationObj ? formationObj.label : '',
            group_type: typOfObj ? typOfObj.label : '',
            organization_size: orgSizeObj
              ? orgSizeObj.label
              : groupSizeObj
              ? groupSizeObj.label
              : '',
            organization_industry: industryObj ? industryObj.label : '',
            organization_geographic_structure: geoStructObj
              ? geoStructObj.label
              : '',
            organization_remote_work: remoteWorkObj ? remoteWorkObj.label : '',
            organization_location: locationObj ? locationObj.label : '',
          },
        },
        callBack: (success) => (success ? closeModalHandler() : null),
      })
    );
  };

  const renderRadio = () => {
    switch (activeWSFor) {
      case DataWSFor[1].id:
        return (
          <Styles.RadiosRowS>
            <Radio
              title="What type of group will participate in assessments in this workspace?"
              data={DataTypeOfGroup}
              activeId={activeTypeOfGroup}
              clickHandler={setActiveTypeOfGroup}
            />
            <Radio
              title="How big is this group?"
              data={DataHowBig}
              activeId={activeHowBigGroup}
              clickHandler={setActiveHowBigGroup}
            />
          </Styles.RadiosRowS>
        );
      case DataWSFor[0].id:
        return (
          <Styles.RadiosRowS>
            <Radio
              title="How big is this organization?"
              data={DataHowBig}
              activeId={activeHowBigOrg}
              clickHandler={setActiveHowBigOrg}
            />
            <Radio
              title="What industry best matches the business of this organization?"
              data={DataWhatIndustry}
              activeId={activeWhatIndustry}
              clickHandler={setActiveWhatIndustry}
            />
            <Radio
              title="What is the organization's geographic structure?"
              data={DataGeoStructure}
              activeId={activeGeoStructure}
              clickHandler={setActiveGeoStructure}
            />
            <Radio
              title="Is this organization embracing remote work?"
              data={DataRemoteWork}
              activeId={activeRemoteWork}
              clickHandler={setActiveRemoteWork}
            />
            <Radio
              title="Where is this organization located?"
              data={DataOrgLocation}
              activeId={activeOrgLocation}
              clickHandler={setActiveOrgLocation}
            />
          </Styles.RadiosRowS>
        );

      default:
        return <></>;
    }
  };

  return (
    <Styles.ModalOuterWrapperS
      style={{ zIndex: 9999 }}
      tabIndex={0}
      onKeyDown={(e: { keyCode: number; preventDefault: () => void }) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          submitHandler();
        }
      }}
    >
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '592px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>New Workspace</Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <form>
            <Input
              onChange={onChangeHandler}
              value={form[WS_NAME].value}
              errorText={form[WS_NAME].errorText}
              name={WS_NAME}
              label={workspaceName}
              placeholder={workspaceName}
            />
            {Workspaces.errors ? <Alert text={Workspaces.errors} /> : null}
          </form>
          <Styles.RadiosRowS>
            <Radio
              title="Choose workspace type"
              data={DataWSFor}
              activeId={activeWSFor}
              clickHandler={setActiveWSFor}
            />
          </Styles.RadiosRowS>

          {activeWSFor !== 0 && renderRadio()}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>

            <ButtonDefS isSave onClick={submitHandler}>
              {Workspaces.loading ? <Loader /> : 'Save'}
            </ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
