// const DEF_L = 26;

// const AlphabetLetters = (() => {
//   const colLength = 53;
//   const caps = [...Array(DEF_L)].map((val, i) => String.fromCharCode(i + 65));

//   if(DEF_L >= colLength) return caps.slice(0, colLength);

//   const arrNum = Math.floor(colLength / DEF_L);

//   let arr = [...caps];

//   for(let pos=0; pos < arrNum; pos++){

//     let itmNum = colLength / (pos + 1 + 1) - DEF_L;
//     itmNum =  Math.abs(Math.floor(itmNum));
//     if(itmNum === 0) itmNum = DEF_L;
//     arr = [...arr, ...createColName(caps, pos, itmNum)];
//   }

//   console.log(arr);

//   return arr;
// })();

// function createColName(arr: string[], pos: number, itmNum: number): string[] {
//   const newArr = [];
//   console.log(pos);
//   console.log(itmNum);
//   for(let i = 0; i < itmNum; i++) {
//     newArr.push(arr[pos] + arr[i]);
//   }

//   return newArr
// }

// console.log(AlphabetLetters.length);

export enum AlphabetLetters {
  A = 0,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
  AA,
  AB,
  AC,
  AD,
  AE,
  AF,
  AG,
  AH,
  AI,
  AJ,
  AK,
  AL,
  AM,
  AN,
  AO,
  AP,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AV,
  AW,
  AX,
  AY,
  AZ,
  BA,
  BB,
  BC,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BK,
  BL,
  BM,
  BN,
  BO,
  BP,
  BQ,
  BR,
  BS,
  BT,
  BU,
  BV,
  BW,
  BX,
  BY,
  BZ,
}
