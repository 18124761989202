import React from 'react';
import styled from 'styled-components';
import { AddConsultants } from '..';
import { COLORS_RGBA } from '../../utils/colors';
import { ButtonAdd, UserImage } from '../ui';
interface InviteConsultantsProps {}
export const InviteConsultants: React.FC<InviteConsultantsProps> = () => {
  const [isAddVisible, setIsAddVisible] = React.useState(false);
  return (
    <>
      <WrapperS>
        <ButtonAdd
          title="Invite member"
          onClick={() => setIsAddVisible(true)}
        />
        <WrapperSelectsS>
          <li>
            <UserImage src="" />
            <p>Amanda Green</p>
          </li>
          <li>
            <UserImage src="" />
            <p>Amanda Green</p>
          </li>
          <li>
            <UserImage src="" />
            <p>Amanda Green</p>
          </li>
          <li>
            <UserImage src="" />
            <p>Amanda Green</p>
          </li>
        </WrapperSelectsS>
      </WrapperS>
      {isAddVisible ? (
        <AddConsultants closeHandler={() => setIsAddVisible(false)} />
      ) : null}
    </>
  );
};
const WrapperS = styled.div`
  max-width: 504px;
`;

const WrapperSelectsS = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 16px;
    border: solid 1px ${COLORS_RGBA.default(0.12)};
    border-radius: 8px;
    p {
      flex: 1;
      font-size: 1.6rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
