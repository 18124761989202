import React from 'react';
import styled from 'styled-components';
import { Chart, registerables } from 'chart.js';
import {
  EAxisAnswerType,
  ETypeGraph,
  TChart,
  TChartFilter,
  TScatterData,
} from '../../../store/analytics/types';
import { ChartHeader } from '../ChartHeader';
import { useChartRuns } from './hooks/useChartRuns';
import { useDescription } from './hooks/useDescription';
import { GRAPH_COLORS } from '../../../utils/colors';
import { NoData } from '../NoData';
import { BlockChartFilter } from './BlockChartFilter';

const AV = 'Average';

Chart.register(...registerables);
interface ChartProps {
  widthLegend?: boolean;
  isStored: boolean;
  isPreview?: boolean;
  data: TChart;
  index: number;
  chartIndex: number;
  snapshotId: string | null;
  dataStoryChartSettings: TChartFilter | null;
  refHeader: React.MutableRefObject<HTMLDivElement>;
}

export const ScatterChart: React.FC<ChartProps> = React.memo(
    ({
       data: chart,
       index,
       chartIndex,
       isStored,
       dataStoryChartSettings,
       snapshotId,
       isPreview,
       refHeader,
     }) => {
      const chartRef: any = React.createRef();
      const { activeRuns } = useChartRuns();
      const hookDesc = useDescription();
      const description = hookDesc.getAssDescription(chart.assessments_ids || []);
      const version = hookDesc.getAssVersion(chart.assessments_ids || []);
      const [isRuns, setIsRuns] = React.useState(false);
      const [activeQuestionItems, setActiveQuestionItems] = React.useState<
          | {
        x_axis_questions: string[];
        y_axis_questions: string[];
      }
          | string[]
      >();
      React.useEffect(() => {
        let myChar: any = null;
        (() => {
          if ((chart.data && !chart.data[0]) || !chartRef.current) return null;

          const myChartRef = chartRef.current.getContext('2d');
          const data = chart.data as TScatterData[];
          // const mapData = data.map((itm) => itm.points.map((p) => p))[0];
          const yArr: any = [];
          let maxStepY = 5;
          let maxStepX = 5;
          const avData = {
            x: 0,
            y: 0,
          };
          if (chart.meta_data) {
            switch (chart.meta_data.y_axis_answer_type) {
              case EAxisAnswerType.ten: {
                maxStepY = 10;
                break;
              }
              default:
                maxStepY = 5;
            }
            switch (chart.meta_data.x_axis_answer_type) {
              case EAxisAnswerType.ten: {
                maxStepX = 10;
                break;
              }
              default:
                maxStepX = 5;
            }
            avData.x = chart.meta_data.overall_average_point.x;
            avData.y = chart.meta_data.overall_average_point.y;
          }
          data.forEach((l, i) => {
            // if (l.points)
            l.points.forEach(({ y }) => {
              yArr.push(y);
            });
            // else if (l.data) {
            //   l.data.forEach((d) => {
            //     d.points.forEach(({ y }) => {
            //       yArr.push(y);
            //     });
            //   });
            // }
          });

          myChar = new Chart(myChartRef, {
            type: 'scatter',

            data: {
              labels: ['Test', 'Test2'],

              datasets: [
                {
                  data: [
                    {
                      x: maxStepX,
                      y: maxStepY,
                    },
                  ],

                  label: ' ',
                  backgroundColor: 'rgba(0,0,0,0)',
                  borderColor: 'rgba(0,0,0,0)',
                },
                // {
                //   data: [5],
                //   backgroundColor: '#000',
                //   label: 'Average2',
                // },
                // {
                //   data: [{
                //     x:5,y:5
                //   }],
                //   backgroundColor: '#000',
                //   label: 'Average 2',
                // },
                {
                  data: [avData],
                  backgroundColor: '#ffcc01',
                  label: AV,
                },
                ...data.map((l, i) => ({
                  data: l.points.map(({ x, y }) => ({
                    x,
                    y,
                  })),
                  label: l.name,
                  backgroundColor: GRAPH_COLORS[i],
                })),
              ],
            },
            options: {
              // layout: {
              //   padding: {
              //     top: 15,
              //   },
              // },
              maintainAspectRatio: true,
              indexAxis: 'y',
              responsive: true,
              scales: {
                x: {
                  min: 1,
                  // max: maxStepX,
                  ticks: {
                    stepSize: 1,
                  },
                },
                y: {
                  min: 1,
                  ticks: {
                    stepSize: 1,
                  },
                  // max: maxStepY,
                },
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label(tooltipItem) {
                      if (
                          tooltipItem.dataset.backgroundColor === 'rgba(0,0,0,0)'
                      ) {
                        return null;
                      }
                      const dataLabel = [
                        `${tooltipItem.parsed.x} ${chart.meta_data?.x_axis_name}`,
                        `${tooltipItem.parsed.y} ${chart.meta_data?.y_axis_name}`,
                      ];

                      if (tooltipItem.dataset.label === AV) {
                        return [AV, ...dataLabel];
                      }

                      const itemIndex = chart.data.findIndex(
                          (itm) => itm.name === tooltipItem.dataset.label
                      );

                      console.log('-> itemIndex', itemIndex);
                      console.log('-> chart.data', chart.data);

                      return [
                        `${
                            (chart.data[itemIndex] as TScatterData)?.points[
                                tooltipItem.dataIndex
                                ]?.body || ''
                        }`,
                        ...dataLabel,
                      ];
                    },
                  },
                },
                legend: {
                  // display: false,

                  labels: {
                    // usePointStyle: true,

                    font: {
                      size: 10,
                    },
                  },
                },
                title: {
                  display: true,
                  text: 'Scatter Plot',
                },
              },
            },
          });
        })();

        return () => {
          myChar && myChar.destroy();
        };
      }, [chart.data, chart.meta_data, chartRef, chartIndex]);

      const renderBody = () => {
        if (chart.data && chart.data[0]) {
          return (
              <CanvasWrapperS
                  style={{ maxHeight: `${!isPreview ? '500px' : '380px'}` }}
                  preview={isPreview}
              >
                <CanvasS height={200} ref={chartRef} />
                <XWrapperS>{chart.meta_data?.x_axis_name}</XWrapperS>
                <YWrapperS>{chart.meta_data?.y_axis_name}</YWrapperS>
              </CanvasWrapperS>
          );
        }

        return <NoData />;
      };
      return (
          <div style={{ position: 'relative' }}>
            <ChartHeader
                chartView={chart.view}
                isStored={isStored}
                description={description}
                version={version}
                title={chart.name}
                isInFav={chart.is_saved}
                id={chart.id}
                graphType={ETypeGraph.type3}
                isCycles={true}
                chartType={chart.origin}
                chartData={chart.data}
                chartSaved={chart.is_saved}
                chartPosition={chart.position}
                snapshotId={snapshotId}
                activeRuns={activeRuns}
                dataStoryChartSettings={dataStoryChartSettings}
                isPreview={isPreview}
                chartMetaData={chart.meta_data && chart.meta_data}
                isParticipationRateActive={false}
                reference={refHeader}
                selectedСhartQuestions={activeQuestionItems}
                allChartQuestions={chart.select_group?.questions.options}
            />
            <BlockChartFilter
                disabledLabels={null}
                legendHandler={null}
                setDefaultLabels={null}
                chartIndex={chartIndex}
                index={index}
                isRuns={isRuns}
                setIsRuns={setIsRuns}
                treeDisabled
                cyclesDisabled
                tenureDisabled
                graphType={chart.type}
                questions={chart.select_group?.questions.options}
                activeQuestionItems={activeQuestionItems}
                setActiveQuestionItems={setActiveQuestionItems}
            />
            {renderBody()}
          </div>
      );
    }
);

const CanvasWrapperS = styled.div<{ preview: boolean }>`
  overflow: auto;
  max-height: ${({ preview }) => (!preview ? '500px' : '350px')};
  padding: 0 20px;
  position: relative;
  canvas {
    max-height: ${({ preview }) => (!preview ? '450px' : '300px')};
    margin-bottom: 30px;
    margin-left: 20px;
  }
`;
const CanvasS = styled.canvas``;

const XYWRapperS = styled.div`
  position: absolute;
  font-size: 12px;
`;
const XWrapperS = styled(XYWRapperS)`
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;
const YWrapperS = styled(XYWRapperS)`
  top: 50%;
  left: 30px;
  //width: 10px;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
`;
