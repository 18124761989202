import React from 'react';
import { useSelector } from 'react-redux';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import { Alert } from '../components/ui';
import {
  AddNewConsultants,
  TabBtns,
  Tabs,
  WorkspaceConsultants,
  WorkspaceInfo,
} from '../components/workspaceSettings';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { EBtnsSettings } from '../components/workspaceSettings/Tabs';
import { AdditionalInfo } from '../components/workspaceSettings/AdditionalInfo';
// import { HrisIntegrations } from '../components/workspaceSettings/HrisIntegrations';
import {WorkspaceGroupClassification} from "../components/workspaceSettings/WorkspaceGroupClassification";
import {FinchIntegrations} from "../components/workspaceSettings/FinchIntegrations";

interface WorkspaceSettingsProps {}

export const WorkspaceSettings: React.FC<WorkspaceSettingsProps> = () => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [isAddConsultantsVisible, setIsAddConsultantsVisible] =
    React.useState(false);
  const [activeTabId, setActiveTabId] = React.useState(TabBtns[0].id);
  const renderBody = () => {
    switch (activeTabId) {
      case EBtnsSettings.consultants:
        return <WorkspaceConsultants />;
      case EBtnsSettings.additionalInfo:
        return <AdditionalInfo />;
      case EBtnsSettings.surveyExperience:
        return <WorkspaceGroupClassification />;
      case EBtnsSettings.hrisIntegrations:
        return <FinchIntegrations />;
      default:
        return <WorkspaceInfo />;
    }
  };
  return (
    <>
      <MainWrapperS>
        <PageTitleWrapperS>
          <h1>Workspace Settings</h1>
        </PageTitleWrapperS>

        <Tabs activeId={activeTabId} onClick={setActiveTabId} />
        {renderBody()}
        {Workspaces.errors ? <Alert text={Workspaces.errors} /> : null}
        {/* {StoreConsultants.errors ? (
          <Alert text={`Consultants: ${StoreConsultants.errors}`} />
        ) : null} */}
      </MainWrapperS>
      {isAddConsultantsVisible ? (
        <AddNewConsultants
          data={[]}
          closeModal={() => setIsAddConsultantsVisible(false)}
        />
      ) : null}
    </>
  );
};
