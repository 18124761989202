import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../svgs';
import { ButtonS } from './ButtonArrow';
import {group_border_colors} from "../../participants/ParticipantRow";
import {COLORS_RGBA} from "../../../utils/colors";

interface ButtonCloseProps {
  onClick: () => void;
  colorB?: string;
}

export const ButtonClose: React.FC<ButtonCloseProps> = ({ onClick, colorB }) => {
  return (
    <ButtonCloseS onClick={onClick} colorB={colorB} className="btn-close">
      <CloseIcon  />
    </ButtonCloseS>
  );
};

const ButtonCloseS = styled(ButtonS)<{ colorB?: string }>`
  &:hover {
    svg {
      g {
        opacity: 1;
      }
      path {
        ${({ colorB }) => {
          if (colorB === group_border_colors.automatic) {
            return `
              fill: ${COLORS_RGBA.orange(0.8)};
            `;
          } else {
            return `
              fill: ${COLORS_RGBA.accent(0.8)};
            `;
          }
        }}
      }
    }
  }
  &:active {
    svg {
      g {
        opacity: 1;
      }
      path {
        ${({ colorB }) => {
          if (colorB === group_border_colors.automatic) {
            return `
              fill: ${COLORS_RGBA.orange(0.8)};
            `;
          } else {
            return `
              fill: ${COLORS_RGBA.accent(0.8)};
            `;
          }
        }}
      }
    }
  }`;
