export const changeTimeZoneToEst = (date: Date | string) => {
  const timeZone = 'America/New_York';

  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
        hour12: false,
      })
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
      hour12: false,
    })
  );
};
