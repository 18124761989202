import axios from 'axios';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {ETypeGraph, TChart, TChartFilter} from '../../store/analytics/types';
import {AppStore} from '../../store/applicationState';
import {EditSnapshot} from '../../store/dataStory/actions';
import {deepClone} from '../../utils/deepClone';
import {ChartBlock} from '../Analytics/charts/ChartBlock';
import {ScatterChart} from '../Analytics/charts/ScatterChart';
import {StackedHorizontalChart} from '../Analytics/charts/StackedHorizontalChart';
import {FileHeader} from '../Analytics/FileHeader';
import {ButtonCancel, ButtonSave, Loader, TextArea} from '../ui';
import {LineScatterChart} from "../Analytics/charts/LineScatterChart";

interface SnapshotProps {
  chart: TChart;
  //snapshot: TSnapshot;
  name: string;
  type: 'chart' | 'file';
  comment: string;
  snapshotId: string;
  settings: TChartFilter;
  preview?: boolean;
  isDownload?: boolean;
  link: {
    url: string;
    url_expiration: string;
  };
}

export const Snapshot: React.FC<SnapshotProps> = ({
  chart,
  type,
  name,
  comment,
  snapshotId,
  settings,
  preview,
  isDownload,
  link,
}) => {
  const [value, setValue] = React.useState(comment);
  const [oldValue, setOldValue] = React.useState(comment);
  const [loading, setLoading] = React.useState(false);
  const { DataStory } = useSelector((store: AppStore) => store);
  const refHeader = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const callBack = (success: boolean) => {
    if (!success) return null;
    const currentData = deepClone(DataStory.data) as typeof DataStory.data;
    currentData.snapshots.forEach((itm) => {
      if (itm.id === snapshotId) {
        itm.comment = value;
      }
    });
    dispatch(EditSnapshot.success(currentData));
  };

  React.useEffect(() => {
    if (type === 'file' && link) {
      (async () => {
        setLoading(true);
        try {
          const resp = await axios.request({
            method: 'get',
            url: link.url,
            responseType: 'blob',
          });
          const blob = new Blob([resp.data], { type: 'image/png' });
          let objectURL = URL.createObjectURL(blob);
          const image = document.getElementById(snapshotId) as HTMLImageElement;
          image.src = objectURL;
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [link, snapshotId, type]);

  const renderSnapshot = (
    chart: TChart,
    name: string,
    snapshotId: string,
    type: 'chart' | 'file'
  ) => {
    if (type === 'file') {
      return (
        <SnapshotS>
          <GraphS isPreview={preview}>
            <FileHeader
              isPreview={preview}
              title={name}
              snapshotId={snapshotId}
              settings={settings}
              refHeader={refHeader}
            />
            <ImageWrapperS>
              {loading && (
                <WhiteBgS>
                  <Loader isGreen />
                </WhiteBgS>
              )}
              <img id={snapshotId} alt="Snapshot" />
            </ImageWrapperS>
          </GraphS>
          {!preview ? (
            <>
              <TextArea
                value={value}
                onChange={(e) => setValue(e.currentTarget.value)}
                placeholder={'Type comments here...'}
                maxLength={500}
              />
              {value !== oldValue && (
                <ButtonWrapperS>
                  <ButtonCancel
                    onClick={() => {
                      setValue(oldValue);
                    }}
                  />
                  <ButtonSave
                    onClick={() => {
                      setOldValue(value);
                      dispatch(
                        EditSnapshot.request({
                          snapshotId,
                          comment: value,
                          callBack,
                        })
                      );
                    }}
                  />
                </ButtonWrapperS>
              )}
            </>
          ) : (
            <CommentTextS>{comment}</CommentTextS>
          )}
        </SnapshotS>
      );
    } else
      switch (chart.type) {
        case ETypeGraph.type1:
          return (
            <SnapshotS>
              <GraphS isPreview={preview}>
                <ChartBlock
                  isStored={true}
                  snapshotId={snapshotId}
                  chartIndex={chart.position}
                  index={null}
                  data={chart}
                  key={chart.id}
                  widthLegend={false}
                  dataStoryChartSettings={settings}
                  isPreview={preview}
                  refHeader={refHeader}
                  isDownload={isDownload}
                />
              </GraphS>
              {!preview ? (
                <>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    placeholder={'Type comments here...'}
                    maxLength={500}
                  />
                  {value !== oldValue && (
                    <ButtonWrapperS>
                      <ButtonCancel
                        onClick={() => {
                          setValue(oldValue);
                        }}
                      />
                      <ButtonSave
                        onClick={() => {
                          setOldValue(value);
                          dispatch(
                            EditSnapshot.request({
                              snapshotId,
                              comment: value,
                              callBack,
                            })
                          );
                        }}
                      />
                    </ButtonWrapperS>
                  )}
                </>
              ) : (
                <CommentTextS>{comment}</CommentTextS>
              )}
            </SnapshotS>
          );
        case ETypeGraph.type2:
          return (
            <SnapshotS>
              <GraphS isPreview={preview}>
                <StackedHorizontalChart
                  snapshotId={snapshotId}
                  chartIndex={chart.position}
                  index={chart.position}
                  data={chart}
                  isStored={true}
                  isPreview={preview}
                  dataStoryChartSettings={settings}
                  refHeader={refHeader}
                />
              </GraphS>
              {!preview ? (
                <>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    placeholder={'Type comments here…'}
                    maxLength={500}
                  />
                  {value !== oldValue && (
                    <ButtonWrapperS>
                      <ButtonCancel onClick={() => setValue(oldValue)} />
                      <ButtonSave
                        onClick={() => {
                          setOldValue(value);
                          dispatch(
                            EditSnapshot.request({
                              snapshotId,
                              comment: value,
                              callBack,
                            })
                          );
                        }}
                      />
                    </ButtonWrapperS>
                  )}
                </>
              ) : (
                <CommentTextS>{comment}</CommentTextS>
              )}
            </SnapshotS>
          );
        case ETypeGraph.type3:
          return (
            <SnapshotS>
              <GraphS isPreview={preview}>
                <ScatterChart
                  snapshotId={snapshotId}
                  chartIndex={chart.position}
                  index={chart.position}
                  data={chart}
                  isStored={true}
                  isPreview={preview}
                  dataStoryChartSettings={settings}
                  refHeader={refHeader}
                />
              </GraphS>
              {!preview ? (
                <>
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    placeholder={'Type comments here…'}
                    maxLength={500}
                  />
                  {value !== oldValue && (
                    <ButtonWrapperS>
                      <ButtonCancel onClick={() => setValue(oldValue)} />
                      <ButtonSave
                        onClick={() => {
                          setOldValue(value);
                          dispatch(
                            EditSnapshot.request({
                              snapshotId,
                              comment: value,
                              callBack,
                            })
                          );
                        }}
                      />
                    </ButtonWrapperS>
                  )}
                </>
              ) : (
                <CommentTextS>{comment}</CommentTextS>
              )}
            </SnapshotS>
          );
        case ETypeGraph.type4:
          return (
              <SnapshotS>
                <GraphS isPreview={preview}>
                  <LineScatterChart
                      isStored={true}
                      snapshotId={snapshotId}
                      chartIndex={chart.position}
                      index={null}
                      data={chart}
                      key={chart.id}
                      widthLegend={false}
                      dataStoryChartSettings={settings}
                      isPreview={preview}
                      refHeader={refHeader}
                      isDownload={isDownload}
                  />
                </GraphS>
                {!preview ? (
                    <>
                      <TextArea
                          value={value}
                          onChange={(e) => setValue(e.currentTarget.value)}
                          placeholder={'Type comments here...'}
                          maxLength={500}
                      />
                      {value !== oldValue && (
                          <ButtonWrapperS>
                            <ButtonCancel
                                onClick={() => {
                                  setValue(oldValue);
                                }}
                            />
                            <ButtonSave
                                onClick={() => {
                                  setOldValue(value);
                                  dispatch(
                                      EditSnapshot.request({
                                        snapshotId,
                                        comment: value,
                                        callBack,
                                      })
                                  );
                                }}
                            />
                          </ButtonWrapperS>
                      )}
                    </>
                ) : (
                    <CommentTextS>{comment}</CommentTextS>
                )}
              </SnapshotS>
          );
        default:
          return (
            <div
              key={chart.id}
              style={{ padding: '120px 0', textAlign: 'center' }}
            >
              <h3>No Such Type of Graph yet</h3>
            </div>
          );
      }
  };
  return (

    <MainWrapperS chartType={chart?.type} preview={preview}>
      {renderSnapshot(chart, name, snapshotId, type)}
    </MainWrapperS>
  );
};
const MainWrapperS = styled.div<{chartType?: ETypeGraph, preview: boolean}>`
  display: flex;
  flex-wrap: wrap;
  min-width: ${({ chartType,preview }) => (chartType === ETypeGraph.type4 && !preview? '900px' : '450px')};
  width: ${({ chartType, preview }) => (chartType === ETypeGraph.type4 && !preview? '100%' : 'calc(50% - 20px)')};
  
`;
const CommentTextS = styled.p`
  font-size: 15px;
  margin: 0 5px 0 5px;
  padding: 0;
  width: 690px;
  overflow: auto;
  max-height: 180px;
`;
const SnapshotS = styled.div`
  width: 100%;
  margin-bottom: 20px;

  textarea {
    background-color: #f8f8f8;
  }
  :hover {
    textarea,
    & > div {
      transition-duration: 1s;
      border-color: #4caf50;
    }
  }
`;
const GraphS = styled.div<{ isPreview: boolean }>`
  border-radius: 10px;
  border: ${({ isPreview }) =>
    isPreview ? 'none' : 'solid 1px rgba(42, 47, 42, 0.12)'};
  box-shadow: ${({ isPreview }) =>
    isPreview ? '0 4px 12px 0 rgba(0, 0, 0, 0.04)' : 'none'};
  height: ${({ isPreview }) => (!isPreview ? '600px' : '')};
  margin: ${({ isPreview }) => (!isPreview ? '24px 0 24px 0' : '0 0 10px 0')};
  background-color: white;
`;
const ButtonWrapperS = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0 0 16px;
    max-width: 100px;
  }
`;
const ImageWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 520px;
  height: 100%;
  img {
    max-width: 100%;
    max-height: 520px;
  }
`;
const WhiteBgS = styled.div`
  z-index: 999;
  background: white;
  height: 100%;
  width: 100%;
  position: absolute;
`;
