import React from 'react';
import { TSearchSettingsKind } from '../../../components/Analytics/Collaboration/SearchSettings';

export const useFilterSearch = (
  resetHandler: (data: null, id: string) => void
) => {
  const [currUserId, setCurrUserId] = React.useState('');

  const [settingsKindFilter, setSettingsKindFilter] =
    React.useState<TSearchSettingsKind>('def');

  React.useEffect(() => {
    (() => {
      if (!currUserId) return null;

      resetHandler(null, currUserId);

      const lines = document.querySelectorAll('.userLine');

      if (!lines[0]) return null;

      lines.forEach((line: HTMLElement) => {
        if (
          line!.dataset.sourceId === currUserId &&
          line.classList.contains('or')
        ) {
          setTimeout(() => {
            line.setAttribute(
              'style',
              `stroke: #fbaf0d !important; stroke-width: 4px;`
            );
          }, 10);
        }
        if (
          line!.dataset.sourceId === currUserId &&
          settingsKindFilter === 'target'
        ) {
          line.setAttribute('style', `stroke: #d3d3d3 !important; `);
        } else if (
          line!.dataset.sourceId !== currUserId &&
          settingsKindFilter === 'source'
        ) {
          line.setAttribute('style', `stroke: #d3d3d3 !important; `);
        }
      });
    })();

    // eslint-disable-next-line
  }, [settingsKindFilter, currUserId]);

  return {
    settingsKindFilter,
    setSettingsKindFilter,
    setCurrUserId,
  };
};
