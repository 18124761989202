import React from 'react';
import styled from 'styled-components';
import { InfoTooltipIcon, MinusIcon, PlusIcon } from '../../svgs';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import { MUTUAL } from '../../../utils/consts';
import { ArrowTempIcon } from '../../svgs/ArrowTempIcon';
import { SettingsIcon } from '../../svgs/Settings';
import { SearchSettings } from './SearchSettings';
import { useGlobalConnectionFilter } from '../../../pages/Visualizations/hooks/useGlobalConnectionFilter';
import ReactSwitch from 'react-switch';
import { useFriendToggle } from '../../../pages/Visualizations/hooks/useFriendToggle';

interface Props {
  zoomIn: () => void;
  zoomOut: () => void;
  hideBtnSettings?: boolean;
}
export const ControlBtns: React.FC<Props> = ({
  zoomIn,
  zoomOut,
  hideBtnSettings,
}) => {
  const { settingsKindFilter, setSettingsKindFilter } =
    useGlobalConnectionFilter();
  const { friendChecked, setFriendChecked } = useFriendToggle();
  return (
    <WrapperS>
      <ToggleWrapperS title={'Top Collaborators'}>
        <ReactSwitch
          checked={friendChecked}
          onChange={() => {
            setFriendChecked(!friendChecked);
          }}
          className="react-switch"
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={COLORS.accent}
          activeBoxShadow={`0px 1px 10px ${COLORS_RGBA.accent(0.6)}`}
          height={25}
        />
        <ToggleInfoS>
          <InfoTooltipIcon />
          <InfoS>
            <InfoInnerS className="hasBottomTriangle">
              <span>
                Top Collaborators are an organization’s oil in the machine. It’s
                proven that these people raise the performance of those around
                them by 10-15%. <br />
                Based on how many people listed the person, the Magenta
                ring/frame is more or less opaque (the person with the most “top
                collaborator” mentions is 100% fill).
              </span>
            </InfoInnerS>
          </InfoS>
        </ToggleInfoS>
      </ToggleWrapperS>

      {!hideBtnSettings && (
        <BtnSettingsS>
          <SettingsIcon />
          <InfoS>
            <InfoInnerS className="hasBottomTriangle">
              <SearchSettings
                isDef={settingsKindFilter === 'def'}
                isGlobal
                isSource={settingsKindFilter === 'source'}
                isTarget={settingsKindFilter === 'target'}
                onChange={setSettingsKindFilter}
              />
            </InfoInnerS>
          </InfoS>
        </BtnSettingsS>
      )}

      <BtnInfoS>
        <InfoTooltipIcon />
        <InfoS>
          <InfoInnerS className="hasBottomTriangle">
            <WrapperInfoObjLineS>
              <LineS style={{ background: '#fbaf0d' }} />

              <InfoObjS>
                Gold lines indicate each person identified the other as a close
                collaborator.
              </InfoObjS>
            </WrapperInfoObjLineS>
            <WrapperInfoObjLineS>
              <LineS style={{ background: MUTUAL.isUnActive.color }} />

              <InfoObjS>
                Green lines indicate only one person identified the other as a
                close collaborator.
              </InfoObjS>
            </WrapperInfoObjLineS>
            <WrapperInfoObjLineS last>
              <ArrowWrapperS>
                <span />
                <ArrowTempIcon />
              </ArrowWrapperS>
              <InfoObjS>
                Arrows point to someone's manager or direct report.
              </InfoObjS>
            </WrapperInfoObjLineS>
          </InfoInnerS>
        </InfoS>
      </BtnInfoS>

      <ControlBtnsWrapperS>
        <button onClick={zoomOut}>
          <MinusIcon />
          <InfoS>
            <InfoInnerS className="hasBottomTriangle">Zoom Out</InfoInnerS>
          </InfoS>
        </button>
        <button onClick={zoomIn}>
          <PlusIcon />
          <InfoS>
            <InfoInnerS className="hasBottomTriangle">Zoom In</InfoInnerS>
          </InfoS>
        </button>
      </ControlBtnsWrapperS>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const InfoS = styled.span`
  position: absolute;
  padding: 0 0 10px 0;
  bottom: 100%;
  right: 0;
  min-width: 300px;
  display: none;
`;

const BtnInfoS = styled.button`
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  svg path {
    fill: #fff;
  }

  &:hover {
    svg path {
      fill: ${COLORS.accent};
    }

    ${InfoS} {
      display: block;
    }
  }
`;

const ToggleInfoS = styled.div`
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    fill: #fff;
  }

  &:hover {
    svg path {
      fill: ${COLORS.accent};
    }

    ${InfoS} {
      display: block;
    }
  }
`;

const ToggleWrapperS = styled.div`
  padding: 0 10px;
  width: 105px;
  height: 48px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

// const ToggleInnerWrapperS = styled.div`
//   margin-top: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;
// const ToggleTextS = styled.span`
//   color: white;
//   font-size: 12px;
// `;

// const ToggleLabelS = styled.label``;

const BtnSettingsS = styled(BtnInfoS)`
  margin-right: 10px;
  margin-left: 10px;
  svg {
    transform: scale(0.7);
    path {
      opacity: 1;
    }
  }
`;

const ControlBtnsWrapperS = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-left: 20px;
  button {
    background: none;
    border: 0;
    outline: none;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${InfoS} {
      min-width: auto;
      white-space: nowrap;

      .hasBottomTriangle {
        padding: 10px 20px;
      }
    }
    svg g {
      fill: white;
    }

    &:hover {
      svg rect,
      svg g {
        fill: ${COLORS.accent};
      }
      ${InfoS} {
        display: block;
      }
    }
  }
`;

const InfoInnerS = styled.span`
  background: white;
  display: block;
  width: 100%;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.24);
  padding: 20px;
  position: relative;
  border-radius: 10px;
  &.hasBottomTriangle {
    &:after {
      border-width: 7px;
      right: 23px;
    }
  }
`;

const WrapperInfoObjLineS = styled.span<{ last?: boolean }>`
  display: flex;

  ${({ last }) =>
    last
      ? `
    margin-bottom: 0;
  `
      : `
    margin-bottom: 20px;
  `}
`;
const LineS = styled.span`
  display: block;
  width: 40px;
  height: 2px;
  margin-right: 10px;
  margin-top: 10px;
`;
const InfoObjS = styled.span`
  text-align: left;
  font-size: 14px;
`;

const ArrowWrapperS = styled.span`
  display: flex;
  align-items: center;
  height: 10px;
  margin-top: 5px;
  margin-right: 5px;
  & > span {
    width: 15px;
    height: 2px;
    background: ${MUTUAL.isUnActive.color};
  }

  svg {
    width: 15px;
    height: 13px;
    fill: ${MUTUAL.isUnActive.color};
    margin-left: -10px;
  }
`;
