export const genChartId = (
  name: string,
  id: string,
  date: string,
  name2: string,
  link: string,
  hiringDate?: string
) => {
  if (!hiringDate) return `${name}/${id}/${date}/${name2}/${link}`;
  return `${name}/${id}/${date}/${name2}/${link}/${hiringDate}`;
};
