import React from 'react';

interface AddDataStoryIconProps {}

export const AddDataStoryIcon: React.FC<AddDataStoryIconProps> = () => {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.019 4a6.59 6.59 0 0 0 .074 1.6H5.6a.8.8 0 0 0-.795.707L4.8 6.4v8H12a.8.8 0 0 1 .794.7l.006.1v2.4a.8.8 0 0 0 .707.795l.093.005a.8.8 0 0 0 .795-.707l.005-.093v-6.693a6.543 6.543 0 0 0 1.6.074V17.6a2.4 2.4 0 0 1-2.236 2.394L13.6 20H2.4A2.4 2.4 0 0 1 0 17.6v-2.4a.8.8 0 0 1 .8-.8h2.4v-8A2.4 2.4 0 0 1 5.6 4h3.419zM11.2 16H1.6v1.6a.8.8 0 0 0 .707.795l.093.005h8.937a2.396 2.396 0 0 1-.137-.8V16zm.8-5.6a.8.8 0 1 1 0 1.6H7.2a.8.8 0 1 1 0-1.6H12zM15.5 0a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9zM9.586 7.2c.267.586.62 1.124 1.04 1.6H7.2a.8.8 0 1 1 0-1.6h2.386zM15.5 2a.5.5 0 0 0-.492.41L15 2.5V4h-1.5a.5.5 0 0 0-.09.992L13.5 5H15v1.5a.5.5 0 0 0 .992.09L16 6.5V5h1.5a.5.5 0 0 0 .09-.992L17.5 4 16 3.999V2.5a.5.5 0 0 0-.5-.5z"
        fillRule="evenodd"
      />
    </svg>
  );
};
