import React from 'react';
import styled from 'styled-components';
import { TCatAssessment } from '../../store/assessments/types';
import { TypeGetCatR } from '../../store/categories/actions';
import { COLORS_RGBA } from '../../utils/colors';
import { VALUES } from '../../utils/valueConst';
import {
  ButtonArrow,
  ButtonMore,
  DeleteSelectItem,
  EditSelectItem,
} from '../ui';
import { AssessmentBox } from './AssessmentBox';

interface CatRowProps {
  title: string;
  id: string;
  description?: string;
  editHandler: () => void;
  deleteHandler: () => void;
  clickHandler: () => void;
  assessments: TCatAssessment[];
  allAssessments: TCatAssessment[];
  type: TypeGetCatR['type'];
}

export const CatRow: React.FC<CatRowProps> = ({
  title,
  description,
  clickHandler,
  assessments,
  allAssessments,
  editHandler,
  deleteHandler,
  type,
  id,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <WrapperS>
      <HeaderS>
        <HeaderTitleS onClick={() => setIsOpen((oldVal) => !oldVal)}>
          <h3>{title}</h3>
        </HeaderTitleS>
        {type === 'default' ? null : (
          <ButtonMore
            onClick={(event) => {
              if (event === VALUES.edit) return editHandler();
              if (event === VALUES.delete) return deleteHandler();
              return null;
            }}
            data={[
              {
                title: <EditSelectItem />,
                id: 1,
                value: VALUES.edit,
              },
              {
                title: <DeleteSelectItem />,
                id: 2,
                value: VALUES.delete,
              },
            ]}
          />
        )}

        <ButtonArrow
          onClick={() => setIsOpen((oldVal) => !oldVal)}
          active={isOpen}
        />
      </HeaderS>
      {isOpen && (
        <BodyWrapperS>
          <DescS>
            <p>{description}</p>
          </DescS>
          <BodyS>
            {assessments.map((itm) => {
              // if (
              //   itm.name.en_US === 'Employee Net Promoter Score (eNPS)' ||
              //   itm.name.en_US === 'Human Capital Yield Baseline'
              // )
              //   return null;

              let ver = '';
              if (
                allAssessments.find(
                  (ass) =>
                    ass.name.en_US === itm.name.en_US &&
                    ass.id !== itm.id &&
                    ass.version
                )
              )
                ver = itm.version;
              return (
                <AssessmentBox
                  type={type}
                  catId={id}
                  catName={title}
                  id={itm.id}
                  key={itm.id}
                  title={itm.name.en_US}
                  questionsCount={itm.number_of_questions}
                  actionClick={clickHandler}
                  version={ver}
                  answer_type={itm.answer_type}
                />
              );
            })}
          </BodyS>
        </BodyWrapperS>
      )}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;
`;

const HeaderS = styled.div`
  display: flex;
  align-items: center;
  background: ${COLORS_RGBA.accent(0.1)};
  padding: 10px 16px;
  border-radius: 8px;

  .btn-more {
    margin-right: 10px;
  }
`;

const BodyWrapperS = styled.div<{ active?: boolean }>``;
const HeaderTitleS = styled.div`
  margin: 0;
  flex: 1;

  h3 {
    margin: 0;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3c3c3c;
  }
`;

const DescS = styled.div``;

const BodyS = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0 -10px;

  & > * {
    margin: 1%;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      border-color: ${COLORS_RGBA.accent(0.5)};
    }
    &:active {
      border-color: #f4f4f4;
    }
  }
`;
