import React from 'react';

export const InfoTooltipIcon = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 7a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
