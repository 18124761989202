import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TAssessmentCards } from './types';

function* getAllAssesmentCards(
  action: ReturnType<typeof Actions.GetAllAssessmentCards.request>
): Generator {
  let success = true;
  let resp = null;
  const { workspaceId, callBack } =
    action.payload as Actions.TypeGetAllAssessmentCardsR;
  try {
    resp = yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.assessmentCards.gAll + '/' + workspaceId,
    });
    yield put(Actions.GetAllAssessmentCards.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllAssessmentCards.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* createAssesmentCards(
  action: ReturnType<typeof Actions.CreateAssessmentCards.request>
): Generator {
  let success = true;
  const { callBack, workspaceId, data } =
    action.payload as Actions.TypeCreateAssessmentCardR;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.assessmentCards.cMultiply(workspaceId),
      data: data,
    })) as TAssessmentCards;
    console.log(resp);
    yield put(Actions.CreateAssessmentCards.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateAssessmentCards.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}
function* deleteAssesmentCard(
  action: ReturnType<typeof Actions.DeleteAssessmentCard.request>
): Generator {
  let success = true;
  let resp = null;
  const { callBack, assessmentCardId } =
    action.payload as Actions.TypeDeleteAssessmentCardR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.assessmentCards.path + '/' + assessmentCardId,
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeleteAssessmentCard.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_CARDS_R, getAllAssesmentCards);
  yield takeEvery(ActionTypes.DELETE_CARDS_R, deleteAssesmentCard);
  yield takeEvery(ActionTypes.CREATE_CARDS_R, createAssesmentCards);
}

export default function* assessmentCardsSaga() {
  yield all([fork(watchFetchRequest)]);
}
