import React from 'react';
import { TEXT } from '../../utils/textConst';

export const NoData: React.FC = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '60px 0 0',
      }}
    >
      <h2>{TEXT.noData}</h2>
    </div>
  );
};
