import React from 'react';
import { ButtonBorderedS } from './styles';

interface ButtonCancelProps {
  onClick: () => void;
  title?: string;
}

export const ButtonCancel: React.FC<ButtonCancelProps> = ({ onClick, title = 'Cancel' }) => {
  return (
    <ButtonBorderedS isCancel onClick={onClick}>
      {title}
    </ButtonBorderedS>
  );
};
