import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../store/applicationState';
import { TCatAssessment } from '../store/assessments/types';
import { updatePackageAss } from '../store/packages/actions';
import { TPackage } from '../store/packages/types';
import { deepClone } from '../utils/deepClone';

export const useUpdateAssInPackage = () => {
  const { Packages } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const updateAssInPackage = (
    newAss: TCatAssessment | null,
    packages?: TPackage[]
  ) => {
    if (!newAss && packages && packages[0])
      return dispatch(updatePackageAss(packages));
    if (!newAss) return null;
    if (!Packages.data || !Packages.data[0] || !newAss) return null;
    const newPackages = deepClone(Packages.data) as TPackage[];

    newPackages.forEach((pack) => {
      if (!pack.assessments || !pack.assessments[0]) return null;
      const ass = pack.assessments.find((assItem) => assItem.id === newAss.id);
      if (!ass) return null;
      ass.number_of_questions = newAss.number_of_questions;
      ass.name = newAss.name.en_US;
      ass.version = newAss.version;
    });

    dispatch(updatePackageAss(newPackages));
  };
  return {
    updateAssInPackage,
  };
};
