import React from 'react';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { useCloseModal } from '../modals/hooks/useCloseModal';
import * as MSt from '../modals/modalStyles';
import { ButtonCancel, ButtonSave } from '../ui';
import { ConsultantItem } from './ConsultantItem';

interface AddNewConsultantsProps {
  data: [];
  closeModal: () => void;
}

export const AddNewConsultants: React.FC<AddNewConsultantsProps> = ({ closeModal }) => {
  const { refInnWrapper, closeModal: closeModalInner } = useCloseModal(closeModal);
  const [activeConsultants, setActiveConsultants] = React.useState<number[]>([]);

  const checkBoxHandler = (isChecked: boolean, id: number) => {
    console.log(111);

    if (!isChecked) {
      setActiveConsultants((oldConsultants) =>
        oldConsultants.filter((itm) => String(itm) !== String(id))
      );
    } else {
      setActiveConsultants((oldConsultants) => [...oldConsultants, id]);
    }
  };

  return (
    <MSt.ModalOuterWrapperS>
      <MSt.ModalInnerWrapperS
        className={AnimatedClasses.zoomIn}
        ref={refInnWrapper}
        style={{ maxWidth: '504px' }}
      >
        <MSt.ModalHeaderS>
          <MSt.ModalTileS>Add new consultants to this workspace</MSt.ModalTileS>
        </MSt.ModalHeaderS>
        <MSt.ModalBodyS>
          <ul style={{ padding: '0' }}>
            <ConsultantItem
              key={1}
              src=""
              name="Jacob Willson"
              isNew
              isActive={activeConsultants.includes(1)}
              checkHandler={(isChecked) => checkBoxHandler(isChecked, 1)}
            />
            <ConsultantItem
              key={2}
              src=""
              name="Jacob Willson 2"
              isNew
              isActive={activeConsultants.includes(2)}
              checkHandler={(isChecked) => checkBoxHandler(isChecked, 2)}
            />
          </ul>
        </MSt.ModalBodyS>
        <MSt.ModalFooterS>
          <MSt.BtnsWrapperS>
            <ButtonCancel onClick={closeModalInner} />
            <ButtonSave onClick={closeModalInner} title="Add" />
          </MSt.BtnsWrapperS>
        </MSt.ModalFooterS>
      </MSt.ModalInnerWrapperS>
    </MSt.ModalOuterWrapperS>
  );
};
