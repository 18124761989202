import React from 'react';

interface MoreIconProps {}

export const MoreIcon: React.FC<MoreIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1280 -146) translate(1080 64) translate(16 76) translate(184 6)">
                  <path
                    fill="#2A2F2A"
                    d="M6 10c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm6 0c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm6 0c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
                    opacity=".3"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
