import React from 'react';
import styled from 'styled-components';
import { CSS_MEDIA } from '../../../utils/media';
interface RespTitleProps {
  title: string;
  alignRight?: boolean;
}

export const RespTitle: React.FC<RespTitleProps> = ({ title, alignRight }) => {
  return <TitleS alignRight={alignRight}>{title}</TitleS>;
};

const TitleS = styled.h2<{ alignRight: boolean }>`
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434352;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  ${CSS_MEDIA.maxMd} {
    font-size: 22px;
  }
`;
