import styled from 'styled-components';
import { COLORS } from '../../../utils/colors';

const B_RAD = '6px';
const PAD_DEF = '14px';
const HEIGHT = '42px';

export const ButtonDefS = styled.button<{
  isSave?: boolean;
  isLoading?: boolean;
}>`
  width: 100%;
  background: ${COLORS.accent};
  color: white;
  border: 0;
  border-radius: ${B_RAD};
  padding: 0 ${PAD_DEF};
  position: relative;
  border: solid 1px ${COLORS.accent};
  height: ${HEIGHT};
  transition: 0.2s;
  min-height: ${HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  .orgvitals-loader {
    transform: scale(0.6) !important;
  }

  &:hover {
    background: ${COLORS.accentHover};
    border: solid 1px ${COLORS.accentHover};
  }

  &:active {
    background: ${COLORS.accentActive};
    border: solid 1px ${COLORS.accentActive};
  }

  ${({ isSave }) =>
    isSave
      ? `
    min-width: 92px;
  `
      : null}

  &:disabled {
    opacity: 0.3;
    cursor: pointer;
  }
`;

export const ButtonBorderedS = styled.button<{
  isCancel?: boolean;
  isDanger?: boolean;
  isYellow?: boolean;
  isLoading?: boolean;
}>`

  ${({ isDanger}) =>
      isDanger ? `border: solid 1px ${COLORS.danger};color: ${COLORS.danger};`: ``
  }
      
  ${({ isCancel}) =>
      isCancel ? 'min-width: 81px;border: solid 1px #d8d8d8;color: #2a2f2a;' : ``
  }
      
  ${({isYellow}) =>
      isYellow ? `border: solid 1px ${COLORS.orangeActive};color: ${COLORS.orangeActive};` : ``
  }
  
  ${({isDanger, isCancel, isYellow}) =>
      !isDanger && !isCancel && !isYellow ? `border: solid 1px #d8d8d8;color: #2a2f2a;` : ``
  }

  width: 100%;
  background: transparent;
  border-radius: ${B_RAD};
  padding: 0 ${PAD_DEF};
  transition: 0.2s;
  min-height: ${HEIGHT};
  position: relative;
  .orgvitals-loader {
    transform: scale(0.6) !important;
  }
   
  &:hover {
    ${({ isDanger}) =>
      isDanger ? `border: solid 1px ${COLORS.dangerActive};`: ``
    }
    
    ${({ isCancel}) =>
      isCancel ? ` border: solid 1px rgba(42, 47, 42, 0.4)` : ``
    }
    
    ${({isYellow}) =>
    isYellow ? ` border: solid 1px ${COLORS.orangeHover}` : ``
    }

    ${({isDanger, isCancel, isYellow}) =>
      !isDanger && !isCancel && !isYellow ? `border: solid 1px ${COLORS.accent};` : ``
    }
  }

  &:active {
    
    ${({ isDanger}) =>
    isDanger ? `border: solid 1px ${COLORS.danger};color: ${COLORS.danger};`: ``
    }
      
      ${({ isCancel}) =>
    isCancel ? `border: solid 1px rgba(42, 47, 42, 0.6);color:rgba(42, 47, 42, 1);` : ``
    }
      
      ${({isYellow}) =>
    isYellow ? `border: solid 1px ${COLORS.orangeActive};color: ${COLORS.orangeActive};` : ``
    }
  
      ${({isDanger, isCancel, isYellow}) =>
    !isDanger && !isCancel && !isYellow ? `border: solid 1px ${COLORS.accentActive}; background: ${COLORS.accentActive};color: white;` : ``
    }
    
    svg { 
      ${({ isDanger}) =>
        isDanger ? `opacity: 0.7;`: ``
      }
      
      ${({ isCancel}) =>
        isCancel ? `opacity: 1;` : ``
      }
      
      ${({isYellow}) =>
        isYellow ? `opacity: 0.7;` : ``
      }
  
      ${({isDanger, isCancel, isYellow}) =>
        !isDanger && !isCancel && !isYellow ? `opacity: 0.7;` : ``
      }
      
      g {
        
        ${({ isDanger}) =>
          isDanger ? `fill: ${COLORS.danger};`: ``
        }
      
        ${({ isCancel}) =>
          isCancel ? `fill: rgba(42, 47, 42, 0.6);` : ``
        }
      
        ${({isYellow}) =>
          isYellow ? `fill: ${COLORS.orangeActive};` : ``
        }
  
        ${({isDanger, isCancel, isYellow}) =>
          !isDanger && !isCancel && !isYellow ? `fill: #fff;` : ``
        }
      }
    }
  }

  &:disabled {
    border: solid 1px #d8d8d8 !important;
    cursor: default;
    opacity: 0.2;
  }
`;
export const ButtonLinkS = styled.button`
  display: inline-flex;
  background: none;
  color: ${COLORS.accent};
  border: 0;
  border-radius: 6px;
  padding: 0;
  margin-left: 20px;
`;
