import { TMergeModel } from '../hrisIntegrations/types';
import {TFinchIntegrations} from "../finchIntegrations/types";

export enum EAdditionalInfoText {
  formation_type = 'Workspace type',
  group_type = 'Type of group',
  // group_size = 'Group size',
  organization_size = 'Organization size',
  organization_industry = 'Organization industry',
  organization_geographic_structure = 'Organization geographic structure',
  organization_remote_work = 'Organization remote work',
  organization_location = 'Organization location',
}
export interface TAdditionalInfo {
  formation_type: string;
  group_type: string;
  organization_size: string;
  organization_industry: string;
  organization_geographic_structure: string;
  organization_remote_work: string;
  organization_location: string;
  main_contact?: TMainContact;
}

export interface TMainContact {
  first_name: string;
  last_name: string;
  phone_code: string;
  phone: string;
  email: string;
  site_link: string;
}
export interface TWorkspace {
  id: string;
  name: string;
  group_classification: string;
  created_by_id: string;
  created_at: Date;
  updated_at: Date;
  team_id: string;
  additional_info: TAdditionalInfo;
  logo_url: string;
  collaboration_link: string;
  finch_integration: TFinchIntegrations[];
  merge_models: TMergeModel[];
}

export interface TWorkspacesState {
  readonly loading: boolean;
  data: TWorkspace[] | null;
  current: TWorkspace | null;
  readonly errors?: string | undefined;
}

export const ACTION_CONST = {
  getByTeam: 'GET_BY_TEAM',
  create: 'CREATE',
  delete: 'DELETE',
  move: 'MOVE',
  edit: 'EDIT',
};

enum ActionTypes {
  GET_BY_TEAM_R = '@@workspaces/GET_BY_TEAM_R',
  GET_BY_TEAM_S = '@@workspaces/GET_BY_TEAM_S',
  GET_BY_TEAM_E = '@@workspaces/GET_BY_TEAM_E',

  CREATE_R = '@@workspaces/CREATE_R',
  CREATE_S = '@@workspaces/CREATE_S',
  CREATE_E = '@@workspaces/CREATE_E',

  DELETE_R = '@@workspaces/DELETE_R',
  DELETE_S = '@@workspaces/DELETE_S',
  DELETE_E = '@@workspaces/DELETE_E',

  EDIT_R = '@@workspaces/EDIT_R',
  EDIT_S = '@@workspaces/EDIT_S',
  EDIT_E = '@@workspaces/EDIT_E',

  MOVE_R = '@@workspaces/MOVE_R',
  MOVE_S = '@@workspaces/MOVE_S',
  MOVE_E = '@@workspaces/MOVE_E',

  SET_WORKSPACE = '@@workspaces/SET_WORKSPACE',

  CLEAN_UP = '@@workspaces/CLEAN_UP',
}

export default ActionTypes;
