import React from 'react';
import { ScheduleIcon } from '../../svgs/ScheduleIcon';
import { WrapperItemSelectS } from './styles';

interface ScheduleSelectItemProps {
  title?: string;
}

export const ScheduleSelectItem: React.FC<ScheduleSelectItemProps> = ({
  title,
}) => {
  return (
    <WrapperItemSelectS>
      <ScheduleIcon opacity={'0.3'} />
      {title}
    </WrapperItemSelectS>
  );
};
