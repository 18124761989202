import { QuestionsType } from '.';
import { EOrigin, TCatAssessment } from '../../../../store/assessments/types';
import { TCat } from '../../../../store/categories/types';
import { deepClone } from '../../../../utils/deepClone';
import { ICbAss } from './types';

interface IRes extends ICbAss {
  questions: QuestionsType[];
}
export const callBackCreate = (
  res: IRes
): null | {
  newCasts: IRes['cats'];
} => {
  const { success, data, cats, name, questions } = res;

  if (!success) return null;
  if (!data || (data && !data.id) || (data && !data.category_id)) {
    console.log(success, 'success');
    console.error(data, 'data');
    return null;
  }

  if (!cats || !Array.isArray(cats)) {
    console.error(cats, 'cats');
    return null;
  }

  const newCasts = deepClone(cats) as TCat[];
  const cat = newCasts.find((c) => `${c.id}` === `${data.category_id}`);

  if (!cat) {
    console.warn('Could not find cat callBack submitHandler');
    return null;
  }
  const origin: TCatAssessment['origin'] = EOrigin.custom;
  const newAss = {
    id: data.id,
    number_of_questions: questions.length,
    name: {
      en_US: name,
    },
    version: data.version,
    answer_type: data.answer_type,
    origin,
    category_id: data.category_id,
    category_name: data.catName,
  };
  cat.assessments =
    cat.assessments && cat.assessments[0]
      ? [newAss, ...cat.assessments]
      : [newAss];

  return {
    newCasts,
  };
};
