import React from 'react';
import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../utils/colors';

interface TabsProps {
  data: {
    title: string;
    id: string;
  }[];
  onClick: (id: string) => void;
  activeTab: string;
  isUnderline?: boolean;
  isAlignLeft?: boolean;
}

export const Tabs: React.FC<TabsProps> = ({
  data,
  activeTab,
  onClick,
  isUnderline,
  isAlignLeft,
}) => {
  return (
    <WrapperS isUnderline={isUnderline} isAlignLeft={isAlignLeft}>
      {data &&
        data[0] &&
        data.map((tab) => (
          <TabS
            isUnderline={isUnderline}
            key={tab.id}
            onClick={() => (activeTab !== tab.id ? onClick(tab.id) : null)}
            active={activeTab === tab.id}
          >
            {tab.title}
          </TabS>
        ))}
    </WrapperS>
  );
};

const WrapperS = styled.div<{ isUnderline?: boolean; isAlignLeft?: boolean }>`
  display: flex;
  ${({ isAlignLeft }) => {
    if (isAlignLeft) {
      return ``;
    }
    return `justify-content: space-between;`;
  }}
  ${({ isUnderline }) => {
    if (isUnderline) {
      return `
          border-bottom: solid 1px ${COLORS_RGBA.default(0.2)};
        `;
    }
    return ``;
  }}
`;
const TabS = styled.div<{ active?: boolean; isUnderline?: boolean }>`
  cursor: pointer;
  flex: 1;
  text-align: center;

  padding: 7px 10px;
  white-space: nowrap;
  border-right-width: 0;

  ${({ active, isUnderline }) => {
    if (isUnderline && active) {
      return `
        color:  ${COLORS_RGBA.accent()};
        border-bottom: solid 1px ${COLORS_RGBA.accent()};
        position: relative;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          bottom: -1px;
          left: 0;
          position: absolute;
          background: ${COLORS_RGBA.accent()};
        }
      `;
    }

    if (active) {
      return `
      background: ${COLORS_RGBA.accent()};
      color: #fff;
      border-color: ${COLORS_RGBA.accent()};
      cursor: default;
      `;
    }
    return `
      &:hover {
        background: ${COLORS_RGBA.accent(0.1)};
      }
      &:active {
        background: ${COLORS.accentActive};
        color: #fff;
      }
    `;
  }}

  ${({ isUnderline }) => {
    if (isUnderline) {
      return `
        border-bottom: solid 0 transparent;
        flex: 0;
        &:first-child {
         padding-left: 0;
   
        }      
        &:last-child {
         padding-right: 0;
    
        }
        
      &:hover {
          background: transparent;
          color: ${COLORS_RGBA.accent(1)};
      }
        &:active {
          color: ${COLORS.accentActive};
          background: transparent;
        }
      `;
    }
    return `
      border: solid 1px ${COLORS_RGBA.default(0.2)};
      
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-right-width: 1px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
     `;
  }}
`;
