import React from 'react';
import {useSelector} from 'react-redux';
import {AppStore} from '../../store/applicationState';
import {EQuestionType, TAssessment} from '../../store/assessments/types';
import {AnimatedClasses} from '../../utils/animatedStyles';
import {SELECT_TYPES} from '../../utils/consts';
import {Alert, DefaultSelect, Input, Loader, TextArea,} from '../ui';
import {ButtonBorderedS, ButtonDefS} from '../ui/buttons/styles';
import {SelectItemType} from '../ui/selects/DefaultSelect';
import {useNewAssessment} from './hooks/useNewAssessment';
import * as Styles from './modalStyles';
import {closeModal} from './utils/closeModal';
import {LinkSurveyRow} from "../Assessments/LinkSurveyRow";

interface NewLinkedSurveyProps {
    editMode?: boolean;
    assessment?: TAssessment;
    closeHandler: () => void;
}

export const NewLinkedSurvey: React.FC<NewLinkedSurveyProps> = ({
    closeHandler,
    assessment,
    }) => {
    const { Cats, User, Packages } = useSelector((store: AppStore) => store);
    const selectData: SelectItemType[] | null =
        Cats.data &&
        Cats.data.map((itm) => ({
            title: itm.name,
            value: itm.id,
            id: itm.id,
        }));

    const refInnWrapper = React.useRef<HTMLDivElement>(null);
    const newAssData = useNewAssessment(
        closeHandler,
        User.data?.team_id || '',
        Cats.data || [],
        assessment,
        Packages.data || [],
        EQuestionType.linked_survey
    );

    const closeModalHandler = () => {
        if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
    };

    React.useEffect(() => {
        if (assessment && Cats.data) {
            const cat = Cats.data.find((c) => c.id === assessment.category_id);
            if (cat) {
                newAssData.setCat({
                    id: cat.id,
                    value: cat.id,
                    title: cat.name,
                });
            }

            const type = SELECT_TYPES.find((t) => t.value === assessment.type);

            if (type) {
                newAssData.setTypeSelect(type);
            }
        }

        if (!assessment) {
            newAssData.addQuestionLinkSurvey()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessment, Cats.data]);
    return (
        <Styles.ModalOuterWrapperS>
            <Styles.ModalInnerWrapperS
                style={{ maxWidth: '768px' }}
                ref={refInnWrapper}
                className={AnimatedClasses.zoomIn}
            >
                <Styles.ModalHeaderS
                    style={{
                        background: '#f6f8f6',
                        marginBottom: '20px',
                        paddingBottom: '20px',
                    }}
                >
                    <Styles.ModalTileS>
                        {assessment ? 'Edit Linked Survey' : 'Create New Linked Survey'}
                    </Styles.ModalTileS>
                    <Input
                        onChange={newAssData.onChangeHandler}
                        value={newAssData.form[newAssData.TITLE].value}
                        errorText={newAssData.form[newAssData.TITLE].errorText}
                        name={newAssData.TITLE}
                        placeholder="Type Linked Survey title here"
                        autoFocus
                    />
                </Styles.ModalHeaderS>
                <Styles.ModalBodyS>
                    <form>
                        <Styles.RowWrapperS>
                            <DefaultSelect
                                label="Category"
                                placeHolder="Choose Category"
                                data={selectData || []}
                                onChange={(newCat) => newAssData.setCat(newCat)}
                                selected={newAssData.cat}
                            />
                            <DefaultSelect
                                label="Type"
                                placeHolder="Choose Type"
                                data={SELECT_TYPES}
                                onChange={newAssData.setTypeSelect}
                                selected={newAssData.typeSelect}
                            />
                        </Styles.RowWrapperS>
                        {/* <Input
              stylesWrapper={{ marginTop: '20px' }}
              onChange={newAssData.onChangeHandler}
              value={newAssData.form[newAssData.CODE].value}
              name={newAssData.CODE}
              readOnly
              errorText={newAssData.form[newAssData.CODE].errorText}
              label="Assessment Code"
              placeholder="Code"
            /> */}
                        <TextArea
                            stylesWrapper={{ marginTop: '20px' }}
                            onChange={newAssData.onChangeHandler}
                            value={newAssData.form[newAssData.DESC].value}
                            name={newAssData.DESC}
                            label="Linked Survey Description"
                            placeholder="Description"
                        />
                    </form>
                    {newAssData.questions.map((q, i) => (
                        <LinkSurveyRow
                            key={q.id}
                            value={q.title}
                            position={i}
                            onChange={(e) =>
                                newAssData.titleHandler({
                                    title: e.currentTarget.value || '',
                                    position: i,
                                    id: q.id,
                                })
                            }
                            placeHolder={'eg. https://www.orgvitals.com'}
                        />
                    ))}
                </Styles.ModalBodyS>
                {Cats.errors || newAssData.warning ? (
                    <div style={{ padding: '32px' }}>
                        <Alert text={Cats.errors || newAssData.warning} />
                    </div>
                ) : null}

                <Styles.ModalFooterS>
                    <Styles.BtnsWrapperS>
                        <ButtonBorderedS isCancel onClick={closeModalHandler}>
                            Cancel
                        </ButtonBorderedS>
                        <ButtonDefS
                            isSave
                            onClick={Cats.loading ? () => null : newAssData.submitLinkedSurveyHandler}
                        >
                            {Cats.loading ? <Loader /> : 'Save'}
                        </ButtonDefS>
                    </Styles.BtnsWrapperS>
                </Styles.ModalFooterS>
            </Styles.ModalInnerWrapperS>
        </Styles.ModalOuterWrapperS>
    );
};
