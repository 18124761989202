import React from 'react';

interface DownloadIconProps {}

export const DownloadIcon: React.FC<DownloadIconProps> = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 10a1 1 0 0 1 1 1v4a1 1 0 0 1-.883.993L15 16H1a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v3h12v-3a1 1 0 0 1 1-1zm-7.707.69L4.33 7.727a1 1 0 0 1 1.414-1.414L7 7.569V1a1 1 0 1 1 2 0v6.604l1.293-1.291a1 1 0 0 1 1.32-.084l.094.084a1 1 0 0 1 0 1.414L8.744 10.69a.996.996 0 0 1-.648.291H7.94a.996.996 0 0 1-.648-.291z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
};
