import React from 'react';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { RespTitle } from './components/RespTitle';
import { RespBtnS } from './styles';
import { TLang } from '../../types/lang';
import { checkLang } from './assLang';
import { langIsAllingRight } from '../../utils/langIsEng';

interface ScreenThanksProps {
  backToList: () => void;
  lang: TLang;
}

export const ScreenThanks: React.FC<ScreenThanksProps> = ({
  backToList,
  lang,
}) => {
  return (
    <div className={AnimatedClasses.zoomIn} style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }}>
      <RespTitle
        title={checkLang(lang).assessments.thankForResp}
        alignRight={langIsAllingRight(lang)}
      />
      <RespBtnS onClick={backToList} alignRight={langIsAllingRight(lang)}>
        {checkLang(lang).assessments.goToAss}
      </RespBtnS>
    </div>
  );
};
