import React from 'react';

interface ExclamationIconProps {
  color?: string;
}

export const ExclamationIcon: React.FC<ExclamationIconProps> = ({
  color = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="mdi-exclamation"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z"
      />
    </svg>
  );
};
