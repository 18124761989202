import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TFinchIntegrations } from './types';

function* getAllFinchIntegrations(
  action: ReturnType<typeof Actions.GetAllFinchIntegrations.request>
): Generator {
  let success = true;
  let resp = null;
  const { workspaceId, callBack } = action.payload as Actions.TypeGetAllR;
  try {
    resp = yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.finchIntegrations.gAll(workspaceId),
    });
    yield put(Actions.GetAllFinchIntegrations.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllFinchIntegrations.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* createFinchIntegration(
  action: ReturnType<typeof Actions.CreateFinchIntegration.request>
): Generator {
  let success = true;

  const { callBack, data, workspaceId } = action.payload as Actions.TypeCreateR;
  console.log(data)
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.finchIntegrations.retriveAccToken(workspaceId),
      data: data,
    })) as TFinchIntegrations;
    yield put(Actions.CreateFinchIntegration.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateFinchIntegration.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}
function* deleteFinchIntegration(
  action: ReturnType<typeof Actions.DeleteFinchIntegration.request>
): Generator {
  let success = true;
  let resp = null;
  const { callBack } = action.payload as Actions.TypeDeleteR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: '',
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeleteFinchIntegration.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_R, getAllFinchIntegrations);
  yield takeEvery(ActionTypes.DELETE_R, deleteFinchIntegration);
  yield takeEvery(ActionTypes.CREATE_R, createFinchIntegration);
}

export default function* finchIntegrationsSaga() {
  yield all([fork(watchFetchRequest)]);
}
