import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

interface TabsProps {
  onClick: (id: number) => void;
  activeId: number;
}
export enum EBtnsSettings {
  info = 1,
  additionalInfo = 2,
  consultants = 3,
  surveyExperience = 4,
  hrisIntegrations = 5,
}
export const btns = [
  { title: 'Info', id: EBtnsSettings.info },
  { title: 'Additional Info', id: EBtnsSettings.additionalInfo },
  { title: 'Members', id: EBtnsSettings.consultants },
  { title: 'Survey Experience', id: EBtnsSettings.surveyExperience },
  { title: 'HRIS Integrations', id: EBtnsSettings.hrisIntegrations },
];

export const Tabs: React.FC<TabsProps> = React.memo(({ onClick, activeId }) => {
  return (
    <WrapperS>
      {btns.map(({ id, title }) => (
        <BtnS key={id} active={activeId === id} onClick={() => onClick(id)}>
          {title}
        </BtnS>
      ))}
    </WrapperS>
  );
});

const WrapperS = styled.div`
  display: flex;
  margin-bottom: 24px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const BtnS = styled.button<{ active?: boolean }>`
  padding: 10px 0;
  margin-right: 24px;
  background: none;
  border: 0;
  outline: none;
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  transition: 0.1s;
  &:hover {
    color: ${COLORS.accentHover};
  }
  &:active {
    color: ${COLORS.accentActive};
  }

  ${({ active }) =>
    active
      ? `
    border-bottom: 1px solid ${COLORS.accent};
    color: ${COLORS.accent};
  `
      : `
  border-bottom: 1px solid transparent;


  `}
`;
