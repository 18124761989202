import React from 'react';
import { TParticipant } from '../../store/groups/types';
import { getCutText } from '../../utils/helpers';
import { VALUES } from '../../utils/valueConst';
import {
  ButtonArrow,
  ButtonMore,
  DeleteSelectItem,
  EditSelectItem,
} from '../ui';
import { ISelectItemData } from '../ui/buttons/types';
import { GroupParticipant } from './GroupPrticipant';
import { useDndSideBar } from './hooks/useDndSideBar';
import { useGroupSection } from './hooks/useGroupSection';
import * as Styles from './styles/GroupSectionStyles';
import styled from "styled-components";

interface GroupSectionProps {
  title: string;
  id: string;
  data: TParticipant[];
  groupIsLoading?: boolean;
  isNotDeletable?: boolean;
  groupPosition: number;
  removeGroup: () => void;
  editGroup: () => void;
  groupIsDefault?: boolean;
  isCustom: boolean;
}

export const GroupSection: React.FC<GroupSectionProps> = ({
  title,
  data,
  id,
  removeGroup,
  editGroup,
  groupPosition,
  groupIsLoading,
  isNotDeletable,
  groupIsDefault,
  isCustom,
}) => {
  const {
    isVisible,
    setIsVisible,
    deleteGroupAnimationHandler,
    groupRef,
  } = useGroupSection({ groupId: id, groupPosition });
  const { isOver, drop } = useDndSideBar(id);
  const cutTitle = getCutText(title, 20);
  const renderUsers = () => {
    if (!isVisible) return <></>;

    return (
      data &&
      data.map((participant, i) => {
        return (
          <GroupParticipant
            key={participant.id}
            participant={participant}
            groupId={id}
            groupPosition={groupPosition}
            position={i}
            groupIsLoading={groupIsLoading}
          />
        );
      })
    );
  };

  const dragHere = (
    <Styles.WrapperItemDragS ref={drop} active={isOver}>
      Drag participant here
    </Styles.WrapperItemDragS>
  );

  const btnMoreHandler = (value: ISelectItemData['value']) => {
    if (value === VALUES.delete)
      return deleteGroupAnimationHandler(removeGroup);
    if (value === VALUES.edit) return editGroup();
    return null;
  };

  return (
    <WrapperS ref={groupRef} isCustom={isCustom}>
      <Styles.HeaderS>
        <TitleS isCustom={isCustom}>{cutTitle}</TitleS>
        <ButtonMore
          hidden={groupIsDefault}
          onClick={btnMoreHandler}
          data={
            isNotDeletable
              ? [
                  {
                    title: <EditSelectItem />,
                    value: VALUES.edit,
                    id: 1,
                  },
                ]
              : [
                  {
                    title: <EditSelectItem />,
                    value: VALUES.edit,
                    id: 1,
                  },
                  {
                    title: <DeleteSelectItem />,
                    value: VALUES.delete,
                    id: 2,
                  },
                ]
          }
        />
        <ButtonArrow
          onClick={() => setIsVisible(!isVisible)}
          active={isVisible}
        />
      </Styles.HeaderS>
      <Styles.BodyS>
        {renderUsers()}
        {isVisible ? dragHere : null}
      </Styles.BodyS>
    </WrapperS>
  );
};

export const WrapperS = styled.div<{ isCustom: boolean }>`
  padding: ${({ isCustom }) => (isCustom ? '23px 0' : '15px 0')};
  // border-radius: 6px;
  border-bottom: ${({ isCustom }) => (isCustom ? '1px solid rgba(0, 0, 0, 0.12)' : '')};
`;

export const HeaderS = styled.header`
  display: flex;
  align-items: end;
  /* justify-content: space-between; */
  .btn-arrow {
    margin-left: 12px;
  }
`;


export const TitleS = styled.h3<{ isCustom: boolean }>`
  flex: 1;
  color: #3c3c3c;
  font-size: ${({ isCustom }) => (isCustom ? '1.6rem' : '1.4rem')};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;
