import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { IMatchingTemplates } from './types';

function* getMatchingTemplate(action: ReturnType<typeof Actions.getMatchingTemplateR>): Generator {
  let success = true;
  try {
    const workspaceId = action.payload.workspaceId;
    const current = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.matchingTemplates.gOne(workspaceId),
    })) as IMatchingTemplates;

    yield put(Actions.getMatchingTemplateS(current));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.getMatchingTemplateE(e));
  } finally {
    action.payload.callBack && action.payload.callBack(success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ONE_R, getMatchingTemplate);
}

export default function* matchingTemplatesSaga() {
  yield all([fork(watchFetchRequest)]);
}
