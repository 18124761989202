import React from 'react';

interface GifIconProps {}

export const GifIcon: React.FC<GifIconProps> = () => {
  return (
    <svg
      width="32"
      height="41"
      viewBox="0 0 32 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.176.037H5a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h22a5 5 0 0 0 5-5V7.865a3 3 0 0 0-.878-2.12L26.298.915a3 3 0 0 0-2.122-.88zM2 27.037h28v-17h-4c-2.21 0-4-1.784-4-3.995V2.038H5c-1.657 0-3 1.345-3 3.003v21.996zM24 2.038v4.004c0 1.106.895 1.995 2 1.995h4v-.165c0-.266-.105-.52-.293-.708l-4.824-4.832a1 1 0 0 0-.707-.294H24zM9.769 21.925c.507.996 1.678 1.367 2.615.827l9.141-4.947c.33-.19.6-.478.778-.828.507-.996.16-2.242-.778-2.782l-9.141-4.947A1.838 1.838 0 0 0 11.465 9c-1.065 0-1.929.919-1.929 2.052v9.895c0 .342.08.678.233.978zM11.672 21V11l9 4.996-9 5.004zm2.524 12.042a.499.499 0 0 0-.356-.124h-1.368a.529.529 0 0 0-.36.112c-.085.075-.128.182-.128.32 0 .14.043.246.128.32a.529.529 0 0 0 .36.113h.736v1.241c-.304.08-.61.12-.92.12-.57 0-.997-.16-1.28-.48-.283-.32-.424-.806-.424-1.458 0-1.265.544-1.897 1.632-1.897.224 0 .43.029.616.088.187.058.392.157.616.296.08.048.148.083.204.104a.525.525 0 0 0 .188.032c.107 0 .199-.05.276-.148a.56.56 0 0 0 .116-.356.536.536 0 0 0-.048-.24.603.603 0 0 0-.168-.193c-.485-.384-1.099-.576-1.84-.576-.576 0-1.08.117-1.512.352a2.41 2.41 0 0 0-.996 1.005c-.232.435-.348.946-.348 1.533 0 .598.117 1.115.352 1.55a2.4 2.4 0 0 0 1.008 1c.437.233.955.349 1.552.349a5.58 5.58 0 0 0 1.48-.208c.17-.048.299-.107.384-.176a.552.552 0 0 0 .18-.28c.035-.118.052-.284.052-.497v-1.562a.445.445 0 0 0-.132-.34zm1.856 2.87a.615.615 0 0 0 .456.169.615.615 0 0 0 .456-.168.617.617 0 0 0 .168-.457v-4.492a.606.606 0 0 0-.168-.448.616.616 0 0 0-.456-.168.616.616 0 0 0-.456.168.606.606 0 0 0-.168.448v4.492c0 .193.056.345.168.457zm3.324.169a.61.61 0 0 1-.452-.172.612.612 0 0 1-.172-.453v-4.484c0-.187.05-.33.152-.432.101-.101.245-.152.432-.152h2.752c.203 0 .352.04.448.12.096.08.144.205.144.376 0 .166-.048.288-.144.369-.096.08-.245.12-.448.12h-2.104v1.297h1.944c.203 0 .352.04.448.12.096.08.144.206.144.376 0 .166-.048.289-.144.369-.096.08-.245.12-.448.12h-1.944v1.801a.626.626 0 0 1-.164.457.591.591 0 0 1-.444.168z"
        fill="#9A4AE6"
      />
    </svg>
  );
};
