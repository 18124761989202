import React from 'react';

interface MoveIconProps {}

export const MoveIcon: React.FC<MoveIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" opacity=".3">
        <g fill="#000">
          <g>
            <g>
              <path
                d="M21.35 15c.41 0 .794.193 1.04.52L23.5 17H28c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H18c-1.657 0-3-1.343-3-3V18c0-1.657 1.343-3 3-3h3.35zM21 17h-3c-.552 0-1 .448-1 1v10c0 .552.448 1 1 1h10c.552 0 1-.448 1-1v-8c0-.552-.448-1-1-1h-4.85c-.41 0-.794-.193-1.04-.52L21 17zm2 4c.552 0 1 .448 1 1v1h1c.552 0 1 .448 1 1s-.448 1-1 1h-1v1c0 .552-.448 1-1 1s-1-.448-1-1v-1h-1c-.552 0-1-.448-1-1s.448-1 1-1h1v-1c0-.552.448-1 1-1z"
                transform="translate(-1036 -294) translate(1020 229) translate(1 50)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
