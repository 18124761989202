import { API_ROUTE_PATH } from '../../utils/api_routes';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import * as Actions from './actions';
import ActionTypes, { TTeam } from './types';
import { setInfoModal } from '../modal/actions';

function* getMy(
  action: ReturnType<typeof Actions.GetMyTeam.request>
): Generator {
  const { callBack } = action.payload as Actions.TGet_MY_TEAM_R;
  let success = true;
  try {
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.teams.gMy,
    })) as TTeam;

    yield put(Actions.GetMyTeam.success(data));
  } catch (e) {
    success = false;
    yield put(Actions.GetMyTeam.error(e));
  } finally {
    callBack && callBack(success);
  }
}
function* edit(action: ReturnType<typeof Actions.UpdateTeam.request>) {
  const { data, id, callBack, src } = action.payload as Actions.TUpdateTeamR;
  let success = true;
  const sendData = {
    name: data.name,
    is_logo_changed: data.is_logo_changed,
  };
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file || '');
  bodyFormData.append('fields', JSON.stringify(sendData));
  try {
    const team = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.teams.path + '/' + id,
      data: bodyFormData,
    })) as TTeam;
    team.logo_url = src;
    yield put(Actions.UpdateTeam.success(team));
  } catch (e) {
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.UpdateTeam.error(e));
    success = false;
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_MY_R, getMy);
  yield takeEvery(ActionTypes.EDIT_R, edit);
}
export default function* teamSaga() {
  yield all([fork(watchFetchRequest)]);
}
