import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { DeleteAttachment } from '../../store/attachments/actions';
import { TAttachment } from '../../store/attachments/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { deepClone } from '../../utils/deepClone';
import { CloseIcon, WrappedLinkIcon } from '../svgs';
import { FileIcons } from './FileIcons';

interface AttachmentProps {
  attachment: TAttachment;
}
export const Attachment: React.FC<AttachmentProps> = ({ attachment }) => {
  const dispatch = useDispatch();
  const { Attachments } = useSelector((store: AppStore) => store);
  return (
    <WrapperS>
      <LinkWrapperS
        onClick={async () => {
          if (attachment.object_type === 'file') {
            let resp = null;
            try {
              resp = (await callApi({
                method: 'get',
                path: API_ROUTE_PATH.attachments.getUrl(attachment.id),
              })) as { name: string; url: string; url_expiration: string };
            } catch (e) {
              console.log(e);
            } finally {
              const a = document.createElement('a');
              a.setAttribute('href', resp.url);
              a.setAttribute('download', resp.name);
              a.setAttribute('target', '_blank');
              document.body.appendChild(a);
              a.click();
              a.remove();
            }
          } else if (attachment.object_type === 'link') {
            const a = document.createElement('a');
            a.setAttribute('href', attachment.object.url);
            a.setAttribute('target', '_blank');
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        }}
      >
        {attachment.object_type && attachment.object_type === 'link' ? (
          <WrappedLinkIcon />
        ) : (
          <FileIcons
            fileName={attachment.object_label}
            extention={attachment.object.extension}
          />
        )}
        <TextWrapperS>
          <span
            style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}
          >
            {attachment.object_label}
          </span>
          {/* <span style={{ fontSize: '10px' }}>Added 2m ago</span> */}
        </TextWrapperS>
      </LinkWrapperS>
      <DeleteButtonS
        onClick={() => {
          const callBack = (success: boolean) => {
            if (!success) return null;
            if (!Attachments.data) {
              console.warn('Attachments.data does not exist');
              return null;
            }
            const data = deepClone(Attachments.data) as TAttachment[];
            const newData = data.filter((a) => a.id !== attachment.id);
            dispatch(DeleteAttachment.success(newData));
          };
          dispatch(
            DeleteAttachment.request({ attachmentId: attachment.id, callBack })
          );
        }}
      >
        <CloseIcon />
        <span>Delete</span>
      </DeleteButtonS>
    </WrapperS>
  );
};
const WrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  svg {
    height: 28px;
    width: auto;
  }
`;
const LinkWrapperS = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TextWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const DeleteButtonS = styled.button`
  height: 100%;
  border: none;
  background: transparent;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 10px;
    height: 15px;
  }
`;
