import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AddMonitorCards } from '../components';
import { HomeInfoCard, RightSideBar } from '../components/Home';
import { AddMonitorCardButton } from '../components/Home/AddMonitorCardButton';
import { MonitorCard } from '../components/Home/MonitorCard';
import { Loader } from '../components/ui';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { GetAllAssessmentCards } from '../store/assessmentCards/actions';
import { HEADER_HEIGHT, SIDE_BAR_WIDTH } from '../utils/config';

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const { Workspaces, AssessmentCards } = useSelector(
    (store: AppStore) => store
  );
  const [showAddMonitorCard, setShowAddMonitorCard] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!Workspaces.loading && Workspaces.current) {
      dispatch(
        GetAllAssessmentCards.request({ workspaceId: Workspaces.current.id })
      );
    }
  }, [dispatch, Workspaces]);
  return (
    <WrapperS>
      <h1>Home</h1>
      <HomeInfoCard />
      <RightSideBar />
      <CardWrapperS>
        {AssessmentCards.loading && <Loader isGreen />}
        {AssessmentCards.data && (
          <>
            {AssessmentCards.data.map((card) => {
              return <MonitorCard card={card} key={card.id} />;
            })}
            <AddMonitorCardButton
              onClick={async () => {
                setShowAddMonitorCard(true);
              }}
            />
            {!AssessmentCards.data[0] && (
              <TextS>Please, create monitor cards for assessments</TextS>
            )}
          </>
        )}
      </CardWrapperS>

      {showAddMonitorCard && (
        <AddMonitorCards
          closeHandler={() => {
            setShowAddMonitorCard(false);
          }}
        />
      )}
    </WrapperS>
  );
};
const WrapperS = styled(MainWrapperS)`
  padding: calc(${HEADER_HEIGHT} + 26px) calc(${SIDE_BAR_WIDTH} + 24px);
`;
const CardWrapperS = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
`;
const TextS = styled.p`
  margin: 0 24px 24px 0;
  font-size: 16px;
  font-weight: 600;
  max-width: 200px;
`;
