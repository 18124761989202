import React from 'react';
import styled from 'styled-components';

interface EnvProps {}

export const Env: React.FC<EnvProps> = () => {
  return <WrapperS>App version: {process.env.REACT_APP_VERSION}</WrapperS>;
};

const WrapperS = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  color: white;
  font-size: 1.1rem;
`;
