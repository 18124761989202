import React from 'react';
import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import { CheckIcon } from '../../svgs';

interface CollabSelectProps {
  isCheck?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: () => void;
}
export const CollabSelect: React.FC<CollabSelectProps> = ({
  isCheck,
  onChange,
  handleClick,
}) => {
  return (
    <LabelS>
      <input
        type="checkbox"
        onChange={onChange}
        checked={isCheck}
        onClick={handleClick}
      />
      <span>{isCheck ? <CheckIcon /> : null}</span>
    </LabelS>
  );
};

const LabelS = styled.label`
  display: block;
  cursor: pointer;
  margin-right: 10px;
  input {
    display: none;
  }

  span {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: solid 1px ${COLORS_RGBA.default(0.12)};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 35px;
      height: 32px;
      path {
        fill: #fff;
      }
    }
  }

  &:hover {
    span {
      border-color: ${COLORS.accent};
    }
  }
  &:active {
    span {
      border-color: ${COLORS.accentActive};
    }
  }

  input:checked ~ span {
    background: ${COLORS.accent};
  }
`;
