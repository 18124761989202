import React from 'react';
import { TGroup } from '../../../store/groups/types';

export const useGroupOriginSourceSection = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<TGroup[]>([]);

  return {
    items,
    setItems,
    isVisible,
    setIsVisible,
    setIsLoading,
    isLoading,
  };
};
