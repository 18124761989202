import React from 'react';
import styled from 'styled-components';
import { TUser } from '../../store/user/types';
import { AvatarIcon } from '../svgs';
import { Alert, ButtonSave, Input } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import { useMyAccountInfo } from './hooks/useMyAccountInfo';

interface ProfileProps {
  user: TUser;
}

export const Profile: React.FC<ProfileProps> = ({ user }) => {
  const hookMyAccountInfo = useMyAccountInfo();

  return (
    <>
      {hookMyAccountInfo.errors && <Alert text={hookMyAccountInfo.errors} />}
      <WrapperS>
        <WrapperImageTextS>
          <ImageWrapperS style={{ backgroundColor: 'white' }}>
            <AvatarIcon />
            {hookMyAccountInfo.image.src !== '' && (
              <ImageS
                style={{
                  backgroundImage: `url("${hookMyAccountInfo.image.src}")`,
                }}
              />
            )}
          </ImageWrapperS>
          <TextWrapperS>
            <p>Profile picture</p>
            <input
              ref={hookMyAccountInfo.refInputUpload}
              onChange={() => {
                hookMyAccountInfo.onChangeUploadHandler(
                  hookMyAccountInfo.updateImageHandler
                );
              }}
              type="file"
              style={{ display: 'none' }}
            />
            <BtnsWrapperS>
              <ButtonBorderedS onClick={hookMyAccountInfo.clickUploadHandler}>
                Upload
              </ButtonBorderedS>
              {hookMyAccountInfo.image.src && (
                <ButtonBorderedS
                  onClick={() => {
                    hookMyAccountInfo.removeImageHandler(
                      hookMyAccountInfo.updateImageHandler
                    );
                  }}
                >
                  Remove
                </ButtonBorderedS>
              )}
            </BtnsWrapperS>
          </TextWrapperS>
        </WrapperImageTextS>
        <Input
          label="First Name"
          name={hookMyAccountInfo.NAME}
          value={hookMyAccountInfo.getVal(hookMyAccountInfo.NAME)}
          errorText={hookMyAccountInfo.getError(hookMyAccountInfo.NAME)}
          onChange={hookMyAccountInfo.onChangeHandler}
        />
        <Input
          label="Last Name"
          name={hookMyAccountInfo.LAST_NAME}
          value={hookMyAccountInfo.getVal(hookMyAccountInfo.LAST_NAME)}
          errorText={hookMyAccountInfo.getError(hookMyAccountInfo.LAST_NAME)}
          onChange={hookMyAccountInfo.onChangeHandler}
        />
        <ButtonSave
          onClick={hookMyAccountInfo.saveHandler}
          loading={hookMyAccountInfo.loading}
        />
      </WrapperS>
    </>
  );
};

const WrapperS = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;
const WrapperImageTextS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ImageWrapperS = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  svg {
    width: 100%;
    height: auto;
    opacity: 0.5;
  }
`;

const ImageS = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`;

const TextWrapperS = styled.div`
  padding-left: 20px;
  p {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }
`;
const BtnsWrapperS = styled.div`
  display: flex;
  button {
    margin-right: 20px;
    height: 36px;
    min-height: 36px;
    min-width: 92px;
  }
`;
