const tryLater = 'try again later or contact with administrator';
export const TEXT = {
  isRequired: 'This field is required',
  incorrectEmail: 'The email address is incorrect. Please check and retry',
  endRequest: 'Please wait until the request ends. ',
  tryLater,
  noDataUser: 'User does not have data, ' + tryLater,
  noTeamId: 'User does not have team id, ' + tryLater,
  someError: 'Some error occurred, ' + tryLater,
  noData: 'No data for this time period',
};

export const NETWORK_MAP_TEXT = {
  inactive: 'did not complete any assessments.',
  uncaptured: 'doesn\'t match filters by either Assessment or Time Period.',
  captured: 'matched all filters including the assessment percentile',
  matched: 'matched filters except the assessment percentile.',
};
