import React from 'react';
import styled from 'styled-components';
import { TRespIdentify, TRespUser } from '../../pages/Responding/types';
import { ELinkType } from '../../types/respondingTypes';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { CSS_MEDIA } from '../../utils/media';
import { TEXT } from '../../utils/textConst';
import { AboutInfo, DefaultSelect, Input, Loader } from '../ui';
import { RespTitle } from './components/RespTitle';
import { useScreenAuth } from './hooks/useScreenAuthorization';
import * as RespStyles from './styles';
import { TLang } from '../../types/lang';
import { checkLang } from './assLang';
import { DataValues } from './types';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { countryDial } from '../../utils/countryDial';
import { langIsAllingRight } from '../../utils/langIsEng';

interface ScreenAuthorizationProps {
  nextFn: (data: TRespIdentify) => void;
  user: TRespUser | null;
  linkType: ELinkType;
  lang: TLang;
}

export const ScreenAuthorization: React.FC<ScreenAuthorizationProps> = ({
  user,
  linkType,
  nextFn,
  lang,
}) => {
  const {
    submitHandler,
    selected,
    selectData,
    textError,
    setSelected,
    setInputVal,
    loading,
    inputVal,
    phoneCode,
    setPhoneCode,
  } = useScreenAuth(user, linkType, nextFn, lang);
  const [isListVisible, setIsListVisible] = React.useState(false);
  const { ref: refList } = useOutsideClick(() => setIsListVisible(false));

  const [searchValue, setSearchValue] = React.useState('');
  const checkForZero = (value: string) => {
    if (Array.from(value)[0] === '0') setInputVal(value.substr(1));
  };
  if (!user)
    return (
      <>
        <h5>User is null</h5>
      </>
    );
  if (!selected)
    return (
      <>
        <RespTitle title={`${user.first_name} ${user.last_name}`} />
        <h3>No available credentials, {TEXT.tryLater}</h3>
      </>
    );

  return (
    <WrapperS
      className={AnimatedClasses.zoomIn}
      alignRight={langIsAllingRight(lang)}
    >
      <RespTitle
        title={`${checkLang(lang).assessments.hello} ${user.first_name} ${
          user.last_name
        }`}
        alignRight={langIsAllingRight(lang)}
      />
      <InfoWrapperS alignRight={langIsAllingRight(lang)}>
        <RespStyles.RespTextS alignRight={langIsAllingRight(lang)}>
          {checkLang(lang).assessments.enterCred}
        </RespStyles.RespTextS>
        <AboutInfo
            title={`${
                checkLang(lang).assessments.toUnderstand
            } <a href="https://www.orgvitals.com/privacy" target="_blank">${
                checkLang(lang).assessments.privacyPol
            } </a>`}
        />
      </InfoWrapperS>
      <WrapperRowS alignRight={langIsAllingRight(lang)}>
        {selectData.length > 1 ? (
          <DefaultSelect
            onChange={setSelected}
            selected={selected}
            data={selectData}
          />
        ) : null}
        <NumberRowS>
          {selected.id === DataValues.phone_number && (
            <PhoneCodeWrapperS>
              <input
                name={``}
                placeholder="Code"
                onChange={(e) => {}}
                type="text"
                value={phoneCode}
                onClick={() => {
                  setIsListVisible(true);
                }}
                readOnly
              />
              {isListVisible && (
                <SearchWrapperS ref={refList}>
                  <SearchInputS
                    type="text"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.currentTarget.value);
                    }}
                    placeholder={'Search'}
                    autoFocus
                  />
                  <SelectCountryListS>
                    {!searchValue
                      ? countryDial.map((itm, i) => (
                          <li
                            key={i + itm.name + itm.code}
                            onClick={() => {
                              setPhoneCode(
                                `${countryDial[i].flag} ${countryDial[i].dial_code}`
                              );
                              setIsListVisible(false);
                            }}
                          >
                            {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                          </li>
                        ))
                      : countryDial
                          .filter(
                            (itm) =>
                              itm.code.includes(searchValue.toUpperCase()) ||
                              itm.name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase()) ||
                              itm.dial_code.includes(searchValue)
                          )
                          .map((itm) => (
                            <li
                              key={
                                countryDial.findIndex(
                                  (country) => itm.name === country.name
                                ) +
                                itm.name +
                                itm.code
                              }
                              onClick={() => {
                                setPhoneCode(`${itm.flag} ${itm.dial_code}`);
                                setIsListVisible(false);
                              }}
                            >
                              {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                            </li>
                          ))}
                  </SelectCountryListS>
                </SearchWrapperS>
              )}
            </PhoneCodeWrapperS>
          )}
          <Input
            errorText={textError}
            value={inputVal}
            placeholder={selected.placeholder}
            onChange={(e) => {
              setInputVal(e.currentTarget.value);
              if (selected.id === DataValues.phone_number) {
                checkForZero(e.currentTarget.value);
              }
            }}
            onKeyDown={(e: { keyCode: number; preventDefault: () => void }) => {
              if (e.keyCode === 13 && inputVal) {
                e.preventDefault();
                submitHandler();
              }
            }}
            autoFocus
          />
        </NumberRowS>
      </WrapperRowS>
      <RespStyles.RespBtnS
        disabled={!inputVal}
        onClick={loading ? () => null : submitHandler}
        alignRight={langIsAllingRight(lang)}
      >
        {loading ? (
          <Loader size={0.5} />
        ) : (
          checkLang(lang).assessments.btns.continue
        )}
      </RespStyles.RespBtnS>
    </WrapperS>
  );
};
const InfoWrapperS = styled.div<{ alignRight: boolean }>`
  display: flex;
  align-items: center;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  ${({ alignRight }) =>
    alignRight
      ? `flex-direction: row-reverse;
           justify-content: flex-end;`
      : ''};
  div {
    margin: 0;
    div {
      bottom: 17px;
      &::after {
        content: none;
      }
    }
  }
  p {
    margin: 0px 10px 0px 0px;
    direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  }
`;
const WrapperRowS = styled.div<{ alignRight: boolean }>`
  display: flex;
  align-items: flex-start;
  margin-top: 48px;
  ${CSS_MEDIA.maxMd} {
    flex-direction: column;
  }
  & > div {
    width: 100%;
    &.ov-select {
      margin: ${({ alignRight }) =>
        alignRight ? '0px 0px 0px 24px' : '0px 24px 0px 0px'};
      direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
      max-width: 180px;
      & > div {
        border-radius: 26px;
      }

      ${CSS_MEDIA.maxMd} {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  input {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 0;

    &::placeholder {
      opacity: 0.5;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #434352;
    }
  }
`;
const WrapperS = styled.div<{ alignRight: boolean }>`
  ${CSS_MEDIA.maxLg} {
    padding: 125px 0 30px;
  }
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
`;
const PhoneCodeWrapperS = styled.div`
  margin-right: 10px;
  max-width: 100px;
`;
const SearchInputS = styled(Input)``;
const SelectCountryListS = styled(RespStyles.SelectListS)``;
const SearchWrapperS = styled.div`
  position: absolute;
  max-width: 300px;
  width: 100%;
  z-index: 100;
`;
const NumberRowS = styled.div`
  display: flex;
  div {
    width: 100%;
  }
`;
