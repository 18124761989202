import { ILangInput } from './types';

interface ILang {
  hello: string;
  answers: {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
  };
  btns: {
    back: string;
    next: string;
    continue: string;
  };
  question: {
    title: string;
  };
  greeting: string;
  descriptions: string;
  provideInfo: string;
  enterCred: string;
  classificationFilter: (name: string) => string;
  inputs: {
    name: ILangInput;
    email: ILangInput;
    phone: ILangInput;
    payrollId: ILangInput;
  };
  toUnderstand: string;
  privacyPol: string;
  welcome: string;
  respond: (num: number) => string;
  thankForResp: string;
  goToAss: string;
  goodWork: (name: string) => string;
  completed: string;
  thanksForTime: string;
  privilegedAndConfidential: string;
  privilegedAndConfidentialTitle: string;
  understandBtnText: string;
  anonymousQuestion: string;
}

export const LangAssessments: {
  en: ILang;
  es: ILang;
  fr: ILang;
  he: ILang;
} = {
  en: {
    anonymousQuestion: 'Anonymous question',
    understandBtnText: 'I understand',
    privilegedAndConfidentialTitle: 'Your survey feedback is confidential and protected',
    privilegedAndConfidential: 'Privileged and Confidential: this survey constitutes quality assurance and performance ' +
        'improvement work-product as defined and protected by 42 CFR § 483.75(o)(3), 42 U.S.C. § 1395i-3(b)(1)(B), and ' +
        '42 U.S.C. § 1396r(b)(1)(B). This survey form, its content, and all other documents, notes, compilations, studies, ' +
        'findings, plans, recommendations, evaluations, opinions, minutes, reports, and records are highly confidential, ' +
        'privileged and protected by the federal quality assurance privilege and applicable state laws, and shall not be' +
        ' subject to discovery, subpoena, or other means of legal compulsion for their release to any person or entity or ' +
        'be admissible into evidence in any civil action, arbitration, or any other judicial or administrative proceeding.' +
        ' Nor shall testimony relating thereto be compelled, or admitted or introduced into evidence, or in any action ' +
        'of any kind in any court or before any administrative body, agency, or person for any purpose whatsoever. ' +
        'Any and all other legal privileges, immunities, and/or defenses are expressly reserved and preserved. ' +
        '\nI acknowledge ' +
        'I provided my honest feedback and did not fabricate feedback on anyone’s behalf. I agree to enable temporary ' +
        'tracking that ensures the integrity of this survey.',
    thanksForTime:
      'Thanks for your time. Understanding how employees feel about their workplace culture is critical to knowing how to improve the culture.',
    completed: 'You completed the assessments!',
    goodWork: (name) => `Good work ${name}!`,
    goToAss: 'Go to assessment list',
    thankForResp: 'This section is complete!',
    respond: (n) => `Please respond to this survey request:`,
    welcome: 'Welcome',
    toUnderstand:
      'To understand the purpose of this survey asking for your identity and understand your data privacy, please visit',
    privacyPol: 'OrgVitals Privacy Policy',
    hello: 'Hello',
    answers: {
      0: 'Pass',
      1: 'Strongly Disagree',
      2: 'Somewhat Disagree',
      3: 'Neither Agree nor Disagree',
      4: 'Somewhat Agree',
      5: 'Strongly Agree',
    },
    btns: {
      back: 'Back',
      next: 'Next',
      continue: 'Continue',
    },
    question: {
      title: 'Question',
    },
    greeting: 'Hello! Welcome to OrgVitals.',
    descriptions:
      'OrgVitals aggregates employee responses to understand organizational culture and tracks sentiment in areas like stress, connectedness and capacity.',
    provideInfo:
      'Information you provide will help generate meaningful conversations to improve the work culture.',
    inputs: {
      name: {
        placeholder: 'Start typing your name to find yourself on the list',
        label: '',
        subText: '',
        errorText: '',
      },
      email: {
        placeholder: 'Email',
        label: '',
        subText: '',
        errorText: '',
      },
      phone: {
        placeholder: 'Phone',
        label: '',
        subText: '',
        errorText: '',
      },
      payrollId: {
        placeholder: 'ID',
        label: '',
        subText: '',
        errorText: '',
      },
    },
    enterCred: 'Please enter your credential. If you see a dropdown, you may pick the credential you prefer to use.',
    classificationFilter: (name) => `Find your ${name}:`,
  },
  es: {
    anonymousQuestion: 'Pregunta anónima',
    understandBtnText: 'Entiendo',
    privilegedAndConfidentialTitle: 'Los comentarios de su encuesta son confidenciales y están protegidos',
    privilegedAndConfidential: 'Privilegiado y confidencial: esta encuesta constituye un producto de trabajo de ' +
        'garantía de calidad y mejora del desempeño según lo define y protege 42 CFR § 483.75(o)(3), 42 U.S.C.' +
        ' § 1395i-3(b)(1)(B), y 42 U.S.C. § 1396r(b)(1)(B). Este formulario de encuesta, su contenido y todos' +
        ' los demás documentos, notas, compilaciones, estudios, hallazgos, planes, recomendaciones, evaluaciones,' +
        ' opiniones, actas, informes y registros son altamente confidenciales, privilegiados y protegidos por el ' +
        'privilegio federal de garantía de calidad y el estado aplicable leyes, y no estarán sujetos a descubrimiento,' +
        ' citación u otros medios de coerción legal para su liberación a ninguna persona o entidad ni serán admisibles' +
        ' como prueba en ninguna acción civil, arbitraje o cualquier otro procedimiento judicial o administrativo.' +
        ' Tampoco se obligará, ni se admitirá ni se introducirá como prueba el testimonio relacionado con el mismo, ' +
        'ni en ninguna acción de ningún tipo en ningún tribunal o ante ningún organismo administrativo, agencia o ' +
        'persona para ningún propósito. Todos y cada uno de los demás privilegios, inmunidades y/o defensas legales ' +
        'están expresamente reservados y preservados. \nReconozco que proporcioné mis comentarios honestos y que no los ' +
        'inventé en nombre de nadie. Acepto habilitar un seguimiento temporal que garantice la integridad de esta encuesta.',
    thanksForTime:
      'Gracias por tu tiempo. Comprender cómo se sienten los empleados sobre su cultura en el lugar de trabajo es fundamental para saber cómo mejorar la cultura.',
    completed: '¡Has completado las evaluaciones!',
    goodWork: (name) => `Buen trabajo ${name}!`,
    goToAss: 'Ir a la lista de evaluación',
    thankForResp: '¡Esta sección está completa!',
    respond: (n) => `Responda a esta solicitud de encuesta:`,
    welcome: 'Bienvenido',
    toUnderstand:
      'Para comprender el propósito de esta encuesta solicitando su identidad y comprenda su privacidad de datos, visite la',
    privacyPol: 'Política de privacidad de OrgVitals',
    hello: 'Hola',
    answers: {
      0: 'Aprobar',
      1: 'Totalmente en desacuerdo',
      2: 'Algo en desacuerdo',
      3: 'Ni de acuerdo ni en desacuerdo',
      4: 'Algo de acuerdo',
      5: 'Totalmente de acuerdo',
    },
    btns: {
      back: 'Atrás',
      next: 'Próximo',
      continue: 'Continuar',
    },

    question: {
      title: 'Pregunta',
    },
    greeting: '¡Hola! Bienvenido a OrgVitals.',
    descriptions:
      'OrgVitals agrega las respuestas de los empleados para comprender la cultura organizacional y las pistas del sentimiento en áreas como el estrés, la conexión y la capacidad.',
    provideInfo:
      'La información que proporciona ayudará a generar conversaciones significativas para mejorar la cultura laboral.',
    inputs: {
      name: {
        placeholder:
          'Comience a escribir su nombre para encontrarte en la lista',
        label: '',
        subText: '',
        errorText: '',
      },
      email: {
        placeholder: 'Email',
        label: '',
        subText: '',
        errorText: '',
      },
      phone: {
        placeholder: 'Teléfono',
        label: '',
        subText: '',
        errorText: '',
      },
      payrollId: {
        placeholder: 'ID',
        label: '',
        subText: '',
        errorText: '',
      },
    },
    enterCred: 'Por favor ingrese su credencial. Si ve un menú desplegable, puede elegir la credencial que prefiere usar.',
    classificationFilter: (name) => `Encuentra tu ${name}:`,
  },
  fr: {
    anonymousQuestion: 'Question anonyme',
    understandBtnText: 'Je comprends',
    privilegedAndConfidentialTitle: 'Les commentaires de votre enquête sont confidentiels et protégés',
    privilegedAndConfidential: 'Privilégié et confidentiel: cette enquête constitue un produit de travail ' +
        'd\'assurance qualité et d\'amélioration des performances tel que défini et protégé par 42 CFR § 483.75(o)(3), ' +
        '42 U.S.C. § 1395i-3(b)(1)(B) et 42 U.S.C. § 1396r(b)(1)(B). Ce formulaire d\'enquête, son contenu et tous les ' +
        'autres documents, notes, compilations, études, conclusions, plans, recommandations, évaluations, opinions, ' +
        'procès-verbaux, rapports et dossiers sont hautement confidentiels, privilégiés et protégés par le privilège' +
        ' fédéral d\'assurance qualité et par l\'État applicable lois, et ne doivent pas faire l\'objet d\'une enquête ' +
        'préalable, d\'une assignation à comparaître ou d\'autres moyens de contrainte légale pour leur libération à ' +
        'toute personne ou entité ou être admissibles comme preuve dans toute action civile, arbitrage ou toute autre ' +
        'procédure judiciaire ou administrative. Les témoignages s\'y rapportant ne seront pas non plus forcés, admis ' +
        'ou introduits en preuve, ni dans aucune action de quelque nature que ce soit devant un tribunal ou devant un' +
        ' organe administratif, une agence ou une personne à quelque fin que ce soit. Tous les autres privilèges, ' +
        'immunités et/ou défenses juridiques sont expressément réservés et préservés. \nJe reconnais que j’ai fourni mes ' +
        'commentaires honnêtes et que je n’ai fabriqué aucun commentaire au nom de qui que ce soit. J\'accepte d\'activer ' +
        'le suivi temporaire qui garantit l\'intégrité de cette enquête.',
    thanksForTime:
      'Merci pour votre temps. Comprendre comment les employés se sentent à propos de leur culture sur le lieu de travail sont essentiels à savoir comment améliorer la culture.',
    completed: 'Vous avez terminé les évaluations!',
    goodWork: (name) => `Bon travail ${name}!`,
    goToAss: `Aller à la liste d'évaluation`,
    thankForResp: `Cette section est complète!`,
    respond: (n) => `Veuillez répondre à cette demande d'enquête:`,
    welcome: 'Bienvenue',
    toUnderstand:
      'Pour comprendre le but de cette enquête demandant votre identité et comprendre votre confidentialité de données, veuillez visiter la ',
    privacyPol: 'Politique de confidentialité des OrgVitals',
    hello: 'Bonjour',
    answers: {
      0: 'Passe',
      1: `Fortement en désaccord`,
      2: `Un peu en désaccord`,
      3: `Ni d'accord ni en désaccord`,
      4: `Plutôt d'accord`,
      5: `Tout à fait d'accord`,
    },
    btns: {
      back: 'Retour',
      next: 'Suivant',
      continue: 'Continuez',
    },
    question: {
      title: 'Question',
    },
    greeting: 'Salut! Bienvenue dans les OrgVitals.',
    descriptions:
      'OrgVitals abrite les réponses des employés pour comprendre la culture organisationnelle et les pistes de sentiment dans des domaines tels que le stress, la connectivité et la capacité.',
    provideInfo:
      'Les informations que vous fournissez aideront à générer des conversations significatives pour améliorer la culture de travail.',
    inputs: {
      name: {
        placeholder:
          'Commencez à taper votre nom pour vous retrouver sur la liste',
        label: '',
        subText: '',
        errorText: '',
      },
      email: {
        placeholder: 'Email',
        label: '',
        subText: '',
        errorText: '',
      },
      phone: {
        placeholder: 'Téléphoner',
        label: '',
        subText: '',
        errorText: '',
      },
      payrollId: {
        placeholder: 'ID',
        label: '',
        subText: '',
        errorText: '',
      },
    },
    enterCred: 'Veuillez saisir votre identifiant. Si vous voyez une liste déroulante, vous pouvez choisir l\'identifiant que vous préférez utiliser.',
    classificationFilter: (name) => `Trouvez votre ${name}:`,
  },
  he: {
    anonymousQuestion: 'שאלה אנונימית',
    understandBtnText: 'אני מבין',
    privilegedAndConfidentialTitle: 'המשוב שלך על הסקר הוא סודי ומוגן',
    privilegedAndConfidential: 'מיוחס וסודי: סקר זה מהווה אבטחת איכות ושיפור ביצועים-מוצר עבודה כפי שהוגדר ומוגן ב-42 CFR § 483.75(o)(3), 42 U.S.C. § 1395i-3(b)(1)(B), ו-42 U.S.C. § 1396r(b)(1)(B). טופס סקר זה, תוכנו וכל שאר המסמכים, ההערות, הליקוטים, המחקרים, הממצאים, התוכניות, ההמלצות, הערכות, דעות, פרוטוקולים, דוחות ורישומים הם חסויים ביותר, מוגנים ומוגנים על ידי הרשאות אבטחת האיכות הפדרלית והמדינה הרלוונטית. חוקים, ולא יהיו כפופים לגילוי, הזמנה לדין או אמצעים אחרים של כפייה משפטית לשחרורם לכל אדם או ישות או יהיו קבילים לראיה בכל תביעה אזרחית, בוררות או כל הליך שיפוטי או מנהלי אחר. כמו כן, לא תיאלץ עדות המתייחסת לכך, או תתקבל או תוצג לראיה, או בכל פעולה מכל סוג שהוא בבית משפט או בפני כל גוף מנהלי, סוכנות או אדם לכל מטרה שהיא. כל יתר הפריבילגיות, החסינות ו/או ההגנות המשפטיות שמורות ונשמרות במפורש.\nאני מאשר שסיפקתי את המשוב הכנה שלי ולא בדהתי משוב מטעם אף אחד. אני מסכים לאפשר מעקב זמני המבטיח את שלמות הסקר הזה.',
    thanksForTime:
      'תודה על הזמן שלך. ההבנה כיצד עובדים מרגישים לגבי תרבות מקום העבודה שלהם היא קריטית לדעת כיצד לשפר את התרבות.',
    completed: 'השלמת את ההערכות!',
    goodWork: (name) => `עבודה טובה ${name}!`,
    goToAss: `עבור לרשימת הערכה`,
    thankForResp: `החלק הזה שלם!`,
    respond: (n) => `אנא השיב/י לבקשת סקר זה:`,
    welcome: 'ברוכ/ה הבא/ה',
    toUnderstand:
      'כדי להבין את מטרתו של סקר זה המבקש את זהותך והבנת פרטיות הנתונים שלך, בקר במדיניות הפרטיות של',
    privacyPol: 'OrgVitals',
    hello: 'שלום',
    answers: {
      0: 'דלג',
      1: `מאוד לא מסכים`,
      2: `קצת לא מסכים`,
      3: `לא מסכים ולא לא מסכים`,
      4: `קצת מסכים`,
      5: `מסכים לחלוטין`,
    },
    btns: {
      back: 'חזור',
      next: 'הַבָּא',
      continue: 'המשך/י',
    },
    question: {
      title: 'שְׁאֵלָה',
    },
    greeting: 'שלום! ברוכ/ה הבא/ה ל- OrgVitals.',
    descriptions:
      'OrgVitals אוספת את תגובות העובדים כדי להבין את התרבות הארגונית ועוקבת אחרי התחושות בתחומים כגון לחץ, מחוברות ותכולת תפקיד.',
    provideInfo: 'המידע שתספק/י יעזור לייצר שיח משמעותי לשיפור תרבות העבודה.',
    inputs: {
      name: {
        placeholder: 'התחל/י להקליד את שמך באנגלית כדי למצוא את עצמך ברשימה',
        label: '',
        subText: '',
        errorText: '',
      },
      email: {
        placeholder: 'אימייל',
        label: '',
        subText: '',
        errorText: '',
      },
      phone: {
        placeholder: 'טלפון',
        label: '',
        subText: '',
        errorText: '',
      },
      payrollId: {
        placeholder: 'ID',
        label: '',
        subText: '',
        errorText: '',
      },
    },
    enterCred: 'אנא הזן את האישור שלך. אם אתה רואה תפריט נפתח, אתה יכול לבחור את האישור שאתה מעדיף להשתמש בו.',
    classificationFilter: (name) => `מצא את ${name} שלך:`
  },
};
