import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { EditPackage } from '../../../store/packages/actions';
import { TPackage } from '../../../store/packages/types';
import { deepClone } from '../../../utils/deepClone';
import { getCutText } from '../../../utils/helpers';
import { TEXT } from '../../../utils/textConst';
import { VALUES } from '../../../utils/valueConst';
import {
  Alert,
  ButtonMore,
  CheckBox,
  DeleteSelectItem,
  EditSelectItem,
  Loader,
} from '../../ui';
import * as QBS from './QuestionsBoxStyles';
import { useDndSideBar } from './useDndSideBar';

interface QuestionsBoxProps {
  pack: TPackage;
  loading?: boolean;
  clickHandler: () => void;
  editHandler: () => void;
  deleteHandler: () => void;
}

export const QuestionsBox: React.FC<QuestionsBoxProps> = ({
  pack,
  clickHandler,
  editHandler,
  deleteHandler,
  loading,
}) => {
  const { isOver, drop } = useDndSideBar(pack.id);
  const { Packages } = useSelector((store: AppStore) => store);
  const [isBoxLoading, setIsBoxLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();
  const packQSum = (assessments: TPackage['assessments']) => {
    let count = 0;
    if (!assessments) return 0;
    assessments.forEach((ass) => (count += ass.number_of_questions));
    return count;
  };
  const shortDesc = getCutText(pack.description, 55);

  const updatePackCallBack = (success: boolean, checked: boolean) => {
    setIsBoxLoading(false);
    if (!success) return setError(true);
    if (!Packages.data) return null;
    const newPackages = deepClone(Packages.data) as TPackage[];
    const newPack = newPackages.find((p) => p.id === pack.id);
    if (!newPack) {
      console.warn('Could not find package');
      return null;
    }
    newPack.is_scheduled = checked;

    return dispatch(EditPackage.success(newPackages));
  };
  return (
    <QBS.OuterWrapperS ref={drop} isOver={isOver}>
      <QBS.WrapperS>
        <QBS.HeaderS>
          <CheckBox
            onChange={(e) => {
              setIsBoxLoading(true);
              const { checked } = e.currentTarget;
              dispatch(
                EditPackage.request({
                  id: pack.id,
                  data: {
                    description: pack.description,
                    name: pack.name,
                    is_scheduled: checked,
                    logo_team: false,
                    logo_workspace: false,
                    languages_tags: pack.languages_tags,
                    welcome_screen_description: { en_US: '' },
                    welcome_screen_title: { en_US: '' },
                  },
                  callBack: (success) => updatePackCallBack(success, checked),
                })
              );
            }}
            isCheck={pack.is_scheduled}
            disabled={pack.is_has_delayed_message}
          />
          <QBS.QuestionsCountS onClick={clickHandler}>
            {packQSum(pack.assessments)} questions
          </QBS.QuestionsCountS>
          <ButtonMore
            onClick={(e) => {
              if (e === VALUES.edit) return editHandler();
              if (e === VALUES.delete) return deleteHandler();
            }}
            data={[
              {
                title: <EditSelectItem />,
                value: VALUES.edit,
                id: 1,
              },
              {
                title: <DeleteSelectItem />,
                value: VALUES.delete,
                id: 2,
              },
            ]}
          />
        </QBS.HeaderS>
        <QBS.BodyS onClick={clickHandler}>
          <h2>
            <QBS.TitleS>{pack.name}</QBS.TitleS>{' '}
            <QBS.CountS>&nbsp;({pack.assessments?.length || 0})</QBS.CountS>
          </h2>
          <QBS.DescS>{shortDesc}</QBS.DescS>
          {error ? <Alert text={TEXT.someError} /> : null}
        </QBS.BodyS>
      </QBS.WrapperS>
      {loading || isBoxLoading ? <Loader isGreen /> : null}
    </QBS.OuterWrapperS>
  );
};
