import React from 'react';
import styled from 'styled-components';
import { InputSearch } from '../../ui';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { SettingsIcon } from '../../svgs/Settings';
import { SearchSettings } from './SearchSettings';
import { useFilterSearch } from '../../../pages/Visualizations/hooks/useFilterSearch';

interface IUserColSearch {
  name: string;
  id: string;
}

interface Props {
  clickItem: (id: string) => void;
  setIsGlobalSettings: (isGlobalSettings: boolean) => void;
  searchVal: string;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
}
export const Search: React.FC<Props> = ({
  clickItem,
  setIsGlobalSettings,
  searchVal,
  setSearchVal,
}) => {
  const [isResBtn, setIsResBtn] = React.useState(false);
  const { Collaborations, Workspaces, Analytics } = useSelector(
    (store: AppStore) => store
  );
  const [users, setUsers] = React.useState<IUserColSearch[]>([]);
  const [searchUsers, setSearchUsers] = React.useState<IUserColSearch[]>([]);
  const [isResultList, setIsResultList] = React.useState(false);
  const [isSettings, setIsSettings] = React.useState(false);

  const { ref } = useOutsideClick(() => {
    setSearchUsers([]);
    setIsResultList(false);
    setIsSettings(false);
  });

  React.useEffect(() => {
    if (Workspaces.current) setSearchVal('');
    // eslint-disable-next-line
  }, [Workspaces]);

  React.useEffect(() => {
    (() => {
      if (!Collaborations.data) return null;
      const innerUsers: IUserColSearch[] = [];
      const ids: string[] = [];
      Collaborations.data.forEach((u) => {
        if (!ids.includes(u.source)) {
          ids.push(u.source);
          innerUsers.push({
            name: `${u.source_first_name} ${u.source_last_name}`,
            id: u.source,
          });
        }
        if (!ids.includes(u.target)) {
          ids.push(u.target);
          innerUsers.push({
            name: `${u.target_first_name} ${u.target_last_name}`,
            id: u.target,
          });
        }
      });
      setUsers(innerUsers);
    })();
  }, [Collaborations.data]);

  const searchHandler = (val: string) => {
    setSearchVal(val);
    if (val.length < 3) {
      setIsResultList(false);
      return setSearchUsers([]);
    }

    if (!isResultList) setIsResultList(true);

    const innerSearchUsers = users.filter((u) =>
      u.name.toLowerCase().includes(val.toLowerCase())
    );

    setSearchUsers(innerSearchUsers);
  };

  const renderList = () => {
    if (!searchUsers[0] && searchVal.length >= 3) {
      return <NoResultsS>No Results</NoResultsS>;
    }

    return (
      <ul>
        {searchUsers.map((u, i) => (
          <ListItemS
            onClick={() => clickHandler(u)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') clickHandler(u);
            }}
            tabIndex={i + 2}
            key={u.id}
          >
            {u.name}
          </ListItemS>
        ))}
      </ul>
    );
  };

  const clickHandler = (data: IUserColSearch | null, id?: string) => {
    setIsGlobalSettings(false);
    if (data) setSearchVal(data.name);
    setIsResultList(false);
    setIsResBtn(true);
    setSearchUsers([]);
    clickItem(data ? data.id : id);
    if (data) setCurrUserId(data.id);
  };

  const { settingsKindFilter, setSettingsKindFilter, setCurrUserId } =
    useFilterSearch(clickHandler);

  const resetHandler = () => {
    const circles = document.querySelectorAll('.userCircle');
    const circlesText = document.querySelectorAll('.circle-text');
    const markers = document.querySelectorAll('marker');
    const lines = document.querySelectorAll('.userLine');
    setIsResBtn(false);
    searchHandler('');
    circles.forEach((c, i) => {
      c.removeAttribute('style');
      circlesText[i].removeAttribute('style');
    });
    markers.forEach((c) => {
      const path = c.querySelector('path');
      const id = c.id;
      if (!path) return null;
      path.style.fill = '#' + id;
    });
    lines.forEach((c) => {
      c.removeAttribute('style');
    });
    setIsSettings(false);
    setIsGlobalSettings(true);
  };

  React.useEffect(() => {
    searchHandler('');
    setIsSettings(false);
    setIsGlobalSettings(true);
    setIsResBtn(false);

    // eslint-disable-next-line
  }, [Analytics.filters]);

  return (
    <WrapperS ref={ref}>
      <div style={{ position: 'relative', paddingBottom: '5px' }}>
        <InputSearch
          placeholder={'Search participant by name…'}
          onChange={(e) => searchHandler(e.currentTarget.value)}
          value={searchVal}
          results={[]}
          clearHandler={() => searchHandler('')}
        />
        {isSettings && (
          <SearchSettings
            isDef={settingsKindFilter === 'def'}
            isSource={settingsKindFilter === 'source'}
            isTarget={settingsKindFilter === 'target'}
            onChange={setSettingsKindFilter}
          />
        )}
      </div>

      {isResultList ? <SearchWrapperS>{renderList()}</SearchWrapperS> : null}
      {isResBtn && (
        <>
          <ResetHandlerS onClick={resetHandler} type={'button'}>
            Reset
          </ResetHandlerS>
          <SettingsBtnHandlerS onClick={() => setIsSettings((d) => !d)}>
            <SettingsIcon />
          </SettingsBtnHandlerS>
        </>
      )}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 100%;
  max-width: 400px;
  /* z-index: 999; */
  input {
    border-color: white;
  }
  ul {
    margin: 0;
    padding: 0;
  }
`;

const SearchWrapperS = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 5px;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: auto;
`;

const ListItemS = styled.li`
  cursor: pointer;
  padding: 10px 20px;
  list-style: none;

  &:hover {
    background: ${COLORS_RGBA.accent(0.1)};
  }

  &:active {
    background: ${COLORS_RGBA.accent(0.9)};
    color: white;
  }
`;
const NoResultsS = styled.div`
  padding: 20px;
`;

const ResetHandlerS = styled.button`
  position: absolute;
  left: 100%;
  margin-left: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: 0;
  outline: 0;
  appearance: none;
  text-decoration: underline;
  color: ${COLORS_RGBA.accent()};
  margin-top: -2px;
  &:hover {
    color: ${COLORS_RGBA.accent(0.6)};
  }
`;

const SettingsBtnHandlerS = styled(ResetHandlerS)`
  margin-left: 10px;
  margin-top: 0;

  svg path {
    fill: ${COLORS_RGBA.accent(1)};
  }

  &:hover {
    svg path {
      fill: ${COLORS.accentHover};
    }
  }
`;
