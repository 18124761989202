import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS_RGBA } from '../../utils/colors';
import { CheckBox, Tabs } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';

interface SyncHrisSecondProps {
  closeHandler: () => void;
}

export const SyncHrisModalSecond: React.FC<SyncHrisSecondProps> = ({
  closeHandler,
}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };
  const [activeTab, setActiveTab] = React.useState('1');
  const [tabs] = React.useState([
    {
      id: '1',
      title: 'Participants',
    },
    {
      id: '2',
      title: 'Groups',
    },
  ]);
  const renderBody = () => {
    if (activeTab === '1')
      return (
        <>
          <h3>Added:</h3>
          <AddS>
            <GroupsS>Groups: Group1, Group2</GroupsS>
            <DataOuterWrapperS>
              <DataInnerWrapperS>
                <DataWrapperS>
                  <p>Samuel Washington</p>
                </DataWrapperS>
                <DataWrapperS>
                  <p>+19199999119</p>
                  <p>samuelwashington@gmail.com</p>
                  <p>81238</p>
                </DataWrapperS>
              </DataInnerWrapperS>
              <CheckBox isCheck={false} onChange={() => {}} />
            </DataOuterWrapperS>
          </AddS>
          <AddS>
            <GroupsS>Groups: Group1, Group2</GroupsS>
            <DataOuterWrapperS>
              <DataInnerWrapperS>
                <DataWrapperS>
                  <p>Aldon Roome</p>
                </DataWrapperS>
                <DataWrapperS>
                  <p>aroome2@slashdot.org</p>
                </DataWrapperS>
              </DataInnerWrapperS>
              <CheckBox isCheck={true} onChange={() => {}} />
            </DataOuterWrapperS>
          </AddS>
          <h3>Updated:</h3>
          <ItemS>
            OrgVitals:
            <DefaultS>
              <GroupsS>Groups: Group1, Group2</GroupsS>
              <DataOuterWrapperS>
                <DataInnerWrapperS>
                  <DataWrapperS>
                    <p>Artem Golovhcenko</p>
                  </DataWrapperS>
                  <DataWrapperS>
                    <p>+380964529220</p>
                    <p>golovchenkoartem@gmail.com</p>
                  </DataWrapperS>
                </DataInnerWrapperS>
              </DataOuterWrapperS>
            </DefaultS>
            HRIS System:
            <DefaultS>
              <GroupsS>Groups: Group1, Group2, Group3</GroupsS>
              <DataOuterWrapperS>
                <DataInnerWrapperS>
                  <DataWrapperS>
                    <p>Artem Golovhcenko</p>
                  </DataWrapperS>
                  <DataWrapperS>
                    <p>+380964519110</p>
                    <p>golovchenkoartem1@gmail.com</p>
                  </DataWrapperS>
                </DataInnerWrapperS>
                <CheckBox isCheck={false} onChange={() => {}} />
              </DataOuterWrapperS>
            </DefaultS>
          </ItemS>
          <h3>Deleted:</h3>
          <DeleteS>
            <DataOuterWrapperS>
              <GroupsS>Groups: Group1, Group2</GroupsS>
              <DataInnerWrapperS>
                <DataWrapperS>
                  <p>Yevette Oldfield</p>
                </DataWrapperS>
                <DataWrapperS>
                  <p>+18193211232</p>
                  <p>yoldfield4@apache.org</p>
                </DataWrapperS>
              </DataInnerWrapperS>
              <CheckBox isCheck={true} onChange={() => {}} />
            </DataOuterWrapperS>
          </DeleteS>
        </>
      );
    if (activeTab === '2')
      return (
        <>
          <h3>Added:</h3>
          <AddS>
            <DataOuterWrapperS>
              <DataInnerWrapperS>
                <DataWrapperS>
                  <p>NewGroup1</p>
                </DataWrapperS>
              </DataInnerWrapperS>
              <CheckBox isCheck={true} onChange={() => {}} />
            </DataOuterWrapperS>
          </AddS>
          <AddS>
            <DataOuterWrapperS>
              <DataInnerWrapperS>
                <DataWrapperS>
                  <p>NewGroup2</p>
                </DataWrapperS>
              </DataInnerWrapperS>
              <CheckBox isCheck={false} onChange={() => {}} />
            </DataOuterWrapperS>
          </AddS>
          <h3>Updated:</h3>
          <ItemS>
            OrgVitals:
            <DefaultS>
              <DataOuterWrapperS>
                <DataInnerWrapperS>
                  <DataWrapperS>
                    <p>Groip2</p>
                  </DataWrapperS>
                </DataInnerWrapperS>
              </DataOuterWrapperS>
            </DefaultS>
            HRIS System:
            <DefaultS>
              <DataOuterWrapperS>
                <DataInnerWrapperS>
                  <DataWrapperS>
                    <p>Group2</p>
                  </DataWrapperS>
                </DataInnerWrapperS>
                <CheckBox isCheck={true} onChange={() => {}} />
              </DataOuterWrapperS>
            </DefaultS>
          </ItemS>
          <h3>Deleted:</h3>
          <DeleteS>
            <DataOuterWrapperS>
              <DataInnerWrapperS>
                <DataWrapperS>
                  <p>Group1</p>
                </DataWrapperS>
              </DataInnerWrapperS>
              <CheckBox isCheck={false} onChange={() => {}} />
            </DataOuterWrapperS>
          </DeleteS>
        </>
      );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '700px', height: '900px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>HRIS Sync</Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <TabsWrapperS>
            <TabsInnerWrapperS>
              <Tabs
                isUnderline
                data={tabs}
                onClick={setActiveTab}
                activeTab={activeTab}
              />
            </TabsInnerWrapperS>
          </TabsWrapperS>
          <WrapperS>{renderBody()}</WrapperS>
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonDefS
              onClick={() => {
                console.log('next');
              }}
            >
              Done
            </ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const WrapperS = styled.div`
  display: flex;
  flex-direction: column;
`;
const TabsInnerWrapperS = styled.div`
  width: 150px;
  * > div {
    padding: 0 0 5px 0;
  }
`;
const TabsWrapperS = styled.div`
  display: flex;
  justify-content: center;
`;
const ItemS = styled.div`
  width: 100%;
  //border-bottom: solid 1px rgba(42, 47, 42, 0.4);
  margin-bottom: 10px;
`;
const DefaultS = styled.div`
  border: 1px solid ${COLORS_RGBA.accent(0.6)};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
`;
const DeleteS = styled.div`
  background: ${COLORS_RGBA.danger(0.2)};
  border: 1px solid ${COLORS_RGBA.danger(0.6)};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
`;
const AddS = styled.div`
  background: ${COLORS_RGBA.accent(0.2)};
  border: 1px solid ${COLORS_RGBA.accent(0.6)};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;
const DataWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  p {
    padding: 0;
    margin: 0 5px 0 0;
  }
`;

const DataInnerWrapperS = styled.div`
  display: flex;
  align-items: center;
`;
const DataOuterWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const GroupsS = styled.span`
  margin-bottom: 5px;
`;
