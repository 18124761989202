import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { GetAllAttachments } from '../../store/attachments/actions';
import { AddMaterials } from '../modals';
import {
  RightSideBarWrapperS,
  RightSideBarHeaderS,
  RightSideBarTitleS,
  RightSideBarBodyS,
  RightSideBarEmptyTextS,
} from '../RightSideBar/RightSideBarStyles';
import { ButtonAdd, Alert, Loader } from '../ui';
import { Attachment } from './Attachment';

interface RightSideBarProps {}

export const RightSideBar: React.FC<RightSideBarProps> = React.memo(() => {
  const [showAddMat, setShowAddMat] = React.useState(false);
  const { Attachments, Workspaces } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (Workspaces.current)
      dispatch(
        GetAllAttachments.request({ workspaceId: Workspaces.current.id })
      );
  }, [Workspaces, dispatch]);
  return (
    <>
      <RightSideBarWrapperS>
        <RightSideBarHeaderS>
          <div>
            <RightSideBarTitleS>Materials</RightSideBarTitleS>
            <RightSideBarUndertitle>
              Resources related to the workspace
            </RightSideBarUndertitle>
          </div>
          <ButtonAddAttachS>
            <ButtonAdd title="Add" onClick={() => setShowAddMat(true)} />
          </ButtonAddAttachS>
        </RightSideBarHeaderS>
        <RightSideBarBodyS isEmpty={Attachments.data && !Attachments.data[0]}>
          {Attachments.errors && (
            <Alert type="warning" text={Attachments.errors} />
          )}
          {Attachments.loading && <Loader isGreen />}
          {Attachments.data && Attachments.data[0] ? (
            Attachments.data.map((itm) => {
              return <Attachment key={itm.id} attachment={itm} />;
            })
          ) : Attachments.loading ? (
            <Loader isGreen />
          ) : (
            <RightSideBarEmptyTextS>
              Create attachments related to your workspace
            </RightSideBarEmptyTextS>
          )}
        </RightSideBarBodyS>
      </RightSideBarWrapperS>
      {showAddMat && (
        <AddMaterials
          closeHandler={() => {
            setShowAddMat(false);
          }}
        />
      )}
    </>
  );
});
const RightSideBarUndertitle = styled.span`
  font-size: 8px;
  height: 10px;
`;
const ButtonAddAttachS = styled.div`
  button {
    height: 30px !important;
    min-height: 30px !important;
    font-size: 14px;
    font-weight: bold;
    svg {
      height: 10px;
      width: 10px;
    }
    span {
      height: auto;
    }
  }
`;
