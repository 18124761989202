import React from 'react';

interface TimePeriodIconProps {}

export const TimePeriodIcon: React.FC<TimePeriodIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="#4caf50" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M29 10c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3H19c-1.657 0-3-1.343-3-3V13c0-1.657 1.343-3 3-3v1c0 .552.448 1 1 1s1-.448 1-1v-1h6v1c0 .513.386.936.883.993L28 12c.552 0 1-.448 1-1v-1zm1 6H18v7c0 .552.448 1 1 1h10c.552 0 1-.448 1-1v-7zm-2 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                transform="translate(-886 -187) translate(288 149) translate(582 28)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
