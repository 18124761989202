import React from 'react';
import { ANIM_CL } from '../../../utils/animatedStyles';
import { classController } from '../../../utils/classContorller';
import { delay } from '../../../utils/helpers';

export const useCloseModal = (closeHandler: () => void) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);

  const closeModal = async () => {
    const innerW = { ...classController };
    innerW.el = refInnWrapper.current;
    innerW.removeClass(ANIM_CL.zoomIn);
    innerW.addClass(ANIM_CL.zoomOut);
    await delay(200);
    closeHandler();
  };
  return {
    refInnWrapper,
    closeModal,
  };
};
