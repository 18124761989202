import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import { ButtonS } from '../../ui/buttons/ButtonArrow';
import { colNames } from '../types/columnNames';
import {group_border_colors} from "../ParticipantRow";

type Keys = keyof typeof colNames;
type TypeColName = typeof colNames[Keys];

export const TableS = styled.table`
  width: 100%;
  margin-top: 24px;
  min-width: 700px;
`;
export const THeadS = styled.thead``;
export const TBodyS = styled.tbody`
  position: relative;
`;
export const TrS = styled.tr``;
export const TdS = styled.td<{ name?: TypeColName }>`
  padding: 25px 20px 0 0;
  vertical-align: top;

  p {
    margin: 0;
  }
  /* border-top: 1px solid rgba(0, 0, 0, 0.12); */

  ${({ name }) => {
    if (name === 'group_name') {
      return `
      padding: 11px 0 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

 
    `;
    }
    if (name === colNames[1]) {
      return `
        padding-top: 16px;
        .orgvitals-loader {
          left: 50%;
        }
      `;
    }
    return ``;
  }}
`;
export const ThTitleS = styled.div`
  display: flex;
  opacity: 0.8;
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  align-items: center;
  button {
    margin-left: 10px;

    svg {
      width: 8px;
      height: 12px;
    }
  }
`;

export const SortBtnS = styled(ButtonS)<{ active?: boolean }>`
  ${({ active }) =>
    active
      ? `
     svg g {
      fill: ${COLORS.accent};
      opacity: 1;
    }
  `
      : ``}
`;
export const ThS = styled.th<{ name?: TypeColName }>`
  text-align: left;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  ${({ name }) => {
    if (name === 'name') {
      return `
      width: 31.5%;
    `;
    }
    return ``;
  }}
`;

export const BoxUserNameS = styled.div`
  padding: 9px 12px 9px 12px;
  border-radius: 8px;
  background-color: #f4f4f4;
  border: 1px solid transparent;
  cursor: pointer;
  .orgvitals-loader {
    left: 0;
  }

  &:hover {
    background: ${COLORS_RGBA.accent(0.06)};
    border-color: ${COLORS_RGBA.accent(1)};
  }
  &:active {
    background-color: #f4f4f4;
    border: 1px solid transparent;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;

export const GroupsWrapperS = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -8px;
`;
export const GroupNameS = styled.div<{ colorB?: string }>`
  border-radius: 8px;
  padding: 4px 8px;
  white-space: nowrap;
  display: inline-flex;
  margin-left: 8px;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  ${({ colorB }) => {
    if (colorB === group_border_colors.automatic) {
      return `
      border: solid 1px ${COLORS_RGBA.orange(0.8)};
      `;
    }
    if (colorB === group_border_colors.default) {
      return `
       border: solid 1px ${COLORS_RGBA.lightBlue(1)};
        `;
    } else {
      return `
       border: solid 1px rgba(0, 0, 0, 0.2);
        `;
    }
  }}
`;
