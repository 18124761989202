import React from 'react';
import { MAX_IMG_FILE_SIZE } from '../utils/config';

export const useUploadImage = () => {
  const [imageError, setErrors] = React.useState('');
  const [imageChanged, setImageChange] = React.useState(false);
  const [image, setImage] = React.useState<{
    src: string;
    file: File | null;
  }>({
    src: '',
    file: null,
  });
  const refInputUpload = React.useRef<HTMLInputElement | null>(null);
  const onChangeUploadHandler = (
    callBack: (file: File, src: string) => void
  ) => {
    if (!refInputUpload.current) return null;
    if (!refInputUpload.current.files || !refInputUpload.current.files[0])
      return setErrors('Error upload file');

    const file = refInputUpload.current.files[0];

    if (!file.name) return setErrors('Error upload file, no file name');
    const fileNameArray = file.name.split('.');
    const fileType = fileNameArray[fileNameArray.length - 1];
    console.log('-> fileType', fileType);
    if (
      !(
        fileType.toLowerCase() === 'png' ||
        fileType.toLowerCase() === 'jpg' ||
        fileType.toLowerCase() === 'svg' ||
        fileType.toLowerCase() === 'jpeg'
      )
    ) {
      return setErrors('Error upload file, incorrect format');
    }
    if (file.size > MAX_IMG_FILE_SIZE * 1e6)
      return setErrors(`File must be less than ${MAX_IMG_FILE_SIZE} Mb.`);
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result !== 'string') return null;
      setErrors('');
      if (reader.result === image.src) setImageChange(false);
      else setImageChange(true);
      console.log('-> file', file);
      setImage({
        src: reader.result,
        file,
      });
      callBack(file, reader.result);
    };
    reader.readAsDataURL(file);
  };
  const removeImageHandler = (callBack: (file: File, src: string) => void) => {
    setImage({
      src: '',
      file: null,
    });
    callBack(null, '');
  };
  return {
    onChangeUploadHandler,
    removeImageHandler,
    refInputUpload,
    image,
    imageChanged,
    imageError,
    setImage,
  };
};
