import { ANIM_CL } from '../../../utils/animatedStyles';
import { classController } from '../../../utils/classContorller';
import { delay } from '../../../utils/helpers';

export async function closeModal(ref: HTMLDivElement, closeHandler: () => void) {
  const innerW = { ...classController };
  innerW.el = ref;
  innerW.removeClass(ANIM_CL.zoomIn);
  innerW.addClass(ANIM_CL.zoomOut);
  await delay(200);
  closeHandler();
}
