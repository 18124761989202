import React from 'react';
import { AlphabetLetters } from '../../../utils/alphabet';
import { SelectItemType } from '../../ui/selects/DefaultSelect';
import { GroupSelectProps, ESingleOrMultiply } from '../types';
import {HashTable} from "../useDropFile";

export const useGroupSelect = ({
  columns,
  csvData,
  selectHandler,
  isFirstRowHeader,
  groupsValue,
  setGroupsValue,
}: {
  columns: GroupSelectProps['columns'];
  csvData: GroupSelectProps['csvData'];
  selectHandler: GroupSelectProps['selectHandler'];
  isFirstRowHeader: boolean;
  groupsValue: HashTable;
  setGroupsValue: (HashTable) => void;
}) => {
  const [svgColumns, setSvgColumns] = React.useState<SelectItemType[]>([]);
  const [selectedSvgColumn, setSelectedSvgColumn] =
    React.useState<SelectItemType | null>(null);
  const [selectedGroup, setSelectedGroup] =
    React.useState<SelectItemType | null>(null);
  const [selectedSingleOrMultiply, setSelectedSingleOrMultiply] =
    React.useState<SelectItemType | null>(null);
  const [groupsSelect, setGroupsSelect] = React.useState<
    SelectItemType[] | null
  >(null);
  const [selectedColumnGroup, setSelectedColumnGroup] =
    React.useState<SelectItemType | null>(null);
  const [delimiter, setDelimiter] = React.useState('');

  React.useEffect(() => {
    setSelectedGroup(null);
    setSelectedColumnGroup(null);
    setSelectedSvgColumn(null);
    setSelectedColumnGroup(null);
    setDelimiter('');
    const newColumns: SelectItemType[] = [];
    columns.forEach((c, i) => {
      newColumns.push({
        id: i,
        value: `${i}`,
        title: isFirstRowHeader ? c : AlphabetLetters[i],
      });
    });
    setSvgColumns(newColumns);
  }, [columns, isFirstRowHeader]);

  const updateMainSelectValue = React.useCallback(() => {
    if (
      selectedSvgColumn &&
      selectedGroup &&
      selectedSingleOrMultiply &&
      selectedColumnGroup
    ) {
      let gv = groupsValue
      gv[selectedGroup.value] = false;
      setGroupsValue(gv)

      selectHandler({
        title: selectedSvgColumn.title,
        input_value: selectedColumnGroup.title,
        single: selectedSingleOrMultiply.id === ESingleOrMultiply.single,
        multiple: selectedSingleOrMultiply.id === ESingleOrMultiply.multiply,
        input_value_delimiter_char: delimiter,
        column_idx: selectedSvgColumn.value,
        group_id: selectedGroup.value,
      });
    } else if (
        selectedGroup &&
        selectedSingleOrMultiply &&
        !selectedColumnGroup &&
        selectedSvgColumn) {
        let gv = groupsValue
        gv[selectedGroup.value] = true;
        setGroupsValue(gv)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSvgColumn,
    selectedGroup,
    selectedSingleOrMultiply,
    selectedColumnGroup,
    delimiter,
  ]);

  React.useEffect(() => {
    updateMainSelectValue();
  }, [updateMainSelectValue]);

  React.useEffect(() => {
    setDelimiter('');
    setSelectedColumnGroup(null);
  }, [selectedSingleOrMultiply]);

  React.useEffect(() => {
    (() => {
      if (!selectedSvgColumn || !selectedSingleOrMultiply) return null;
      const n = selectedSvgColumn.id as number;
      const groupValues: string[] = [];
      const newGroupsSelect: SelectItemType[] = [];

      if (selectedSingleOrMultiply.id === ESingleOrMultiply.single) {
        setSelectedColumnGroup(null);
        csvData.forEach((row, i) => {
          if (i === 0 && isFirstRowHeader) return null;
          if (row[n] && !groupValues.includes(row[n].toLowerCase())) {
            groupValues.push(row[n].toLowerCase());
            newGroupsSelect.push({
              id: i,
              value: `${i}`,
              title: row[n],
            });
          }
        });
      } else if (
        selectedSingleOrMultiply.id === ESingleOrMultiply.multiply &&
        delimiter
      ) {
        csvData.forEach((row, i) => {
          if (!row[n] || (i === 0 && isFirstRowHeader)) return null;

          const splitData = row[n].split(delimiter);

          if (splitData && splitData[0]) {
            splitData.forEach((sd) => {
              if (sd && !groupValues.includes(sd.toLowerCase())) {
                groupValues.push(sd.toLowerCase());
                newGroupsSelect.push({
                  id: i + sd,
                  value: sd,
                  title: sd,
                });
              }
            });
          }
        });
      }

      return setGroupsSelect(newGroupsSelect);
    })();
  }, [
    csvData,
    selectedSingleOrMultiply,
    selectedSvgColumn,
    delimiter,
    isFirstRowHeader,
  ]);

  return {
    svgColumns,
    selectedSvgColumn,
    selectedGroup,
    selectedSingleOrMultiply,
    groupsSelect,
    selectedColumnGroup,
    setSelectedColumnGroup,
    setSelectedSvgColumn,
    setSelectedGroup,
    setSelectedSingleOrMultiply,
    setGroupsSelect,
    delimiter,
    setDelimiter,
  };
};
