import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { ACTION_CONST, TConsultant } from './types';

const GetConsultantsActions = new Actions(ACTION_CONST.getByTeam, ActionTypes);

export interface TGet_By_Team_R extends TDefRequest {
  teamId: string;
}

export const GetConsultantsByTeam = {
  request: (payload: TGet_By_Team_R) => GetConsultantsActions.request(payload),
  success: (payload: TConsultant[]) => GetConsultantsActions.success(payload),
  error: (message: string) => GetConsultantsActions.error(message),
};

const EditConsultantActions = new Actions(ACTION_CONST.edit, ActionTypes);

export interface TEdit_R extends TDefRequest {
  data: {
    name: string;
  };
  workspaceId: string;
}
export const EditConsultant = {
  request: (payload: TEdit_R) => EditConsultantActions.request(payload),
  success: (payload: TConsultant[]) => EditConsultantActions.success(payload),
  error: (message: string) => EditConsultantActions.error(message),
};

const CreateConsultantActions = new Actions(ACTION_CONST.create, ActionTypes);

export interface TCreate_R extends TDefRequest {
  data: {
    team_id: string;
    created_by_id: string;
    name: string;
  };
}
export const CreateConsultant = {
  request: (payload: TCreate_R) => CreateConsultantActions.request(payload),
  success: (payload: TConsultant) => CreateConsultantActions.success(payload),
  error: (message: string) => CreateConsultantActions.error(message),
};

const DeleteConsultantActions = new Actions(ACTION_CONST.delete, ActionTypes);

export interface TDelete_R extends TDefRequest {
  workspaceId: string;
}
export const DeleteConsultant = {
  request: (payload: TDelete_R) => DeleteConsultantActions.request(payload),
  success: (payload: TConsultant[]) => DeleteConsultantActions.success(payload),
  error: (message: string) => DeleteConsultantActions.error(message),
};

const SetWorkspaceConsultantActions = new Actions(ACTION_CONST.setWorkspace, ActionTypes);

export interface TSetWorkspace_R extends TDefRequest {
  data: {
    relation: boolean;
    consultant_id: string;
    workspace_id: string;
  };
}
export const SetConsultantWorkspace = {
  request: (payload: TSetWorkspace_R) => SetWorkspaceConsultantActions.request(payload),
  success: (payload: TConsultant[]) => SetWorkspaceConsultantActions.success(payload),
  error: (message: string) => SetWorkspaceConsultantActions.error(message),
};
