import React from 'react';

export const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-312 -583) translate(288 75) translate(24 508)">
              <rect width="2" height="24" fill="#D8D8D8" rx="1" />
              <rect
                width="2"
                height="24"
                x="11"
                y="11"
                fill="#4CAF50"
                rx="1"
                transform="rotate(90 12 23)"
              />
              <path
                fill="#4CAF50"
                fillRule="nonzero"
                d="M14.948 16.072c.192 0 .362-.072.51-.216.148-.144.222-.312.222-.504 0-.152-.064-.304-.192-.456l-2.604-3.192 2.508-3.06c.12-.128.18-.28.18-.456 0-.192-.074-.358-.222-.498-.148-.14-.318-.21-.51-.21-.2 0-.372.088-.516.264l-2.34 2.892-2.34-2.892c-.152-.176-.328-.264-.528-.264-.192 0-.36.07-.504.21-.144.14-.216.306-.216.498 0 .176.06.328.18.456l2.508 3.06-2.616 3.192c-.12.144-.18.296-.18.456 0 .192.074.358.222.498.148.14.318.21.51.21.2 0 .372-.084.516-.252l2.448-3.024 2.436 3.024c.152.176.328.264.528.264z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
