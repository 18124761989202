import React from 'react';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { ALL_ASS } from '../utils/consts';
import { useSelector } from 'react-redux';
import { AppStore } from '../store/applicationState';

export const useConvertSelectAss = (
  disableAllAss?: boolean,
  isTeam?: boolean,
  isCollab?: boolean
) => {
  const { Assessments } = useSelector((store: AppStore) => store);
  const [activeAssIds, setActiveAssIds] = React.useState<SelectItemType[]>([]);
  const [activeQuestionIds, setActiveQuestionIds] = React.useState<
    SelectItemType[]
  >([]);
  const [selectDataAss, setSelectDataAss] = React.useState<SelectItemType[]>(
    []
  );
  const [answeredDataAss, setAnsweredDataAss] = React.useState<
    SelectItemType[]
  >([]);
  const [answeredNotCloneAss, setAnsweredNotCloneAss] = React.useState<
    SelectItemType[]
  >([]);
  const PREF = ' (archived)';
  React.useEffect(() => {
    (() => {
      if (!Assessments.existingData) return null;
      if (!Assessments.answered) return null;
      const newAssSelData: SelectItemType[] = [];
      //const newQuestionSelData: SelectItemType[] = [];
      const newAnsweredSelData: SelectItemType[] = [];
      const newAnsweredNonCloneData: SelectItemType[] = [];
      if (!disableAllAss) newAssSelData.push(ALL_ASS);
      const setToAssArray = (dataArray: typeof Assessments.existingData) => {
        dataArray.forEach((itm) => {
          let name = itm.name;
          if (
            dataArray.find(
              (ass) => ass.name === itm.name && ass.id !== itm.id && ass.version
            )
          )
            name = `${itm.name} ${itm.version}`;

          newAssSelData.push({
            title: !itm.is_archived ? name : name + PREF,
            value: itm.id,
            id: itm.id,
            questions: itm.questions,
          });
        });
      };

      if (isCollab) {
        setToAssArray(Assessments.answered);
      } else {
        setToAssArray(Assessments.existingData);
      }

      const setToAnsweredArray = (dataArray: typeof Assessments.answered) => {
        dataArray.forEach((itm) => {
          let name = itm.name;
          if (
            dataArray.find(
              (ass) => ass.name === itm.name && ass.id !== itm.id && ass.version
            )
          )
            name = `${itm.name} ${itm.version}`;

          if (itm.is_cloneable)
            newAnsweredSelData.push({
              title: !itm.is_archived ? name : name + PREF,
              value: itm.id,
              id: itm.id,
              questions: itm.questions,
            });
          newAnsweredNonCloneData.push({
            title: !itm.is_archived ? name : name + PREF,
            value: itm.id,
            id: itm.id,
            questions: itm.questions,
          });
        });
      };
      setToAnsweredArray(Assessments.answered);
      if (!disableAllAss) setActiveAssIds([ALL_ASS]);
      setSelectDataAss(newAssSelData);
      setAnsweredDataAss(newAnsweredSelData);
      setAnsweredNotCloneAss(newAnsweredNonCloneData);
    })();
  }, [Assessments, isTeam, disableAllAss, isCollab]);

  return {
    selectDataAss,
    activeAssIds,
    setActiveAssIds,
    activeQuestionIds,
    setActiveQuestionIds,
    answeredDataAss,
    answeredNotCloneAss,
  };
};
