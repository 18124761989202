import { action } from 'typesafe-actions';
import { putError } from '../../utils/putError';
import ActionTypes, { IMatchingTemplates } from './types';

export const setMatchingTemplateLoading = () => action(ActionTypes.SET_LOADING);

export const getMatchingTemplateR = (
  workspaceId: string,
  callBack?: (success: boolean) => void
) => action(ActionTypes.GET_ONE_R, { callBack, workspaceId });

export const getMatchingTemplateS = (payload: IMatchingTemplates) =>
  action(ActionTypes.GET_ONE_S, payload);

export const getMatchingTemplateE = (message: string) =>
  putError(message, ActionTypes.GET_ONE_E);

