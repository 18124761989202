import React from 'react';
import styled from 'styled-components';

interface Props {
  length: number;
  max: number;
}
export const Counter: React.FC<Props> = ({ length, max }) => {
  return (
    <WrapperS>
      {length}/{max}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
