import React from 'react';

interface SettingsIconProps {}
export const SettingsIcon: React.FC<SettingsIconProps> = () => {
  return (
    <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.925 11.668c-.038-.338-.431-.591-.772-.591-1.1 0-2.075-.646-2.484-1.645a2.673 2.673 0 0 1 .67-2.967.666.666 0 0 0 .074-.905 11.875 11.875 0 0 0-1.901-1.92.668.668 0 0 0-.912.074c-.716.792-2 1.086-2.993.672a2.666 2.666 0 0 1-1.621-2.605.666.666 0 0 0-.59-.701c-.896-.104-1.8-.107-2.699-.007a.668.668 0 0 0-.594.686A2.67 2.67 0 0 1 9.46 4.32c-.981.401-2.257.11-2.971-.676a.67.67 0 0 0-.906-.076 11.92 11.92 0 0 0-1.941 1.92.668.668 0 0 0 .072.912 2.66 2.66 0 0 1 .67 2.994c-.414.986-1.438 1.622-2.61 1.622a.652.652 0 0 0-.694.589 12.02 12.02 0 0 0-.005 2.729c.038.339.443.59.787.59 1.045-.027 2.048.62 2.47 1.644a2.668 2.668 0 0 1-.672 2.968.667.667 0 0 0-.073.904c.558.71 1.198 1.356 1.899 1.92.275.222.675.19.913-.072.718-.794 2.003-1.088 2.992-.673a2.661 2.661 0 0 1 1.623 2.605c-.02.353.239.66.59.7a11.928 11.928 0 0 0 2.699.008.668.668 0 0 0 .594-.687 2.667 2.667 0 0 1 1.642-2.56c.987-.403 2.258-.107 2.972.677a.67.67 0 0 0 .906.076 11.947 11.947 0 0 0 1.94-1.92.666.666 0 0 0-.07-.912 2.659 2.659 0 0 1-.673-2.993 2.687 2.687 0 0 1 2.455-1.627l.148.004a.668.668 0 0 0 .703-.588c.105-.903.107-1.82.005-2.73zM13 16c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"
        fillRule="nonzero"
        stroke="white"
        opacity="0.7"
        strokeWidth="2"
        fill="none"
      />
    </svg>
  );
};
