export interface TUser {
  profile_picture_url: string;
  email: string;
  created_at: string;
  updated_at: string;
  id: string;
  team_id: string;
  first_name: string;
  last_name: string;
  workspaces_ids: string[];
  team: {
    id: string;
    created_at: string;
    title: string;
    updated_at: string;
  };
}

export interface TUserState {
  readonly loading: boolean;
  readonly data: TUser | null;
  readonly isLoggedIn: boolean;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_USER_R = '@@user/GET_USER_R',
  GET_USER_S = '@@user/GET_USER_S',
  GET_USER_E = '@@user/GET_USER_E',

  GET_ME_R = '@@user/GET_ME_R',
  GET_ME_S = '@@user/GET_ME_S',
  GET_ME_E = '@@user/GET_ME_E',

  LOG_OUT_R = '@@user/LOG_OUT_R',
  LOG_OUT_S = '@@user/LOG_OUT_S',
  LOG_OUT_E = '@@user/LOG_OUT_E',

  UPDATE_R = '@@user/UPDATE_R',
  UPDATE_S = '@@user/UPDATE_S',
  UPDATE_E = '@@user/UPDATE_E',

  UPDATE_PASS_R = '@@user/UPDATE_PASS_R',
  UPDATE_PASS_S = '@@user/UPDATE_PASS_S',
  UPDATE_PASS_E = '@@user/UPDATE_PASS_E',

  FORGOT_PASS_R = '@@user/FORGOT_PASS_R',
  FORGOT_PASS_S = '@@user/FORGOT_PASS_S',
  FORGOT_PASS_E = '@@user/FORGOT_PASS_E',

  SET_WORKSPACE = '@@user/SET_WORKSPACE',

  CLEAN_UP = '@@user/CLEAN_UP',
}

export default ActionTypes;
