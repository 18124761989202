import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../utils/colors';

export const WrapperS = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;
export const DropWrapperS = styled.div<{ disabled?: boolean }>`
  border-radius: 8px;
  border: dashed 1px ${COLORS_RGBA.accent()};
  background-color: #f6f8f6;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.2)};
  p {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    span {
      color: ${COLORS_RGBA.accent()};
      cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'no-drop')};

      &:hover {
        color: ${COLORS.accentHover};
      }
      &:active {
        color: ${COLORS.accentActive};
      }
    }
  }
`;

export const WrapperCsvInfoS = styled.div`
  h4 {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #171717;
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: flex;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #171717;
      margin-top: 7px;
      svg {
        margin-right: 8px;
        margin-top: 2px;
        path {
          fill: ${COLORS_RGBA.accent()};
        }
      }
      span {
        flex: 1;
      }
    }
  }
`;

export const SelectColumnWrapperS = styled.div`
  display: flex;
  align-items: center;

  & > div {
    /* flex: 1; */
    /* max-width: 200px; */
  }

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CheckBoxWrapperS = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-size: 16px;
    margin-left: 10px;
  }
`;

export const InfoIS = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: ${COLORS.accent};
  color: white;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  margin-left: 10px;

  & > div {
    display: none;
    position: absolute;
    bottom: 100%;
    width: 300px;
    left: 50%;
    bottom: 100%;
    padding: 5px 16px;
    border-radius: 10px;
    box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
    border: solid 1px rgba(162, 165, 162, 0.15);
    color: grey;
    text-align: left;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`;

export const GroupWrapperS = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  & > * {
    &:first-child {
      /* flex: 1; */

      & > div {
        min-width: 190px;
        max-width: 190px;
      }

      & > svg {
        width: 10px;
        height: 10px;
        max-width: 10px;
        max-height: 10px;
      }

      .ov-select {
        ul {
          min-width: 240px;
        }
      }
    }
  }
`;
