import React from 'react';
import { WorkspacesAnswers } from './WorkspacesAnswers';
import { useWorkspaceInfo } from './hooks/useWorkspaceInfo';
import { ButtonCancel, ButtonSave } from '../ui';
import { EAdditionalInfoText } from '../../store/workspaces/types';
import styled from 'styled-components';

export const AdditionalInfo: React.FC = () => {
  const { chbData, isLoading, saveBtnHandler, radioHandler } =
    useWorkspaceInfo();
  const [editMode, setEditMode] = React.useState(false);

  const renderBody = () => {
    if (!chbData.formation_type || editMode) {
      return (
        <>
          <WorkspacesAnswers
            radioHandler={(t, v) => {
              if (!editMode) setEditMode(true);
              radioHandler(t, v);
            }}
            data={chbData}
          />
          <BtnsWrapperS>
            <ButtonCancel onClick={() => setEditMode(false)} />
            <ButtonSave
              loading={isLoading}
              onClick={() => {
                saveBtnHandler();
                setEditMode(false);
              }}
            />
          </BtnsWrapperS>
        </>
      );
    }

    type P = keyof typeof chbData;
    return (
      <>
        <WrapperQS>
          {Object.keys(chbData).map((key) => {
            if (key === 'main_contact') return null;
              if (key === 'group_classification') return null;
            const text = chbData[key as P];
            if (!text) return null;
            return (
              <li key={key}>
                <b>{EAdditionalInfoText[key as P]}</b>
                <span>{text}</span>
              </li>
            );
          })}
        </WrapperQS>
        <BtnsWrapperS>
          <ButtonSave title={'Edit'} onClick={() => setEditMode(true)} />
        </BtnsWrapperS>
      </>
    );
  };

  return renderBody();
};

const WrapperQS = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  border: 1px solid rgba(191, 190, 190, 0.35);
  display: flex;
  border-radius: 6px;
  max-width: 505px;
  flex-direction: column;
  li {
    padding: 20px;
    border-bottom: 1px solid rgba(191, 190, 190, 0.35);
    span {
      display: block;
      margin-top: 7px;
    }
    &:last-child {
      border: 0;
      border-radius: 6px;
    }
  }
`;

const BtnsWrapperS = styled.div`
  display: flex;
  margin: 50px 0;

  & > * {
    width: 150px;
    margin-right: 20px;
  }
`;
