import React from 'react';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { Alert, Loader } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import { TDelayedMessage, TPackage } from '../../store/packages/types';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { COLORS } from '../../utils/colors';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { LabelS } from '../ui/inputs/inputStyles';

interface Props {
  closeHandler: () => void;
  message: TDelayedMessage;
  pack: TPackage;
}

export const CreatableSelectComponents = {
  DropdownIndicator: null,
};

export interface CreatableSelectOption {
  readonly label: string;
  readonly value: string;
}

export const createCreatableSelectOption = (label: string) => ({
  label,
  value: label,
});

export const customCreatableSelectStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '1.6rem',
    border: `1px solid ${COLORS.secondary}`,
    '&:hover': {
      border: `1px solid ${COLORS.accent}`,
    },
    '&:active': {
      border: `1px solid ${COLORS.accent}`,
    },
    boxShadow: 'none',
    //   minHeight: '48px',
    //   height: '48px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    //   height: '48px',
    padding: '0 6px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),

  indicatorSeparator: (state) => ({
    display: 'none',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    //   height: '48px',
  }),

  multiValueRemove: (base, state) => ({
    ...base,
    '&:hover': {
      color: COLORS.dangerActive,
    },
  }),
};

export const SendTestAssPackage: React.FC<Props> = ({
  closeHandler,
  message,
  pack,
}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState('');
  const [isSend, setIsSend] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<readonly CreatableSelectOption[]>(
    []
  );

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case ' ':
      case ',':
      case 'Tab':
        setValue((prev) => [...prev, createCreatableSelectOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  const sendTestHandler = async (emails: readonly CreatableSelectOption[]) => {
    try {
      setLoading(true);
      const participant_email_info = [];
      emails.forEach((val) => {
        participant_email_info.push({ email: val.value });
      });
      const data = {
        participant_email_info,
        send_package_via_email: {
          html_content: message.metadata.email_content,
          subject: message.metadata.email_subject,
        },
      };
      const resp = await callApi({
        path: API_ROUTE_PATH.packages.testSend(pack.id),
        method: 'post',
        data,
      });

      if (!resp.emails_sent_number) {
        return setErrors('Error! No emails was sent');
      }

      if (
        resp.requests_errors &&
        Array.isArray(resp.requests_errors) &&
        resp.requests_errors[0]
      ) {
        return setErrors((resp.requests_errors as string[]).join(', '));
      }

      setIsSend(true);
    } catch (e) {
      console.log(e);
      setErrors(e);
      setTimeout(() => {
        setErrors('');
      }, 10000);
    } finally {
      setLoading(false);
    }
  };

  const renderBody = () => {
    if (isSend) {
      return <Alert text="Test email was sent" type={'success'} />;
    }
    if (errors) {
      return <Alert text={errors} type={'danger'} />;
    }
    return (
      <>
        <ThirdStepWrapperS>
          <LabelS>
            <span>Emails to receive test mail:</span>
            <CreatableSelect
              components={CreatableSelectComponents}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder="Type emails and press enter..."
              value={value}
              styles={customCreatableSelectStyles}
            />
          </LabelS>
        </ThirdStepWrapperS>
        {loading && <Loader isGreen />}
      </>
    );
  };

  const renderBnts = () => {
    if (errors || isSend) {
      return (
        <ButtonAlternativeWrapperS>
          <ButtonBorderedS
            isCancel
            onClick={() => {
              closeModalHandler();
            }}
          >
            Close
          </ButtonBorderedS>
        </ButtonAlternativeWrapperS>
      );
    }
    return (
      <ButtonAlternativeWrapperS>
        <ButtonBorderedS
          isCancel
          onClick={() => {
            closeModalHandler();
          }}
        >
          Cancel
        </ButtonBorderedS>
        <ButtonDefS
          isSave
          onClick={() => {
            sendTestHandler(value);
          }}
          disabled={!value || !value[0]}
        >
          Send
        </ButtonDefS>
      </ButtonAlternativeWrapperS>
    );
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '416px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <InnerWrapperS>
          <MainWrapperS>
            <MainInnerWrapperS>
              <Styles.ModalTileS>Send Test Message</Styles.ModalTileS>
              <TitleS>{message.metadata.email_subject}</TitleS>
              {renderBody()}
            </MainInnerWrapperS>
            <Styles.BtnsWrapperS>{renderBnts()}</Styles.BtnsWrapperS>
          </MainWrapperS>
        </InnerWrapperS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};

const InnerWrapperS = styled.div`
  display: flex;
  position: relative;
  max-height: 100%;
  overflow: auto;
`;
const MainWrapperS = styled.div`
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MainInnerWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 24px 12px;
`;
const ThirdStepWrapperS = styled.div`
  height: 400px;
  margin-top: 15px;
`;
const ButtonAlternativeWrapperS = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  max-height: 60px;
`;
const TitleS = styled.h4`
  margin: 10px 0 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c;
`;
