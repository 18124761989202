import React from 'react';

interface SortIconProps {}

export const SortIcon: React.FC<SortIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
      <g fill="none" fillRule="evenodd" opacity=".23">
        <g fill="#2A2F2A">
          <g>
            <g>
              <path
                d="M43.722 13.295L46 15.568l2.278-2.273c.394-.393 1.033-.393 1.427 0 .393.393.393 1.031 0 1.424l-2.992 2.986c-.394.393-1.032.393-1.426 0l-2.992-2.986c-.393-.393-.393-1.03 0-1.424.394-.393 1.033-.393 1.427 0zm2.991-7l2.992 2.986c.393.393.393 1.03 0 1.424-.394.393-1.033.393-1.427 0L46 8.432l-2.278 2.273c-.394.393-1.033.393-1.427 0-.393-.393-.393-1.031 0-1.424l2.992-2.986c.394-.393 1.032-.393 1.426 0z"
                transform="translate(-342 -154) translate(288 148) translate(12)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
