import jwt_decode from 'jwt-decode';
import { API_ROUTE_PATH } from './api_routes';
import { callApi } from './callApi';
import { TOKEN } from './config';
import { devPrint } from './print';

let refreshStarted = false;

const cash: {
  [key: string]: {
    at_id?: string;
    authorized?: boolean;
    exp?: number;
    iat?: number;
    usr_id?: string;
  };
} = {};

export interface ReturnType {
  usrId: string;
  success: boolean;
  token: string;
}

const returnFailObj = {
  usrId: '',
  success: false,
  token: '',
};

const returnFail = (): ReturnType => {
  localStorage.clear();
  return returnFailObj;
};

const refresh = async (): Promise<ReturnType> => {
  const refreshToken = localStorage.getItem(TOKEN.refresh);
  if (!refreshToken) return returnFail();

  const decodeToken: {
    at_id?: string;
    authorized?: boolean;
    exp?: number;
    iat?: number;
    usr_id?: string;
  } = jwt_decode(refreshToken || '');

  if (!decodeToken) {
    return returnFail();
  }

  if (!decodeToken.exp || !decodeToken.usr_id) {
    localStorage.clear();
    return returnFail();
  }
  const isExpired = Date.now() >= decodeToken.exp * 1000;

  if (isExpired) {
    return returnFail();
  }

  if (refreshStarted) {
    console.log('-> waiting for token update');
    return new Promise((resp) => {
      const interval = setInterval(() => {
        if (!refreshStarted) {
          const token = localStorage.getItem(TOKEN.access);
          resp(
            token
              ? {
                  success: true,
                  token,
                  usrId: decodeToken.usr_id,
                }
              : returnFail()
          );
          clearInterval(interval);
        }
      }, 200);
    });
  }

  refreshStarted = true;

  try {
    const data = await callApi({
      path: API_ROUTE_PATH.authentication.refreshToken,
      method: 'post',
      subPath: '/api/v1/auth',
      refreshToken,
    });

    if (!data.access_token || !data.refresh_token) {
      return returnFail();
    }
    const decodeAccessToken: {
      at_id?: string;
      authorized?: boolean;
      exp?: number;
      iat?: number;
      usr_id?: string;
    } = jwt_decode(data.access_token || '');

    if (!decodeAccessToken) {
      devPrint('checkToken.ts decodeAccessToken', 11);
      return returnFail();
    }

    if (!decodeAccessToken.exp || !decodeAccessToken.usr_id) {
      devPrint('checkToken.ts decodeAccessToken', 22);
      return returnFail();
    }

    cash[data.access_token] = decodeAccessToken;
    localStorage.setItem(TOKEN.access, data.access_token);
    localStorage.setItem(TOKEN.refresh, data.refresh_token);

    // window.location.reload();

    devPrint('Token was decoded and refreshed checkToken.ts', decodeToken);

    return {
      success: true,
      token: data.access_token,
      usrId: decodeToken.usr_id,
    };
  } catch (e) {
    window.location.reload();
    return returnFail();
  } finally {
    refreshStarted = false;
  }
};

export async function checkToken(): Promise<ReturnType> {
  const accessToken = localStorage.getItem(TOKEN.access);
  if (!accessToken) return returnFail();
  let decodeToken: {
    at_id?: string;
    authorized?: boolean;
    exp?: number;
    iat?: number;
    usr_id?: string;
  };

  if (cash[accessToken]) {
    devPrint('checkToken.ts token was got from cash');
    decodeToken = cash[accessToken];
  } else {
    decodeToken = jwt_decode(accessToken || '');
  }

  if (!decodeToken) {
    devPrint('checkToken.ts', 1);
    return returnFail();
  }

  if (!decodeToken.exp || !decodeToken.usr_id) {
    devPrint('checkToken.ts', 2);
    return returnFail();
  }

  const isExpired = Date.now() >= decodeToken.exp * 1000;
  if (isExpired) {
    delete cash[accessToken];
    devPrint('checkToken.ts', 3);
    return await refresh();
  }

  if (!cash[accessToken]) cash[accessToken] = decodeToken;

  return {
    usrId: decodeToken.usr_id,
    success: true,
    token: accessToken,
  };
}
