import React from 'react';

interface ArrowRightIconProps {}

export const ArrowRightIcon: React.FC<ArrowRightIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
      <g fill="none" fillRule="evenodd">
        <g fill="#3C9C40">
          <g>
            <path
              d="M541.705 21.293l4.002 4.002c.39.39.39 1.024 0 1.414l-4.002 4.002c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414L542.585 27H535c-.552 0-1-.447-1-1 0-.551.448-1 1-1h7.585l-2.294-2.294c-.39-.39-.39-1.024 0-1.414.39-.39 1.023-.39 1.414 0z"
              transform="translate(-969 -430) translate(435 409)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
