import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { TParticipantFail } from '../../store/participants/types';
import DatePicker from 'react-datepicker';
import { countryDial } from '../../utils/countryDial';
import { SelectListS } from '../Responding/styles';
import { ButtonClose, Input } from '../ui';
import { defFields, IParticipantsInputs } from './types/AddParticipantsTypes';
import { COLORS_RGBA } from '../../utils/colors';

interface AddParticipantsTrProps {
  participant: typeof defFields;
  position: number;
  widthError?: boolean;
  removeHandler: (id: number) => void;
  onlyEmail?: boolean;
  onChange: (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>,
    withErrors?: boolean,
    date?: Date,
    label?: string
  ) => void;
  failParticipants: TParticipantFail | undefined;
}

export const AddParticipantsTr: React.FC<AddParticipantsTrProps> = ({
  participant,
  position,
  removeHandler,
  onChange,
  widthError,
  onlyEmail,
  failParticipants,
}) => {
  const [isListVisible, setIsListVisible] = React.useState(false);
  const { ref: refList } = useOutsideClick(() => setIsListVisible(false));
  const mainRef = React.useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = React.useState('');
  const redStyle = widthError
    ? {
        background: COLORS_RGBA.danger(0.1),
        border: `1px solid ${COLORS_RGBA.danger(0.1)}`,
      }
    : {};

  const moreRedStyle = failParticipants
    ? {
        background: '#f8d7da',
        border: `1px solid ${COLORS_RGBA.danger(0.2)}`,
      }
    : {};
  const checkForZero = (value: string) => {
    if (Array.from(value)[0] === '0') {
      participant[IParticipantsInputs.phone] = value.substr(1);
    }
  };
  React.useEffect(() => {
    mainRef.current.scrollIntoView();
  }, [mainRef]);
  return (
    <>
      <WrapperS
        key={participant.id}
        style={(widthError && redStyle) || (failParticipants && moreRedStyle)}
        ref={mainRef}
      >
        <NumberWrapperS>
          <span>{`Participant ${position + 1}`}</span>
        </NumberWrapperS>
        <InnerWrapperS>
          <BlockWrapperS style={{ marginTop: 10 }}>
            <LabelS>Name</LabelS>
            <NameInnerWrapperS>
              <HalfWrapperS>
                <Input
                  name={`${IParticipantsInputs.first_name}-${position}${
                    widthError ? '-widthError' : ''
                  }`}
                  placeholder="First Name"
                  onChange={onChange}
                  value={participant[IParticipantsInputs.first_name]}
                />
              </HalfWrapperS>
              <HalfWrapperS>
                <Input
                  name={`${IParticipantsInputs.last_name}-${position}${
                    widthError ? '-widthError' : ''
                  }`}
                  placeholder="Last Name"
                  onChange={onChange}
                  value={participant[IParticipantsInputs.last_name]}
                />
              </HalfWrapperS>
            </NameInnerWrapperS>
          </BlockWrapperS>
          <BlockWrapperS style={{ marginTop: 10 }}>
            <Input
              placeholder="Email"
              name={`${IParticipantsInputs.email}-${position}${
                widthError ? '-widthError' : ''
              }`}
              type="email"
              value={participant[IParticipantsInputs.email]}
              onChange={onChange}
              label={'Email'}
            />
          </BlockWrapperS>
          {onlyEmail ? null : (
            <>
              <BlockWrapperS style={{ marginTop: 10 }}>
                <LabelS>Phone</LabelS>
                <PhoneInnerWrapperS>
                  <QuaterWrapperS>
                    <input
                      name={`${IParticipantsInputs.phone_code}-${position}${
                        widthError ? '-widthError' : ''
                      }`}
                      type="text"
                      placeholder="Code"
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={participant[IParticipantsInputs.phone_code]}
                      onClick={() => {
                        setIsListVisible(true);
                      }}
                      readOnly
                    />

                    {isListVisible && (
                      <SearchWrapperS ref={refList}>
                        <SearchInputS
                          type="text"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.currentTarget.value);
                          }}
                          placeholder={'Search'}
                          autoFocus
                        />
                        <SelectCountryListS>
                          {!searchValue
                            ? countryDial.map((itm, i) => (
                                <li
                                  key={i + itm.name + itm.code}
                                  onClick={() => {
                                    participant[
                                      IParticipantsInputs.phone_code
                                    ] = `${countryDial[i].flag} ${countryDial[i].dial_code}`;
                                    setIsListVisible(false);
                                  }}
                                >
                                  {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                                </li>
                              ))
                            : countryDial
                                .filter(
                                  (itm) =>
                                    itm.code.includes(
                                      searchValue.toUpperCase()
                                    ) ||
                                    itm.name
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase()) ||
                                    itm.dial_code.includes(searchValue)
                                )
                                .map((itm) => (
                                  <li
                                    key={
                                      countryDial.findIndex(
                                        (country) => itm.name === country.name
                                      ) +
                                      itm.name +
                                      itm.code
                                    }
                                    onClick={() => {
                                      participant[
                                        IParticipantsInputs.phone_code
                                      ] = `${itm.flag} ${itm.dial_code}`;
                                      setIsListVisible(false);
                                    }}
                                  >
                                    {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                                  </li>
                                ))}
                        </SelectCountryListS>
                      </SearchWrapperS>
                    )}
                  </QuaterWrapperS>
                  <ThreeQuatersWrapperS>
                    <Input
                      placeholder={`9999999999`}
                      onChange={(e) => {
                        onChange(e);
                        checkForZero(e.currentTarget.value);
                      }}
                      name={`${IParticipantsInputs.phone}-${position}${
                        widthError ? '-widthError' : ''
                      }`}
                      value={participant[IParticipantsInputs.phone]}
                    />
                  </ThreeQuatersWrapperS>
                </PhoneInnerWrapperS>
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                  placeholder="ID"
                  onChange={onChange}
                  name={`${IParticipantsInputs.payroll_id}-${position}${
                    widthError ? '-widthError' : ''
                  }`}
                  value={participant[IParticipantsInputs.payroll_id]}
                  label={'ID'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                    name={`${IParticipantsInputs.job}-${position}${
                        widthError ? '-widthError' : ''
                    }`}
                    placeholder="Job"
                    onChange={onChange}
                    value={participant[IParticipantsInputs.job]}
                    label={'Job'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <NameInnerWrapperS>
                  <HalfWrapperS>
                    <LabelS>Start Date</LabelS>
                    <DatePicker
                        placeholderText="yyyy.mm.dd"
                        name={`${IParticipantsInputs.hiring_date}-${position}${
                            widthError ? '-widthError' : ''
                        }`}
                        value={participant[IParticipantsInputs.hiring_date]}
                        onChange={(date, event) => {
                          onChange(
                              event,
                              false,
                              date as Date,
                              `${IParticipantsInputs.hiring_date}-${position}${
                                  widthError ? '-widthError' : ''
                              }`
                          );
                        }}
                        maxDate={new Date()}
                    />
                  </HalfWrapperS>
                  <HalfWrapperS>
                    <LabelS>Date of Birth</LabelS>
                    <DatePicker
                        placeholderText="yyyy.mm.dd"
                        name={`${IParticipantsInputs.birth_date}-${position}${
                            widthError ? '-widthError' : ''
                        }`}
                        value={participant[IParticipantsInputs.birth_date]}
                        onChange={(date, event) => {
                          onChange(
                              event,
                              false,
                              date as Date,
                              `${IParticipantsInputs.birth_date}-${position}${
                                  widthError ? '-widthError' : ''
                              }`
                          );
                        }}
                        maxDate={new Date()}
                        showYearDropdown
                        popperPlacement="bottom"
                        dropdownMode="select"
                    />
                  </HalfWrapperS>
                </NameInnerWrapperS>
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                    name={`${IParticipantsInputs.gender}-${position}${
                        widthError ? '-widthError' : ''
                    }`}
                    placeholder="Gender"
                    onChange={onChange}
                    value={participant[IParticipantsInputs.gender]}
                    label={'Gender'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                  name={`${IParticipantsInputs.ethnicity}-${position}${
                    widthError ? '-widthError' : ''
                  }`}
                  placeholder="Ethnicity"
                  onChange={onChange}
                  value={participant[IParticipantsInputs.ethnicity]}
                  label={'Ethnicity'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                  name={`${IParticipantsInputs.pay_group}-${position}${
                    widthError ? '-widthError' : ''
                  }`}
                  placeholder="Pay Group"
                  onChange={onChange}
                  value={participant[IParticipantsInputs.pay_group]}
                  label={'Pay Group'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                    name={`${IParticipantsInputs.department}-${position}${
                        widthError ? '-widthError' : ''
                    }`}
                    placeholder="Department"
                    onChange={onChange}
                    value={participant[IParticipantsInputs.department]}
                    label={'Department'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                    name={`${IParticipantsInputs.segment}-${position}${
                        widthError ? '-widthError' : ''
                    }`}
                    placeholder="Department Segment"
                    onChange={onChange}
                    value={participant[IParticipantsInputs.segment]}
                    label={'Department Segment'}
                />
              </BlockWrapperS>
              <BlockWrapperS>
                <Input
                    name={`${IParticipantsInputs.home_base}-${position}${
                        widthError ? '-widthError' : ''
                    }`}
                    placeholder="Division"
                    onChange={onChange}
                    value={participant[IParticipantsInputs.home_base]}
                    label={'Division'}
                />
              </BlockWrapperS>
              <WorkLocationWrapperS>
                <LabelS>Work Location</LabelS>
                <WorkLocationInnerWrapperS>
                  <QuaterWrapperS>
                    <Input
                      name={`${
                        IParticipantsInputs.work_location_name
                      }-${position}${widthError ? '-widthError' : ''}`}
                      placeholder="Name"
                      onChange={onChange}
                      value={
                        participant[IParticipantsInputs.work_location_name]
                      }
                      label={'Name'}
                    />
                  </QuaterWrapperS>
                  <QuaterWrapperS>
                    <Input
                      name={`${
                        IParticipantsInputs.work_location_city
                      }-${position}${widthError ? '-widthError' : ''}`}
                      placeholder="City"
                      onChange={onChange}
                      value={
                        participant[IParticipantsInputs.work_location_city]
                      }
                      label={'City'}
                    />
                  </QuaterWrapperS>
                  <QuaterWrapperS>
                    <Input
                      name={`${
                        IParticipantsInputs.work_location_state
                      }-${position}${widthError ? '-widthError' : ''}`}
                      placeholder="State"
                      onChange={onChange}
                      value={
                        participant[IParticipantsInputs.work_location_state]
                      }
                      label={'State'}
                    />
                  </QuaterWrapperS>
                  <QuaterWrapperS>
                    <Input
                      name={`${
                        IParticipantsInputs.work_location_country
                      }-${position}${widthError ? '-widthError' : ''}`}
                      placeholder="Country"
                      onChange={onChange}
                      value={
                        participant[IParticipantsInputs.work_location_country]
                      }
                      label={'Country'}
                    />
                  </QuaterWrapperS>
                </WorkLocationInnerWrapperS>
              </WorkLocationWrapperS>
            </>
          )}
        </InnerWrapperS>
        <CloseWrapperS>
          <ButtonClose onClick={() => removeHandler(position)} />
        </CloseWrapperS>
        {failParticipants && <ErrorTextS>{failParticipants.error}</ErrorTextS>}
      </WrapperS>
    </>
  );
};
// const ErrorTdS = styled.td`
//   margin: 0;
//   padding: 0;
//   background: ${COLORS_RGBA.danger(0.1)};
//   border-bottom: 2px solid ${COLORS_RGBA.danger(0.2)};
//   color: #842029;
//   background-color: #f8d7da;
//   border-color: #f5c2c7;
// `;
const LabelS = styled.span`
  font-size: 1.4rem;
  display: block;
  color: rgba(42, 47, 42, 0.8);
  margin-bottom: 5px;
  font-weight: 400;
`;
const WrapperS = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  padding: 20px 10px;
  border: solid 1px #d4d5d4;
  margin-bottom: 10px;
  position: relative;
  * > input {
    margin-bottom: 5px;
  }
`;
const InnerWrapperS = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const BlockWrapperS = styled.div`
  width: 32%;

  margin-bottom: 10px;
`;
const WorkLocationWrapperS = styled.div`
  width: 100%;
`;
const WorkLocationInnerWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
`;
const NameInnerWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PhoneInnerWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
`;
const QuaterWrapperS = styled.div`
  width: 24%;
`;
const HalfWrapperS = styled.div`
  width: 49%;
`;
const ThreeQuatersWrapperS = styled.div`
  width: 74%;
`;
const CloseWrapperS = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const NumberWrapperS = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
`;
const ErrorTextS = styled.p`
  margin: 0;
  padding: 0;
`;

const SearchInputS = styled(Input)``;
const SelectCountryListS = styled(SelectListS)``;
const SearchWrapperS = styled.div`
  position: absolute;
  width: 300px;
  z-index: 100;
`;
