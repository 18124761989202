import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {ParticipantSources, TGroupSelect} from "../../../store/groups/types";
import {AppStore} from "../../../store/applicationState";
import {useGroups} from "../../../hooks/useGroups";
import {ALL_GROUPS, ALL_PARTICIPANTS} from "../../../utils/consts";
import {SelectItemType} from "../../ui/selects/DefaultSelect";
import {deepClone} from "../../../utils/deepClone";


interface HashTable {
  [key: string]: TGroupSelect[];
}

export interface HashTableActiveGroup {
  [key: string]: IsActiveObject[];
}

export interface IsActiveObject {
  id: string;
  title: string;
  is_active: boolean
}

export const useSendPackOS = () => {
  const { Groups, Workspaces } = useSelector(
    (store: AppStore) => store
  );
  const dispatch = useDispatch();

  const [selectDataOriginSource, setSelectDataOriginSource] = React.useState<
      SelectItemType[]
  >([]);
  const [activeOriginSource, setActiveOriginSource] = React.useState<IsActiveObject[]>([])
  const [activeGroups, setActiveGroups] = React.useState<HashTableActiveGroup>({})

  const [activeGroupIds, setActiveGroupIds] = React.useState<SelectItemType[]>(
    []
  );
  useGroups();

  const setActiveGroupsHandler = (activeIds: string[]) => {
    if (!activeIds || !activeIds[0]) return null;

    const newSelects: SelectItemType[] = [];

    Groups.data.forEach((itm) => {
      if (activeIds.includes(itm.id))
        newSelects.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
    });

    return setActiveGroupIds(newSelects);
  };

  React.useEffect(() => {
    if (Workspaces.current && Workspaces.current.id) {
      setActiveGroupIds([ALL_PARTICIPANTS]);
    }
    // eslint-disable-next-line
  }, [Workspaces, dispatch]);




  React.useEffect(() => {
    const setDataOriginSource = () => {
      if (!Groups.data) return null;
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
      const newActiveGroups: HashTableActiveGroup = {}
      const newActiveOriginSource: IsActiveObject[]  = [{
        id: ALL_PARTICIPANTS.id,
        title: ALL_PARTICIPANTS.title,
        is_active: true
      }];

      if (Groups.data && Groups.data[1]) {
        newSelects.push(ALL_GROUPS);
        newActiveOriginSource.push({
          id: ALL_GROUPS.id,
          title: ALL_GROUPS.title,
          is_active: false
        })
      }

      const hashTable: HashTable = {};

      const arrOfKeys: string[] = []

      Groups.data.forEach((itm) => {
        const os = itm.origin_source !== '' ? itm.origin_source : 'default'

        if (!hashTable[os]) {
          arrOfKeys.push(os)
          hashTable[os] = []
          newActiveGroups[os] = []

          newActiveGroups[os].push({
            id: ALL_GROUPS.id,
            title: ALL_GROUPS.title,
            is_active: false
          })
        }

        hashTable[os].push({
          id: itm.id,
          origin_source: os,
          name: itm.name
        })

        newActiveGroups[os].push({
          id:itm.id,
          title: itm.name,
          is_active: false
        })
      });

      arrOfKeys.sort()

      arrOfKeys.forEach((key, idx) => {
       if (key !== 'default') {
         newSelects.push({
           id: key,
           value: key,
           title: ParticipantSources[key],
           groups: hashTable[key],
         })

         newActiveOriginSource.push({
           id: key,
           title: ParticipantSources[key],
           is_active: false
         });
       }

        if (idx === arrOfKeys.length-1) {
          newSelects.push({
            id: 'default',
            value: 'default',
            title: ParticipantSources[''],
            groups: hashTable['default'],
          })

          newActiveOriginSource.push({
            id: 'default',
            title: ParticipantSources[''],
            is_active: false
          });
        }
      })

      setActiveGroups(newActiveGroups)
      setActiveOriginSource(newActiveOriginSource)
      setSelectDataOriginSource(newSelects);
    };

    if (Groups.data && Groups.data[0] && !Groups.loading) {
      setDataOriginSource();
    } else if (!Groups.data || !Groups.data[0]) {
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
      setSelectDataOriginSource(newSelects);
    }
  }, [Groups.data, Groups.loading]);

  React.useEffect(() => {

    const groups = getGroupIds(activeGroups, activeOriginSource[0]?.is_active)
    setActiveGroupsHandler(groups)
    console.log(activeGroups)
    // eslint-disable-next-line
  }, [activeGroups]);

  React.useEffect(() => {
    console.log(activeGroupIds)
    // eslint-disable-next-line
  }, [activeGroupIds]);

  const setResetGroups = (dataGroup:  HashTableActiveGroup, key: string, value: boolean) => {
    if (dataGroup.hasOwnProperty(key)) {
      dataGroup[key]?.length > 0 && dataGroup[key].forEach((obj, idx) => {
        dataGroup[key][idx].is_active = value
      })
    }
  }

  const setAllValueOriginSource = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], value: boolean) => {
    dataOriginSource?.length > 0 && dataOriginSource.forEach((itm, idx) => {
      if (itm.id !== ALL_PARTICIPANTS.id) {
        dataOriginSource[idx].is_active = value
        setResetGroups(dataGroup, itm.id, value)
      } else {
        dataOriginSource[idx].is_active = !value
      }
    })
  }

  const setOnceValueOriginSource = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string) => {
    const idx = dataOriginSource.findIndex((it) => it.id === originSource)
    if (idx >= 0) {
      dataOriginSource[idx].is_active = !dataOriginSource[idx].is_active
      setResetGroups(dataGroup, dataOriginSource[idx].id, dataOriginSource[idx].is_active)
      dataOriginSource[0].is_active = checkAllParticipants(dataGroup)
      dataOriginSource[1].is_active = checkAllGroups(dataGroup)
    }
  }

  const setAllValueGroup = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string, value: boolean) => {
    dataGroup[originSource].forEach((itm, idx) => {
      dataGroup[originSource][idx].is_active = value
    })

    dataOriginSource[1].is_active = checkAllGroups(dataGroup)
  }

  const setOnceValueGroup = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string, groupId: string) => {
    if (dataGroup[originSource]?.length < 3 && originSource !== 'default')
      return

    const idx = dataGroup[originSource].findIndex((it) => it.id === groupId)
    if (idx >= 0) {
      dataGroup[originSource][idx].is_active = !dataGroup[originSource][idx].is_active

      if (!dataGroup[originSource][idx].is_active) {
        dataGroup[originSource][0].is_active = dataGroup[originSource][idx].is_active
      }

      const idxForAllGroupsFalse = dataGroup[originSource].findIndex((it, i) => i !== 0 && it.is_active === false )

      if (idxForAllGroupsFalse === -1 ) dataGroup[originSource][0].is_active = true

      const idxForAllGroupsTrue = dataGroup[originSource].findIndex((it, i) => i !== 0 && it.is_active === true && originSource !== 'default')

      if (idxForAllGroupsTrue === -1 && originSource !== 'default') {
        dataGroup[originSource].forEach((itm, i) => {
          dataGroup[originSource][i].is_active = true
        })
      }

      dataOriginSource[0].is_active = checkAllParticipants(dataGroup)
      dataOriginSource[1].is_active = checkAllGroups(dataGroup)
    }
  }

  const checkAllParticipants = (dataGroup:  HashTableActiveGroup): boolean => {
    for (const key in dataGroup) {
      if (dataGroup.hasOwnProperty(key)) {
        const idx = dataGroup[key].findIndex((it) => it.is_active === true)
        if (idx >= 0)
          return !dataGroup[key][idx].is_active
      }
    }
    return true
  }

  const checkAllGroups = (dataGroup:  HashTableActiveGroup): boolean => {
    for (const key in dataGroup) {
      if (dataGroup.hasOwnProperty(key)) {
        if (!dataGroup[key][0].is_active)
          return dataGroup[key][0].is_active
      }
    }
    return true
  }

  const getGroupIds = (groups: HashTableActiveGroup, isAllParticipants: boolean): string[] => {
    let groupsIdArr: string[] = []
    if (isAllParticipants) {
      return groupsIdArr
    }
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        groups[key].forEach((itm) => {
          itm.id !== ALL_GROUPS.id && itm.is_active && groupsIdArr.push(itm.id)
        })
      }
    }
    return groupsIdArr
  }

  const originSourceSelectHandler = (data: SelectItemType) => {
    if (
        data.id === ALL_PARTICIPANTS.id &&
        activeOriginSource[0].is_active
    ) {
      return null;
    }
    if (
        data.id === ALL_GROUPS.id &&
        activeOriginSource[1].is_active
    ) {
      return null;
    }

      const newActiveOriginSource = deepClone(activeOriginSource);
      const newActiveGroups = deepClone(activeGroups);

      switch (data.id) {
        case ALL_PARTICIPANTS.id:
          setAllValueOriginSource(newActiveGroups, newActiveOriginSource, false)
            setActiveOriginSource(newActiveOriginSource)
            setActiveGroups(newActiveGroups)
          break
        case ALL_GROUPS.id:
          setAllValueOriginSource(newActiveGroups, newActiveOriginSource, true)
            setActiveOriginSource(newActiveOriginSource)
            setActiveGroups(newActiveGroups)
          break
        default:
          setOnceValueOriginSource(newActiveGroups, newActiveOriginSource, data.id as string)
          setActiveOriginSource(newActiveOriginSource)
          setActiveGroups(newActiveGroups)
      }
  }

  const groupSelectHandler = (data: SelectItemType, parentId: string) => {
    if (
        data.id === ALL_GROUPS.id &&
        activeGroups[parentId][0].is_active
    ) {
      return null;
    }

    const newActiveOriginSource = deepClone(activeOriginSource);
    const newActiveGroups = deepClone(activeGroups);

    switch (data.id) {
      case ALL_GROUPS.id:
        setAllValueGroup(newActiveGroups, newActiveOriginSource, parentId, true)
        setActiveOriginSource(newActiveOriginSource)
        setActiveGroups(newActiveGroups)
        break
      default:
        setOnceValueGroup(newActiveGroups, newActiveOriginSource, parentId, data.id as string)
        setActiveOriginSource(newActiveOriginSource)
        setActiveGroups(newActiveGroups)
    }
  }

  return {
    groupSelectHandler,
    activeGroups,
    activeOriginSource,
    selectDataOriginSource,
    activeGroupIds,
    originSourceSelectHandler,
  };
};
