import { Reducer } from 'redux';
import ActionTypes, { TAssessmentsState } from './types';

export const initialState: TAssessmentsState = {
  data: null,
  loading: false,
  errors: undefined,
  existingData: [],
  current: null,
  answered: null,
  archivedData: [],
};

const reducer: Reducer = (state = initialState, action): TAssessmentsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_R:
    case ActionTypes.GET_ARCHIVE_R:
    case ActionTypes.GET_ALL_BY_TEAM_R:
    case ActionTypes.GET_ALL_ANSWERED_R:
    case ActionTypes.REMOVE_FROM_PACKAGE_R:
    case ActionTypes.ADD_TO_PACKAGE_R:
    case ActionTypes.UNARCHIVE_R:
    case ActionTypes.DELETE_ARCHIVED_R:
    case ActionTypes.GET_ONE_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ActionTypes.GET_ARCHIVE_S:
      return {
        ...state,
        loading: false,
        archivedData: action.payload,
      };
    case ActionTypes.REMOVE_FROM_PACKAGE_S:
    case ActionTypes.ADD_TO_PACKAGE_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
      };
    case ActionTypes.UNARCHIVE_S:
    case ActionTypes.DELETE_ARCHIVED_S:
      return {
        ...state,
        loading: false,
        archivedData: action.payload,
      };
    case ActionTypes.GET_ONE_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        current: action.payload.id ? action.payload : null,
      };
    case ActionTypes.GET_ALL_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: action.payload,
      };

    case ActionTypes.GET_ALL_ANSWERED_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        answered: action.payload,
      };
    case ActionTypes.GET_ALL_BY_TEAM_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        existingData: action.payload,
      };
    case ActionTypes.SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload,
      };
    case ActionTypes.GET_ALL_BY_TEAM_E:
    case ActionTypes.GET_ALL_E:
    case ActionTypes.DELETE_ARCHIVED_E:
    case ActionTypes.UNARCHIVE_E:
    case ActionTypes.GET_ALL_ANSWERED_E:
    case ActionTypes.REMOVE_FROM_PACKAGE_E:
    case ActionTypes.ADD_TO_PACKAGE_E:
    case ActionTypes.GET_ARCHIVE_E:
    case ActionTypes.GET_ONE_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as AssessmentsReducer };
