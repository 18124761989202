import React from 'react';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

interface Props {
  editorState: any;
  onChange: (e: any) => void;
}
export const HtmlEditor: React.FC<Props> = ({ editorState, onChange }) => {
  return (
    <Editor editorState={editorState} onEditorStateChange={onChange}
    toolbar={{
      options: ['inline', 'list', 'history'],
      inline: {
        options: ['bold', 'italic', 'underline']
      }
      // inline: {
      //   visible: true,
      //   bold: { visible: true },
      //   italic: { visible: true, },
      //   underline: { visible: false,  },
      //   strikeThrough: { visible: false,  },
      //   monospace: { visible: false,}
      // },

    }}>
      <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      />
    </Editor>
  );
};
