import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import shortid from 'shortid';
import styled from 'styled-components';
import {AppStore} from '../../store/applicationState';
import {CreateAssessment, GetOneAssessment, setAssEditMode,} from '../../store/assessments/actions';
import {EOrigin, EQuestionType, TAssessment, TCatAssessment, TQuestionType,} from '../../store/assessments/types';
import {TCat} from '../../store/categories/types';
import {AnimatedClasses} from '../../utils/animatedStyles';
import {COLORS} from '../../utils/colors';
import {SELECT_TYPES, SHORT_ID_CHARS} from '../../utils/consts';
import {deepClone} from '../../utils/deepClone';
import {CatList} from '../Assessments/CatList';
import {Loader} from '../ui';
import {ButtonBorderedS, ButtonDefS} from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import {closeModal} from './utils/closeModal';
import EnFlag from '../../images/eng.png';
import EsFlag from '../../images/es.svg';
import FrFlag from '../../images/fr.svg';
import IlFlag from '../../images/il.svg';
import {NewLinkedSurvey} from "./NewLinkedSurvey";

interface LinkedSurveyViewProps {
  closeHandler: () => void;
  ass: TAssessment;
  isArchived: boolean;
}
shortid.characters(SHORT_ID_CHARS);

export const LinkedSurveyView: React.FC<LinkedSurveyViewProps> = ({
  closeHandler,
  ass,
  isArchived,
}) => {
  const { User, Cats, Assessments } = useSelector((store: AppStore) => store);
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = React.useState(false);
  const [showCats, setShowCats] = React.useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };
  const getQuestionAnnotation = (type: TQuestionType) => {
    switch (type) {
      case EQuestionType.ten: {
        return 'Rate from 1 to 10';
      }
      case EQuestionType.five_reverse: {
        return 'Reverse Scoring';
      }
      case EQuestionType.text: {
        return 'Open feedback';
      }
      case EQuestionType.text_anonymous: {
        return 'Open feedback (anonymous)';
      }
      case EQuestionType.select_collaborations:
      case EQuestionType.select_friendly_collaborations:
      case EQuestionType.select_manager_collaborations:
        return 'Select participants from the list';
    }
  };
  const cloneHandler = (catId?: string) => {
    if (!User.data) return null;
    if (ass.origin === EOrigin.default && !catId) return setShowCats(true);
    const PREF = ' (copy)';
    setLoading(true);

    const callBack = (success: boolean, data: TAssessment) => {
      if (!success) return null;
      if (!Cats.data) return null;
      if (!data || (data && !data.id) || (data && !data.category_id)) {
        console.log(success, 'success');
        console.error(data, 'data');
        return null;
      }

      if (!Cats.data || !Array.isArray(Cats.data)) {
        console.error(Cats.data, 'cats');
        return null;
      }

      const newCasts = deepClone(Cats.data) as TCat[];
      const cat = newCasts.find((c) => `${c.id}` === `${data.category_id}`);

      if (!cat) {
        console.warn('Could not find cat callBack submitHandler');
        return null;
      }

      const origin: TCatAssessment['origin'] = EOrigin.custom;
      const newAss = {
        id: data.id,
        number_of_questions: data.questions.length,
        name: data.name,
        category_id: data.category_id,
        version: data.version,
        answer_type: data.answer_type,
        origin,
        category_name: data.catName,
      };
      cat.assessments =
        cat.assessments && cat.assessments[0]
          ? [newAss, ...cat.assessments]
          : [newAss];

      dispatch(CreateAssessment.success(newCasts));

      if (Assessments.editMode) dispatch(setAssEditMode(false));

      return closeHandler();
    };

    const createObj = (cId: string) => ({
      name: {
        en_US: ass.name.en_US + PREF,
      },
      description: {
        en_US: ass.description.en_US,
      },
      type: ass.type,
      code: shortid.generate(),
      version: ass.version,
      team_id: User.data!.team_id,
      answer_type: ass.answer_type,
      category_id: cId,
      questions: ass.questions,
    });

    if (catId) {
      setShowCats(false);

      return dispatch(
        CreateAssessment.request({
          data: createObj(catId),
          callBack: (success) => {
            if (success) dispatch(CreateAssessment.success(Cats.data!));
            return closeHandler();
          },
        })
      );
    }

    return dispatch(
      CreateAssessment.request({
        data: createObj(ass.category_id),
        callBack,
      })
    );
  };

  const type = SELECT_TYPES.find((s) => s.value === ass.type);

  if (editMode || Assessments.editMode)
    return (
      <NewLinkedSurvey
        assessment={ass}
        closeHandler={() => {
          setEditMode(false);
          dispatch(setAssEditMode(false));
          dispatch(GetOneAssessment.success({}));
        }}
      />
    );

  const BtnText = ass.origin === EOrigin.default ? 'Clone' : 'Duplicate';
  const desc =
    ass.description && ass.description.en_US
      ? ass.description.en_US.split('\n')
      : [];
  const descJoin = desc.join('\n \n');
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '768px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS
          style={{
            background: '#f6f8f6',
            marginBottom: '20px',
            paddingBottom: '20px',
          }}
        >
          <Styles.ModalSubtitleTileS>
            {(!isArchived && ass.catName + ' | ') || ''}
            {type?.title || 'None'}
          </Styles.ModalSubtitleTileS>

          <Styles.WrapperLangTitleS>
            <Styles.ModalTileS>{ass.name.en_US}</Styles.ModalTileS>
            {ass.origin === EOrigin.default && (
              <Styles.LangAssWrapperS>
                <div>
                  <img src={EnFlag} alt="EnFlag" />
                  <span>En</span>
                </div>
                <div>
                  <img src={EsFlag} alt="EsFlag" />
                  <span>Es</span>
                </div>
                <div>
                  <img src={FrFlag} alt="EnFlag" />
                  <span>Fr</span>
                </div>
                <div>
                  <img src={IlFlag} alt="IlFlag" />
                  <span>He</span>
                </div>
              </Styles.LangAssWrapperS>
            )}
          </Styles.WrapperLangTitleS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <Styles.ModalBodyTextS style={{ whiteSpace: 'pre-line' }}>
            {descJoin}
          </Styles.ModalBodyTextS>

          <hr />

          <h3>Linked Survey Statements</h3>
          {ass.questions.length === 1 ? ass.questions.map((itm, i) => {
            return (
              <AssRowS key={itm.id}>
                <PositionS>Link: </PositionS>
                <div>
                  <p style={{ padding: 0, marginBottom: 0 }}>
                    {itm.body.en_US}
                  </p>
                  {(itm.answer_type === EQuestionType.linked_survey) && (
                    <p style={{ padding: 0, margin: 0, opacity: 0.6 }}>
                      {getQuestionAnnotation(itm.answer_type)}
                    </p>
                  )}
                </div>
              </AssRowS>
            );
          }): null}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <div style={{ flex: 1 }}>
              <span style={{ fontSize: '14px', opacity: '0.7' }}>
                {ass.version && `v${ass.version}`} Code: {ass.code}
              </span>
            </div>
            <ButtonBorderedS
              isCancel
              style={{ position: 'relative' }}
              onClick={loading ? () => null : closeModalHandler}
            >
              {loading ? <Loader size={0.5} isGreen /> : 'Close'}
            </ButtonBorderedS>
            {!isArchived && (
              <>
                {ass.is_cloneable && (
                  <ButtonDefS isSave onClick={() => cloneHandler()}>
                    {loading ? <Loader size={0.5} /> : BtnText}
                  </ButtonDefS>
                )}
                {(Assessments.current?.been_answered && Assessments.current?.answer_type !== EQuestionType.linked_survey) ||
                ass.origin === EOrigin.default ? null : (
                  <ButtonDefS
                    isSave
                    onClick={() => (loading ? null : setEditMode(true))}
                  >
                    {loading ? <Loader size={0.5} /> : 'Edit'}
                  </ButtonDefS>
                )}
              </>
            )}
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
        {User.data?.team_id && showCats ? (
          <CatList
            selectHandler={(catId) => cloneHandler(catId)}
            closeHandler={() => setShowCats(false)}
            teamId={User.data.team_id}
          />
        ) : null}
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};

const AssRowS = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
`;
const PositionS = styled.div`
  padding-left: 35px;
  padding-right: 35px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 10px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.accent};
  color: ${COLORS.accent};
  font-size: 1.6rem;
  margin: 6px 12px 0 0;
`;
