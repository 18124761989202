import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import {group_border_colors} from "../ParticipantRow";

export const WrapperS = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 23px 0;
`;
export const HeaderS = styled.header`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  .btn-arrow {
    margin-left: 12px;
  }
`;

export const TitleS = styled.h3`
  flex: 1;
  color: #3c3c3c;
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BodyS = styled.div``;

export const UserItemS = styled.div<{ colorB?: string }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  align-items: center;
  border-radius: 8px;
  border: solid 1px #4caf50;
  background-color: rgba(76, 175, 80, 0.06);
  padding: 9px 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 1.4rem;
  ${({ colorB }) => {
    if (colorB === group_border_colors.automatic) {
      return `
      border: solid 1px ${COLORS_RGBA.orange(1)};
      background-color: ${COLORS_RGBA.orange(0.06)};
      
      `;
    } else {
      return `
      border: solid 1px ${COLORS_RGBA.accent(1)};
      background-color: ${COLORS_RGBA.accent(0.06)};
        `;
    }
  }}

  .btn-close {
    svg {
      g {
        ${({ colorB }) => {
          if (colorB === group_border_colors.automatic) {
            return `
              fill: ${COLORS_RGBA.orange(0.8)};
            `;
          } else {
            return `
              fill: ${COLORS_RGBA.accent(0.8)};
            `;
          }
        }}
      }
    }

    &:hover {
      svg {
        g {
          ${({ colorB }) => {
            if (colorB === group_border_colors.automatic) {
              return `
              fill: ${COLORS.orangeHover};
            `;
            } else {
              return `
              fill: ${COLORS.accentHover};
            `;
            }
          }}
        }
      }
    }
    &:active {
      svg {
        g {
          ${({ colorB }) => {
            if (colorB === group_border_colors.automatic) {
              return `
              fill: ${COLORS.orangeActive};
            `;
            } else {
              return `
              fill: ${COLORS.accentActive};
            `;
            }
          }}
        }
      }
    }
  }
`;

export const WrapperItemDragS = styled.div<{ active?: boolean }>`
  width: 100%;
  margin: 8px 0 0;
  padding: 9px 16px;
  border-radius: 8px;
  border: dashed 1px ${COLORS_RGBA.default(0.2)};
  color: ${COLORS_RGBA.default(0.5)};
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  ${({ active }) =>
    active
      ? `
    border-color: ${COLORS.accent};
    color:${COLORS.accent};
  `
      : ''}
`;
