import React from 'react';
import styled from 'styled-components';
import { ButtonClose, Tabs } from '../ui';
import {
  Data,
  Groups,
  Info,
  Notes,
  OpenFeedBack,
  Relationships,
  Themes,
} from './collabEmpoleeOverlayItems';
import { useDispatch } from 'react-redux';
import { GetCollabParticipantInfo } from '../../store/collaborations/actions';

interface Props {
  editMode?: boolean;
  closeHandler: () => void;
  user: {
    first_name: string;
    last_name: string;
    id: string;
  };
  isFixed?: boolean;
}
export const CollabEmployeeOverlay: React.FC<Props> = ({
  closeHandler,
  user,
  isFixed,
}) => {
  const dispatch = useDispatch();
  const [tabs] = React.useState([
    {
      id: '1',
      title: 'Details',
    },
    {
      id: '2',
      title: 'Groups',
    },
    {
      id: '3',
      title: 'Relationships',
    },
    {
      id: '4',
      title: 'Data',
    },
    {
      id: '5',
      title: 'Notes',
    },
    // {
    //   id: '6',
    //   title: 'Themes',
    // },
    // {
    //   id: '7',
    //   title: 'Open Feedback',
    // },
  ]);
  const [activeTab, setActiveTab] = React.useState('1');

  React.useEffect(() => {
    (async () => {
      if (!user) return null;
      dispatch(
        GetCollabParticipantInfo.request({
          participantId: user.id,
          body: {
            is_all_assessments: true,
            is_all_participants: true,
            period_of_answer_date: {
              is_all_time: true,
              is_last_month: false,
              is_last_quarter: false,
              is_last_year: false,
              start_date: '',
              end_date: '',
            },
            assessments_ids: [],
            groups_ids: [],
          },
        })
      );
    })();
  }, [user, dispatch]);

  const renderBody = () => {
    switch (activeTab) {
      case '1':
        return <Info />;
      case '2':
        return <Groups />;
      case '3':
        return <Relationships />;
      case '4':
        return <Data />;
      case '5':
        return <Notes participantId={user.id} />;
      case '6':
        return <Themes />;
      case '7':
        return <OpenFeedBack />;
      default:
        return <Info />;
    }
  };

  return (
    <WrapperCurrentS isFixed={isFixed}>
      <InnerWrapperS>
        <WrapperHeaderS>
          <h2>
            {user.first_name} {user.last_name}
          </h2>
          <ButtonClose onClick={closeHandler} />
        </WrapperHeaderS>
        <WrapperBodyS>
          <Tabs
            isUnderline
            data={tabs}
            onClick={setActiveTab}
            activeTab={activeTab}
          />
          {renderBody()}
        </WrapperBodyS>
      </InnerWrapperS>
    </WrapperCurrentS>
  );
};

const WrapperCurrentS = styled.div<{ isFixed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  background: ${({ isFixed }) =>
    isFixed ? 'rgba(0, 0, 0, 0.6);' : 'rgba(255, 255, 255, 0.5)'};
  z-index: 21474836481;
`;

const InnerWrapperS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 664px;
  padding: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  h2 {
    margin: 0;
  }
`;

const WrapperHeaderS = styled.div`
  display: flex;
  width: 100%;
  & > *:first-child {
    flex: 1;
    padding-right: 10px;
  }
`;

const WrapperBodyS = styled.div`
  padding-top: 10px;
`;
