export const mainClass = 'animate__animated animate__faster';
export const ANIM_CL = {
  zoomIn: 'animate__zoomIn',
  zoomOut: 'animate__zoomOut',
  fadeIn: 'animate__fadeIn',
  fadeOut: 'animate__fadeIn',
};

export const AnimatedClasses = {
  fadeIn: mainClass + ' ' + ANIM_CL.fadeIn,
  fadeOut: mainClass + ' ' + ANIM_CL.fadeOut,
  fadeFromBottom: mainClass + ' animate__fadeInUp',
  fadeFromTop: mainClass + ' animate__fadeInDown',
  fadeFromLeft: mainClass + ' animate__fadeInLeft',
  fadeOutLeft: mainClass + ' animate__fadeOutLeft',
  fadeOutRight: mainClass + ' animate__fadeOutRight',
  bounceIn: mainClass + ' animate__bounceIn',
  zoomIn: mainClass + ' ' + ANIM_CL.zoomIn,
  zoomOut: mainClass + ' ' + ANIM_CL.zoomOut,
  slideFromLeft: mainClass + ' animate__slideInLeft',
  slideOutLeft: mainClass + ' animate__slideOutLeft',
};

export const SelectClasses = {
  in: 'animate__zoomIn',
  out: 'animate__zoomOut',
};
