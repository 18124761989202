import React from 'react';
import { getGroupsR } from '../store/groups/actions';
import { GetAllAnsweredAssessments } from '../store/assessments/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../store/applicationState';

export const useGroups = () => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Workspaces.current && Workspaces.current.id) {
      dispatch(getGroupsR(Workspaces.current.id));
      dispatch(
        GetAllAnsweredAssessments.request({
          workspaceId: Workspaces.current.id,
        })
      );
    }
  }, [Workspaces, dispatch]);
};
