import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

export const WrapperRowS = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -20px -12px 0;

  & > div {
    margin: 12px;
    width: 230px;
    cursor: pointer;

    &:hover {
      border: solid 1px ${COLORS.accentHover};
    }
    &:active {
      border: solid 1px ${COLORS.accentActive};
    }
  }
`;
