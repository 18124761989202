import styled from 'styled-components';
import { HEADER_HEIGHT, SIDE_BAR_WIDTH } from '../../utils/config';

export const PageTitleWrapperS = styled.div`
  display: flex;
  align-items: center;
  min-height: 42px;
  margin-bottom: 24px;
  h1 {
    margin: 0;
  }
`;

export const RightSideBarTitleS = styled.h4`
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  margin: 0;
`;

export const RightSideBarBodyS = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isEmpty }) =>
    isEmpty
      ? `
  height: 100%;
  min-height: 300px;
  justify-content: center;
  text-align: center;
  align-items: center;
`
      : ''}
`;

export const RightSideBarHeaderS = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;

  button {
    max-width: 138px;
    min-height: 36px;
  }
`;
export const RightSideBarWrapperS = styled.div`
  position: fixed;
  right: 0;
  width: ${SIDE_BAR_WIDTH};
  bottom: 0;
  height: calc(100vh - ${HEADER_HEIGHT});
  max-height: calc(100vh - ${HEADER_HEIGHT});
  overflow: auto;
  background-color: #fdfdfd;
  padding: 24px 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
`;
export const RightSideBarEmptyTextS = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  padding: 0 30px;
  margin-top: -83px;
`;
