import React from 'react';
import { API_ROUTE_PATH } from '../../../utils/api_routes';
import { callApi } from '../../../utils/callApi';
import { IConsultants } from '../ModalConsultants';
export const useModalConsultants = () => {
  const [isChangePass, setIsChangePass] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isDone, setIsDone] = React.useState(false);
  const changePassHandler = async (
    consultant: IConsultants,
    newPass: string,
    newPassRepeat: string
  ) => {
    setLoading(true);
    if (newPass.length < 5 && newPass) {
      setLoading(false);
      setErrorText('Member password may contain at least 5 charecters');
    } else if (newPass !== newPassRepeat) {
      setLoading(false);
      setErrorText('Passwords are not equal');
    } else
      try {
        await callApi({
          method: 'post',
          // subPath: '/api/v1/auth',
          path: API_ROUTE_PATH.admins.resetConsultantPass,
          data: {
            id: consultant.id,
            new_password: newPass,
          },
        });
      } catch (e) {
        setErrorText(`${e}`);
      } finally {
        if (!errorText) {
          setIsDone(true);
        }
        setLoading(false);
      }
  };
  const closeHandler = () => {};
  return {
    isChangePass,
    changePassHandler,
    setIsChangePass,
    closeHandler,
    isLoading,
    setErrorText,
    errorText,
    isDone,
  };
};
