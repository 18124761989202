import React from 'react';
import { EColFilterStatus } from '../../../store/collaborations/types';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';

export const useRangeDom = (range: number[]) => {
  const { Collaborations } = useSelector((store: AppStore) => store);

  React.useEffect(() => {
    setTimeout(() => {
      const circles = document.querySelectorAll('.userCircle');

      if (!circles || !circles[0]) return null;

      const changeColorCircle = (circle: HTMLElement) => {
        if (!Collaborations.data) return null;
        if (!circle.classList.contains('captured')) return null;
        const { percentile } = circle.dataset;
        const { matched } = EColFilterStatus;
        if (range[0] <= +percentile && range[1] >= +percentile) {
          circle.classList.contains(matched) &&
            circle.classList.remove(matched);
        } else {
          circle.classList.add(matched);
        }
      };

      circles.forEach(changeColorCircle);
    }, 100);
  }, [range, Collaborations.data]);
};
