import { Reducer } from 'redux';
import ActionTypesUser from '../user/types';
import ActionTypes, { TGroupState } from './types';

export const initialState: TGroupState = {
  data: null,
  loading: true,
  errors: undefined,
};

const reducer: Reducer<TGroupState> = (
  state = initialState,
  action
): TGroupState => {
  switch (action.type) {
    case ActionTypes.DELETE_R:
    case ActionTypes.CREATE_R:
    case ActionTypes.GET_ALL_R:
    case ActionTypes.GET_ALL_BY_ORIGIN_SOURCE_R:
    case ActionTypes.SET_LOADING:
    case ActionTypes.EDIT_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
        data: state.data ? [...state.data] : [],
      };

    case ActionTypes.DELETE_S:
    case ActionTypes.GET_ALL_S:
    case ActionTypes.GET_ALL_BY_ORIGIN_SOURCE_S:
    case ActionTypes.EDIT_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        data: state.data ? [action.payload, ...state.data] : [action.payload],
      };
    case ActionTypesUser.LOG_OUT_S:
      return {
        data: null,
        loading: true,
        errors: undefined,
      };

    case ActionTypes.CREATE_E:
    case ActionTypes.DELETE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.GET_ALL_E:
    case ActionTypes.GET_ALL_BY_ORIGIN_SOURCE_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { reducer as GroupsReducer };
