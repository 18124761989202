import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { ArrowRightIcon } from '../svgs';
import { DefaultSelect, Input } from '../ui';
import { useGroupSelect } from './hooks/useGroupSelect';
import { SelectColumnWrapperS } from './styles';
import {
    GroupSelectProps,
    selectSingleOrMultiply,
    ESingleOrMultiply, IMatchingTemplateGroupData, EExistOrDoesntExistOrMissing,
} from './types';

export const GroupSelect: React.FC<GroupSelectProps> = React.memo(
  ({
    groups,
    columns,
    label,
    name,
    csvData,
    selectHandler,
    isFirstRowHeader,
    isUseMatchingTemplate,
    selectedItems,
    isExistFields,
    selectExistFieldHandler,
    setIsExistMatchingFieldsHandler,
    openSelectHandler,
    groupsValue,
    setGroupsValue,
  }) => {
    const dataHook = useGroupSelect({
      columns,
      csvData,
      selectHandler,
      isFirstRowHeader,
      groupsValue,
      setGroupsValue,
    });

    const groupsValues =
      groups && groups.map((g) => ({ title: g.name, value: g.id, id: g.id }));
      React.useEffect(() => {
          if (isUseMatchingTemplate && selectedItems[name]) {
              let matchingGroup = selectedItems[name] as IMatchingTemplateGroupData
              //eslint-disable-next-line
              if (matchingGroup && groupsValues) {
                  let groupValue = groupsValues.find(g =>  g.id === matchingGroup.group_id)

                  if (!groupValue) {
                      setIsExistMatchingFieldsHandler(name+'_group_name',  EExistOrDoesntExistOrMissing.doesntExist)
                  } else {
                      dataHook.setSelectedGroup(groupValue)
                  }

              }
          } else {
              dataHook.setSelectedGroup(null)
          }
          //eslint-disable-next-line
      }, [columns, selectedItems]);

      React.useEffect(() => {
          if (isUseMatchingTemplate && selectedItems[name]) {
              let matchingGroup = selectedItems[name] as IMatchingTemplateGroupData
              dataHook.setSelectedSvgColumn(selectedItems[matchingGroup.column.toLowerCase()  + '_title'])
          } else {
              dataHook.setSelectedSvgColumn(null);
          }
          //eslint-disable-next-line
      }, [dataHook.selectedGroup]);

      React.useEffect(() => {
          if (isUseMatchingTemplate && selectedItems[name] ) {
              dataHook.setSelectedColumnGroup(null)
              let matchingGroup = selectedItems[name] as IMatchingTemplateGroupData
              if (matchingGroup.single) {
                  dataHook.setSelectedSingleOrMultiply(selectSingleOrMultiply.find((item) => item.id === 1))
              } else if (matchingGroup.multiple) {
                  dataHook.setSelectedSingleOrMultiply(selectSingleOrMultiply.find((item) => item.id === 2))
                  if (dataHook.selectedSvgColumn) dataHook.setDelimiter(matchingGroup.input_value_delimiter_char)
              } else {
                  dataHook.setSelectedSingleOrMultiply(null)
              }
          } else {
              dataHook.setSelectedSingleOrMultiply(null)
          }
          //eslint-disable-next-line
      }, [dataHook.selectedSvgColumn]);

      React.useEffect(() => {
          if (isUseMatchingTemplate && selectedItems[name]) {
              let matchingGroup = selectedItems[name] as IMatchingTemplateGroupData
              let idSelectedSingleOrMultiply: number = matchingGroup.single ? 1 : 2;

              if (dataHook.selectedSingleOrMultiply && idSelectedSingleOrMultiply !== dataHook.selectedSingleOrMultiply?.id) {
                      dataHook.setSelectedColumnGroup(null)
                      return
              }


              if (dataHook.groupsSelect) {
                  const value = dataHook.groupsSelect.find((itm) => itm.title.toLowerCase() === matchingGroup.input_value.toLowerCase()
                  )
                  if (value) {
                      dataHook.setSelectedColumnGroup(value)
                      selectExistFieldHandler(name+'_value');
                  } else {
                      dataHook.setSelectedColumnGroup(null)
                      setIsExistMatchingFieldsHandler(name+'_value',  EExistOrDoesntExistOrMissing.doesntExist)
                  }

              } else {
                  dataHook.setSelectedColumnGroup(null)
              }

          } else {
              dataHook.setSelectedColumnGroup(null)
          }
          //eslint-disable-next-line
      }, [dataHook.selectedSingleOrMultiply, dataHook.groupsSelect]);

    const renderAfterFieldInputOrValue = () => {
      if (!dataHook.selectedGroup) return null;
      if (!dataHook.selectedSvgColumn) return null;
      if (!dataHook.selectedSingleOrMultiply) return null;
      if (dataHook.selectedSingleOrMultiply.id === ESingleOrMultiply.single) {
        return (
          <>
            <ArrowRightIcon />
            <DefaultSelect
              placeHolder="Select value"
              isOpenHandler={openSelectHandler}
              data={dataHook.groupsSelect || []}
              isExistField={isExistFields[name+'_value']}
              selected={dataHook.selectedColumnGroup}
              onChange={(e) => {
                  selectExistFieldHandler(name+'_value');
                  dataHook.setSelectedColumnGroup(e);

              }}
            />
          </>
        );
      }
      if (dataHook.selectedSingleOrMultiply.id === ESingleOrMultiply.multiply) {
        return (
          <>
            <ArrowRightIcon />
            <WrapperSeparatorLabelS>
              <SeparatorLabelS>
                Punctuation separator examples are ;-:,
              </SeparatorLabelS>
              <Input
                value={dataHook.delimiter}
                onChange={(e) => dataHook.setDelimiter(e.currentTarget.value)}
                name="delimiter"
                placeholder="Value Separator"
              />
            </WrapperSeparatorLabelS>
          </>
        );
      }
      return null;
    };

    return (
      <WrapperS>
        <DefaultSelect
          data={groupsValues || []}
          placeHolder="Select group"
          isOpenHandler={openSelectHandler}
          isExistField={isExistFields[name+'_group_name']}
          selected={dataHook.selectedGroup}
          onChange={(e) => {
              dataHook.setSelectedGroup(e);
              selectExistFieldHandler(name+'_group_name');
          }}
        />
        <ArrowRightIcon />
        <DefaultSelect
          placeHolder="Column name"
          isOpenHandler={openSelectHandler}
          data={dataHook.svgColumns}
          selected={dataHook.selectedSvgColumn}
          isExistField={isExistFields[name]}
          onChange={(e) => {
            dataHook.setSelectedSvgColumn(e);
              selectExistFieldHandler(name);
            // selectHandler(e);
          }}
        />
        {dataHook.selectedSvgColumn && dataHook.selectedGroup && (
          <>
            <ArrowRightIcon />
            <DefaultSelect
              placeHolder="Values Per Cell"
              isOpenHandler={openSelectHandler}
              data={selectSingleOrMultiply}
              selected={dataHook.selectedSingleOrMultiply}
              onChange={dataHook.setSelectedSingleOrMultiply}
            />
          </>
        )}

        {renderAfterFieldInputOrValue()}

        {dataHook.delimiter && (
          <>
            <ArrowRightIcon />
            <DefaultSelect
              placeHolder="Select value"
              isOpenHandler={openSelectHandler}
              data={dataHook.groupsSelect || []}
              selected={dataHook.selectedColumnGroup}
              isExistField={isExistFields[name+'_value']}
              onChange={(e) => {
                  selectExistFieldHandler(name+'_value');
                  dataHook.setSelectedColumnGroup(e);

              }}
            />
          </>
        )}
      </WrapperS>
    );
  }
);

const WrapperS = styled(SelectColumnWrapperS)`
  margin: 0;
`;
const WrapperSeparatorLabelS = styled.div`
  position: relative;
`;
const SeparatorLabelS = styled.span`
  position: absolute;
  font-size: 11px;
  left: 0;
  bottom: 100%;
  white-space: nowrap;
  color: ${COLORS.accent};
`;
