import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import * as Actions from './actions';
import ActionTypes, { TDataStory, TDataStoryLink, TSnapshot } from './types';

function* getStories(
  action: ReturnType<typeof Actions.GetStories.request>
): Generator {
  const { workspaceId } = action.payload as Actions.TypeGetStoriesR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.story.gByWorkspaceid + '/' + workspaceId,
    })) as TDataStory;
    yield put(Actions.GetStories.success(resp));
  } catch (e) {
    yield put(Actions.GetStories.error(e));
  } finally {
  }
}
function* getStoriesByLink(
  action: ReturnType<typeof Actions.GetStoriesBylink.request>
): Generator {
  const { link } = action.payload as Actions.TypeGetStoriesByLinkR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.story.gByLink + '/' + link,
    })) as TDataStory;
    yield put(Actions.GetStoriesBylink.success(resp));
  } catch (e) {
    yield put(Actions.GetStoriesBylink.error(e));
  } finally {
  }
}
function* editStories(
  action: ReturnType<typeof Actions.EditStories.request>
): Generator {
  let success = true;
  const { data, callBack, id } = action.payload as Actions.TypeEditSrotiesR;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.story.path + '/' + id,
      data,
    })) as TDataStory;
    yield put(Actions.EditStories.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.EditStories.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createLink(
  action: ReturnType<typeof Actions.CreateLink.request>
): Generator {
  let success = true;
  const { workspaceId, link_expiration, callBack } =
    action.payload as Actions.TypeCreateLinkR;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.story.linkByID(workspaceId),
      data: {
        link_expiration,
      },
    })) as TDataStoryLink;
    yield put(Actions.CreateLink.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateLink.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getLink(
  action: ReturnType<typeof Actions.GetLink.request>
): Generator {
  let success = true;
  const { workspaceId, callBack } = action.payload as Actions.TypeGetLinkR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.story.linkByID(workspaceId),
    })) as TDataStoryLink;
    yield put(Actions.GetLink.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.GetLink.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deactivateLink(
  action: ReturnType<typeof Actions.DeactivateLink.request>
): Generator {
  const { workspaceId } = action.payload as Actions.TypeDeactivateLinkR;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.story.linkByID(workspaceId),
    })) as TDataStoryLink;
    yield put(Actions.DeactivateLink.success(resp));
  } catch (e) {
    yield put(Actions.DeactivateLink.error(e));
  } finally {
  }
}

function* createSnapshot(
  action: ReturnType<typeof Actions.CreateSnapshot.request>
): Generator {
  let success = true;
  const { snapshot, callBack, id } =
    action.payload as Actions.TypeCreateSnapshotR;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.snapshot.byWorkspace + '/' + id,
      data: {
        snapshot,
      },
    })) as TDataStory;
    yield put(Actions.CreateSnapshot.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateSnapshot.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createFileSnapshot(
  action: ReturnType<typeof Actions.CreateFileSnapshot.request>
): Generator {
  let success = true;
  const { callBack, workspaceId, data } =
    action.payload as Actions.TypeCreateFileSnapshotR;
  const sendData: {
    name: string;
    data: {};
  } = {
    name: data.name,
    data: data.data ? data.data : null,
  };
  console.log(data.file);
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file);
  bodyFormData.append('fields', JSON.stringify(sendData));
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.snapshot.cWithFile(workspaceId),
      data: bodyFormData,
    })) as TSnapshot;
    yield put(Actions.CreateFileSnapshot.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateFileSnapshot.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* editSnapshot(
  action: ReturnType<typeof Actions.EditSnapshot.request>
): Generator {
  let success = true;
  const { comment, callBack, snapshotId } =
    action.payload as Actions.TypeEditSnapshotR;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.snapshot.path + '/' + snapshotId,
      data: {
        comment,
      },
    });
  } catch (e) {
    success = false;
    yield put(Actions.EditSnapshot.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSnapshot(
  action: ReturnType<typeof Actions.DeleteSnapshot.request>
): Generator {
  let success = true;
  const { snapshotId, callBack } =
    action.payload as Actions.TypeDeleteSnapshotR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.snapshot.path + '/' + snapshotId,
    });
  } catch (e) {
    success = false;
    yield put(Actions.DeleteSnapshot.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_STORIES_R, getStories);
  yield takeEvery(ActionTypes.GET_STORIES_BY_LINK_R, getStoriesByLink);
  yield takeEvery(ActionTypes.CREATE_LINK_R, createLink);
  yield takeEvery(ActionTypes.GET_LINK_R, getLink);
  yield takeEvery(ActionTypes.DEACTIVATE_LINK_R, deactivateLink);
  yield takeEvery(ActionTypes.EDIT_STORIES_R, editStories);
  yield takeEvery(ActionTypes.CREATE_SNAPSHOT_R, createSnapshot);
  yield takeEvery(ActionTypes.CREATE_FILE_SNAPSHOT_R, createFileSnapshot);
  yield takeEvery(ActionTypes.EDIT_SNAPSHOT_R, editSnapshot);
  yield takeEvery(ActionTypes.DELETE_SNAPSHOT_R, deleteSnapshot);
}

export default function* dataStorySaga() {
  yield all([fork(watchFetchRequest)]);
}
