import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../utils/colors';

export const ArrowIconS = styled.div``;

export const WrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  &:hover {
    ${ArrowIconS} {
      svg {
        g {
          opacity: 1;
        }

        path {
          fill: ${COLORS.accentHover};
        }
      }
    }
  }
`;

export const UserNameImageWrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  span {
    font-size: 1.6rem;
    white-space: nowrap;
  }

  &:active {
    color: ${COLORS.accentActive};

    ${ArrowIconS} {
      svg {
        g {
          opacity: 1;
        }
        path {
          fill: ${COLORS.accentActive};
        }
      }
    }
  }
`;
export const WrapperItemsS = styled.div`
  position: absolute;
  margin-top: 10px;
  width: 100%;
  right: 0;
  min-width: 284px;
  background: white;
  top: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.16);
  background-color: #ffffff;
  overflow: hidden;

  .avatarImage {
    min-width: 40px;
    height: 40px;
  }
  hr {
    opacity: 0.4;
  }

  a {
    cursor: pointer;
    background: white;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    &.active {
      background: white;
      cursor: pointer;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    &:hover {
      background: ${COLORS_RGBA.accent(0.05)};
      color: #3c3c3c;
    }

    &:active {
      background: ${COLORS_RGBA.accent(0.15)};
      color: #3c3c3c;
    }

    span {
      margin-left: 0;
    }
    &.teamLink {
      &.active {
        svg g {
          fill: #000;
        }
      }
      svg {
        width: 26px;
        height: 18px;
        margin-right: 7px;
        opacity: 0.3;
        g {
          fill: #000;
        }
      }
    }

    &.logOutLink {
      color: ${COLORS.danger};

      &:hover {
        background: ${COLORS_RGBA.danger(0.1)};
      }

      &:active {
        background: ${COLORS_RGBA.danger(0.2)};
      }
    }
  }
`;
