import React from 'react';
import styled from 'styled-components';
import {COLORS} from '../../utils/colors';
import {InfoIcon, QuestionIcon} from '../svgs';

import {useOutsideClick} from "../../hooks/useOutsideClick";

interface ParticipationProps {
    notified: number;
    surveyed: number;
}

export const Participation: React.FC<ParticipationProps> = React.memo(
  ({ notified, surveyed }) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const { ref } = useOutsideClick(() => setIsOpen(false));
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const renderBody = () => {
      return (
        <>
          <ListS>
              <li><span style={{color: COLORS.accent}}>Notified: {notified}; Surveyed: {surveyed}</span></li>
              <li><span style={{whiteSpace:'inherit', color: '#2a2f2a', fontSize:'12px'}}>
                  <p><InfoIcon /><strong>Notified:</strong> number of participants who received email notification in current cycle.
                  <br/>
                  </p>
                  <p><InfoIcon /><strong>Surveyed:</strong> number of participants, who responded to at least one assessment in the package in the current cycle.</p>
                </span></li>
          </ListS>

        </>
      );
    };

    return (
      <WrapperS ref={ref}>
        <LinkS
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          isBlack={false}
        >
          {' '}
          {<QuestionIcon />}
        </LinkS>
        {isOpen && <WrapperModS>{renderBody()}</WrapperModS>}
        {isTooltipOpen && (
          <TooltipWrapperS>
            <span style={{color: 'inherit', fontSize: 'inherit', fontWeight: 'normal', textDecoration: 'none', border: 'none',padding: '0', margin: '0', display: 'inline'}}>Sending Statistic</span>
          </TooltipWrapperS>
        )}
      </WrapperS>
    );
  }
);

const WrapperS = styled.div`
  position: relative;
  margin-left: 15px;
`;

export const TooltipWrapperS = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  min-width: 125px;
  padding: 5px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  z-index: 5;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 6px;
  span {
    margin: 0;
    padding: 0;
  }
`;
const WrapperModS = styled.div`
  margin-top: 10px;
  position: absolute;
  min-width: 350px;
  min-height: 100px;
  left: -25px;
  top: 100%;
  transform: translateX(15px);
  background: white;
  padding: 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  /* max-width: 334px; */
  z-index: 5;
  border-radius: 5px;
  /* min-width: 334px; */

`;

export const LinkS = styled.button<{ isBlack?: boolean }>`
  display: inline-flex;
  background: none;
  border: none;
  opacity: 0.5;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: ${({ isBlack }) => (!isBlack ? `${COLORS.default}` : 'black')};
  &:hover {
    color: ${COLORS.accent};
    opacity: 1;
  }
  &:active {
    color: ${COLORS.accentActive};
    opacity: 1;
  }
`;

const ListS = styled.ul`
  list-style: none;
  outline: 0;
  padding: 0;
  margin: 10px 0 0;
  li {
    display: flex;
    align-items: left;
    margin: 0;
    cursor: pointer;
    span {
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
    }
  }
`;
// const PackScheduleS = styled.a`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   font-size: 16px;
//   background: transparent;
//   color: #2a2f2a;
//   border: solid 1px #d8d8d8;
//   border-radius: 8px;
//   padding: 0 10px;
//   transition: 0.2s;
//   min-height: 36px;

//   &:hover {
//     border: solid 1px ${COLORS.accent};
//   }

//   &:active {
//     border: solid 1px ${COLORS.accentActive};
//     background: ${COLORS.accentActive};
//     color: white;

//     svg {
//       opacity: 0.7;
//       g {
//         fill: #fff;
//       }
//     }
//   }

//   &:disabled {
//     border: solid 1px #d8d8d8 !important;
//     cursor: default;
//     opacity: 0.2;
//   }
// `;
