import styled from 'styled-components';
import { ButtonDefS } from '../../components/ui/buttons/styles';
import { COLORS, COLORS_RGBA } from '../../utils/colors';

export const HeaderS = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background: white;
  border-bottom: 1px solid ${COLORS_RGBA.secondary(0.5)};
  z-index: 2;
`;

export const LogoWrapperS = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const MainS = styled.main``;

export const FormWrapperS = styled.section`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 100px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormS = styled.form`
  width: 100%;
  max-width: 416px;
`;

export const LoginBtnS = styled(ButtonDefS)`
  margin-top: 26px;
  position: relative;
`;

export const WrapperSignUpS = styled.div`
  margin-top: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-size: 1.6rem;
    opacity: 0.6;
  }
  a {
    font-weight: 400;
    font-size: 1.6rem;
    margin-left: 5px;
    text-decoration: none;
  }
`;

export const ForgotPassS = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 28px;

  a {
    font-weight: 400;
    font-size: 1.6rem;
  }
`;

export const WrapperLinksS = styled.div`
  display: flex;
  & > * {
    margin: 20px 10px 0;
  }

  a {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: ${COLORS.accent};
    }
  }
`;
