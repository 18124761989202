interface ILang {
  collaborators: {
    pleaseChoose: string;
    collaborators: string;
    search: string;
    addToCollab: string;
    collab: string;
    continue: string;
    managerSelect: string;
    collabThanks: string;
  };
  friendCollaborators: {
    pleaseName: string;
    theseCan: string;
    addToFriendCollab: string;
    addedFriend: string;
  };
}
export const LangCollaborators: {
  en: ILang;
  es: ILang;
  fr: ILang;
  he: ILang;
} = {
  en: {
    collaborators: {
      pleaseChoose: ', please choose your collaborators.',
      collaborators:
        'Collaborators are people you collaborate most with. You can choose 1 - 10 collaborators from the list.',
      search: 'Search',
      addToCollab: 'Add to my collaborators',
      collab: 'Collaborator',
      continue: 'Continue',
      managerSelect:
        ', are any of these people a manager, boss or direct report?',
      collabThanks: 'Thank you! The list of your collaborators was saved.',
    },
    friendCollaborators: {
      pleaseName:
        ', please name 3 - 10 people who are the best collaborators and the people you desire to work with the most. We’ll assume these are nice people who are good listeners, know a lot about the work, and help get things done. ',
      theseCan:
        'These can be different people than you listed previously as people you work the most with.',
      addToFriendCollab: 'Add to best collaborators',
      addedFriend: 'Best collaborator',
    },
  },
  es: {
    collaborators: {
      pleaseChoose:
        ', elige a las personas con las que más trabajas, por favor.',
      collaborators:
        'Estas son las personas con las que más colaboras. Puede elegir de 1 a 10 personas de la lista.',
      search: 'Buscar',
      addToCollab: 'Añadir a mis colaboradores',
      collab: 'Colaborador',
      continue: 'Continuar',
      managerSelect:
        ', ¿alguna de estas personas es gerente, jefe o subordinado directo?',
      collabThanks: '¡Gracias! La lista de sus colaboradores se guardó.',
    },
    friendCollaborators: {
      pleaseName:
        ', nombra 3 - 10 personas que son los mejores colaboradores y las personas con las que más deseas trabajar. Asumiremos que estas son personas agradables que son buenos oyentes, saben mucho sobre el trabajo y ayudaremos a hacer las cosas.',
      theseCan:
        'Estas pueden ser personas diferentes a las que mencionaste anteriormente como personas con las que trabajas más.',
      addToFriendCollab: 'Agregar a los mejores colaboradores',
      addedFriend: 'Mejor colaborador',
    },
  },
  fr: {
    collaborators: {
      pleaseChoose: ', veuillez choisir vos collaborateurs.',
      collaborators:
        'Les collaborateurs sont des personnes que vous collaborez le plus avec. Vous pouvez choisir 1 - 10 collaborateurs de la liste.',
      search: 'Chercher',
      addToCollab: 'Ajouter à mes collaborateurs',
      collab: 'Collaborateur',
      continue: 'Continuez',
      managerSelect:
        ', est-ce une de ces personnes un gestionnaire, un patron ou un rapport direct?',
      collabThanks: 'Merci! La liste de vos collaborateurs a été enregistrée.',
    },
    friendCollaborators: {
      pleaseName:
        ', veuillez nommer 3 à 10 personnes qui sont les meilleurs collaborateurs et les personnes avec lesquelles vous désirez travailler le plus. Nous supposerons que ce sont des gens sympas qui sont de bons auditeurs, en savent beaucoup sur le travail et aident à faire avancer les choses.',
      theseCan:
        'Celles-ci peuvent être des personnes différentes de celles que vous avez énumérées auparavant en tant que personnes avec lesquelles vous travaillez le plus.',
      addToFriendCollab: 'Ajouter aux meilleurs collaborateurs',
      addedFriend: 'Meilleur collaborateur',
    },
  },
  he: {
    collaborators: {
      pleaseChoose: ', אנא בחר את משתפי הפעולה שלך.',
      collaborators:
        'בחר/י את האנשים שאיתם את/ה עובד/ת בשיתוף פעולה במידה הרבה ביותר. בחר/י 1-10 אנשים מהרשימה.',
      search: 'לחפש',
      addToCollab: 'הוסף למשתפי הפעולה שלי',
      collab: 'מְשַׁתֵף פְּעוּלָה',
      continue: 'המשך/י',
      managerSelect: ',האם מישהו מהם המנהל שלך?',
      collabThanks: 'תודה! רשימת משתפי הפעולה שלך נשמרה.',
    },
    friendCollaborators: {
      pleaseName:
        ', אנא שם 3 - 10 אנשים שהם משתפי הפעולה הטובים ביותר והאנשים שאתה רוצה לעבוד איתם הכי הרבה. נניח שאלו אנשים נחמדים שהם מאזינים טובים, יודעים הרבה על העבודה ועוזרים לעשות דברים.',
      theseCan:
        'אלה יכולים להיות אנשים שונים ממה שרשמת בעבר כאנשים שאתה הכי עובד איתם.',
      addToFriendCollab: 'הוסף למשתפי הפעולה הטובים ביותר',
      addedFriend: 'משתף הפעולה הטוב ביותר',
    },
  },
};
