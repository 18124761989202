import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TMessageTemplate } from './types';

const GetAllMessageTemplatesActions = new Actions(
  'GET_ALL_TEMPLATES',
  ActionTypes
);

export interface TypeGetAllMessageTemplatesR extends TDefRequest {
  workspace_name: string;
}

export const GetAllMessageTemplates = {
  request: (payload: TypeGetAllMessageTemplatesR) =>
    GetAllMessageTemplatesActions.request(payload),
  success: (payload: TMessageTemplate[]) =>
    GetAllMessageTemplatesActions.success(payload),
  error: (message: string) => GetAllMessageTemplatesActions.error(message),
};

const DeleteMessageTemplateActions = new Actions(
  'DELETE_TEMPLATE',
  ActionTypes
);

export interface TypeDeleteMessageTemplateR extends TDefRequest {
  messageTemplateId: string;
}

export const DeleteMessageTemplate = {
  request: (payload: TypeDeleteMessageTemplateR) =>
    DeleteMessageTemplateActions.request(payload),
  success: (payload: TMessageTemplate[]) =>
    DeleteMessageTemplateActions.success(payload),
  error: (message: string) => DeleteMessageTemplateActions.error(message),
};

const CreateMessageTemplateActions = new Actions(
  'CREATE_TEMPLATE',
  ActionTypes
);

export interface TypeCreateMessageTemplateR extends TDefRequest {
  data: {
    subject: string;
    html_content: string;
  };
}

export const CreateMessageTemplate = {
  request: (payload: TypeCreateMessageTemplateR) =>
    CreateMessageTemplateActions.request(payload),
  success: (payload: TMessageTemplate) =>
    CreateMessageTemplateActions.success(payload),
  error: (message: string) => CreateMessageTemplateActions.error(message),
};

export const setTemplate = (payload: TMessageTemplate | null) =>
  action(ActionTypes.SET_TEMPLATE, payload || null);
