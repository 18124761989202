import React from 'react';

interface ArchiveIconProps {}

export const ArchiveIcon: React.FC<ArchiveIconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.667 0a2 2 0 0 1 1.846 1.23l1.412 3.385c.06.146.085.297.076.445L16 13a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5c0-.16.038-.312.105-.446l1.383-3.322A2 2 0 0 1 3.334 0h9.333zm0 2H3.334l-.832 2H4a1 1 0 0 1 0 2H2v7a1 1 0 0 0 .883.993L3 14h10a1 1 0 0 0 .993-.883L14 13V6h-2l-.117-.007A1 1 0 0 1 12 4h1.502l-.835-2zM7.98 3.989a1 1 0 0 1 1 1v2.999h1.5a.5.5 0 0 1 .385.82L8.381 11.8a.5.5 0 0 1-.77 0L5.127 8.808a.5.5 0 0 1 .384-.82h1.47v-3a1 1 0 0 1 1-1z"
        fill="#2A2F2A"
        fillRule="evenodd"
        opacity=".3"
      />
    </svg>
  );
};
