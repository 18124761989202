import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { Loader } from '../../ui';
import { BlockS, BlockTitleS, BlockTextS, BlocksWrapperS } from './styles';
import { WrapperS } from './styles';

export const Relationships: React.FC = () => {
  const { Collaborations } = useSelector((store: AppStore) => store);

  if (Collaborations.participantLoading)
    return (
      <WrapperS style={{ position: 'relative' }}>
        <Loader isGreen />
      </WrapperS>
    );
  const { info } = Collaborations.participantInfo;
  const renderManagers = () => {
    if (!info.managers || !info.managers[0]) {
      return <BlockTextS>No managers yet</BlockTextS>;
    }

    return info.managers.map((m) => (
      <BlockTextS key={m.id}>
        {m.first_name} {m.last_name}
      </BlockTextS>
    ));
  };
  return (
    <WrapperS>
      <BlocksWrapperS>
        <BlockS style={{ width: '66%' }}>
          <BlockTitleS>Connections:</BlockTitleS>
          <BlockTextS>
            <b>People This Person Listed:</b>{' '}
            {info.number_of_selected_collaborators || '0'}
          </BlockTextS>
          <BlockTextS>
            <b>Times Listed By Other People:</b>{' '}
            {info.number_of_collaborators_selected_participant || '0'}
          </BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>Manager or Direct Report: </BlockTitleS>
          {renderManagers()}
        </BlockS>
        <BlockS style={{ width: '66%' }}>
          <BlockTitleS>Times Listed as Top Collaborator:</BlockTitleS>
          <BlockTextS>
            {info.number_of_collaborators_selected_friendly || '0'}
          </BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>Connectedness Rate:</BlockTitleS>
          <BlockTextS>{info.connectedness_score || '0'}</BlockTextS>
        </BlockS>
      </BlocksWrapperS>
    </WrapperS>
  );
};
