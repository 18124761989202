import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { ArrowRightIcon } from '../svgs';
import { Tabs } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import { IRates } from './charts/hooks/useParticipantsRate';

interface PackagePassInfoProps {
  packageInfo: IRates;
  returnHandler: () => void;
}

export const PackagePassInfo: React.FC<PackagePassInfoProps> = ({
  packageInfo,
  returnHandler,
}) => {
  const [activeTab, setActiveTab] = React.useState('Completed');
  const [tabs, setTabs] = React.useState<{ title: string; id: string }[]>([
    { title: 'Completed', id: 'Completed' },
    { title: 'Not Completed', id: 'Not_Completed' },
  ]);

  React.useEffect(() => {
    if (
      packageInfo.number_of_unfinished &&
      packageInfo.number_of_unfinished !== 0
    ) {
      setTabs([
        {
          title: `Completed (${
            packageInfo.number_of_finished
              ? packageInfo.number_of_finished
              : '0'
          })`,
          id: 'Completed',
        },
        {
          title: `Not Completed (${packageInfo.number_of_unfinished})`,
          id: 'Not_Completed',
        },
      ]);
    } else {
      setTabs([
        {
          title: `Completed (${
            packageInfo.number_of_finished
              ? packageInfo.number_of_finished
              : '0'
          })`,
          id: 'Completed',
        },
      ]);
    }
  }, [packageInfo]);
  const renderBody = () => {
    if (!packageInfo) return null;
    switch (activeTab) {
      case 'Completed': {
        if (packageInfo.names_of_finished[0])
          return packageInfo.names_of_finished.map((itm, i) => {
            return (
              <li key={itm + i}>
                <span onClick={() => null}>{itm}</span>
              </li>
            );
          });
        return (
          <li>
            <span>No сompleted yet</span>
          </li>
        );
      }

      case 'Not_Completed': {
        if (packageInfo.names_of_unfinished[0]) {
          return packageInfo.names_of_unfinished.map((itm, i) => {
            return (
              <li key={itm + i}>
                <span>{itm}</span>
              </li>
            );
          });
        }
        return (
          <li>
            <span>No participants yet</span>
          </li>
        );
      }

      default: {
        return (
          <li>
            <span>No data</span>
          </li>
        );
      }
    }
  };
  return (
    <>
      <TitleS>{packageInfo.package_name}</TitleS>
      <Tabs data={tabs} activeTab={activeTab} onClick={setActiveTab} />
      <WrapperS>{renderBody()}</WrapperS>
      <ReturnButtonS onClick={returnHandler}>
        <ArrowRightIcon />
      </ReturnButtonS>
    </>
  );
};
const WrapperS = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 260px;
  overflow: auto;
  font-size: 16px;
  li {
    padding-top: 12px;
  }
`;
const TitleS = styled.h2`
  font-size: 18px;
  padding: 0 30px;
  text-align: center;
`;
const ReturnButtonS = styled(ButtonBorderedS)`
  position: absolute;
  top: 23px;
  background: transparent;
  height: 30px;
  max-height: 30px;
  min-height: 30px;
  width: 30px;
  padding: 0;
  svg {
    transform: rotate(180deg);
    opacity: 0.3;
    g {
      fill: ${COLORS.default};
    }
  }
`;
