import React from 'react';
import { TParticipant } from '../../store/groups/types';
import { COLORS } from '../../utils/colors';
import { ButtonClose, Loader } from '../ui';
import { useGroupParticipant } from './hooks/useGroupParticipant';
import * as Styles from './styles/GroupSectionStyles';

interface GroupParticipantProps {
  participant: TParticipant;
  groupId: string;
  groupPosition: number;
  position: number;
  groupIsLoading?: boolean;
}

export const GroupParticipant: React.FC<GroupParticipantProps> = ({
  participant,
  groupId,
  groupPosition,
  position,
  groupIsLoading,
}) => {
  const { participantRef, isLoading, deleteItem } = useGroupParticipant({
    groupId,
    groupPosition,
  });
  return (
    <Styles.UserItemS ref={participantRef} key={participant.id} colorB={participant.add_source}>
      {isLoading ? <Loader color={COLORS.accent} size={0.3} /> : null}
      <span style={isLoading ? { opacity: 0.2 } : {}}>
        {participant.first_name} {participant.last_name}
      </span>
      <ButtonClose
        colorB={participant.add_source}
        onClick={() => {
          if (groupIsLoading) return null;
          deleteItem(participant.id, position);
        }}
      />
    </Styles.UserItemS>
  );
};
