// import userExampleSaga from './UsersExample/sagas';
import { fork } from 'redux-saga/effects';
import analyticsSaga from './analytics/sagas';
import assSaga from './assessments/sagas';
import catsSaga from './categories/sagas';
import consultantsSaga from './consultants/sagas';
import groupsSaga from './groups/sagas';
import packagesSaga from './packages/sagas';
import participantsSaga from './participants/sagas';
import teamSaga from './team/sagas';
import userSaga from './user/sagas';
import workspacesSaga from './workspaces/sagas';
import collaborationsSaga from './collaborations/sagas';
import dataStorySaga from './dataStory/sagas';
import attachmentsSaga from './attachments/saga';
import assessmentCardsSaga from './assessmentCards/saga';
import finchIntegrationsSaga from './finchIntegrations/sagas';
import messageTemplatesSaga from './messageTemplates/saga';
import matchingTemplatesSaga from "./matchingTemplates/sagas";

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(groupsSaga);
  yield fork(teamSaga);
  yield fork(participantsSaga);
  yield fork(workspacesSaga);
  yield fork(consultantsSaga);
  yield fork(catsSaga);
  yield fork(assSaga);
  yield fork(packagesSaga);
  yield fork(analyticsSaga);
  yield fork(collaborationsSaga);
  yield fork(dataStorySaga);
  yield fork(attachmentsSaga);
  yield fork(assessmentCardsSaga);
  yield fork(finchIntegrationsSaga);
  yield fork(messageTemplatesSaga);
  yield fork(matchingTemplatesSaga);
}
