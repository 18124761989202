import React from 'react';
import { ArchiveIcon } from '../../svgs';

import { WrapperItemSelectS } from './styles';

interface ArchiveSelectItemProps {
  title?: string;
}

export const ArchiveSelectItem: React.FC<ArchiveSelectItemProps> = ({
  title = 'Archive',
}) => {
  return (
    <WrapperItemSelectS>
      <ArchiveIcon />
      {title}
    </WrapperItemSelectS>
  );
};
