import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';

interface MessageModalProps {
  title: string;
  closeHandler: () => void;
}

export const MessageModal: React.FC<MessageModalProps> = ({
  title,
  closeHandler,
}) => {
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ minWidth: `${200}px` }}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalBodyS
          style={{
            minHeight: `${125}px`,
          }}
        >
          <TextS>{title}</TextS>
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeHandler}>
              Ok
            </ButtonBorderedS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const TextS = styled.p`
  font-size: 16px;
  margin: 12px 0 0 0;
`;
