import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';

import ActionTypes, {
  TCollab,
  TConnectednessScore,
  TParticipantInfo,
} from './types';
import {
  AddCollabParticipantNote,
  DeleteCollabParticipantNote,
  GetCollaborations,
  GetConnectednessScore,
  GetCollabParticipantInfo,
  TAdd_ParticipantNote_R,
  TDelete_ParticipantNote_R,
  TGet_COLLAB_R,
  TGet_GetCollabParticipantInfo_R,
  TUpdate_ParticipantNote_R,
  UpdateCollabParticipantNote,
  TGet_SCORE_R,
} from './actions';

function* getCollaborations(
  action: ReturnType<typeof GetCollaborations.request>
): Generator {
  const { data, callBack } = action.payload as TGet_COLLAB_R;
  const { workspace_id } = data;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.collaborations.getByWorkspace(workspace_id),
      data,
    }))as TCollab[];

    yield put(GetCollaborations.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: `${e}` }));
    yield put(GetCollaborations.error(e));
  } finally {
    if (callBack) callBack(success);
  }
}

function* getConnectednessScore(
  action: ReturnType<typeof GetConnectednessScore.request>
): Generator {
  const { data, callBack } = action.payload as TGet_SCORE_R;
  const { workspace_id } = data;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.collaborations.getConnectednessScore(workspace_id),
      data,
    })) as TConnectednessScore;

    yield put(GetConnectednessScore.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: `${e}` }));
    yield put(GetConnectednessScore.error(e));
  } finally {
    if (callBack) callBack(success);
  }
}

function* getCollaborationParticipantInfo(
  action: ReturnType<typeof GetCollaborations.request>
): Generator {
  const {
    body: data,
    participantId,
    callBack,
  } = action.payload as TGet_GetCollabParticipantInfo_R;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.participants.getParticipantDetails(participantId),
      data,
    })) as TParticipantInfo;

    yield put(GetCollabParticipantInfo.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: `${e}` }));
    yield put(GetCollabParticipantInfo.error(e));
  } finally {
    if (callBack) callBack(success);
  }
}

function* addCollaborationParticipantNote(
  action: ReturnType<typeof AddCollabParticipantNote.request>
): Generator {
  const { body, participantId, callBack } =
    action.payload as TAdd_ParticipantNote_R;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.participant.addNote(participantId),
      data: {
        body,
      },
    })) as TParticipantInfo['notes'][0];

    yield put(AddCollabParticipantNote.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: `${e}` }));
    yield put(AddCollabParticipantNote.error(e));
  } finally {
    if (callBack) callBack(success);
  }
}

function* deleteCollaborationParticipantNote(
  action: ReturnType<typeof AddCollabParticipantNote.request>
): Generator {
  const { noteId, callBack, newNotes } =
    action.payload as TDelete_ParticipantNote_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.participant.deleteNote(noteId),
    });

    yield put(DeleteCollabParticipantNote.success(newNotes));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: `${e}` }));
    yield put(DeleteCollabParticipantNote.error(e));
  } finally {
    if (callBack) callBack(success);
  }
}

function* updateCollaborationParticipantNote(
  action: ReturnType<typeof AddCollabParticipantNote.request>
): Generator {
  const { noteId, callBack, body, newNotes } =
    action.payload as TUpdate_ParticipantNote_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.participant.updateNote(noteId),
      data: {
        body,
      },
    });
    yield put(UpdateCollabParticipantNote.success(newNotes));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: `${e}` }));
    yield put(UpdateCollabParticipantNote.error(e));
  } finally {
    if (callBack) callBack(success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_COLLAB_R, getCollaborations);
  yield takeEvery(ActionTypes.GET_SCORE_R, getConnectednessScore);
  yield takeEvery(
    ActionTypes.ADD_COLLAB_PARTICIPANT_NOTE_R,
    addCollaborationParticipantNote
  );
  yield takeEvery(
    ActionTypes.GET_COLLAB_PARTICIPANT_INFO_R,
    getCollaborationParticipantInfo
  );
  yield takeEvery(
    ActionTypes.UPDATE_COLLAB_PARTICIPANT_NOTE_R,
    updateCollaborationParticipantNote
  );
  yield takeEvery(
    ActionTypes.DELETE_COLLAB_PARTICIPANT_NOTE_R,
    deleteCollaborationParticipantNote
  );
}

export default function* collaborationsSaga() {
  yield all([fork(watchFetchRequest)]);
}
