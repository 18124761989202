import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { Loader, Tabs } from '../ui';

interface ColaboratorsPassInfoProps {}

export const ColaboratorsPassInfo: React.FC<ColaboratorsPassInfoProps> = () => {
  const [activeTab, setActiveTab] = React.useState('Completed');
  const [tabs, setTabs] = React.useState<{ title: string; id: string }[]>([
    { title: 'Completed', id: 'Completed' },
    { title: 'Not Completed', id: 'Not_Completed' },
  ]);
  const [answeredParticipants, setAnsweredParticipants] =
    React.useState<{ first_name: string; last_name: string; id: string }[]>();
  const [participants, setParticipants] =
    React.useState<{ first_name: string; last_name: string; id: string }[]>();
  const [completedIds, setCompletedIds] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { Workspaces, Collaborations } = useSelector(
    (store: AppStore) => store
  );
  React.useEffect(() => {
    (async () => {
      // if (!isOpen) return null;
      if (!Workspaces.current) return null;
      // if (!Collaborations.data[0]) return null;
      setLoading(true);
      try {
        const resp = await callApi({
          method: 'get',
          path:
            API_ROUTE_PATH.collaborations.gWithoutCollab +
            '/' +
            Workspaces.current.id,
        });
        setParticipants(resp);
      } catch (e) {
        // setErrors(`${e}`);
      } finally {
        setLoading(false);
      }
    })();

    const sourceIds: string[] = [];
    const answeredList = [];
    Collaborations.data.forEach((itm) => {
      if (!sourceIds.includes(itm.source)) {
        sourceIds.push(itm.source);
        answeredList.push({
          first_name: itm.source_first_name,
          last_name: itm.source_last_name,
          id: itm.source,
        });
      }
      if (itm.is_mutual && !sourceIds.includes(itm.target)) {
        sourceIds.push(itm.target);
        answeredList.push({
          first_name: itm.target_first_name,
          last_name: itm.target_last_name,
          id: itm.target,
        });
      }
    });
    setCompletedIds(sourceIds);
    setAnsweredParticipants(answeredList);
  }, [Workspaces, Collaborations.data]);
  React.useEffect(() => {
    setTabs([
      {
        title: `Completed (${completedIds ? completedIds.length : ''})`,
        id: 'Completed',
      },
      {
        title: `Not Completed (${participants ? participants.length : '0'})`,
        id: 'Not_Completed',
      },
    ]);
  }, [participants, completedIds]);
  const renderBody = () => {
    if (!participants || !answeredParticipants) return null;
    if (loading)
      return (
        <div style={{ margin: '20px' }}>
          <Loader isGreen />
        </div>
      );
    switch (activeTab) {
      case 'Completed': {
        if (answeredParticipants[0])
          return answeredParticipants.map((itm) => {
            return (
              <li key={itm.id}>
                <span onClick={() => null}>
                  {itm.first_name} {itm.last_name}
                </span>
              </li>
            );
          });
        return (
          <li>
            <span onClick={() => null}>No сompleted yet</span>
          </li>
        );
      }

      case 'Not_Completed': {
        if (participants[0]) {
          return participants.map((itm) => {
            return (
              <li key={itm.id}>
                <span onClick={() => null}>
                  {itm.first_name} {itm.last_name}
                </span>
              </li>
            );
          });
        }
        return (
          <li>
            <span onClick={() => null}>No participants yet</span>
          </li>
        );
      }

      default: {
        return <span>No data</span>;
      }
    }
  };
  return (
    <>
      <Tabs data={tabs} activeTab={activeTab} onClick={setActiveTab} />
      <WrapperS>{renderBody()}</WrapperS>
    </>
  );
};
const WrapperS = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  overflow: auto;
  font-size: 16px;
  li {
    padding-top: 12px;
  }
`;
