import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useUpdateAssInPackage} from '../../hooks/useUpdateAssInPackage';
import {AppStore} from '../../store/applicationState';
import {
  AddAssToPack,
  ArchiveAssessment,
  DeleteArchived,
  DeleteAssessment,
  GetOneAssessment,
  setAssEditMode,
} from '../../store/assessments/actions';
import {EOrigin, EQuestionType, TCatAssessment} from '../../store/assessments/types';
import {TypeGetCatR} from '../../store/categories/actions';
import {TCat} from '../../store/categories/types';
import {TPackage} from '../../store/packages/types';
import {deepClone} from '../../utils/deepClone';
import {getCutText} from '../../utils/helpers';
import {getUniqueId} from '../../utils/uniqueId';
import {VALUES} from '../../utils/valueConst';
import {ButtonMore, DeleteSelectItem, EditSelectItem, Loader} from '../ui';
import {ISelectItemData} from '../ui/buttons/types';
import {ArchiveSelectItem} from '../ui/selects/ArchiveSelectItem';
import {useDnd} from './hooks/useDnd';
import * as BoxStyles from './styles/AssessmentBoxStyles';

interface AssessmentBoxProps {
  title: string;
  id: number | string;
  catId: string;
  catName: string;
  version: string;
  answer_type?: EQuestionType;
  questionsCount: number;
  actionClick: () => void;
  disableDrag?: boolean;
  isArchived?: boolean;
  unarchiveHandler?: () => void;
  packId?: string;
  selectData?: ISelectItemData[];
  type: TypeGetCatR['type'];
}

const uniqId = getUniqueId();
export const AssessmentBox: React.FC<AssessmentBoxProps> = ({
  title,
  questionsCount,
  answer_type,
  id,
  selectData,
  disableDrag,
  catId,
  catName,
  version,
  packId,
  type,
  isArchived,
  unarchiveHandler,
}) => {
  const { Cats, Assessments, Packages } = useSelector(
    (store: AppStore) => store
  );
  const { updateAssInPackage } = useUpdateAssInPackage();
  const [deleted, setDeleted] = React.useState(false);
  const cutTitle = getCutText(title, disableDrag ? 30 : 50);

  const { drag, opacity } = useDnd({
    ass: {
      name: {
        en_US: title,
      },
      category_id: catId,
      category_name: catName,
      origin: EOrigin.custom,
      number_of_questions: questionsCount,
      answer_type: answer_type,
      id: `${id}`,
      version: version,
    },
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (Assessments.current) {
      setLoading(false);
    }
  }, [Assessments]);

  const deleteFormPack = () => {
    const callBack = (success: boolean, data: TCatAssessment | null) => {
      setLoading(false);
      if (!success || !data) return null;

      if (!Packages.data || !Packages.data[0]) return null;
      const newPackages = deepClone(Packages.data) as TPackage[];
      const pack = newPackages.find((packItem) => packItem.id === packId);
      if (!pack) {
        return console.error('Package does not exist');
      }
      pack.assessments = pack.assessments.filter((ass) => ass.id !== id);

      updateAssInPackage(null, newPackages);
      setDeleted(true);
    };

    dispatch(
      AddAssToPack.request({
        data: {
          relation: false,
          assessment_id: `${id}`,
          package_id: packId!,
        },
        callBack,
      })
    );
  };
  const deleteArchived = () => {
    const callBack = (success: boolean) => {
      setLoading(false);
      if (!success) return null;
      const newArchived = Assessments.archivedData.filter(
        (itm) => itm.id !== id
      );
      dispatch(DeleteArchived.success(newArchived));
    };

    dispatch(
      DeleteArchived.request({
        id: `${id}`,
        callBack,
      })
    );
  };
  const deleteHandler = () => {
    if (packId) {
      return deleteFormPack();
    }
    if (isArchived) {
      return deleteArchived();
    }
    const callBack = (success: boolean) => {
      setLoading(false);
      if (!success) return null;
      if (!Cats.data) {
        console.warn('Cats.data does not exist');
        return null;
      }
      const newCats = deepClone(Cats.data) as TCat[];
      const cat = newCats.find((c) => c.id === catId);
      if (!cat) return console.warn('Cat does not exist');
      cat.assessments = cat.assessments.filter((a) => a.id !== id);
      dispatch(DeleteAssessment.success(newCats));
    };

    dispatch(
      DeleteAssessment.request({
        id: `${id}`,
        callBack,
      })
    );
  };

  const editHandler = () => {
    dispatch(setAssEditMode(true));
    dispatch(
      GetOneAssessment.request({
        id: `${id}`,
        catName,
      })
    );
  };

  const archiveHandler = () => {
    const callBack = (success: boolean) => {
      setLoading(false);
      if (!success) return null;
      const newCats = deepClone(Cats.data) as TCat[];
      const cat = newCats.find((c) => c.id === catId);
      if (!cat) return console.warn('Cat does not exist');
      cat.assessments = cat.assessments.filter((a) => a.id !== id);
      dispatch(ArchiveAssessment.success(newCats));
    };
    dispatch(ArchiveAssessment.request({ id: `${id}`, callBack }));
  };
  // const unarchiveHandler = () => {
  //   console.log('unarchive');
  //   const callBack = (success: boolean) => {
  //     setLoading(false);
  //     if (!success) return null;
  //   };
  // };
  if (deleted) return null;
  return (
    <BoxStyles.WrapperS
      ref={disableDrag ? null : drag}
      isDragging={!opacity}
      style={{ position: 'relative' }}
    >
      <BoxStyles.TitleWrapperS
        onClick={() =>
          dispatch(GetOneAssessment.request({ id: `${id}`, catName }))
        }
      >
        <div>
          <h5>{cutTitle}</h5>
          <BoxStyles.QuestionsCountS>
            {answer_type === EQuestionType.linked_survey ?
                      `Linked Survey`
                      :  `${questionsCount} question${questionsCount > 1 ? 's' : ''}`
            }
          </BoxStyles.QuestionsCountS>
        </div>
      </BoxStyles.TitleWrapperS>

      {type === 'default' ? null : (
        <ButtonMore
          onClick={(e) => {
            setLoading(true);
            if (e === VALUES.delete) {
              return deleteHandler();
            }
            if (e === VALUES.edit) {
              return editHandler();
            }
            if (e === VALUES.archive) {
              return archiveHandler();
            }
            if (e === VALUES.unarchive) {
              setLoading(false);
              return isArchived && unarchiveHandler
                ? unarchiveHandler()
                : () => null;
            }
          }}
          data={
            selectData
              ? selectData
              : [
                  {
                    title: <EditSelectItem />,
                    id: uniqId.next().value as number,
                    value: VALUES.edit,
                  },
                  {
                    title: <ArchiveSelectItem />,
                    id: uniqId.next().value as number,
                    value: VALUES.archive,
                  },
                  // {
                  //   title: <MoveSelectIcon />,
                  //   id: uniqId.next().value as number,
                  //   value: VALUES.move,
                  //   subItems: [
                  //     {
                  //       title: <span>Package 1</span>,
                  //       id: uniqId.next().value as number,
                  //       value: 'Package 1',
                  //     },
                  //     {
                  //       title: <span>Package 2</span>,
                  //       id: uniqId.next().value as number,
                  //       value: 'Package 2',
                  //     },
                  //     {
                  //       title: <span>Package 3</span>,
                  //       id: uniqId.next().value as number,
                  //       value: 'Package 3',
                  //     },
                  //   ],
                  // },
                  {
                    title: <DeleteSelectItem />,
                    id: uniqId.next().value as number,
                    value: VALUES.delete,
                  },
                ]
          }
        />
      )}
      {version && <BoxStyles.VersionS>v{version}</BoxStyles.VersionS>}
      {loading ? <Loader isGreen size={0.5} /> : null}
    </BoxStyles.WrapperS>
  );
};
