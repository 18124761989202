import React from 'react';
import styled from 'styled-components';
import {COLORS} from "../../../utils/colors";

interface LoaderProps {
    color?: string;
    size?: number;
    isGreen?: boolean;
    isFixed?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ color = '#fff', size = 1, isGreen, isFixed }) => {
    if (isGreen) color = COLORS.accent;
    const renderDivs = React.useMemo(() => {
        const divs = [];
        const divCount = 4;
        for (let i = 0; i < divCount; i++) {
            divs.push(<div key={i} style={{ background: color }} />);
        }

        return divs;
    }, [color]);
    return (
        <WrapperS
            className="orgvitals-loader"
            style={{
                transform: `scale(${size})`,
                position: isFixed ? 'fixed' : 'absolute',
                zIndex: 999,
            }}
        >
            <LoaderS>{renderDivs}</LoaderS>
        </WrapperS>
    );
};

const WrapperS = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
const LoaderS = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: auto;
  padding-top: 14px;


  div {
    position: relative;
    top: 50%;
    margin-top: -13px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
