import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersRow, Row } from '../../components/Analytics';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { Alert, Loader } from '../../components/ui';
import { MainWrapperS } from '../../globalStyle';
import { GetCharts } from '../../store/analytics/actions';
import { AppStore } from '../../store/applicationState';
import { setGroupsLoading } from '../../store/groups/actions';

interface CultureProps {}

export const Culture: React.FC<CultureProps> = () => {
  const { Analytics, Groups, Assessments } = useSelector(
    (store: AppStore) => store
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(GetCharts.success([]));
      dispatch(setGroupsLoading());
    };
  }, [dispatch]);

  const rows = React.useMemo(() => {
    if (Analytics.errors)
      return (
        <div style={{ marginTop: '40px' }}>
          <Alert text={Analytics.errors} />
        </div>
      );
    if (
      (!Analytics.data && Analytics.loading) ||
      Groups.loading ||
      Assessments.loading
    )
      return <Loader isGreen />;
    if (
      (!Analytics.data && !Analytics.loading) ||
      (!Analytics.data![0] && !Analytics.loading)
    )
      return (
        <h2 style={{ marginTop: '40px' }}>
          Culture does not have any data yet
        </h2>
      );

    return Analytics.data!.map((row, i) => (
      <Row key={row.id} rowIndex={i} row={row} />
    ));
  }, [
    Analytics.data,
    Analytics.errors,
    Analytics.loading,
    Groups.loading,
    Assessments.loading,
  ]);

  return (
    <MainWrapperS>
      <PageTitleWrapperS>
        <h1>Culture</h1>
      </PageTitleWrapperS>
      <FiltersRow />
      {rows}
      {Analytics.loading && <Loader isGreen isFixed />}
    </MainWrapperS>
  );
};
