import { Reducer } from 'redux';
import ActionTypes, { TUserState } from './types';

export const initialState: TUserState = {
  data: null,
  isLoggedIn: false,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TUserState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ME_R:
    case ActionTypes.LOG_OUT_R:
    case ActionTypes.FORGOT_PASS_R:
      return { ...state, loading: true, errors: undefined, isLoggedIn: false };
    case ActionTypes.FORGOT_PASS_S:
      return { ...state, loading: false };

    case ActionTypes.LOG_OUT_S:
      return {
        ...state,
        loading: false,
        data: null,
        errors: undefined,
        isLoggedIn: false,
      };

    case ActionTypes.GET_ME_S:
      return {
        ...state,
        loading: false,
        data: { ...action.payload },
        errors: undefined,
        isLoggedIn: true,
      };

    case ActionTypes.UPDATE_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: undefined,
        isLoggedIn: true,
      };
    case ActionTypes.SET_WORKSPACE:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          workspaces_ids:
            state.data && Array.isArray(state.data.workspaces_ids)
              ? [...state.data.workspaces_ids, action.payload]
              : [action.payload],
        },
        errors: undefined,
        isLoggedIn: true,
      };

    case ActionTypes.CLEAN_UP:
      return {
        ...state,
        data: null,
        isLoggedIn: false,
        loading: false,
        errors: undefined,
      };

    case ActionTypes.LOG_OUT_E:
    case ActionTypes.FORGOT_PASS_E:
    case ActionTypes.GET_ME_E:
      return {
        ...state,
        loading: false,
        data: null,
        errors: action.payload,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export { reducer as UserReducer };
