import React from 'react';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { callApi } from '../../../../utils/callApi';
import { API_ROUTE_PATH } from '../../../../utils/api_routes';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/applicationState';
import { ETypeGraph } from '../../../../store/analytics/types';

export interface IRates {
  package_id: string;
  package_name: string;
  cycle_date: string;
  link: string;
  names_of_finished: string[];
  names_of_unfinished: string[];
  number_of_finished: number;
  number_of_unfinished: number;
}

export const useParticipantsRate = (chartId: string) => {
  const { Workspaces, Analytics } = useSelector((store: AppStore) => store);
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentItm, setCurrentItm] = React.useState<string | null>(null);
  const { ref } = useOutsideClick(() => setIsOpen(false));
  const [rates, setRates] = React.useState<IRates[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<string | undefined>('');
  const [graphType] = React.useState<ETypeGraph>(ETypeGraph.type1);

  React.useEffect(() => {
    (async () => {
      if (!isOpen) return null;
      if (!chartId) return null;
      if (!Workspaces.current) return null;
      setLoading(true);
      try {
        const resp = await callApi({
          method: 'post',
          path: API_ROUTE_PATH.chart.getParticipationRate(
            Workspaces.current.id,
            chartId
          ),
          data: Analytics.filters,
        });

        return setRates(resp);
      } catch (e) {
        setErrors(`${e}`);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      setErrors('');
      setRates([]);
    };
    // eslint-disable-next-line
  }, [Workspaces.current, chartId, isOpen, Analytics.filters]);
  return {
    isOpen,
    setIsOpen,
    currentItm,
    setCurrentItm,
    ref,
    loading,
    rates,
    errors,
    graphType,
  };
};
