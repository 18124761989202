import { month_names_short } from '../components/participants/ParticipantRow';

export const convertEposToRenderDate = (utcSeconds: number) => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  return `${month_names_short[d.getMonth()]} ${d.getDate()}`;
};
export const convertEposToDate = (utcSeconds: number) => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  return d;
};