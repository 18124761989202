import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import {
  ArrowIcon,
  ArrowRightIcon,
  LinkIcon,
  WatermarkIcon,
} from '../components/svgs';
import { AppStore } from '../store/applicationState';
import {
  EditStories,
  GetStories,
  GetStoriesBylink,
} from '../store/dataStory/actions';
import { WorkspaceAvatarIcon } from '../components/svgs/WorkspaceAvatarIcon';
import { Snapshot } from '../components/DataStory/Snapshot';
import { Alert, ButtonSave, Loader } from '../components/ui';
import { SettingsIcon } from '../components/svgs/Settings';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { ShareLink } from '../components/modals/ShareLink';
import * as Styles from './styles/DataStoryViewStyles';
import { DragCard } from '../components/DataStory/DragCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { deepClone } from '../utils/deepClone';
import { TSnapshot } from '../store/dataStory/types';
interface DataStoryViewProps {}

export const DataStoryView: React.FC<DataStoryViewProps> = () => {
  const { User, DataStory } = useSelector((store: AppStore) => store);
  const { id } = useParams<{ id: string }>();
  const [isWorkspaceOn, setIsWorkspaceOn] = React.useState(false);
  const [isTeamOn, setIsTeamOn] = React.useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [frameColor, setFrameColor] = React.useState('fff');
  const [snapshots, setSnapshots] = React.useState<
    typeof DataStory.data.snapshots
  >([]);
  const [selected, setSelected] = React.useState<TSnapshot>(null);
  const [showNav, setShowNav] = React.useState(true);
  const { ref } = useOutsideClick(() => {
    if (isSettingsOpen) {
      setIsSettingsOpen(false);
    }
  });
  const refSlider = React.useRef<typeof Slider>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (User && User.isLoggedIn) {
      dispatch(GetStories.request({ workspaceId: id }));
    } else dispatch(GetStoriesBylink.request({ link: id }));
    // eslint-disable-next-line
  }, [dispatch, id]);
  React.useEffect(() => {
    if (DataStory.data) {
      setIsTeamOn(DataStory.data.logo_team);
      setIsWorkspaceOn(DataStory.data.logo_workspace);
      setSnapshots(DataStory.data.snapshots);
      setSelected(DataStory.data.snapshots[0]);
      DataStory.data.frame_color && setFrameColor(DataStory.data.frame_color);
    }
  }, [DataStory.data]);

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = snapshots[dragIndex];
      const newSnapshots = deepClone(snapshots);
      newSnapshots.splice(dragIndex, 1);
      newSnapshots.splice(hoverIndex, 0, dragCard);
      setSnapshots(newSnapshots);
    },
    [snapshots]
  );
  const settings = {
    arrows: true,
    infinite: false,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => setSelected(snapshots[next]),
  };
  const renderBody = () => {
    if (DataStory && DataStory.loading) {
      return <Loader isGreen />;
    }
    if (DataStory && DataStory.errors) {
      return <Alert text={DataStory.errors} />;
    }
    return (
      <Slider {...settings} className={'slides'} ref={refSlider}>
        {DataStory &&
          DataStory.data &&
          DataStory.data.snapshots.map((itm) => {
            return (
              <Styles.SnapshotWrapperS key={itm.id}>
                <Snapshot
                  name={itm.name}
                  chart={itm.data}
                  type={itm.type}
                  comment={itm.comment}
                  snapshotId={itm.id}
                  settings={itm.data && itm.data.settings}
                  preview={true}
                  link={itm.url}
                />
              </Styles.SnapshotWrapperS>
            );
          })}
      </Slider>
    );
  };
  const renderCard = (card: TSnapshot, index: number) => (
    <DragCard
      key={card.id}
      id={card.id}
      text={card.name}
      index={index}
      moveCard={moveCard}
      onClick={(snapshot: TSnapshot) => {
        setSelected(snapshot);
        refSlider.current.slickGoTo(index);
      }}
      type={card.data && card.data.type}
      snapshot={card}
      selectedId={selected.id}
      onDrop={() => {
        const snapIds: string[] = [];
        snapshots.forEach((itm) => snapIds.push(itm.id));
        dispatch(
          EditStories.request({
            id: DataStory.data.id,
            data: {
              logo_team: isTeamOn,
              logo_workspace: isWorkspaceOn,
              frame_color: frameColor,
              snapshots_ids_position: snapIds,
            },
          })
        );
      }}
    />
  );

  return (
    <Styles.PreviewPageS
      color={
        /^#([0-9A-F]{3}){1,2}$/i.test('#' + frameColor)
          ? '#' + frameColor
          : 'linear-gradient(to right, #4caf50, #fc0 100%)'
      }
    >
      <Styles.InnerWrapperS>
        <>
          {User.isLoggedIn && (
            <Styles.BackBtnHrefS href={`${window.location.origin}/data-story`}>
              <ArrowRightIcon /> Back
            </Styles.BackBtnHrefS>
          )}
          {DataStory && DataStory.data && (isTeamOn || isWorkspaceOn) && (
            <Styles.ViewHeaderS>
              {DataStory && DataStory.data && isWorkspaceOn && (
                <Styles.ImageNTextS>
                  <Styles.ImageWrapperS>
                    <WorkspaceAvatarIcon />
                    {DataStory.data.workspace &&
                      DataStory.data.workspace.logo_url && (
                        <Styles.ImageS
                          style={{
                            backgroundImage: `url("${DataStory.data.workspace.logo_url}")`,
                          }}
                        />
                      )}
                  </Styles.ImageWrapperS>
                  <h3>{DataStory.data.workspace.name}</h3>
                </Styles.ImageNTextS>
              )}
              {DataStory && DataStory.data && isTeamOn && isWorkspaceOn && (
                <Styles.DividerS />
              )}
              {DataStory && DataStory.data && isTeamOn && (
                <Styles.ImageNTextS>
                  <Styles.ImageWrapperS>
                    <WorkspaceAvatarIcon />
                    {DataStory.data.team.logo_url && (
                      <Styles.ImageS
                        style={{
                          backgroundImage: `url("${DataStory.data.team.logo_url}")`,
                        }}
                      />
                    )}
                  </Styles.ImageWrapperS>
                  <h3>{DataStory.data.team.name}</h3>
                </Styles.ImageNTextS>
              )}
            </Styles.ViewHeaderS>
          )}
          <Styles.ViewBodyS
            style={{
              borderRadius: `${
                DataStory && DataStory.data && (isTeamOn || isWorkspaceOn)
                  ? '0 0 8px 8px'
                  : '8px'
              }`,
              margin: `${
                DataStory && DataStory.data && (isTeamOn || isWorkspaceOn)
                  ? '0 12px 12px 12px'
                  : '12px'
              }`,
            }}
          >
            <div
              style={{
                width: '850px',
                padding: '0px 50px',
              }}
            >
              {renderBody()}
            </div>
            {User.isLoggedIn && <></>}
            {User.isLoggedIn && (
              <Styles.DragWrapperS>
                <DndProvider backend={HTML5Backend}>
                  <Styles.DragBlockS show={showNav}>
                    <Styles.DragAreaS>
                      {snapshots.map((itm, i) => renderCard(itm, i))}
                    </Styles.DragAreaS>
                  </Styles.DragBlockS>
                </DndProvider>
                <Styles.DragBlockButton
                  onClick={() => {
                    setShowNav(!showNav);
                  }}
                  show={showNav}
                >
                  <ArrowIcon />
                </Styles.DragBlockButton>
              </Styles.DragWrapperS>
            )}

            <Styles.WatermarkWrapperS>
              <WatermarkIcon />
            </Styles.WatermarkWrapperS>
            {User.isLoggedIn && (
              <>
                <Styles.BottomBlockWrapperS ref={ref}>
                  {isSettingsOpen && (
                    <Styles.SettingsWrapperS>
                      <Styles.SettingsLabelS>
                        Select logo{' '}
                      </Styles.SettingsLabelS>
                      <span>(optional)</span>
                      <div style={{ marginBottom: '20px' }}>
                        <Styles.ImageNTextSettingsS
                          onClick={() => setIsTeamOn(!isTeamOn)}
                          isActive={isTeamOn}
                        >
                          <Styles.ImageWrapperS>
                            <WorkspaceAvatarIcon />
                            {DataStory.data.team.logo_url && (
                              <Styles.ImageS
                                style={{
                                  backgroundImage: `url("${DataStory.data.team.logo_url}")`,
                                }}
                              />
                            )}
                          </Styles.ImageWrapperS>
                          <span>{DataStory.data.team.name}</span>
                        </Styles.ImageNTextSettingsS>
                        <Styles.ImageNTextSettingsS
                          onClick={() => setIsWorkspaceOn(!isWorkspaceOn)}
                          isActive={isWorkspaceOn}
                        >
                          <Styles.ImageWrapperS>
                            <WorkspaceAvatarIcon />
                            {DataStory.data.workspace.logo_url && (
                              <Styles.ImageS
                                style={{
                                  backgroundImage: `url("${DataStory.data.workspace.logo_url}")`,
                                }}
                              />
                            )}
                          </Styles.ImageWrapperS>
                          <span>{DataStory.data.workspace.name}</span>
                        </Styles.ImageNTextSettingsS>
                      </div>
                      <Styles.FrameSettingsWrapperS>
                        <Styles.SettingsLabelS>
                          Frame Colour
                        </Styles.SettingsLabelS>
                        <Styles.InputWrapperS>
                          <Styles.SignS>#</Styles.SignS>
                          <Styles.InputS
                            onChange={(e) => setFrameColor(e.target.value)}
                            value={frameColor}
                          />
                        </Styles.InputWrapperS>
                        <Styles.ColorButtonsWrapperS>
                          <Styles.ColorButtonS
                            color={
                              'linear-gradient(to right, #4caf50, #fc0 100%)'
                            }
                            onClick={() => setFrameColor('OrgVitals')}
                            isActive={frameColor === 'OrgVitals'}
                          />
                          <Styles.ColorButtonS
                            color={'#3b98ff'}
                            onClick={() => setFrameColor('3b98ff')}
                            isActive={frameColor === '3b98ff'}
                          />
                          <Styles.ColorButtonS
                            color={'#4533b3'}
                            onClick={() => setFrameColor('4533b3')}
                            isActive={frameColor === '4533b3'}
                          />
                          <Styles.ColorButtonS
                            color={'#9f36ff'}
                            onClick={() => setFrameColor('9f36ff')}
                            isActive={frameColor === '9f36ff'}
                          />
                          <Styles.ColorButtonS
                            color={'#1a916e'}
                            onClick={() => setFrameColor('1a916e')}
                            isActive={frameColor === '1a916e'}
                          />
                          <Styles.ColorButtonS
                            color={'#fcbf54'}
                            onClick={() => setFrameColor('fcbf54')}
                            isActive={frameColor === 'fcbf54'}
                          />
                        </Styles.ColorButtonsWrapperS>
                        <Styles.ButtonSaveSettings>
                          <ButtonSave
                            onClick={() => {
                              const snapIds: string[] = [];
                              snapshots.forEach((itm) => snapIds.push(itm.id));
                              dispatch(
                                EditStories.request({
                                  id: DataStory.data.id,
                                  data: {
                                    logo_team: isTeamOn,
                                    logo_workspace: isWorkspaceOn,
                                    frame_color: frameColor,
                                    snapshots_ids_position: snapIds,
                                  },
                                })
                              );
                            }}
                          />
                        </Styles.ButtonSaveSettings>
                      </Styles.FrameSettingsWrapperS>
                    </Styles.SettingsWrapperS>
                  )}
                  <Styles.ShareLinkButtonS onClick={() => setShareOpen(true)}>
                    <div>
                      <LinkIcon />
                      Share link
                    </div>
                  </Styles.ShareLinkButtonS>
                  <Styles.SettingsButtonS
                    isActive={isSettingsOpen}
                    onClick={() => setIsSettingsOpen(true)}
                  >
                    <SettingsIcon />
                  </Styles.SettingsButtonS>
                  {User.isLoggedIn && shareOpen && (
                    <ShareLink closeModal={() => setShareOpen(false)} />
                  )}
                </Styles.BottomBlockWrapperS>
              </>
            )}
          </Styles.ViewBodyS>
        </>
      </Styles.InnerWrapperS>
    </Styles.PreviewPageS>
  );
};
