import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS_RGBA } from '../../utils/colors';
import { DEFAULT_PADDING } from '../../utils/config';

export const WrapperS = styled.div`
  max-height: calc(100vh - 157px);

  overflow: auto;
`;
export const BlockS = styled.div`
  margin-bottom: 20px;
`;
export const SubtitleS = styled.h4`
  padding: 10px ${DEFAULT_PADDING};
  opacity: 0.5;
  font-size: 1.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111111;
  margin: 0;
`;
export const IconS = styled.span`
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
    opacity: 0.3;
  }
`;
export const ParentS = styled.div<{ active?: boolean }>`
  & > div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #3c3c3c;
    font-size: 1.6rem;

    font-weight: normal;
    transition: 0.1s;
    width: 100%;

    & > span {
      width: 100%;
      padding: 14px ${DEFAULT_PADDING};
      display: flex;
      align-items: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      &:hover {
        background: ${COLORS_RGBA.accent(0.05)};
        color: #3c3c3c;
      }
      &:active {
        background: ${COLORS_RGBA.accent(0.15)};
        color: #3c3c3c;
      }
    }

    & > a {
      width: 100%;
      padding-left: 58px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      &.active {
        background: none;
        font-weight: normal;
        color: ${COLORS_RGBA.accent(1)};
      }
    }

    &.active {
      & > span {
        background: ${COLORS_RGBA.accent(0.1)};
        font-weight: bold;
      }
      svg {
        opacity: 1;
        g {
          fill: ${COLORS_RGBA.accent(1)};
        }
      }
    }
  }
`;
export const LinkS = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 14px ${DEFAULT_PADDING};
  color: #3c3c3c;
  font-size: 1.6rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: normal;
  transition: 0.1s;
  &:hover {
    background: ${COLORS_RGBA.accent(0.05)};
    color: #3c3c3c;
  }

  &:active {
    background: ${COLORS_RGBA.accent(0.15)};
    color: #3c3c3c;
  }

  &.active {
    background: ${COLORS_RGBA.accent(0.1)};
    color: #3c3c3c;
    font-weight: bold;
    cursor: default;

    svg {
      opacity: 1;
      g {
        fill: ${COLORS_RGBA.accent(1)};
      }
    }
  }

  & > span {
    display: block;
  }
`;
export const OuterLinkS = styled.a`
  display: flex;
  align-items: center;
  padding: 14px ${DEFAULT_PADDING};
  color: #3c3c3c;
  font-size: 1.6rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: normal;
  transition: 0.1s;
  &:hover {
    background: ${COLORS_RGBA.accent(0.05)};
    color: #3c3c3c;
  }

  &:active {
    background: ${COLORS_RGBA.accent(0.15)};
    color: #3c3c3c;
  }

  &.active {
    background: ${COLORS_RGBA.accent(0.1)};
    color: #3c3c3c;
    font-weight: bold;
    cursor: default;

    svg {
      opacity: 1;
      g {
        fill: ${COLORS_RGBA.accent(1)};
      }
    }
  }

  & > span {
    display: block;
  }
`;
