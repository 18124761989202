import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TUser } from './types';

type TUserPayload = { id: string };

export const getUserR = (payload: TUserPayload, callBack: () => void) =>
  action(ActionTypes.GET_USER_R, { id: payload.id, callBack });

export const getUserS = (payload: TUser) =>
  action(ActionTypes.GET_USER_S, payload);

export const getUserE = (message: string) =>
  action(ActionTypes.GET_USER_E, message);

export const getMeR = (callBack: () => void) =>
  action(ActionTypes.GET_ME_R, { callBack });

export const getMeS = (payload: TUser) => action(ActionTypes.GET_ME_S, payload);

export const getMeE = (message: string) =>
  action(ActionTypes.GET_ME_E, message);

export const logOutR = (callBack?: () => void) =>
  action(ActionTypes.LOG_OUT_R, { callBack });
export const logOutS = () => action(ActionTypes.LOG_OUT_S);
export const logOutE = (message: string) =>
  action(ActionTypes.LOG_OUT_E, message);

type TForgotPassPayload = {
  email: string;
  callBack: () => void;
};
export const forgotPassR = (payload: TForgotPassPayload) =>
  action(ActionTypes.FORGOT_PASS_R, payload);

export const forgotPassS = () => action(ActionTypes.FORGOT_PASS_S);

export const forgotPassE = (message: string) =>
  action(ActionTypes.FORGOT_PASS_E, message);

export const setWorkspaceToUser = (payload: string) =>
  action(ActionTypes.SET_WORKSPACE, payload);

const UpdateActions = new Actions('UPDATE', ActionTypes);

export interface TypUpdateUserR extends TDefRequest {
  data: {
    first_name: string;
    last_name: string;
    is_profile_picture_changed: boolean;
    file: File | null;
  };
  src: string;
  id: string;
}

export const UpdateUser = {
  request: (payload: TypUpdateUserR) => UpdateActions.request(payload),
  success: (payload: TUser) => UpdateActions.success(payload),
  error: (message: string) => UpdateActions.error(message),
};
