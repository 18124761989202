import React from 'react';
import styled from 'styled-components';
import { TCat } from '../../store/categories/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { COLORS_RGBA } from '../../utils/colors';
import { CloseIcon } from '../svgs';
import { Alert, DefaultSelect, Loader } from '../ui';
import { ButtonLinkS } from '../ui/buttons/styles';

interface CatListProps {
  selectHandler: (id: TCat['id']) => void;
  closeHandler: () => void;
  teamId: string;
  title?: string;
}

export interface TInnerCat {
  id: string;
  name: string;
}

export const CatList: React.FC<CatListProps> = ({
  selectHandler,
  closeHandler,
  teamId,
  title = 'Please, choose the category to clone this assessment in "My Library"',
}) => {
  const [errors, setErrors] = React.useState('');
  const [cats, setCats] = React.useState<TInnerCat[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        const data = await callApi({
          path: API_ROUTE_PATH.categories.gList + '/' + teamId,
          method: 'get',
        });

        setCats(data);
      } catch (error) {
        setErrors(`${error || 'Some Error'}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [teamId]);

  if (loading) {
    return <Loader isGreen />;
  }
  return (
    <WrapperS>
      <InnerWrapperS>
        <HeaderS>
          <p>{title}</p>

          <ButtonCloseS onClick={closeHandler}>
            <CloseIcon />
          </ButtonCloseS>
        </HeaderS>
        {errors && <Alert text={errors} />}

        {cats && cats[0] ? (
          <div style={{ maxWidth: '300px', margin: '20px 20px 40px 20px' }}>
            <DefaultSelect
              data={cats.map((c) => ({ title: c.name, value: c.id, id: c.id }))}
              selected={null}
              onChange={(itm) => selectHandler(`${itm.id}`)}
              placeHolder="Choose the category"
            />
          </div>
        ) : (
          <div style={{ padding: '20px' }}>
            <Alert
              type="warning"
              text="Please create a category before duplicate assessment"
            />
          </div>
        )}
      </InnerWrapperS>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: absolute;
  top: 0;
  /* transform: translateY(-50%) translateX(-50%); */
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  /* max-width: 300px;
  min-width: 200px; */
  margin-bottom: 10px;
  overflow: auto;
  border-radius: 7px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.3);
`;

const InnerWrapperS = styled.div`
  max-height: 90%;
  position: relative;
  width: 80%;
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
`;

const HeaderS = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  p {
    font-size: 17px;
    margin: 0;
  }
  & > * {
    &:first-child {
      flex: 1;
    }
  }
`;

const ButtonCloseS = styled(ButtonLinkS)`
  margin-left: 30px;
  margin-top: 10px;

  &:hover {
    svg g {
      fill: ${COLORS_RGBA.accent()};
    }
  }
  /* position: absolute;
  right: 10px;
  top: 10px; */
`;
