import { SelectItemType } from '../../ui/selects/DefaultSelect';
import { ALL_ASS } from '../../../utils/consts';
import { TCollabAssessment } from '../../../store/collaborations/types';

export const getCollabAss = (
  selectItems: SelectItemType[],
  isAllQuestionsSelected: {
    [key: string]: boolean;
  },
  activeQuestionIds: SelectItemType[]
): TCollabAssessment[] => {
  const assessments: TCollabAssessment[] = [];
  const all_question_ids: (string | number)[] = [];
  activeQuestionIds.forEach((question) => {
    all_question_ids.push(question.id);
  });
  if (selectItems[0])
    selectItems.forEach((itm) => {
      if (itm.value !== ALL_ASS.value) {
        const current_question_ids: string[] = [];

        itm.questions.forEach((question) => {
          if (all_question_ids.includes(question.id)) {
            current_question_ids.push(question.id);
          }
        });

        assessments.push({
          assessment_id: itm.id as string,
          all_questions: isAllQuestionsSelected[itm.id],
          questions_ids: isAllQuestionsSelected[itm.id]
            ? []
            : current_question_ids,
        });
      }
    });

  return assessments;
};
