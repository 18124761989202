import React from 'react';

interface HomeIconProps {}

export const HomeIcon: React.FC<HomeIconProps> = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.705 2.408a.328.328 0 0 0-.41 0L3.405 7.92a.328.328 0 0 0-.124.257v9.213c0 .181.147.328.328.328h3.61v-6.89a.984.984 0 0 1 .984-.985h4.594a.984.984 0 0 1 .984.984v6.891h3.61a.328.328 0 0 0 .328-.328V8.176a.328.328 0 0 0-.124-.256l-6.89-5.512zM9.065.87a2.297 2.297 0 0 1 2.87 0l6.89 5.512c.545.436.863 1.096.863 1.794v9.213a2.297 2.297 0 0 1-2.297 2.297h-4.594a.984.984 0 0 1-.985-.985v-6.89H9.188v6.89a.984.984 0 0 1-.985.985H3.61a2.297 2.297 0 0 1-2.296-2.297V8.176c0-.697.317-1.357.862-1.793L9.065.871z"
        />
      </g>
    </svg>
  );
};
