import { ISelectItemData } from '../components/ui/buttons/types';

export function convertToSelectItems(data: Object[]): ISelectItemData[] {
  if (!data) return [];

  if (!Array.isArray(data)) {
    console.warn('Data is not an array');
    return [];
  }

  const selectItems: ISelectItemData[] = [];

  data.forEach((itm: any, i) => {
    const obj: ISelectItemData = {
      id: '',
      title: '',
      value: '',
    };

    if ('id' in itm) {
      obj.id = `${itm.id}`;
      obj.value = `${itm.id}`;
    } else {
      console.warn('Object does not have any key for Select item id');
      return null;
    }

    if ('name' in itm) {
      obj.title = `${itm.name}`;
    } else if ('first_name' in itm) {
      obj.title = `${itm.first_name}`;
      if ('last_name' in itm) {
        obj.title = obj.title + ' ' + itm.last_name;
      }
    } else if ('title' in itm) {
      obj.title = `${itm.title}`;
    } else {
      console.warn('Object does not have any key for Select item title');
      return null;
    }

    return selectItems.push(obj);
  });

  return selectItems;
}
