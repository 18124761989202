import { EAxisAnswerType, TChartFilter } from '../analytics/types';

export enum EColFilterStatus {
  inactive = 'inactive',
  captured = 'captured',
  uncaptured = 'uncaptured',
  matched = 'matched',
}

export enum EFriendScoreStatus {
  times_listed_0 = 'times-listed-0',
  times_listed_1 = 'times-listed-1',
  times_listed_2 = 'times-listed-2',
  times_listed_3 = 'times-listed-3',
  times_listed_4 = 'times-listed-4',
  times_listed_5 = 'times-listed-5',
  times_listed_6 = 'times-listed-6',
  times_listed_7 = 'times-listed-7',
  times_listed_8 = 'times-listed-8',
  times_listed_9 = 'times-listed-9',
  times_listed_10 = 'times-listed-10',
  times_listed_11 = 'times-listed-11',
  times_listed_12 = 'times-listed-12',
  times_listed_13 = 'times-listed-13',
  times_listed_14 = 'times-listed-14',
  times_listed_15 = 'times-listed-15',
  times_listed_16 = 'times-listed-16',
  times_listed_17 = 'times-listed-17',
  times_listed_18_or_more = 'times-listed-18-or-more',
}

export interface TCollab {
  source: string;
  source_first_name: string;
  source_last_name: string;
  source_friendly_score: number;
  source_percentile: number;
  target: string;
  target_first_name: string;
  target_friendly_score: number;
  target_last_name: string;
  target_percentile: number;
  type: 'manager' | 'default';
  is_mutual: boolean;
  source_filtered_status: EColFilterStatus;
  target_filtered_status: EColFilterStatus;
}

export interface TConnectednessScore {
  connectedness_score: number;
}

export interface TParticipantInfo {
  data: {
    assessment_id: string;
    assessment_name: string;
    average_score: number;
    answer_type: EAxisAnswerType;
    last_score: number;
  }[];
  groups: {
    id: string;
    name: string;
  }[];
  info: {
    first_name: string;
    last_name: string;
    email: string;
    phone_code: string;
    phone: string;
    hiring_date: string;
    ethnicity: string;
    gender: string;
    job: string;
    work_location_name: string;
    work_location_city: string;
    work_location_state: string;
    work_location_country: string;
    pay_group: string;
    birth_date: string;
    connectedness_score: number;
    number_of_selected_collaborators: number;
    number_of_collaborators_selected_friendly: number;
    number_of_collaborators_selected_participant: number;
    managers: {
      id: string;
      first_name: string;
      last_name: string;
    }[];
    payroll_id: [] | null;
  };
  notes:
    | {
        id: string;
        body: string;
        created_at: Date;
      }[]
    | null;
}

export interface TCollabAssessment {
  assessment_id: string;
  all_questions: boolean;
  questions_ids: string[];
}

export interface TCollaborationsState {
  loading: boolean;
  participantLoading: boolean;
  additionalLoading: boolean;
  notesLoading: boolean;
  data: TCollab[] | null;
  participantInfo: TParticipantInfo | null;
  filters: TChartFilter;
  additionalInfo: TConnectednessScore;
  errors?: string | undefined;
}

enum ActionTypes {
  GET_COLLAB_R = '@@collaborations/GET_COLLAB_R',
  GET_COLLAB_S = '@@collaborations/GET_COLLAB_S',
  GET_COLLAB_E = '@@collaborations/GET_COLLAB_E',

  GET_SCORE_R = '@@collaborations/GET_SCORE_R',
  GET_SCORE_S = '@@collaborations/GET_SCORE_S',
  GET_SCORE_E = '@@collaborations/GET_SCORE_E',

  GET_COLLAB_PARTICIPANT_INFO_R = '@@collaborations/GET_COLLAB_PARTICIPANT_INFO_R',
  GET_COLLAB_PARTICIPANT_INFO_S = '@@collaborations/GET_COLLAB_PARTICIPANT_INFO_S',
  GET_COLLAB_PARTICIPANT_INFO_E = '@@collaborations/GET_COLLAB_PARTICIPANT_INFO_E',

  ADD_COLLAB_PARTICIPANT_NOTE_R = '@@collaborations/ADD_COLLAB_PARTICIPANT_NOTE_R',
  ADD_COLLAB_PARTICIPANT_NOTE_S = '@@collaborations/ADD_COLLAB_PARTICIPANT_NOTE_S',
  ADD_COLLAB_PARTICIPANT_NOTE_E = '@@collaborations/ADD_COLLAB_PARTICIPANT_NOTE_E',

  DELETE_COLLAB_PARTICIPANT_NOTE_R = '@@collaborations/DELETE_COLLAB_PARTICIPANT_NOTE_R',
  DELETE_COLLAB_PARTICIPANT_NOTE_S = '@@collaborations/DELETE_COLLAB_PARTICIPANT_NOTE_S',
  DELETE_COLLAB_PARTICIPANT_NOTE_E = '@@collaborations/DELETE_COLLAB_PARTICIPANT_NOTE_E',

  UPDATE_COLLAB_PARTICIPANT_NOTE_R = '@@collaborations/UPDATE_COLLAB_PARTICIPANT_NOTE_R',
  UPDATE_COLLAB_PARTICIPANT_NOTE_S = '@@collaborations/UPDATE_COLLAB_PARTICIPANT_NOTE_S',
  UPDATE_COLLAB_PARTICIPANT_NOTE_E = '@@collaborations/UPDATE_COLLAB_PARTICIPANT_NOTE_E',

  SET_COLLAB_FILTERS = '@@collaborations/SET_COLLAB_FILTERS',

  GET_COLLAB_L = '@@collaborations/GET_COLLAB_L',

  CLEAN_UP = '@@collaborators/CLEAN_UP',
}

export default ActionTypes;
