import React from 'react';
import { MoreIcon } from '../../svgs/MoreIcon';
import { WrapperItemSelectS } from './styles';

interface MoreSelectItemProps {
  title?: string;
}

export const MoreSelectItem: React.FC<MoreSelectItemProps> = ({
  title = 'More Info',
}) => {
  return (
    <WrapperItemSelectS>
      <MoreIcon />
      {title}
    </WrapperItemSelectS>
  );
};
