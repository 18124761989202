export const AssessmentAnswers = [
  {
    title: 'Strongly Disagree',
    id: 1,
    value: 'Strongly Disagree',
  },
  {
    title: 'Somewhat Disagree',
    id: 2,
    value: 'Somewhat Disagree',
  },
  {
    title: 'Neither Agree nor Disagree',
    id: 3,
    value: 'Neither Agree nor Disagree',
  },
  {
    title: 'Somewhat Agree',
    id: 4,
    value: 'Somewhat Agree',
  },
  {
    title: 'Strongly Agree',
    id: 5,
    value: 'Strongly Agree',
  },
  {
    title: 'Pass',
    id: 0,
    value: 'Pass',
  },
];
