import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes from './types';

function* getAllAttachments(
  action: ReturnType<typeof Actions.GetAllAttachments.request>
): Generator {
  let success = true;
  let resp = null;
  const { workspaceId, callBack } = action.payload as Actions.TypeGetAllAttachR;
  try {
    resp = yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.attachments.getList + '/' + workspaceId,
    });
    yield put(Actions.GetAllAttachments.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllAttachments.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* getPresignedUrl(
  action: ReturnType<typeof Actions.GetPresignedUrl.request>
): Generator {
  let success = true;
  let resp = null;
  const { attachmentId, callBack } =
    action.payload as Actions.TypeGetPresignedUrlR;
  try {
    resp = yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.attachments.getUrl(attachmentId),
    });
    yield put(Actions.GetPresignedUrl.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetPresignedUrl.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* createAttach(
  action: ReturnType<typeof Actions.CreateAttachment.request>
): Generator {
  let success = true;
  let resp = null;
  const { callBack, workspaceId, data } =
    action.payload as Actions.TypeCreateAttachmentR;
  // const { name, description, workspace_id, languages_tags } = data;
  const sendData: {
    name: string;
    type: 'link' | 'file';
    link_url?: string;
  } = {
    name: data.name,
    type: data.type,
    link_url: data.link_url,
  };
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file);
  bodyFormData.append('fields', JSON.stringify(sendData));
  try {
    resp = yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.attachments.cOne + '/' + workspaceId,
      data: bodyFormData,
    });
    yield put(Actions.CreateAttachment.success({ ...resp, assessments: [] }));
  } catch (e) {
    success = false;
    yield put(Actions.CreateAttachment.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* deleteAttach(
  action: ReturnType<typeof Actions.DeleteAttachment.request>
): Generator {
  let success = true;
  let resp = null;
  const { attachmentId, callBack } =
    action.payload as Actions.TypeDeleteAttachmentR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.attachments.path + '/' + attachmentId,
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeleteAttachment.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_ATTACH_R, getAllAttachments);
  yield takeEvery(ActionTypes.DELETE_ATTACH_R, deleteAttach);
  yield takeEvery(ActionTypes.GET_PRESIGNED_URL_R, getPresignedUrl);
  yield takeEvery(ActionTypes.CREATE_ATTACH_R, createAttach);
}

export default function* attachmentsSaga() {
  yield all([fork(watchFetchRequest)]);
}
