import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/applicationState';
import { getDescription } from '../utils/getDescription';
import { getVersion } from '../utils/getVersion';

export const useDescription = () => {
  const { Assessments } = useSelector((store: AppStore) => store);
  const getAssDescription = (ids: string[]) => {
    return getDescription(ids, Assessments.answered || []);
  };
  const getAssVersion = (ids: string[]) => {
    return getVersion(ids, Assessments.answered || []);
  };
  return {
    getAssDescription,
    getAssVersion,
  };
};
