import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  DeleteGraph,
  EditOneGraph,
  GetChartsBlock,
  SetChartsFilters,
} from '../../store/analytics/actions';
import {
  ETypeGraph,
  TAnalyticsRow,
  TChart,
  TChartParams,
} from '../../store/analytics/types';
import { AppStore } from '../../store/applicationState';
import { GetAllAssessmentsByTeam } from '../../store/assessments/actions';
import { GRAPH_DEMO } from '../../utils/config';
import { deepClone } from '../../utils/deepClone';
import { VALUES } from '../../utils/valueConst';
import { AreYouShurePopUp, CustomGraph } from '../modals';
import { EditGraph } from '../modals/EditGraph';
import {
  ButtonArrow,
  ButtonMore,
  DefaultSelect,
  DeleteSelectItem,
  EditSelectItem,
} from '../ui';
import { ButtonLinkS } from '../ui/buttons/styles';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { AddCustomGraph } from './AddCustomGraph';
import { ChartBlock } from './charts/ChartBlock';
import { StackedHorizontalChart } from './charts/StackedHorizontalChart';
import * as RowStyles from './RowStyles';
import { ScatterChart } from './charts/ScatterChart';
import {LineScatterChart} from "./charts/LineScatterChart";

interface RowProps {
  row: TAnalyticsRow;
  rowIndex: number;
}

export const Row: React.FC<RowProps> = ({ row, rowIndex }) => {
  const { Analytics, Assessments, Workspaces } = useSelector(
    (store: AppStore) => store
  );
  const [isOpen, setIsOpen] = React.useState(!!row.charts[0]);
  const [isFirst] = React.useState(rowIndex === 0);
  const [isCustomGraph, setIsCustomGraph] = React.useState(false);
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const refHeader = React.useRef<HTMLDivElement>(null);
  const [selectDataQuestions] = React.useState<SelectItemType[]>([
    {
      title: 'All assessments',
      value: 'all',
      id: 'all',
    },
  ]);

  const dispatch = useDispatch();

  const callBack = (success: boolean, data: TAnalyticsRow) => {
    if (!success) return null;
    const newData = deepClone(Analytics.data) as TAnalyticsRow[];
    newData.splice(
      newData.findIndex((itm) => itm.id === data.id),
      1,
      data
    );
    dispatch(GetChartsBlock.success(newData));
  };

  React.useEffect(() => {

  }, []);

  React.useEffect(() => {
    if (isFirst && !isOpen) {
      dispatch(
        GetChartsBlock.request({
          workspace_id: Workspaces.current.id,
          block_id: row.id,
          data: Analytics.filters.params,
          callBack,
        })
      );
      const newFilters = deepClone(Analytics.filters) as TChartParams;
      newFilters.opened_visualizations = [];
      newFilters.opened_visualizations.push(row.id);
      dispatch(SetChartsFilters(newFilters));

      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [isFirst]);

  const deleteBlockHandler = () => {
    const callBack = (success: boolean) => {
      if (!success) return null;
      if (!Analytics.data) {
        console.warn('Block does not exist');
        return null;
      }
      const newRows = deepClone(Analytics.data) as TAnalyticsRow[];
      const index = newRows.findIndex((itm) => itm.id === row.id);
      newRows.splice(index, 1);
      dispatch(DeleteGraph.success(newRows));
      if (Assessments.existingData) {
        const curExistingData = deepClone(
          Assessments.existingData
        ) as typeof Assessments.existingData;
        const assIds: string[] = [];
        const deleteIds: string[] = [];
        Analytics.data.forEach((itm) =>
          itm.charts.forEach((chart) => {
            chart.assessments_ids.forEach((ass) => assIds.push(ass));
          })
        );
        row.charts.forEach((chart) =>
          chart.assessments_ids.forEach((ass) => deleteIds.push(ass))
        );
        row.charts.forEach((chart) => {
          chart.assessments_ids.forEach((assId) => {
            if (
              assIds.filter((itm) => itm === assId).length ===
              deleteIds.filter((itm) => itm === assId).length
            ) {
              curExistingData.splice(
                curExistingData.findIndex((curName) => curName.id === assId),
                1
              );
              for (
                let i = 0;
                i < deleteIds.filter((itm) => (itm = assId)).length;
                i++
              ) {
                deleteIds.splice(
                  deleteIds.findIndex((itm) => itm === assId),
                  1
                );
              }
            }
          });
        });
        dispatch(GetAllAssessmentsByTeam.success(curExistingData));
      }
    };
    dispatch(DeleteGraph.request({ id: row.id, callBack }));
  };

  const editBlockHandler = (name: string) => {
    const callBack = (success: boolean) => {
      if (!success) return null;
      if (!Analytics.data) {
        console.warn('Block does not exist');
        return null;
      }
      const newRows = deepClone(Analytics.data) as TAnalyticsRow[];
      const index = newRows.findIndex((itm) => itm.id === row.id);
      newRows[index].name = name;
      dispatch(EditOneGraph.success(newRows));
    };
    dispatch(EditOneGraph.request({ id: row.id, name, callBack }));
  };

  const renderChart = (data: TChart[]) => {
    // if (!data[0]) return <h3>No charts</h3>;
    return data.map((itm, index) => {
      switch (itm.type) {
        case ETypeGraph.type1:
          return (
            <ChartBlock
              chartIndex={index}
              index={rowIndex}
              data={itm}
              key={itm.id}
              widthLegend={false}
              dataStoryChartSettings={null}
              snapshotId={null}
              isStored={false}
              refHeader={refHeader}
            />
          );
        case ETypeGraph.type2:
          return (
            <StackedHorizontalChart
              chartIndex={index}
              index={rowIndex}
              data={itm}
              key={itm.id}
              isStored={false}
              dataStoryChartSettings={null}
              snapshotId={null}
              refHeader={refHeader}
            />
          );
        case ETypeGraph.type3:
          return (
            <ScatterChart
              chartIndex={index}
              index={rowIndex}
              data={itm}
              key={itm.id}
              isStored={false}
              dataStoryChartSettings={null}
              snapshotId={null}
              refHeader={refHeader}
            />
          );
        case ETypeGraph.type4:
          return (
              <LineScatterChart
                  chartIndex={index}
                  index={rowIndex}
                  data={itm}
                  key={itm.id}
                  widthLegend={false}
                  dataStoryChartSettings={null}
                  snapshotId={null}
                  isStored={false}
                  refHeader={refHeader}
              />
          );
        default:
          return (
            <div
              key={itm.id}
              style={{ padding: '120px 0', textAlign: 'center' }}
            >
              <h3>No Such Type of Graph yet</h3>
            </div>
          );
      }
    });
  };

  return (
    <>
      <RowStyles.WrapperS key={row.id}>
        <RowStyles.HeaderS>
          <RowStyles.HeaderTitleS
            onClick={() => {
              if (!isOpen) {
                dispatch(
                  GetChartsBlock.request({
                    workspace_id: Workspaces.current.id,
                    block_id: row.id,
                    data: Analytics.filters.params,
                    callBack,
                  })
                );
                const newFilters = deepClone(Analytics.filters) as TChartParams;
                newFilters.opened_visualizations.push(row.id);
                dispatch(SetChartsFilters(newFilters));
              } else {
                const newFilters = deepClone(Analytics.filters) as TChartParams;
                newFilters.opened_visualizations.splice(
                  newFilters.opened_visualizations.findIndex(
                    (itm) => itm === row.id
                  ),
                  1
                );
                dispatch(SetChartsFilters(newFilters));
              }
              setIsOpen((oldVal) => !oldVal);
            }}
          >
            <h3>{row.name}</h3>
          </RowStyles.HeaderTitleS>
          {isEditOpen && (
            <EditGraph
              closeHandler={() => setIsEditOpen(false)}
              row={row}
              saveHandler={editBlockHandler}
            />
          )}
          {isDeleteOpen && (
            <AreYouShurePopUp
              title={
                'You want to delete block with all graphs in it. Are you sure?'
              }
              yesClick={() => {
                setIsDeleteOpen(false);
                deleteBlockHandler();
              }}
              noClick={() => setIsDeleteOpen(false)}
              agreeText={'Yes, delete'}
              disagreeText={'No, keep the block'}
              width={500}
              height={130}
            />
          )}
          {!GRAPH_DEMO && (
            <>
              <DefaultSelect
                data={selectDataQuestions}
                onChange={() => null}
                selected={selectDataQuestions[0]}
              />
              <ButtonLinkS style={{ margin: '0 20px', fontSize: '14px' }}>
                Show benchmarks{' '}
              </ButtonLinkS>
            </>
          )}
          {row.origin === 'custom' && (
            <MoreButtonWrapperS>
              <ButtonMore
                onClick={(event) => {
                  if (event === VALUES.edit) return setIsEditOpen(true);
                  if (event === VALUES.delete && row.charts.length === 0) {
                    return deleteBlockHandler();
                  }
                  if (event === VALUES.delete) {
                    return setIsDeleteOpen(true);
                  }

                  return null;
                }}
                data={[
                  {
                    title: <EditSelectItem />,
                    id: 1,
                    value: VALUES.edit,
                  },
                  {
                    title: <DeleteSelectItem />,
                    id: 2,
                    value: VALUES.delete,
                  },
                ]}
              />
            </MoreButtonWrapperS>
          )}
          <ButtonArrow
            onClick={() => {
              if (!isOpen) {
                dispatch(
                  GetChartsBlock.request({
                    workspace_id: Workspaces.current.id,
                    block_id: row.id,
                    data: Analytics.filters.params,
                    callBack,
                  })
                );
                const newFilters = deepClone(Analytics.filters) as TChartParams;
                newFilters.opened_visualizations.push(row.id);
                dispatch(SetChartsFilters(newFilters));
              } else {
                const newFilters = deepClone(Analytics.filters) as TChartParams;
                newFilters.opened_visualizations.splice(
                  newFilters.opened_visualizations.findIndex(
                    (itm) => itm === row.id
                  ),
                  1
                );
                dispatch(SetChartsFilters(newFilters));
              }
              setIsOpen((oldVal) => !oldVal);
            }}
            active={isOpen}
          />
        </RowStyles.HeaderS>
        {isOpen && (
          <RowStyles.BodyWrapperS>
            <RowStyles.BodyS>
              <RowStyles.GraphsS>
                {renderChart(row.charts)}
                {/*<ScatterChart*/}
                {/*  chartIndex={999}*/}
                {/*  index={2}*/}
                {/*  data={{} as any}*/}
                {/*  key={3122}*/}
                {/*  widthLegend={false}*/}
                {/*  dataStoryChartSettings={null}*/}
                {/*  snapshotId={null}*/}
                {/*  isStored={false}*/}
                {/*/>*/}
                {row.origin !== 'default' && (
                  <AddCustomGraph onClick={() => setIsCustomGraph(true)} />
                )}
              </RowStyles.GraphsS>
            </RowStyles.BodyS>
          </RowStyles.BodyWrapperS>
        )}
      </RowStyles.WrapperS>

      {isCustomGraph && (
        <CustomGraph
          rowId={row.id}
          closeHandler={() => setIsCustomGraph(false)}
        />
      )}
    </>
  );
};
const MoreButtonWrapperS = styled.div`
  .btn-more {
    padding: 0;
  }
`;
