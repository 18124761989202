import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { TQuestionSelect } from '../../../store/assessments/types';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import { COLORS_RGBA } from '../../../utils/colors';
import { ALL_ASS, ALL_QUESTIONS } from '../../../utils/consts';
import { delay } from '../../../utils/helpers';
import { ArrowIcon, CheckIcon } from '../../svgs';
import { SelectItemType } from './DefaultSelect';

interface AssesmQuestionSelectProps {
  data: SelectItemType[];
  selected: SelectItemType | null;
  placeHolder?: string;
  icon?: React.FC;
  label?: string;
  isMultiple?: boolean;
  activeSelects?: SelectItemType[];
  activeQuestionSelects?: SelectItemType[];
  onAssessmentChange: (item: SelectItemType) => void;
  onQuestionChange?: (parent: SelectItemType, data: SelectItemType) => void;
  isOpenHandler?: (isOpen: boolean) => void;
  openToTop?: boolean;
  setQuestionActiveSelects?: React.Dispatch<
    React.SetStateAction<SelectItemType[]>
  >;
  isAllQuestionsSelected: {
    [key: string]: boolean;
  };
  setIsAllQuestionsSelected: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

export const AssesmQuestionSelect: React.FC<AssesmQuestionSelectProps> = ({
  data,
  selected,
  onAssessmentChange,
  onQuestionChange,
  label,
  placeHolder,
  icon: Icon,
  isOpenHandler,
  isMultiple,
  activeSelects = [],
  activeQuestionSelects = [],
  setQuestionActiveSelects,
  setIsAllQuestionsSelected,
  isAllQuestionsSelected,
  openToTop,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentRawQList, setCurrentRawQList] =
    React.useState<TQuestionSelect[]>(null);
  const [currentSelectQList, setCurrentSelectQList] =
    React.useState<SelectItemType[]>(null);
  const [currentQuestionParentId, setCurrentQuestionParentId] =
    React.useState<SelectItemType>(null);
  const modalRef = React.useRef<HTMLUListElement>(null);
  const questionsRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const newActiveSelects = activeQuestionSelects;
    const newIsAllSelected: { [key: string]: boolean } = {
      ...isAllQuestionsSelected,
    };

    if (data && data[0]) {
      data.forEach((assessment) => {
        if (
          activeSelects.findIndex((active) => active.id === assessment.id) >=
            0 &&
          !(assessment.id in newIsAllSelected) &&
          assessment.questions
        ) {
          assessment.questions.forEach((question) => {
            newActiveSelects.push({
              value: question.id,
              title: question.body,
              id: question.id,
            });
          });
          newIsAllSelected[assessment.id] = true;
        }
        if (
          !(
            activeSelects.findIndex((active) => active.id === assessment.id) >=
            0
          ) &&
          assessment.id in newIsAllSelected
        ) {
          assessment.questions.forEach((question) => {
            if (
              newActiveSelects.findIndex(
                (active) => active.id === question.id
              ) >= 0
            ) {
              newActiveSelects.splice(
                newActiveSelects.findIndex(
                  (active) => active.id === question.id
                ),
                1
              );
              delete newIsAllSelected[assessment.id];
            }
          });
        }
      });
    }

    setQuestionActiveSelects(newActiveSelects);
    setIsAllQuestionsSelected(newIsAllSelected);
    //eslint-disable-next-line
  }, [
    data,
    activeSelects,
    setIsAllQuestionsSelected,
    setQuestionActiveSelects,
  ]);

  React.useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  const hideList = async () => {
    if (modalRef && modalRef.current) {
      modalRef.current.classList.remove(SelectClasses.in);
      modalRef.current.classList.add(SelectClasses.out);
      await delay(100);
      setCurrentRawQList(null);
      setCurrentSelectQList(null);
      setCurrentQuestionParentId(null);
      setIsOpen(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    if (isOpen) {
      hideList();
    }
  });

  React.useEffect(() => {
    if (currentRawQList) {
      const newQuestionsSelect: SelectItemType[] = [];
      //   newQuestionsSelect.push(ALL_QUESTIONS);
      currentRawQList.forEach((question) => {
        newQuestionsSelect.push({
          value: question.id,
          title: question.body,
          id: question.id,
        });
      });
      setCurrentSelectQList(newQuestionsSelect);
    }
  }, [currentRawQList]);

  return (
    <WrapperS className="ov-select" ref={ref}>
      {label ? <LabelS>{label}</LabelS> : null}

      <SelectBtnS
        isOpen={isOpen}
        className="ov-select-wr"
        onClick={() => {
          setIsOpen(true);
          if (isOpenHandler) isOpenHandler(true);
        }}
      >
        {Icon && (
          <span className="svgIconSelect">
            <Icon />
          </span>
        )}
        <SelectSpanS active={!!selected}>
          {selected ? (
            isMultiple && activeSelects[0] ? (
              <span className="multiSpan">
                {activeSelects.map(
                  (s, i) =>
                    s.title + (activeSelects.length === i + 1 ? '' : ', ')
                )}{' '}
              </span>
            ) : (
              selected.title
            )
          ) : (
            placeHolder
          )}
        </SelectSpanS>
        {data[1] && <ArrowIcon />}
      </SelectBtnS>

      {!isOpen ? null : (
        <>
          <ItemsWrapperS
            top={openToTop}
            ref={modalRef}
            className={`${mainClass} ${SelectClasses.in}`}
          >
            {data && data[0] ? (
              data.map((itm) => {
                if (!isMultiple && selected && selected.id === itm.id)
                  return null;

                return (
                  <ItemS
                    key={itm.id}
                    selected={
                      isMultiple &&
                      !!activeSelects.find((it) => it.value === itm.value)
                    }
                  >
                    <span
                      onClick={() => {
                        if (!isMultiple) {
                          hideList();
                        }
                        onAssessmentChange(itm);
                        setCurrentRawQList(null);
                        setCurrentSelectQList(null);
                        setCurrentQuestionParentId(null);
                      }}
                    >
                      <span>{itm.title}</span>
                    </span>
                    {isMultiple &&
                      activeSelects.find((it) => it.value === itm.value) &&
                      itm.value !== ALL_ASS.value && (
                        <ArrowWrapperS
                          onClick={() => {
                            setCurrentRawQList(itm.questions);
                            setCurrentQuestionParentId(itm);
                          }}
                          isActive={
                            currentRawQList &&
                            itm.questions &&
                            itm.questions === currentRawQList
                          }
                        >
                          <ArrowIcon />
                        </ArrowWrapperS>
                      )}
                  </ItemS>
                );
              })
            ) : (
              <ItemS noItems>No items</ItemS>
            )}
          </ItemsWrapperS>
          {currentSelectQList && currentQuestionParentId && (
            <QuestionsWrapperS
              ref={questionsRef}
              className={`${mainClass} ${SelectClasses.in}`}
            >
              <QuestionS>
                <span
                  onClick={() => {
                    onQuestionChange(currentQuestionParentId, ALL_QUESTIONS);
                  }}
                >
                  <span>{ALL_QUESTIONS.title}</span>
                  {isAllQuestionsSelected[currentQuestionParentId.id] && (
                    <CheckIcon />
                  )}
                </span>
              </QuestionS>
              {currentSelectQList.map((question, i) => {
                return (
                  <QuestionS key={question.id}>
                    <span
                      onClick={() => {
                        onQuestionChange(currentQuestionParentId, question);
                      }}
                    >
                      <span>{`${i + 1}. ${question.title}`}</span>
                      {activeQuestionSelects.find(
                        (it) => it.id === question.id
                      ) &&
                        !isAllQuestionsSelected[currentQuestionParentId.id] && (
                          <CheckIcon />
                        )}
                    </span>
                  </QuestionS>
                );
              })}
            </QuestionsWrapperS>
          )}
        </>
      )}
    </WrapperS>
  );
};

export const WrapperS = styled.div`
  position: relative;
  width: 100%;
`;
export const InnerWrapperS = styled.div`
  display: flex;
  justify-content: center;
`;
export const QuestionsWrapperS = styled.div`
  max-height: 300px;
  width: 300px;
  position: absolute;
  overflow: auto;
  background: white;
  top: calc(100% + 10px);
  left: calc(100% + 10px);
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgb(42 47 42 / 16%);
  margin: 0;
  padding: 0;
  z-index: 2;
`;
export const SelectBtnS = styled.div<{ isOpen?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  border: solid 1px ${COLORS_RGBA.default(0.2)};
  border-radius: 6px;
  height: 48px;
  align-items: center;
  padding: 13px 16px;
  cursor: pointer;

  .svgIconSelect {
    margin-right: 10px;
  }

  &:hover {
    border-color: ${COLORS_RGBA.accent(1)};
  }

  svg {
    transition: 0.2s;
    ${({ isOpen }) =>
      isOpen
        ? `
   transform: translateY(1px) scale(1, -1);
  `
        : ''}
  }
`;

export const SelectSpanS = styled.span<{ active?: boolean }>`
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ active }) => (active ? `  opacity: 1;` : `  opacity: 0.6;`)}

  .multiSpan {
    max-width: 200px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
`;

export const LabelS = styled.div`
  span {
    font-size: 1.4rem;
    display: block;
    color: ${COLORS_RGBA.default(0.8)};
    font-weight: 400;
  }
  margin-bottom: 5px;
`;

export const ItemsWrapperS = styled.ul<{ top?: boolean }>`
  position: absolute;

  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.16);
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  z-index: 2;
  ${({ top }) =>
    top
      ? `
    bottom: 100%;
  `
      : `
    top: 100%;
  `}
`;
export const ArrowWrapperS = styled.div<{ isActive?: boolean }>`
  height: 100%;
  padding: 13px 5px;

  &:hover {
    background: ${COLORS_RGBA.accent(0.13)};
  }

  &:active {
    background: ${COLORS_RGBA.accent(1)};
    svg {
      g {
        path {
          fill: white !important;
        }
      }
    }
  }

  svg {
    transform: rotate(270deg);
    width: 15px !important;
    height: 15px !important;
    min-width: 8px !important;
    min-height: 8px !important;

    g {
      opacity: 1;
      path {
        fill: ${({ isActive }) =>
          isActive ? `white` : `${COLORS_RGBA.accent()}`};
      }
    }
  }
`;
export const ItemS = styled.li<{ noItems?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  & > span {
    flex: 1;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding: 13px 16px;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: ${COLORS_RGBA.accent()};
      width: 25px;
      height: 25px;
      min-width: 18px;
      min-height: 18px;
    }

    & > * {
      &:first-child {
        flex: 1;
      }
    }

    &:hover {
      background: ${COLORS_RGBA.accent(0.13)};
    }

    &:active {
      background: ${COLORS_RGBA.accent(1)};
      color: white;
    }
  }

  ${({ selected }) =>
    selected ? `background: ${COLORS_RGBA.accent(0.3)};` : ``}

  ${({ noItems }) =>
    noItems
      ? `
    padding: 10px;
  `
      : ``}
`;
export const QuestionS = styled.li<{ noItems?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  & > span {
    flex: 1;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding: 13px 16px;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: ${COLORS_RGBA.accent()};
      width: 25px;
      height: 25px;
      min-width: 18px;
      min-height: 18px;
    }

    & > * {
      &:first-child {
        flex: 1;
      }
    }

    &:hover {
      background: ${COLORS_RGBA.accent(0.13)};
    }

    &:active {
      background: ${COLORS_RGBA.accent(1)};
      color: white;
    }
  }

  ${({ selected }) =>
    selected ? `background: ${COLORS_RGBA.accent(0.3)};` : ``}

  ${({ noItems }) =>
    noItems
      ? `
    padding: 10px;
  `
      : ``}
`;
