import React from 'react';
import styled from 'styled-components';
import { Env } from '../components/Env';

interface AppVersionProps {}

export const AppVersion: React.FC<AppVersionProps> = () => {
  return (
    <WrapperS>
      <Env />
      <a href="/"> Go Back </a>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: white;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > div {
    position: relative;
    font-size: 1.5rem;
    left: inherit;
  }

  a {
    margin: 20px;
    font-size: 1.6rem;
  }
`;
