import { Translation } from '../../data/translation';
import { TLang } from '../../types/lang';
import { SelectItemType } from '../ui/selects/DefaultSelect';

export const en = Translation.en_US;
export const es = Translation.es_ES;
export const fr = Translation.fr_FR;
export const he = Translation.he_IL;

export const checkLang = (lang: TLang) => {
  switch (lang) {
    case 'en_US':
      return en;
    case 'es_ES':
      return es;
    case 'fr_FR':
      return fr;
    case 'he_IL':
      return he;
  }
};
export const getLangName = (lang: TLang) => {
  switch (lang) {
    case 'en_US':
      return 'English';
    case 'es_ES':
      return 'Spanish';
    case 'fr_FR':
      return 'French';
    case 'he_IL':
      return 'Hebrew';
  }
};
export const getNativeLangName = (lang: TLang) => {
  switch (lang) {
    case 'en_US':
      return 'English';
    case 'es_ES':
      return 'Español';
    case 'fr_FR':
      return 'Français';
    case 'he_IL':
      return 'עִבְרִית';
  }
};
export const getNativeLangSelect = (langs: TLang[]) => {
  const newLangSelect: SelectItemType[] = [];
  langs.forEach((itm) => {
    newLangSelect.push({ title: getNativeLangName(itm), value: itm, id: itm });
  });
  return newLangSelect;
};
