import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { EditGroup } from '../../../store/groups/actions';
import { TGroup } from '../../../store/groups/types';
import { MoveToGroupParticipant } from '../../../store/participants/actions';
import { TParticipant } from '../../../store/participants/types';
import { AnimatedClasses } from '../../../utils/animatedStyles';
import { deepClone } from '../../../utils/deepClone';

export const useGroupParticipant = (payload: { groupId: string; groupPosition: number }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const participantRef = React.useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { Groups, Participants } = useSelector((store: AppStore) => store);

  const deleteItem = (participant_id: string, position: number) => {
    const allGroups = deepClone(Groups.data) as TGroup[];
    const allParticipants = deepClone(Participants.data) as TParticipant[];

    if (!allGroups) return console.error('Could not clone Groups');
    if (!allParticipants) return console.error('Could not clone Participants');

    const group = Array.isArray(allGroups) ? allGroups[payload.groupPosition] : null;

    if (!group) return console.error('Group does not exist deleteItem useGroupSection hook');

    if (group.id !== payload.groupId)
      return console.error('Group does not have the save id as deleteItem - useGroupSection hook');

    const deletedParticipant = group.participants.splice(position, 1);

    if (!deletedParticipant) return console.error('Could not get deleted participant');

    const participant = allParticipants.find((p) => `${p.id}` === `${deletedParticipant[0].id}`);

    if (!participant) return console.error('Could not get participant');
    setIsLoading(true);
    participant.groups = participant.groups.filter(
      (participantGroup) => `${participantGroup.id}` !== `${group.id}`
    );

    dispatch(
      MoveToGroupParticipant.request({
        data: {
          relation: false,
          participant_id,
          group_id: payload.groupId,
        },
        callBack: (success) => {
          setIsLoading(false);

          if (success && participantRef && participantRef.current) {
            participantRef.current.classList.add(...AnimatedClasses.fadeOutRight.split(' '));
            setTimeout(() => {
              participantRef.current!.style.display = 'none';
              dispatch(MoveToGroupParticipant.success(allParticipants));
              dispatch(EditGroup.success(allGroups));
            }, 500);
          }
        },
      })
    );
  };

  return {
    deleteItem,
    participantRef,
    isLoading,
  };
};
