import React from 'react';
import styled from 'styled-components';
import {
  ANSWERS_DATA,
  ANSWERS_DATA_REVERSED,
  ANSWERS_DATA_TEN,
  DEFAULT_STEPS_COUNT,
} from '../../pages/Responding/config';
import { TRespAnswer, TRespAssessment } from '../../pages/Responding/types';
import { ANIM_CL, mainClass } from '../../utils/animatedStyles';
import { CSS_MEDIA } from '../../utils/media';
import { TEXT } from '../../utils/textConst';
import { ArrowRightIcon } from '../svgs';
import { Alert, TextArea } from '../ui';
import { RespTitle } from './components/RespTitle';
import { RespBtnS } from './styles';
import { TLang } from '../../types/lang';
import { checkLang } from './assLang';
import {
  langIsAllingRight,
  langIsEng,
  langIsFrench,
  langIsHebrew,
  langIsSpan,
} from '../../utils/langIsEng';
import { EQuestionType } from '../../store/assessments/types';

interface ScreenCurrentAssessmentProps {
  nextFn: (answer: TRespAnswer, stepNum: number) => void;
  questionStep: number;
  ass: TRespAssessment;
  answers: TRespAnswer[];
  lang: TLang;
}

export const ScreenCurrentAssessment: React.FC<
  ScreenCurrentAssessmentProps
> = ({ nextFn, ass, questionStep, answers, lang }) => {
  const index = questionStep - DEFAULT_STEPS_COUNT - 1;
  const refWr = React.useRef<HTMLDivElement>(null);
  const T = ANSWERS_DATA(lang)[0];
  const [selected, setSelected] = React.useState<typeof T | null>(null);
  const [textAnswer, setTextAnswer] = React.useState('');

  console.log(ass.questions)
  React.useEffect(() => {
    if (answers[index]) {
      const selData = {
        id: 99,
        title: answers[index].answer_body,
        value: answers[index].answer_value,
      };
      setSelected(selData);
    }

    if (refWr && refWr.current) {
      refWr.current.classList.remove(ANIM_CL.zoomOut);
      refWr.current!.classList.add(ANIM_CL.zoomIn);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (ass.questions[index].answer_type === EQuestionType.text ||
        ass.questions[index].answer_type === EQuestionType.text_anonymous) {
      setSelected({ id: 0, title: 'Pass', value: 0 });
    }
    // return () => {
    //   if (refCur) {
    //     refCur.classList.remove(ANIM_CL.zoomIn);
    //     refCur.classList.add(ANIM_CL.zoomOut);
    //   }
    // };

    // eslint-disable-next-line
  }, [answers, index]);

  if (!ass) return <Alert text={`No assessment,` + TEXT.tryLater} />;
  if (!ass.id) return <Alert text={`No assessment id,` + TEXT.tryLater} />;
  if (!ass.questions || !ass.questions)
    return (
      <Alert text={`Assessment does not have questions,` + TEXT.tryLater} />
    );
  const saveAnswer = () => {
    if (refWr && refWr.current) {
      refWr.current.classList.remove(ANIM_CL.zoomIn);
      setTimeout(() => {
        refWr.current!.classList.add(ANIM_CL.zoomOut);
      }, 100);
    }

    setTimeout(() => {
      setSelected(null);
      setTextAnswer('');
      nextFn(
        {
          answer_body: (selected && selected.title) || '',
          answer_value: (selected && selected.value) || 0,
          question_id: ass.questions[index].id,
          answer_type: ass.questions[index].answer_type,
        },
        questionStep + 1
      );
    }, 300);
  };

  const renderAssBody = (body: typeof ass.questions[0]['body']) => {
    if (langIsEng(lang)) {
      return body.en_US;
    }
    if (langIsSpan(lang) && body.es_ES) {
      return body.es_ES;
    }
    if (langIsFrench(lang) && body.fr_FR) {
      return body.fr_FR;
    }
    if (langIsHebrew(lang) && body.he_IL) {
      return body.he_IL;
    }
    return body.en_US;
  };
  const renderScale = (lang: TLang) => {
    if (!ass.questions[index].scale_start_label.en_US) return null;
    const currentScales = () => {
      switch (lang) {
        case 'en_US': {
          return {
            scale_start: ass.questions[index].scale_start_label.en_US,
            scale_end: ass.questions[index].scale_end_label.en_US,
          };
        }
        case 'es_ES': {
          return {
            scale_start: ass.questions[index].scale_start_label.es_ES,
            scale_end: ass.questions[index].scale_end_label.es_ES,
          };
        }
        case 'fr_FR': {
          return {
            scale_start: ass.questions[index].scale_start_label.fr_FR,
            scale_end: ass.questions[index].scale_end_label.fr_FR,
          };
        }
        case 'he_IL': {
          return {
            scale_start: ass.questions[index].scale_start_label.he_IL,
            scale_end: ass.questions[index].scale_end_label.he_IL,
          };
        }
        default: {
          return {
            scale_start: ass.questions[index].scale_start_label.en_US,
            scale_end: ass.questions[index].scale_end_label.en_US,
          };
        }
      }
    };
    return (
      <TenTextWrapperS>
        <TenScaleLeftS alignRight={langIsAllingRight(lang)}>
          {currentScales().scale_start}
        </TenScaleLeftS>
        <TenScaleRightS alignRight={langIsAllingRight(lang)}>
          {currentScales().scale_end}
        </TenScaleRightS>
      </TenTextWrapperS>
    );
  };
  return (
    <WrapperAssS
      ref={refWr}
      className={mainClass}
      tabIndex={0}
      onKeyDown={(e: { keyCode: number; preventDefault: () => void }) => {
        if (e.keyCode === 13 && selected) {
          e.preventDefault();
          saveAnswer();
        }
      }}
      style={{ outline: 0 }}
    >
      <RespTitle
        title={renderAssBody(ass.questions[index].body)}
        alignRight={langIsAllingRight(lang)}
      />
      <WrapperS>
        {ass.questions[index].answer_type === EQuestionType.five &&
          ANSWERS_DATA(lang).map((itm) => {
            return (
              <SelectItemS
                key={itm.id}
                onClick={() => {
                  setSelected(itm);
                  nextFn(
                    {
                      answer_body: (itm && itm.title) || '',
                      answer_value: (itm && itm.value) || 0,
                      question_id: ass.questions[index].id,
                      answer_type: ass.questions[index].answer_type,
                    },
                    questionStep
                  );
                }}
                active={!!selected && selected.value === itm.value}
                alignRight={langIsAllingRight(lang)}
              >
                {itm.title}
              </SelectItemS>
            );
          })}
        {ass.questions[index].answer_type === EQuestionType.five_reverse &&
          ANSWERS_DATA_REVERSED(lang).map((itm) => {
            return (
              <SelectItemS
                key={itm.id}
                onClick={() => {
                  setSelected(itm);
                  nextFn(
                    {
                      answer_body: (itm && itm.title) || '',
                      answer_value: (itm && itm.value) || 0,
                      question_id: ass.questions[index].id,
                      answer_type: ass.questions[index].answer_type,
                    },
                    questionStep
                  );
                }}
                active={!!selected && selected.value === itm.value}
                alignRight={langIsAllingRight(lang)}
              >
                {itm.title}
              </SelectItemS>
            );
          })}
        {ass.questions[index].answer_type === EQuestionType.ten && (
          <div>
            <SelectTenWrapperS>
              {ANSWERS_DATA_TEN(lang).map((itm) => {
                return (
                  <SelectItemsTenS
                    key={itm.id}
                    onClick={() => {
                      setSelected(itm);
                      nextFn(
                        {
                          answer_body: (itm && itm.title) || '',
                          answer_value: (itm && itm.value) || 0,
                          question_id: ass.questions[index].id,
                          answer_type: ass.questions[index].answer_type,
                        },
                        questionStep
                      );
                    }}
                    active={!!selected && selected.value === itm.value}
                    isPass={
                      itm.title === checkLang(lang).assessments.answers['0']
                    }
                  >
                    {itm.title}
                  </SelectItemsTenS>
                );
              })}
            </SelectTenWrapperS>
            {renderScale(lang)}
          </div>
        )}
        {ass.questions[index].answer_type === EQuestionType.text && (
          <TextArea
            onChange={(e) => {
              setTextAnswer(e.currentTarget.value);
              setSelected({
                id: 0,
                title: e.currentTarget.value ? e.currentTarget.value : 'Pass',
                value: 0,
              });
              console.log(e.currentTarget.value);
              nextFn(
                {
                  answer_body: e.currentTarget.value
                    ? e.currentTarget.value
                    : 'Pass',
                  answer_value: null,
                  question_id: ass.questions[index].id,
                  answer_type: ass.questions[index].answer_type,
                },
                questionStep
              );
            }}
            value={textAnswer}
            stylesWrapper={{ width: `100%`, minWidth: '250px' }}
            maxLength={500}
          />
        )}
        {ass.questions[index].answer_type === EQuestionType.text_anonymous && (
                <TextArea
                    onChange={(e) => {
                      setTextAnswer(e.currentTarget.value);
                      setSelected({
                        id: 0,
                        title: e.currentTarget.value ? e.currentTarget.value : 'Pass',
                        value: 0,
                      });
                      console.log(e.currentTarget.value);
                      nextFn(
                          {
                            answer_body: e.currentTarget.value
                                ? e.currentTarget.value
                                : 'Pass',
                            answer_value: null,
                            question_id: ass.questions[index].id,
                            answer_type: ass.questions[index].answer_type,
                          },
                          questionStep
                      );
                    }}
                    value={textAnswer}
                    stylesWrapper={{ width: `100%`, minWidth: '250px' }}
                    maxLength={500}
                />
            )}
      </WrapperS>
      {/* <div style={{ textAlign: 'right' }}> */}
      <RespBtnS
        onClick={saveAnswer}
        disabled={
          ass.questions[index].answer_type === EQuestionType.text ||
          ass.questions[index].answer_type === EQuestionType.text_anonymous
            ? false
            : !selected
        }
        alignRight={langIsAllingRight(lang)}
      >
        {checkLang(lang).assessments.btns.next} <ArrowRightIcon />
      </RespBtnS>
      {/* </div> */}
    </WrapperAssS>
  );
};

const WrapperS = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  flex-wrap: wrap;
`;
const TenTextWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  ${CSS_MEDIA.maxMd} {
    display: none;
  }
`;
const TenScaleLeftS = styled.p<{ alignRight: boolean }>`
  padding: 0;
  margin: 0;
  width: 300px;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
`;
const TenScaleRightS = styled.p<{ alignRight: boolean }>`
  padding: 0;
  margin: 0;
  width: 300px;
  text-align: right;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
`;
const SelectTenWrapperS = styled.div`
  border-radius: 66px;
  border: 1px solid rgba(197, 197, 209, 0.39);
  overflow: hidden;
  display: flex;
  ${CSS_MEDIA.maxMd} {
    border-radius: 30px;
    flex-wrap: wrap;
  }
`;
const SelectItemsTenS = styled.div<{ active?: boolean; isPass?: boolean }>`
  text-align: center;
  cursor: pointer;
  transition: 0.1s;
  padding: 10px 34px;
  border-right: 1px solid rgba(197, 197, 209, 0.39);
  ${CSS_MEDIA.minLg} {
    &:hover {
      background-color: #4ac24f;
      color: white;
    }
  }
  ${CSS_MEDIA.maxMd} {
    width: 50%;
    border-bottom: 1px solid rgba(197, 197, 209, 0.39);

    ${({ isPass }) =>
      isPass &&
      `
      width:100%
      `}
  }
  ${({ active }) =>
    active &&
    `
    background-color: #4ac24f;
    color: white;
    cursor: default;
  `}
`;
const SelectItemS = styled.div<{ active?: boolean; alignRight: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 11px 16px 10px;
  margin: 10px 8px;
  border-radius: 66px;
  background-color: #f5f5fa;
  cursor: pointer;
  transition: 0.1s;

  ${CSS_MEDIA.maxMd} {
    width: 100%;
    margin: 10px 0;
    direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  }
  ${CSS_MEDIA.minLg} {
    &:hover {
      background-color: #4ac24f;
      color: white;
    }
  }

  ${({ active }) =>
    active &&
    `
    background-color: #4ac24f;
    color: white;
    cursor: default;
  `}
`;

const WrapperAssS = styled.div`
  ${CSS_MEDIA.maxLg} {
    padding: 150px 0 30px;
  }
`;
