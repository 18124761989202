import React from 'react';
import styled from 'styled-components';

import { AnimatedClasses } from '../../utils/animatedStyles';
import { Alert, ButtonSave, Input } from '../ui';
import { WorkspaceAvatarIcon } from '../svgs/WorkspaceAvatarIcon';
import { ButtonBorderedS } from '../ui/buttons/styles';
import { useWorkspaceInfo } from './hooks/useWorkspaceInfo';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { countryDial } from '../../utils/countryDial';
import { SelectListS } from '../Responding/styles';
import { COLORS_RGBA } from '../../utils/colors';

interface WorkspaceNameProps {}

export const WorkspaceInfo: React.FC<WorkspaceNameProps> = () => {
  const hookWorkspaceInfo = useWorkspaceInfo();
  const [isListVisible, setIsListVisible] = React.useState(false);
  const { ref: refList } = useOutsideClick(() => setIsListVisible(false));
  const [searchValue, setSearchValue] = React.useState('');
  return (
    <>
      {hookWorkspaceInfo.errors && <Alert text={hookWorkspaceInfo.errors} />}
      <WrapperImageTextS>
        <ImageWrapperS>
          <WorkspaceAvatarIcon />
          {hookWorkspaceInfo.image.src !== '' && (
            <ImageS
              style={{
                backgroundImage: `url("${hookWorkspaceInfo.image.src}")`,
              }}
            />
          )}
        </ImageWrapperS>
        <TextWrapperS>
          <p>Workspace Image</p>
          <input
            ref={hookWorkspaceInfo.refInputUpload}
            onChange={() => {
              hookWorkspaceInfo.onChangeUploadHandler(
                hookWorkspaceInfo.updateImageHandler
              );
            }}
            type="file"
            style={{ display: 'none' }}
          />
          <BtnsWrapperS>
            <ButtonBorderedS onClick={hookWorkspaceInfo.clickUploadHandler}>
              Upload
            </ButtonBorderedS>
            {hookWorkspaceInfo.image.src && (
              <ButtonBorderedS
                onClick={() => {
                  hookWorkspaceInfo.removeImageHandler(
                    hookWorkspaceInfo.updateImageHandler
                  );
                }}
              >
                Remove
              </ButtonBorderedS>
            )}
          </BtnsWrapperS>
        </TextWrapperS>
      </WrapperImageTextS>

      <FormS
        action=""
        onSubmit={hookWorkspaceInfo.saveBtnHandler}
        className={AnimatedClasses.fadeIn}
      >
        <h1>Info</h1>
        <Input
          value={hookWorkspaceInfo.nameValue}
          onChange={hookWorkspaceInfo.onChangeHandler}
          label="Workspace Name"
          placeholder="Workspace Name"
          errorText={hookWorkspaceInfo.nameError}
          name={hookWorkspaceInfo.inputName}
        />
        <Input
          value={hookWorkspaceInfo.siteValue}
          onChange={hookWorkspaceInfo.onChangeHandler}
          label="Company Website"
          placeholder="Company Website"
          errorText={hookWorkspaceInfo.siteError}
          name={hookWorkspaceInfo.inputSite}
        />
        <h1>Main contact</h1>

        <Input
          value={hookWorkspaceInfo.firstNameValue}
          onChange={hookWorkspaceInfo.onChangeHandler}
          label="First Name"
          placeholder="First Name"
          errorText={hookWorkspaceInfo.firstNameError}
          name={hookWorkspaceInfo.inputContactFirst}
        />
        <Input
          value={hookWorkspaceInfo.lastNameValue}
          onChange={hookWorkspaceInfo.onChangeHandler}
          label="Last Name"
          placeholder="Last Name"
          errorText={hookWorkspaceInfo.lastNameError}
          name={hookWorkspaceInfo.inputContactLast}
        />
        <Input
          value={hookWorkspaceInfo.emailValue}
          onChange={(e) => {
            hookWorkspaceInfo.changeEmailHandler(e.currentTarget.value);
          }}
          label="Email"
          placeholder="Email"
          errorText={hookWorkspaceInfo.emailError}
          name={hookWorkspaceInfo.inputContactEmail}
        />
        <LabelTextS>Phone</LabelTextS>
        <PhoneInputWrapperS>
          <PhoneCodeWrapperS>
            <input
              name={hookWorkspaceInfo.inputContactPhoneCode}
              placeholder="Code"
              onChange={hookWorkspaceInfo.onChangeHandler}
              type="text"
              value={hookWorkspaceInfo.phoneCodeValue}
              onClick={() => {
                setIsListVisible(true);
              }}
              readOnly
            />
            {isListVisible && (
              <SearchWrapperS ref={refList}>
                <SearchInputS
                  type="text"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.currentTarget.value);
                  }}
                  placeholder={'Search'}
                  autoFocus
                />
                <SelectCountryListS>
                  {!searchValue
                    ? countryDial.map((itm, i) => (
                        <li
                          key={i + itm.name + itm.code}
                          onClick={() => {
                            hookWorkspaceInfo.changePhoneCodeHandler(
                              `${countryDial[i].flag} ${countryDial[i].dial_code}`
                            );
                            setIsListVisible(false);
                          }}
                        >
                          {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                        </li>
                      ))
                    : countryDial
                        .filter(
                          (itm) =>
                            itm.code.includes(searchValue.toUpperCase()) ||
                            itm.name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                            itm.dial_code.includes(searchValue)
                        )
                        .map((itm) => (
                          <li
                            key={
                              countryDial.findIndex(
                                (country) => itm.name === country.name
                              ) +
                              itm.name +
                              itm.code
                            }
                            onClick={() => {
                              hookWorkspaceInfo.changePhoneCodeHandler(
                                `${itm.flag} ${itm.dial_code}`
                              );
                              setIsListVisible(false);
                            }}
                          >
                            {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                          </li>
                        ))}
                </SelectCountryListS>
              </SearchWrapperS>
            )}
          </PhoneCodeWrapperS>
          <Input
            value={hookWorkspaceInfo.phoneValue}
            onChange={(e) =>
              hookWorkspaceInfo.changePhoneHandler(e.currentTarget.value)
            }
            placeholder="Phone"
            errorText={hookWorkspaceInfo.phoneError}
            name={hookWorkspaceInfo.inputContactPhone}
          />
        </PhoneInputWrapperS>
        <div style={{ margin: '50px 0 50px' }}>
          <ButtonSave
            loading={hookWorkspaceInfo.isLoading}
            onClick={hookWorkspaceInfo.saveBtnHandler}
          />
        </div>
        {'' && (
          <DangerBtnS>
            <ButtonBorderedS type={'button'} isDanger>
              Disable workspace
            </ButtonBorderedS>
          </DangerBtnS>
        )}
      </FormS>
    </>
  );
};
const LabelTextS = styled.span`
  font-size: 1.4rem;
  display: block;
  color: ${COLORS_RGBA.default(0.8)};
  margin-bottom: 5px;
  font-weight: 400;
`;
const SearchInputS = styled(Input)``;
const SelectCountryListS = styled(SelectListS)``;
const SearchWrapperS = styled.div`
  position: absolute;
  max-width: 416px;
  z-index: 100;
`;
const PhoneInputWrapperS = styled.div`
  display: flex;
  input {
    border-radius: 0 8px 8px 0;
  }
  div {
    width: 100%;
  }
`;
const PhoneCodeWrapperS = styled.div`
  max-width: 100px;
  input {
    border-right-width: 0px;
    border-radius: 8px 0px 0px 8px;
  }
  div {
    input {
      border-radius: 8px;
      border-right-width: 1px;
    }
  }
`;
const FormS = styled.form`
  max-width: 416px;
  & > div {
    margin-bottom: 15px;
  }
  button {
    margin-top: 24px;
  }
`;

const WrapperImageTextS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ImageWrapperS = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  svg {
    width: 100%;
    height: auto;
  }
`;

const ImageS = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`;

const TextWrapperS = styled.div`
  padding-left: 20px;
  p {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }
`;

const BtnsWrapperS = styled.div`
  display: flex;
  button {
    margin-right: 20px;
    height: 36px;
    min-height: 36px;
    min-width: 92px;
  }
`;
const DangerBtnS = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: 24px;
  margin-bottom: 24px;

  button {
    max-width: 169px;
    height: 36px;
    line-height: 36px;
    border: solid 1px rgba(42, 47, 42, 0.2);
    &:hover {
      background: transparent;
    }
  }
`;
