import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
interface AreYouShurePopUpProps {
  agreeText?: string;
  disagreeText?: string;
  title?: string;
  yesClick: () => void;
  noClick: () => void;
  width?: number;
  height?: number;
}
export const AreYouShurePopUp: React.FC<AreYouShurePopUpProps> = ({
  agreeText,
  disagreeText,
  title,
  yesClick,
  noClick,
  width,
  height,
}) => {
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ minWidth: `${width}px` }}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalBodyS
          style={{
            minHeight: `${height}px`,
          }}
        >
          <WrapperS>
            <TextS>
              {title ? (
                `${title}`
              ) : (
                <p>
                  By unchecking this box, you are removing the participant
                  masking which means the data will be identified for each
                  participant. <br />
                  That means anyone who access this export will see the data
                  reported next to the names. <br />
                  Are you sure you want to export the data without masking the
                  identities?
                </p>
              )}
            </TextS>
            <ButtonWrapperS>
              <ButtonDefS onClick={yesClick} style={{ minHeight: 30 }}>
                {agreeText ? agreeText : 'Yes'}
              </ButtonDefS>
              <NoButtonS onClick={noClick}>
                {disagreeText ? disagreeText : 'No'}
              </NoButtonS>
            </ButtonWrapperS>
          </WrapperS>
        </Styles.ModalBodyS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const WrapperS = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  font-size: 16px;
`;
const TextS = styled.p`
  margin-bottom: 10px;
  margin-top: 32px;
  p {
    margin: 0;
  }
`;
const ButtonWrapperS = styled.div`
  display: flex;
  align-items: center;
  button {
    height: 30px;
    width: 175px;
  }
`;
const NoButtonS = styled.button`
  background: transparent;
  text-decoration: underline;
  color: darkgray;
  border: 0;
  &:hover {
    color: lightgray;
  }
  &:active {
    color: gray;
  }
`;
