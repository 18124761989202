import { Reducer } from 'redux';
import ActionTypesUser from '../user/types';
import ActionTypes, { TParticipantsState } from './types';

export const initialState: TParticipantsState = {
  data: null,
  fail: [],
  success: [],
  loading: true,
  errors: undefined,
};

const reducer: Reducer<TParticipantsState> = (
  state = initialState,
  action
): TParticipantsState => {

  switch (action.type) {
    case ActionTypes.DELETE_R:
    case ActionTypes.BULK_DELETE_R:
    case ActionTypes.CREATE_R:
    case ActionTypes.MOVE_R:
    case ActionTypes.GET_ALL_R:
    case ActionTypes.GET_PAGE_R:
    case ActionTypes.EDIT_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
        data: state.data ? [...state.data] : [],
      };

    case ActionTypes.DELETE_S:
    case ActionTypes.BULK_DELETE_S:
    case ActionTypes.GET_ALL_S:
    case ActionTypes.MOVE_S:
    case ActionTypes.EDIT_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.GET_PAGE_S:
      return {
        ...state,
        loading: false,
        data: action.payload.is_first_load ? action.payload.participants ? action.payload.participants : []:
            [...state.data, ...action.payload.participants ? action.payload.participants : []],
        has_more: action.payload.has_more,
        next_limit: action.payload.next_limit,
        next_offset: action.payload.next_offset
      };

    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? [...(action.payload.success || []), ...state.data]
          : action.payload.success,
        fail: state.fail
          ? [...(action.payload.fail || []), ...state.fail]
          : action.payload.fail,
        success: state.success
          ? [...(action.payload.success || []), ...state.success]
          : action.payload.success,
      };

    case ActionTypes.CLEAN_UP:
      return {
        data: state.data,
        fail: [],
        success: [],
        loading: state.loading,
        errors: state.errors,
      };
    case ActionTypesUser.LOG_OUT_S:
      return {
        data: null,
        fail: [],
        success: [],
        loading: false,
        errors: undefined,
      };
    case ActionTypes.DELETE_E:
    case ActionTypes.BULK_DELETE_E:
    case ActionTypes.CREATE_E:
    case ActionTypes.MOVE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.GET_ALL_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { reducer as ParticipantsReducer };
