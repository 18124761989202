import React from 'react';
import { useHistory } from 'react-router-dom';
import { NAV_IDS } from '../../utils/routes';
import { NAVS, TNav2 } from './menuConfig';
import * as MS from './MenuStyles';

interface MenuProps {}
interface LinkWrapperProps {
  // children: React.ReactChild;
  exact: boolean;
  href: string;
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({ children, exact, href }) => {
  if (href)
    return (
      <MS.LinkS exact={exact} to={href}>
        {children}
      </MS.LinkS>
    );
  return <MS.ParentS>{children}</MS.ParentS>;
};
interface ItemWithChildrenProps {
  // children: React.ReactChild;
  link: TNav2;
  activeTab: string;
  handler: () => void;
}

const ItemWithChildren: React.FC<ItemWithChildrenProps> = ({
  link,
  handler,
  activeTab,
}) => {
  return (
    <div className={activeTab === link.id ? 'active' : ''}>
      <span onClick={handler}>
        {link.icon ? (
          <MS.IconS>
            <link.icon />
          </MS.IconS>
        ) : null}

        {link.title}
      </span>

      {activeTab === link.id &&
        link.nav?.map((innerLink) =>
          innerLink.outerHref ? (
            <MS.OuterLinkS
              href={innerLink.href}
              target={'_blank'}
              key={innerLink.id as number}
            >
              {innerLink.title}
            </MS.OuterLinkS>
          ) : (
            <LinkWrapper
              key={innerLink.id as number}
              exact
              href={innerLink.href}
            >
              {innerLink.title}
            </LinkWrapper>
          )
        )}
    </div>
  );
};

export const Menu: React.FC<MenuProps> = () => {
  const [activeLink, setActiveLink] = React.useState('');
  const history = useHistory();

  React.useEffect(() => {
    const { pathname } = history.location;

    const checkPath = (path: string) => {
      const hasL = (str: string) => path.includes(str);
      if (hasL(NAV_IDS.ass)) {
        setActiveLink(NAV_IDS.ass);
      } else if (hasL(NAV_IDS.vis)) {
        setActiveLink(NAV_IDS.vis);
      } else if (hasL(NAV_IDS.rep)) {
        setActiveLink(NAV_IDS.rep);
      } else {
        setActiveLink('');
      }
    };

    checkPath(pathname);

    const unListen = history.listen((location) => {
      checkPath(location.pathname);
    });

    return () => unListen();
  }, [history]);

  return (
    <MS.WrapperS>
      {NAVS.map((block) => (
        <MS.BlockS key={block.id as number} id={`${block.id as number}`}>
          {block.subtitle && <MS.SubtitleS>{block.subtitle}</MS.SubtitleS>}
          <nav>
            {block.nav.map((link) => (
              <LinkWrapper
                key={link.id as number}
                exact={link.exact}
                href={link.href}
              >
                {link.href ? (
                  <>
                    {link.icon && (
                      <MS.IconS>
                        <link.icon />
                      </MS.IconS>
                    )}

                    {link.title}
                  </>
                ) : (
                  <ItemWithChildren
                    handler={() => setActiveLink(link.id as string)}
                    activeTab={activeLink}
                    link={link}
                  />
                )}
              </LinkWrapper>
            ))}
          </nav>
        </MS.BlockS>
      ))}
    </MS.WrapperS>
  );
};
