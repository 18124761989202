import { Reducer } from 'redux';
import ActionTypesUser from '../user/types';
import ActionTypes, { TConsultantsState } from './types';

export const initialState: TConsultantsState = {
  data: null,
  loading: true,
  errors: undefined,
};

const reducer: Reducer<TConsultantsState> = (state = initialState, action): TConsultantsState => {
  switch (action.type) {
    case ActionTypes.DELETE_R:
    case ActionTypes.CREATE_R:
    case ActionTypes.GET_BY_TEAM_R:
    case ActionTypes.SET_WORKSPACE_R:
    case ActionTypes.EDIT_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
        data: state.data ? [...state.data] : null,
      };

    case ActionTypes.DELETE_S:
    case ActionTypes.GET_BY_TEAM_S:
    case ActionTypes.EDIT_S:
    case ActionTypes.SET_WORKSPACE_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        data: state.data ? [action.payload, ...state.data] : [action.payload],
      };
    case ActionTypesUser.LOG_OUT_S:
      return {
        data: null,
        loading: true,
        errors: undefined,
      };

    case ActionTypes.DELETE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.GET_BY_TEAM_E:
    case ActionTypes.SET_WORKSPACE_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { reducer as ConsultantsReducer };
