import styled from 'styled-components';
import { CSS_MEDIA } from '../../utils/media';

export const RespTextS = styled.p<{ alignRight?: boolean }>`
  font-size: 16px;
  margin-top: 10px;
  font-weight: normal;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #434352;
  ${CSS_MEDIA.maxSm} {
    font-size: 13px;
  }
`;

export const RespInputSearchWrapperS = styled.div<{ alignRight: boolean }>`
  position: relative;

  input {
    border-radius: 26px;
    /* backdrop-filter: blur(25px); */
    height: 52px;
    border: solid 1px rgba(67, 67, 82, 0.2);
    padding: 0 20px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434352;
    text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
    ${CSS_MEDIA.maxMd} {
      padding: 0 10px;
      font-size: 16px;
    }
    ${CSS_MEDIA.maxSm} {
      font-size: 11px;
    }
    &::placeholder {
      opacity: 0.6;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #434352;

      ${CSS_MEDIA.maxMd} {
        font-size: 16px;
      }
      ${CSS_MEDIA.maxSm} {
        font-size: 11px;
      }
    }

    &:focus {
      border: solid 1px #4caf50;
    }
    &#username {
      ${CSS_MEDIA.maxMd} {
        font-size: 16px;
      }
      ${CSS_MEDIA.maxSm} {
        font-size: 16px;
      }
    }
  }
`;

export const SelectListS = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  z-index: 1;
  li {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434352;
    transition: 0.1s;
    &:hover {
      background: rgba(76, 175, 80, 0.1);
    }
    &:active {
      background-color: #4caf50;
      color: white;
    }

    ${CSS_MEDIA.maxMd} {
      font-size: 14px;
    }
  }
`;

export const RespBtnS = styled.button<{ alignRight: boolean }>`
  min-width: 118px;
  position: relative;
  height: 52px;
  border: 1px solid transparent;
  padding: 15px 20px 14px;
  white-space: nowrap;
  border-radius: 66px;
  background-color: #4caf50;
  color: white;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 32px;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  svg {
    margin: ${({ alignRight }) =>
      alignRight ? '0px 10px 0px 0px' : '0px 0px 0px 10px'};
    g {
      fill: #fff;
    }
  }

  &:hover {
    background-color: #4ac24f;
  }
  &:active {
    background-color: #3c9c40;
  }

  &:disabled {
    background: rgba(42, 47, 42, 0.1);
    cursor: default;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(42, 47, 42, 0.5);

    svg {
      g {
        fill: rgba(42, 47, 42, 0.5);
      }
    }
  }
`;

export const RespSelectWrapperS = styled.div`
  display: flex;
  position: relative;
`;

export const RespSelectS = styled.div`
  border-radius: 26px;
  border: solid 1px #e5e5e5;
  height: 52px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  min-width: 120px;
`;

export const RespSelectItemsWrapperS = styled(SelectListS)``;
