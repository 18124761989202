import React from 'react';
import styled from 'styled-components';
import { Loader } from '../Loader';
import { ButtonDefS } from './styles';

interface ButtonSaveProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  disabled?: boolean;
}

export const ButtonSave: React.FC<ButtonSaveProps> = ({
  onClick,
  title = 'Save',
  type = 'submit',
  loading,
  disabled,
}) => {
  return (
    <BtnS
      onClick={loading ? () => null : onClick}
      isSave
      type={type}
      disabled={disabled}
    >
      {loading ? <Loader size={0.5} /> : null}
      <span style={loading ? { opacity: 0 } : {}}>{title}</span>
    </BtnS>
  );
};

const BtnS = styled(ButtonDefS)`
  width: auto;
  padding: 0 28px;
  position: relative;
`;
