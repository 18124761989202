import React from 'react';
import { TSearchSettingsKind } from '../../../components/Analytics/Collaboration/SearchSettings';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';

export const useGlobalConnectionFilter = () => {
  const [settingsKindFilter, setSettingsKindFilter] =
    React.useState<TSearchSettingsKind>('def');
  const { Analytics } = useSelector((store: AppStore) => store);

  React.useEffect(() => {
    (() => {
      if (
        !Analytics.filters &&
        !Analytics.filters.params &&
        !Analytics.filters.params.groups_ids &&
        !Analytics.filters.params.groups_ids[0]
        // && !Analytics.filters.assessments_ids[0]
      )
        return null;
      const activeCircles = document.querySelectorAll('circle.captured');
      if (!activeCircles[0]) return null;
      const ids: string[] = [];
      activeCircles.forEach((itm) => {
        ids.push(itm.id);
      });

      // ids.forEach((id) => {
      //
      // });
      const lines = document.querySelectorAll(`.userLine`);

      lines.forEach((line: HTMLElement) => {
        // line.setAttribute('style', `stroke: #d3d3d3 !important; `);
        if (settingsKindFilter === 'def') {
          line.removeAttribute('style');
          return null;
        }
        if (
          ids.includes(
            line!.dataset[
              settingsKindFilter === 'source' ? 'sourceId' : 'targetId'
            ]
          )
        ) {
          line.setAttribute(
            'style',
            `stroke: ${
              line.classList.contains('or') ? '#fbaf0d' : '#4caf50'
            } !important; `
          );
        } else {
          line.setAttribute('style', `stroke: #d3d3d3 !important; `);
        }
      });
    })();

    // eslint-disable-next-line
  }, [settingsKindFilter, Analytics.filters]);

  return {
    settingsKindFilter,
    setSettingsKindFilter,
  };
};
