import React from 'react';

export const CollabIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#4CAF50">
          <g>
            <g>
              <path
                d="M23.5 19c1.933 0 3.5 1.567 3.5 3.5S25.433 26 23.5 26h-4c-1.933 0-3.5-1.567-3.5-3.5s1.567-3.5 3.5-3.5h4zm5.5 1c1.657 0 3 1.343 3 3s-1.343 3-3 3h-1v-2h1c.552 0 1-.448 1-1 0-.513-.386-.936-.883-.993L29 22h-1v-2h1zm-5.5 1h-4c-.828 0-1.5.672-1.5 1.5 0 .78.595 1.42 1.356 1.493L19.5 24h4c.828 0 1.5-.672 1.5-1.5 0-.78-.595-1.42-1.356-1.493L23.5 21zm4.5-8c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm-6.5-3c1.933 0 3.5 1.567 3.5 3.5S23.433 17 21.5 17 18 15.433 18 13.5s1.567-3.5 3.5-3.5zm6.5 5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-6.5-3c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"
                transform="translate(-304.000000, -234.000000) translate(288.000000, 196.000000) translate(0.000000, 28.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
