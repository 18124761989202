import React from 'react';
import { ViewIcon } from '../../svgs/ViewIcon';
import { WrapperItemSelectS } from './styles';

interface ViewSelectItemProps {
  title?: string;
}

export const ViewSelectItem: React.FC<ViewSelectItemProps> = ({
  title = 'View',
}) => {
  return (
    <WrapperItemSelectS>
      <ViewIcon />
      {title}
    </WrapperItemSelectS>
  );
};
