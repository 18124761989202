import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TMessageTemplate } from './types';

function* getAllMessageTemplates(
  action: ReturnType<typeof Actions.GetAllMessageTemplates.request>
): Generator {
  let success = true;
  let resp: TMessageTemplate[] = null;
  const { callBack, workspace_name } =
    action.payload as Actions.TypeGetAllMessageTemplatesR;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.messageTemplates.getAll,
    })) as TMessageTemplate[];
    resp[0].name = resp[0].name + ' ' + workspace_name;
    resp[0].email_subject = resp[0].email_subject + ' ' + workspace_name;
    yield put(Actions.GetAllMessageTemplates.success(resp));
    yield put(Actions.setTemplate(resp[0]));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllMessageTemplates.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}

function* createMessageTemplate(
  action: ReturnType<typeof Actions.CreateMessageTemplate.request>
): Generator {
  let success = true;
  let resp: TMessageTemplate = null;
  const { callBack, data } =
    action.payload as Actions.TypeCreateMessageTemplateR;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.messageTemplates.path,
      data,
    })) as TMessageTemplate;
    yield put(Actions.CreateMessageTemplate.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateMessageTemplate.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}

function* deleteMessageTemplate(
  action: ReturnType<typeof Actions.DeleteMessageTemplate.request>
): Generator {
  let success = true;
  const { messageTemplateId, callBack } =
    action.payload as Actions.TypeDeleteMessageTemplateR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.messageTemplates.path + '/' + messageTemplateId,
    });
  } catch (e) {
    success = false;
    yield put(Actions.DeleteMessageTemplate.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_TEMPLATES_R, getAllMessageTemplates);
  yield takeEvery(ActionTypes.DELETE_TEMPLATE_R, deleteMessageTemplate);
  yield takeEvery(ActionTypes.CREATE_TEMPLATE_R, createMessageTemplate);
}

export default function* messageTemplatesSaga() {
  yield all([fork(watchFetchRequest)]);
}
