import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TCat } from './types';

const GetActions = new Actions('GET_ALL', ActionTypes);

export interface TypeGetCatR extends TDefRequest {
  teamId: string;
  type?: 'custom' | 'default';
}

export const GetCats = {
  request: (payload: TypeGetCatR) => GetActions.request(payload),
  success: (payload: TCat[]) => GetActions.success(payload),
  error: (message: string) => GetActions.error(message),
};

const CreateActions = new Actions('CREATE', ActionTypes);

export interface TypeCreateCatR extends TDefRequest {
  data: {
    team_id: string;
    name: string;
    description: string;
  };
}

export const CreateCat = {
  request: (payload: TypeCreateCatR) => CreateActions.request(payload),
  success: (payload: TCat) => CreateActions.success(payload),
  error: (message: string) => CreateActions.error(message),
};

const EditActions = new Actions('EDIT', ActionTypes);

export interface TypeEditCatR extends TDefRequest {
  data: {
    name: string;
    description: string;
  };
  catId: string;
}

export const EditCat = {
  request: (payload: TypeEditCatR) => EditActions.request(payload),
  success: (payload: TCat[]) => EditActions.success(payload),
  error: (message: string) => EditActions.error(message),
};

const DeleteActions = new Actions('DELETE', ActionTypes);

export interface TypeDeleteCatR extends TDefRequest {
  catId: string;
}

export const DeleteCat = {
  request: (payload: TypeDeleteCatR) => DeleteActions.request(payload),
  success: (payload: TCat[]) => DeleteActions.success(payload),
  error: (message: string) => DeleteActions.error(message),
};
