import React from 'react';
import { SendIcon } from '../../svgs/SendIcon';
import { WrapperItemSelectS } from './styles';
import styled from "styled-components";

interface DeleteSelectItemProps {
  title?: string;
}

export const SendSelectItem: React.FC<DeleteSelectItemProps> = ({ title = 'Send' }) => {
  return (
    <WrapperS>
      <SendIcon />
      {title}
    </WrapperS>
  );
};


const WrapperS = styled(WrapperItemSelectS)`
  svg {
    width: 20px;
    height: 20px;
  }
`;
