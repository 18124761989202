import { TQuestionType } from '../assessments/types';

export interface TAssessmentCardsState {
  readonly loading: boolean;
  readonly data: TAssessmentCards[] | null;
  readonly errors?: string | undefined;
}
export enum ECardStatus {
  used = 'used',
  used_and_displayed = 'used_and_displayed',
  unused = 'unused',
}
export type TCardStatus =
  | ECardStatus.used_and_displayed
  | ECardStatus.unused
  | ECardStatus.used;

export interface TAssessmentCards {
  id: string;
  position: number;
  assessment_info: {
    id: string;
    name: string;
    description: string;
    version: string;
    answer_type: TQuestionType;
  };
  last_cycle_info: {
    status: TCardStatus;
    package_name: string;
    link_create_at: string;
    average_value: number;
    difference: number;
  };
}
enum ActionTypes {
  GET_ALL_CARDS_R = '@@assessmentCards/GET_ALL_CARDS_R',
  GET_ALL_CARDS_S = '@@assessmentCards/GET_ALL_CARDS_S',
  GET_ALL_CARDS_E = '@@assessmentCards/GET_ALL_CARDS_E',

  CREATE_CARDS_R = '@@assessmentCards/CREATE_CARDS_R',
  CREATE_CARDS_S = '@@assessmentCards/CREATE_CARDS_S',
  CREATE_CARDS_E = '@@assessmentCards/CREATE_CARDS_E',

  DELETE_CARDS_R = '@@assessmentCards/DELETE_CARDS_R',
  DELETE_CARDS_S = '@@assessmentCards/DELETE_CARDS_S',
  DELETE_CARDS_E = '@@assessmentCards/DELETE_CARDS_E',
}

export default ActionTypes;
