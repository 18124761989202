import React from 'react';
import { ButtonBorderedS } from '../ui/buttons/styles';

interface AddManuallyBtnProps {
  onClick: () => void;
}

export const AddManuallyBtn: React.FC<AddManuallyBtnProps> = ({ onClick }) => {
  return <ButtonBorderedS onClick={onClick}>Add participants manually</ButtonBorderedS>;
};
