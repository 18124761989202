export const deepClone = (data: any) => {
  if (typeof data !== 'object' || data === null) return data;

  if (Array.isArray(data)) {
    const newData: any[] = [];

    data.forEach((d, i) => {
      newData.push(d);
      if (typeof d === 'object') {
        newData[i] = deepClone(d);
      }
    });

    return newData;
  }

  const cloneElement: any = {};

  for (const key in data) {
    if (typeof data[key] === 'object') {
      cloneElement[key] = deepClone(data[key]);
    } else {
      cloneElement[key] = data[key];
    }
  }

  return cloneElement;
};
