import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import * as Actions from './actions';
import ActionTypes, { TAnalyticsRow, TChart } from './types';

function* getCharts(
  action: ReturnType<typeof Actions.GetCharts.request>
): Generator {
  let success = true;
  const { data, callBack } = action.payload as Actions.TypeGetChartsR;
  const { page_id, workspace_id } = data;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.visualizations.genGAll(workspace_id, page_id),
      data,
    })) as TAnalyticsRow[];
    yield put(Actions.GetCharts.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.GetCharts.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getChartsBlock(
  action: ReturnType<typeof Actions.GetChartsBlock.request>
): Generator {
  let success = true;
  let dataResp: TAnalyticsRow | null = null;
  const { data, block_id, workspace_id, callBack } =
    action.payload as Actions.TypeGetChartsBlockR;
  try {
    dataResp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.visualizations.getOneByParams(
        workspace_id,
        block_id
      ),
      data,
    })) as TAnalyticsRow;
    // yield put(Actions.GetChartsBlock.success(resp));
  } catch (e) {
    success = false;
    dataResp = null;
    yield put(Actions.GetChartsBlock.error(e));
  } finally {
    if (callBack) yield call(callBack, success, dataResp);
  }
}

function* createCustomGlobalChart(
  action: ReturnType<typeof Actions.CreateCustomGlobalChart.request>
): Generator {
  let success = true;
  const { data, callBack, pageId, workspaceId } =
    action.payload as Actions.TypeCreateCustomGlobalChartR;
  let respData: TAnalyticsRow = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.visualizations.createCustom(workspaceId, pageId),
      data,
    })) as TAnalyticsRow;
    respData = resp
    yield put(Actions.CreateCustomGlobalChart.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateCustomGlobalChart.error(e));
  } finally {
    if (callBack) yield call(callBack, success, respData);
  }
}

function* createCustomChart(
  action: ReturnType<typeof Actions.CreateCustomChart.request>
): Generator {
  let success = true;
  let dataResp: TChart | null = null;
  const { data, callBack, visualisationId } =
    action.payload as Actions.TypeCreateCustomChartR;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.chart.createCustom(visualisationId),
      data,
    })) as TChart;
    dataResp = resp;
    // yield put(Actions.CreateCustomChart.request(resp));
  } catch (e) {
    success = false;
    dataResp = null;
    yield put(Actions.CreateCustomChart.error(e));
  } finally {
    if (callBack) yield call(callBack, success, dataResp);
  }
}

function* getCurrentChart(
  action: ReturnType<typeof Actions.GetCurrentChart.request>
): Generator {
  let success = true;
  let dataResp: TChart | null = null;
  const { data, callBack, uri } = action.payload as Actions.TGetCurrentChart;
  try {
    dataResp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.chart.getCurrent(
        uri.workspaceId,
        uri.chartId,
        uri.type
      ),
      data: data,
    })) as TChart;
    // yield put(Actions.CreateCustomChart.request(resp));
  } catch (e) {
    success = false;
    dataResp = null;
    yield put(Actions.GetCurrentChart.error(e));
  } finally {
    if (callBack) yield call(callBack, success, dataResp);
  }
}
function* deleteGraph(
  action: ReturnType<typeof Actions.DeleteGraph.request>
): Generator {
  let success = true;
  const { id, callBack } = action.payload as Actions.TypeDeleteGraphR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.visualizations.path + '/' + id,
    });
  } catch (e) {
    success = false;
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* editGraph(
  action: ReturnType<typeof Actions.EditOneGraph.request>
): Generator {
  let success = true;
  const { id, name, callBack } = action.payload as Actions.TypeEditGraphR;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.visualizations.path + '/' + id,
      data: {
        name,
      },
    });
  } catch (e) {
    success = false;
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* deleteCurrentChart(
  action: ReturnType<typeof Actions.DeleteChartCurrent.request>
): Generator {
  let success = true;
  const { id, callBack } = action.payload as Actions.TypeDeleteChartCurrentR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.chart.path + '/' + id,
    });
  } catch (e) {
    success = false;
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CHARTS_R, getCharts);
  yield takeEvery(ActionTypes.GET_CHARTS_BLOCK_R, getChartsBlock);
  yield takeEvery(ActionTypes.DELETE_GRAPH_R, deleteGraph);
  yield takeEvery(ActionTypes.DELETE_CHART_CURRENT_R, deleteCurrentChart);
  yield takeEvery(ActionTypes.EDIT_GRAPH_R, editGraph);
  yield takeEvery(
    ActionTypes.CREATE_CUSTOM_GLOBAL_GRAPH_R,
    createCustomGlobalChart
  );
  yield takeEvery(ActionTypes.CREATE_CUSTOM_GRAPH_R, createCustomChart);
  yield takeEvery(ActionTypes.GET_CHART_CURRENT_R, getCurrentChart);
}

export default function* analyticsSaga() {
  yield all([fork(watchFetchRequest)]);
}
