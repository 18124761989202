import React from 'react';
import styled from 'styled-components';
import { COLORS_RGBA } from '../../../utils/colors';
import * as Styles from './inputStyles';
import { DefInputProps } from './types';

interface TextAreaProps extends DefInputProps {
  maxLength?: number;
}

export const TextArea: React.FC<TextAreaProps> = ({
  label = '',
  placeholder = '',
  classInput = '',
  classWrapper = '',
  errorText,
  name,
  stylesWrapper,
  stylesInput,
  onChange,

  autoFocus,
  value,
  maxLength,
}) => {
  const [focused, setFocused] = React.useState(false);
  return (
    <Styles.WrapperS style={stylesWrapper} error={!!errorText}>
      <Styles.LabelS className={classWrapper}>
        {label ? <span>{label}</span> : null}
        <Styles.TextAreaS
          autoFocus={autoFocus}
          onChange={(e) => {
            if (
              (maxLength && maxLength >= e.currentTarget.value.length) ||
              !maxLength
            )
              onChange(e);
          }}
          // onChange={(e) => console.log(e)}
          // style={stylesInput}
          className={classInput}
          name={name}
          value={value}
          placeholder={placeholder}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          // defaultValue={value}
        />
        {maxLength && (
          <CounterS isGreen={focused}>
            {value.length}/{maxLength}
          </CounterS>
        )}
      </Styles.LabelS>
      {errorText ? <Styles.ErrorTextS>{errorText}</Styles.ErrorTextS> : null}
    </Styles.WrapperS>
  );
};
const CounterS = styled.p<{ isGreen?: boolean }>`
  position: absolute;
  color: ${({ isGreen }) => (isGreen ? COLORS_RGBA.accent() : '#2a2f2a')};
  opacity: ${({ isGreen }) => (isGreen ? '1' : '0.5')};
  margin: 0;
  padding: 0;
  bottom: 10px;
  right: 20px;
  font-size: 12px;
`;
