import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PackageGenLink } from '../components';
import { PackageRow } from '../components/PackageSchedule/PackageRow';
import { Loader } from '../components/ui';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { GetAllPackages } from '../store/packages/actions';
import { TPackage, TDelayedMessage } from '../store/packages/types';
import { AnimatedClasses } from '../utils/animatedStyles';
import { HEADER_HEIGHT, SIDE_BAR_WIDTH } from '../utils/config';
import { SendAssPackage } from '../components/modals/SendAssPackage';
import { SendTestAssPackage } from '../components/modals/SendTestAssPackage';
import { SendIp } from '../components/modals/SendIp';
import { ButtonDefS } from '../components/ui/buttons/styles';
import { QuestionIcon } from '../components/svgs';
import { ViewSentAssPackage } from '../components/modals/ViewSentAssPackage';
import { getGroupsR } from '../store/groups/actions';

interface PackageScheduleProps {}

export const PackageSchedule: React.FC<PackageScheduleProps> = () => {
  const { Packages, Workspaces } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const [linkPack, setLinkPack] = React.useState<TPackage | null>(null);
  const [sendPack, setSendPack] = React.useState<TPackage | null>(null);

  const [testMessage, setTestMessage] = React.useState<TDelayedMessage | null>(
    null
  );
  const [testPack, setTestPack] = React.useState<TPackage | null>(null);

  const [editMessage, setEditMessage] = React.useState<TDelayedMessage | null>(
    null
  );
  const [editPack, setEditPack] = React.useState<TPackage | null>(null);

  const [showInfo, setShowInfo] = React.useState(false);

  const [viewMessage, setViewMessage] = React.useState<TDelayedMessage | null>(
    null
  );

  // const [tabs] = React.useState<{ title: string; id: string }[]>([
  //   { title: 'Packages', id: '1' },
  //   { title: '-------', id: '2' },
  //   { title: 'Message Templates', id: '3' },
  // ]);
  // const [activeTab, setActiveTab] = React.useState('1');

  React.useEffect(() => {
    if (Workspaces.current) {
      dispatch(GetAllPackages.request({ workspaceId: Workspaces.current.id }));
      dispatch(getGroupsR(Workspaces.current.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Workspaces.current]);

  const renderData = React.useMemo(() => {
    if (!Packages.data && Packages.loading)
      return (
        // <TrS>
        //   <TdS style={{ borderBottom: 0, padding: '40px 0' }}>
        <Loader isGreen />
        //   {/* </TdS>
        // </TrS> */
      );
    if (!Packages.data)
      return (
        // <TrS>
        // <TdS style={{ borderBottom: 0 }}>No Packages</TdS>
        // /* </TrS> */
        <></>
      );
    const packages = Packages.data.filter((pack) => pack.is_scheduled);
    if (sendPack) {
      setSendPack(packages.find((pack) => pack.id === sendPack.id));
    }
    if (!packages[0])
      return (
        // <TrS>
        //   <TdS style={{ borderBottom: 0 }}>No Scheduled Packages</TdS>
        // </TrS>
        <>No Scheduled Packages</>
      );
    return packages.map((pack) => {
      if (!pack.is_scheduled) return null;
      return (
        <PackageRow
          sendHandler={() => setSendPack(pack)}
          packages={Packages.data!}
          key={pack.id}
          pack={pack}
          copyLinkHandler={() => setLinkPack(pack)}
          sendTestHandler={(message) => {
            setTestMessage(message);
            setTestPack(pack);
          }}
          editScheduleHandler={(message) => {
            setEditMessage(message);
            setEditPack(pack);
          }}
          viewSentHandler={(message) => {
            setViewMessage(message);
          }}
        />
      );
    });
    // eslint-disable-next-line
  }, [Packages]);

  // const renderBody = () => {
  //   switch (activeTab) {
  //     case '1':
  //       return (
  //         <>
  //           <TableS cellSpacing={0}>
  //             <THeadS>
  //               <TrS>
  //                 <ThS>Name</ThS>
  //                 <ThS />
  //                 <ThS />
  //               </TrS>
  //             </THeadS>
  //             <TBodyS>{tableData}</TBodyS>
  //           </TableS>
  //         </>
  //       );
  //     case '2':
  //       return <></>;
  //     case '3':
  //       return <></>;
  //   }
  // };

  return (
    <>
      <WrapperS className={AnimatedClasses.fadeIn}>
        {/* <PageTitleWrapperS> */}
        <PageHeaderWrapperS>
          <h1>Package Schedule</h1>
          <InfoButtonWrapperS>
            <ButtonDefS
              onClick={() => {
                setShowInfo(true);
              }}
            >
              <QuestionIcon /> IP Address Info
            </ButtonDefS>
          </InfoButtonWrapperS>
        </PageHeaderWrapperS>
        {/* </PageTitleWrapperS> */}
        {/* <TabsWrapperS>
          <Tabs
            data={tabs}
            activeTab={activeTab}
            onClick={setActiveTab}
            isUnderline
            isAlignLeft
          />
        </TabsWrapperS> */}
        {/* {renderBody()} */}
        {/* <TableS cellSpacing={0}>
          <THeadS>
            <TrS>
              <ThS>Name</ThS>
              <ThS />
              <ThS />
            </TrS>
          </THeadS>
          <TBodyS>{tableData}</TBodyS>
        </TableS> */}
        <TitleS>Name</TitleS>
        {renderData}
      </WrapperS>
      {linkPack ? (
        <PackageGenLink
          pack={linkPack}
          closeHandler={() => setLinkPack(null)}
        />
      ) : null}
      {sendPack && (
        <SendAssPackage
          pack={sendPack}
          closeHandler={() => setSendPack(null)}
        />
      )}
      {testMessage && testPack && (
        <SendTestAssPackage
          message={testMessage}
          pack={testPack}
          closeHandler={() => {
            setTestMessage(null);
            setTestPack(null);
          }}
        />
      )}
      {editMessage && editPack && (
        <SendAssPackage
          pack={editPack}
          closeHandler={() => {
            setEditMessage(null);
            setEditPack(null);
          }}
          message={editMessage}
        />
      )}
      {viewMessage && (
        <ViewSentAssPackage
          closeHandler={() => {
            setViewMessage(null);
          }}
          message={viewMessage}
        />
      )}
      {showInfo && (
        <SendIp
          closeModal={() => {
            setShowInfo(false);
          }}
        />
      )}
    </>
  );
};

const WrapperS = styled(MainWrapperS)`
  padding: calc(${HEADER_HEIGHT} + 26px) 24px calc(${HEADER_HEIGHT} + 26px)
    calc(${SIDE_BAR_WIDTH} + 24px);
`;
const TitleS = styled.h2`
  text-align: left;
  padding: 16px 0;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
`;
const PageHeaderWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin: 0;
  }
`;
const InfoButtonWrapperS = styled.div`
  max-width: 200px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

// const TableS = styled.table`
//   width: 100%;
//   th,
//   td {
//     text-align: left;
//     padding: 16px 0;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//     position: relative;

//     &:first-child {
//       width: 100%;
//     }
//     .btn-more {
//       transform: rotate(90deg);
//     }
//   }
// `;
// const TrS = styled.tr``;
// const TdS = styled.td``;
// const ThS = styled.th``;
// const TBodyS = styled.tbody``;
// const THeadS = styled.thead``;
// const TabsWrapperS = styled.div`
//   font-size: 18px;
//   font-weight: 600;
// `;
