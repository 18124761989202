import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { AppStore } from '../../store/applicationState';
import {
  CreatePackage,
  EditPackage,
  setPackageEditMode,
} from '../../store/packages/actions';
import { TPackage } from '../../store/packages/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { deepClone } from '../../utils/deepClone';
import { TEXT } from '../../utils/textConst';
import { Alert, ButtonSave, DefaultSelect, Input, TextArea } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import { TLang } from '../../types/lang';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { ONE_LANG } from '../../utils/consts';
import { getLangName } from '../Responding/assLang';

interface NewPackageProps {
  editMode?: boolean;
  closeHandler: () => void;
  pack: TPackage | null;
}

const NAME = 'name';
const DESC = 'description';

export const NewPackage: React.FC<NewPackageProps> = ({
  closeHandler,
  editMode,
  pack,
}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const { Workspaces, Packages } = useSelector((store: AppStore) => store);
  // const [isEs_ES, setIsEs_ES] = React.useState(true);
  const [activeLangIds, setActiveLangIds] = React.useState<SelectItemType[]>([
    {
      title: 'Spanish',
      value: 'es_ES',
      id: 'es_ES',
    },
    { title: 'French', value: 'fr_FR', id: 'fr_FR' },
    { title: 'Hebrew', value: 'he_IL', id: 'he_IL' },
  ]);
  const dispatch = useDispatch();
  const selectNumberType: SelectItemType[] = [
    ONE_LANG,
    {
      title: 'Spanish',
      value: 'es_ES',
      id: 'es_ES',
    },
    { title: 'French', value: 'fr_FR', id: 'fr_FR' },
    { title: 'Hebrew', value: 'he_IL', id: 'he_IL' },
  ];
  const { form, onChangeHandler, updateForm } = useForm({
    [NAME]: {
      value: pack ? pack.name : '',
    },
    [DESC]: {
      value: pack ? pack.description : '',
    },
  });

  React.useEffect(() => {
    if (pack) {
      //setIsEs_ES(pack.languages_tags.includes('es_ES'));
      const currrentActiveIds: SelectItemType[] = [];
      pack.languages_tags.forEach((itm) => {
        if (itm !== 'en_US') {
          currrentActiveIds.push({
            id: itm,
            title: getLangName(itm),
            value: itm,
          });
        }
      });
      setActiveLangIds(currrentActiveIds);
    }
  }, [pack]);

  const closeModalHandler = () => {
    if (refInnWrapper.current)
      closeModal(refInnWrapper.current, () => {
        closeHandler();
        dispatch(setPackageEditMode(null));
      });
  };

  const assSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ONE_LANG.value &&
      activeLangIds[0] &&
      activeLangIds[0].value === ONE_LANG.value
    ) {
      return null;
    }
    const newData = deepClone(activeLangIds) as SelectItemType[];
    const ass_ids: string[] = [];
    if (data.value === ONE_LANG.value) {
      setActiveLangIds([ONE_LANG]);
      return null;
    }
    let hasVal = false;
    if (newData[0] && newData[0].value === ONE_LANG.value) {
      newData.splice(0, 1);
    }
    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ONE_LANG.value) {
        ass_ids.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ONE_LANG);
    }
    setActiveLangIds(newData);
    return null;
  };
  const submitHandler = () => {
    const name = form[NAME].value,
      description = form[DESC].value;

    if (!Workspaces.current || !Workspaces.current.id)
      return dispatch(
        CreatePackage.error('Workspace is undefined, ' + TEXT.tryLater)
      );

    if (!name)
      return updateForm(NAME, {
        value: '',
        errorText: TEXT.isRequired,
      });

    if (pack) {
      const callBack = (success: boolean) => {
        if (!success) return null;
        if (!Packages.data) return null;
        const newPackages = deepClone(Packages.data) as TPackage[];
        const newPack = newPackages.find((p) => p.id === pack.id);
        if (!newPack) {
          console.warn('Could not find package');
          return null;
        }
        newPack.name = name;
        newPack.description = description;
        newPack.languages_tags = ['en_US'];
        if (activeLangIds[0] && activeLangIds[0].value !== ONE_LANG.value) {
          activeLangIds.forEach((itm) => {
            newPack.languages_tags.push(itm.value as TLang);
          });
        }
        dispatch(EditPackage.success(newPackages));
        closeModalHandler();
      };

      let languages_tags: TLang[] = ['en_US'];
      if (activeLangIds[0] && activeLangIds[0].value !== ONE_LANG.value) {
        activeLangIds.forEach((itm) => {
          languages_tags.push(itm.value as TLang);
        });
      }

      return dispatch(
        EditPackage.request({
          id: pack.id,
          data: {
            description,
            name,
            is_scheduled: pack.is_scheduled,
            logo_team: false,
            logo_workspace: false,
            languages_tags,
            welcome_screen_description: { en_US: '' },
            welcome_screen_title: { en_US: '' },
          },
          callBack,
        })
      );
    }

    const callBack = (success: boolean) => {
      if (success) closeModalHandler();
    };

    let languages_tags: TLang[] = ['en_US'];

    if (activeLangIds[0] && activeLangIds[0].value !== ONE_LANG.value) {
      activeLangIds.forEach((itm) => {
        languages_tags.push(itm.value as TLang);
      });
    }

    return dispatch(
      CreatePackage.request({
        data: {
          description,
          name,
          workspace_id: Workspaces.current.id,
          languages_tags,
        },
        callBack,
      })
    );
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '416px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>
            {pack ? 'Edit Package' : 'New Package'}
          </Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <form
            onKeyDown={(e: {
              keyCode: number;
              preventDefault: () => void;
              shiftKey: any;
            }) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                submitHandler();
              }
            }}
          >
            <Input
              onChange={onChangeHandler}
              value={form[NAME].value}
              name={NAME}
              errorText={form[NAME].errorText}
              label="Package Name"
              placeholder="Package Name"
              autoFocus
            />
            <TextArea
              stylesWrapper={{ marginTop: '20px' }}
              onChange={onChangeHandler}
              value={form[DESC].value}
              name={DESC}
              errorText={form[DESC].errorText}
              label="Description"
              placeholder="Description"
            />

            <Styles.LangWrapperS>
              <span>Option to take this package in:</span>
              <Styles.SelectWrapperS>
                <DefaultSelect
                  data={selectNumberType}
                  selected={selectNumberType[0]}
                  onChange={assSelectHandler}
                  isMultiple
                  activeSelects={activeLangIds}
                  openToTop
                />
              </Styles.SelectWrapperS>
              {/* <CheckBox
                onChange={() => setIsEs_ES(!isEs_ES)}
                isCheck={isEs_ES}
              /> */}
            </Styles.LangWrapperS>
          </form>
        </Styles.ModalBodyS>
        {Packages.errors ? (
          <div style={{ padding: '0 20px 20px' }}>
            <Alert text={Packages.errors} />
          </div>
        ) : null}

        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonSave onClick={submitHandler} loading={Packages.loading} />
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
