import styled, { createGlobalStyle } from 'styled-components';
import { COLORS, COLORS_RGBA } from './utils/colors';
import { FONT_FAMILY, HEADER_HEIGHT, SIDE_BAR_WIDTH } from './utils/config';
import {
  EColFilterStatus,
  EFriendScoreStatus,
} from './store/collaborations/types';
import { MUTUAL } from './utils/consts';

export const ContainerFluidS = styled.div`
  padding: 0 20px;
  width: 100%;
`;

export const MainWrapperS = styled.main`
  padding: calc(${HEADER_HEIGHT} + 26px) 24px 0 calc(${SIDE_BAR_WIDTH} + 24px);
`;

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
  }

  * {
    box-sizing: border-box;
    font-family: ${FONT_FAMILY};
  }

  body {
    color: ${COLORS.default};
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
  }

  h1 {
    font-size: 3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  input {
    height: 48px;

  }

  textarea {
    min-height: 135px;

  }

  textarea,
  input {
    outline: 0;
    caret-color: ${COLORS.accent};
    font-family: ${FONT_FAMILY};
    padding: 14px 16px 12px 16px;
    border-radius: 6px;
    border: solid 1px ${COLORS_RGBA.default(0.15)};
    width: 100%;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.default};
    transition: 0.2s;

    &:hover {
      border-color: ${COLORS.accentHover}
    }

    &:focus {
      border-color: ${COLORS.accent}
    }
  }

  button {
    font-size: 1.6rem;
    cursor: pointer;
    outline: 0;
  }


  a {
    color: ${COLORS.accent};
    text-decoration: none;

    &:hover {
      color: ${COLORS.accentHover};
    }

    &:active {
      color: ${COLORS.accentActive};
    }
  }

  button {
    color: ${COLORS.default};
  }

  h1 {
    white-space: nowrap;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #171717;
  }

  .animate__zoomIn {
    animation-duration: 0.1s !important;
  }
  .animate__slideOutLeft {
    animation-duration: 0.1s !important;
  }
  
  .react-time-picker__wrapper {
    outline: 0;
    caret-color: #4caf50;
    font-family: 'Inter', sans-serif;
    padding: 14px 16px 12px 16px;
    border-radius: 6px;
    border: solid 1px rgba(42, 47, 42, 0.15);
    width: 100%;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #2a2f2a;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 6px;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-size: 1.3rem;
  }

  .react-datepicker {
    font-size: 1.2rem;
  }

  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    margin: 0.3rem;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: ${COLORS.accent} !important;
  }

  input.react-datepicker-time__input{
    font-size:1.4rem;
    padding: 5px 10px;
    height:auto !important;
  }

  .react-datepicker__triangle::before, 
  .react-datepicker__triangle::after{
    left: 0px !important;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--keyboard-selected {
    /* background: transparent;
    color: black; */
  }

  .hasBottomTriangle,
  .hasTopTriangle {
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;

      position: absolute;
      right: 50%;
      transform: translateX(50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .hasBottomTriangle {
    &:after {
      top: 100%;
      border-top: 5px solid #fff;
    }
  }

  .hasTopTriangle {
    &:after {
      bottom: 100%;
      border-bottom: 5px solid #fff;
    }
  }


  .link {
    fill: none;
    stroke: #666;
    stroke-width: 2px;
  }

  #licensing {
    fill: green;
  }

  .link.licensing {
    stroke: green;
  }

  .link.resolved {
    stroke-dasharray: 0, 2 1;
  }

  circle {
    fill: #ccc;
    stroke: #333;
    stroke-width: 1px;
  }

  text {
    font: 10px sans-serif;
    pointer-events: none;
    text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;
  }


  path.${EColFilterStatus.inactive} {
    fill: #fbaf0d !important;
  }

  path.userLine.${EColFilterStatus.inactive} {
    stroke: #d3d3d3 !important;
    fill: none !important;
  }

  path.${EColFilterStatus.inactive},
  circle.${EColFilterStatus.inactive} {
    stroke: #d3d3d3 !important;
  }
  

  circle.${EColFilterStatus.uncaptured} {
    stroke: #919191 !important;
    fill: #d3d3d3 !important;
  }



  circle.${EColFilterStatus.captured} {
    stroke: ${COLORS.accent} !important;
    fill: ${COLORS.accent} !important;
  }

  path.${EColFilterStatus.matched} {
    stroke: ${MUTUAL.isActive.color} !important;
    /* opacity:0.5; */
  }
  
  circle.${EColFilterStatus.matched} {
    stroke: ${MUTUAL.isActive.color} !important;
    /* stroke: #ff9d02 !important; */
    stroke-width: 3px;
    fill: ${MUTUAL.isActive.color} !important;
    /* fill: #7542c1 !important; */
    display: block;
    /* opacity:0.9; */
  }

  circle.${EFriendScoreStatus.times_listed_0}{
    filter: drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
      0
    )}) drop-shadow( 0 -3px 4px ${COLORS_RGBA.magenta(
  0
)}) drop-shadow( 3px 0 4px  ${COLORS_RGBA.magenta(
  0
)}) drop-shadow( -3px 0 4px ${COLORS_RGBA.magenta(0)}); 
  }

  circle.${EFriendScoreStatus.times_listed_1}{
    filter: drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
      0.05
    )}) drop-shadow( 0 -3px 4px ${COLORS_RGBA.magenta(
  0.05
)}) drop-shadow( 3px 0 4px  ${COLORS_RGBA.magenta(
  0.05
)}) drop-shadow( -3px 0 4px ${COLORS_RGBA.magenta(0.05)});
  }

  circle.${EFriendScoreStatus.times_listed_2}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.11
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.11
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.11
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.11)}); 
  }

  circle.${EFriendScoreStatus.times_listed_3}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.16
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.16
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.16
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.16)}); 
  }

  circle.${EFriendScoreStatus.times_listed_4}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.22
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.22
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.22
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.22)}); 
  }

  circle.${EFriendScoreStatus.times_listed_5}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.27
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.27
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.27
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.27)}); 
  }

  circle.${EFriendScoreStatus.times_listed_6}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.33
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.33
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.33
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.33)}); 
  }

  circle.${EFriendScoreStatus.times_listed_7}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.38
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.38
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.38
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.38)}); 
  }

  circle.${EFriendScoreStatus.times_listed_8}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.44
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.44
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.44
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.44)}); 
  }

  circle.${EFriendScoreStatus.times_listed_9}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.5
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.5
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.5
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.5)}); 
  }

  circle.${EFriendScoreStatus.times_listed_10}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.55
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.55
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.55
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.55)}); 
  }

  circle.${EFriendScoreStatus.times_listed_11}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.6
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.6
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.6
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.6)}); 
  }

  circle.${EFriendScoreStatus.times_listed_12}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.66
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.66
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.66
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.66)}); 
  }

  circle.${EFriendScoreStatus.times_listed_13}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.72
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.72
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.72
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.72)}); 
  }

  circle.${EFriendScoreStatus.times_listed_14}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.77
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.77
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.77
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.77)}); 
  }

  circle.${EFriendScoreStatus.times_listed_15}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.82
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.82
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.82
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.82)}); 
  }

  circle.${EFriendScoreStatus.times_listed_16}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.88
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.88
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.88
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.88)}); 
  }

  circle.${EFriendScoreStatus.times_listed_17}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      0.93
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  0.93
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  0.93
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(0.93)}); 
  }

  circle.${EFriendScoreStatus.times_listed_18_or_more}{
    filter: drop-shadow(3px 0 4px ${COLORS_RGBA.magenta(
      1
    )}) drop-shadow(-3px 0 4px  ${COLORS_RGBA.magenta(
  1
)}) drop-shadow( 0 3px 4px ${COLORS_RGBA.magenta(
  1
)}) drop-shadow( 0 -3px 4px  ${COLORS_RGBA.magenta(1)}); 
  }
  
  text.${EColFilterStatus.captured} {
    fill: white !important;
    text-shadow: none !important;
  }


  
  path.${EColFilterStatus.captured}.unmutual {
    fill: ${COLORS.accent} !important;
  }





  path.${EColFilterStatus.uncaptured} {
    stroke: #d3d3d3 !important;
  }  
  
  path.userLine.${EColFilterStatus.uncaptured} {
    stroke: #d3d3d3 !important;
  }



  path.${EColFilterStatus.captured} {
    stroke: ${COLORS.accent} !important;
  }



  path.userLine.withFilters.${EColFilterStatus.uncaptured}{
    stroke: #d3d3d3 !important;
  }
  
  path.userLine.withFilters.greenImp {
    stroke: ${COLORS.accent} !important;
  }
  path.userLine.or,
  path.userLine.withFilters.or {
    stroke: #fbaf0d !important;
  }
  
  .rdw-editor-main {
    outline: 0;
    caret-color: ${COLORS.accent};
    font-family: ${FONT_FAMILY};
    padding: 0 16px;
    border-radius: 6px;
    border: solid 1px ${COLORS_RGBA.default(0.15)};
    width: 100%;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.default};
    transition: 0.2s;
    
    span {
      display: initial !important;
 
    }
    

  }
  
  .rdw-editor-toolbar {
    span {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      color: black;
      padding-right: 20px;
    }

  }

  .rdw-link-modal-target-option {
    display: flex;
    align-items: center;
    white-space: nowrap;

    input {
      min-width: 20px;
      min-height: 20px;
    }
  }

  .rdw-link-modal {
    height: auto !important;
    min-height: 205px;
  }

`;
