import React from 'react';
import { useWorkspaceInfo } from './hooks/useWorkspaceInfo';
import styled from 'styled-components';
import {AnimatedClasses} from "../../utils/animatedStyles";
import {AssessmentIcon, CheckIcon} from "../svgs";
import {ButtonSave, DefaultSelect} from "../ui";
import {COLORS_RGBA} from "../../utils/colors";

export const WorkspaceGroupClassification: React.FC = () => {
    const { saveBtnHandler, groupClassificationList,
        selectedGroupClassification, selectHandler, isSuccess } =
        useWorkspaceInfo();

    return (
        <div className={AnimatedClasses.fadeIn}>
             <WrapperS>
                 <div style={{marginBottom: '20px', fontSize: '16px'}}>Please, select the main classification of the group. Participants will pik their group based on this classification before taking the assessment.</div>
                <DefaultSelect
                    data={groupClassificationList}
                    icon={AssessmentIcon}
                    selected={selectedGroupClassification}
                    label="Choose Group Classification"
                    onChange={selectHandler}
                    placeHolder="Choose Group Classification"
                    // isLoading={isLoadingPackage}
                />
             </WrapperS>
            <div style={{ display: 'flex', flexDirection: 'row', margin: '50px 0 50px' }}>
                <ButtonSave
                    onClick={saveBtnHandler}
                />
                {isSuccess ? (
                    <>
                        <CopySuccessS className={AnimatedClasses.bounceIn}>
                            <CheckIcon /> Saved successfully!
                        </CopySuccessS>
                    </>
                ) : null}
            </div>

        </div>
    );
};


const WrapperS = styled.ul`
  margin-top: 16px;
  max-width: 504px;
  padding: 0;
  list-style: none;
   
  & > * {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }

  .ov-select-wr {
    height: 36px;
    padding: 3px 13px;
  }

  .svgIconSelect svg {
    transform: translateY(0) scale(-1, 1) !important;
    g {
      fill: ${COLORS_RGBA.default(0.3)};
      opacity: 1;
    }
  }
`;

const CopySuccessS = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS_RGBA.default(0.9)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  width: 135px;
  height: 42px;
  font-size: 16px;
  margin: 0 0 0 12px;
  padding: 10px 16px;
  padding: 0;
  border-radius: 8px;
  svg {
    margin: 0;
    padding: 0;
    height: 30px;
    width: 30px;
    path {
      fill: ${COLORS_RGBA.accent(1)};
    }
  }
`;
