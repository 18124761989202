import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { QuestionIcon } from '../svgs';
import { ColaboratorsPassInfo } from './ColaboratorsPassInfo';
import {
  IRates,
  useParticipantsRate,
} from './charts/hooks/useParticipantsRate';
import { Alert, Loader } from '../ui';
import { PackagePassInfo } from './PackagePassInfo';

interface ParticipationRateProps {
  chartId: string;
  isCollaborationRate?: boolean;
  title?: string;
  isBlack?: boolean;
}

export const ParticipationRate: React.FC<ParticipationRateProps> = React.memo(
  ({ chartId, isCollaborationRate, title, isBlack }) => {
    const { isOpen, setIsOpen, ref, loading, rates, errors } =
      useParticipantsRate(chartId);
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const [сurrentPackage, setCurrentPackage] = React.useState<IRates>(null);
    const renderBody = () => {
      if (isCollaborationRate) {
        return <ColaboratorsPassInfo />;
      }
      if (сurrentPackage) {
        return (
          <PackagePassInfo
            packageInfo={сurrentPackage}
            returnHandler={() => {
              setCurrentPackage(null);
            }}
          />
        );
      }
      return (
        <>
          {/* <PackScheduleS href="/package-schedule">
            <span style={{ paddingRight: '10px' }}>
              Show in Package Schedule
            </span>{' '}
            <ArrowRightIcon />
          </PackScheduleS> */}
          <ListS>
            {loading && (
              <li>
                <Loader isGreen />
              </li>
            )}
            {errors ? (
              <li>
                <Alert text={errors} />
              </li>
            ) : (
              rates &&
              rates[0] &&
              rates.map((itm) => {
                // if(graphType === ETypeGraph.type2) {
                //   return (
                //     <li key={itm.link}>
                //       <span onClick={() =>null}>{itm.cycle_date}</span>
                //       <span>{itm.number_of_unfinished} passed</span>
                //     </li>
                //   )
                // }
                return (
                  <li
                    key={itm.link}
                    onClick={() => {
                      setCurrentPackage(itm);
                    }}
                  >
                    <span onClick={() => null}>
                      {itm.package_name} {itm.cycle_date}
                    </span>
                    <span>
                      {itm.number_of_finished} completed
                      {itm.number_of_unfinished
                        ? `, ${itm.number_of_unfinished} no response`
                        : ''}
                    </span>
                  </li>
                );
              })
            )}
          </ListS>
        </>
      );
    };

    return (
      <WrapperS ref={ref}>
        <LinkS
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          isBlack={isBlack}
        >
          {' '}
          {title ? <TitleS>{title}</TitleS> : <QuestionIcon />}
        </LinkS>
        {isOpen && <WrapperModS>{renderBody()}</WrapperModS>}
        {isTooltipOpen && !title && (
          <TooltipWrapperS>
            <span>Participation rate</span>
          </TooltipWrapperS>
        )}
      </WrapperS>
    );
  }
);

const WrapperS = styled.div`
  position: relative;
`;
const TitleS = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
`;
export const TooltipWrapperS = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  min-width: 125px;
  padding: 5px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  z-index: 5;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 6px;
  span {
    margin: 0;
    padding: 0;
  }
`;
const WrapperModS = styled.div`
  margin-top: 10px;
  position: absolute;
  min-width: 250px;
  max-height: 400px;
  right: 0;
  top: 100%;
  transform: translateX(15px);
  background: white;
  padding: 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  /* max-width: 334px; */
  z-index: 5;
  border-radius: 5px;
  /* min-width: 334px; */

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    bottom: 100%;
    right: 20px;
    //transform: translateX(-50%);
    border-bottom: 5px solid #fff;
  }
`;

export const LinkS = styled.button<{ isBlack?: boolean }>`
  display: inline-flex;
  background: none;
  border: none;
  opacity: 0.5;
  font-size: 14px;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  color: ${({ isBlack }) => (!isBlack ? `${COLORS.default}` : 'black')};
  &:hover {
    color: ${COLORS.accent};
    opacity: 1;
  }
  &:active {
    color: ${COLORS.accentActive};
    opacity: 1;
  }
`;

const ListS = styled.ul`
  list-style: none;
  outline: 0;
  padding: 0;
  margin: 10px 0 0;
  li {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    span {
      white-space: nowrap;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      &:first-child {
        /* cursor: pointer; */

        text-align: right;
        color: ${COLORS.accent};
        //width: 110px;
        /* display: inli; */
        margin-right: 15px;

        /* &:hover {
          color: ${COLORS.accentHover};
        }
        &:active {
          color: ${COLORS.accentActive};
        } */
      }
    }
  }
`;
// const PackScheduleS = styled.a`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   font-size: 16px;
//   background: transparent;
//   color: #2a2f2a;
//   border: solid 1px #d8d8d8;
//   border-radius: 8px;
//   padding: 0 10px;
//   transition: 0.2s;
//   min-height: 36px;

//   &:hover {
//     border: solid 1px ${COLORS.accent};
//   }

//   &:active {
//     border: solid 1px ${COLORS.accentActive};
//     background: ${COLORS.accentActive};
//     color: white;

//     svg {
//       opacity: 0.7;
//       g {
//         fill: #fff;
//       }
//     }
//   }

//   &:disabled {
//     border: solid 1px #d8d8d8 !important;
//     cursor: default;
//     opacity: 0.2;
//   }
// `;
