import React from 'react';
import {
  BlockS,
  BlocksWrapperS,
  BlockTextS,
  BlockTitleS,
  WrapperS,
} from './styles';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { Loader } from '../../ui';
import { month_names_short } from '../../participants/ParticipantRow';

export const Info: React.FC = () => {
  const { Collaborations } = useSelector((store: AppStore) => store);

  if (Collaborations.participantLoading)
    return (
      <WrapperS style={{ position: 'relative' }}>
        <Loader isGreen />
      </WrapperS>
    );

  if (!Collaborations.participantInfo)
    return (
      <WrapperS>
        <div>No data</div>
      </WrapperS>
    );

  const { info } = Collaborations.participantInfo;

  const dateInfo =
    info.hiring_date && info.hiring_date !== '0001-01-01T00:00:00Z'
      ? info.hiring_date.split('T')[0].split('-')
      : null;

  const locationFirstRow: string[] = [];
  info.work_location_name && locationFirstRow.push(info.work_location_name);
  info.work_location_city && locationFirstRow.push(info.work_location_city);
  const locationSecondRow: string[] = [];
  info.work_location_state && locationSecondRow.push(info.work_location_state);
  info.work_location_country &&
    locationSecondRow.push(info.work_location_country);
  // console.log(locationFirstRow);
  // console.log(locationSecondRow);
  return (
    <WrapperS>
      <BlocksWrapperS>
        <BlockS>
          <BlockTitleS>Email:</BlockTitleS>
          <BlockTextS>{info.email || '-'}</BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>ID:</BlockTitleS>
          <BlockTextS>{info.payroll_id || '-'}</BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>Phone number:</BlockTitleS>
          <BlockTextS>
            {info.phone_code}
            {info.phone || '-'}
          </BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>Start date:</BlockTitleS>
          <BlockTextS>
            {info.hiring_date && info.hiring_date !== '0001-01-01T00:00:00Z'
              ? dateInfo &&
                `${month_names_short[+dateInfo[1] - 1]} ${dateInfo[2]} ${
                  dateInfo[0]
                }`
              : '-'}
          </BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>Job:</BlockTitleS>
          <BlockTextS>{info.job || '-'}</BlockTextS>
        </BlockS>
        <BlockS>
          <BlockTitleS>Work location:</BlockTitleS>
          {locationFirstRow[0] || locationSecondRow[0] ? (
            <>
              <BlockTextS>{locationFirstRow.join(', ')}</BlockTextS>
              <BlockTextS>{locationSecondRow.join(', ')}</BlockTextS>
            </>
          ) : (
            <BlockTextS>-</BlockTextS>
          )}
        </BlockS>
      </BlocksWrapperS>
    </WrapperS>
  );
};
