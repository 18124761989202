import React from 'react';
import { readString } from 'react-papaparse';
import { MainWrapperS } from '../globalStyle';

interface CsvReaderProps {}

export const CsvReader: React.FC<CsvReaderProps> = () => {
  return (
    <MainWrapperS>
      <input
        type="file"
        onChange={(e) => {
          console.log(e.target);
          if (!e.currentTarget || !e.currentTarget.files) return null;
          const file = e.currentTarget.files[0];
          const fileReader = new FileReader();

          const handleFileReader = () => {
            const content = fileReader.result;
            console.log(typeof content);
            const results = readString(content as string, null) as any;
            if (results.data) {
              const itms = results.data.filter(
                (d: any) => d[2] === 'ygor.agro2@gmail.com'
              );
              console.log(itms, 'itms');
            }
            console.log(results, 'results');
          };

          fileReader.onloadend = handleFileReader;
          fileReader.readAsText(file);
        }}
      />
    </MainWrapperS>
  );
};
