import styled from 'styled-components';
import ShapeSrc2 from '../../images/shape-2.svg';
import ShapeSrc3 from '../../images/shape-3.svg';
import ShapeSrc from '../../images/shape.svg';
import WavesSrc from '../../images/waves.png';
import { COLORS } from '../../utils/colors';
import { CSS_MEDIA } from '../../utils/media';

export const WrapperS = styled.div`
  background: white;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 35px 25px;
  & > * {
    z-index: 2;

    ${CSS_MEDIA.maxMd} {
      margin-top: -100px;
    }
  }

  &::before,
  &::after {
    position: fixed;
    content: '';
    display: block;
    width: 300px;
    height: 240px;
    background: url(${WavesSrc}) no-repeat center / cover;
    @media screen and (max-width: 800px) {
      width: 100px;
      height: 100px;
    }
  }

  &:before {
    top: 0;
    left: 0;
    transform: translateY(-10%) translateX(-20%);
    @media screen and (max-width: 800px) {
      // display: none;
      transform: translateY(0) translateX(0);
    }
    ${CSS_MEDIA.maxMd} {
      transform: translateY(-20%) translateX(-20%);
    }
  }
  &:after {
    bottom: 0;
    right: 0;
    transform: translateY(0) translateX(20%) rotate(180deg);

    @media screen and (max-width: 800px) {
      // display: none;
      transform: translateY(0) translateX(0) rotate(180deg);
    }
  }

  @media screen and (max-width: 320px) {
    padding: 70px 15px;
  }
`;

const Shape = styled.div`
  position: fixed;

  @media screen and (max-width: 993px) {
    display: none;
  }
`;

export const Shape1 = styled(Shape)`
  bottom: 0;
  left: 10%;
  background: url(${ShapeSrc}) no-repeat bottom / cover;
  width: 90.5px;
  height: 51px;
`;
export const Shape2 = styled(Shape)`
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: url(${ShapeSrc2}) no-repeat bottom / cover;
  width: 50px;
  height: 178px;
`;
export const Shape3 = styled(Shape)`
  top: 0;
  right: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: url(${ShapeSrc3}) no-repeat bottom / cover;
  width: 64px;
  height: 64px;
`;

export const FooterS = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin: 10px;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: ${COLORS.accent};
    }
  }
`;

export const LangWrapperS = styled.div<{ alignRight: boolean }>`
  position: absolute;
  top: 0;
  right: 50%;
  padding: 20px;
  z-index: 9999;
  display: flex;
  transform: translateX(50%);
  min-width: 125px;
  ${CSS_MEDIA.maxMd} {
    /* left: ${({ alignRight }) => ('' ? '0' : '')}; */
    /* right: ${({ alignRight }) => ('' ? '100%' : '0')}; */
    right: 0;
    /* padding: ${({ alignRight }) =>
      '' ? '10px 0 0 30px' : '10px 120px 0 0'}; */
    padding: 10px 120px 0 0;
    transform: translateX(0);
  }
  /* ${CSS_MEDIA.maxSm} {
    right: 0;
    padding: 10px 25px 0 0;
    transform: translateX(0);
  } */
  .ov-select-wr {
    padding: 7px 12px;
    min-width: 90px;
    height: auto;
    span {
      font-size: 13px;
    }
    svg {
      width: 10px;
      height: auto;
    }
  }
`;

export const LangBtnIsActiveS = styled.button<{ activeLang: boolean }>`
  padding: 0;
  border: 0;
  margin-left: 15px;
  background: none;
  img {
    margin-right: 5px;
    width: 15px;
    height: 10px;
  }

  ${({ activeLang }) =>
    activeLang
      ? `
      cursor: default;
    opacity: 1;
    `
      : `
        opacity: 0.2;
      `}
`;
