import React from 'react';
import styled from 'styled-components';
import { ProgressIcon } from '../../svgs';
import { TLang } from '../../../types/lang';
import { checkLang } from '../assLang';
import { CSS_MEDIA } from '../../../utils/media';
import { langIsAllingRight } from '../../../utils/langIsEng';

interface StepsTrackingProps {
  total: number;
  current: number;
  lang: TLang;
  isAnonymous: boolean;
}

export const StepsTracking: React.FC<StepsTrackingProps> = ({
  total,
  current,
  lang,
  isAnonymous,
}) => {
  return (
    <StepsTrackingWrapperS data-cy="stepsTrackingWrapperS">
      <TextS alignRight={langIsAllingRight(lang)}>
          {checkLang(lang).assessments.question.title}
        {!langIsAllingRight(lang)
          ? ` ${current}/${total}`
          : ` ${total}/${current}`}
      </TextS>


      <LinesWrapperS>
        <ProgressIcon color="#000" opacity="0.2" />
        <LineActiveWrapperS style={{ width: `${(current / total) * 100}%` }}>
          <ProgressIcon color="#5DD862" />
        </LineActiveWrapperS>
      </LinesWrapperS>
        {isAnonymous ?
            <TextAnonymousS alignRight={langIsAllingRight(lang)}>
                {checkLang(lang).assessments.anonymousQuestion}
        </TextAnonymousS> : null }
    </StepsTrackingWrapperS>
  );
};

const StepsTrackingWrapperS = styled.div`
  position: absolute;
  top: 25px;
  right: 35px;

  ${CSS_MEDIA.maxMd} {
    top: 50px;
  }

  @media screen and (max-width: 320px) {
    right: 15px;
  }
`;

const LinesWrapperS = styled.div`
  position: relative;
  margin-top: 5px;
  height: 16px;
  width: 168px;
`;
const LineActiveWrapperS = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
`;

const TextS = styled.div<{ alignRight: boolean }>`
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  opacity: 0.8;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #000000;
`;

const TextAnonymousS = styled.div<{ alignRight: boolean }>`
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  display: flex;
  justify-content: center; 
  align-items: center;
  border-radius: 6px;
  opacity: 0.8;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 0.8px;
  background-color: #4caf50;
  font-size: 14px;
`;
