import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Header, LeftSideBar, NewWorkspace } from './components';
import { ButtonAdd } from './components/ui';
import { useChangeWorkspace } from './hooks/useChangeWorkspace';
import { AppStore } from './store/applicationState';
import { GetWorkspacesByTeam } from './store/workspaces/actions';
import { CurrentWorkspaceId } from './utils/config';
import { ROUTE_PATH } from './utils/routes';

interface LoggedInComponentsProps {}

export const LoggedInComponents: React.FC<LoggedInComponentsProps> = () => {
  const { User, Workspaces } = useSelector((store: AppStore) => store);
  const { changeWorkspace } = useChangeWorkspace();
  const [sidebarIsVisible, setSidebarIsVisible] = React.useState(false);
  const [isCreateNewSpaceVisible, setIsCreateNewSpaceVisible] =
    React.useState(false);
  const [isCreatePageVisible, setIsCreatePageVisible] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (User.data && User.data.team_id) {
      dispatch(GetWorkspacesByTeam.request({ teamId: User.data.team_id }));
    }
  }, [User.data, dispatch]);

  React.useEffect(() => {
    const checkPath = (path: string) => {
      if (path.includes(ROUTE_PATH.admin)) {
        setSidebarIsVisible(false);
      } else {
        setSidebarIsVisible(true);
      }
    };

    checkPath(history.location.pathname);

    const unListen = history.listen((location) => {
      checkPath(location.pathname);
    });

    return () => unListen();
  }, [history]);

  React.useEffect(() => {
    if (
      !Workspaces.current &&
      Workspaces.data &&
      Array.isArray(Workspaces.data) &&
      Workspaces.data[0] &&
      User.data &&
      User.data.workspaces_ids[0]
    ) {
      setWorkspace();
    } else if (
      (Workspaces.data &&
        Array.isArray(Workspaces.data) &&
        !Workspaces.data[0]) ||
      (User.data && !User.data.workspaces_ids[0])
    ) {
      setIsCreatePageVisible(true);
    }

    function setWorkspace() {
      const workspaceId = localStorage.getItem(CurrentWorkspaceId);
      if (!workspaceId) return setNewWorkspace();
      if (!User.data?.workspaces_ids.includes(workspaceId))
        return setNewWorkspace();
      const workspace = Workspaces.data!.find(
        (ws) => `${ws.id}` === `${workspaceId}`
      );
      if (!workspace) return setNewWorkspace();

      return changeWorkspace(workspace);
    }

    function setNewWorkspace() {
      const workspace = Workspaces.data!.find(
        (ws) => ws.id === User.data!.workspaces_ids[0]
      );
      if (!workspace) return setIsCreatePageVisible(true);

      return changeWorkspace(workspace);
    }
    if (Workspaces.current && isCreatePageVisible) {
      setIsCreatePageVisible(false);
    }
  }, [Workspaces, dispatch, changeWorkspace, User.data, isCreatePageVisible]);
  // location.pathname.includes(ROUTE_PATH.admin)
  React.useEffect(() => {
    if (isCreatePageVisible) {
      setIsCreateNewSpaceVisible(true);
    }
  }, [isCreatePageVisible]);
  return (
    <>
      <Header />
      {sidebarIsVisible && (
        <LeftSideBar createNewFn={() => setIsCreateNewSpaceVisible(true)} />
      )}
      {isCreateNewSpaceVisible && (
        <NewWorkspace closeHandler={() => setIsCreateNewSpaceVisible(false)} />
      )}
      {isCreatePageVisible && (
        <NoWorksapceWrapperS>
          <Header onlyLogout={true} />
          <h2>
            Create a workspace or have a colleague add you to an existing
            workspace that belongs to your team.
          </h2>
          <ButtonAdd
            onClick={() => {
              setIsCreateNewSpaceVisible(true);
            }}
            title="Add new Workspace"
          />
        </NoWorksapceWrapperS>
      )}
    </>
  );
};
const NoWorksapceWrapperS = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    max-width: 600px;
    text-align: center;
    font-size: 20px;
  }
`;
