import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { EFriendScoreStatus } from '../../../store/collaborations/types';

export const useFriendToggle = () => {
  const [friendChecked, setFriendChecked] = React.useState(false);
  const { Collaborations } = useSelector((store: AppStore) => store);

  React.useEffect(() => {
    setTimeout(() => {
      const circles = document.querySelectorAll('.userCircle');
      const circlesScore: number[] = [];

      if (!circles || !circles[0]) return null;

      const toArray = (circle: HTMLElement) => {
        if (!Collaborations.data) return null;
        const { friendScore } = circle.dataset;
        circlesScore.push(+friendScore);
      };

      circles.forEach(toArray);

      let maxScore = Math.max(...circlesScore);

      const changeShadowCircle = (circle: HTMLElement) => {
        if (!Collaborations.data) return null;
        // if (!circle.classList.contains('captured')) return null;
        const { friendScore } = circle.dataset;

        const preAdjustedScore = Math.round(+friendScore / (maxScore / 18));
        const adjustedScore =
          +friendScore !== 0 && preAdjustedScore <= 1 ? 1 : preAdjustedScore;

        if (adjustedScore < 18) {
          if (!friendChecked) {
            circle.classList.contains(
              EFriendScoreStatus['times_listed_' + adjustedScore]
            ) &&
              circle.classList.remove(
                EFriendScoreStatus['times_listed_' + adjustedScore]
              );
          } else {
            circle.classList.add(
              EFriendScoreStatus['times_listed_' + adjustedScore]
            );
          }
        } else if (adjustedScore >= 18) {
          if (!friendChecked) {
            circle.classList.contains(
              EFriendScoreStatus.times_listed_18_or_more
            ) &&
              circle.classList.remove(
                EFriendScoreStatus.times_listed_18_or_more
              );
          } else {
            circle.classList.add(EFriendScoreStatus.times_listed_18_or_more);
          }
        }
      };

      circles.forEach(changeShadowCircle);
    }, 100);
  }, [friendChecked, Collaborations.data]);

  return {
    friendChecked,
    setFriendChecked,
  };
};
