import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  AssessmentPackage,
  AssessmentView,
  NewAssessment,
  NewCategory,
  NewPackage,
} from '../components';
import { CatRow, RightSideBar, SearchAndBtns } from '../components/Assessments';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import { Loader } from '../components/ui';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { GetOneAssessment } from '../store/assessments/actions';
import {EQuestionType, TCatAssessment} from '../store/assessments/types';
import { DeleteCat, GetCats, TypeGetCatR } from '../store/categories/actions';
import { TCat } from '../store/categories/types';
import { GetAllPackages } from '../store/packages/actions';
import { AnimatedClasses } from '../utils/animatedStyles';
import { HEADER_HEIGHT, SIDE_BAR_WIDTH } from '../utils/config';
import { deepClone } from '../utils/deepClone';
import { TEXT } from '../utils/textConst';
import {NewLinkedSurvey} from "../components/modals/NewLinkedSurvey";
import {LinkedSurveyView} from "../components/modals/LinkedSurveyView";

interface MyAssessmentsProps {
  type?: TypeGetCatR['type'];
}

export const MyAssessments: React.FC<MyAssessmentsProps> = ({
  type = 'custom',
}) => {
  const { User, Cats, Assessments, Workspaces, Packages } = useSelector(
    (store: AppStore) => store
  );
  const [isNewPackageVisible, setIsNewPackageVisible] = React.useState(false);
  const [isNewCatVisible, setIsNewCatVisible] = React.useState(false);
  const [isNewAssVisible, setIsNewAssVisible] = React.useState(false);
  const [isNewLinkedSurveyVisible, setIsNewLinkedSurveyVisible] = React.useState(false);
  const [editCatObj, setEditCatObj] = React.useState<TCat | null>(null);
  const [allAssessments, setAllAssessments] = React.useState<TCatAssessment[]>(
    []
  );

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (User.data && User.data.team_id)
      dispatch(GetCats.request({ teamId: User.data.team_id, type }));
  }, [dispatch, User.data, type]);
  React.useEffect(() => {
    if (Workspaces.current)
      dispatch(GetAllPackages.request({ workspaceId: Workspaces.current.id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Workspaces.current]);

  React.useEffect(() => {
    const unlisten = history.listen((data) => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [history]);

  React.useEffect(() => {
    const allAss: TCatAssessment[] = [];
    Cats &&
      Cats.data &&
      Array.isArray(Cats.data) &&
      Cats.data.forEach((category) => {
        category.assessments.forEach((ass) => {
          allAss.push(ass);
        });
      });
    setAllAssessments(allAss);
  }, [Cats]);

  const deleteRowHandler = React.useCallback(
    (id: string, position: number) => {
      const callBack = (success: boolean) => {
        if (!success) return null;
        let newCats = deepClone(Cats.data) as TCat[];

        if (!newCats[position])
          return dispatch(
            DeleteCat.error('Could not find cat ' + TEXT.tryLater)
          );

        if (`${newCats[position].id}` === `${id}`) {
          newCats.splice(position, 1);
        } else {
          newCats = newCats.filter((cat) => `${cat.id} !== ${id}`);
        }

        return dispatch(DeleteCat.success(newCats));
      };

      return dispatch(
        DeleteCat.request({
          catId: id,
          callBack,
        })
      );
    },
    [Cats.data, dispatch]
  );

  const renderCatRow = React.useMemo(() => {
    if (Cats.loading && (!Cats.data || (Cats.data && !Cats.data[0])))
      return null;

    return (
      Cats.data &&
      Array.isArray(Cats.data) &&
      Cats.data.map((itm, i) => (
        <CatRow
          id={itm.id}
          key={itm.id}
          assessments={itm.assessments}
          clickHandler={() => null}
          deleteHandler={() => deleteRowHandler(itm.id, i)}
          type={type}
          editHandler={() => {
            setEditCatObj(itm);
            setIsNewCatVisible(true);
          }}
          title={itm.name}
          description={itm.description}
          allAssessments={allAssessments}
        />
      ))
    );
  }, [Cats, deleteRowHandler, type, allAssessments]);

  const isEmpty = !Cats.loading && (!Cats.data || (Cats.data && !Cats.data[0]));
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <WrapperS className={AnimatedClasses.fadeIn}>
          <PageTitleWrapperS>
            <h1>{type === 'default' ? 'OrgVitals Library' : 'My Library'} </h1>
          </PageTitleWrapperS>
          {type === 'default' ? null : (
            <SearchAndBtns
              newCatHandler={() => setIsNewCatVisible(true)}
              newAssessmentHandler={() => setIsNewAssVisible(true)}
              linkedSurveyHandler={() => setIsNewLinkedSurveyVisible(true)}
              isActiveBtnAss={!isEmpty}
            />
          )}

          <BodyS isEmpty={isEmpty}>
            {isEmpty ? (
              <EmptyTextS>
                <p>
                  Create an assessment Category and add a new Assessment to it
                </p>
              </EmptyTextS>
            ) : (
              renderCatRow
            )}
            {Cats.loading ? <Loader isGreen /> : null}
          </BodyS>
        </WrapperS>

        <RightSideBar newPackageHandler={() => setIsNewPackageVisible(true)} />
        {Packages.current ? (
          <AssessmentPackage assPackage={Packages.current} />
        ) : null}
      </DndProvider>
      {isNewPackageVisible || Packages.editObj ? (
        <NewPackage
          pack={Packages.editObj}
          closeHandler={() => setIsNewPackageVisible(false)}
        />
      ) : null}
      {isNewCatVisible ? (
        <NewCategory
          closeHandler={() => {
            setIsNewCatVisible(false);
            setEditCatObj(null);
          }}
          editObj={editCatObj}
        />
      ) : null}
      {isNewAssVisible ? (
        <NewAssessment closeHandler={() => setIsNewAssVisible(false)} />
      ) : null}
      {isNewLinkedSurveyVisible? (
          <NewLinkedSurvey closeHandler={() => setIsNewLinkedSurveyVisible(false)} />
      ) : null}
      {Assessments.current ?
          Assessments.current.answer_type === EQuestionType.linked_survey?
          <LinkedSurveyView
              ass={Assessments.current}
              closeHandler={() => dispatch(GetOneAssessment.success({}))}
              isArchived={false}
          />
          :
          <AssessmentView
              ass={Assessments.current}
              closeHandler={() => dispatch(GetOneAssessment.success({}))}
              isArchived={false}
          />
       : null}
      {Assessments.loading ? <Loader isFixed isGreen /> : null}
    </>
  );
};

const WrapperS = styled(MainWrapperS)`
  padding: calc(${HEADER_HEIGHT} + 26px) calc(${SIDE_BAR_WIDTH} + 24px);
`;

const BodyS = styled.div<{ isEmpty?: boolean }>`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  ${({ isEmpty }) =>
    isEmpty
      ? `
    align-items: center;
  justify-content: center;
  display: flex;
  
  
  `
      : ``}
`;

const EmptyTextS = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  max-width: 234px;
  text-align: center;
`;
