import styled from 'styled-components';
import { COLORS_RGBA } from '../../../utils/colors';

export const OuterWrapperS = styled.div<{ isOver?: boolean }>`
  background: white;
  position: relative;
  margin-top: 20px;
  width: 98%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    border: 1px solid ${COLORS_RGBA.default(0.12)};
    z-index: -1;
    transition: 0.2s;
  }

  & > div {
    transition: 0.2s;
  }

  &:hover {
    & > div {
      border-color: ${COLORS_RGBA.accent(1)};
    }
    &:before {
      border-color: ${COLORS_RGBA.accent(1)};
    }
  }

  ${({ isOver }) =>
    isOver
      ? `
  & > div {
  border-color: ${COLORS_RGBA.accent(1)};
}
&:before {
  border-color: ${COLORS_RGBA.accent(1)};
}
`
      : ``}
`;
export const WrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 15px;
  border: 1px solid ${COLORS_RGBA.default(0.12)};
  background: white;
`;

export const HeaderS = styled.header`
  display: flex;
  align-items: center;
`;

export const BodyS = styled.div`
  padding-top: 42px;
  cursor: pointer;
  h2 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    display: flex;
    align-items: flex-start;
  }
  p {
    margin: 5px 0 0;
  }
`;

export const TitleS = styled.span`
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CountS = styled.span`
  color: #b8b8b8;
  transform: translateY(-1px);
`;

export const DescS = styled.p`
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLORS_RGBA.default(0.7)};
`;

export const QuestionsCountS = styled.span`
  flex: 1;
  padding-left: 8px;
  color: ${COLORS_RGBA.default(0.7)};
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
