import { Reducer } from 'redux';
import ActionTypes, { TAttachmentsState } from './types';

export const initialState: TAttachmentsState = {
  loading: false,
  data: null,
  errors: undefined,
};

const reducer: Reducer = (state = initialState, action): TAttachmentsState => {
  switch (action.type) {
    case ActionTypes.CREATE_ATTACH_R:
    case ActionTypes.DELETE_ATTACH_R:
    case ActionTypes.GET_ALL_ATTACH_R:
    case ActionTypes.GET_PRESIGNED_URL_R:
      return { ...state, loading: true };
    case ActionTypes.DELETE_ATTACH_S:
      return { ...state, data: action.payload, loading: false };
    case ActionTypes.GET_ALL_ATTACH_S:
      return { ...state, data: action.payload, loading: false };
    case ActionTypes.CREATE_ATTACH_S:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
      };
    case ActionTypes.GET_PRESIGNED_URL_S:
      return { ...state, loading: false };
    case ActionTypes.CREATE_ATTACH_E:
    case ActionTypes.DELETE_ATTACH_E:
    case ActionTypes.GET_ALL_ATTACH_E:
    case ActionTypes.GET_PRESIGNED_URL_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
export { reducer as AttachmentsReducer };
