import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../svgs';
import { ButtonDefS, ButtonLinkS } from '../ui/buttons/styles';
import { CopySuccess } from '../ui/CopySuccess';
import * as Styles from './modalStyles';
import { DaysWrapperS, LinkWrapperS } from './PackageGenLink';

interface SendIpProps {
  closeModal: () => void;
}
export const SendIp: React.FC<SendIpProps> = ({ closeModal }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const ref = React.useRef<HTMLInputElement | null>(null);

  const copyToClipBoard = () => {
    if (!ref || !ref.current) return null;
    ref.current.select();
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderBody = () => {
    return (
      <>
        <PreviewLinkWrapperS>
          <input
            type="text"
            ref={ref}
            value={'159.183.191.208'}
            name="IP"
            readOnly
            onChange={() => null}
          />
          <ButtonDefS onClick={copyToClipBoard}>Copy</ButtonDefS>
        </PreviewLinkWrapperS>
        <DaysWrapperS>
          {isCopied ? <CopySuccess text="IP Copied!" /> : null}
        </DaysWrapperS>
      </>
    );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '400px', minHeight: '250px' }}
      >
        <BtnCloseS
          style={{ zIndex: 1 }}
          className="closeModalBtn"
          onClick={closeModal}
        >
          <CloseIcon />
        </BtnCloseS>
        <Styles.ModalBodyS style={{ paddingTop: '24px' }}>
          <Styles.ModalTileS>IP Info</Styles.ModalTileS>
          <TextS>
            OrgVitals IP address to provide to IT to avoid spam filters (
            SendGrid ):
          </TextS>
          <WrapperS>{renderBody()}</WrapperS>
        </Styles.ModalBodyS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const BtnCloseS = styled(ButtonLinkS)`
  position: absolute;
  opacity: 0.5;
  right: 20px;
  top: 20px;
`;
const PreviewLinkWrapperS = styled(LinkWrapperS)`
  width: 100%;
  margin-top: 10px;
  input {
    font-size: 1.6rem;
  }
`;
const WrapperS = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
const TextS = styled.p`
  font-size: 16px;
  margin: 10px 0;
`;
