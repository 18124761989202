import { Reducer } from 'redux';
import ActionTypes, { TFinchIntegrationsState } from './types';

export const initialState: TFinchIntegrationsState = {
  loading: false,
  data: null,
  errors: null,
};

const reducer: Reducer<TFinchIntegrationsState> = (
  state = initialState,
  action
): TFinchIntegrationsState => {
  switch (action.type) {
    case ActionTypes.CREATE_R:
    case ActionTypes.DELETE_R:
    case ActionTypes.GET_ALL_R:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case ActionTypes.DELETE_S:
    case ActionTypes.GET_ALL_S:
      return {
        ...state,
        loading: false,
        errors: null,
        data: action.payload,
      };
    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        errors: null,
        data: state.data ? [...state.data, action.payload] : [action.payload],
      };

    case ActionTypes.CREATE_E:
    case ActionTypes.DELETE_E:
    case ActionTypes.GET_ALL_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as FinchIntegrationsReducer };
