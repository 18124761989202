import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { COLORS } from '../../utils/colors';
import { ButtonMore, EditSelectItem, Loader } from '../ui';
import * as Styles from '../LeftSideBar/WSstyles';
import { WorkspaceAvatarIcon } from '../svgs/WorkspaceAvatarIcon';
import { checkLinkForHttp } from '../../utils/helpers';
import { VALUES } from '../../utils/valueConst';
import { useHistory } from 'react-router-dom';
interface HomeInfoCardProps {}

export const HomeInfoCard: React.FC<HomeInfoCardProps> = () => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const history = useHistory();
  const renderHeader = () => {
    if (!Workspaces.current) return <Loader isGreen />;
    const additional_info = Workspaces.current.additional_info;
    return (
      <>
        <HeaderWrapperS>
          <Styles.ImageS>
            <WorkspaceAvatarIcon />
            {Workspaces.current?.logo_url !== '' && (
              <Styles.ImageInnerS
                style={{
                  backgroundImage: `url("${
                    Workspaces.current?.logo_url || ''
                  }")`,
                }}
              />
            )}
          </Styles.ImageS>
          <HeaderTextS>
            <h4>{Workspaces.current.name}</h4>
            <span>
              {additional_info.organization_location
                ? `${additional_info.organization_location} • ${additional_info.organization_geographic_structure}`
                : additional_info.formation_type}
            </span>
          </HeaderTextS>
        </HeaderWrapperS>
        <ButtonMore
          onClick={(event) => {
            if (event === VALUES.edit)
              return history.push('/workspace-settings');
            return null;
          }}
          data={[
            {
              title: <EditSelectItem />,
              id: 1,
              value: VALUES.edit,
            },
          ]}
        />
      </>
    );
  };
  const renderBody = () => {
    if (!Workspaces.current) return <Loader isGreen />;
    const additional_info = Workspaces.current.additional_info;
    return (
      <AdditionalInfoS>
        <MainInfoS>
          <InfoWrapperS>
            <label>Main contact:</label>
            <span>
              {additional_info.main_contact.first_name
                ? `${additional_info.main_contact.first_name} ${additional_info.main_contact.last_name}`
                : 'add name'}
            </span>
            <span>
              {additional_info.main_contact.email
                ? `${additional_info.main_contact.email}`
                : 'add email'}
            </span>
            <span>
              {additional_info.main_contact.phone_code
                ? `${additional_info.main_contact.phone_code}${additional_info.main_contact.phone}`
                : 'add phone'}
            </span>
          </InfoWrapperS>
        </MainInfoS>
        <OtherInfoS>
          <InfoWrapperS>
            <label>Website:</label>
            {additional_info.main_contact.site_link ? (
              <a
                href={
                  checkLinkForHttp(additional_info.main_contact.site_link)
                    ? additional_info.main_contact.site_link
                    : `https://${additional_info.main_contact.site_link}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {additional_info.main_contact.site_link}
              </a>
            ) : (
              'add URL'
            )}
          </InfoWrapperS>
          {!additional_info.group_type && (
            <InfoWrapperS>
              <label>Industry:</label>
              <span>{additional_info.organization_industry}</span>
            </InfoWrapperS>
          )}
          {additional_info.group_type && (
            <InfoWrapperS>
              <label>Type of group:</label>
              <span>{additional_info.group_type}</span>
            </InfoWrapperS>
          )}
          <InfoWrapperS>
            <label>Size:</label>
            <span>
              {additional_info.organization_size
                ? additional_info.organization_size
                : '-'}
            </span>
          </InfoWrapperS>
          {!additional_info.group_type && (
            <InfoWrapperS>
              <label>Structure:</label>
              <span>{additional_info.organization_remote_work}</span>
            </InfoWrapperS>
          )}
        </OtherInfoS>
      </AdditionalInfoS>
      /* <ConcernsInfoS>
      <label>Culture concerns:</label>
      <ConsernsWrapperS>
        <ConcernsS>Culture</ConcernsS>
        <ConcernsS>People</ConcernsS>
        <ConcernsS>Sport</ConcernsS>
        <ConcernsS>Collaboration</ConcernsS>
        <ConcernsS>Kind</ConcernsS>
        <ConcernsS>Organized</ConcernsS>
        <ConcernsS>New employees</ConcernsS>
      </ConsernsWrapperS>
    </ConcernsInfoS> */
    );
  };
  return (
    <WrapperS>
      <HeaderS>{renderHeader()}</HeaderS>
      <BodyS>{renderBody()}</BodyS>
    </WrapperS>
  );
};
const WrapperS = styled.div`
  border-radius: 12px;
  border: solid 1px #d4d5d4;
  margin-bottom: 24px;
`;
const HeaderS = styled.div`
  padding: 12px;
  border-bottom: 1px solid #d4d5d4;
  display: flex;
  position: relative;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
`;
const HeaderWrapperS = styled.div`
  display: flex;
`;
const HeaderTextS = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    margin: 0;
  }
  span {
    font-size: 12px;
    font-weight: bold;
    color: #555955;
  }
`;
const BodyS = styled.div`
  position: relative;
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 150px;
  justify-content: center;
  label {
    color: #555955;
    font-size: 12px;
    font-weight: 700;
  }
  span {
    color: ${COLORS.default};
    font-weight: bold;
  }
`;
const AdditionalInfoS = styled.div`
  display: flex;
`;
const InfoWrapperS = styled.div`
  display: flex;
  padding: 10px;

  flex-direction: column;
  min-width: 100px;
`;
const MainInfoS = styled.div``;
const OtherInfoS = styled.div`
  position: relative;
  width: 90%;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  div {
    position: relative;
    width: 50%;
  }
`;
// const ConcernsInfoS = styled.div`
//   padding: 12px;
//   display: flex;
//   flex-direction: column;
// `;
// const ConsernsWrapperS = styled.div`
//   display: flex;
//   flex-wrap: wrap;
// `;
// const ConcernsS = styled.span`
//   padding: 6px 12px;
//   margin-top: 6px;
//   margin-right: 10px;
//   border: 1px solid ${COLORS_RGBA.accent(0.7)};
//   border-radius: 6px;
//   background-color: ${COLORS_RGBA.accent(0.1)};
//   font-weight: normal !important;
//   color: ${COLORS_RGBA.accent(1)} !important;
// `;
