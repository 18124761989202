import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TFinchIntegrations } from './types';

const GetAllActions = new Actions('GET_ALL', ActionTypes);

export interface TypeGetAllR extends TDefRequest {
  workspaceId: string;
}

export const GetAllFinchIntegrations = {
  request: (payload: TypeGetAllR) => GetAllActions.request(payload),
  success: (payload: TFinchIntegrations[]) => GetAllActions.success(payload),
  error: (message: string) => GetAllActions.error(message),
};

const DeleteActions = new Actions('DELETE', ActionTypes);

export interface TypeDeleteR extends TDefRequest {
  id: string;
}

export const DeleteFinchIntegration = {
  request: (payload: TypeDeleteR) => DeleteActions.request(payload),
  success: (payload: null) => DeleteActions.success(payload),
  error: (message: string) => DeleteActions.error(message),
};

const CreateActions = new Actions('CREATE', ActionTypes);

export interface TypeCreateR extends TDefRequest {
  workspaceId: string;
  data: { code: string;};
}

export const CreateFinchIntegration = {
  request: (payload: TypeCreateR) => CreateActions.request(payload),
  success: (payload: TFinchIntegrations) => CreateActions.success(payload),
  error: (message: string) => CreateActions.error(message),
};
