import React from 'react';

export const FiltersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#4CAF50">
          <g>
            <path
              d="M21 21c1.306 0 2.417.834 2.829 2H31c.552 0 1 .448 1 1s-.448 1-1 1h-7.17l.008-.026C23.434 26.153 22.316 27 21 27s-2.434-.847-2.838-2.026l.009.026H17c-.552 0-1-.448-1-1s.448-1 1-1h1.171c.412-1.166 1.523-2 2.829-2zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm6-8c1.306 0 2.417.834 2.829 2H31c.552 0 1 .448 1 1s-.448 1-1 1h-1.17l.008-.026C29.434 20.153 28.316 21 27 21s-2.434-.847-2.838-2.026l.009.026H17c-.552 0-1-.448-1-1s.448-1 1-1h7.171c.412-1.166 1.523-2 2.829-2zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-6-8c1.302 0 2.41.83 2.825 1.988l.004.01L31 11c.552 0 1 .448 1 1s-.448 1-1 1h-7.17l.008-.026C23.434 14.153 22.316 15 21 15s-2.434-.847-2.838-2.026l.009.026H17c-.552 0-1-.448-1-1s.448-1 1-1l1.171-.002.004-.01C18.59 9.83 19.698 9 21 9zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
              transform="translate(-1132.000000, -233.000000) translate(288.000000, 196.000000) translate(828.000000, 28.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
