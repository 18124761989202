import React from 'react';

interface IdentityIconProps {}

export const IdentityIcon: React.FC<IdentityIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M24 10c2.213 0 4 1.787 4 4s-1.787 4-4 4-4-1.787-4-4 1.787-4 4-4zm0 2c-1.109 0-2 .891-2 2s.891 2 2 2 2-.891 2-2-.891-2-2-2zm5.537 14H18.463C17.103 26 16 24.897 16 23.537 16 20.61 21.333 19 24 19s8 1.61 8 4.537C32 24.897 30.897 26 29.537 26zm0-2c.256 0 .463-.207.463-.463 0-.365-.586-.973-1.837-1.55C26.855 21.384 25.145 21 24 21c-1.145 0-2.855.384-4.163.987-1.251.577-1.837 1.185-1.837 1.55 0 .256.207.463.463.463h11.074z"
                transform="translate(-595 -187) translate(288 149) translate(291 28)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
