import React from 'react';

interface ParticipantsIconProps {}

export const ParticipantsIcon: React.FC<ParticipantsIconProps> = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
          <g fill="#000000">
            <g>
              <g>
                <path
                  d="M41 26l.176.005c1.517.088 2.731 1.302 2.819 2.819L44 29v2c0 1.598-1.249 2.904-2.824 2.995L41 34v-2c.513 0 .936-.386.993-.883L42 31v-2c0-.513-.386-.936-.883-.993L41 28v-2zm-6-1c2.21 0 4 1.79 4 4v1c0 2.21-1.79 4-4 4h-7c-2.21 0-4-1.79-4-4v-1c0-2.21 1.79-4 4-4zm0 2h-7c-1.105 0-2 .895-2 2v1c0 1.105.895 2 2 2h7c1.105 0 2-.895 2-2v-1c0-1.105-.895-2-2-2zm4.5-9c1.933 0 3.5 1.567 3.5 3.5S41.433 25 39.5 25 36 23.433 36 21.5s1.567-3.5 3.5-3.5zm0 2c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm-8-6c2.485 0 4.5 2.015 4.5 4.5S33.985 23 31.5 23 27 20.985 27 18.5s2.015-4.5 4.5-4.5zm0 2c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z"
                  transform="translate(-24 -199) translate(0 64) translate(0 121)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
