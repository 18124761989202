import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { ACTION_CONST, TTeam } from './types';

const GetTeamActions = new Actions('GET_MY', ActionTypes);
export const getMyR = (callBack: () => void) =>
  action(ActionTypes.GET_MY_R, { callBack });
export interface TGet_MY_TEAM_R extends TDefRequest {
  data: null;
}
export const GetMyTeam = {
  request: (payload: TGet_MY_TEAM_R) => GetTeamActions.request(payload),
  success: (payload: TTeam) => GetTeamActions.success(payload),
  error: (message: string) => GetTeamActions.error(message),
};
const UpdateActions = new Actions(ACTION_CONST.edit, ActionTypes);
export interface TUpdateTeamR extends TDefRequest {
  data: {
    name: string;
    is_logo_changed: boolean;
    file: File | null;
  };
  src: string;
  id: string;
}
export const UpdateTeam = {
  request: (payload: TUpdateTeamR) => UpdateActions.request(payload),
  success: (payload: TTeam) => UpdateActions.success(payload),
  error: (message: string) => UpdateActions.error(message),
};
