export const FONT_FAMILY = `'Inter', sans-serif`;
export const BIG_FONT_FAMILY = `'Big Shoulders Display', cursive`;
export const SECOND_BIG_FONT_FAMILY = `'Space Mono', monospace`;
export const API_URI = process.env.REACT_APP_API || '';
export const MY_EMAIL = process.env.REACT_APP_EMAIL || '';
export const MY_PASS = process.env.REACT_APP_PASS || '';
// export const MY_DEV_WORKSPACE = process.env.REACT_APP_DEV_WORKSPACE || '';
export const DEFAULT_PADDING = '24px';
export const SIDE_BAR_WIDTH = '265px';
export const HEADER_HEIGHT = '69px';
export const CurrentWorkspaceId = 'V29ya3NwYWNlSWQ=';
export const MAX_CSV_FILE_SIZE = 5;
export const MAX_ATTACHMENT_FILE_SIZE = 15;
export const MAX_SNAPSHOT_FILE_SIZE = 20;
export const MAX_IMG_FILE_SIZE = 1;
export const TOKEN = {
  access: 'YWNjZXNzLXRva2Vu',
  refresh: 'cmVmcmVzaC10b2tlbg',
};

export const LANG = 'ccsLbm';

export const LINKS = {
  privacy: {
    name: 'Privacy Policy',
    link: 'https://www.orgvitals.com/privacy',
  },
  terms: {
    name: 'Terms of use',
    link: 'https://www.orgvitals.com/terms',
  },
};

export const GRAPH_DEMO = true;
