import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { CreateFileSnapshot } from '../../store/dataStory/actions';
import { TDataStory } from '../../store/dataStory/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS } from '../../utils/colors';
import { deepClone } from '../../utils/deepClone';
import { DropWrapperS, WrapperCsvInfoS } from '../CsvAddParticipants/styles';
import { FileIcons } from '../Home/FileIcons';
import { CheckIcon } from '../svgs';
import { Alert, ButtonCancel, ButtonSave, Input } from '../ui';
import { useAddSnapshotFile } from './hooks/useAddSnapshotFile';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
interface AddSnapshotProps {
  closeHandler: () => void;
}

export const AddSnapshot: React.FC<AddSnapshotProps> = ({ closeHandler }) => {
  const { Workspaces, DataStory } = useSelector((store: AppStore) => store);
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const hookAddSnapshot = useAddSnapshotFile();
  const dispatch = useDispatch();
  React.useEffect(() => {
    hookAddSnapshot.setFile(null);
    hookAddSnapshot.setErrorText('');
    setNameError('');
    // eslint-disable-next-line
  }, []);
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };
  const saveHandler = () => {
    hookAddSnapshot.setErrorText('');
    if (!hookAddSnapshot.file)
      return hookAddSnapshot.setErrorText('File is empty');
    if (!name) {
      setNameError('Name is required');
      return null;
    }
    const callBack = (success: boolean) => {
      const newDataStory = deepClone(DataStory.data) as TDataStory;
      newDataStory.snapshots.push();
      //dispatch(CreateFileSnapshot.success());
      if (success) closeHandler();
    };
    dispatch(
      CreateFileSnapshot.request({
        workspaceId: Workspaces.current.id,
        data: {
          name: name,
          file: hookAddSnapshot.file,
        },
        callBack,
      })
    );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '900px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <h1 style={{ fontSize: '20px' }}>Add image</h1>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <div>
            <Alert text={hookAddSnapshot.errorText} />
          </div>
          <DropWrapperS {...hookAddSnapshot.getRootProps()}>
            <input {...hookAddSnapshot.getInputProps()} />
            {hookAddSnapshot.isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>
                Drag an image here or <span>browse</span> for a file…
              </p>
            )}
          </DropWrapperS>
          <div style={{ minHeight: '200px' }}>
            <BottomWrapperS>
              <InputWrapperS>
                <Input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  placeholder={`Name`}
                  label={'Name*'}
                  errorText={nameError}
                />
              </InputWrapperS>
            </BottomWrapperS>
            <TypeInfoRowS>
              <UploadedFileS>
                {hookAddSnapshot.file && (
                  <>
                    <FileIcons fileName={hookAddSnapshot.file.name} />
                    <FileInfoS>
                      <span style={{ maxWidth: '100%' }}>
                        {hookAddSnapshot.file.name}
                      </span>
                      <ButtonDeleteS
                        onClick={() => {
                          hookAddSnapshot.setFile(null);
                        }}
                      >
                        Delete
                      </ButtonDeleteS>
                    </FileInfoS>
                  </>
                )}
              </UploadedFileS>
              {
                <FileCheckWrapperS>
                  <WrapperCsvInfoS>
                    <h4>Check your image </h4>
                    <ul>
                      <li>
                        <CheckIcon /> File extension must be .png .jpeg .jpg
                      </li>
                      <li>
                        <CheckIcon /> Maximum upload file size: 20MB
                      </li>
                      <li>
                        <CheckIcon /> File name is required
                      </li>
                    </ul>
                  </WrapperCsvInfoS>
                </FileCheckWrapperS>
              }
            </TypeInfoRowS>
          </div>
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonCancel onClick={closeModalHandler} />
            <ButtonSave
              onClick={() => saveHandler()}
              loading={hookAddSnapshot.loading}
            />
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const InputWrapperS = styled.div`
  width: 50%;
  span {
    margin-top: 24px;
  }
`;
const BottomWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TypeInfoRowS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin-top: 0;
  }
`;
const FileCheckWrapperS = styled.div`
  width: 40%;
  bottom: 0;
  right: 0;
`;
const UploadedFileS = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  svg {
    width: 40px;
    height: 50px;
    margin-right: 7px;
  }
`;
const FileInfoS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    font-weight: 600;
  }
`;
const ButtonDeleteS = styled.button`
  background: transparent;
  color: ${COLORS.danger};
  font-size: 14px;
  border: 0;
  margin: 0;
  padding: 0;
  &:hover {
    color: ${COLORS.dangerActive};
  }
  &:active {
    color: ${COLORS.danger};
  }
`;
