import React from 'react';
import { ALL_PACKS } from '../../../../utils/consts';


export const useChartRuns = () => {
  const [activeRuns, setActiveRuns] = React.useState<string[]>([]);

  const updateActiveRuns =  (data: string | string[]) => {
    if (Array.isArray(data)) return setActiveRuns(data);

    if (data === ALL_PACKS.id) return setActiveRuns([]);

    let oldData = [...activeRuns];

    if (oldData.includes(data)) {
      oldData = oldData.filter((d) => d !== data);
    } else {
      oldData.push(data);
    }

    return setActiveRuns(oldData);
  };

  return {
    updateActiveRuns,
    activeRuns,
  };
};
