import React from 'react';
import styled from 'styled-components';
import { ArrowRightIcon } from '../../svgs';

interface BackBtnProps {
  classes?: string;
  title?: string;
  onClick: () => void;
  disabled?: boolean;
}

export const BackBtn: React.FC<BackBtnProps> = ({ classes, title = 'Back', onClick, disabled }) => {
  return (
    <BtnBackS type="button" onClick={onClick} className={`${classes}`} disabled={disabled}>
      <ArrowRightIcon />
      <span>{title}</span>
    </BtnBackS>
  );
};

const BtnBackS = styled.button`
  display: flex;
  align-items: center;
  height: 40px;

  padding: 9px 16px;
  border-radius: 66px;
  background: transparent;
  border: solid 2px rgba(67, 67, 82, 0.3);
  color: #434352;
  font-size: 16px;
  svg {
    margin-right: 10px;
    transition: 0.2s;
    transform: rotate(180deg) translateX(0);
    g {
      fill: #434352;
    }
  }

  &:hover {
    border: solid 2px #4ac24f;

    svg {
      transform: rotate(180deg) translateX(-5px);
    }
  }
  &:active {
    border: solid 2px #4caf50;
  }

  &:disabled {
    opacity: 0.3;
    border: solid 2px rgba(67, 67, 82, 0.3) !important;
    cursor: default;
    svg {
      transform: translateX(0) !important;
    }
  }

  &.questionBack {
    position: absolute;
    top: 25px;
    left: 35px;

    @media screen and (max-width: 320px) {
      left: 15px;
    }
  }
`;
