import React from 'react';
import { useSelector } from 'react-redux';
import { LogoIcon } from '../../components/svgs/LogoIcon';
import { Alert, Input, Loader } from '../../components/ui';
import { ContainerFluidS } from '../../globalStyle';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { LINKS } from '../../utils/config';
import { useLogin } from './hooks/useLogin';
import * as Styles from './styles';

interface DefaultProps {}

const EMAIL = 'email';
const PASS = 'pass';

export const Default: React.FC<DefaultProps> = () => {
  const { User } = useSelector((store: AppStore) => store);
  const { form, onChangeHandler, submitHandler, warning, loading } = useLogin(EMAIL, PASS);

  return (
    <>
      <Styles.HeaderS>
        <ContainerFluidS>
          <Styles.LogoWrapperS>
            <LogoIcon />
          </Styles.LogoWrapperS>
        </ContainerFluidS>
      </Styles.HeaderS>
      <Styles.MainS>
        <ContainerFluidS>
          <Styles.FormWrapperS className={AnimatedClasses.fadeIn}>
            <h1>Login</h1>
            <Styles.FormS action="" onSubmit={submitHandler}>
              <Input
                errorText={form[EMAIL].errorText}
                label="Email:"
                name={EMAIL}
                value={form[EMAIL].value}
                onChange={onChangeHandler}
                autoComplete="on"
              />
              <Input
                name={PASS}
                label="Password:"
                errorText={form[PASS].errorText}
                stylesWrapper={{ marginTop: '24px' }}
                value={form[PASS].value}
                type="password"
                onChange={onChangeHandler}
                autoComplete="on"
              />
              {/* <Styles.ForgotPassS>
                <Link to={ROUTE_PATH.forgotPass}>Forgot password?</Link>
              </Styles.ForgotPassS> */}
              <Styles.LoginBtnS type="submit" onSubmit={submitHandler}>
                {User.loading || loading ? <Loader /> : 'Log in'}
              </Styles.LoginBtnS>

              {User.errors ? <Alert text={User.errors} /> : null}
              {warning ? <Alert text={warning} type="warning" /> : null}

              {/* <Styles.WrapperSignUpS>
                <p>Don’t have an account?</p>
                <Link to={ROUTE_PATH.signUp}> Sign up </Link>
              </Styles.WrapperSignUpS> */}
            </Styles.FormS>
            <Styles.WrapperLinksS>
              <a href={LINKS.terms.link} target="_blank" rel="noreferrer">
                {LINKS.terms.name}
              </a>
              <a href={LINKS.privacy.link} target="_blank" rel="noreferrer">
                {LINKS.privacy.name}
              </a>
            </Styles.WrapperLinksS>
          </Styles.FormWrapperS>
        </ContainerFluidS>
      </Styles.MainS>
    </>
  );
};
