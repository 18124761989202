import React from 'react';
import { SelectItemType } from '../../../components/ui/selects/DefaultSelect';
import { useForm } from '../../../hooks/useForm';
import { API_ROUTE_PATH } from '../../../utils/api_routes';
import { callApi } from '../../../utils/callApi';
import { validateEmail } from '../../../utils/helpers';
import { TEXT } from '../../../utils/textConst';

export const useLoadData = () => {
  enum FORM_DATA_KEYS {
    firstName = 'first_name',
    lastName = 'last_name',
    email = 'email',
    password = 'password',
    confirmPass = 'confirmPass',
  }
  const [teams, setTeams] = React.useState<SelectItemType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [currentTeam, setCurrentTeam] = React.useState<SelectItemType | null>(
    null
  );
  const [permissionError, setPermissionError] = React.useState(true);
  const [errors, setErrors] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [createdConsultant, setCreatedConsultant] = React.useState<{
    email: string;
    password: string;
  } | null>(null);

  React.useEffect(() => {
    setCreatedConsultant(null);
  }, [currentTeam]);
  const { form, onChangeHandler, updateForm } = useForm({
    [FORM_DATA_KEYS.firstName]: {
      value: '',
    },
    [FORM_DATA_KEYS.lastName]: {
      value: '',
    },
    [FORM_DATA_KEYS.email]: {
      value: '',
    },
    [FORM_DATA_KEYS.password]: {
      value: '',
    },
    [FORM_DATA_KEYS.confirmPass]: {
      value: '',
    },
  });

  const getTeams = React.useCallback(async () => {
    interface ITeamsData {
      id: 'b944bd31-7878-4313-bfa0-6928ff5d117c';
      name: 'dev';
    }

    try {
      const data = (await callApi({
        path: API_ROUTE_PATH.admins.teamsPath,
        method: 'get',
      })) as ITeamsData[];

      if (
        !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
      ) {
        console.log(data, 'error data');
        return setErrors(TEXT.someError);
      }

      const newTeams: typeof teams = [];

      data.forEach((itm) => {
        newTeams.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });
      setPermissionError(false);

      return setTeams(newTeams);
    } catch (e) {
      setErrors(`${e}`);
      setPermissionError(true);
      window.location.href = '/';
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getTeams();
    setSuccessMessage('');
    setErrors('');
    setLoading(false);
  }, [getTeams]);

  const saveTeamHandler = async (name: string) => {
    setLoading(true);
    interface IResp {
      id: string;
      name: string;
      created_at: Date;
      updated_at: Date;
    }
    try {
      const resp = (await callApi({
        path: API_ROUTE_PATH.admins.teamsPath,
        method: 'post',
        data: {
          name,
        },
      })) as IResp;

      if (!(resp.id || resp.name)) {
        console.log(resp, 'Error resp data');
        return setErrors('Error data' + TEXT.tryLater);
      }

      const selectD: SelectItemType = {
        title: resp.name,
        value: resp.id,
        id: resp.id,
      };

      setCurrentTeam(selectD);

      const newTeams = [...teams];
      newTeams.push(selectD);
      setTeams(newTeams);
    } catch (error) {
      setErrors(`${error}`);
    } finally {
      setLoading(false);
    }
  };
  
  const saveConsultantHandler = async () => {
    setErrors('');
    if (!currentTeam) return setErrors('Team does not selected');

    const email = form[FORM_DATA_KEYS.email].value;
    const first_name = form[FORM_DATA_KEYS.firstName].value;
    const last_name = form[FORM_DATA_KEYS.lastName].value;
    const password = form[FORM_DATA_KEYS.password].value;
    const confPass = form[FORM_DATA_KEYS.confirmPass].value;

    if (!validateEmail(email)) {
      return updateForm(FORM_DATA_KEYS.email, {
        value: email,
        errorText: TEXT.incorrectEmail,
      });
    }
    if (!first_name) {
      return updateForm(FORM_DATA_KEYS.firstName, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }
    if (!last_name) {
      return updateForm(FORM_DATA_KEYS.lastName, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }
    if (!password) {
      return updateForm(FORM_DATA_KEYS.password, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }
    if (!confPass) {
      return updateForm(FORM_DATA_KEYS.confirmPass, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }

    if (password !== confPass) {
      return setErrors('Error - Password mismatch');
    }
    setLoading(true);

    try {
      await callApi({
        path: API_ROUTE_PATH.admins.consultPath,
        method: 'post',
        data: {
          team_id: currentTeam.value,
          first_name,
          last_name,
          email: email.toLowerCase(),
          password,
        },
      });

      setCreatedConsultant({
        email: email.toLowerCase(),
        password,
      });

      updateForm(FORM_DATA_KEYS.firstName, {
        value: '',
      });
      updateForm(FORM_DATA_KEYS.lastName, {
        value: '',
      });
      updateForm(FORM_DATA_KEYS.email, {
        value: '',
      });
      updateForm(FORM_DATA_KEYS.password, {
        value: '',
      });
      updateForm(FORM_DATA_KEYS.confirmPass, {
        value: '',
      });
    } catch (e) {
      setErrors(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    teams,
    form,
    onChangeHandler,
    FORM_DATA_KEYS,
    currentTeam,
    setCurrentTeam,
    saveTeamHandler,
    saveConsultantHandler,
    permissionError,
    loading,
    errors,
    successMessage,
    createdConsultant,
    setCreatedConsultant,
  };
};
