import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import {EditPackage, GetAllPackages} from '../../../store/packages/actions';
import { TPackage } from '../../../store/packages/types';
import { callApi } from '../../../utils/callApi';
import { deepClone } from '../../../utils/deepClone';

export const usePackageGenLink = (pack: TPackage, workspaceID: string) => {
  const dispatch = useDispatch();
  const { Packages } = useSelector((store: AppStore) => store);
  const [link, setLink] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [expirationNum, setExpirationNum] = React.useState(30);
  const [triggerDaysAfter, setTriggerDaysAfter] = React.useState(30);

  const [errorText, setErrorText] = React.useState('');
  const oldExpirationNum = React.useRef(30);

  const [isForever, setIsForever] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(false);

  const [isSelectDefaultRectangle, setIsSelectDefaultRectangle] = React.useState(false);
  const [isSelectForeverRectangle, setIsSelectForeverRectangle] = React.useState(false);

  React.useEffect(() => {
    if (pack) {
      setIsForever(pack.is_forever)
      setIsPaused(pack.is_paused)
    }
    // eslint-disable-next-line
  }, [pack.is_paused, pack.is_forever]);

  React.useEffect(() => {
    (async () => {
      if (!pack.link_expiration && !pack.is_forever) return setLoading(false);
      try {
        setErrorText('');
        const data = await callApi({
          method: 'get',
          path: `/packages/${pack.id}/link`,
        });
        if (data && data.package_link) {
          setLink(
            window.location.origin +
              '/assessment-responding/' +
              data.package_link
          );
        }
        if (
          data &&
          data.days_difference !== null &&
          data.days_difference >= 0
        ) {
          setExpirationNum(data.days_difference);
          oldExpirationNum.current = data.days_difference;
        }
      } catch (e) {
        setLink('');
        setErrorText(`${e}`);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [pack.id, pack.link_expiration]);

  const handleClick = (id) => {
    switch (id) {
      case 'default':
        setIsSelectDefaultRectangle(true)
        setIsSelectForeverRectangle(false)
        setIsForever(false)
        break
      case 'forever':
        setIsSelectForeverRectangle(true)
        setIsSelectDefaultRectangle(false)
        setIsForever(true)
        break
      default:
        setIsSelectForeverRectangle(false)
        setIsSelectDefaultRectangle(false)
        break
    }
  };

  const genLink = async () => {
    if (expirationNum < 1)
      return setErrorText('Expiration number could not be lower then 1');
    try {
      setErrorText('');
      setLoading(true);
      const data = await callApi({
        method: 'post',
        path: `/packages/${pack.id}/link`,
        data: {
          // package_id: pack.id,
          is_forever: isForever,
          link_expiration: expirationNum,
        },
      });
      if (data && data.package_link) {
        setLink(
          window.location.origin + '/assessment-responding/' + data.package_link
        );
      }
      if (data && data.days_difference) {
        setExpirationNum(data.days_difference);
        oldExpirationNum.current = data.days_difference;
      }
      const newPacks = deepClone(Packages.data) as TPackage[];
      const newPack = newPacks.find((p) => p.id === pack.id);
      if (!newPack) return null;
      newPack.is_forever = data.is_forever;
      newPack.link_expiration = data.link_expiration;
      newPack.link_created_at = data.link_created_at;
      dispatch(EditPackage.success(newPacks));
    } catch (e) {
      setLink('');
      setErrorText(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  const removeLink = async () => {
    if (expirationNum < 1)
      return setErrorText('Expiration number could not be lower then 1');
    try {
      setErrorText('');
      setLoading(true);
      await callApi({
        method: 'put',
        path: `/packages/${pack.id}/link`,
        data: {
          // package_id: pack.id,
        },
      });
      setLink('');
      const newPacks = deepClone(Packages.data) as TPackage[];
      const newPack = newPacks.find((p) => p.id === pack.id);
      if (!newPack) return null;
      newPack.link_expiration = 0;
      newPack.is_forever = false;
      dispatch(EditPackage.success(newPacks));
    } catch (e) {
      setErrorText(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  const pauseLink = async () => {
    try {
      setErrorText('');
      setLoading(true);
      await callApi({
        method: 'put',
        path: `/packages/${pack.id}/link/is-paused`,
        data: {
          is_paused: !pack.is_paused,
        },
      });
      setIsPaused(!isPaused)
      dispatch(GetAllPackages.request({ workspaceId: workspaceID }));
      const newPacks = deepClone(Packages.data) as TPackage[];
      const newPack = newPacks.find((p) => p.id === pack.id);
      if (!newPack) return null;
      newPack.is_paused = !isPaused;
      dispatch(EditPackage.success(newPacks));

    } catch (e) {
      setErrorText(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    link,
    loading,
    genLink,
    errorText,
    expirationNum,
    setExpirationNum,
    triggerDaysAfter,
    setTriggerDaysAfter,
    oldExpirationNum,
    removeLink,
    isForever,
    isPaused,
    setIsForever,
    setIsPaused,
    pauseLink,
    handleClick,
    isSelectDefaultRectangle,
    isSelectForeverRectangle
  };
};
