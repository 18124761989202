import React from 'react';
import styled from 'styled-components';
import { AvatarIcon } from '../svgs';

interface UserImageProps {
  src: string;
}

export const UserImage: React.FC<UserImageProps> = ({ src }) => {
  return (
    <ImageS className="avatarImage">
      <AvatarIcon />
      {src !== '' && (
        <ProfileImageS
          style={{
            backgroundImage: `url("${src}")`,
          }}
        />
      )}
    </ImageS>
  );
};
const AvatarSize = '36px';

export const ImageS = styled.div`
  width: ${AvatarSize};
  height: ${AvatarSize};
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
  svg {
    width: 99%;
    height: 99%;
    path {
      fill: #929292;
    }
  }
`;
const ProfileImageS = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`;
