import React from 'react';
import styled from 'styled-components';
import { TLang } from '../../../types/lang';
import { checkLang } from '../../Responding/assLang';
import { ActiveIcon } from '../../svgs/ActiveIcon';
import { ButtonDefS } from './styles';
interface ButtonCollabChooseProps {
  isChoosen: boolean;
  disable: boolean;
  handleClick: () => void;
  lang: TLang;
  isFriendCollab?: boolean;
}
export const ButtonCollabChoose: React.FC<ButtonCollabChooseProps> = ({
  isChoosen,
  handleClick,
  disable,
  lang,
  isFriendCollab,
}) => {
  // prettier-ignore
  return (
    <ButtonCollabChooseS
      style={
        isChoosen
          ? {}
          : {
              background: '#f5f5fa',
              color: '#434352',
              borderColor: 'transparent',
            }
      }
      onClick={handleClick}
      disabled={disable}
    >
      <ButtonContentS>
        {isChoosen ? (
          <span>
            <ActiveIcon />
            {!isFriendCollab ? checkLang(lang).collaborations.collaborators.collab : checkLang(lang).collaborations.friendCollaborators.addedFriend}
          </span>
        ) : (
          `${!isFriendCollab ? checkLang(lang).collaborations.collaborators.addToCollab:checkLang(lang).collaborations.friendCollaborators.addToFriendCollab}`
        )}
      </ButtonContentS>
    </ButtonCollabChooseS>
  );
};

const ButtonCollabChooseS = styled(ButtonDefS)`
  border-radius: 18px;
  font-size: 14px;
  max-width: 205px;
  margin-right: 5px;
`;
const ButtonContentS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
