import React from 'react';
import styled from 'styled-components';
import { TTeam } from '../../store/team/types';
import { AvatarIcon } from '../svgs';
import { Alert, ButtonSave, Input } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import { useMyTeamInfo } from './hooks/useMyTeamInfo';

interface TeamSettingsProps {
  team: TTeam;
}

export const TeamSettings: React.FC<TeamSettingsProps> = () => {
  const hookMyTeamInfo = useMyTeamInfo();
  return (
    <>
      {hookMyTeamInfo.errors && <Alert text={hookMyTeamInfo.errors} />}
      <WrapperS>
        <WrapperImageTextS>
          <ImageWrapperS style={{ backgroundColor: 'white' }}>
            <AvatarIcon />
            {hookMyTeamInfo.image.src !== '' && (
              <ImageS
                style={{
                  backgroundImage: `url("${hookMyTeamInfo.image.src}")`,
                }}
              />
            )}
          </ImageWrapperS>
          <TextWrapperS>
            <p>Team picture</p>
            <input
              ref={hookMyTeamInfo.refInputUpload}
              onChange={() => {
                hookMyTeamInfo.onChangeUploadHandler(
                  hookMyTeamInfo.updateImageHandler
                );
              }}
              type="file"
              style={{ display: 'none' }}
            />
            <BtnsWrapperS>
              <ButtonBorderedS
                onClick={() => {
                  hookMyTeamInfo.clickUploadHandler();
                }}
              >
                Upload
              </ButtonBorderedS>
              {hookMyTeamInfo.image.src && (
                <ButtonBorderedS
                  onClick={() => {
                    hookMyTeamInfo.removeImageHandler(
                      hookMyTeamInfo.updateImageHandler
                    );
                  }}
                >
                  Remove
                </ButtonBorderedS>
              )}
            </BtnsWrapperS>
          </TextWrapperS>
        </WrapperImageTextS>
        <Input
          label="Name"
          name={hookMyTeamInfo.NAME}
          value={hookMyTeamInfo.getVal(hookMyTeamInfo.NAME)}
          errorText={hookMyTeamInfo.getError(hookMyTeamInfo.NAME)}
          onChange={hookMyTeamInfo.onChangeHandler}
        />
        <ButtonSave
          onClick={hookMyTeamInfo.saveHandler}
          loading={hookMyTeamInfo.loading}
        />
      </WrapperS>
    </>
  );
};

const WrapperS = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;
const WrapperImageTextS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ImageWrapperS = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  svg {
    width: 100%;
    height: auto;
    opacity: 0.5;
  }
`;

const ImageS = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`;

const TextWrapperS = styled.div`
  padding-left: 20px;
  p {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }
`;
const BtnsWrapperS = styled.div`
  display: flex;
  button {
    margin-right: 20px;
    height: 36px;
    min-height: 36px;
    min-width: 92px;
  }
`;
