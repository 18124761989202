import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GetCurrentChart } from '../../../store/analytics/actions';
import {
  EChartSelectType,
  EChartType,
  ETypeGraph,
  TChart,
  TDatum,
  TDatumData,
  TGraphOptions,
  TScatterGraphOptions,
} from '../../../store/analytics/types';
import { AppStore } from '../../../store/applicationState';
import {
  ALL_HIRING_TIME,
  ALL_PACKS,
  ALL_QUESTIONS,
  ALL_X_QUESTIONS,
  ALL_Y_QUESTIONS,
} from '../../../utils/consts';
import { DefaultSelect, Loader, TreeSelect } from '../../ui';
import { ButtonS } from '../../ui/buttons/ButtonArrow';
import { SelectItemType } from '../../ui/selects/DefaultSelect';
import { TreeSelectProps } from '../../ui/selects/TreeSelect';
import { genChartId } from '../../../utils/genChartId';
import { COLORS } from '../../../utils/colors';
import { deepClone } from '../../../utils/deepClone';

interface BlockChartFilterProps {
  index: number;
  chartIndex: number;
  graphType: ETypeGraph;
  disabledLabels: string[];
  legendHandler: (ids: string | string[]) => void;
  setDefaultLabels: (data: string[]) => void;
  isStackedChart?: boolean;
  tenureDisabled?: boolean;
  cyclesDisabled?: boolean;
  treeDisabled?: boolean;
  questions: TGraphOptions[] | TScatterGraphOptions;
  isRuns: boolean;
  setIsRuns: (isRuns: boolean) => void;
  setActiveQuestionItems: React.Dispatch<
    React.SetStateAction<
      | {
          x_axis_questions: string[];
          y_axis_questions: string[];
        }
      | string[]
    >
  >;
  activeQuestionItems:
    | {
        x_axis_questions: string[];
        y_axis_questions: string[];
      }
    | string[];
}

export const BlockChartFilter: React.FC<BlockChartFilterProps> = ({
  chartIndex,
  index,
  legendHandler,
  disabledLabels,
  setDefaultLabels,
  isStackedChart,
  isRuns,
  setIsRuns,
  tenureDisabled,
  cyclesDisabled,
  treeDisabled,
  graphType,
  questions,
  activeQuestionItems,
  setActiveQuestionItems,
}) => {
  const { Analytics, Workspaces } = useSelector((state: AppStore) => state);
  const [chart, setChart] = React.useState<TChart>(null);
  const [multiSelectItems, setMultiSelectItems] = React.useState<
    SelectItemType[]
  >([]);
  const [questionsSelectItems, setQuestionsSelectItems] = React.useState<
    SelectItemType[]
  >([]);
  const [xQuestionsSelectItems, setXQuestionsSelectItems] = React.useState<
    SelectItemType[]
  >([]);
  const [yQuestionsSelectItems, setYQuestionsSelectItems] = React.useState<
    SelectItemType[]
  >([]);
  // const [allXQuestions, setAllXQuestions] = React.useState<string[]>();
  // const [allYQuestions, setAllYQuestions] = React.useState<string[]>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [treeSelect, setTreeSelect] = React.useState<TreeSelectProps['data']>(
    []
  );
  const cashActiveSelects = React.useRef<string[]>([]);

  const dispatch = useDispatch();
  const hiringDateSelect: SelectItemType[] = [
    ALL_HIRING_TIME,
    {
      id: 'from_0_to_30_days',
      value: 'from_0_to_30_days',
      title: '0-30 days',
    },
    {
      id: 'from_31_to_60_days',
      value: 'from_31_to_60_days',
      title: '31-60 days',
    },
    {
      id: 'from_61_to_90_days',
      value: 'from_61_to_90_days',
      title: '61-90 days',
    },
    {
      id: 'from_0_to_90_days',
      value: 'from_0_to_90_days',
      title: '0-90 days',
    },
    {
      id: 'from_91_to_180_days',
      value: 'from_91_to_180_days',
      title: '91-180 days',
    },
    {
      id: 'from_0_to_1_years',
      value: 'from_0_to_1_years',
      title: '0-1 year',
    },
    {
      id: 'from_1_to_3_years',
      value: 'from_1_to_3_years',
      title: '1-3 years',
    },
    {
      id: 'from_3_to_6_years',
      value: 'from_3_to_6_years',
      title: '3-6 years',
    },
    { id: 'from_6_years', value: 'from_6_years', title: '6+ years' },
  ];
  const [activeHiringDate, setActiveHiringDate] = React.useState<
    SelectItemType[]
  >([ALL_HIRING_TIME]);
  const [activeSelectQuestion, setActiveSelectQuestion] = React.useState<
    SelectItemType[]
  >([ALL_QUESTIONS]);
  const [activeXSelectQuestion, setActiveXSelectQuestion] = React.useState<
    SelectItemType[]
  >([ALL_X_QUESTIONS]);
  const [activeYSelectQuestion, setActiveYSelectQuestion] = React.useState<
    SelectItemType[]
  >([ALL_Y_QUESTIONS]);
  const defaultHiringItems = {
    from_0_to_30_days: false,
    from_31_to_60_days: false,
    from_61_to_90_days: false,
    from_0_to_90_days: false,
    from_91_to_180_days: false,
    from_0_to_1_years: false,
    from_1_to_3_years: false,
    from_3_to_6_years: false,
    from_6_years: false,
  };
  const defaultQuestionItems: typeof Analytics.filters.params.questions_ids =
    graphType === ETypeGraph.type3
      ? { x_axis_questions: [], y_axis_questions: [] }
      : [];
  const [activeHiringItems, setActiveHiringItems] = React.useState<{
    [key: string]: boolean;
  }>(defaultHiringItems);

  const hiringDateSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_HIRING_TIME.value &&
      activeHiringDate[0] &&
      activeHiringDate[0].value === ALL_HIRING_TIME.value
    ) {
      return null;
    }
    const newData = deepClone(activeHiringDate) as SelectItemType[];
    const ass_ids: string[] = [];
    if (data.value === ALL_HIRING_TIME.value) {
      setActiveHiringItems(defaultHiringItems);
      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        defaultHiringItems,
        activeQuestionItems
      );
      setActiveHiringDate([ALL_HIRING_TIME]);
      return null;
    }
    let hasVal = false;
    if (newData[0] && newData[0].value === ALL_HIRING_TIME.value) {
      newData.splice(0, 1);
    }
    const newActiveHiringItems = { ...activeHiringItems };
    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        newActiveHiringItems[itm.value] = false;

        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_HIRING_TIME.value) {
        ass_ids.push(itm.value);
        newActiveHiringItems[itm.value] = true;
      }
    });

    if (!newData[0]) {
      newData.push(ALL_HIRING_TIME);
      setActiveHiringItems(defaultHiringItems);
      setActiveHiringDate(newData);
      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        defaultHiringItems,
        activeQuestionItems
      );
      return null;
    }
    getCharts(
      isRuns ? EChartType.cycles : EChartType.combined,
      newActiveHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
      activeQuestionItems
    );
    setActiveHiringItems(newActiveHiringItems);
    setActiveHiringDate(newData);
    return null;
  };

  const questionSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_QUESTIONS.value &&
      activeSelectQuestion[0] &&
      activeSelectQuestion[0].value === ALL_QUESTIONS.value
    ) {
      return null;
    }
    const newData = deepClone(activeSelectQuestion) as SelectItemType[];
    if (data.value === ALL_QUESTIONS.value) {
      setActiveQuestionItems(defaultQuestionItems);
      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
        defaultQuestionItems
      );
      setActiveSelectQuestion([ALL_QUESTIONS]);
      return null;
    }
    let hasVal = false;
    if (newData[0] && newData[0].value === ALL_QUESTIONS.value) {
      newData.splice(0, 1);
    }
    let newActiveQuestionItems = activeQuestionItems as string[];
    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        newActiveQuestionItems = newActiveQuestionItems.filter(
          (question) => question !== itm.value
        );

        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_QUESTIONS.value) {
        if (newActiveQuestionItems === undefined) {
          newActiveQuestionItems = [];
        }
        if (!newActiveQuestionItems.includes(itm.value))
          newActiveQuestionItems.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_QUESTIONS);
      setActiveQuestionItems(defaultQuestionItems);
      setActiveSelectQuestion(newData);
      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
        defaultQuestionItems
      );
      return null;
    }
    getCharts(
      isRuns ? EChartType.cycles : EChartType.combined,
      activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
      newActiveQuestionItems
    );
    setActiveQuestionItems(newActiveQuestionItems);
    setActiveSelectQuestion(newData);
    return null;
  };

  const xQuestionSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_X_QUESTIONS.value &&
      activeXSelectQuestion[0] &&
      activeXSelectQuestion[0].value === ALL_X_QUESTIONS.value
    ) {
      return null;
    }

    const newData = deepClone(activeXSelectQuestion) as SelectItemType[];

    if (data.value === ALL_X_QUESTIONS.value) {
      const currentItems = activeQuestionItems as {
        x_axis_questions: string[];
        y_axis_questions: string[];
      };

      setActiveQuestionItems({
        x_axis_questions: [],
        y_axis_questions: currentItems.y_axis_questions,
      });

      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
        {
          x_axis_questions: [],
          y_axis_questions: currentItems.y_axis_questions,
        }
      );

      setActiveXSelectQuestion([ALL_X_QUESTIONS]);
      return null;
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_X_QUESTIONS.value) {
      newData.splice(0, 1);
    }

    let newActiveQuestionItems = activeQuestionItems as {
      x_axis_questions: string[];
      y_axis_questions: string[];
    };

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);

        newActiveQuestionItems.x_axis_questions =
          newActiveQuestionItems.x_axis_questions.filter(
            (question) => question !== itm.value
          );

        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_X_QUESTIONS.value) {
        if (newActiveQuestionItems === undefined) {
          newActiveQuestionItems = {
            x_axis_questions: [],
            y_axis_questions: [],
          };
        }
        if (!newActiveQuestionItems.x_axis_questions.includes(itm.value))
          newActiveQuestionItems.x_axis_questions.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_X_QUESTIONS);
      const currentItems = activeQuestionItems as {
        x_axis_questions: string[];
        y_axis_questions: string[];
      };

      setActiveQuestionItems({
        x_axis_questions: [],
        y_axis_questions: currentItems.y_axis_questions,
      });
      setActiveXSelectQuestion(newData);

      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
        {
          x_axis_questions: [],
          y_axis_questions: currentItems.y_axis_questions,
        }
      );
      return null;
    }

    getCharts(
      isRuns ? EChartType.cycles : EChartType.combined,
      activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
      newActiveQuestionItems
    );
    setActiveQuestionItems(newActiveQuestionItems);
    setActiveXSelectQuestion(newData);
    return null;
  };

  const yQuestionSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_Y_QUESTIONS.value &&
      activeYSelectQuestion[0] &&
      activeYSelectQuestion[0].value === ALL_Y_QUESTIONS.value
    ) {
      return null;
    }

    const newData = deepClone(activeYSelectQuestion) as SelectItemType[];

    if (data.value === ALL_Y_QUESTIONS.value) {
      const currentItems = activeQuestionItems as {
        x_axis_questions: string[];
        y_axis_questions: string[];
      };

      setActiveQuestionItems({
        x_axis_questions: currentItems.x_axis_questions,
        y_axis_questions: [],
      });

      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
        {
          x_axis_questions: currentItems.x_axis_questions,
          y_axis_questions: [],
        }
      );

      setActiveYSelectQuestion([ALL_Y_QUESTIONS]);
      return null;
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_Y_QUESTIONS.value) {
      newData.splice(0, 1);
    }

    let newActiveQuestionItems = activeQuestionItems as {
      x_axis_questions: string[];
      y_axis_questions: string[];
    };

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        newActiveQuestionItems.y_axis_questions =
          newActiveQuestionItems.y_axis_questions.filter(
            (question) => question !== itm.value
          );

        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_Y_QUESTIONS.value) {
        if (newActiveQuestionItems === undefined) {
          newActiveQuestionItems = {
            x_axis_questions: [],
            y_axis_questions: [],
          };
        }
        if (!newActiveQuestionItems.y_axis_questions.includes(itm.value))
          newActiveQuestionItems.y_axis_questions.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_Y_QUESTIONS);
      const currentItems = activeQuestionItems as {
        x_axis_questions: string[];
        y_axis_questions: string[];
      };

      setActiveQuestionItems({
        x_axis_questions: currentItems.x_axis_questions,
        y_axis_questions: [],
      });

      setActiveYSelectQuestion(newData);

      getCharts(
        isRuns ? EChartType.cycles : EChartType.combined,
        activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
        {
          x_axis_questions: currentItems.x_axis_questions,
          y_axis_questions: [],
        }
      );
      return null;
    }

    getCharts(
      isRuns ? EChartType.cycles : EChartType.combined,
      activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
      newActiveQuestionItems
    );
    setActiveQuestionItems(newActiveQuestionItems);
    setActiveYSelectQuestion(newData);
    return null;
  };

  const updateTreeSelect = React.useCallback((chart: TChart) => {
    if (!chart) return null;

    const groups: TreeSelectProps['data'] = [];

    const ids: string[] = [];

    if (!chart.select_group.cycles.options) return null;

    chart.select_group.cycles.options.forEach((groupOption, i) => {
      groups.push({
        title: groupOption.group_name,
        id: groupOption.group_id,
        children: groupOption.cycles
          ? groupOption.cycles.map((cycle) => {
              const id = genChartId(
                cycle.package_name,
                cycle.package_id,
                cycle.cycle_date,
                groupOption.group_name,
                cycle.link,
                chart.data[0].period_of_hiring_date
              );
              ids.push(id);
              return {
                title: `${cycle.package_name} (${cycle.cycle_date})`,
                id,
              };
            })
          : [],
      });
    });
    cashActiveSelects.current = ids;
    setDefaultLabels(ids);
    setTreeSelect(groups);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    (() => {
      if (
        !Analytics.data ||
        !Analytics.data[index] ||
        !Analytics.data[index].charts ||
        !Analytics.data[index].charts[chartIndex]
      )
        return null;
      const newChart: TChart = {
        ...Analytics.data[index].charts[chartIndex],
      };

      if (!newChart) return null;

      setChart(newChart);

      if (newChart.view !== EChartType.cycles) return null;

      if (newChart.select_group.cycles.type !== EChartSelectType.m)
        return updateTreeSelect(newChart);

      if (isStackedChart) {
        const selectItems: SelectItemType[] = [ALL_PACKS];
        newChart.select_group.cycles.options.forEach((itm) =>
          selectItems.push({
            title: itm.package_name
              ? `${itm.package_name} ${itm.cycle_date}`
              : 'No Pack Name',
            id: genChartId(
              itm.package_name,
              itm.package_id,
              itm.cycle_date,
              '',
              itm.link || ''
            ),
            value: genChartId(
              itm.package_name,
              itm.package_id,
              itm.cycle_date,
              '',
              itm.link || ''
            ),
          })
        );
        return setMultiSelectItems(selectItems);
      }
      const selectItems: SelectItemType[] = [ALL_PACKS];
      (newChart.data as TDatum[]).forEach((itm, i) => {
        (itm.data as TDatumData[]).forEach((itm2) => {
          selectItems.push({
            title: itm2.package_name
              ? `${itm2.package_name} ${itm2.cycle_date}`
              : 'No Pack Name',
            id: genChartId(
              itm2.package_name,
              itm2.package_id,
              itm2.cycle_date,
              itm2.group_name,
              itm2.link,
              itm.period_of_hiring_date
            ),
            value: genChartId(
              itm2.package_name,
              itm2.package_id,
              itm2.cycle_date,
              itm2.group_name,
              itm2.link,
              itm.period_of_hiring_date
            ),
          } as SelectItemType);
        });
      });
      return setMultiSelectItems(selectItems);
    })();
    //eslint-disable-next-line
  }, [Analytics.data, index, chartIndex, updateTreeSelect, isStackedChart]);

  React.useEffect(() => {
    setIsRuns(false);
    //eslint-disable-next-line
  }, [Analytics.filters.params]);

  React.useEffect(() => {
    (() => {
      if (!questions) return null;
      if (graphType !== ETypeGraph.type3) {
        const newSelectQuestions: SelectItemType[] = [ALL_QUESTIONS];
        const newQuestions = questions as TGraphOptions[];
        newQuestions.forEach((question) => {
          newSelectQuestions.push({
            title: question.body,
            id: question.id,
            value: question.id,
          });
        });
        setQuestionsSelectItems(newSelectQuestions);
      } else {
        const newXSelectQuestions: SelectItemType[] = [ALL_X_QUESTIONS];
        const newYSelectQuestions: SelectItemType[] = [ALL_Y_QUESTIONS];
        // const newAllXQuestions: string[] = [];
        // const newAllYQuestions: string[] = [];
        const newQuestions = questions as TScatterGraphOptions;
        newQuestions.x_axis_questions.forEach((question) => {
          newXSelectQuestions.push({
            title: question.body,
            id: question.id,
            value: question.id,
          });
          // newAllXQuestions.push(question.id);
        });
        newQuestions.y_axis_questions.forEach((question) => {
          newYSelectQuestions.push({
            title: question.body,
            id: question.id,
            value: question.id,
          });
          // newAllYQuestions.push(question.id);
        });
        setXQuestionsSelectItems(newXSelectQuestions);
        setYQuestionsSelectItems(newYSelectQuestions);
        // setAllXQuestions(newAllXQuestions);
        // setAllYQuestions(newAllYQuestions);
      }
    })();
  }, [graphType, questions]);

  if (!chart) return null;

  const callBack = (success: boolean, data: TChart) => {
    setIsLoading(false);
    if (!success) return console.log('success false callBack');
    if (!data) return console.log('no data callBack');

    const newAnalyticsData = [...Analytics.data];

    newAnalyticsData[index].charts[chartIndex] = data;

    dispatch(GetCurrentChart.success(newAnalyticsData));
  };

  const getCharts = (
    type: EChartType,
    hiring_date: typeof Analytics.filters.params.period_of_hiring_date,
    questions: typeof Analytics.filters.params.questions_ids
  ) => {
    setIsRuns(type === EChartType.cycles);
    setIsLoading(true);
    const newData = { ...Analytics.filters.params };
    newData.period_of_hiring_date = hiring_date;
    if (graphType === ETypeGraph.type3) {
      const newQuestions = questions as {
        x_axis_questions: string[];
        y_axis_questions: string[];
      };
      // if (!newQuestions.x_axis_questions[0]) {
      //   newQuestions.x_axis_questions = allXQuestions;
      // }
      // if (!newQuestions.y_axis_questions[0]) {
      //   newQuestions.y_axis_questions = allYQuestions;
      // }
      newData.questions_ids = newQuestions;
    } else newData.questions_ids = questions;

    dispatch(
      GetCurrentChart.request({
        data: newData,
        uri: {
          workspaceId: Workspaces.current!.id,
          chartId: chart.id,
          type,
        },
        callBack,
      })
    );
  };
  return (
    <>
      <WrapperS>
        <SelectWrapperS>
          {chart.view === EChartType.cycles && !treeDisabled && (
            <div style={{ marginRight: '10px', width: '50%' }}>
              {chart.select_group.cycles.type === EChartSelectType.t && (
                <TreeSelect
                  activeSelects={disabledLabels}
                  data={treeSelect}
                  cashActiveSelects={cashActiveSelects.current}
                  clickHandler={(id) => legendHandler(id)}
                />
              )}

              {chart.select_group.cycles.type === EChartSelectType.m && (
                <DefaultSelect
                  isMultiple
                  placeHolder="Choose cycle"
                  data={multiSelectItems}
                  selected={ALL_PACKS}
                  activeSelects={
                    disabledLabels[0]
                      ? multiSelectItems.filter((sel) =>
                          disabledLabels.includes(`${sel.id}`)
                        )
                      : [ALL_PACKS]
                  }
                  onChange={(sel) => legendHandler(`${sel.id}`)}
                />
              )}
            </div>
          )}

          {!Analytics.filters.params.groups_ids[0] && !tenureDisabled && (
            <div style={{ marginRight: '10px', width: '50%' }}>
              <DefaultSelect
                isMultiple
                placeHolder="Choose hiring range"
                data={hiringDateSelect}
                selected={hiringDateSelect[0]}
                activeSelects={activeHiringDate}
                onChange={hiringDateSelectHandler}
              />
            </div>
          )}

          {graphType === ETypeGraph.type3 ? (
            <>
              <div style={{ marginRight: '10px', width: '50%' }}>
                <DefaultSelect
                  isMultiple
                  placeHolder="X Questions"
                  data={xQuestionsSelectItems}
                  selected={xQuestionsSelectItems[0]}
                  activeSelects={activeXSelectQuestion}
                  onChange={xQuestionSelectHandler}
                />
              </div>
              <div style={{ width: '50%' }}>
                <DefaultSelect
                  isMultiple
                  placeHolder="Y Questions"
                  data={yQuestionsSelectItems}
                  selected={yQuestionsSelectItems[0]}
                  activeSelects={activeYSelectQuestion}
                  onChange={yQuestionSelectHandler}
                />
              </div>
            </>
          ) : (
            <div style={{ width: '50%' }}>
              <DefaultSelect
                zIndex={1}
                isMultiple
                placeHolder="Questions"
                data={questionsSelectItems}
                selected={questionsSelectItems[0]}
                activeSelects={activeSelectQuestion}
                onChange={questionSelectHandler}

              />
            </div>
          )}
        </SelectWrapperS>

        {!cyclesDisabled && (
          <WrapperBtnsCycleS>
            <ButtonS
              onClick={() =>
                getCharts(
                  EChartType.combined,
                  activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
                  activeQuestionItems
                )
              }
            >
              {isLoading && !isRuns && <Loader isGreen size={0.5} />}
              <span
                style={{
                  opacity: isLoading && !isRuns ? 0 : 1,
                  color: !isRuns ? COLORS.accent : COLORS.default,
                }}
              >
                Combined
              </span>
            </ButtonS>
            <ButtonS
              onClick={() =>
                getCharts(
                  EChartType.cycles,
                  activeHiringItems as typeof Analytics.filters.params.period_of_hiring_date,
                  activeQuestionItems
                )
              }
            >
              {isLoading && isRuns && <Loader isGreen size={0.5} />}
              <span
                style={{
                  opacity: isLoading && isRuns ? 0 : 1,
                  color: isRuns ? COLORS.accent : COLORS.default,
                }}
              >
                Cycles
              </span>
            </ButtonS>
          </WrapperBtnsCycleS>
        )}
      </WrapperS>
      {/* <Loader isGreen /> */}
    </>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 70px;

  .ov-select-wr {
    padding: 5px 10px;
    height: 30px;
    font-size: 14px;
    .multiSpan {
      font-size: 14px;
    }
  }
`;

const WrapperBtnsCycleS = styled.div`
  display: flex;
  align-items: center;
  button {
    width: initial;
    margin: 0 5px;
    font-size: 12px;
    position: relative;
    &:first-child {
      &:after {
        content: '';
        display: block;
        width: 2px;
        background: ${COLORS.accent};
        height: 15px;
        margin-left: 10px;
      }
    }
  }
`;
const SelectWrapperS = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;
