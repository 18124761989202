import React from 'react';
import { useDropzone } from 'react-dropzone';
import { readString } from 'react-papaparse';
import { MAX_ATTACHMENT_FILE_SIZE } from '../../../utils/config';
export const useAttachFile = (disabled?: boolean) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [errorText, setErrorText] = React.useState('');
  const [isFileLoaded, setIsFileLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const onDrop = React.useCallback((acceptedFiles) => {
    setErrorText('');
    const file = acceptedFiles[0] as File | null;

    if (!file) return setErrorText('File is undefined');
    if (file.size > MAX_ATTACHMENT_FILE_SIZE * 1e6)
      return setErrorText(
        `File must be less than ${MAX_ATTACHMENT_FILE_SIZE} Mb.`
      );
    if (
      !file.name.includes('.xls') &&
      !file.name.includes('.xlsx') &&
      !file.name.includes('.doc') &&
      !file.name.includes('.docx') &&
      !file.name.includes('.pdf') &&
      !file.name.includes('.gif') &&
      !file.name.includes('.png') &&
      !file.name.includes('.jpg') &&
      !file.name.includes('.jpeg') &&
      !file.name.includes('.csv')
    )
      return setErrorText('Incorrect file format');

    const fileReader = new FileReader();

    const handleFileReader = () => {
      const content = fileReader.result;
      const results = readString(content as string, null) as any;
      if (results.data) setIsFileLoaded(true);
    };
    fileReader.onloadend = handleFileReader;
    fileReader.readAsText(file);
    console.log('-> file', file);
    setFile(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: disabled,
  });
  return {
    getRootProps,
    getInputProps,
    isDragActive,
    errorText,
    isFileLoaded,
    loading,
    file,
    setFile,
    setErrorText,
    setLoading,
  };
};
