import React from 'react';
import { useParams } from 'react-router-dom';
import { TRespIdentify, TRespUser } from '../../../pages/Responding/types';
import { ELinkType } from '../../../types/respondingTypes';
import { API_ROUTE_PATH } from '../../../utils/api_routes';
import { callApi } from '../../../utils/callApi';
import { TEXT } from '../../../utils/textConst';
import { SelectItemType } from '../../ui/selects/DefaultSelect';
import { DATA, DataValues, E_VALUES } from '../types';
import { TLang } from '../../../types/lang';
import { countryDial } from '../../../utils/countryDial';

export const useScreenAuth = (
  user: TRespUser | null,
  link_type: ELinkType,
  nextFn: (data: TRespIdentify) => void,
  lang: TLang
) => {
  const [selectData, setSelectData] = React.useState<SelectItemType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams<{ id: string }>();

  const getSelectData = (): SelectItemType[] => {
    const selData: SelectItemType[] = [];
    if (!user) return [];
    if (user.has_email) selData.push(DATA(lang)[DataValues.email]);
    if (user.has_payroll_id) selData.push(DATA(lang)[DataValues.payroll_id]);
    if (user.has_phone) selData.push(DATA(lang)[DataValues.phone_number]);
    return selData;
  };

  const [selected, setSelected] = React.useState<SelectItemType | null>(
    getSelectData()[0] || null
  );
  const [inputVal, setInputVal] = React.useState('');
  const [phoneCode, setPhoneCode] = React.useState(
    `${countryDial[234].flag} ${countryDial[234].dial_code}`
  );
  const [textError, setTextError] = React.useState('');
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSelectData(getSelectData());
    if (selectData[0]) {
      const index = selectData.findIndex((itm) => itm.id === selected.id);
      setSelected(getSelectData()[index]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, lang]);

  const submitHandler = async () => {
    if (!inputVal) return setTextError(TEXT.isRequired);
    setLoading(true);
    const dataToSend = {
      link: id,
      first_name: user!.first_name,
      last_name: user!.last_name,
      email:
        selected!.value === E_VALUES.email ? inputVal.trim().toLowerCase() : '',
      phone:
        selected!.value === E_VALUES.phone
          ? (phoneCode.split(' ')[1] + inputVal).trim()
          : '',
      payroll_id:
        selected!.value === E_VALUES.payroll_id ? inputVal.trim() : '',
      link_type: link_type,
    };
    setTextError('');

    try {
      const resp = (await callApi({
        path: API_ROUTE_PATH.participants.identify,
        method: 'post',
        data: dataToSend,
      })) as TRespIdentify;
      if (link_type === ELinkType.package) {
        if (!resp.package) throw Error('No package');
      }
      if (!resp.participant) throw Error('No participant');

      nextFn(resp);
    } catch (e) {
      setTextError(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    submitHandler,
    selected,
    selectData,
    textError,
    setSelected,
    setInputVal,
    loading,
    inputVal,
    phoneCode,
    setPhoneCode,
  };
};
