import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import { useUploadImage } from '../../../hooks/useUplodImage';
import { AppStore } from '../../../store/applicationState';
import { EditWorkspace } from '../../../store/workspaces/actions';
import {TAdditionalInfo, TWorkspace} from '../../../store/workspaces/types';
import { countryDial } from '../../../utils/countryDial';
import { deepClone } from '../../../utils/deepClone';
import { validateEmail } from '../../../utils/helpers';
import { TEXT } from '../../../utils/textConst';
import { DataWSFor } from '../../modals/data/workspaceData';
import {SelectItemType} from "../../ui/selects/DefaultSelect";
import {TypeOfGroupClassification} from "../../../utils/GroupClassificationList";
const WS_NAME = 'workspaceName';
const WS_SITE = 'companyWebsite';
const WS_CONTACT_FIRSTNAME = 'firstName';
const WS_CONTACT_LASTNAME = 'lastName';
const WS_CONTACT_EMAIL = 'email';
const WS_CONTACT_PHONECODE = 'phoneCode';
const WS_CONTACT_PHONE = 'phone';

export const useWorkspaceInfo = () => {
  const dispatch = useDispatch();
  const {
    image,
    onChangeUploadHandler,
    removeImageHandler,
    imageChanged,
    imageError,
    refInputUpload,
    setImage,
  } = useUploadImage();
  const [warning, setWarning] = React.useState('');
  const { Workspaces } = useSelector((store: AppStore) => store);
  const workspace = Workspaces.current;
  const [errors, setErrors] = React.useState('');
  const [isSuccess, setIsSuccess] =React.useState(false);

  const [chbData, setChbData] = React.useState<TAdditionalInfo>({
    formation_type: '',
    group_type: '',
    organization_size: '',
    organization_industry: '',
    organization_geographic_structure: '',
    organization_remote_work: '',
    organization_location: '',
    main_contact: {
      site_link: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_code: '',
      phone: '',
    },
  });

  const [groupClassificationList, setGroupClassificationList] = React.useState<SelectItemType[]>([]);
  const [selectedGroupClassification, setSelectedGroupClassification] = React.useState<SelectItemType>(null);

  const copyToClipBoard = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 2000);
  };

  const { form, updateForm, onChangeHandler } = useForm({
    [WS_NAME]: {
      value: '',
    },
    [WS_SITE]: {
      value: '',
    },
    [WS_CONTACT_FIRSTNAME]: {
      value: '',
    },
    [WS_CONTACT_LASTNAME]: {
      value: '',
    },
    [WS_CONTACT_EMAIL]: {
      value: '',
    },
    [WS_CONTACT_PHONECODE]: {
      value: '',
    },
    [WS_CONTACT_PHONE]: {
      value: '',
    },
  });
  const changePhoneHandler = (phone: string) => {
    updateForm(WS_CONTACT_PHONE, {
      value: Array.from(phone)[0] === '0' ? phone.substr(1) : phone,
    });
  };
  const changePhoneCodeHandler = (phoneCode: string) => {
    updateForm(WS_CONTACT_PHONECODE, {
      value: phoneCode,
    });
  };
  const changeEmailHandler = (email: string) => {
    if (validateEmail(email)) {
      updateForm(WS_CONTACT_EMAIL, {
        value: email,
        errorText: '',
      });
    } else {
      updateForm(WS_CONTACT_EMAIL, {
        value: email,
        errorText: 'Email is invalid',
      });
    }
  };
  React.useEffect(() => {
    setErrors(`${imageError}`);
  }, [imageError]);
  React.useEffect(() => {
    updateForm(WS_NAME, { value: workspace ? workspace.name : '' });
    updateForm(WS_SITE, {
      value:
        workspace &&
        workspace.additional_info &&
        workspace.additional_info.main_contact
          ? workspace.additional_info.main_contact.site_link
          : '',
    });
    updateForm(WS_CONTACT_FIRSTNAME, {
      value:
        workspace &&
        workspace.additional_info &&
        workspace.additional_info.main_contact
          ? workspace.additional_info.main_contact.first_name
          : '',
    });
    updateForm(WS_CONTACT_LASTNAME, {
      value:
        workspace &&
        workspace.additional_info &&
        workspace.additional_info.main_contact
          ? workspace.additional_info.main_contact.last_name
          : '',
    });
    updateForm(WS_CONTACT_EMAIL, {
      value:
        workspace &&
        workspace.additional_info &&
        workspace.additional_info.main_contact
          ? workspace.additional_info.main_contact.email
          : '',
    });
    updateForm(WS_CONTACT_PHONECODE, {
      value:
        workspace &&
        workspace.additional_info &&
        workspace.additional_info.main_contact &&
        workspace.additional_info.main_contact.phone_code
          ? workspace.additional_info.main_contact.phone_code
          : `${countryDial[234].flag} ${countryDial[234].dial_code}`,
    });
    // if (
    //   workspace &&
    //   workspace.additional_info &&
    //   workspace.additional_info.main_contact &&
    //   workspace.additional_info.main_contact.phone_code
    // )
    //   setPhoneCode(workspace.additional_info.main_contact.phone_code);
    updateForm(WS_CONTACT_PHONE, {
      value:
        workspace &&
        workspace.additional_info &&
        workspace.additional_info.main_contact
          ? workspace.additional_info.main_contact.phone
          : '',
    });

    if (workspace) {
      const {
        formation_type,
        group_type,
        organization_size,
        organization_industry,
        organization_geographic_structure,
        organization_remote_work,
        organization_location,
        main_contact,
      } = workspace.additional_info;

      setChbData({
        formation_type,
        group_type,
        organization_size,
        organization_industry,
        organization_geographic_structure,
        organization_remote_work,
        organization_location,
        main_contact,
      });
    }

    if (workspace && workspace.group_classification) {
      const Items: SelectItemType[] = []

      Object.keys(TypeOfGroupClassification).forEach((key) => {
        let grpClass: SelectItemType = {
          id: key,
          title: TypeOfGroupClassification[key],
          value: TypeOfGroupClassification[key],
        }

        Items.push(grpClass)

        if (key === workspace.group_classification) {
          setSelectedGroupClassification(grpClass)
        }
      });

      let startIndex = 1;
      let endIndex = Items.length - 1;

      let subArray = Items.slice(startIndex, endIndex + 1);

      subArray.sort((a, b) => a.title.localeCompare(b.title));

      Items.splice(startIndex, subArray.length, ...subArray);

      setGroupClassificationList(Items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  const selectHandler = (item: SelectItemType) => {
    setSelectedGroupClassification(item)
  };

  const radioHandler = (type: keyof typeof chbData, val: string) => {
    dispatch(EditWorkspace.error(''));
    if (type === 'main_contact') return null;
    const newData = { ...chbData };
    newData[type] = val;
    setChbData(newData);
  };

  const callBack = (success: boolean) => {
    if (success) {
      const allWorkspaces = deepClone(Workspaces.data) as TWorkspace[];
      dispatch(EditWorkspace.success(allWorkspaces));
    }
  };
  const updateImageHandler = (file: File | null, src: string) => {
    dispatch(
      EditWorkspace.request({
        workspaceId: Workspaces.current?.id || '',
        data: {
          name: form[WS_NAME].value,
          additional_info: chbData,
          group_classification: selectedGroupClassification.id as string,
          is_logo_changed: true,
          file,
        },
        src,
        callBack,
      })
    );
  };
  const saveBtnHandler = () => {
    const newName = form[WS_NAME].value;
    if (!newName) {
      return updateForm(WS_NAME, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }
    if (form[WS_CONTACT_EMAIL].value && form[WS_CONTACT_EMAIL].errorText) {
      return null;
    }
    const newData = { ...chbData };
    newData.main_contact.site_link = form[WS_SITE].value && form[WS_SITE].value;
    newData.main_contact.first_name =
      form[WS_CONTACT_FIRSTNAME].value && form[WS_CONTACT_FIRSTNAME].value;
    newData.main_contact.last_name =
      form[WS_CONTACT_LASTNAME].value && form[WS_CONTACT_LASTNAME].value;
    newData.main_contact.email =
      form[WS_CONTACT_EMAIL].value && form[WS_CONTACT_EMAIL].value;
    newData.main_contact.phone_code = form[WS_CONTACT_PHONE].value
      ? form[WS_CONTACT_PHONECODE].value &&
        form[WS_CONTACT_PHONECODE].value.includes(' ')
        ? form[WS_CONTACT_PHONECODE].value.split(' ')[1]
        : form[WS_CONTACT_PHONECODE].value
      : '';
    newData.main_contact.phone = form[WS_CONTACT_PHONE].value
      ? form[WS_CONTACT_PHONE].value
      : '';

    editWorkspaceHandler(form[WS_NAME].value, newData, selectedGroupClassification.id as string, false, image.file);
  };

  const clickUploadHandler = () => {
    if (!refInputUpload.current) return null;
    dispatch(EditWorkspace.error(''));
    refInputUpload.current!.click();
  };
  React.useEffect(() => {
    if (workspace?.logo_url) {
      setImage({
        src: workspace.logo_url,
        file: null,
      });
    } else
      setImage({
        src: '',
        file: null,
      });
    // eslint-disable-next-line
  }, [Workspaces]);
  const editWorkspaceHandler = (
    value: string,
    additional_info: TAdditionalInfo,
    group_classification: string,
    is_logo_changed: boolean,
    file: File | null
  ) => {
    setWarning('');
    if (!Workspaces.data) return setWarning('no Workspaces data');
    const allWorkspaces = deepClone(Workspaces.data) as TWorkspace[];
    const workspace = allWorkspaces.find(
      (ws) => `${ws.id}` === `${Workspaces.current?.id || ''}`
    );
    if (!workspace) return setWarning('Could not find workspace');
    if (chbData.formation_type === DataWSFor[1].label) {
      chbData.organization_industry = '';
      chbData.organization_geographic_structure = '';
      chbData.organization_remote_work = '';
      chbData.organization_location = '';
    } else {
      chbData.group_type = '';
    }
    workspace.name = value;
    workspace.additional_info = chbData;
    workspace.group_classification = group_classification;
    workspace.logo_url = image.src;
    const callBack = (success: boolean) => {
      if (success) {
        dispatch(EditWorkspace.success(allWorkspaces));
        dispatch(EditWorkspace.setWorkspace(workspace));
        copyToClipBoard()
      }
    };

    return dispatch(
      EditWorkspace.request({
        workspaceId: Workspaces.current?.id || '',
        data: {
          name: value,
          additional_info: additional_info,
          group_classification: selectedGroupClassification.id as string,
          is_logo_changed,
          file,
        },
        src: image.src,
        callBack,
      })
    );
  };

  return {
    chbData,
    warning,
    editWorkspaceHandler,
    workspace: Workspaces.current,
    isLoading: Workspaces.loading,
    imageChanged,
    saveBtnHandler,
    updateImageHandler,
    onChangeUploadHandler,
    clickUploadHandler,
    errors: Workspaces.errors || errors,
    onChangeHandler,
    changePhoneHandler,
    changePhoneCodeHandler,
    changeEmailHandler,
    refInputUpload,
    removeImageHandler,
    image,
    nameValue: form[WS_NAME].value,
    nameError: form[WS_NAME].errorText,
    siteValue: form[WS_SITE].value,
    siteError: form[WS_SITE].errorText,
    firstNameValue: form[WS_CONTACT_FIRSTNAME].value,
    firstNameError: form[WS_CONTACT_FIRSTNAME].errorText,
    lastNameValue: form[WS_CONTACT_LASTNAME].value,
    lastNameError: form[WS_CONTACT_LASTNAME].errorText,
    emailValue: form[WS_CONTACT_EMAIL].value,
    emailError: form[WS_CONTACT_EMAIL].errorText,
    phoneValue: form[WS_CONTACT_PHONE].value,
    phoneCodeValue: form[WS_CONTACT_PHONECODE].value,
    phoneError: form[WS_CONTACT_PHONE].errorText,
    phoneCodeError: form[WS_CONTACT_PHONECODE].errorText,
    inputName: WS_NAME,
    inputSite: WS_SITE,
    inputContactFirst: WS_CONTACT_FIRSTNAME,
    inputContactLast: WS_CONTACT_LASTNAME,
    inputContactEmail: WS_CONTACT_EMAIL,
    inputContactPhone: WS_CONTACT_PHONE,
    inputContactPhoneCode: WS_CONTACT_PHONECODE,
    radioHandler,
    groupClassificationList,
    selectedGroupClassification,
    selectHandler,
    isSuccess
  };
};
