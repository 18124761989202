import { ELinkType } from '../../../types/respondingTypes';
import { API_ROUTE_PATH } from '../../../utils/api_routes';
import { callApi } from '../../../utils/callApi';
import { TRespUser } from '../types';

interface ICbData {
  users: TRespUser[] | null;
  success: boolean;
  errorText?: string;
}
export const getUsers = async (data: {
  id: string;
  linkType: ELinkType;
  cb?: (data: ICbData) => void;
}) => {
  const { id, linkType } = data;
  let success = true;

  let cbData: ICbData = {
    users: null,
    success: false,
    errorText: '',
  };

  try {
    const resp = await callApi({
      method: 'get',
      path: `${API_ROUTE_PATH.participants.gAllByLink}/${id}/${linkType}`,
    });
    cbData = {
      users: resp,
      success,
    };
    return cbData;
  } catch (error) {
    console.log(error, 'error');
    success = false;
    cbData = {
      users: null,
      success,
      errorText: `${error}`,
    };
    return cbData;
  } finally {
    if (data.cb) data.cb(cbData);
  }
};
