import React from 'react';

interface LinkIconProps {
  color?: string;
}

export const LinkIcon: React.FC<LinkIconProps> = ({ color = '#000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" opacity="1">
        <g fill={color}>
          <g>
            <g>
              <path
                d="M22.715 14.853c1.22-.016 2.388.487 3.196 1.377.342.374.35.935.017 1.318l-.012.015c-.194.225-.478.357-.78.36-.305.005-.597-.12-.799-.343-.07-.077-.145-.148-.226-.214-.691-.563-1.676-.63-2.44-.166-.178.107-.338.24-.475.394l-.02.02-2.6 3.029c-.361.422-.532.969-.473 1.515.06.547.343 1.047.787 1.386.914.667 2.205.516 2.931-.342l1.099-1.28c.371-.432 1.033-.49 1.477-.128.445.361.504 1.005.133 1.437l-1.095 1.276c-.652.753-1.556 1.26-2.553 1.429-.13.022-.259.038-.39.049-1.108.095-2.21-.25-3.052-.958-1.718-1.459-1.928-3.984-.473-5.693l2.593-3.02c.782-.91 1.935-1.444 3.155-1.46zm1.912-3.398c1.47-1.697 4.06-1.944 5.841-.559l.18.15c.761.673 1.238 1.6 1.332 2.602.11 1.112-.24 2.22-.972 3.08l-2.55 2.97c-.492.574-1.138 1.005-1.867 1.244-.206.068-.417.12-.632.157-1.393.234-2.813-.232-3.777-1.238-.393-.413-.369-1.057.055-1.441.423-.384 1.086-.361 1.48.05.68.71 1.783.854 2.63.342.178-.107.338-.24.475-.394l.024-.025 2.556-2.978c.739-.848.662-2.11-.175-2.867-.422-.365-.979-.547-1.542-.507-.563.04-1.086.301-1.448.722l-1.23 1.432c-.24.28-.616.414-.985.351-.369-.062-.676-.312-.805-.654s-.06-.726.18-1.005z"
                transform="translate(-1162 -274) translate(288 248) translate(858 16)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
