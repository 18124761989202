import { Reducer } from 'redux';
import ActionTypes, { TMatchingTemplatesState } from './types';

export const initialState: TMatchingTemplatesState = {
  current: null,
  loading: true,
  errors: undefined,
};

const reducer: Reducer<TMatchingTemplatesState> = (
  state = initialState,
  action
): TMatchingTemplatesState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };

    case ActionTypes.GET_ONE_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        current: action.payload,
      };
    case ActionTypes.GET_ONE_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { reducer as MatchingTemplatesReducer };
