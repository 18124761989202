import { TLang } from '../../types/lang';
import { checkLang } from '../../components/Responding/assLang';

export const DEFAULT_STEPS_COUNT = 3;

export const ANSWERS_DATA = (lang: TLang) => [
  {
    id: 1,
    title: checkLang(lang).assessments.answers['1'],
    value: 1,
  },
  {
    id: 2,
    title: checkLang(lang).assessments.answers['2'],
    value: 2,
  },
  {
    id: 3,
    title: checkLang(lang).assessments.answers['3'],
    value: 3,
  },
  {
    id: 4,
    title: checkLang(lang).assessments.answers['4'],
    value: 4,
  },
  {
    id: 5,
    title: checkLang(lang).assessments.answers['5'],
    value: 5,
  },
  {
    id: 6,
    title: checkLang(lang).assessments.answers['0'],
    value: 0,
  },
];

export const ANSWERS_DATA_REVERSED = (lang: TLang) => [
  {
    id: 1,
    title: checkLang(lang).assessments.answers['1'],
    value: 5,
  },
  {
    id: 2,
    title: checkLang(lang).assessments.answers['2'],
    value: 4,
  },
  {
    id: 3,
    title: checkLang(lang).assessments.answers['3'],
    value: 3,
  },
  {
    id: 4,
    title: checkLang(lang).assessments.answers['4'],
    value: 2,
  },
  {
    id: 5,
    title: checkLang(lang).assessments.answers['5'],
    value: 1,
  },
  {
    id: 6,
    title: checkLang(lang).assessments.answers['0'],
    value: 0,
  },
];

export const ANSWERS_DATA_TEN = (lang: TLang) => [
  {
    id: 1,
    title: '1',
    value: 1,
  },
  {
    id: 2,
    title: '2',
    value: 2,
  },
  {
    id: 3,
    title: '3',
    value: 3,
  },
  {
    id: 4,
    title: '4',
    value: 4,
  },
  {
    id: 5,
    title: '5',
    value: 5,
  },
  {
    id: 6,
    title: '6',
    value: 6,
  },
  {
    id: 7,
    title: '7',
    value: 7,
  },
  {
    id: 8,
    title: '8',
    value: 8,
  },
  {
    id: 9,
    title: '9',
    value: 9,
  },
  {
    id: 10,
    title: '10',
    value: 10,
  },
  {
    id: 11,
    title: checkLang(lang).assessments.answers['0'],
    value: 0,
  },
];
