import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import { AppStore } from '../../../store/applicationState';
import { getMyR } from '../../../store/team/actions';
import { getMeR } from '../../../store/user/actions';
import { TSubmitForm } from '../../../types/form';
import { MY_EMAIL, MY_PASS } from '../../../utils/config';
import { getToken } from '../../../utils/getToken';
import { delay, validateEmail } from '../../../utils/helpers';
import { TEXT } from '../../../utils/textConst';

export const useLogin = (EMAIL: string, PASS: string) => {
  const { User } = useSelector((store: AppStore) => store);
  const [warning, setWarning] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { form, onChangeHandler, updateForm } = useForm({
    [EMAIL]: {
      value: MY_EMAIL,
    },
    [PASS]: {
      value: MY_PASS,
    },
  });

  React.useEffect(() => {
    return () => {};
  }, []);
  const setErrors = {
    email: (text: string) => {
      updateForm(EMAIL, {
        value: form[EMAIL].value,
        errorText: text,
      });
    },
    password: (text: string) => {
      updateForm(PASS, {
        value: form[PASS].value,
        errorText: text,
      });
    },
  };

  const submitHandler = async (event: TSubmitForm) => {
    event.preventDefault();
    setWarning('');
    if (User.loading) {
      setWarning(TEXT.endRequest);
      await delay(1500);
      return setWarning('');
    }
    const email = form[EMAIL].value.toLowerCase().trim();
    const pass = form[PASS].value;

    if (!email && !pass) {
      setErrors.email(TEXT.isRequired);

      return setErrors.password(TEXT.isRequired);
    }
    if (!email) {
      setErrors.email(TEXT.isRequired);
      return null;
    }
    const isEmail = validateEmail(email);
    if (!isEmail) return setErrors.email(TEXT.incorrectEmail);

    if (!pass) {
      return setErrors.password(TEXT.isRequired);
    }
    setLoading(true);

    const data = await getToken(email, pass);

    if (data && data.status) {
      console.log(data, 'Promise<TSuccess | TError>');
      setLoading(false);
      return setWarning(data.detail || '');
    }

    return dispatch(getMeR(() => dispatch(getMyR(() => setLoading(false)))));
  };

  return {
    submitHandler,
    onChangeHandler,
    form,
    warning,
    loading,
  };
};
