import { TQuestionType } from '../assessments/types';
import { TCollabAssessment } from '../collaborations/types';

export interface TDatumData {
  group_id: string;
  group_name: string;
  package_id?: string;
  package_name?: string;
  cycle_date?: string;
  link?: string;
  value_avg: number;
}
export interface TDatum {
  package_name: string;
  group_name: string;
  package_id?: string;
  cycle_date?: string;
  link?: string;
  value_avg: number;
  data?: TDatumData[];
  period_of_hiring_date: string;
}

export interface TLineScatterData {
  group_id: string;
  group_name: string;
  package_id?: string;
  package_name?: string;
  cycle_date?: string;
  link?: string;
  value: number;
}

export interface TLineScatter {
  package_name: string;
  group_name: string;
  package_id?: string;
  cycle_date?: string;
  link?: string;
  value: number;
  data?: TLineScatterData[];
  period_of_hiring_date: string;
}
export interface TStakedDataData {
  question_id: string;
  question_body: string;
  question_index: number;
  question_benchmark_value?: string;
  question_answer_types?: TQuestionType;
  value1: number;
  value2: number;
  value3: number;
  value4: number;
  value5: number;
  cycles: {
    package_id: string;
    passes?: number | null;
    link: string;
    package_name: string;
    cycle_date: string;
    value1: number;
    value2: number;
    value3: number;
    value4: number;
    value5: number;
  }[];
}
export interface TStackedData {
  data?: TStakedDataData[];
  question_id?: string;
  question_body?: string;
  question_benchmark_value?: string;
  question_answer_types?: TQuestionType;
  question_index?: number;
  value1?: number;
  value2?: number;
  value3?: number;
  value4?: number;
  value5?: number;
  cycles?: {
    package_id: string;
    passes?: number | null;
    link: string;
    package_name: string;
    cycle_date: string;
    value1: number;
    value2: number;
    value3: number;
    value4: number;
    value5: number;
  }[];
  period_of_hiring_date: string;
}

export interface TScatterData {
  id: string;
  name: string;
  points: {
    body: string;
    x: number;
    y: number;
  }[];
  period_of_hiring_date?: string;
  // data?: {
  //   id: string;
  //   name: string;
  //   points: {
  //     body: string;
  //     x: number;
  //     y: number;
  //   }[];
  // }[];
}

export enum ETypeGraph {
  type1 = 'horizontal_bar_chart',
  type2 = 'stacked_horizontal_bar_chart',
  type3 = 'scatter_chart',
  type4 = 'horizontal_line_scatter_chart',
}

export enum EChartSelectType {
  m = 'multi',
  t = 'tree',
}
export interface TMetaData {
  x_axis_name?: string;
  x_axis_answer_type?: EAxisAnswerType;
  y_axis_name?: string;
  y_axis_answer_type?: EAxisAnswerType;
  overall_average_point: {
    body: string;
    x: number;
    y: number;
  };
  answer_types: TQuestionType;
}

export enum EAxisAnswerType {
  five = 'one_to_five',
  five_reverse = 'one_to_five_reversed',
  ten = 'one_to_ten',
}

export interface TGraphOptions {
  id: string;
  body: string;
  index: number;
  answer_type?: TQuestionType;
}

export interface TScatterGraphOptions {
  x_axis_questions: TGraphOptions[];
  y_axis_questions: TGraphOptions[];
}

export interface TSelectGroup {
  cycles: {
    options: {
      package_id?: string;
      package_name?: string;
      cycle_date?: string;
      link?: string;
      cycles?: {
        package_id: string;
        package_name: string;
        cycle_date: string;
        link: string;
      }[];
      group_id?: string;
      group_name?: string;
    }[];
    type: EChartSelectType;
  };
  questions: {
    options: TGraphOptions[] | TScatterGraphOptions;
    type: EChartSelectType;
  };
}
export interface TChart {
  id: string;
  name: string;
  type: ETypeGraph;
  select_group: TSelectGroup;
  position: number;
  is_saved: boolean;
  origin: 'default' | 'custom';
  view: EChartType;
  // select_type: EChartSelectType;
  assessments_ids: string[];
  settings?: TChartFilter | null;
  meta_data?: TMetaData;
  data: TDatum[] | TStackedData[] | TScatterData[] | TLineScatter[];
}

export interface TAnalyticsRow {
  id: string;
  name: string;
  origin: 'default' | 'custom';
  charts: TChart[];
}

export interface TChartParams {
  opened_visualizations: string[];
  params: TChartFilter;
}

export interface TChartFilter {
  is_all_assessments: boolean;
  is_all_participants: boolean;
  period_of_answer_date: {
    is_all_time: boolean;
    is_last_month: boolean;
    is_last_quarter: boolean;
    is_last_year: boolean;
    start_date: string;
    end_date: string;
  };
  is_all_time?: boolean;
  is_last_month?: boolean;
  is_last_quarter?: boolean;
  is_last_year?: boolean;
  start_date?: string;
  end_date?: string;
  period_of_hiring_date?: {
    from_0_to_30_days: boolean;
    from_31_to_60_days: boolean;
    from_61_to_90_days: boolean;
    from_0_to_90_days: boolean;
    from_91_to_180_days: boolean;
    from_0_to_1_years: boolean;
    from_1_to_3_years: boolean;
    from_3_to_6_years: boolean;
    from_6_years: boolean;
  };
  assessments_ids?: string[];
  assessments?: TCollabAssessment[];
  groups_ids: string[];
  questions_ids?:
    | { x_axis_questions: string[]; y_axis_questions: string[] }
    | string[];
  groupsNames?: string[] | null;
  assesmNames?: string[] | null;
  percentiles?: {
    start_range: number;
    end_range: number;
  };
  connectedness_score?: number;
  participantName?: string;
  activeRuns?: string[] | null;
}

export interface TAnalyticsState {
  loading: boolean;
  data: TAnalyticsRow[] | null;
  filters: TChartParams;
  errors?: string | undefined;
  loadingOne?: boolean;
  createErrors?: string | undefined;
}

export enum EChartType {
  cycles = 'cycles',
  combined = 'combined',
}

enum ActionTypes {
  GET_CHARTS_R = '@@analytics/GET_CHARTS_R',
  GET_CHARTS_S = '@@analytics/GET_CHARTS_S',
  GET_CHARTS_E = '@@analytics/GET_CHARTS_E',

  GET_CHARTS_BLOCK_R = '@@analytics/GET_CHARTS_BLOCK_R',
  GET_CHARTS_BLOCK_S = '@@analytics/GET_CHARTS_BLOCK_S',
  GET_CHARTS_BLOCK_E = '@@analytics/GET_CHARTS_BLOCK_E',

  GET_CHARTS_BY_TEAM_R = '@@analytics/GET_CHARTS_BY_TEAM_R',
  GET_CHARTS_BY_TEAM_S = '@@analytics/GET_CHARTS_BY_TEAM_S',
  GET_CHARTS_BY_TEAM_E = '@@analytics/GET_CHARTS_BY_TEAM_E',

  CREATE_CUSTOM_GLOBAL_GRAPH_R = '@@analytics/CREATE_CUSTOM_GLOBAL_GRAPH_R',
  CREATE_CUSTOM_GLOBAL_GRAPH_S = '@@analytics/CREATE_CUSTOM_GLOBAL_GRAPH_S',
  CREATE_CUSTOM_GLOBAL_GRAPH_E = '@@analytics/CREATE_CUSTOM_GLOBAL_GRAPH_E',

  CREATE_CUSTOM_GRAPH_R = '@@analytics/CREATE_CUSTOM_GRAPH_R',
  CREATE_CUSTOM_GRAPH_S = '@@analytics/CREATE_CUSTOM_GRAPH_S',
  CREATE_CUSTOM_GRAPH_E = '@@analytics/CREATE_CUSTOM_GRAPH_E',

  GET_CHART_CURRENT_R = '@@analytics/GET_CHART_CURRENT_R',
  GET_CHART_CURRENT_S = '@@analytics/GET_CHART_CURRENT_S',
  GET_CHART_CURRENT_E = '@@analytics/GET_CHART_CURRENT_E',

  EDIT_GRAPH_R = '@@analytics/EDIT_GRAPH_R',
  EDIT_GRAPH_S = '@@analytics/EDIT_GRAPH_S',
  EDIT_GRAPH_E = '@@analytics/EDIT_GRAPH_E',

  DELETE_GRAPH_R = '@@analytics/DELETE_GRAPH_E',
  DELETE_GRAPH_S = '@@analytics/DELETE_GRAPH_S',
  DELETE_GRAPH_E = '@@analytics/DELETE_GRAPH_E',

  DELETE_CHART_CURRENT_R = '@@analytics/DELETE_CHART_CURRENT_R',
  DELETE_CHART_CURRENT_S = '@@analytics/DELETE_CHART_CURRENT_S',
  DELETE_CHART_CURRENT_E = '@@analytics/DELETE_CHART_CURRENT_E',

  ADD_CHART_TO_STORY_R = '@@analytics/ADD_CHART_TO_STORY_R',
  ADD_CHART_TO_STORY_S = '@@analytics/ADD_CHART_TO_STORY_S',
  ADD_CHART_TO_STORY_E = '@@analytics/ADD_CHART_TO_STORY_E',

  UPDATE_GRAPHS = '@@analytics/UPDATE_GRAPHS',

  SET_CHART_FILTERS = '@@analytics/SET_CHART_FILTERS',

  SET_OPENED_CHARTS = '@@analytics/SET_OPENED_CHARTS',
}

export default ActionTypes;
