import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/applicationState';
import { callApi } from '../../../../utils/callApi';
import { API_ROUTE_PATH } from '../../../../utils/api_routes';
import { updateGraphs } from '../../../../store/analytics/actions';
import { TStackedData } from '../../../../store/analytics/types';
import { setInfoModal } from '../../../../store/modal/actions';

interface IRatesStacked {
  question_body: string;
  question_id: string;
  question_index: string;
  cycles: {
    package_id: string;
    package_name: string;
    cycle_date: string;
    link: string;
    number_of_pass: number;
  }[];
}

export const useShowPassHandler = (chartIndex: number, indexRow: number) => {
  const { Analytics, Workspaces } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const showPassHandler = async () => {
    if (!Analytics.data) return null;
    if (!Workspaces.current) return null;
    const newAnalyticsData = [...Analytics.data];
    if (!newAnalyticsData[indexRow]) return null;
    if (!newAnalyticsData[indexRow].charts[chartIndex]) return null;

    const chart = newAnalyticsData[indexRow].charts[chartIndex];

    const newFilters = Analytics.filters;
    newFilters.params.questions_ids = [];
    try {
      const resp = (await callApi({
        method: 'post',
        path: API_ROUTE_PATH.chart.getParticipationRate(
          Workspaces.current.id,
          chart.id,
          true
        ),
        data: newFilters.params,
      })) as Promise<IRatesStacked[]>;

      (chart.data as TStackedData[]).forEach((row) => {
        row.data.forEach((data) => {
          data.cycles.forEach((cycles, c) => {
            if (resp[data.question_index-1].cycles[c] && cycles.link === resp[data.question_index-1].cycles[c].link)
              data.cycles[c].passes = resp[data.question_index-1].cycles[c].number_of_pass;
          });
        });
      });
      dispatch(updateGraphs(newAnalyticsData));
    } catch (e) {
      dispatch(
        setInfoModal({
          // title: 'Error',
          isError: true,
          mess: `${e}`,
        })
      );
    }
  };
  return { showPassHandler };
};
