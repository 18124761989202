import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS_RGBA } from '../../utils/colors';
import { CheckIcon } from '../svgs';

interface CopySuccessProps {
  text?: string;
}

export const CopySuccess: React.FC<CopySuccessProps> = ({
  text = 'Link copied',
}) => {
  return (
    <CopySuccessS className={AnimatedClasses.bounceIn}>
      <CheckIcon /> {text}
    </CopySuccessS>
  );
};

const CopySuccessS = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS_RGBA.default(0.9)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 5px 16px;
  position: absolute;
  bottom: 24px;
  height: 43px;
  right: 24px;
  border-radius: 8px;
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    path {
      fill: ${COLORS_RGBA.accent(1)};
    }
  }
`;
