export const VALUES = {
  delete: 'delete',
  edit: 'edit',
  move: 'move',
  more: 'more',
  send: 'send',
  triggered: 'triggered',
  archive: 'archive',
  unarchive: 'unarchive',
  schedule: 'schedule',
  view: 'view',
};
