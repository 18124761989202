import { convertToDoubleStr } from './convertToDoubleStr';

export const getFormatDate = (date: Date, endDate?: boolean) => {
  const y = date.getFullYear();
  const m = convertToDoubleStr(`${date.getMonth() + 1}`);
  const d = convertToDoubleStr(`${date.getDate()}`);

  const time = endDate ? '23:59:59' : '00:00:00';

  return `${y}-${m}-${d}T${time}+00:00`;
};
