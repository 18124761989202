import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { validateEmail } from '../../../utils/helpers';
import { getUniqueId } from '../../../utils/uniqueId';
import { defFields, IParticipantsInputs } from '../types/AddParticipantsTypes';
const getId = getUniqueId();

type TP = {
  [key: string]: string;
};

export const useConsultants = (payload: {
  closeModal: () => void;
  defFieldsCount?: number;
}) => {
  const [newConsultants, setNewConsultants] = React.useState<TP[]>([]);
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [newConsultantsWithErrors, setNewConsultantsWithErrors] =
    React.useState<TP[]>([]);
  const [checkAgain, setCheckAgain] = React.useState(false);
  const [warning, setWarning] = React.useState('');
  // const dispatch = useDispatch();

  const addConsultant = () => {
    setNewConsultants(() => [
      ...newConsultants,
      {
        ...defFields,
        id: `${getId.next().value}`,
        workspace_id: Workspaces.current?.id || '',
      },
    ]);
  };

  React.useEffect(() => {
    const newParts: TP[] = [];

    const fc = payload.defFieldsCount ? payload.defFieldsCount : 4;
    for (let i = 0; i < fc; i++) {
      newParts.push({
        ...defFields,
        id: `${getId.next().value}`,
        workspace_id: Workspaces.current?.id || '',
      });
    }
    setNewConsultants(newParts);
  }, [Workspaces, payload.defFieldsCount]);

  const onChangeHandler = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>,
    withError?: boolean
  ) => {
    event.preventDefault();
    const { name, value } = event.currentTarget;
    let newData = [...newConsultants];
    if (withError) {
      newData = [...newConsultantsWithErrors];
    }
    if (!name) return null;

    const objName = name.split('-')[0];
    const position = name.split('-')[1];

    const consultant = newData[Number(position)];

    if (!consultant) return null;
    if (!(objName in consultant)) return null;

    consultant[objName] = value;
    if (withError) {
      return setNewConsultantsWithErrors(newData);
    } else {
      return setNewConsultants(newData);
    }
  };

  const removeConsultant = (position: number, withErrors?: boolean) => {
    let data = [...newConsultants];

    if (withErrors) {
      data = [...newConsultantsWithErrors];
    }

    if (!data[1]) return setNewConsultants([{ ...defFields, id: data[0].id }]);
    data.splice(position, 1);
    setNewConsultants(data);
  };

  const checkFields = () => {
    const invalidFields: TP[] = [];
    const findFillElement = [
      ...newConsultants,
      ...newConsultantsWithErrors,
    ].filter((consultant) => {
      const firstName = consultant[IParticipantsInputs.first_name];
      const lastName = consultant[IParticipantsInputs.last_name];
      const email = consultant[IParticipantsInputs.email];

      if (email && !validateEmail(email)) {
        invalidFields.push(consultant);
        return false;
      }

      if (firstName && lastName && email) {
        return true;
      }

      if (!firstName && !lastName && !email) return false;

      invalidFields.push({ ...consultant });
      return false;
    });

    return {
      validFields: findFillElement,
      invalidFields,
    };
  };

  const checkHandler = () => {
    const { invalidFields, validFields } = checkFields();

    setNewConsultantsWithErrors(invalidFields);
    setNewConsultants(validFields);
    setCheckAgain(!invalidFields[0]);
  };

  const saveHandler = (onlySend?: boolean) => {
    setWarning('');
    if (onlySend && newConsultants[0]) {
      // const sendData = newConsultants as unknown;
      // return dispatch(
      //   CreateParticipants.request({
      //     newConsultants: sendData as NewParticipant[],
      //     callBack: (success) => (success ? payload.closeModal() : null),
      //   })
      // );
    }

    const { invalidFields, validFields } = checkFields();

    if (!invalidFields[0] && !validFields[0])
      return setWarning('At least one member should be added');

    setNewConsultantsWithErrors(invalidFields);
    setNewConsultants(validFields);
    if (invalidFields[0]) {
      return setCheckAgain(true);
    }

    const sendData = validFields as unknown;
    payload.closeModal();
    return console.log(sendData);
    // return dispatch(
    //   CreateParticipants.request({
    //     newConsultants: sendData as NewParticipant[],
    //     callBack: (success) => (success ? payload.closeModal() : null),
    //   })
    // );
  };

  return {
    newConsultants,
    addConsultant,
    removeConsultant,
    onChangeHandler,
    saveHandler,
    newConsultantsWithErrors,
    checkHandler,
    checkAgain,
    warning,
  };
};
