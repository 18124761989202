import React from 'react';

interface AssessmentIconProps {}

export const AssessmentIcon: React.FC<AssessmentIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <g>
            <g>
              <path
                d="M40 14c2.21 0 4 1.79 4 4v9l-7 7h-9c-2.21 0-4-1.79-4-4V18c0-2.21 1.79-4 4-4h12zm0 2H28c-1.054 0-1.918.816-1.995 1.85L26 18v12c0 1.054.816 1.918 1.85 1.995L28 32h7v-4c0-1.598 1.249-2.904 2.824-2.995L38 25h4v-7c0-1.002-.736-1.831-1.697-1.977l-.154-.018L40 16zm1.17 11H38c-.513 0-.936.386-.993.883L37 28v3.17L41.17 27zM32 27c.552 0 1 .448 1 1s-.448 1-1 1h-3c-.552 0-1-.448-1-1s.448-1 1-1h3zm2-4c.552 0 1 .448 1 1s-.448 1-1 1h-5c-.552 0-1-.448-1-1s.448-1 1-1h5zm5-4c.552 0 1 .448 1 1s-.448 1-1 1H29c-.552 0-1-.448-1-1s.448-1 1-1h10z"
                transform="translate(-24 -247) translate(0 64) translate(0 169)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
