import React from 'react';
import {
  DataGeoStructure,
  DataHowBig,
  DataOrgLocation,
  DataRemoteWork,
  DataTypeOfGroup,
  DataWhatIndustry,
  DataWSFor,
} from '../modals/data/workspaceData';
import * as Styles from '../modals/modalStyles';
import { Radio } from '../ui';
import { RadioItemType } from '../ui/inputs/Radio';
import styled from 'styled-components';
import { TAdditionalInfo } from '../../store/workspaces/types';

interface WorkspacesAnswersProps {
  isNew?: boolean;
  data: TAdditionalInfo;
  radioHandler: (type: keyof TAdditionalInfo, value: string) => void;
}
export const WorkspacesAnswers: React.FC<WorkspacesAnswersProps> = ({
  radioHandler,
  data,
}) => {
  const getActiveId = (array: RadioItemType[], val: string) => {
    const d: RadioItemType | undefined = array.find((d) => d.label === val);
    if (!d) return 0;
    return d.id;
  };

  const renderRadio = () => {
    const activeR = DataWSFor.find((d) => d.label === data.formation_type);

    switch (activeR?.id || 0) {
      case DataWSFor[1].id:
        return (
          <Styles.RadiosRowS>
            <Radio
              title="What type of group will participate in assessments in this workspace?"
              data={DataTypeOfGroup}
              activeId={getActiveId(DataTypeOfGroup, data.group_type)}
              clickHandler={(_, index) => {
                radioHandler('group_type', DataTypeOfGroup[index]?.label || '');
              }}
            />
            <Radio
              title="How big is this group?"
              data={DataHowBig}
              activeId={getActiveId(DataHowBig, data.organization_size)}
              clickHandler={(_, index) => {
                radioHandler(
                  'organization_size',
                  DataHowBig[index]?.label || ''
                );
              }}
            />
          </Styles.RadiosRowS>
        );
      case DataWSFor[0].id:
        return (
          <Styles.RadiosRowS className="radio-rows">
            <Radio
              title="How big is this organization?"
              data={DataHowBig}
              activeId={getActiveId(DataHowBig, data.organization_size)}
              clickHandler={(_, index) => {
                radioHandler(
                  'organization_size',
                  DataHowBig[index]?.label || ''
                );
              }}
            />
            <Radio
              title="What industry best matches the business of this organization?"
              data={DataWhatIndustry}
              activeId={getActiveId(
                DataWhatIndustry,
                data.organization_industry
              )}
              clickHandler={(_, index) => {
                radioHandler(
                  'organization_industry',
                  DataWhatIndustry[index]?.label || ''
                );
              }}
            />
            <Radio
              title="What is the organization's geographic structure?"
              data={DataGeoStructure}
              activeId={getActiveId(
                DataGeoStructure,
                data.organization_geographic_structure
              )}
              clickHandler={(_, index) => {
                radioHandler(
                  'organization_geographic_structure',
                  DataGeoStructure[index]?.label || ''
                );
              }}
            />
            <Radio
              title="Is this organization embracing remote work?"
              data={DataRemoteWork}
              activeId={getActiveId(
                DataRemoteWork,
                data.organization_remote_work
              )}
              clickHandler={(_, index) => {
                radioHandler(
                  'organization_remote_work',
                  DataRemoteWork[index]?.label || ''
                );
              }}
            />
            <Radio
              title="Where is this organization located?"
              data={DataOrgLocation}
              activeId={getActiveId(
                DataOrgLocation,
                data.organization_location
              )}
              clickHandler={(_, index) => {
                radioHandler(
                  'organization_location',
                  DataOrgLocation[index]?.label || ''
                );
              }}
            />
          </Styles.RadiosRowS>
        );

      default:
        return <></>;
    }
  };

  return (
    <WrapperS>
      <Radio
        title="Choose workspace type"
        data={DataWSFor}
        activeId={getActiveId(DataWSFor, data.formation_type)}
        clickHandler={(_, index) => {
          radioHandler('formation_type', DataWSFor[index]?.label || '');
        }}
      />

      {data.formation_type && renderRadio()}
    </WrapperS>
  );
};

const WrapperS = styled.div<{ isNew?: boolean }>`
  h3 {
    margin-top: 40px;
  }

  .radio-rows {
    //display: flex;
    //flex-wrap: wrap;
    //width: 100%;
    //
    //& > * {
    //  flex: 50%;
    //  max-width: 500px;
    //  padding-right: 40px;
    //}
  }
`;
