import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { AppStore } from '../../store/applicationState';
import { AlphabetLetters } from '../../utils/alphabet';
import { countryDial } from '../../utils/countryDial';
import { SelectListS } from '../Responding/styles';
import { ArrowRightIcon } from '../svgs';
import { DefaultSelect, Input } from '../ui';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { ColumnSelect } from './ColumnSelect';
import { PhoneSelectProps } from './types';

export const PhoneSelect: React.FC<PhoneSelectProps> = React.memo(
  ({
    groups,
    columns,
    label,
    name,
    isFirstRowHeader,
       isUseMatchingTemplate,
       isExistFields,
       codeIsExistField,
       selectExistFieldHandler,
       codeSelectExistFieldHandler,
    phoneCode,
    setPhoneCode,
    selectHandler,
    codeField,
    codeSelectHandler,
    selectedItems,
    setphoneType,
  }) => {
    const [svgColumns, setSvgColumns] = React.useState<SelectItemType[]>([]);
    const { Groups } = useSelector((store: AppStore) => store);
    const [selectedSvgColumn, setSelectedSvgColumn] =
      React.useState<SelectItemType | null>(null);
    const [selectedNumberType, setSelectedNumberType] =
      React.useState<SelectItemType | null>(null);
    const [isListVisible, setIsListVisible] = React.useState(false);
    const { ref: refList } = useOutsideClick(() => setIsListVisible(false));
    const [searchValue, setSearchValue] = React.useState('');
    const selectNumberType = [
      { title: 'full number with country code', value: 'full_number', id: 1 },
      {
        title: 'country code in a separate column',
        value: 'separate_column',
        id: 2,
      },
      { title: 'without country code', value: 'without', id: 3 },
    ];
    React.useEffect(() => {
      const newColumns: SelectItemType[] = [
        { id: -1, value: '', title: 'Empty' },
      ];

        selectedItems ? setSelectedSvgColumn(selectedItems[name] as SelectItemType) : setSelectedSvgColumn(null);
        setSelectedNumberType(null);
      columns.forEach((c, i) => {
        newColumns.push({
          id: i,
          value: `${i}`,
          title: isFirstRowHeader ? c : AlphabetLetters[i],
        });
      });
      setSvgColumns(newColumns);
        //eslint-disable-next-line
    }, [columns, isFirstRowHeader, selectedItems]);

      React.useEffect(() => {
          if (selectedSvgColumn?.id === -1) {
              setSelectedNumberType(null);
              setphoneType(0)
              setPhoneCode(`${countryDial[234].flag} ${countryDial[234].dial_code}`);
              return
          }
          if (isUseMatchingTemplate && selectedItems['phone_format'] && selectedSvgColumn) {
              let num = selectedItems['phone_format'];
              let numberType = selectNumberType.find((itm) =>
                  itm.id === num)
              setSelectedNumberType(numberType);
          } else {
              setSelectedNumberType(null);
              setphoneType(0)
              setPhoneCode(`${countryDial[234].flag} ${countryDial[234].dial_code}`);
          }
          //eslint-disable-next-line
      }, [selectedSvgColumn]);

    const renderBody = () => {
      switch (selectedNumberType.id) {
        case 1: {
          setphoneType(1);
          setPhoneCode('');
          codeField.column_idx = '';
          return null;
        }
        case 2: {
          setphoneType(2);
          setPhoneCode('');
          return (
            <ColumnSelect
              groups={codeField.isGroup ? Groups.data || [] : []}
              label={''}
              name={codeField.name}
              openSelectHandler={() => null}
              isFirstRowHeader={isFirstRowHeader}
              isUseMatchingTemplate={isUseMatchingTemplate}
              isExistField={isExistFields['phone_code_col_idx']}
              selectExistFieldHandler={codeSelectExistFieldHandler}
              selectedItems={selectedItems ? selectedItems : null}
              columns={columns}
              selectHandler={codeSelectHandler}
            />
          );
        }
        case 3: {
          if (!phoneCode)
            setPhoneCode(
              `${countryDial[234].flag} ${countryDial[234].dial_code}`
            );
          setphoneType(3);
          codeField.column_idx = '';
          return (
            <>
              <ArrowRightIcon />
              <div>
                <input
                  name={`phoneCode`}
                  placeholder="Code"
                  type="text"
                  value={phoneCode}
                  onClick={() => {
                    setIsListVisible(true);
                  }}
                  readOnly
                />
                {isListVisible && (
                  <SearchWrapperS ref={refList}>
                    <SearchInputS
                      type="text"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.currentTarget.value);
                      }}
                      placeholder={'Search'}
                      autoFocus
                    />
                    <SelectCountryListS>
                      {!searchValue
                        ? countryDial.map((itm, i) => (
                            <li
                              key={i + itm.name + itm.code}
                              onClick={() => {
                                setPhoneCode(`${itm.flag} ${itm.dial_code}`);
                                setIsListVisible(false);
                              }}
                            >
                              {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                            </li>
                          ))
                        : countryDial
                            .filter(
                              (itm) =>
                                itm.code.includes(searchValue.toUpperCase()) ||
                                itm.name
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                itm.dial_code.includes(searchValue)
                            )
                            .map((itm) => (
                              <li
                                key={
                                  countryDial.findIndex(
                                    (country) => itm.name === country.name
                                  ) +
                                  itm.name +
                                  itm.code
                                }
                                onClick={() => {
                                  setPhoneCode(`${itm.flag} ${itm.dial_code}`);
                                  setIsListVisible(false);
                                }}
                              >
                                {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                              </li>
                            ))}
                    </SelectCountryListS>
                  </SearchWrapperS>
                )}
              </div>
            </>
          );
        }
      }
    };
    return (
      <WrapperS>
        <Input value={label} name={name} readOnly onChange={() => null} />
        <ArrowRightIcon />
        <DefaultSelect
          placeHolder="Column name"
          isOpenHandler={() => null}
          data={svgColumns}
          selected={selectedSvgColumn}
          isExistField={isExistFields[name]}
          onChange={(e) => {
            setSelectedSvgColumn(e);
            selectHandler(e);
            selectExistFieldHandler(name)
          }}
        />
        {selectedSvgColumn && selectedSvgColumn.title !== 'Empty' && (
          <>
            <ArrowRightIcon />
            <DefaultSelect
              placeHolder="Select type"
              isOpenHandler={() => null}
              data={selectNumberType}
              selected={selectedNumberType}
              onChange={(e) => {
                setSelectedNumberType(e);
              }}
            />
          </>
        )}
        {selectedNumberType && <>{renderBody()}</>}
      </WrapperS>
    );
  }
);
const WrapperS = styled.div`
  display: flex;
  align-items: center;
  div,
  svg {
    margin-right: 10px;
  }
`;
const SearchInputS = styled(Input)``;
const SelectCountryListS = styled(SelectListS)``;
const SearchWrapperS = styled.div`
  position: absolute;
  width: 300px;
  z-index: 100;
`;
