import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/applicationState';
import { SelectItemType } from '../../../ui/selects/DefaultSelect';
import {
  ALL_ASS,
  ALL_GROUPS,
  ALL_PARTICIPANTS,
  ALL_QUESTIONS,
} from '../../../../utils/consts';
import { deepClone } from '../../../../utils/deepClone';
import { TRange } from '../../../ui/selects/SelectWithRange';
import { useConvertSelectAss } from '../../../../hooks/useConvertSelectAss';
import { convertToDoubleStr } from '../../../../utils/convertToDoubleStr';
import { getIds } from '../../utils/getIds';
import {
  GetCollaborations,
  GetConnectednessScore,
  SetCollabFilters,
  TGet_COLLAB_R,
} from '../../../../store/collaborations/actions';

import { getCollabAss } from '../../utils/getCollabAss';

interface TRenderData {
  is_all_assessments?: TGet_COLLAB_R['data']['is_all_assessments'];
  is_all_participants?: TGet_COLLAB_R['data']['is_all_participants'];
  period_of_answer_date: {
    is_all_time?: TGet_COLLAB_R['data']['period_of_answer_date']['is_all_time'];
    is_last_month?: TGet_COLLAB_R['data']['period_of_answer_date']['is_last_month'];
    is_last_quarter?: TGet_COLLAB_R['data']['period_of_answer_date']['is_last_quarter'];
    is_last_year?: TGet_COLLAB_R['data']['period_of_answer_date']['is_last_year'];
    start_date?: TGet_COLLAB_R['data']['period_of_answer_date']['start_date'];
    end_date?: TGet_COLLAB_R['data']['period_of_answer_date']['end_date'];
  };
  period_of_hiring_date: {
    from_0_to_30_days?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_0_to_30_days'];
    from_31_to_60_days?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_31_to_60_days'];
    from_61_to_90_days?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_61_to_90_days'];
    from_0_to_90_days?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_0_to_90_days'];
    from_91_to_180_days?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_91_to_180_days'];
    from_0_to_1_years?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_0_to_1_years'];
    from_1_to_3_years?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_1_to_3_years'];
    from_3_to_6_years?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_3_to_6_years'];
    from_6_years?: TGet_COLLAB_R['data']['period_of_hiring_date']['from_6_years'];
  };
  assessments?: TGet_COLLAB_R['data']['assessments'];
  groups_ids?: TGet_COLLAB_R['data']['groups_ids'];
  // questions_ids?: TGet_COLLAB_R['data']['questions_ids'];
}

export const useFilter = () => {
  const { Groups, Workspaces } = useSelector((store: AppStore) => store);
  const [selectDataGroups, setSelectDataGroups] = React.useState<
    SelectItemType[]
  >([]);
  const dispatch = useDispatch();

  const [rangeDates, setRangeDates] = React.useState({
    start_date: '',
    end_date: '',
  });
  const [activePeriod, setActivePeriod] = React.useState<TRange>('all_time');
  const {
    selectDataAss,
    activeAssIds,
    setActiveAssIds,
    activeQuestionIds,
    setActiveQuestionIds,
  } = useConvertSelectAss(false, false, true);
  const [isAllQuestionsSelected, setIsAllQuestionsSelected] =
    React.useState<{ [key: string]: boolean }>(null);
  const [activeGroupIds, setActiveGroupIds] = React.useState<SelectItemType[]>(
    []
  );

  const getCollab = React.useCallback(
    (
      {
        is_all_assessments = true,
        is_all_participants = true,
        period_of_answer_date: {
          is_all_time = true,
          is_last_month = false,
          is_last_quarter = false,
          is_last_year = false,
          start_date = '',
          end_date = '',
        },
        period_of_hiring_date: {
          from_0_to_30_days = false,
          from_31_to_60_days = false,
          from_61_to_90_days = false,
          from_0_to_90_days = false,
          from_91_to_180_days = false,
          from_0_to_1_years = false,
          from_1_to_3_years = false,
          from_3_to_6_years = false,
          from_6_years = false,
        },
        assessments = [],
        groups_ids = [],
      }: TRenderData,
      isSend?: boolean
    ) => {
      if (!Workspaces.current || !Workspaces.current.id) return null;
      const data = {
        is_all_assessments,
        is_all_participants,
        period_of_answer_date: {
          is_all_time,
          is_last_month,
          is_last_quarter,
          is_last_year,
          start_date,
          end_date,
        },
        period_of_hiring_date: {
          from_0_to_30_days,
          from_31_to_60_days,
          from_61_to_90_days,
          from_0_to_90_days,
          from_91_to_180_days,
          from_0_to_1_years,
          from_1_to_3_years,
          from_3_to_6_years,
          from_6_years,
        },
        assessments,
        groups_ids,
      };

      dispatch(SetCollabFilters(data));
      if (isSend) {
        dispatch(
          GetCollaborations.request({
            data: {
              ...data,
              workspace_id: Workspaces.current.id,
            },
          })
        );
        dispatch(
          GetConnectednessScore.request({
            data: {
              ...data,
              workspace_id: Workspaces.current.id,
            },
          })
        );
      }
    },
    [Workspaces, dispatch]
  );

  React.useEffect(() => {
    setActiveGroupIds([ALL_PARTICIPANTS]);

    // getCollab({ period_of_answer_date: {}, period_of_hiring_date: {} }, true);
    // eslint-disable-next-line
  }, [Workspaces.current]);

  React.useEffect(() => {
    (() => {
      if (!Groups.data) return null;
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];

      if (Groups.data && Groups.data[1]) newSelects.push(ALL_GROUPS);

      Groups.data.forEach((itm) => {
        newSelects.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });

      setSelectDataGroups(newSelects);
    })();
  }, [Groups.data]);

  const getPeriodData = () => {
    return {
      is_all_time: activePeriod === 'all_time',
      is_last_month: activePeriod === 'last_month',
      is_last_quarter: activePeriod === 'last_quarter',
      is_last_year: activePeriod === 'last_year',
      start_date: rangeDates.start_date,
      end_date: rangeDates.end_date,
    };
  };

  const groupSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_GROUPS.value &&
      activeGroupIds[0].value === ALL_GROUPS.value
    ) {
      return null;
    }

    if (
      activeGroupIds[0].value === ALL_PARTICIPANTS.value &&
      data.value === ALL_PARTICIPANTS.value
    ) {
      return null;
    }

    const newData = deepClone(activeGroupIds) as SelectItemType[];

    const sendPeriodData = getPeriodData();
    const groups_ids: string[] = [];
    // const ass_ids = getIds(activeAssIds, true);
    // const questions_ids = getIds(activeQuestionIds);
    const assessments = getCollabAss(
      activeAssIds,
      isAllQuestionsSelected,
      activeQuestionIds
    );

    if (data.value === ALL_PARTICIPANTS.value) {
      setActiveGroupIds([ALL_PARTICIPANTS]);
      return getCollab({
        is_all_participants: true,
        period_of_answer_date: { ...sendPeriodData },
        period_of_hiring_date: {},
        groups_ids: [],
        is_all_assessments: !assessments[0],
        assessments: assessments,
        // questions_ids: questions_ids as string[],
      });
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_PARTICIPANTS.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        const indexAll = newData.findIndex((p) => p.value === ALL_GROUPS.value);
        if (indexAll >= 0) {
          newData.splice(indexAll, 1);
        }
        hasVal = true;
      }
    });

    if (data.value === ALL_GROUPS.value && !hasVal) {
      selectDataGroups.forEach((itm) => {
        if (itm.value !== ALL_PARTICIPANTS.value) {
          itm.value !== ALL_GROUPS.value && groups_ids.push(itm.value);
          !newData.find((it) => it.id === itm.id) && newData.push(itm);
        }
      });

      setActiveGroupIds(newData);
      return getCollab({
        period_of_answer_date: { ...sendPeriodData },
        period_of_hiring_date: {},
        is_all_participants: !groups_ids[0],
        groups_ids,
        is_all_assessments: !assessments[0],
        assessments: assessments,
        // questions_ids: questions_ids as string[],
      });
    }

    if (newData[0] && newData[0].value === ALL_GROUPS.value) {
      newData.splice(0, 1);
    }

    // if (!newData[0]) return null;

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (
        itm.value !== ALL_PARTICIPANTS.value &&
        itm.value !== ALL_GROUPS.value
      )
        groups_ids.push(itm.value);
    });

    if (!newData[0]) {
      newData.push(ALL_PARTICIPANTS);
    }

    if (Groups.data[1] && Groups.data.length === newData.length) {
      const isAllGroupsIn = newData.find((p) => p.id === ALL_GROUPS.id);
      if (!isAllGroupsIn) newData.push(ALL_GROUPS);
    }

    setActiveGroupIds(newData);
    return getCollab({
      period_of_answer_date: { ...sendPeriodData },
      period_of_hiring_date: {},
      is_all_participants: !groups_ids[0],
      groups_ids,
      is_all_assessments: !assessments[0],
      assessments: assessments,
      // questions_ids: questions_ids as string[],
    });
  };

  const selectedRange = (): null | string => {
    if (activePeriod) {
      switch (activePeriod) {
        case 'all_time':
          return 'All time';
        case 'last_month':
          return 'Last month';
        case 'last_quarter':
          return 'Last quarter';
        case 'last_year':
          return 'Last year';
        default:
          return null;
      }
    }

    if (rangeDates.start_date) {
      const stDate = new Date(rangeDates.start_date);
      const enDate = new Date(rangeDates.end_date);
      const sy = stDate.getFullYear();
      const sm = stDate.getMonth() + 1;
      const sd = stDate.getDate();
      const ey = enDate.getFullYear();
      const em = enDate.getMonth() + 1;
      const ed = enDate.getDate();

      const stD = `${sy}.${convertToDoubleStr(`${sm}`)}.${convertToDoubleStr(
        `${sd}`
      )}`;
      const enD = `${ey}.${convertToDoubleStr(`${em}`)}.${convertToDoubleStr(
        `${ed}`
      )}`;
      return `${stD} - ${enD}`;
    }

    return null;
  };

  const assSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_ASS.value &&
      activeAssIds[0].value === ALL_ASS.value
    ) {
      return null;
    }
    const newData = deepClone(activeAssIds) as SelectItemType[];
    // const ass_ids: string[] = [];
    // const questions_ids = getIds(activeQuestionIds);
    const groups_ids = getIds(activeGroupIds) as string[];
    const sendPeriodData = getPeriodData();

    if (data.value === ALL_ASS.value) {
      setActiveAssIds([ALL_ASS]);
      return getCollab({
        period_of_answer_date: { ...sendPeriodData },
        period_of_hiring_date: {},
        is_all_assessments: true,
        assessments: [],
        is_all_participants: !groups_ids[0],
        groups_ids,
      });
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_ASS.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    // newData.forEach((itm) => {
    //   if (itm.value !== ALL_ASS.value) {
    //     ass_ids.push(itm.value);
    //   }
    // });

    if (!newData[0]) {
      newData.push(ALL_ASS);
    }

    setActiveAssIds(newData);

    const assessments = getCollabAss(
      newData,
      isAllQuestionsSelected,
      activeQuestionIds
    );

    return getCollab({
      period_of_answer_date: { ...sendPeriodData },
      period_of_hiring_date: {},
      is_all_participants: !groups_ids[0],
      groups_ids,
      is_all_assessments: !assessments[0],
      assessments: assessments,
      // questions_ids: questions_ids as string[],
    });
  };

  // question handler желательно исправить, логика которая требует коментов - плохая логика,
  // нужен рефакторинг под новый тип данных на беке, а не просто подстраивание под него через getCollabAss
  const questionSelectHandler = (
    parent: SelectItemType,
    data: SelectItemType
  ) => {
    const newData = deepClone(activeQuestionIds) as SelectItemType[];

    const sendPeriodData = getPeriodData();
    const groups_ids = getIds(activeGroupIds) as string[];

    const questions_ids: string[] = [];

    if (
      data.value === ALL_QUESTIONS.value &&
      isAllQuestionsSelected[parent.id]
    ) {
      return null;
    }

    //при All questions пушим те значения которых нет в списке
    if (data.value === ALL_QUESTIONS.value) {
      parent.questions.forEach((question) => {
        if (
          !(newData.findIndex((itm) => itm.id === question.id) >= 0) &&
          question.id !== data.value
        ) {
          newData.push({
            id: question.id,
            value: question.id,
            title: question.body,
          });
        }
      });
    }

    //если дата не All questions то идем дальше
    if (data.value !== ALL_QUESTIONS.value) {
      //переменная для того чтобы определить выбранно ли нажатое значение
      let hasVal = false;
      //для каждого вопроса из родительского
      parent.questions.forEach((question, i) => {
        //проверяем существует ли вопрос в списке выбраных и если
        // в данный момент выбраны все значения
        //и оно не соответствует тому на которое нажали , то удаляем его, наша цель оставить только то на которое нажали
        if (
          newData.findIndex((itm) => question.id === itm.id) >= 0 &&
          // question.id !== data.value &&
          isAllQuestionsSelected[parent.id]
        ) {
          newData.splice(
            newData.findIndex((itm) => question.id === itm.id),
            1
          );
        }
        if (
          newData.findIndex((itm) => question.id === itm.id) >= 0 &&
          question.id === data.value &&
          !isAllQuestionsSelected[parent.id]
        ) {
          hasVal = true;
          newData.splice(
            newData.findIndex((itm) => question.id === itm.id),
            1
          );
        }
      });

      if (!hasVal) {
        newData.push(data);
      }
    }

    newData.forEach((itm) => {
      questions_ids.push(itm.value);
    });

    const includedQuestions = [];
    const current_question_ids = getIds(newData);

    parent.questions.forEach((question) => {
      if (current_question_ids.includes(question.id)) {
        includedQuestions.push(question);
      }
    });

    let isAllCurrentlyIncluded =
      includedQuestions.length === parent.questions.length;

    if (includedQuestions.length === 0) {
      isAllCurrentlyIncluded = true;
    }

    const newIsAllSelected = { ...isAllQuestionsSelected };
    newIsAllSelected[parent.id] = isAllCurrentlyIncluded;
    setIsAllQuestionsSelected(newIsAllSelected);
    setActiveQuestionIds(newData);

    const assessments = getCollabAss(activeAssIds, newIsAllSelected, newData);
    return getCollab({
      period_of_answer_date: { ...sendPeriodData },
      period_of_hiring_date: {},
      is_all_participants: !groups_ids[0],
      groups_ids,
      is_all_assessments: !assessments[0],
      assessments: assessments,
      // questions_ids: questions_ids,
    });
  };

  const applyHandler = () => {
    // const ass_ids = getIds(activeAssIds, true) as string[];
    const assessments = getCollabAss(
      activeAssIds,
      isAllQuestionsSelected,
      activeQuestionIds
    );
    // const questions_ids = getIds(activeQuestionIds) as string[];
    const groups_ids = getIds(activeGroupIds) as string[];
    const sendPeriodData = getPeriodData();

    return getCollab(
      {
        period_of_answer_date: { ...sendPeriodData },
        period_of_hiring_date: {},
        is_all_participants: !groups_ids[0],
        groups_ids,
        is_all_assessments: !assessments[0],
        assessments: assessments,
        // questions_ids: questions_ids,
      },
      true
    );
  };

  return {
    selectDataGroups,
    groupSelectHandler,
    activeGroupIds,
    selectedRange,
    setActivePeriod,
    activePeriod,
    setRangeDates,
    rangeDates,
    selectDataAss,
    activeAssIds,
    setActiveAssIds,
    activeQuestionIds,
    setActiveQuestionIds,
    isAllQuestionsSelected,
    setIsAllQuestionsSelected,
    assSelectHandler,
    questionSelectHandler,
    getCollab,
    applyHandler,
  };
};
