import React from 'react';
import styled from 'styled-components';
import { Chart, ChartData, registerables } from 'chart.js';
import {
    EChartType,
    ETypeGraph,
    TChart,
    TChartFilter,
    TLineScatter,
} from '../../../store/analytics/types';
import { GRAPH_COLORS } from '../../../utils/colors';
import { ChartHeader } from '../ChartHeader';
import { NoData } from '../NoData';
import { BlockChartFilter } from './BlockChartFilter';
import { genChartId } from '../../../utils/genChartId';
import { useChartRuns } from './hooks/useChartRuns';
import { useDescription } from './hooks/useDescription';
import { getCutText } from '../../../utils/helpers';
import {useSelector} from "react-redux";
import {AppStore} from "../../../store/applicationState";

interface ChartProps {
    widthLegend?: boolean;
    isStored: boolean;
    isPreview?: boolean;
    isDownload?: boolean;
    data: TChart;
    index: number;
    chartIndex: number;
    snapshotId: string | null;
    dataStoryChartSettings: TChartFilter | null;
    refHeader: React.MutableRefObject<HTMLDivElement>;
}

Chart.register(...registerables);

interface IChData {
    name: string;
    full_name?: string;
    color: string;
    val: number;
    id: string;
    benchmark?: number;
    // period_of_hiring_date?: string;
}

interface LabelData {
    name: string;
    full_name: string;
}

interface dataSets {
    label?: string,
    data: {x: number, y: string}[],
    pointStyle?: string[],
    pointRadius?: number,
    showLine?: boolean
}

export const LineScatterChart: React.FC<ChartProps> = React.memo(
    ({
         data: chart,
         index,
         chartIndex,
         isStored,
         dataStoryChartSettings,
         snapshotId,
         isPreview,
         refHeader,
         isDownload,
     }) => {
        const { Analytics } = useSelector(
            (store: AppStore) => store
        );
        const chartRef: any = React.createRef();
        const refLegend: any = React.createRef();
        const [dataForChart, setDataForChart] = React.useState<IChData[]>([]);
        const [chartHeight, setChartHeight] = React.useState(500);
        const [step, setStep] = React.useState(5);
        const [chartWrapperHeight, setChartWrapperHeight] = React.useState(520);
        const refData = React.useRef<IChData[]>([]);
        const { activeRuns, updateActiveRuns } = useChartRuns();
        const [isRuns, setIsRuns] = React.useState(false);
        const hookDesc = useDescription();
        const description = hookDesc.getAssDescription(chart.assessments_ids || []);
        const version = hookDesc.getAssVersion(chart.assessments_ids || []);
        const [activeQuestionItems, setActiveQuestionItems] = React.useState<
            | {
            x_axis_questions: string[];
            y_axis_questions: string[];
        }
            | string[]
        >();

        React.useEffect(() => {
            if (Analytics.filters.params.is_all_participants === true) {
                updateActiveRuns([])
            }
            // eslint-disable-next-line
        }, [Analytics.filters.params.is_all_participants])

        const scatterDataLabels = {
            id: 'scatterDataLabels',
            afterDatasetsDraw(chart, args, options) {
                const {ctx} = chart;
                ctx.save();
                ctx.font = '14 sans-serif';

                chart.config.data.datasets.forEach((d,idx) => {
                    ctx.fillText(d.data[2]?.x, chart.getDatasetMeta(idx).data[2]?.x-5,
                        chart.getDatasetMeta(idx).data[2]?.y - 10)
                })
            }
        }
        const renderBody = () => {
            return (
                <div>
                    <BlockChartFilter
                        disabledLabels={
                            isStored ? dataStoryChartSettings.activeRuns : activeRuns
                        }
                        legendHandler={updateActiveRuns}
                        setDefaultLabels={updateActiveRuns}
                        chartIndex={chartIndex}
                        index={index}
                        isRuns={isStored ? true : isRuns}
                        setIsRuns={setIsRuns}
                        graphType={chart.type}
                        questions={chart.select_group?.questions.options}
                        activeQuestionItems={activeQuestionItems}
                        setActiveQuestionItems={setActiveQuestionItems}
                    />
                    {!chart.data ||
                    !chart.data[0] ||
                    !refData.current ||
                    !refData.current.length ? (
                        <NoData />
                    ) : (

                        <div>
                            <CanvasWrapperS
                                preview={isPreview}
                                wrapperHeight={chartWrapperHeight}
                            >
                                <ChartWrapperS chartHeight={chartHeight}>
                                    <CanvasS ref={chartRef} />
                                </ChartWrapperS>
                            </CanvasWrapperS>
                            <WrapperS style={{ paddingBottom: '20px' }} ref={refLegend}>
                                {/*{chart.view !== EChartType.cycles &&*/}
                                {/*    refData.current[1] &&*/}
                                {/*    refData.current.map((ch, i) => {*/}
                                {/*        const tenure = ch.id.split('/')[5];*/}
                                {/*        return (*/}
                                {/*            <LegendS*/}
                                {/*                key={ch.name + i}*/}
                                {/*                disabled={(isStored*/}
                                {/*                        ? dataStoryChartSettings.activeRuns*/}
                                {/*                        : activeRuns*/}
                                {/*                ).includes(ch.id)}*/}
                                {/*                onClick={() => updateActiveRuns(ch.id)}*/}
                                {/*                style={{ margin: '10px' }}*/}
                                {/*            >*/}
                                {/*                <LegendItemsS*/}
                                {/*                    style={{ background: ch.color, marginRight: '5px' }}*/}
                                {/*                />*/}
                                {/*                <LegendNameS>*/}
                                {/*                    {ch.name + ' '}*/}
                                {/*                    {tenure && tenure + ' '} <span>({ch.val})</span>*/}
                                {/*                </LegendNameS>*/}
                                {/*            </LegendS>*/}
                                {/*        );*/}
                                {/*    })}*/}
                            </WrapperS>
                        </div>
                    )}
                </div>
            );
        };
        React.useEffect(() => {
            const newData: IChData[] = [];
            const cashGroup: {
                [key: string]: IChData;
            } = {};
            chart.data &&
            chart.data[0] &&
            (chart.data as TLineScatter[]).forEach((itm, i) => {
                if (itm && !itm.period_of_hiring_date) {
                    const existGroup =
                        cashGroup[itm.group_name] ||
                        newData.find((gr) => gr.name === itm.group_name);
                    if (!cashGroup[itm.group_name])
                        cashGroup[itm.group_name] = existGroup;
                    newData.push({
                        name: /[A-Z]{2,}/.test(itm.group_name)
                            ? getCutText(itm.group_name, 10)
                            : itm.group_name !== 'All participants' ?
                                getCutText(itm.group_name, 12) :
                                getCutText(itm.group_name, 17),
                        full_name: itm.group_name,
                        val: itm.value,
                        color: existGroup ? existGroup.color : GRAPH_COLORS[i] || 'grey',
                        id: genChartId(
                            itm.package_name,
                            itm.package_id,
                            itm.cycle_date,
                            itm.group_name,
                            itm.link
                        ),
                    });
                } else {
                    itm.data.forEach((data, c) => {
                        const existGroup =
                            cashGroup[data.group_name] ||
                            newData.find((gr) => gr.name === data.group_name);
                        if (!cashGroup[data.group_name])
                            cashGroup[data.group_name] = existGroup;

                        newData.push({
                            name: /[A-Z]{2,}/.test(data.group_name)
                                ? getCutText(data.group_name, 10)
                                : data.group_name !== 'All participants' ?
                                    getCutText(data.group_name, 12) :
                                    getCutText(data.group_name, 17),
                            full_name: data.group_name,
                            val: data.value,
                            color: existGroup ? existGroup.color : GRAPH_COLORS[c] || 'grey',
                            id: genChartId(
                                data.package_name,
                                data.package_id,
                                data.cycle_date,
                                data.group_name,
                                data.link,
                                itm.period_of_hiring_date
                            ),
                        });
                    });
                }

                if (isPreview) {
                    setChartHeight(
                        newData.length * 50 < 350 ? 350 : newData.length * 50
                    );
                } else {
                    setChartHeight(
                        newData.length * 50 < 500 ? 500 : newData.length * 50
                    );
                }
            });
            refData.current = newData;
            newData.sort((a, b) => a.name.localeCompare(b.name));
            setDataForChart(newData);
            // eslint-disable-next-line
        }, [chart]);

        React.useEffect(() => {
            if (!isPreview && refHeader.current && refLegend.current) {
                setChartWrapperHeight(
                    590 - refHeader.current.clientHeight - refLegend.current.clientHeight
                );
            } else if (
                isPreview &&
                !isDownload &&
                refHeader.current &&
                refLegend.current
            ) {
                setChartWrapperHeight(
                    440 - refHeader.current.clientHeight - refLegend.current.clientHeight
                );
            } else {
                setChartWrapperHeight(chartHeight);
            }
            // eslint-disable-next-line
        }, [refHeader, refLegend, chartHeight]);

        React.useEffect(() => {
            let myChar: any = null;

            (() => {
                if (!chart.data) return null;
                if ((chart.data && !chart.data[0]) || !chartRef.current) return null;
                const myChartRef = chartRef.current.getContext('2d');
                const newLabels: LabelData[] = [];
                const labels : string[] = [];
                let ld : LabelData = { name: '', full_name: ''};
                    let dataObj: dataSets[] = []

                    let maxValue = 75;
                    let minValue = -25;

                dataForChart.forEach((n,idx) => {
                    if (n.val > 75 && n.val > maxValue) {
                        maxValue = n.val;
                    }

                    if (n.val < -25 && n.val < minValue) {
                        minValue = n.val;
                    }
                })

                if (isPreview && (minValue < -25 || maxValue > 75)) {
                    minValue =  Math.sign(minValue) * Math.ceil(Math.abs(minValue) / 10) * 10
                    maxValue =  Math.ceil(maxValue / 10) * 10
                    setStep(10)
                }

                ld = {
                    name : '',
                    full_name: ''
                }
                newLabels.push(ld)
                labels.push(ld.name)
                dataForChart.forEach((n) => {
                    if (
                        (isStored
                                ? dataStoryChartSettings.activeRuns
                                : activeRuns
                        ).includes(n.id) &&
                        chart.view !== EChartType.cycles
                    )
                        return null;

                    if (
                        chart.view === EChartType.cycles &&
                        (isStored ? dataStoryChartSettings.activeRuns : activeRuns)[0] &&
                        !(
                            isStored ? dataStoryChartSettings.activeRuns : activeRuns
                        ).includes(n.id)
                    )
                        return null;

                    let data: {x:number, y: string}[] = []
                    const nameArray = n.id.split('/');
                    if (chart.view === EChartType.cycles) {
                        ld = {
                            name : [
                                `${
                                    /[A-Z]{2,}/.test(nameArray[3])
                                        ? getCutText(nameArray[3], 10)
                                        : nameArray[3] !== 'All participants' ?
                                            getCutText(nameArray[3], 10) :
                                            getCutText(nameArray[3], 17)
                                }${nameArray[5] ? ', ' + nameArray[5] : ''}`,
                                `\n${nameArray[0]}`,
                                ` (${nameArray[2]})`,
                            ] as any,
                            full_name: [
                                `${
                                    /[A-Z]{2,}/.test(nameArray[3])
                                        ? nameArray[3]
                                        : nameArray[3]
                                }${nameArray[5] ? ', ' + nameArray[5] : ''}`,
                                `\n${nameArray[0]}`,
                                ` (${nameArray[2]})`,
                            ] as any,
                        }
                        newLabels.push(ld);
                        labels.push(ld.name);
                    } else {
                        ld = {
                            name: [
                                `${n.name}`,
                                ` ${nameArray[5] ? nameArray[5] : 'All tenures'}`,
                            ] as any,
                            full_name : [
                                `${n.full_name}`,
                                ` ${nameArray[5] ? nameArray[5] : 'All tenures'}`,
                            ] as any,
                        }
                        newLabels.push(ld);
                        labels.push(ld.name);
                    }


                        if (minValue < -25) {
                            if (minValue%5 === 0) {
                                data.push({x:minValue,y: labels[labels.length-1]})
                            }else {
                                minValue = minValue - 5 - Math.ceil(minValue%5)
                                data.push({x:minValue,y: labels[labels.length-1]})
                            }

                        } else {
                            data.push({x:-25,y: labels[labels.length-1]})
                        }
                        data.push({x:0,y: labels[labels.length-1]})

                        data.push({x:n.val,y: labels[labels.length-1]})

                        if (maxValue > 75) {
                            if (maxValue%5 === 0) {
                                data.push({x:maxValue ,y: labels[labels.length-1]})
                            }

                            if (maxValue%5 !== 0) {
                                maxValue = maxValue+5-maxValue%5
                                data.push({x:maxValue ,y: labels[labels.length-1]})

                            }

                        } else {
                            data.push({x:75 ,y: labels[labels.length-1]})
                        }

                        let obj = {
                            label: labels[labels.length-1],
                            data: data,
                            backgroundColor: function() {
                                if (data[2].x >=50 && data[2].x <= 100)
                                    return 'darkGreen'
                                if (data[2].x >=30 && data[2].x < 50)
                                    return 'lightGreen'
                                if (data[2].x >=20 && data[2].x < 30)
                                    return 'yellow'
                                if (data[2].x < 20)
                                    return 'red'
                            },
                            pointStyle: ['cross','cross','circle','cross'],
                            showLine: true
                        }

                        minValue = Math.ceil(minValue)
                        maxValue = Math.ceil(maxValue)
                        dataObj.push(obj)
                    })

                ld = {
                    name : '',
                    full_name: ''
                }
                newLabels.push(ld)
                labels.push(ld.name)

                let data: ChartData<'scatter', {x: number, y:string}[]> = {
                    labels,
                    datasets: dataObj,
                };

                myChar = new Chart(myChartRef, {
                    type: 'scatter',
                    data,
                    options: {
                        // aspectRatio: 2.7,
                        elements: {
                            point: {
                                radius: function(context) {
                                    if (context.dataIndex === 0 || context.dataIndex === 3)
                                        return -1;

                                    return 5;
                                },
                                backgroundColor: function(context) {
                                    if (context.dataIndex !== 2)
                                        return 'rgb(148,148,148)';

                                },
                                // borderColor: 'rgb(77 175 81)',
                                borderColor: 'rgb(102,102,102)',
                                borderWidth: 1
                            },
                            line: {
                                borderWidth: 1,
                                borderColor: 'rgb(195,192,192)'
                            }
                        },
                        layout: {
                            padding: {
                                left: 0
                            }
                        },
                        maintainAspectRatio: false,
                        indexAxis: 'y',
                        responsive: true,
                        scales: {
                            x: {
                                type: 'linear',
                                ticks: {
                                    stepSize: step,
                                    precision: 0,
                                },
                                min: minValue,
                                max: maxValue,
                            },
                            y: {
                                afterFit: function (scaleInstance) {
                                    scaleInstance.width =
                                        chart.view === EChartType.cycles ? 130 : 123;
                                },
                                ticks: {
                                    // autoSkip: false,
                                    font: {
                                        size: chart.view === EChartType.cycles ? 10 : 13,
                                    },
                                },
                                type: 'category',
                                position: 'left',
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                filter: function (tooltipItem): boolean {
                                    if (tooltipItem.element.options.pointStyle !== 'circle') {
                                        return false
                                    } else {
                                        return true;
                                    }
                                },
                                usePointStyle: true,
                                callbacks: {
                                    labelPointStyle: function(context) {
                                        return {
                                            pointStyle: 'circle',
                                            rotation: 1
                                        };
                                    },
                                    label(tooltipItem) {
                                            return newLabels[tooltipItem.datasetIndex + 1].full_name
                                    },
                                }
                            },
                            title: {
                                padding: {
                                    bottom: 20,
                                    top: 20
                                },
                                display: true,
                                text: 'Net Score',
                            },
                        },
                    },
                    plugins: [scatterDataLabels]
                    // plugins: [plugin],
                });

                return myChar;
            })();

            return () => {
                myChar && myChar.destroy();
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [chartRef]);

        return (
            <div style={{ position: 'relative',...(!isPreview ? { width: '100%', minWidth: '900px'} : {})}}>
                <ChartHeader
                    chartView={chart.view}
                    chartMetaData={chart.meta_data}
                    isStored={isStored}
                    description={description}
                    version={version}
                    title={chart.name}
                    isInFav={chart.is_saved}
                    id={chart.id}
                    graphType={ETypeGraph.type4}
                    isCycles={true}
                    chartType={chart.origin}
                    chartData={chart.data}
                    chartSaved={chart.is_saved}
                    chartPosition={chart.position}
                    snapshotId={snapshotId}
                    activeRuns={activeRuns}
                    dataStoryChartSettings={dataStoryChartSettings}
                    isPreview={isPreview}
                    reference={refHeader}
                    selectedСhartQuestions={activeQuestionItems}
                    allChartQuestions={chart.select_group?.questions.options}
                />
                <div>{renderBody()}</div>
            </div>
        );
    }
);

const CanvasWrapperS = styled.div<{ preview: boolean; wrapperHeight: number }>`
  overflow: auto;
  height: ${({ preview, wrapperHeight }) => `${wrapperHeight}px`};
  max-height: ${({ preview }) => (!preview ? '500px' : '350px')};
  padding: 0 20px;
  transform: ${({ preview }) => (!preview ? '' : 'scale(1)')};
  //canvas {
  //  max-height: ${({ preview }) => (!preview ? '600px' : '350px')};
  //}
`;

const ChartWrapperS = styled.div<{ chartHeight: number }>`
  height: ${({ chartHeight }) => `${chartHeight}px`};
`;
const CanvasS = styled.canvas`
  //min-height: 300px;
  //min-height: ${({ height }) => height}px !important;
  //height: ${({ height }) => height}px !important;
`;

const WrapperS = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* div {
    margin: 10px;
  } */
`;



