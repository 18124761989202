import { Reducer } from 'redux';
import ActionTypes, { TAnalyticsState } from './types';

export const initialState: TAnalyticsState = {
  loading: true,
  filters: {
    opened_visualizations: [],
    params: {
      is_all_assessments: true,
      is_all_participants: true,
      period_of_answer_date: {
        is_all_time: true,
        is_last_month: false,
        is_last_quarter: false,
        is_last_year: false,
        start_date: '',
        end_date: '',
      },
      assessments_ids: [],
      groups_ids: [],
      questions_ids: [],
    },
  },
  errors: undefined,
  data: null,
};

const reducer: Reducer<TAnalyticsState> = (
  state = initialState,
  action
): TAnalyticsState => {
  switch (action.type) {
    case ActionTypes.CREATE_CUSTOM_GRAPH_R:
    case ActionTypes.CREATE_CUSTOM_GLOBAL_GRAPH_R:
    case ActionTypes.DELETE_CHART_CURRENT_R:
    case ActionTypes.EDIT_GRAPH_R:
    case ActionTypes.DELETE_GRAPH_R:
    case ActionTypes.GET_CHARTS_R:
      return { ...state, loading: true };
    case ActionTypes.GET_CHART_CURRENT_R:
      return { ...state, loadingOne: true };
    case ActionTypes.GET_CHARTS_BLOCK_R:
      return { ...state };
    case ActionTypes.GET_CHARTS_BLOCK_S:
      return { ...state, data: action.payload };
    case ActionTypes.GET_CHART_CURRENT_S:
      return { ...state, loadingOne: false, data: action.payload, errors: '' };
    case ActionTypes.GET_CHARTS_S:
      return { ...state, loading: false, data: action.payload, errors: '' };
    case ActionTypes.CREATE_CUSTOM_GLOBAL_GRAPH_S:
      return {
        ...state,
        loading: false,
        data: state.data ? [action.payload, ...state.data] : [action.payload],
        errors: '',
      };
    case ActionTypes.DELETE_CHART_CURRENT_S:
    case ActionTypes.EDIT_GRAPH_S:
    case ActionTypes.DELETE_GRAPH_S:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ActionTypes.UPDATE_GRAPHS:
      return {
        ...state,
        data: action.payload,
      };
    case ActionTypes.CREATE_CUSTOM_GRAPH_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: action.payload,
      };

    case ActionTypes.GET_CHART_CURRENT_E:
    case ActionTypes.GET_CHARTS_E:
      return { ...state, loading: false, errors: action.payload };
    case ActionTypes.DELETE_CHART_CURRENT_E:
    case ActionTypes.EDIT_GRAPH_E:
    case ActionTypes.DELETE_GRAPH_E:
    case ActionTypes.CREATE_CUSTOM_GRAPH_E:
    case ActionTypes.CREATE_CUSTOM_GLOBAL_GRAPH_E:
      return {
        ...state,
        loading: false,
        errors: '',
        createErrors: action.payload,
      };

    case ActionTypes.SET_CHART_FILTERS:
      return { ...state, filters: action.payload };

    default:
      return state;
  }
};

export { reducer as AnalyticsReducer };
