import React from 'react';
import * as Styles from './inputStyles';
import { InputProps } from './types';

export const Input: React.FC<InputProps> = ({
  label = '',
  type = 'text',
  placeholder = '',
  classInput = '',
  classWrapper = '',
  errorText,
  name,
  stylesWrapper,
  stylesInput,
  onChange,
  value,
  autoComplete = 'off',
  readOnly,
  onKeyDown,
  autoFocus
}) => {
  return (
    <Styles.WrapperS style={stylesWrapper} error={!!errorText}>
      <Styles.LabelS className={classWrapper}>
        {label ? <span>{label}</span> : null}
        <Styles.InputS
          onChange={onChange}
          type={type}
          style={stylesInput}
          className={classInput}
          readOnly={readOnly}
          name={name}
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
        />
      </Styles.LabelS>
      {errorText ? <Styles.ErrorTextS>{errorText}</Styles.ErrorTextS> : null}
    </Styles.WrapperS>
  );
};
