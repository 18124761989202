import React from 'react';


interface RecyclingIconProps {
    color?: string;
    opacity?: string;
}

export const RecyclingIcon: React.FC<RecyclingIconProps> = ({
      color = '#000',
      opacity = '1',
  }) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="20" height="20" viewBox="0 0 1280.000000 1280.000000"
             preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
               fill={color} opacity={opacity} stroke="none">
                <path d="M6495 12789 c-698 -5 -1353 -12 -1455 -15 l-185 -7 80 -27 c271 -93
497 -228 739 -439 219 -191 444 -474 655 -824 103 -171 1213 -2216 1207 -2225
-2 -4 -182 -122 -400 -262 l-396 -255 277 -6 c153 -4 721 -9 1263 -13 542 -3
1073 -9 1180 -12 l195 -5 753 1243 c414 684 764 1263 779 1287 l26 44 -114
-60 c-63 -34 -220 -116 -349 -183 -129 -67 -285 -149 -346 -181 l-110 -58
-316 582 c-174 320 -327 605 -341 633 -43 85 -141 225 -217 307 -207 224 -480
384 -790 463 -85 22 -450 24 -2135 13z"/>
                <path d="M4285 12663 c-205 -26 -428 -132 -592 -280 -51 -46 -1509 -2291
-1496 -2303 4 -5 624 -373 1377 -819 797 -472 1373 -808 1378 -803 5 5 337
544 739 1198 l729 1188 -75 142 c-492 940 -1159 1570 -1765 1669 -87 14 -222
18 -295 8z"/>
                <path d="M10179 7814 c-745 -455 -1360 -832 -1368 -840 -12 -11 89 -203 652
-1246 l666 -1233 318 0 c240 1 353 5 463 19 448 54 782 146 1095 301 186 92
315 179 437 294 247 232 374 543 355 871 -13 214 20 139 -592 1345 -307 605
-583 1148 -615 1207 l-56 108 -1355 -826z"/>
                <path d="M1690 8598 c-575 -4 -1172 -11 -1328 -16 l-282 -7 420 -328 c231
-181 420 -333 420 -338 0 -6 -191 -376 -425 -823 -233 -447 -435 -846 -449
-887 -128 -385 31 -827 435 -1203 356 -331 733 -468 1426 -516 164 -11 666
-16 681 -6 5 3 263 419 572 925 l562 919 77 -41 c42 -23 231 -125 421 -228
190 -103 346 -186 348 -184 2 2 -329 619 -735 1372 l-739 1368 -179 1 c-99 0
-650 -3 -1225 -8z"/>
                <path d="M7447 3933 l-718 -1295 238 -436 c132 -241 455 -832 718 -1314 l480
-877 6 72 c4 39 12 250 18 467 6 217 14 421 17 453 l6 58 772 -2 c682 -1 781
1 854 15 191 38 401 127 577 243 134 89 320 276 391 393 39 63 1618 3012 1718
3208 15 29 1 20 -86 -55 -388 -335 -787 -498 -1423 -579 -114 -15 -290 -18
-1462 -21 l-1333 -4 0 43 c0 76 -40 811 -48 870 l-7 57 -718 -1296z"/>
                <path d="M210 4987 c0 -3 352 -655 781 -1449 430 -793 841 -1553 913 -1687
128 -239 133 -247 226 -341 203 -205 492 -342 861 -409 296 -54 288 -54 1571
-58 l1198 -4 0 1609 0 1609 -622 7 c-343 3 -1091 9 -1663 12 -1879 11 -1799
10 -1925 28 -510 76 -930 277 -1254 602 -47 48 -86 84 -86 81z"/>
            </g>
        </svg>
    )
}