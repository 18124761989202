import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { Alert, ButtonAdd, ButtonCancel, ButtonSave } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as PartStyles from './AddParticipantsStyles';
import { AddParticipantsTr } from './AddParticipantsTr';
import { useCloseModal } from './hooks/useCloseModal';
import { useConsultants } from './hooks/useConsultants';
import * as Styles from './modalStyles';

interface Props {
  closeHandler: () => void;
}

export const AddConsultants: React.FC<Props> = ({ closeHandler }) => {
  const { refInnWrapper, closeModal } = useCloseModal(closeHandler);
  const { Participants } = useSelector((store: AppStore) => store);
  const {
    newConsultants,
    addConsultant,
    removeConsultant,
    onChangeHandler,
    saveHandler,
    checkAgain,
    warning,
    newConsultantsWithErrors,
  } = useConsultants({ closeModal, defFieldsCount: 2 });

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '1160px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>
            Invite consultant(s) to this consulting team
          </Styles.ModalTileS>
          {/* <PartStyles.SubtitleS style={{ margin: '0' }}>
            You need to fill one or all of the user credentionals: email,
          </PartStyles.SubtitleS> */}
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS
          style={{
            minHeight: '300px',
            maxHeight: '50vh',
          }}
        >
          <PartStyles.TableS cellPadding={0} cellSpacing={0}>
            <PartStyles.THeadS>
              <tr>
                <PartStyles.TTh tdType="first_name">First Name</PartStyles.TTh>
                <PartStyles.TTh tdType="last_name">Last Name</PartStyles.TTh>
                <PartStyles.TTh tdType="email">Email</PartStyles.TTh>
                <th />
              </tr>
            </PartStyles.THeadS>
            <PartStyles.TBodyS>
              {newConsultantsWithErrors[0] &&
                newConsultantsWithErrors.map((participant, i) => (
                  <AddParticipantsTr
                    widthError
                    onlyEmail
                    key={participant.id}
                    participant={participant}
                    position={i}
                    removeHandler={(pos) => removeConsultant(pos, true)}
                    onChange={(e) => onChangeHandler(e, true)}
                    failParticipants={undefined}
                  />
                ))}

              {newConsultants.map((participant, i) => (
                <AddParticipantsTr
                  key={participant.id}
                  participant={participant}
                  position={i}
                  onlyEmail
                  removeHandler={removeConsultant}
                  onChange={onChangeHandler}
                  failParticipants={undefined}
                />
              ))}
            </PartStyles.TBodyS>
          </PartStyles.TableS>
          <PartStyles.AddParticipantBtnWrapperS>
            <ButtonAdd title="Add consultant" onClick={addConsultant} />
          </PartStyles.AddParticipantBtnWrapperS>
        </Styles.ModalBodyS>
        <Styles.ErrorInfoS>
          {newConsultantsWithErrors[0] && (
            <Alert text="Consultants on the red background would not be added." />
          )}
          {Participants.errors && <Alert text={Participants.errors} />}
          {warning && <Alert type="warning" text={warning} />}
        </Styles.ErrorInfoS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <PartStyles.BtnCSVWrapperS>
              <ButtonBorderedS disabled style={{ visibility: 'hidden' }}>
                Import consultants via CSV
              </ButtonBorderedS>
            </PartStyles.BtnCSVWrapperS>
            <ButtonCancel onClick={closeModal} />
            {checkAgain ? (
              <>
                <ButtonSave
                  loading={false}
                  onClick={() => saveHandler()}
                  title="Check and Invite"
                />
                {newConsultants[0] ? (
                  <ButtonSave
                    loading={false}
                    onClick={() => saveHandler(true)}
                    title="Invite"
                  />
                ) : null}
              </>
            ) : (
              <ButtonSave
                loading={false}
                onClick={() => saveHandler()}
                title="Invite"
              />
            )}
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
