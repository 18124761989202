import React from 'react';
import styled from 'styled-components';
import {
  ButtonArrow,
  ButtonCancel,
  ButtonMore,
  ButtonSave,
  DeleteSelectItem,
  EditSelectItem,
  Loader,
  TextArea,
} from '../../ui';
import { COLORS_RGBA } from '../../../utils/colors';
import { TParticipantInfo } from '../../../store/collaborations/types';
import { useDispatch } from 'react-redux';
import {
  DeleteCollabParticipantNote,
  UpdateCollabParticipantNote,
} from '../../../store/collaborations/actions';
import { Counter } from '../../ui/Counter';

interface Props {
  note: TParticipantInfo['notes'][0];
  notes: TParticipantInfo['notes'];
  pos: number;
  maxCommentLength: number;
}

const DEL_BTN = {
  title: <DeleteSelectItem title="Delete" />,
  value: 'del',
  id: 'del',
};
const UDP_BTN = {
  title: <EditSelectItem />,
  value: 'upd',
  id: 'upd',
};

export const NoteItem: React.FC<Props> = ({
  note,
  notes,
  pos,
  maxCommentLength,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(note.body);
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const d = new Date(note.created_at);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const da = new Intl.DateTimeFormat('ru', { day: 'numeric' }).format(d);
  const m = d.getMinutes();
  const h = d.getHours();

  React.useEffect(() => {
    return () => {
      setLoading(false);
      // setIsOpen(false);
    };
  });

  const deleteHandler = () => {
    const updatedNotes = [...notes];
    setLoading(true);
    updatedNotes.splice(pos, 1);

    dispatch(
      DeleteCollabParticipantNote.request({
        noteId: note.id,
        newNotes: updatedNotes,
        callBack: () => {
          setLoading(false);
        },
      })
    );
  };

  const updateHandler = () => {
    const newNotes = [...notes];
    const curNote = newNotes.find((n) => n.id === note.id);
    if (!curNote)
      return dispatch(UpdateCollabParticipantNote.error('Could not find note'));
    curNote.body = val;
    setLoading(true);
    dispatch(
      UpdateCollabParticipantNote.request({
        noteId: note.id,
        body: val,
        newNotes,
        callBack: () => {
          setLoading(false);
          setIsEdit(false);
        },
      })
    );
  };

  return (
    <WrapperS style={{ position: 'relative' }}>
      <HeaderS>
        <TimeS>
          {`${da}.${mo}.${ye}`} &nbsp;&nbsp;
          {`${h}`.length > 1 ? h : '0' + h}:{`${m}`.length > 1 ? m : '0' + m}
        </TimeS>
        <ButtonMore
          onClick={(val) => {
            if (DEL_BTN.value === val) deleteHandler();
            if (UDP_BTN.value === val) setIsEdit(true);
          }}
          active={false}
          data={[UDP_BTN, DEL_BTN]}
        />
      </HeaderS>
      {isEdit ? (
        <div style={{ padding: '20px' }}>
          <div style={{ position: 'relative' }}>
            <TextArea
              onChange={(e) => {
                if (maxCommentLength <= e.target.value.length) return null;
                return setVal(e.target.value);
              }}
              value={val}
            />
            <Counter max={500} length={val.length} />
          </div>
          <WrapperBtns>
            <ButtonCancel
              onClick={() => {
                setIsEdit(false);
                setVal(note.body);
              }}
            />
            <ButtonSave loading={loading} onClick={updateHandler} />
          </WrapperBtns>
        </div>
      ) : (
        <BodyS isOpen={isOpen}>
          <TextWrapperS>{note.body}</TextWrapperS>
          {note.body && note.body.length > 46 && (
            <ButtonArrow onClick={() => setIsOpen((o) => !o)} />
          )}
        </BodyS>
      )}

      {loading && <Loader isGreen={true} />}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  margin: 12px 0 0;
  padding: 12px 0;
  border-radius: 6px;
  border: solid 1px #e4e4e4;
  background-color: #fbfbfb;
`;

const HeaderS = styled.div`
  display: flex;
  padding: 0 11px 10px;
  border-bottom: solid 1px #e4e4e4;
  align-items: center;
`;

const TimeS = styled.div`
  flex: 1;
  padding-right: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
`;

const TextWrapperS = styled.div``;

const BodyS = styled.div<{ isOpen?: boolean }>`
  padding: 10px 11px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .btn-arrow {
    margin-bottom: 2px;
    transform: rotate(180deg);

    svg {
      g {
        opacity: 1;
      }
      path {
        fill: ${COLORS_RGBA.accent(1)};
      }
    }
  }
  ${({ isOpen }) => {
    if (!isOpen) {
      return `
      ${TextWrapperS} {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 50%;
          white-space: nowrap;
      }
      
      .btn-arrow {
        transform: rotate(0deg);
      }
      `;
    }

    return ``;
  }}
`;

const WrapperBtns = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    margin: 0 0 0 16px;
    max-width: 100px;
  }
`;
