export const colNames = {
  1: 'name',
  2: 'cred',
  3: 'part',
  4: 'last_part',
  5: 'group_name',
} as const;

export interface BoxProps {
  name: string;
}

export interface DropResult {
  allowedDropEffect: string;
  dropEffect: string;
  name: string;
  id: string;
}

export const ItemTypes = {
  BOX: 'box',
};
