import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

export interface TTabsDataItem {
  id: number | string;
  name: string;
}
interface TabsProps {
  data: TTabsDataItem[];
  activeId: number | string | null;
  clickHandler: (itm: TTabsDataItem) => void;
}

export const Tabs: React.FC<TabsProps> = React.memo(
  ({ data, activeId, clickHandler }) => {
    if (!data || !Array.isArray(data)) return null;

    const render = () => {
      return data.map((tab) => (
        <TabS
          onClick={() => clickHandler(tab)}
          key={tab.id}
          active={activeId === tab.id}
        >
          {tab.name}
        </TabS>
      ));
    };

    return <WrapperS>{render()}</WrapperS>;
  }
);

const WrapperS = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #bfbebe;
    position: absolute;
    bottom: 0;
  }

  * {
  }
`;
const TabS = styled.div<{ active?: boolean }>`
  white-space: nowrap;
  margin-right: 24px;
  padding: 0 0 10px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  font-weight: 600;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: transparent;
    bottom: -1px;
    position: absolute;
    z-index: 2;
  }

  &:hover {
    color: ${COLORS.accentHover};
  }
  &:active {
    color: ${COLORS.accentActive};
  }

  ${({ active }) =>
    active
      ? `
  color: ${COLORS.accent};
  cursor: default;


  &:after {
    background: ${COLORS.accent};
  }
  `
      : ''}
`;
