import React from 'react';

interface IconSearchProps {}

export const SearchIcon: React.FC<IconSearchProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" opacity=".3">
        <g fill="#000">
          <g>
            <path
              d="M23.003 10c3.867 0 7.002 3.135 7.002 7.003 0 1.573-.518 3.024-1.394 4.193l3.096 3.096c.39.39.39 1.024 0 1.415-.39.39-1.024.39-1.415 0l-3.096-3.096c-1.169.876-2.62 1.394-4.193 1.394-3.868 0-7.003-3.135-7.003-7.002C16 13.135 19.135 10 23.003 10zm0 2C20.24 12 18 14.24 18 17.004c0 2.762 2.24 5.001 5.002 5.001 2.762 0 5.001-2.239 5.001-5.001 0-2.763-2.239-5.002-5.001-5.002z"
              transform="translate(-852 -98) translate(836 88)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
