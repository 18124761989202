import { TAssessmentsState } from '../../../../store/assessments/types';

export const getVersion = (
  assIds: string[],
  assessments: TAssessmentsState['answered']
): string => {
  let ver = '';
  const asses = assessments.filter((ass) => assIds.includes(ass.id));
  asses.forEach((ass) => {
    if (
      assessments.find(
        (itm) => itm.name === ass.name && itm.id !== ass.id && itm.version
      )
    )
      ver += `${ass.version}\n`;
  });
  const newVer = ver.replace('\n', '');
  if (newVer) return ver;
  else return '';
};
