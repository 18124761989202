import React from 'react';
import { SelectBtnS, SelectSpanS } from '../../ui/selects/DefaultSelect';
import styled from 'styled-components';
import { ArrowIcon, FiltersIcon } from '../../svgs';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { COLORS_RGBA } from '../../../utils/colors';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useRangeDom } from '../../../pages/Visualizations/hooks/useRangeDom';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

interface RangeFilterProps {
  range: number[];
  setRange: React.Dispatch<React.SetStateAction<number[]>>;
}

export const RangeFilter: React.FC<RangeFilterProps> = ({
  range,
  setRange,
}) => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [isOpen, setIsOpen] = React.useState(false);
  const { ref } = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  React.useEffect(() => {
    setRange([1, 100]);

    // eslint-disable-next-line
  }, [Workspaces.current]);

  useRangeDom(range);

  return (
    <WrapperS ref={ref}>
      <SelectBtnS isOpen={isOpen} onClick={() => setIsOpen(true)}>
        <span className="svgIconSelect">
          <FiltersIcon />
        </span>
        <SelectSpanS
          style={{ minWidth: '100px', color: 'black', opacity: '1' }}
        >
          Percentiles
        </SelectSpanS>
        <ArrowIcon />
      </SelectBtnS>
      {isOpen && (
        <AbsolutWrapperS>
          <PercentilesWrapperS>
            <p>Assessment Scoring Percentiles</p>
            <div
              style={{
                position: 'relative',
                // borderBottom: '1px solid rgba(0,0,0,0.1)',
                paddingBottom: '50px',
                paddingTop: '10px',
              }}
            >
              <Range
                min={1}
                max={100}
                defaultValue={range}
                draggableTrack={true}
                onAfterChange={setRange}
                tipProps={{
                  visible: true,
                  placement: 'bottom',
                }}
              />
            </div>
          </PercentilesWrapperS>
          {/* <ParticipantScoreWrapperS>
            <p>Some other filter</p>
            <div
              style={{
                position: 'relative',
                // borderBottom: '1px solid rgba(0,0,0,0.1)',
                paddingBottom: '50px',
                paddingTop: '10px',
              }}
            >
              <Range
                min={1}
                max={100}
                defaultValue={range}
                draggableTrack={true}
                onAfterChange={setRange}
                tipProps={{
                  visible: true,
                  placement: 'bottom',
                }}
              />
            </div>
          </ParticipantScoreWrapperS> */}
          {/*<WrapperCheckboxS>*/}
          {/*  <ArrowRightIcon />*/}
          {/*  <span>Direct Reports & Managers</span>*/}
          {/*  <CheckIcon />*/}
          {/*</WrapperCheckboxS>*/}
        </AbsolutWrapperS>
      )}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: relative;
  padding-top: 22px;
`;

const AbsolutWrapperS = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 320px;
  //max-height: 300px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.16);
  background-color: #ffffff;
  margin: 0;
  z-index: 2;
  padding: 10px 20px 0;
`;

const PercentilesWrapperS = styled.div`
  .rc-slider-track {
    background: ${COLORS_RGBA.accent(0.4)};
  }
  .rc-slider-handle {
    border-color: ${COLORS_RGBA.accent(0.8)};
  }

  .rc-slider-handle-dragging {
    border-color: ${COLORS_RGBA.accent(1)} !important;
    box-shadow: 0 0 5px ${COLORS_RGBA.accent(1)} !important;
  }

  .rc-slider-dot-active {
    border-color: ${COLORS_RGBA.accent(0.8)};
  }

  .rc-slider-tooltip-inner {
    background: none;
    color: black;
    box-shadow: none;
    font-weight: 600;

    &:after {
      content: '%';
    }
  }
`;

// const ParticipantScoreWrapperS = styled.div`
//   .rc-slider-track {
//     background: ${COLORS_RGBA.violet(0.4)};
//   }
//   .rc-slider-handle {
//     border-color: ${COLORS_RGBA.violet(0.8)};
//   }

//   .rc-slider-handle-dragging {
//     border-color: ${COLORS_RGBA.violet(1)} !important;
//     box-shadow: 0 0 5px ${COLORS_RGBA.violet(1)} !important;
//   }

//   .rc-slider-dot-active {
//     border-color: ${COLORS_RGBA.violet(0.8)};
//   }

//   .rc-slider-tooltip-inner {
//     background: none;
//     color: black;
//     box-shadow: none;
//     font-weight: 600;

//     &:after {
//       content: '%';
//     }
//   }
// `;

// const WrapperCheckboxS = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 20px 0;
//
//   span {
//     padding: 0 10px 0 20px;
//     white-space: nowrap;
//   }
//
//   svg {
//     &:nth-child(3) {
//       width: 30px;
//       height: 30px;
//     }
//     path {
//       fill: ${COLORS_RGBA.accent()};
//     }
//   }
// `;
