import React from 'react';

interface RowDataIconProps {}

export const RowDataIcon: React.FC<RowDataIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <g>
            <g>
              <g>
                <path
                  d="M16 0c1.657 0 3 1.343 3 3v12c0 1.657-1.343 3-3 3h-1v-1c0 1.657-1.343 3-3 3H4c-1.657 0-3-1.343-3-3V7c0-1.657 1.343-3 3-3V3c0-1.657 1.343-3 3-3h9zm-4 6H4c-.513 0-.936.386-.993.883L3 7v10c0 .513.386.936.883.993L4 18h8c.513 0 .936-.386.993-.883L13 17V7c0-.513-.386-.936-.883-.993L12 6zm4-4H7c-.513 0-.936.386-.993.883L6 3v1h6c1.657 0 3 1.343 3 3v9h1c.513 0 .936-.386.993-.883L17 15V3c0-.513-.386-.936-.883-.993L16 2zM7.999 8c.552 0 1 .448 1 1l-.001 3.593.29-.287c.389-.387 1.018-.387 1.408 0l.008.008c.39.387.393 1.018.005 1.409l-.005.005-1.98 1.965-.093.083c-.178.142-.396.224-.632.224-.237 0-.455-.083-.627-.22l-.05-.042-.05-.045-1.978-1.965c-.363-.365-.389-.93-.083-1.32l.083-.094.007-.008c.36-.357.924-.385 1.315-.082l.094.082.288.286V9c0-.552.448-1 1-1z"
                  transform="translate(-24 -448) translate(0 64) translate(0 370) translate(24 14)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
