import React from 'react';
import { WrapperItemSelectS } from './styles';
import {RecyclingIcon} from "../../svgs/recycling";

interface RecyclingSelectItemProps {
  title?: string;
}

export const RecyclingSelectItem: React.FC<RecyclingSelectItemProps> = ({
  title,
}) => {
  return (
    <WrapperItemSelectS>
      <RecyclingIcon opacity={'0.3'} />
      {title}
    </WrapperItemSelectS>
  );
};
