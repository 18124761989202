import {IMatchingTemplateGroupData} from "../../components/CsvAddParticipants/types";

export interface IMatchingTemplateData {
  first_name_col: string;
  last_name_col: string;
  email_col: string;
  phone_col: string;
  phone_code_col: string;
  payroll_id_col: string;
  full_name_col: string;
  hiring_date_col: string; 
  ethnicity_col: string; 
  gender_col: string; 
  work_location_name_col: string; 
  work_location_city_col: string;
  work_location_country_col: string;
  work_location_state_col: string;
  pay_group_col: string;
  job_col_idx: string;
  department_col_idx: string;
  segment_col_idx: string;
  home_base_col_idx: string;
  birth_date_col: string;
  groups: IMatchingTemplateGroupData[];
}

export interface IMatchingTemplates {
  id: string;
  first_row_is_header: boolean;
  data_with_header: IMatchingTemplateData;
  data_without_header: IMatchingTemplateData;
  comment_char: string;
  hiring_date_format: string;
  birth_date_format: string;
  phone_format: number;
  phone_code: string;
  workspace_id: string;
  created_at: Date | string;
  updated_at: Date | string;
}

export interface TMatchingTemplatesState {
  readonly loading: boolean;
  readonly current: IMatchingTemplates | null;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_ONE_R = '@@matchingTemplate/GET_ONE_R',
  GET_ONE_S = '@@matchingTemplate/GET_ONE_S',
  GET_ONE_E = '@@matchingTemplate/GET_ONE_E',

  SET_LOADING = '@@matchingTemplate/SET_LOADING',

  CLEAN_UP = '@@matchingTemplate/CLEAN_UP',
}

export default ActionTypes;
