import React from 'react';
import styled from 'styled-components';
import { ButtonLinkS } from '../ui/buttons/styles';
import {DeleteIcon, DownloadIcon} from '../svgs';
import { ButtonS } from '../ui/buttons/ButtonArrow';
import { deepClone } from '../../utils/deepClone';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { DeleteSnapshot } from '../../store/dataStory/actions';
import {ETypeGraph, TChartFilter} from '../../store/analytics/types';
import {TSnapshot} from "../../store/dataStory/types";
import html2canvas from "html2canvas";
import axios from "axios";
import {RenderImg} from "../../pages/RenderImg";

interface FileHeaderProps {
  isPreview: boolean;
  snapshotId: string | null;
  title: string;
  settings: {};
  refHeader: React.MutableRefObject<HTMLDivElement>;
}

export const FileHeader: React.FC<FileHeaderProps> = ({
  isPreview,
  snapshotId,
  title,
  settings,
  refHeader,
}) => {
  const { DataStory } = useSelector((store: AppStore) => store);
  const [loadImg, setLoadImg] = React.useState(false);
  const [current, setCurrent] = React.useState<TSnapshot>(null);

  const dispatch = useDispatch();



  const downloadSimpleImgHandler = (
      ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    if (current) {
      if (current.type === 'file') {
        setTimeout(
            () => downloadFile(current),
            current.data &&
            (current.data.type === ETypeGraph.type1 ||
                current.data.type === ETypeGraph.type4 ||
                current.data.type === ETypeGraph.type3)
                ? 1000
                : 0
        );
      } else {
        setTimeout(
            () => convertToCanvasSimple(ref, current),
            current.data &&
            (current.data.type === ETypeGraph.type1 ||
                current.data.type === ETypeGraph.type4 ||
                current.data.type === ETypeGraph.type3)
                ? 1000
                : 0
        );
      }
    }
  };

  const convertToCanvasSimple = async (
      ref: React.MutableRefObject<HTMLDivElement>,
      snapshot: TSnapshot
  ) => {
    html2canvas(ref.current, {
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight
    }).then((canvas) => {
      const dpi = 300; // Желаемое значение DPI
      const desiredWidth = 1280;
      const desiredHeight = 720;

      const newCanvasWidth = (desiredWidth / 72) * dpi;
      const newCanvasHeight = (desiredHeight / 72) * dpi;

      const newCanvas = document.createElement('canvas');
      newCanvas.width = newCanvasWidth;
      newCanvas.height = newCanvasHeight;
      const ctx = newCanvas.getContext('2d');
      ctx.fillStyle = '#ffffff'; // Используйте нужный вам белый цвет
      ctx.fillRect(0, 0, newCanvasWidth, newCanvasHeight);
      const screenshotWidth = canvas.width;
      const screenshotHeight = canvas.height;

      const scale = Math.min(
          newCanvasWidth / screenshotWidth,
          newCanvasHeight / screenshotHeight
      );
      const scaledWidth = screenshotWidth * scale;
      const scaledHeight = screenshotHeight * scale;

      const x = (newCanvasWidth - scaledWidth) / 2;
      const y = (newCanvasHeight - scaledHeight) / 2;

      ctx.drawImage(
          canvas,
          0,
          0,
          screenshotWidth,
          screenshotHeight,
          x,
          y,
          scaledWidth,
          scaledHeight
      );

      const dataUrl = newCanvas.toDataURL();
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${snapshot.position}_${snapshot.name}.jpg`;
      link.click();
      setLoadImg(false)
    });
  };

  const downloadFile = async (snapshot: TSnapshot) => {
    try {
      const resp = await axios.request({
        method: 'get',
        url: snapshot.url.url,
        responseType: 'blob'
      });
      const data = new Blob([resp.data], { type: 'image/jpg' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = `${snapshot.position}_${snapshot.name}.jpg`;
      link.click();
      setLoadImg(false)
    } catch (e) {
      console.log(e);
    }
  };
  const deleteFromDataStoryHandler = (snapshotId: string) => {
    const callBack = (success: boolean) => {
      if (!success) return null;
      const currentDataStory = deepClone(
        DataStory.data
      ) as typeof DataStory.data;
      const index = currentDataStory.snapshots.findIndex(
        (itm) => itm.id === snapshotId
      );
      currentDataStory.snapshots.splice(index, 1);
      dispatch(DeleteSnapshot.success(currentDataStory));
    };
    dispatch(DeleteSnapshot.request({ snapshotId: snapshotId, callBack }));
  };
  const genSettingsDesc = (fileSettings: TChartFilter) => {
    const groupNames: string[] = [];
    const assessmNames: string[] = [];
    const infoStroke: string[] = [];
    let connectednessScore = '';
    if (!fileSettings) return null;
    if (fileSettings.participantName) {
      infoStroke.push(`Participant: ${fileSettings.participantName}`);
      return infoStroke.join(', ') + connectednessScore;
    }
    if (fileSettings.is_all_participants) infoStroke.push('All Participants');
    if (
      fileSettings.groupsNames &&
      fileSettings.groupsNames[0] &&
      !fileSettings.is_all_participants
    )
      fileSettings.groupsNames.forEach((itm) => groupNames.push(itm));
    if (
      fileSettings.assesmNames &&
      fileSettings.assesmNames[0] &&
      !fileSettings.is_all_assessments
    )
      fileSettings.assesmNames.forEach((itm) => assessmNames.push(itm));
    if (fileSettings.period_of_answer_date) {
      if (fileSettings.period_of_answer_date.is_all_time)
        infoStroke.push('All Time');
      if (fileSettings.period_of_answer_date.start_date)
        infoStroke.push(
          `from ${fileSettings.period_of_answer_date.start_date} to ${fileSettings.period_of_answer_date.end_date}`
        );
      if (fileSettings.period_of_answer_date.is_last_year)
        infoStroke.push('Last Year');
      if (fileSettings.period_of_answer_date.is_last_quarter)
        infoStroke.push('Last Quarter');
      if (fileSettings.period_of_answer_date.is_last_month)
        infoStroke.push('Last Month');
    } else {
      if (fileSettings.is_all_time) infoStroke.push('All Time');
      if (fileSettings.start_date)
        infoStroke.push(
          `from ${fileSettings.start_date} to ${fileSettings.end_date}`
        );
      if (fileSettings.is_last_year) infoStroke.push('Last Year');
      if (fileSettings.is_last_quarter) infoStroke.push('Last Quarter');
      if (fileSettings.is_last_month) infoStroke.push('Last Month');
    }

    if (
      fileSettings.percentiles &&
      fileSettings.percentiles.start_range &&
      fileSettings.percentiles.end_range
    )
      infoStroke.push(
        `include percentiles from ${fileSettings.percentiles.start_range}% to ${fileSettings.percentiles.end_range}%`
      );
    if (fileSettings.connectedness_score)
      connectednessScore = `<br>Connectedness Rate: ${fileSettings.connectedness_score}`;

    return (
      `${groupNames[0] ? 'Groups: ' + groupNames.join(', ') + ' | ' : ''}` +
      ` ${
        assessmNames[0] ? 'Assessments: ' + assessmNames.join('; ') + ' | ' : ''
      }` +
      infoStroke.join(', ') +
      connectednessScore
    );
  };
  return (
    <HeaderSnapshotS ref={refHeader}>
      <WrapperTitleSelectS>
        <SettingsDescWrapper>
          <h5>{title}</h5>
          <p
            style={{
              overflowWrap: 'break-word',
              padding: 0,
              margin: 0,
            }}
            dangerouslySetInnerHTML={{
              __html: genSettingsDesc(settings as TChartFilter),
            }}
          ></p>
        </SettingsDescWrapper>
      </WrapperTitleSelectS>
      {!isPreview && (
          <DataStoryChartButtonS
              onClick={() => {
                const snapshot = DataStory.data.snapshots.find((ds) => ds.id === snapshotId)
                setCurrent(snapshot)
                setLoadImg(true);
              }}
          >

          <span>
            <AddTooltipWrapper>
              <span>Download this graph from Data Story</span>
            </AddTooltipWrapper>

           <DownloadIcon />
          </span>
          </DataStoryChartButtonS>
      )}

      {loadImg && (
          <RenderImg
              current={current}
              downloadHandler={downloadSimpleImgHandler}
              currentImg={1}
              imgGap={1}
          />
      )}

      {!isPreview && (
        <DataStoryChartButtonS
          onClick={() => deleteFromDataStoryHandler(snapshotId)}
        >
          <span>
            <AddTooltipWrapper>
              <span>Delete this image from Data Story</span>
            </AddTooltipWrapper>

            <DeleteIcon />
          </span>
        </DataStoryChartButtonS>
      )}
    </HeaderSnapshotS>
  );
};
const SettingsDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const AddTooltipWrapper = styled.div`
  position: absolute;
  font-size: 14px;
  bottom: 25px;
  right: -5px;
  background: white;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  min-width: 225px;
  padding: 5px;
  z-index: 100;
  display: none;
`;
const DataStoryChartButtonS = styled(ButtonS)`
  position: relative;
  margin-left: 16px;
  span {
    svg {
      path {
        fill: rgba(128, 128, 128, 0.42);
      }
    }
  }
  :hover {
    span {
      div {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
const WrapperTitleSelectS = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .ov-select {
    width: 150px;
  }

  .ov-select-wr {
    height: 30px;
    padding: 0;
  }
`;

const HeaderSnapshotS = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;

  h5 {
    padding-right: 10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #111111;
    margin: 0;
  }

  ${ButtonLinkS} {
    position: relative;

    span {
      position: absolute;
      bottom: 100%;
      background: white;
      right: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      transform: translateX(50%);
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      color: black;
      display: none;
    }

    &:hover {
      span {
        display: block;
      }
      svg path {
        fill: #fbc000;
      }
    }
    &:active {
      span {
        display: none;
      }
      svg path {
        fill: #c39705;
      }
    }
  }
`;
