import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../../store/applicationState';
import { Loader } from '../../ui';

interface ScoreProps {
  isDisplay: boolean;
}
export const Score: React.FC<ScoreProps> = ({ isDisplay }) => {
  const { Collaborations } = useSelector((store: AppStore) => store);
  return (
    <WrapperS displayType={isDisplay}>
      <TextS>
        {Collaborations && !Collaborations.additionalLoading ? (
          `Connectedness Rate: ${
            Collaborations.additionalInfo &&
            Collaborations.additionalInfo.connectedness_score
          }`
        ) : (
          <Loader isGreen />
        )}
      </TextS>
      {/* <AboutInfo title={'test'} /> */}
    </WrapperS>
  );
};

const WrapperS = styled.div<{ displayType: boolean }>`
  position: absolute;
  left: 20px;
  top: 75px;
  width: 250px;
  min-height: 42px;
  background: white;
  border-radius: 8px;
  padding: 12px 20px;
  display: ${({ displayType }) => (displayType ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
`;
const TextS = styled.span`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;
