import React from 'react';
import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import { CheckIcon } from '../../svgs';

interface CheckBoxProps {
  isCheck?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  isCheck,
  onChange,
  disabled,
}) => {
  return (
    <LabelS>
      <input
        type="checkbox"
        onChange={onChange}
        checked={isCheck}
        disabled={disabled}
      />
      <span>{isCheck ? <CheckIcon /> : null}</span>
    </LabelS>
  );
};

const LabelS = styled.label`
  display: block;
  cursor: pointer;
  input {
    display: none;
  }

  span {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: solid 1px ${COLORS_RGBA.default(0.12)};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
      path {
        fill: #fff;
      }
    }
  }

  &:hover {
    span {
      border-color: ${COLORS.accent};
    }
  }
  &:active {
    span {
      border-color: ${COLORS.accentActive};
    }
  }

  input:checked ~ span {
    background: ${COLORS.accent};
  }
  input:disabled ~ span {
    background: ${COLORS.secondary};
    border-color: ${COLORS.secondary};
    cursor: default;
  }
`;
