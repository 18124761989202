import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { AppStore } from '../../store/applicationState';
import { CreateCat, EditCat, GetCats } from '../../store/categories/actions';
import { TCat } from '../../store/categories/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { convertToId } from '../../utils/convertToId';
import { deepClone } from '../../utils/deepClone';
import { TEXT } from '../../utils/textConst';
import { Alert, Input, Loader, TextArea } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';

interface NewCategoryProps {
  editObj: TCat | null;
  closeHandler: () => void;
}

const catName = 'Category Name';
const desc = 'Description';

const NAME_ID = convertToId(catName);
const DESC_ID = convertToId(desc);

export const NewCategory: React.FC<NewCategoryProps> = ({
  closeHandler,
  editObj,
}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const { User, Cats } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { form, updateForm, onChangeHandler } = useForm({
    [NAME_ID]: {
      value: editObj ? editObj.name : '',
    },
    [DESC_ID]: {
      value: editObj ? editObj.description : '',
    },
  });

  React.useEffect(() => {
    dispatch(GetCats.error(''));
  }, [dispatch]);

  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };
  const saveHandler = () => {
    const nameValue = form[NAME_ID].value;
    const descValue = form[DESC_ID].value;

    if (!nameValue)
      return updateForm(NAME_ID, { errorText: TEXT.isRequired, value: '' });

    if (!User.data || (User.data && !User.data.team_id))
      return dispatch(CreateCat.error('No user data, ' + TEXT.tryLater));

    const callBack = (success: boolean) =>
      success ? closeModalHandler() : null;
    if (!editObj)
      return dispatch(
        CreateCat.request({
          data: {
            team_id: User.data.team_id,
            name: nameValue,
            description: descValue,
          },
          callBack,
        })
      );

    const editCallBack = (success: boolean) => {
      if (!success) return null;
      const newCats = deepClone(Cats.data) as TCat[];
      const newCat = newCats.find((cat) => `${cat.id}` === `${editObj.id}`);
      if (!newCat)
        return dispatch(EditCat.error('Could not find cat ' + TEXT.tryLater));

      newCat.name = nameValue;
      newCat.description = descValue;

      dispatch(EditCat.success(newCats));
      return closeModalHandler();
    };

    return dispatch(
      EditCat.request({
        data: {
          name: nameValue,
          description: descValue,
        },
        catId: editObj.id,
        callBack: editCallBack,
      })
    );
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '416px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>
            {editObj ? 'Edit Category' : 'New Category'}
          </Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <form
            onKeyDown={(e: {
              keyCode: number;
              preventDefault: () => void;
              shiftKey: any;
            }) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                saveHandler();
              }
            }}
          >
            <Input
              onChange={onChangeHandler}
              value={form[NAME_ID].value}
              name={NAME_ID}
              label={catName}
              placeholder={catName}
              errorText={form[NAME_ID].errorText}
              autoFocus
            />
            <TextArea
              stylesWrapper={{ marginTop: '20px' }}
              onChange={onChangeHandler}
              value={form[DESC_ID].value}
              name={DESC_ID}
              label={desc}
              placeholder={desc}
              errorText={form[DESC_ID].errorText}
            />
          </form>

          {Cats.errors ? <Alert text={Cats.errors} /> : null}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonDefS isSave onClick={saveHandler}>
              {Cats.loading ? <Loader size={0.5} /> : 'Save'}
            </ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
