import React, { ReactElement } from 'react';

interface Props {
  color?: string;
  opacity?: string;
}

export function ProgressIcon({ color = '#fff', opacity = '1' }: Props): ReactElement {
  return (
    <svg width="167px" height="6px" viewBox="0 0 167 6">
      <title>bar</title>
      <desc>Created with Sketch.</desc>
      <g
        id="onboarding"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={opacity}
        strokeLinecap="round"
      >
        <g
          id="lp_onboarding_step_1"
          transform="translate(-1233.000000, -135.000000)"
          fillRule="nonzero"
          stroke={color}
        >
          <g id="progress" transform="translate(1232.000000, 107.000000)">
            <g id="progressbar" transform="translate(1.000000, 28.000000)">
              <path
                d="M0,3 L0.248923344,3.134274 C5.87902389,6.1712577 12.6594376,6.1712577 18.2895382,3.134274 L18.7873849,2.865726 C21.7018936,1.29358437 24.9246604,0.535286197 28.1365362,0.59083147 C31.1291996,0.642585747 34.1124079,1.40088392 36.8279997,2.865726 L37.3258464,3.134274 C42.955947,6.1712577 49.7363607,6.1712577 55.3664613,3.134274 L55.864308,2.865726 C61.4944085,-0.171257702 68.2748223,-0.171257702 73.9049228,2.865726 L74.4027695,3.134274 C77.3701611,4.73494167 80.6570988,5.49196405 83.9267437,5.40534115 C86.8606715,5.32761241 89.7806754,4.57059003 92.4433843,3.134274 L92.941231,2.865726 C98.5713316,-0.171257702 105.351745,-0.171257702 110.981846,2.865726 L111.479693,3.134274 C111.656234,3.22950364 111.833906,3.32174719 112.012638,3.41100465 C117.533884,6.16827162 124.066748,6.07602807 129.520307,3.134274 L130.018154,2.865726 C133.128608,1.18788768 136.590163,0.437002451 140.014874,0.61307031 C142.789086,0.755695386 145.539123,1.50658062 148.058769,2.865726 L148.556616,3.134274 C154.186716,6.1712577 160.96713,6.1712577 166.597231,3.134274 L166.846154,3"
                id="bar"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
