import React from 'react';
import styled from 'styled-components';
import JSZip from 'jszip';
import { Snapshot } from '../components/DataStory/Snapshot';
import { TSnapshot } from '../store/dataStory/types';
import { SnapshotWrapperS } from './styles/DataStoryViewStyles';
import { Loader } from '../components/ui';
import { COLORS } from '../utils/colors';
interface RenderImgProps {
  current: TSnapshot;
  downloadHandler: (
    ref: React.MutableRefObject<HTMLDivElement>,
    zip?: JSZip
  ) => void;
  zip?: JSZip;
  currentImg: number;
  imgGap: number;
}

export const RenderImg: React.FC<RenderImgProps> = ({
  current,
  downloadHandler,
  zip,
  imgGap,
  currentImg,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    downloadHandler(ref, zip);
    // eslint-disable-next-line
  }, [current]);
  return (
    <>
      <WhiteBgS>
        <DownloadSnapshotWrapperS ref={ref}>
          <Snapshot
            name={current.name}
            type={current.type}
            chart={current.data}
            comment={current.comment}
            snapshotId={current.id}
            settings={current.data && current.data.settings}
            preview={true}
            isDownload={true}
            link={current.url}
          />
        </DownloadSnapshotWrapperS>
      </WhiteBgS>
      <DivS>
        <Loader isGreen />
        <TextS>{`${currentImg}/${imgGap}`}</TextS>
      </DivS>
    </>
  );
};
const TextS = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  display: flex;
  color: ${COLORS.accent};
  justify-content: center;
`;
const DivS = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
`;
const WhiteBgS = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
  overflow: auto;
  div {
    max-height: none !important;
    max-width: 700px;
    margin: 0;
  }
`;
const DownloadSnapshotWrapperS = styled(SnapshotWrapperS)`
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  div {
    box-shadow: none;
  }
`;
