import { Reducer } from 'redux';
import ActionTypes, { TMessageTemplatesState } from './types';

export const initialState: TMessageTemplatesState = {
  loading: false,
  data: null,
  errors: undefined,
  current: null,
};

const reducer: Reducer = (
  state = initialState,
  action
): TMessageTemplatesState => {
  switch (action.type) {
    case ActionTypes.CREATE_TEMPLATE_R:
    case ActionTypes.DELETE_TEMPLATE_R:
    case ActionTypes.GET_ALL_TEMPLATES_R:
      return { ...state, loading: true };
    case ActionTypes.DELETE_TEMPLATE_S:
      return { ...state, data: action.payload, loading: false, errors: '' };
    case ActionTypes.GET_ALL_TEMPLATES_S:
      return { ...state, data: action.payload, loading: false, errors: '' };
    case ActionTypes.CREATE_TEMPLATE_S:
      return {
        ...state,
        data: [...state.data, action.payload],
        current: action.payload,
        loading: false,
        errors: '',
      };
    case ActionTypes.SET_TEMPLATE:
      return {
        ...state,
        current: action.payload,
        errors: '',
      };
    case ActionTypes.CREATE_TEMPLATE_E:
    case ActionTypes.DELETE_TEMPLATE_E:
    case ActionTypes.GET_ALL_TEMPLATES_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
export { reducer as MessageTemplatesReducer };
