import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { Alert, DefaultSelect, Loader } from '../../components/ui';
import {
  ButtonBorderedS,
  ButtonDefS,
} from '../../components/ui/buttons/styles';
import { SelectItemType } from '../../components/ui/selects/DefaultSelect';
import { TGroup } from '../../store/groups/types';
import { TPackage } from '../../store/packages/types';
import { TWorkspace } from '../../store/workspaces/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { ROUTE_PATH } from '../../utils/routes';
import { TEXT } from '../../utils/textConst';

interface AreasOfFocusProps {
  data: [];
}

export const AreasOfFocus: React.FC<AreasOfFocusProps> = () => {
  const [loading, setLoading] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [permissionError, setPermissionError] = React.useState(true);
  const [errors, setErrors] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [teams, setTeams] = React.useState<SelectItemType[]>([]);
  const [currentTeam, setCurrentTeam] = React.useState<SelectItemType | null>(
    null
  );
  const [worksapces, setWorkspaces] = React.useState<SelectItemType[]>([]);
  const [currentWorkspace, setCurrentWorkspace] =
    React.useState<SelectItemType | null>(null);
  const [packages, setPackages] = React.useState<SelectItemType[]>([]);
  const [currentPackage, setCurrentPackage] =
    React.useState<SelectItemType | null>(null);
  const [cycles, setCycles] = React.useState<SelectItemType[]>([]);
  const [currentCycle, setCurrentCycle] = React.useState<SelectItemType | null>(
    null
  );
  const [groups, setGroups] = React.useState<SelectItemType[]>([
    {
      title: 'All Participants',
      value: 'all',
      id: '',
    },
  ]);
  const [currentGroup, setCurrentGroup] = React.useState<SelectItemType | null>(
    groups[0]
  );

  const history = useHistory();

  const getTeams = React.useCallback(async () => {
    interface ITeamsData {
      id: string;
      name: string;
    }

    try {
      setErrors('');
      const data = (await callApi({
        path: API_ROUTE_PATH.admins.teamsPath,
        method: 'get',
      })) as ITeamsData[];

      if (
        !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
      ) {
        console.log(data, 'error data');
        return setErrors(TEXT.someError);
      }

      const newTeams: typeof teams = [];

      data.forEach((itm) => {
        newTeams.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });
      setPermissionError(false);

      return setTeams(newTeams);
    } catch (e) {
      setErrors(`${e}`);
      setPermissionError(true);
      window.location.href = '/';
    } finally {
      setLoading(false);
    }
  }, []);

  const getWorkspaces = React.useCallback(async () => {
    try {
      setErrors('');
      const data = (await callApi({
        path: API_ROUTE_PATH.admins.workspacesPath(currentTeam.id as string),
        method: 'get',
      })) as TWorkspace[];

      if (
        !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
      ) {
        console.log(data, 'error data');
        return setErrors(TEXT.someError);
      }

      const newWorkspaces: typeof worksapces = [];

      data.forEach((itm) => {
        newWorkspaces.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });

      return setWorkspaces(newWorkspaces);
    } catch (e) {
      setErrors(`${e}`);
    } finally {
      setLoading(false);
    }
  }, [currentTeam]);

  const getPackages = React.useCallback(async () => {
    try {
      setErrors('');
      const data = (await callApi({
        path: API_ROUTE_PATH.admins.packagesPath(currentWorkspace.id as string),
        method: 'get',
      })) as TPackage[];

      if (
        !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
      ) {
        console.log(data, 'error data');
        return setErrors('This workspace have no packages with OrgVitals 20 ');
      }

      const newPackages: typeof packages = [];

      data.forEach((itm) => {
        newPackages.push({
          title: itm.name,
          value: itm.id,
          id: itm.id,
        });
      });

      return setPackages(newPackages);
    } catch (e) {
      setErrors(`${e}`);
    } finally {
      setLoading(false);
    }
  }, [currentWorkspace]);

  const getCycles = React.useCallback(async () => {
    try {
      interface ICyclesData {
        from: Date;
        link: string;
      }

      setErrors('');
      const data = (await callApi({
        path: API_ROUTE_PATH.admins.cyclesPathAll(currentPackage.id as string),
        method: 'get',
      })) as ICyclesData[];

      // if (
      //   !(
      //     data &&
      //     Array.isArray(data) &&
      //     data[0] &&
      //     data[0].from &&
      //     data[0].link
      //   )
      // ) {
      //   console.log(data, 'error data');
      //   return setErrors('This package have no closed cycles for OrgVitals 20');
      // }

      const newCycles: typeof cycles = [];

      data.forEach((itm) => {
        const date = new Date(itm.from);
        newCycles.push({
          title: `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`,
          value: itm.link,
          id: itm.link,
        });
      });

      return setCycles(newCycles);
    } catch (e) {
      setErrors(`${e}`);
    } finally {
      setLoading(false);
    }
  }, [currentPackage]);

  const getGroups = React.useCallback(async () => {
    try {
      setErrors('');
      const data = (await callApi({
        path: API_ROUTE_PATH.admins.groupsPath(
          currentWorkspace.id as string,
          currentPackage.id as string,
          currentCycle.id as string
        ),
        method: 'get',
      })) as TGroup[];

      // if (
      //   !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
      // ) {
      //   console.log(data, 'error data');
      //   return setErrors('This cycle have no groups');
      // }

      const newGroups: typeof groups = [
        {
          title: 'All Participants',
          value: 'all',
          id: '',
        },
      ];

      if (
        data &&
        Array.isArray(data) &&
        data[0] &&
        data[0].id &&
        data[0].name
      ) {
        data.forEach((itm) => {
          newGroups.push({
            title: itm.name,
            value: itm.id,
            id: itm.id,
          });
        });
      }

      setCurrentGroup(newGroups[0]);
      return setGroups(newGroups);
    } catch (e) {
      setErrors(`${e}`);
    } finally {
      setLoading(false);
    }
  }, [currentWorkspace, currentPackage, currentCycle]);

  const downloadReport = async () => {
    try {
      setExportLoading(true);
      const response = await callApi({
        path: API_ROUTE_PATH.admins.areasReport,
        method: 'post',
        data: {
          workspace_id: currentWorkspace.id,
          package_id: currentPackage.id,
          link: currentCycle.id,
          group_id: currentGroup ? currentGroup.id : '',
        },
        responseType: 'blob',
      });
      // const jsonMimeType = 'application/json';
      // const dataType = response.type;
      // const isBlob = response instanceof Blob && dataType !== jsonMimeType;

      const outputFilename = `Areas Of Focus Calculation ${currentWorkspace.title} ${currentPackage.title} ${currentCycle.title} ${currentGroup.title}.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setErrors(`${e}`);
    } finally {
      setExportLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    getTeams();
  }, [getTeams]);

  React.useEffect(() => {
    if (currentTeam) {
      setCurrentWorkspace(null);
      setCurrentPackage(null);
      setCurrentCycle(null);
      setCurrentGroup(null);
      getWorkspaces();
    }
  }, [currentTeam, getWorkspaces]);

  React.useEffect(() => {
    if (currentWorkspace) {
      setCurrentPackage(null);
      setCurrentCycle(null);
      setCurrentGroup(null);
      getPackages();
    }
  }, [currentWorkspace, getPackages]);

  React.useEffect(() => {
    if (currentPackage) {
      setCurrentCycle(null);
      setCurrentGroup(null);
      getCycles();
    }
  }, [currentPackage, getCycles]);

  React.useEffect(() => {
    if (currentCycle) {
      getGroups();
    }
  }, [currentCycle, getGroups]);

  const clickHandler = (currentStep: number) => {
    switch (currentStep) {
      case 1: {
        setStep(currentStep + 1);
        return;
      }
      case 2: {
        setStep(currentStep + 1);
        return;
      }
      case 3: {
        downloadReport();
        return;
      }
    }
  };

  const renderBody = () => {
    if (permissionError) return <Loader isFixed isGreen />;
    if (loading) return <Loader isFixed isGreen />;
    if ('') return <Alert text={''} />;
    switch (step) {
      case 1: {
        return (
          <MainBodyWrapper>
            <FirstSelectGroupWrapper>
              <DefaultSelect
                data={teams}
                onChange={setCurrentTeam}
                selected={currentTeam}
                label={'Team'}
                placeHolder={'Select Team'}
                isSearch={true}
              />

              <DefaultSelect
                data={worksapces}
                onChange={setCurrentWorkspace}
                selected={currentWorkspace}
                label={'Workspace'}
                placeHolder={'Select Workspace'}
                isSearch={true}
              />
            </FirstSelectGroupWrapper>
          </MainBodyWrapper>
        );
      }

      case 2: {
        return (
          <MainBodyWrapper>
            <SecondSelectGroupWrapper>
              <DefaultSelect
                data={packages}
                onChange={setCurrentPackage}
                selected={currentPackage}
                label={'Package'}
                placeHolder={'Select Package'}
                isSearch={true}
              />
            </SecondSelectGroupWrapper>
          </MainBodyWrapper>
        );
      }

      case 3: {
        return (
          <MainBodyWrapper>
            <FirstSelectGroupWrapper>
              <DefaultSelect
                data={cycles}
                onChange={setCurrentCycle}
                selected={currentCycle}
                label={'Cycle Start Date'}
                placeHolder={'Select Cycle Start Date'}
              />
              <DefaultSelect
                data={groups}
                onChange={setCurrentGroup}
                selected={currentGroup}
                label={'Group'}
                placeHolder={'Select Group'}
                isSearch={true}
              />
            </FirstSelectGroupWrapper>
          </MainBodyWrapper>
        );
      }
    }
  };

  return (
    <>
      <div style={{ padding: '100px 20px', maxWidth: '900px', margin: 'auto' }}>
        <TitleWrapperS>
          <h1>Areas Of Focus</h1>
          <ButtonBorderedS
            style={{ maxWidth: '200px' }}
            onClick={() => history.push(ROUTE_PATH.admin)}
          >
            Go to the main page
          </ButtonBorderedS>
        </TitleWrapperS>

        {renderBody()}

        {loading ? null : (
          <WrapperBtnS>
            <div>
              {step !== 1 && (
                <ButtonBorderedS
                  style={{ maxWidth: '150px' }}
                  onClick={() => {
                    setErrors('');
                    setStep(step - 1);
                  }}
                >
                  Go Back
                </ButtonBorderedS>
              )}
            </div>
            <ButtonDefS
              onClick={() => {
                clickHandler(step);
              }}
              disabled={
                (step === 1 &&
                  (!currentTeam || !currentWorkspace || !!errors)) ||
                (step === 2 && (!currentPackage || !!errors)) ||
                (step === 3 && (!currentCycle || !!errors || exportLoading))
              }
              style={{ maxWidth: '150px' }}
            >
              {!exportLoading && (step === 3 ? 'Export' : 'Next')}
              {exportLoading && <Loader />}
            </ButtonDefS>
          </WrapperBtnS>
        )}
        {errors && <Alert text={errors} />}
      </div>
    </>
  );
};
const TitleWrapperS = styled(PageTitleWrapperS)`
  justify-content: space-between;
  width: 100%;
`;

const WrapperBtnS = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainBodyWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FirstSelectGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const SecondSelectGroupWrapper = styled.div`
  min-width: 50%;
`;
