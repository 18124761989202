import React from 'react';
import styled from 'styled-components';
import { AlphabetLetters } from '../../utils/alphabet';
import { ArrowRightIcon } from '../svgs';
import { DefaultSelect, Input } from '../ui';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { SelectColumnWrapperS } from './styles';
import {ColumnSelectProps} from './types';

export const ColumnSelect: React.FC<ColumnSelectProps> = React.memo(
  ({
    columns,
    label,
    name,
    selectHandler,
    isFirstRowHeader,
       isUseMatchingTemplate,
       isExistField,
       isExistFields,
       selectExistFieldHandler,
    selectedItems,
    openSelectHandler,
  }) => {
    const [svgColumns, setSvgColumns] = React.useState<SelectItemType[]>([]);
    const [selectedSvgColumn, setSelectedSvgColumn] =
      React.useState<SelectItemType | null>(null);

    React.useEffect(() => {
      const newColumns: SelectItemType[] = [
        { id: -1, value: '', title: 'Empty' },
      ];


      if (selectedItems && isUseMatchingTemplate) {
          let matchingField = selectedItems[name] as SelectItemType || null
          setSelectedSvgColumn(matchingField ? matchingField : null)

          if (matchingField) {
              selectHandler(matchingField)
          }
      } else {
          setSelectedSvgColumn(null);
      }


      columns.forEach((c, i) => {
        newColumns.push({
          id: i,
          value: `${i}`,
          title: isFirstRowHeader ? c : AlphabetLetters[i],
        });
      });
      setSvgColumns(newColumns);
        //eslint-disable-next-line
    }, [selectedItems, columns, isFirstRowHeader]);




    return (
      <WrapperS>
        {label && (
          <Input value={label} name={name} readOnly onChange={() => null} />
        )}
        <ArrowRightIcon />
        <DefaultSelect
          placeHolder="Column name"
          isOpenHandler={openSelectHandler}
          data={svgColumns}
          selected={selectedSvgColumn}
          isExistField={isExistField}
          onChange={(e) => {
            setSelectedSvgColumn(e);
            selectHandler(e);
            selectExistFieldHandler(name);
          }}
        />
      </WrapperS>
    );
  }
);

const WrapperS = styled(SelectColumnWrapperS)``;

