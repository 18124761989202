import React from 'react';

export const WorkspaceAvatarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-288 -199) translate(288 199)">
                <circle
                  cx="50"
                  cy="50"
                  r="49"
                  fill="#F8F8F8"
                  stroke="#A3AFA9"
                  strokeOpacity=".2"
                  strokeWidth="2"
                />
                <g fill="#A3AFA9" fillRule="nonzero" opacity=".484">
                  <path
                    d="M22.775 19.999c-1.239-1.927-3.372-3.165-5.711-3.165-.413 0-.757.206-.964.55L13.486 21.1c-.344.481-1.032.481-1.376 0l-2.615-3.716c-.206-.344-.62-.55-.963-.55-3.165 0-5.917 2.201-6.605 5.298L.413 28.806c-.344 1.514.825 2.89 2.34 2.89h15.412l1.513-6.674c.413-1.927 1.514-3.716 3.097-5.023zM12.798 15.87c3.509 0 6.399-2.89 6.399-6.398V6.788c0-3.509-2.89-6.399-6.4-6.399C9.29.39 6.4 3.28 6.4 6.79v2.683c0 3.509 2.89 6.398 6.399 6.398zM65.64 28.806l-1.513-6.674c-.688-3.097-3.44-5.298-6.606-5.298-.412 0-.756.206-.963.55L53.943 21.1c-.344.481-1.032.481-1.376 0l-2.614-3.716c-.207-.344-.62-.55-.964-.55-2.339 0-4.472 1.238-5.71 3.165 1.513 1.307 2.614 3.096 3.096 5.091l1.514 6.675H63.3c1.583 0 2.683-1.445 2.34-2.96zM53.255 15.87c3.51 0 6.4-2.89 6.4-6.398V6.788c0-3.509-2.89-6.399-6.4-6.399-3.509 0-6.398 2.89-6.398 6.4v2.683c0 3.509 2.89 6.398 6.398 6.398zM43.898 25.64c-.688-3.095-3.44-5.297-6.605-5.297-.413 0-.757.206-.964.55l-2.614 3.716c-.344.481-1.032.481-1.376 0l-2.615-3.716c-.206-.344-.62-.55-.963-.55-3.165 0-5.918 2.202-6.606 5.298l-1.513 6.674c-.344 1.514.825 2.89 2.339 2.89h20.091c1.514 0 2.684-1.445 2.34-2.89l-1.514-6.674zM26.628 10.297v2.684c0 3.509 2.89 6.399 6.399 6.399s6.399-2.89 6.399-6.4v-2.683c0-3.509-2.89-6.399-6.4-6.399-3.508 0-6.398 2.89-6.398 6.4z"
                    transform="translate(17 32)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
