import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../utils/colors';
import { ArrowIcon } from '../../svgs';

interface ButtonArrowProps {
  onClick: () => void;
  active?: boolean;
}

export const ButtonArrow: React.FC<ButtonArrowProps> = ({ onClick, active }) => {
  return (
    <ButtonS onClick={onClick} className="btn-arrow" active={active}>
      <ArrowIcon />
    </ButtonS>
  );
};

export const ButtonS = styled.button<{ active?: boolean}>`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  width: 12px;
  height: 12px;
  outline: 0;
  padding: 0;
  transition: 0.1s;

  ${({ active }) =>
    active
      ? `
   transform: translateY(1px) scale(1, -1);
  `
      : ''}

  svg {
    g,
    path {
      transition: 0.1s;
    }
  }

  &:hover {
    svg {
      g {
        opacity: 1;
      }
      path {
        fill: ${COLORS.accentHover};
      }
    }
  }
  &:active {
    svg {
      g {
        opacity: 1;
      }
      path {
        fill: ${COLORS.accentActive};
      }
    }
  }
`;
