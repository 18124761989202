import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import { COLORS_RGBA } from '../../../utils/colors';
import { delay } from '../../../utils/helpers';
import { VALUES } from '../../../utils/valueConst';
import { MoreIcon } from '../../svgs';
import { ISelectItemData } from './types';

interface ButtonMoreProps {
  onClick: (
    itm: ISelectItemData['value'],
    subItem?: ISelectItemData['value']
  ) => void;
  active?: boolean;
  data: ISelectItemData[];
  disabled?: boolean;
  hidden?: boolean;
}

export const ButtonMore: React.FC<ButtonMoreProps> = ({
  active,
  data,
  onClick,
  disabled,
  hidden,
}) => {
  const [isShow, setIsShown] = React.useState(false);

  const listRef = React.useRef<HTMLUListElement>(null);

  React.useEffect(() => {
    return () => {
      setIsShown(false);
    };
  }, []);

  const hideList = async () => {
    if (listRef && listRef.current) {
      listRef.current.classList.remove(SelectClasses.in);
      listRef.current.classList.add(SelectClasses.out);
      await delay(100);

      setIsShown(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    if (isShow) {
      hideList();
    }
  });

  return (
    <WrapperS ref={ref}>
      {!hidden ? <ButtonMoreS
        onClick={() => setIsShown(true)}
        type="button"
        className="btn-more"
        active={active}
      >
        <MoreIcon />
      </ButtonMoreS> : null}
      {isShow && (
        <ListItemsS
          ref={listRef}
          className={`${mainClass} ${SelectClasses.in}`}
        >
          {data.map((lItem) => (
            <ItemListS
              key={lItem.id}
              isRed={lItem.value === VALUES.delete}
              onClick={() => {
                if (lItem.value === VALUES.move) return null;
                onClick(lItem.value);
                return hideList();
              }}
              disabled={
                (lItem.value === VALUES.edit && disabled) ||
                (lItem.value === VALUES.delete && disabled)
              }
            >
              {lItem.title}
              <ListItemsS>
                {lItem.subItems &&
                  lItem.subItems.map((subItm) => (
                    <ItemListS
                      key={subItm.id}
                      onClick={() => {
                        onClick(lItem.value, subItm.value);
                        hideList();
                      }}
                    >
                      {subItm.title}
                    </ItemListS>
                  ))}
              </ListItemsS>
              <InfoInnerS className={'infoItem'}>
                This option is disabled due to automatic synchronization with
                HRIS
              </InfoInnerS>
            </ItemListS>
          ))}
        </ListItemsS>
      )}
    </WrapperS>
  );
};

const bRad = '8px';
const WrapperS = styled.div`
  position: relative;
`;

const InfoInnerS = styled.div`
  background: white;
  display: none;
  width: 250px;
  box-shadow: 0 4px 23px rgba(42, 47, 42, 0.24);
  padding: 8px;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 100%;
  border-radius: 10px;
  z-index: 999;
`;

export const ListItemsS = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: ${bRad};
  box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
  border: solid 1px rgba(162, 165, 162, 0.15);
  padding: 0;
  margin: 0;
  z-index: 2;
  min-width: 140px;
`;

export const ItemListS = styled.li<{ isRed?: boolean; disabled?: boolean }>`
  list-style: none;
  padding: 10px 15px;
  cursor: pointer;
  background: transparent;
  position: relative;
  transition: 0.1s;
  span {
    ${({ disabled }) => (disabled ? `opacity: 0.4;` : null)}
  }
  &:hover {
    background: ${COLORS_RGBA.accent(0.1)};
    .infoItem {
      ${({ disabled }) => (disabled ? `display:block` : null)}
    }
  }
  &:active {
    background: ${COLORS_RGBA.accent(0.3)};
    ${({ disabled }) => (disabled ? `pointer-events: none;` : null)}
  }

  &:first-child {
    border-top-left-radius: ${bRad};
    border-top-right-radius: ${bRad};
  }
  &:last-child {
    border-bottom-left-radius: ${bRad};
    border-bottom-right-radius: ${bRad};
  }

  ${({ isRed }) =>
    isRed
      ? `
      &:hover {
        background: ${COLORS_RGBA.danger(0.1)};
      }
      &:active {
        background: ${COLORS_RGBA.danger(0.3)};
      }
  `
      : null}

  & > ul {
    position: absolute;
    right: 100%;
    top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
    display: none;
    li {
      border: 0;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &:hover {
    & > ul {
      display: block;
    }
  }
`;

const ButtonMoreS = styled.button<{ active?: boolean }>`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  width: 24px;
  height: 24px;
  outline: 0;
  padding: 0;

  ${({ active }) =>
    active
      ? `
      background: ${COLORS_RGBA.accent(0.2)};

      svg {
      path {
        fill: ${COLORS_RGBA.accent(1)};
        opacity: 1;
      }
    }
  `
      : null};

  svg {
    g {
      transition: 0.1s;
    }
  }

  &:hover {
    color: white;
    background: ${COLORS_RGBA.accent(0.2)};

    svg {
      path {
        fill: ${COLORS_RGBA.accent(1)};
        opacity: 1;
      }
    }
  }
  &:active {
    color: white;
    background: ${COLORS_RGBA.accent(0.3)};
  }
`;
