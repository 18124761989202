import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, {
  ACTION_CONST,
  TAdditionalInfo,
  TWorkspace,
} from './types';

const GetWorkspacesActions = new Actions(ACTION_CONST.getByTeam, ActionTypes);

export const setWorkspace = (payload: TWorkspace) =>
  action(ActionTypes.SET_WORKSPACE, payload);

export interface TGet_By_Team_R extends TDefRequest {
  teamId: string;
}

export const GetWorkspacesByTeam = {
  request: (payload: TGet_By_Team_R) => GetWorkspacesActions.request(payload),
  success: (payload: TWorkspace[]) => GetWorkspacesActions.success(payload),
  error: (message: string) => GetWorkspacesActions.error(message),
  setWorkspace,
};

const EditWorkspaceActions = new Actions(ACTION_CONST.edit, ActionTypes);

export interface TEdit_R extends TDefRequest {
  data: {
    name: string;
    additional_info: TAdditionalInfo;
    group_classification: string;
    is_logo_changed: boolean;
    file: File | null;
  };
  src: string;
  workspaceId: string;
}
export const EditWorkspace = {
  request: (payload: TEdit_R) => EditWorkspaceActions.request(payload),
  success: (payload: TWorkspace[]) => EditWorkspaceActions.success(payload),
  error: (message: string) => EditWorkspaceActions.error(message),
  setWorkspace,
};

const CreateWorkspaceActions = new Actions(ACTION_CONST.create, ActionTypes);

export interface TCreate_R extends TDefRequest {
  data: {
    team_id: string;
    created_by_id: string;
    name: string;
    additional_info: {
      formation_type: string;
      group_type: string;
      organization_size: string;
      organization_industry: string;
      organization_geographic_structure: string;
      organization_remote_work: string;
      organization_location: string;
    };
  };
}
export const CreateWorkspace = {
  request: (payload: TCreate_R) => CreateWorkspaceActions.request(payload),
  success: (payload: TWorkspace) => CreateWorkspaceActions.success(payload),
  error: (message: string) => CreateWorkspaceActions.error(message),
  setWorkspace,
};

const DeleteWorkspaceActions = new Actions(ACTION_CONST.delete, ActionTypes);

export interface TDelete_R extends TDefRequest {
  workspaceId: string;
}
export const DeleteWorkspace = {
  request: (payload: TDelete_R) => DeleteWorkspaceActions.request(payload),
  success: (payload: TWorkspace[]) => DeleteWorkspaceActions.success(payload),
  error: (message: string) => DeleteWorkspaceActions.error(message),
  setWorkspace,
};
