import React from 'react';
import { COLORS } from '../../utils/colors';
import { Loader } from '../ui';
import { useDnd } from './hooks/useDnd';
import * as Styles from './styles/TableStyles';

interface BoxUserNameSProps {
  name: string;
  id: number | string;
  position: number;
}

export const BoxUserName: React.FC<BoxUserNameSProps> = ({ name, id, position }) => {
  const { drag, opacity, dragLoading } = useDnd({ name, id, position });

  return (
    <Styles.BoxUserNameS ref={drag} style={{ width: '232px', position: 'relative' }}>
      {dragLoading ? <Loader color={COLORS.accent} size={0.3} /> : null}
      <span style={dragLoading ? { opacity: 0.2 } : { opacity }}>{name}</span>
    </Styles.BoxUserNameS>
  );
};
