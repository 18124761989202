import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TGroup } from './types';

function* getGroups(action: ReturnType<typeof Actions.getGroupsR>): Generator {
  let success = true;
  try {
    const workspaceId = action.payload.workspaceId;
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.groups.gAll + '/' + workspaceId,
    })) as TGroup;
    yield put(Actions.getGroupsS(data));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.getGroupsE(e));
  } finally {
    action.payload.callBack && action.payload.callBack(success);
  }
}

function* getGroupsByOriginSource(action: ReturnType<typeof Actions.getGroupsByOriginSourceR>): Generator {
  let success = true;
  try {
    const {workspaceId, originSource} = action.payload;
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.groups.gAllByOriginSource(originSource, workspaceId) ,
    })) as TGroup;
    yield put(Actions.getGroupsByOriginSourceS(data));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.getGroupsByOriginSourceE(e));
  } finally {
    action.payload.callBack && action.payload.callBack(success);
  }
}

function* createGroup(
  action: ReturnType<typeof Actions.CreateGroup.request>
): Generator {
  let success = true;
  const { name, description, workspace_id } = action.payload.data;
  try {
    const data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.groups.pathWorkspace + '/' + workspace_id,
      data: {
        name: name,
        description: description,
      },
    })) as TGroup;
    if (
      !data.participants ||
      !(data.participants && Array.isArray(data.participants))
    ) {
      data.participants = [];
    }

    yield put(Actions.CreateGroup.success(data));
  } catch (e) {
    success = false;
    yield put(Actions.CreateGroup.error(e));
  } finally {
    action.payload.callBack && action.payload.callBack(success);
  }
}

function* deleteGroup(
  action: ReturnType<typeof Actions.DeleteGroup.request>
): Generator {
  let success = true;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.groups.path + '/' + action.payload.data.id,
    });
    yield put(
      Actions.DeleteGroup.success(action.payload?.data?.newGroups || [])
    );
  } catch (e) {
    success = false;
    yield put(Actions.DeleteGroup.error(e));
  } finally {
    action.payload.callBack && action.payload.callBack(success);
  }
}

function* editGroup(
  action: ReturnType<typeof Actions.EditGroup.request>
): Generator {
  const { data, callBack } = action.payload as Actions.TypeIEditGroupR;
  const { id, name, description, newGroups } = data;
  let success = true;

  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.groups.path + '/' + id,
      data: {
        name,
        description,
      },
    });
    yield put(Actions.EditGroup.success(newGroups || []));
  } catch (e) {
    success = false;
    yield put(Actions.EditGroup.error(e));
  } finally {
    callBack && callBack(success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_R, getGroups);
  yield takeEvery(ActionTypes.GET_ALL_BY_ORIGIN_SOURCE_R, getGroupsByOriginSource);
  yield takeEvery(ActionTypes.CREATE_R, createGroup);
  yield takeEvery(ActionTypes.DELETE_R, deleteGroup);
  yield takeEvery(ActionTypes.EDIT_R, editGroup);
}

export default function* groupsSaga() {
  yield all([fork(watchFetchRequest)]);
}
