export interface TFinchIntegrationsState {
  readonly loading: boolean;
  data: TFinchIntegrations[] | null;
  readonly errors?: string | undefined;
}

// export interface TFinchModel {
//   id: string;
//   name: string;
//   slug: string;
//   status?: 'DONE' | 'FAIL' | 'SYNCING' | string;
//   is_initial_sync?: boolean;
//   is_last_sync_completed?: boolean;
//   last_sync?: string;
//   last_sync_start?: string;
//   next_sync_start?: string;
// }

export interface TFinchIntegrations {
  color: string;
  company_id: string;
  created_at: Date;
  icon_url: string;
  id: string;
  local_sync_completed_at: string;
  local_sync_status: string;
  logo_url: string;
  name: string;
  provider_id: string;
  sync_completed_at: string;
  sync_scheduled_at: string;
  sync_started_at: string;
  sync_status: string;
  updated_at: Date;
  workspace_id: string;
}

enum ActionTypes {
  GET_ALL_R = '@@finchIntegrations/GET_ALL_R',
  GET_ALL_S = '@@finchIntegrations/GET_ALL_S',
  GET_ALL_E = '@@finchIntegrations/GET_ALL_E',

  CREATE_R = '@@finchIntegrations/CREATE_R',
  CREATE_S = '@@finchIntegrations/CREATE_S',
  CREATE_E = '@@finchIntegrations/CREATE_E',

  DELETE_R = '@@finchIntegrations/DELETE_R',
  DELETE_S = '@@finchIntegrations/DELETE_S',
  DELETE_E = '@@finchIntegrations/DELETE_E',
}

export default ActionTypes;
