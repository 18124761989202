import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TCat } from './types';

function* getCats(
  action: ReturnType<typeof Actions.GetCats.request>
): Generator {
  let success = true;
  const { teamId, callBack, type } = action.payload as Actions.TypeGetCatR;
  const t = type || 'custom';
  try {
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.categories.genGetAll(teamId) + '/' + t,
    })) as TCat[];
    yield put(Actions.GetCats.success(data));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetCats.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* createCat(
  action: ReturnType<typeof Actions.CreateCat.request>
): Generator {
  let success = true;
  const { data, callBack } = action.payload as Actions.TypeCreateCatR;
  const { name, description } = data;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.categories.path,
      data: {
        name: name,
        description: description,
      },
    })) as TCat;
    yield put(Actions.CreateCat.success(resp));
  } catch (e) {
    success = false;
    yield put(Actions.CreateCat.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* editCat(
  action: ReturnType<typeof Actions.EditCat.request>
): Generator {
  let success = true;
  const { data, callBack, catId } = action.payload as Actions.TypeEditCatR;
  const { name, description } = data;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.categories.path + '/' + catId,
      data: {
        name: name,
        description: description,
      },
    });
  } catch (e) {
    success = false;
    yield put(Actions.EditCat.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}
function* deleteCat(
  action: ReturnType<typeof Actions.DeleteCat.request>
): Generator {
  let success = true;
  const { callBack, catId } = action.payload as Actions.TypeDeleteCatR;

  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.categories.path + '/' + catId,
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeleteCat.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_R, getCats);
  yield takeEvery(ActionTypes.CREATE_R, createCat);
  yield takeEvery(ActionTypes.EDIT_R, editCat);
  yield takeEvery(ActionTypes.DELETE_R, deleteCat);
}

export default function* catsSaga() {
  yield all([fork(watchFetchRequest)]);
}
