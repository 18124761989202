import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { DeleteGroup } from '../../store/groups/actions';
import {ParticipantSources, TGroup} from '../../store/groups/types';
import { EditParticipant } from '../../store/participants/actions';
import { TParticipant } from '../../store/participants/types';
import {COLORS, COLORS_RGBA} from '../../utils/colors';
import { deepClone } from '../../utils/deepClone';
import {
  RightSideBarBodyS,
  RightSideBarHeaderS,
  RightSideBarTitleS,
  RightSideBarWrapperS,
} from '../RightSideBar/RightSideBarStyles';
import { ButtonAdd } from '../ui';
import { GroupSection } from './GroupSection';
import { TEditGroupInfo } from './types/groupTypes';
import styled from "styled-components";
import {OriginSourceSection} from "./OriginSourceSection";

interface RightSideBarProps {
  openNewGroup: (data?: TEditGroupInfo) => void;
}

export const RightSideBar: React.FC<RightSideBarProps> = React.memo(
  ({ openNewGroup }) => {
    const { Groups, Participants} = useSelector((store: AppStore) => store);

    const dispatch = useDispatch();

      const removeGroupFromParticipants = (success: boolean, id: string) => {
          if (!success) return console.error('success', false);
          if (!Participants.data) return console.error('Could not find groups');
          const newParticipants = deepClone(Participants.data) as TParticipant[];
          newParticipants.forEach((participant) => {
              if (!participant.groups[0]) return null;
              participant.groups = participant.groups.filter((pg) => pg.id !== id);
          });
          return dispatch(EditParticipant.success(newParticipants));
      };

    const groupOriginSources = React.useMemo(() => {
        // if (Groups.loading)
        //     return  <Loader color={COLORS.accent} />
        const groupMap: { [key: string]: TGroup[] } = Groups.data?.reduce((map, group) => {
            if (!map[group.origin_source]) {
                map[group.origin_source] = [];
            }
            map[group.origin_source].push(group);
            return map;
        }, {} as { [key: string]: TGroup[] });

        const filterGroups = (id: string, position: number, originSource: string): TGroup[] => {
            if (!groupMap[originSource] || !(groupMap[originSource] && Array.isArray(groupMap[originSource])))
                return [];

            let groups: TGroup[] = [...groupMap[originSource]];

            if (String(groups[position].id) === String(id)) {
                groups.splice(position, 1);
            } else {
                groups = groups.filter((group) => String(group.id) !== String(id));
            }

            // eslint-disable-next-line
            groupMap &&Object.keys(groupMap).map((originSourceTmp) => {
                if (originSourceTmp !== originSource ) {
                    groups.push(...groupMap[originSourceTmp])
                }
            })
            return groups;
        };

        let sortStruct:{id:string, name: string}[] = []

        // eslint-disable-next-line
        groupMap &&Object.keys(groupMap).map((originSource) => {
            if (originSource !== '') {
                sortStruct.push({id:originSource, name: ParticipantSources[originSource]})
            } else {
                sortStruct.push({id:'', name: ''})
            }

        })

        sortStruct.sort((a, b) => {
            if (a.name === "") return 1;
            if (b.name === "") return -1;
            return a.name.localeCompare(b.name);
        });

        const mapOfPosition = new Map<string, number>();

        // eslint-disable-next-line
        Groups.data &&  Groups.data.map((grp, i) => {
            mapOfPosition.set(grp.id, i)
        })

        return (
            sortStruct &&
            sortStruct.map((originSource) => {
                if (originSource.name === '') {
                return (
                    groupMap[originSource.id].map(
                        ({ name, description, id, participants, check_for_deletion, origin }, i) => {
                            return (
                                <GroupSection
                                    id={id}
                                    title={name}
                                    groupPosition={mapOfPosition.get(id)}
                                    groupIsLoading={Participants.loading}
                                    key={id}
                                    data={participants}
                                    isCustom={true}
                                    isNotDeletable={check_for_deletion}
                                    groupIsDefault={origin === 'default'}
                                    removeGroup={() =>
                                        dispatch(
                                            DeleteGroup.request(
                                                { id, newGroups: filterGroups(id, i, originSource.id) },
                                                (s) => removeGroupFromParticipants(s, id)
                                            )
                                        )
                                    }
                                    editGroup={() => {
                                        openNewGroup({
                                            title: name,
                                            description,
                                            id,
                                            position: i,
                                            participants,
                                        })
                                    }}
                                />
                            );
                        }
                    )
                )
                } else {
                    return (
                        <OriginSourceSection
                            removeGroupFromParticipants={removeGroupFromParticipants}
                            filterGroups={filterGroups}
                            originSourceID={originSource.id}
                            positionMap={mapOfPosition}
                            groupsD={groupMap[originSource.id]}
                            title={ParticipantSources[originSource.id]}
                            key={originSource.id}
                            openNewGroupHandler={openNewGroup}
                        />
                    );
                }
            })
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        Groups,
        // Groups.loading,
        dispatch,
        Groups.data,
        openNewGroup,
        // Participants.loading
    ]);

    return (
      <RightSideBarWrapperS>
        <RightSideBarHeaderS>
          <RightSideBarTitleS>
              Groups
              <InfoIS>
                  i
                  <div>
                      <div>
                              Colors of the groups that are listed under a participant and colors of the participants listed in a group depend on how they are created/ added to the group:
                      </div>
                      <div>
                          <div>
                              Groups:
                          </div>
                          <WrapperInfoObjLineS>
                              <LineS style={{ background: COLORS_RGBA.secondary(1) }} />

                              <InfoObjS>
                                  manually created
                              </InfoObjS>
                          </WrapperInfoObjLineS>

                          <WrapperInfoObjLineS>
                              <LineS style={{ background: COLORS_RGBA.orange(1) }} />

                              <InfoObjS>
                                  automatically created
                              </InfoObjS>
                          </WrapperInfoObjLineS>

                          <WrapperInfoObjLineS>
                              <LineS style={{ background: COLORS_RGBA.lightBlue(1) }} />

                              <InfoObjS>
                                  default OrgVitals groups
                              </InfoObjS>
                          </WrapperInfoObjLineS>
                      </div>

                      <div>
                          <div>
                              Participants in the groups:
                          </div>
                          <WrapperInfoObjLineS>
                              <LineS style={{ background: COLORS_RGBA.accent(1) }} />

                              <InfoObjS>
                                  participants who were added to the group manually
                              </InfoObjS>
                          </WrapperInfoObjLineS>

                          <WrapperInfoObjLineS>
                              <LineS style={{ background: COLORS_RGBA.orange(1) }} />

                              <InfoObjS>
                                  participants who were added to the group automatically
                              </InfoObjS>
                          </WrapperInfoObjLineS>
                      </div>
                  </div>
              </InfoIS>
          </RightSideBarTitleS>
          <ButtonAdd title="New Group" onClick={openNewGroup} />
        </RightSideBarHeaderS>
        <RightSideBarBodyS isEmpty={false}>{groupOriginSources}</RightSideBarBodyS>
      </RightSideBarWrapperS>
    );
  }
);

export const InfoIS = styled.div`
  font-size: 12px;
  z-index: 1;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: ${COLORS.accent};
  color: white;
  text-align: center;
  border-radius: 50%;
  margin-left: 2px;

  & > div {
    font-weight: normal;
    font-size: 14px;
    display: none;
    position: absolute;
    top: 200%;
    width: 250px;
    left: -75px;
    border-radius: 10px;
    box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
    border: solid 1px rgba(162, 165, 162, 0.15);
    color: ${COLORS.default};
    text-align: left;
    & > div {
      padding: 12px;
      margin-bottom: 0;
      background-color: white;
      &:first-child {
        border-radius: 10px 10px 0 0;
        border-bottom: solid 2px rgba(162, 165, 162, 0.15);
      }
      &:last-child {
        margin-bottom: 0;
        border-radius: 0 0 10px 10px;
        border-top: solid 2px rgba(162, 165, 162, 0.15);
      }
      
      & > div {
        margin-bottom: 20px;
      }
    }
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`;

const InfoObjS = styled.span`
  text-align: left;
  background-color: white;
`;

const LineS = styled.span`
  background-color: white;
  display: block;
  width: 40px;
  height: 2px;
  margin-right: 10px;
  margin-top: 10px;
`;

const WrapperInfoObjLineS = styled.span`
  background-color: white;
  display: flex;
  margin-bottom: 20px;
`;