import React from 'react';
import { useDispatch } from 'react-redux';
import { setPackage } from '../../store/packages/actions';
import { TPackage } from '../../store/packages/types';
import { TLang } from '../../types/lang';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { VALUES } from '../../utils/valueConst';
import { AssessmentBox } from '../Assessments/AssessmentBox';
import { ButtonSave, DeleteSelectItem } from '../ui';
import * as AssSt from './AssessmentPackageStyles';
import * as MSt from './modalStyles';
import { closeModal } from './utils/closeModal';
import EnFlag from '../../images/eng.png';
import EsFlag from '../../images/es.svg';
import FrFlag from '../../images/fr.svg';
import IlFlag from '../../images/il.svg';
import { ILangStr } from '../../types/lang';

interface AssessmentPackageProps {
  assPackage: TPackage;
}

export const AssessmentPackage: React.FC<AssessmentPackageProps> = ({
  assPackage,
}) => {
  const dispatch = useDispatch();
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const closeModalHandler = () => {
    if (refInnWrapper.current)
      closeModal(refInnWrapper.current, () => dispatch(setPackage(null)));
  };

  const renderFlag = (languages_tags: TLang[]) => {
    return languages_tags.map((itm) => {
      switch (itm) {
        case 'en_US':
          return (
            <div key={itm}>
              <img src={EnFlag} alt="EnFlag" />
              <span>En</span>
            </div>
          );
        case 'es_ES':
          return (
            <div key={itm}>
              <img src={EsFlag} alt="EsFlag" />
              <span>Es</span>
            </div>
          );
        case 'fr_FR':
          return (
            <div key={itm}>
              <img src={FrFlag} alt="FrFlag" />
              <span>Fr</span>
            </div>
          );
        case 'he_IL':
          return (
            <div key={itm}>
              <img src={IlFlag} alt="IlFlag" />
              <span>He</span>
            </div>
          );
        default:
          return null;
      }
    });
  };
  return (
    <MSt.ModalOuterWrapperS>
      <MSt.ModalInnerWrapperS
        className={AnimatedClasses.zoomIn}
        ref={refInnWrapper}
        style={{ maxWidth: '778px' }}
      >
        <MSt.ModalHeaderS>
          <MSt.ModalTileS>{assPackage.name}</MSt.ModalTileS>
        </MSt.ModalHeaderS>

        <MSt.ModalBodyS>
          <MSt.DescTitleS>Description</MSt.DescTitleS>
          <p style={{ marginTop: '10px', fontSize: '16px' }}>
            {assPackage.description}
          </p>

          <h3>Assessments</h3>

          <AssSt.WrapperRowS>
            {assPackage.assessments.map((itm) => {
              let ver = '';
              const itmName: ILangStr = itm.name as ILangStr;
              if (
                assPackage.assessments.find((ass) => {
                  const assName: ILangStr = ass.name as ILangStr;
                  return (
                    (ass.name === itm.name ||
                      ass.name === itmName.en_US ||
                      assName.en_US === itm.name ||
                      (assName.en_US && assName.en_US === itmName.en_US)) &&
                    ass.id !== itm.id &&
                    ass.version
                  );
                })
              ) {
                ver = itm.version;
              }
              return (
                <AssessmentBox
                  type="custom"
                  catId={itm.category_id}
                  catName={itm.category_name}
                  id={itm.id}
                  disableDrag
                  key={itm.id}
                  version={ver}
                  answer_type={itm.answer_type}
                  title={
                    typeof itm.name === 'string' ? itm.name : itm.name.en_US
                  }
                  questionsCount={itm.number_of_questions}
                  actionClick={() => null}
                  packId={assPackage.id}
                  selectData={[
                    {
                      title: <DeleteSelectItem title="Remove from package" />,
                      id: 1,
                      value: VALUES.delete,
                    },
                  ]}
                />
              );
            })}
          </AssSt.WrapperRowS>
        </MSt.ModalBodyS>

        <MSt.ModalFooterS>
          <MSt.BtnsWrapperS>
            <MSt.LangWrapperS style={{ flex: 1, marginTop: 0 }}>
              {/* <CheckBox
                onChange={() => null}
                isCheck={assPackage.languages_tags?.includes('es_ES')}
              /> */}
              <MSt.FlagS>{renderFlag(assPackage.languages_tags)}</MSt.FlagS>
            </MSt.LangWrapperS>
            {/* <ButtonCancel onClick={() => dispatch} /> */}
            <ButtonSave title="Close" onClick={closeModalHandler} />
          </MSt.BtnsWrapperS>
        </MSt.ModalFooterS>
      </MSt.ModalInnerWrapperS>
    </MSt.ModalOuterWrapperS>
  );
};
