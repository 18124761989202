import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import {
  CreateLink,
  DeactivateLink,
  GetLink,
} from '../../store/dataStory/actions';
import { COLORS_RGBA } from '../../utils/colors';
import { CheckIcon, CloseIcon, LinkIcon } from '../svgs';
import { Alert, Loader } from '../ui';
import { ButtonBorderedS, ButtonDefS, ButtonLinkS } from '../ui/buttons/styles';
import { CopySuccess } from '../ui/CopySuccess';
import * as Styles from './modalStyles';
import {
  DaysWrapperS,
  LinkWrapperS,
  DaysInputWrapperS,
} from './PackageGenLink';
interface ShareLinkProps {
  closeModal: () => void;
}
export const ShareLink: React.FC<ShareLinkProps> = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { DataStory, Workspaces } = useSelector((store: AppStore) => store);
  const [expirationNum, setExpirationNum] = React.useState(30);
  const [link, setLink] = React.useState('');
  const [isCopied, setIsCopied] = React.useState(false);
  const oldExpirationNum = React.useRef(30);
  const ref = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    if (DataStory.data.link)
      dispatch(GetLink.request({ workspaceId: Workspaces.current.id }));
  }, [dispatch, Workspaces, DataStory.data.link]);
  React.useEffect(() => {
    if (DataStory.link && DataStory.link.story_link && !DataStory.linkErrors)
      setLink(
        window.location.origin + '/data-story-view/' + DataStory.link.story_link
      );
    if (
      DataStory.link &&
      DataStory.link.days_difference !== null &&
      DataStory.link.days_difference >= 0
    ) {
      setExpirationNum(DataStory.link.days_difference);
      oldExpirationNum.current = DataStory.link.days_difference;
    }
  }, [DataStory]);
  const copyToClipBoard = () => {
    if (!ref || !ref.current) return null;
    ref.current.select();
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderBody = () => {
    if (DataStory.linkLoading) {
      return <Loader isGreen />;
    }
    if (DataStory.link && DataStory.link.story_link) {
      return (
        <>
          <PreviewLinkWrapperS>
            <input
              type="text"
              ref={ref}
              value={link}
              name="link"
              readOnly
              onChange={() => null}
            />
            <ButtonDefS onClick={copyToClipBoard}>Copy</ButtonDefS>
          </PreviewLinkWrapperS>
          <p style={{ marginTop: '20px' }}>The link will be available for</p>
          <DaysWrapperS>
            <DaysInputWrapperS>
              <input
                type="number"
                value={expirationNum}
                min="1"
                onChange={(e) => {
                  setExpirationNum(+e.target.value);
                }}
              />
              <span style={{ color: COLORS_RGBA.default(0.6) }}>days</span>
            </DaysInputWrapperS>
            {oldExpirationNum.current !== expirationNum &&
            expirationNum !== 0 ? (
              <>
                <ButtonBorderedS
                  className="btnCancel"
                  onClick={() => setExpirationNum(oldExpirationNum.current)}
                >
                  <CloseIcon />
                </ButtonBorderedS>
                <ButtonDefS
                  className="btnSave"
                  onClick={() =>
                    dispatch(
                      CreateLink.request({
                        workspaceId: Workspaces.current.id,
                        link_expiration: expirationNum,
                      })
                    )
                  }
                  style={{ height: '50px' }}
                >
                  <CheckIcon />
                </ButtonDefS>
              </>
            ) : null}

            <div
              style={{
                flex: 1,
                display: ' flex',
                justifyContent: 'flex-start',
                paddingLeft: '20px',
              }}
            >
              <ButtonBorderedS
                isDanger
                style={{ width: 'initial' }}
                onClick={() =>
                  dispatch(
                    DeactivateLink.request({
                      workspaceId: Workspaces.current.id,
                    })
                  )
                }
              >
                Remove
              </ButtonBorderedS>
            </div>
            {isCopied ? <CopySuccess /> : null}
          </DaysWrapperS>
        </>
      );
    }

    return (
      <>
        <ButtonDefS
          style={{ marginTop: '20px' }}
          onClick={() => {
            dispatch(
              CreateLink.request({
                workspaceId: Workspaces.current.id,
                link_expiration: expirationNum,
              })
            );
          }}
        >
          <span style={{ paddingRight: '12px' }}>
            <LinkIcon color="#fff" />
          </span>
          Generate a link
        </ButtonDefS>
        <Alert text={DataStory.linkErrors} />
      </>
    );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '616px', minHeight: '289px' }}
      >
        <BtnCloseS
          style={{ zIndex: 1 }}
          className="closeModalBtn"
          onClick={closeModal}
        >
          <CloseIcon />
        </BtnCloseS>
        <Styles.ModalBodyS style={{ paddingTop: '24px' }}>
          <Styles.ModalTileS>Share saved visualizations</Styles.ModalTileS>
          <WrapperS>{renderBody()}</WrapperS>
        </Styles.ModalBodyS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const BtnCloseS = styled(ButtonLinkS)`
  position: absolute;
  opacity: 0.5;
  right: 20px;
  top: 20px;
`;
const PreviewLinkWrapperS = styled(LinkWrapperS)`
  width: 100%;
  margin-top: 10px;
`;
const WrapperS = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
