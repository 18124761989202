import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AssessmentView, UnarchiveAssessmentModal } from '../components';
import { AssessmentBox } from '../components/Assessments/AssessmentBox';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import { DeleteSelectItem, Loader } from '../components/ui';
import { ArchiveSelectItem } from '../components/ui/selects/ArchiveSelectItem';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import {
  GetArchivedAssessments,
  GetOneAssessment,
} from '../store/assessments/actions';
import {EQuestionType, TArchivedAssessment} from '../store/assessments/types';
import { COLORS_RGBA } from '../utils/colors';
import { getUniqueId } from '../utils/uniqueId';
import { VALUES } from '../utils/valueConst';
import {LinkedSurveyView} from "../components/modals/LinkedSurveyView";

interface ArchivedAssessmentsProps {}

export const ArchivedAssessments: React.FC<ArchivedAssessmentsProps> = () => {
  const { Assessments, User } = useSelector((store: AppStore) => store);
  const [selected, setSelected] = React.useState<TArchivedAssessment>(null);
  const dispatch = useDispatch();
  const uniqId = getUniqueId();
  React.useEffect(() => {
    if (User.data && User.data.id) {
      dispatch(GetArchivedAssessments.request({ teamId: User.data.team_id }));
    }
  }, [dispatch, User.data]);
  const assessments = () => {
    if (Assessments && Assessments.archivedData[0]) {
      return Assessments.archivedData.map((itm) => {
        return (
          <AssessmentBox
            title={itm.name}
            id={itm.id}
            questionsCount={itm.number_of_questions}
            version={itm.version}
            disableDrag
            catId={null}
            catName={null}
            type={'custom'}
            isArchived
            answer_type={itm.answer_type}
            selectData={[
              {
                title: <ArchiveSelectItem title={'Unzip'} />,
                id: uniqId.next().value as number,
                value: VALUES.unarchive,
              },
              {
                title: <DeleteSelectItem />,
                id: uniqId.next().value as number,
                value: VALUES.delete,
              },
            ]}
            unarchiveHandler={() => {
              setSelected(itm);
            }}
            actionClick={() => null}
            key={itm.id}
          />
        );
      });
    } else return <h2>There are no archived assessments</h2>;
  };
  return (
    <MainWrapperS>
      <DndProvider backend={HTML5Backend}>
        <PageTitleWrapperS>
          <h1>Archived Assessments</h1>
        </PageTitleWrapperS>
        <AssessmentsWrapperS>{assessments()}</AssessmentsWrapperS>
        {Assessments.current ?
            Assessments.current.answer_type === EQuestionType.linked_survey?
                <LinkedSurveyView
                    ass={Assessments.current}
                    closeHandler={() => dispatch(GetOneAssessment.success({}))}
                    isArchived
                />
                :
                <AssessmentView
                    ass={Assessments.current}
                    closeHandler={() => dispatch(GetOneAssessment.success({}))}
                    isArchived
                />
            : null}
      </DndProvider>
      {Assessments.loading && <Loader isGreen />}
      {selected && User.data.team_id && (
        <UnarchiveAssessmentModal
          closeModal={() => {
            setSelected(null);
          }}
          id={selected.id}
          teamId={User.data.team_id}
        />
      )}
    </MainWrapperS>
  );
};
const AssessmentsWrapperS = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0 -10px;

  & > * {
    margin-right: 1%;
    margin-bottom: 1%;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      border-color: ${COLORS_RGBA.accent(0.5)};
    }
    &:active {
      border-color: #f4f4f4;
    }
  }
`;
