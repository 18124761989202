import { delay } from '@redux-saga/core/effects';
import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import { ArrowIcon } from '../../svgs';
import {
  ItemS,
  ItemsWrapperS,
  SelectBtnS,
  SelectSpanS,
  WrapperS,
} from './DefaultSelect';
import { InnerSelectItem } from './InnerSelectItem';
import { ALL_GROUPS, ALL_PACKS } from '../../../utils/consts';

export interface TreeSelectProps {
  data: {
    title: string;
    id: string;
    children: {
      title: string;
      id: string;
    }[];
  }[];
  activeSelects: string[];
  cashActiveSelects: string[];
  clickHandler: (id: string | string[]) => void;
}

export const TreeSelect: React.FC<TreeSelectProps> = React.memo(
  ({ data, clickHandler, activeSelects, cashActiveSelects }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const modalRef = React.useRef<HTMLUListElement>(null);
    React.useEffect(() => {
      return () => {
        setIsOpen(false);
      };
    }, []);

    const hideList = async () => {
      if (modalRef && modalRef.current) {
        modalRef.current.classList.remove(SelectClasses.in);
        modalRef.current.classList.add(SelectClasses.out);
        await delay(100);
        setIsOpen(false);
      }
    };

    const { ref } = useOutsideClick(() => {
      if (isOpen) {
        hideList().then(() => null);
      }
    });

    const names = activeSelects.map((s) => {
      const n = s.split('/');
      return `${n[0]} (${n[2]})`;
    });
    return (
      <WrapperS className="ov-select" ref={ref}>
        <SelectBtnS
          isOpen={isOpen}
          className="ov-select-wr"
          onClick={() => {
            setIsOpen(true);
            // if (isOpenHandler) isOpenHandler(true);
          }}
        >
          <SelectSpanS active={false} style={{ fontSize: '13px' }}>
            {activeSelects.length === cashActiveSelects.length
              ? `${ALL_PACKS.title}, ${ALL_GROUPS.title}`
              : names.join(', ')}
          </SelectSpanS>
          <ArrowIcon />
        </SelectBtnS>

        {!isOpen ? null : (
          <WrapperUl
            ref={modalRef}
            className={`${mainClass} ${SelectClasses.in}`}
          >
            <InnerSelectItem
              allItms={cashActiveSelects}
              itm={{
                id: ALL_PACKS.id,
                title: `${ALL_PACKS.title}, ${ALL_GROUPS.title}`,
                children: [],
              }}
              activeIds={activeSelects}
              clickHandler={clickHandler}
            />
            {data[0] ? (
              data.map((itm) => (
                <InnerSelectItem
                  allItms={cashActiveSelects}
                  key={itm.id}
                  itm={itm}
                  activeIds={activeSelects}
                  clickHandler={clickHandler}
                />
              ))
            ) : (
              <ItemS noItems>No items</ItemS>
            )}
          </WrapperUl>
        )}
      </WrapperS>
    );
  }
);

const WrapperUl = styled(ItemsWrapperS)`
  padding: 10px 10px;
  margin: 0;
  list-style: none;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 10px 10px 10px 20px;
      white-space: nowrap;
    }
  }
`;
