import { useDrop } from 'react-dnd';
import { ItemTypes } from '../types/columnNames';

export const useDndSideBar = (id: string) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: () => ({
        id,
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  return {
    canDrop,
    isOver,
    drop,
  };
};
