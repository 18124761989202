import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS } from '../../utils/colors';
import { Alert } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';

interface SyncHrisProps {
  closeHandler: () => void;
}

export const SyncHrisModal: React.FC<SyncHrisProps> = ({ closeHandler }) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  // const dispatch = useDispatch();
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };
  React.useEffect(() => {}, []);
  const renderBody = () => {
    if ('1')
      return (
        <WrapperS>
          <ItemWrapperS>
            <img
              src={
                'https://merge-api-production.s3.amazonaws.com/media/BambooHR_Square_Logo.jpg'
              }
              alt={'img'}
            ></img>
            BambooHR
            <InfoWrapperS className={'hasTopTriangle'}>
              Last sync: 13.05.2022
            </InfoWrapperS>
          </ItemWrapperS>
          <ItemWrapperS>
            <img
              src={
                'https://merge-api-production.s3.amazonaws.com/media/CharlieHR_Square_Logo.png'
              }
              alt={'img'}
            ></img>
            CharlieHR
            <InfoWrapperS className={'hasTopTriangle'}>
              Last sync: 13.05.2022
            </InfoWrapperS>
          </ItemWrapperS>
        </WrapperS>
      );
    else
      return (
        <SecondaryWrapperS>
          <PickedItemWrapperS>
            <img
              src={
                'https://merge-api-production.s3.amazonaws.com/media/CharlieHR_Square_Logo.png'
              }
              alt={'img'}
            ></img>
            CharlieHR
          </PickedItemWrapperS>
          <Alert
            text={
              '37 participants will be added\n\n7 participants will be removed\n\n3 participant records will be updated\n\n147 total participants will be in the workspace.'
            }
            type={'success'}
          />
          <Alert
            text={
              '3 groups will be added\n\n2 groups will be removed\n\n1 group will be updated'
            }
            type={'success'}
          />
        </SecondaryWrapperS>
      );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '350px', height: '700px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>HRIS Sync</Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>{renderBody()}</Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonDefS>Apply</ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const WrapperS = styled.div`
  display: flex;
`;
const SecondaryWrapperS = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  width: 100px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid rgba(42, 47, 42, 0.12);
  border-radius: 8px;
  transition-duration: 500ms;
  cursor: pointer;
  :hover {
    border: 1px solid ${COLORS.accent};
    span {
      display: block;
    }
  }
  img {
    height: 60px;
    width: 60px;
  }
`;
const PickedItemWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  width: 100px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid ${COLORS.accent};
  border-radius: 8px;
  transition-duration: 500ms;
  cursor: pointer;
  img {
    height: 60px;
    width: 60px;
  }
`;
const InfoWrapperS = styled.span`
  display: none;
  position: absolute;
  bottom: -45px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);
  z-index: 9999;
  min-width: 125px;
  text-align: center;
  border-radius: 8px;
`;
