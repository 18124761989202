import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { LogoIcon } from '../../components/svgs/LogoIcon';
import { Alert, Input, Loader } from '../../components/ui';
import { ContainerFluidS } from '../../globalStyle';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { useForgotPass } from './hooks/useForgotPass';
import * as Styles from './styles';

interface ForgotPassProps {}
const EMAIL = 'email';

export const ForgotPass: React.FC<ForgotPassProps> = () => {
  const { User } = useSelector((store: AppStore) => store);
  const { form, onChangeHandler, submitHandler, successSent, warning } = useForgotPass(EMAIL);
  const textStyles: React.CSSProperties = {
    textAlign: 'center',
    opacity: 0.8,
    marginBottom: '15px',
  };

  const history = useHistory();

  const forgotForm = (
    <Styles.FormS action="" onSubmit={submitHandler} className={AnimatedClasses.fadeIn}>
      <p style={textStyles}>
        Enter the email you used to register. We’ll send password reset instructions to that
        address.
      </p>
      <Input
        errorText={form[EMAIL].errorText}
        label="Email:"
        name={EMAIL}
        value={form[EMAIL].value}
        onChange={onChangeHandler}
        autoComplete="on"
      />
      <Styles.LoginBtnS type="submit" onSubmit={submitHandler}>
        {User.loading ? <Loader /> : 'Reset Password'}
      </Styles.LoginBtnS>

      {User.errors ? <Alert text={User.errors} /> : null}
      {warning ? <Alert text={warning} type="warning" /> : null}

      <Styles.WrapperSignUpS>
        <p>Never mind,</p>
        <Link to="/"> Take me back to the login screen.</Link>
      </Styles.WrapperSignUpS>
    </Styles.FormS>
  );

  const successSentHtml = (
    <Styles.FormS action="" onSubmit={() => history.push('/')}>
      <p style={textStyles}>
        We just sent you an email. Please follow the instructions in the email to access your
        account.{' '}
      </p>
      <Styles.LoginBtnS type="submit" onSubmit={() => history.push('/')}>
        Go to login screen
      </Styles.LoginBtnS>
    </Styles.FormS>
  );

  const renderBody = () => {
    if (successSent) return successSentHtml;
    return forgotForm;
  };

  return (
    <>
      <Styles.HeaderS>
        <ContainerFluidS>
          <Styles.LogoWrapperS>
            <LogoIcon />
          </Styles.LogoWrapperS>
        </ContainerFluidS>
      </Styles.HeaderS>
      <Styles.MainS>
        <ContainerFluidS>
          <Styles.FormWrapperS>
            <h1>Forgot password?</h1>
            {renderBody()}
          </Styles.FormWrapperS>
        </ContainerFluidS>
      </Styles.MainS>
    </>
  );
};
