import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { delay } from 'redux-saga/effects';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import { getFormatDate } from '../../../utils/getFormatDate';
import { BtnsWrapperS } from '../../modals/modalStyles';
import { ArrowIcon } from '../../svgs';
import { ButtonBorderedS, ButtonDefS } from '../buttons/styles';
import {
  ItemsWrapperS,
  LabelS,
  SelectBtnS,
  SelectSpanS,
  WrapperS,
} from './DefaultSelect';

export type TRange =
  | 'all_time'
  | 'last_month'
  | 'last_quarter'
  | 'last_year'
  | '';
export type THiringRange =
  | 'from_0_to_30_days'
  | 'from_31_to_60_days'
  | 'from_61_to_90_days'
  | 'from_0_to_90_days'
  | 'from_91_to_180_days'
  | 'from_0_to_1_years'
  | 'from_1_to_3_years'
  | 'from_3_to_6_years'
  | 'from_6_years';
interface SelectWithRangeProps {
  placeHolder?: string;
  icon?: React.FC;
  label?: string;
  // onChange: (item: [Date, Date] | null) => void;
  onCustomSave: (data: { start_date: string; end_date: string }) => void;
  onSave: (string: TRange) => void;
  isOpenHandler?: (isOpen: boolean) => void;
  selected: string | null;
}

export const SelectWithRange: React.FC<SelectWithRangeProps> = ({
  placeHolder,
  icon: Icon,
  label,
  isOpenHandler,
  onSave,
  onCustomSave,
  selected,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = React.useRef<HTMLUListElement>(null);
  const [dateRange, setDateRange] = React.useState<[Date, Date] | [null, null]>(
    [null, null]
  );
  const [startDate, endDate] = dateRange;

  // React.useEffect(() => {
  //   if (isOpen) setDateRange([null, null]);
  // }, [isOpen]);

  const hideList = async () => {
    if (modalRef && modalRef.current) {
      modalRef.current.classList.remove(SelectClasses.in);
      modalRef.current.classList.add(SelectClasses.out);
      await delay(100);
      setIsOpen(false);
    }
  };
  const { ref } = useOutsideClick(() => {
    if (isOpen) {
      hideList();
    }
  });

  const saveHandler = () => {
    if (!startDate) return null;
    if (!endDate) return null;

    onCustomSave({
      start_date: getFormatDate(startDate).split('T')[0],
      end_date: getFormatDate(endDate, true).split('T')[0],
    });
    hideList();
  };

  return (
    <WrapperS className="ov-select" ref={ref}>
      {label ? <LabelS>{label}</LabelS> : null}
      <SelectBtnS
        isOpen={isOpen}
        className="ov-select-wr"
        onClick={() => {
          setIsOpen(true);
          if (isOpenHandler) isOpenHandler(true);
        }}
      >
        {Icon ? (
          <span className="svgIconSelect">
            <Icon />
          </span>
        ) : null}
        <SelectSpanS active={!!selected}>{selected || placeHolder}</SelectSpanS>
        <ArrowIcon />
      </SelectBtnS>

      {!isOpen ? null : (
        <WrapperModalS
          ref={modalRef}
          className={`dateRangeWrapper ${mainClass} ${SelectClasses.in}`}
        >
          <WrapperInnerMS>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              inline
              monthsShown={2}
              maxDate={new Date()}
              onChange={(update) => {
                setDateRange(update as [Date, Date]);
              }}
              isClearable
              selectsRange={true}
              shouldCloseOnSelect
            />

            <ButtonsWrapperS>
              <ButtonBorderedS
                onClick={() => {
                  onSave('all_time');
                  hideList();
                  setDateRange([null, null]);
                }}
              >
                All Time
              </ButtonBorderedS>
              <ButtonBorderedS
                onClick={() => {
                  onSave('last_month');
                  hideList();
                  setDateRange([null, null]);
                }}
              >
                Last Month
              </ButtonBorderedS>
              <ButtonBorderedS
                onClick={() => {
                  onSave('last_quarter');
                  hideList();
                  setDateRange([null, null]);
                }}
              >
                Last Quarter
              </ButtonBorderedS>
              <ButtonBorderedS
                onClick={() => {
                  onSave('last_year');
                  hideList();
                  setDateRange([null, null]);
                }}
              >
                Last Year
              </ButtonBorderedS>
            </ButtonsWrapperS>
          </WrapperInnerMS>

          <FooterS>
            <BtnsWrapperS>
              <ButtonBorderedS isCancel onClick={hideList}>
                Cancel
              </ButtonBorderedS>

              <ButtonDefS
                disabled={!(startDate && endDate)}
                isSave
                onClick={saveHandler}
              >
                {'Save'}
              </ButtonDefS>
            </BtnsWrapperS>
          </FooterS>
        </WrapperModalS>
      )}
    </WrapperS>
  );
};

const WrapperModalS = styled(ItemsWrapperS)`
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  max-height: initial;

  .react-datepicker {
    display: flex;
    margin-right: 20px;
    border: none;

    &__day-name {
      opacity: 0.6;
      color: #2a2f2a;
      width: 32px;
    }

    &__header {
      background: white;
      border-bottom: 0;
    }

    &__current-month {
      padding: 10px 0;
      border: solid 1px #ececec;
      width: 120px;
      border-radius: 6px;

      font-weight: normal;
      margin: auto;
      font-family: Nunito;
      margin-bottom: 20px;
    }

    &__month-container {
      margin: 0 10px;
    }

    &__day {
      width: 32px;
      line-height: 32px;
    }
    &__navigation {
      top: 21px;
      /* border: 1rem solid transparent; */
      border-width: 0.85rem;
      z-index: 1;
      height: 15px;
      width: 15px;
    }
  }
`;

const WrapperInnerMS = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsWrapperS = styled.div`
  button {
    min-height: 36px;
    width: 183px;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;
const FooterS = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ececec;
  padding-top: 20px;

  button {
    min-height: 36px;
    height: 36px;
    font-size: 16px;
  }
`;
