import React from 'react';
import { Alert } from '../components/ui';
import { MainWrapperS } from '../globalStyle';

interface RespondingIsAuthProps {}

export const RespondingIsAuth: React.FC<RespondingIsAuthProps> = () => {
  return (
    <MainWrapperS>
      <Alert
        type="warning"
        text="You should Log out to be able to see the Responding Page"
      />
    </MainWrapperS>
  );
};
