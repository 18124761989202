interface IClassController {
  el: HTMLElement | null;
  removeClass: (cl: string) => void;
  addClass: (cl: string) => void;
}

export const classController: IClassController = {
  el: null,
  removeClass: function fnRemoveClass(cl: string) {
    if (!this.el) return null;

    this.el.classList.remove(cl);
  },
  addClass: function fnAddClass(cl: string) {
    if (!this.el) return null;

    this.el.classList.add(cl);
  },
};
