import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AddParticipants, EditParticipantModal, NewGroup } from '../components';
import { CollabEmployeeOverlay } from '../components/modals/CollabEmployeeOverlay';
import { SyncHrisModal } from '../components/modals/SyncHrisModal';
import { SyncHrisModalSecond } from '../components/modals/SyncHrisModalSecond';
import { Header, RightSideBar, Table } from '../components/participants';
import { TEditGroupInfo } from '../components/participants/types/groupTypes';
import { Alert } from '../components/ui';
import { AppStore } from '../store/applicationState';
import {
  CreateGroup,
  getGroupsR,
  setGroupsLoading,
} from '../store/groups/actions';
import {
  cleanFailSuccess, GetPaginationParticipants,
} from '../store/participants/actions';
import { TParticipant } from '../store/participants/types';
import { HEADER_HEIGHT, SIDE_BAR_WIDTH } from '../utils/config';
import {setMatchingTemplateLoading} from "../store/matchingTemplates/actions";
import {TSortBy, TSortType} from "../components/participants/types/sort";

interface ParticipantsProps {}

export const Participants: React.FC<ParticipantsProps> = () => {
    const { Workspaces, Participants } = useSelector((store: AppStore) => store);
    const [isNewGroup, setIsNewGroup] = React.useState(false);
    const [editParticipant, setEditParticipant] =
      React.useState<TParticipant | null>(null);
    const [participantPosition, setParticipantPosition] = React.useState(1.1);
    const [editGroupData, setEditGroupData] =
      React.useState<TEditGroupInfo | null>(null);
    const [isEditGroup, setIsEditGroup] = React.useState(false);
    const [isNewParticipants, setIsNewParticipants] = React.useState(false);
    const [isSyncOpen, setIsSyncOpen] = React.useState(false);
    const [isSyncSecondOpen, setIsSyncSecondOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');
    const [sortBy, setSortBy] = React.useState<TSortBy>('');
    const [sortType, setSortType] = React.useState<TSortType>('name');
    const [prevLen, setPrevLen] = React.useState(0);

    const [debouncedTerm, setDebouncedTerm] = React.useState<string>('');

    const limit = 100

    const [currentParticipant, setCurrentParticipant] = React.useState<{
    first_name: string;
    last_name: string;
    id: string;
    } | null>(null);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!Workspaces.loading && Workspaces.current) {
          dispatch(getGroupsR(Workspaces.current.id));
          dispatch(
              GetPaginationParticipants.request({
                workSpaceId: Workspaces.current.id,
                sortBy: '',
                sortType: '',
                searchString: '',
                limit: limit,
                offset: 0
              })
          );
        }

        return () => {
          dispatch(setGroupsLoading());
          dispatch(setMatchingTemplateLoading());
        };
        // eslint-disable-next-line
    }, [dispatch, Workspaces]);

    React.useEffect(() => {
        if (!Workspaces.loading && Workspaces.current && !Participants.loading && sortBy !== '' && sortType !== '') {
            let sbTmp: string

            if (sortBy === 'ABC') {
              sbTmp = 'ASC'
            } else if (sortBy === 'CBA') {
              sbTmp = 'DESC'
            } else {
              sbTmp = ''
            }

            dispatch(
                GetPaginationParticipants.request({
                    workSpaceId: Workspaces.current.id,
                    sortBy: sbTmp,
                    sortType: sortType,
                    searchString: searchValue,
                    limit: Participants.next_offset,
                    offset: 0
                })
            );
        }
        // eslint-disable-next-line
    }, [sortBy, sortType]);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTerm(searchValue);
        }, 500);

        return () => {
          clearTimeout(handler);
        };
    }, [searchValue]);

    React.useEffect(() => {
        const callBack = (success: boolean) => {
            if (!success) return null;
            setPrevLen(debouncedTerm.length)
        };

        const fetchData = async () => {
            if (Workspaces.loading || !Workspaces.current) return;
            if (debouncedTerm.length < 3 && prevLen !== 3) {
              return;
            }

            let sbTmp: string
            if (sortBy === 'ABC') {
                sbTmp = 'ASC'
            } else if (sortBy === 'CBA') {
                sbTmp = 'DESC'
            } else {
                sbTmp = ''
            }

            dispatch(
                GetPaginationParticipants.request({
                    workSpaceId: Workspaces.current.id,
                    sortBy: sbTmp,
                    sortType: sortType,
                    searchString: debouncedTerm.length < 3 ? '' : searchValue,
                    limit: Participants.next_offset,
                    offset: 0,
                    callBack
                })
            );
        };

        fetchData();
    // eslint-disable-next-line
    }, [debouncedTerm]);

    const loadMoreItems = React.useCallback(async () => {
    if (Participants.loading || !Participants.has_more || !Workspaces.current?.id) return;
        let sbTmp: string
        if (sortBy === 'ABC') {
            sbTmp = 'ASC'
        } else if (sortBy === 'CBA') {
            sbTmp = 'DESC'
        } else {
            sbTmp = ''
        }

        dispatch(
            GetPaginationParticipants.request({
                workSpaceId: Workspaces.current.id,
                sortBy: sbTmp,
                sortType: sortType,
                searchString: searchValue,
                limit: limit,
                offset: Participants.next_offset
            })
        );
    // eslint-disable-next-line
  }, [Participants, Workspaces]);

    const loadCurrentItems = React.useCallback(async () => {
        if (Participants.loading || !Workspaces.current?.id) return;
        let sbTmp: string
        if (sortBy === 'ABC') {
            sbTmp = 'ASC'
        } else if (sortBy === 'CBA') {
            sbTmp = 'DESC'
        } else {
            sbTmp = ''
        }

        dispatch(
            GetPaginationParticipants.request({
                workSpaceId: Workspaces.current.id,
                sortBy: sbTmp,
                sortType: sortType,
                searchString: searchValue,
                limit: Participants.next_offset,
                offset: 0
            })
        );
        // eslint-disable-next-line
    }, [Participants, Workspaces]);

  if (Workspaces.errors) {
    return (
        <>
          <WrapperS>
            <Header
                searchHandler={setSearchValue}
                searchValue={searchValue}
                addNewParticipantsHandler={() => setIsNewParticipants(true)}
                syncHrisHandler={() => setIsSyncOpen(true)}
            />
            <Alert text={Workspaces.errors} />
          </WrapperS>
        </>
    );
  }

  return (
      <>
        <WrapperS>
          <Header
              searchHandler={setSearchValue}
              searchValue={searchValue}
              addNewParticipantsHandler={() => setIsNewParticipants(true)}
              syncHrisHandler={() => setIsSyncOpen(true)}
          />
          <DndProvider backend={HTML5Backend}>
            <Table
                loadCurrentParticipantHandler={loadCurrentItems}
                loadParticipantHandler={loadMoreItems}
                searchValue={searchValue}
                setSortBy={(sortBy) => {
                    !Workspaces.loading && !Participants.loading && setSortBy(sortBy)
                }}
                setSortType={(sortType) => {
                    !Workspaces.loading && !Participants.loading && setSortType(sortType)
                }}
                sortBy={sortBy}
                sortType={sortType}
                editParticipantHandler={(participant, position) => {
                  setEditParticipant(participant);
                  setParticipantPosition(position);
                }}
                moreHandler={(participant: TParticipant) => {
                  setCurrentParticipant({
                    first_name: participant.first_name,
                    last_name: participant.last_name,
                    id: participant.id,
                  });
                }}
            />
            <RightSideBar
                openNewGroup={(data) => {
                  setEditGroupData(data || null);
                  setIsNewGroup(true);
                }}
            />
          </DndProvider>
        </WrapperS>
        {!isNewGroup ? null : (
            <NewGroup
                data={editGroupData}
                closeHandler={() => {
                  setIsNewGroup(false);
                  if (isEditGroup) setIsEditGroup(false);
                  dispatch(CreateGroup.error(undefined));
                }}
            />
        )}

        {!isNewParticipants ? null : (
            <AddParticipants
                loadItemHandler={loadCurrentItems}
                closeHandler={() => {
                  setIsNewParticipants(false);
                  dispatch(cleanFailSuccess());
                }}
            />
        )}

        {isSyncOpen && (
            <SyncHrisModal
                closeHandler={() => {
                  setIsSyncOpen(false);
                }}
            />
        )}
        {isSyncSecondOpen && (
            <SyncHrisModalSecond
                closeHandler={() => {
                  setIsSyncSecondOpen(false);
                }}
            />
        )}
        {editParticipant ? (
            <EditParticipantModal
                closeHandler={() => {
                  setEditParticipant(null);
                }}
                data={editParticipant}
                position={participantPosition}
            />
        ) : null}
        {currentParticipant && (
            <CollabEmployeeOverlay
                closeHandler={() => setCurrentParticipant(null)}
                user={currentParticipant}
                isFixed={true}
            />
        )}
      </>
  );
};

const WrapperS = styled.main`
  padding: calc(${HEADER_HEIGHT} + 26px) calc(${SIDE_BAR_WIDTH} + 24px);
`;
