import React from 'react';
import {IMissedParticipantInfo} from '../../store/participants/types';
import styled from "styled-components";
import {COLORS} from "../../utils/colors";
import {CheckBox} from "../ui";

const B_RAD = '6px';

interface ParticipantRowProps {
    deleteParticipantsID: string[];
    participant: IMissedParticipantInfo;
    selectMissedParticipantHandler: (isCheck: boolean, id: string) => void;
}
export const ParticipantRow: React.FC<ParticipantRowProps> = ({
    deleteParticipantsID,
    participant,
    selectMissedParticipantHandler,
}) => {


  return (
    <>
      <WrapperS>
        <ParticipantNameWrapperS>
            <span>{participant.first_name + ' ' + participant.last_name}</span>
        </ParticipantNameWrapperS>
          <ParticipantCredentialsWrapperS>
              { participant.email ? <p>
                  {participant.email}
              </p> : null }
              { participant.phone ? <p>
                  {participant.phone_code&& participant.phone_code}{participant.phone}
              </p> : null }
              { participant.payroll_id ? <p>
                  {participant.payroll_id}
              </p> : null }
          </ParticipantCredentialsWrapperS>
          <CheckBox
              isCheck={deleteParticipantsID.indexOf(participant.id) !== -1}
              onChange={(e) =>
                  selectMissedParticipantHandler(e.currentTarget.checked, participant.id)
              }
          />
      </WrapperS>
    </>
  );
};

const WrapperS = styled.div`
  min-height: 63px;
  max-height: 63px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  border-radius: ${B_RAD};
  border: solid 1px ${COLORS.secondary};
  align-items: center;
  background-color: transparent;
  width: 75%;
  margin-bottom: 15px;
  
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  
  &:last-child {
    margin-bottom: 0px;
  }
`;

const ParticipantNameWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  min-width: 33.33%;
`;

const ParticipantCredentialsWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 33.33%;

  p {
    margin: 0;
  }

  p:first-child {
    margin-top: 5px;
  }
  
  p:last-child {
    margin-bottom: 5px;
  }
`;

