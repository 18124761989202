import React from 'react';

interface PackageIconProps {}

export const PackageIcon: React.FC<PackageIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" opacity=".3">
        <g fill="#000">
          <g>
            <path
              d="M32 41c0 1.657-1.343 3-3 3H15c-1.657 0-3-1.343-3-3V27c0-1.657 1.343-3 3-3h3.868c.702 0 1.353.369 1.715.971L21.2 26H29c1.598 0 2.904 1.249 2.995 2.824L32 29v12zM18.868 26H15c-.513 0-.936.386-.993.883L14 27v14c0 .513.386.936.883.993L15 42h14c.513 0 .936-.386.993-.883L30 41v-9c0-.513-.386-.936-.883-.993L29 31h-5.717c-.82 0-1.583-.401-2.049-1.067l-.095-.147L20.067 28H20v-.112L18.868 26zm3.986 2.757c.09.15.253.243.43.243H29c.351 0 .688.06 1 .171V29c0-.513-.386-.936-.883-.993L29 28h-6.6l.454.757z"
              transform="translate(-300 -203) translate(288 179)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
