import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import iconArrow from '../../images/icon-arrow.svg';
import iconArrowActive from '../../images/icon-arrow_active.svg';
import { ButtonBorderedS } from '../../components/ui/buttons/styles';

export const ColorButtonS = styled.button<{ color: string; isActive: boolean }>`
  height: 18px;
  width: 18px;
  outline-offset: 4px;
  background: ${({ color }) => color};
  border: none;
  outline: ${({ isActive }) => (isActive ? 'solid 1px #fff' : 'none')};
  border-radius: 1px;
  margin-right: 14px;
`;
export const ColorButtonsWrapperS = styled.div`
  display: flex;
  margin-top: 16px;
`;
export const FrameSettingsWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 10px;
  }
`;
export const InputS = styled.input`
  width: 80px;
  height: 36px;
  background: transparent;
  border-top: 1px solid #ffffff30;
  border-bottom: 1px solid #ffffff30;
  border-right: 1px solid #ffffff30;
  border-left: none;
  border-radius: 0 6px 6px 0;
  color: #fff;
  padding: 0;
  font-size: 16px;
  :hover {
    border-color: #ffffff30;
  }
  :active {
    border-color: #ffffff30;
  }
  :focus {
    border-color: #ffffff30;
  }
`;
export const SignS = styled.div`
  width: 37px;
  height: 36px;
  background: transparent;
  border-top: 1px solid #ffffff30;
  border-bottom: 1px solid #ffffff30;
  border-left: 1px solid #ffffff30;
  border-right: none;
  border-radius: 6px 0px 0px 6px;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InputWrapperS = styled.div`
  display: flex;
`;
export const SettingsWrapperS = styled.div`
  position: absolute;
  margin-bottom: 16px;
  bottom: 100%;
  z-index: 10;
  right: 0;
  background-color: rgba(0, 0, 0, 0.999);
  opacity: 0.9;
  border-radius: 8px;
  width: 288px;
  padding: 20px 24px;
  font-size: 16px;
  color: white;
`;
export const SettingsLabelS = styled.label`
  font-weight: 700;
  font-size: 16px;
`;
export const BottomBlockWrapperS = styled.div`
  position: absolute;
  width: 206px;
  height: 76px;
  background-color: rgba(0, 0, 0, 0.999);
  border-radius: 8px;
  margin: 36px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;
export const SettingsButtonS = styled.button<{ isActive: boolean }>`
  padding: 0;
  background-color: transparent;
  border: none;
  svg {
    path {
      stroke: ${({ isActive }) => isActive && `${COLORS.accent}`};
      opacity: ${({ isActive }) => isActive && '1'};
    }
  }
`;
export const WatermarkWrapperS = styled.div`
  position: absolute;
  margin: 30px;
  bottom: 0;
  left: 0;
`;
export const SnapshotWrapperS = styled.div`
  & > div {
    width: 700px;
  }
`;
export const DividerS = styled.div`
  width: 1px;
  height: 36px;
  margin: 2px 16px 2px 16px;
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.12);
`;
export const PreviewPageS = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ color }) => color};
  z-index: 999;
  overflow: hidden;
`;

export const ViewHeaderS = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 72px;
  padding: 12px 24px;
  margin: 12px 12px 0 12px;
`;
export const ViewBodyS = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px 24px;
  .slick-track {
    display: flex;
    align-items: flex-start;
  }
  .slick-slider {
    position: relative;
  }
  .slick-list {
    overflow: hidden;
  }
  .slick-active {
    padding: 0 25px;
    margin-right: 10px;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 0;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 8px;
    background-color: black;
  }

  .slick-arrow.slick-prev {
    right: 100%;
    background-image: url('${iconArrowActive}');
    background-repeat: no-repeat;
    background-position: center;
  }
  .slick-arrow.slick-next {
    left: 100%;
    background-image: url('${iconArrowActive}');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%) rotate(180deg);
  }
  .slick-arrow.slick-prev.slick-disabled {
    background-image: url('${iconArrow}');
  }
  .slick-arrow.slick-next.slick-disabled {
    background-image: url('${iconArrow}');
  }
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    li {
      list-style: none;
      margin: 0 10px;
      button {
        padding: 0;
        font-size: 0;
        border-radius: 50%;
        background: black;
        border: none;
        height: 10px;
        width: 10px;
      }
    }
    .slick-active {
      button {
        background: gray;
      }
    }
  }
`;
export const InnerWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
`;
export const BackBtnHrefS = styled.a`
  position: fixed;
  left: 10px;
  margin-top: 25px;
  margin-left: 10px;
  display: flex;
  z-index: 10;
  align-items: center;
  height: 40px;
  width: 100px;
  padding: 9px 16px;
  border-radius: 66px;
  background: transparent;
  border: solid 2px rgba(67, 67, 82, 0.3);
  color: #434352;
  font-size: 16px;
  svg {
    margin-right: 10px;
    transition: 0.2s;
    transform: rotate(180deg) translateX(0);
    g {
      fill: #434352;
    }
  }

  &:hover {
    border: solid 2px #4ac24f;
    color: #434352;
    svg {
      transform: rotate(180deg) translateX(-5px);
    }
  }
  &:active {
    border: solid 2px #4caf50;
  }
`;
export const ImageWrapperS = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 12px;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const ImageS = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 10px;
`;
export const ImageNTextS = styled.div`
  display: flex;
  align-items: center;
`;
export const ImageNTextSettingsS = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 6px;
  border: ${({ isActive }) =>
    isActive ? `solid 1px ${COLORS.accentActive}` : 'solid 1px #ffffff30'};
`;
export const ButtonSaveSettings = styled.div`
  margin-top: 28px;
  button {
    width: 83px;
    height: 36px;
    min-height: 36px;
    min-width: 83px;
  }
`;
export const ShareLinkButtonS = styled(ButtonBorderedS)`
  width: 130px;
  min-height: 36px;
  height: 36px;
  align-items: center;
  margin-right: 16px;
  margin-left: 20px;
  color: white;
  background: ${COLORS.accent};
  border-color: ${COLORS.accent};
  div {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
      g {
        fill: white;
      }
    }
  }
`;
export const DragAreaS = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DragBlockS = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  margin-left: 12px;
  padding: 20px;
  overflow: auto;
  max-height: 100%;
  border-radius: 8px;
`;
export const DragWrapperS = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  height: 60%;
  overflow: none;
  align-items: center;
`;
export const DragBlockButton = styled(ButtonBorderedS)<{ show: boolean }>`
  height: 30px;
  width: 30px;
  padding: 0;
  margin-left: ${({ show }) => (show ? '0px' : '12px')};
  border: none;
  :hover {
    border: none;
  }
  svg {
    transform: ${({ show }) => (show ? 'rotate(90deg)' : 'rotate(270deg)')};
    height: 14px;
    width: 24px;
  }
  :active {
    border: none;
    background: none;
    color: white;
    svg {
      opacity: 0.7;
      g {
        fill: #fff;
      }
    }
  }
`;
