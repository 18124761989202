import { EOrigin, TCatAssessment } from '../../../../store/assessments/types';
import { TCat } from '../../../../store/categories/types';
import { deepClone } from '../../../../utils/deepClone';
import { ICbAss } from './types';

const updateNewCatForAss = (data: {
  newCasts: ICbAss['cats'];
  assessment: ICbAss['assessment'];
  newAss: ICbAss['assessment'];
}) => {
  const { newCasts, assessment, newAss } = data;
  if (!newAss)
    return console.error('newAss does not exist updateNewCatForAss fn');
  if (!assessment)
    return console.error('newAss does not exist updateNewCatForAss fn');
  const oldCatForAss = newCasts.find(
    (cat) => cat.id === assessment.category_id
  );
  const newCatForAss = newCasts.find((cat) => cat.id === newAss.category_id);
  if (!newCatForAss || !oldCatForAss)
    return console.error('Could not find category updateNewCatForAss fn');

  oldCatForAss.assessments = oldCatForAss.assessments.filter(
    (ass) => ass.id !== assessment.id
  );

  newCatForAss.assessments.push({
    name: newAss.name,
    origin: EOrigin.custom,
    id: newAss.id,
    version: newAss.version,
    number_of_questions: newAss.questions.length,
    category_id: newAss.category_id,
    category_name: newAss.catName,
  });

  return newCatForAss;
};

export const callBackEdit = (
  res: ICbAss
): {
  ass: TCatAssessment;
  newCasts: ICbAss['cats'];
} | null => {
  const { success, data, cats, name } = res;

  if (!res.assessment) return null;

  const { assessment } = res;

  if (!success) return null;

  if (!data || (data && !data.id) || (data && !data.category_id)) {
    console.log(success, 'success');
    console.error(data, 'data');
    return null;
  }

  if (!cats || !Array.isArray(cats)) {
    console.error(cats, 'cats');
    return null;
  }

  const newCasts = deepClone(cats) as TCat[];

  if (data.category_id !== assessment.category_id)
    updateNewCatForAss({ newCasts, newAss: data, assessment });

  const cat = newCasts.find((c) => `${c.id}` === `${data!.category_id}`);

  if (!cat) {
    console.warn('Could not find cat callBack submitHandler');
    return null;
  }

  const ass =
    cat.assessments &&
    cat.assessments[0] &&
    cat.assessments.find((a) => `${a.id}` === `${assessment!.id}`);

  if (!ass) {
    console.warn('Could not find ass callBack submitHandler');
    return null;
  }

  ass.name = {
    en_US: name,
  };
  ass.number_of_questions = data.questions.length;

  return {
    ass,
    newCasts,
  };
};
