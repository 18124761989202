import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useConvertSelectAss } from '../../hooks/useConvertSelectAss';
import { useForm } from '../../hooks/useForm';
import {
  CreateCustomChart,
  CreateCustomGlobalChart, SetChartsFilters,
  TypeCustomChartData,
} from '../../store/analytics/actions';
import {ETypeGraph, TAnalyticsRow, TChart, TChartParams} from '../../store/analytics/types';
import { AppStore } from '../../store/applicationState';
import { GetAllAssessmentsByTeam } from '../../store/assessments/actions';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { CULTURE_PAGE_ID } from '../../utils/consts';
import { deepClone } from '../../utils/deepClone';
import { CustomGraphRow } from '../Analytics/CustomGraphRow';
import { Alert, ButtonAdd, ButtonSave, Input } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
interface CustomGraphProps {
  closeHandler: () => void;
  rowId?: string;
}

const FIELD_NAME = 'chart-block-name';

export const CustomGraph: React.FC<CustomGraphProps> = ({
  closeHandler,
  rowId,
}) => {
  const { answeredDataAss, answeredNotCloneAss } = useConvertSelectAss(
    true,
    true
  );

  const dispatch = useDispatch();

  const { form, onChangeHandler } = useForm({
    [FIELD_NAME]: {
      value: '',
    },
  });

  const { Analytics, Workspaces, Assessments } = useSelector(
    (store: AppStore) => store
  );
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [newCharts, setNewCharts] = React.useState<TypeCustomChartData[]>([]);

  React.useEffect(() => {
    dispatch(CreateCustomChart.error(''));
    return () => {
      dispatch(CreateCustomChart.error(''));
    };
  }, [dispatch]);

  const closeModalHandler = () => {
    if (refInnWrapper.current)
      closeModal(refInnWrapper.current, () => {
        closeHandler();
      }).then(() => null);
  };

  const setNewCustomChart = (chart: TChart) => {
    closeModalHandler();
    if (!chart)
      return dispatch(CreateCustomChart.error('Error, chart is null'));
    const newData = deepClone(Analytics.data) as TAnalyticsRow[];
    const newRow = newData.find((row) => row.id === rowId);
    if (!newRow)
      return dispatch(CreateCustomChart.error('Could not find charts block'));
    newRow.charts.push(chart);
    return dispatch(CreateCustomChart.success(newData));
  };

  const saveRowChart = () => {
    const callBack = (success: boolean, data: TChart) => {
      console.log('-> success 1', success);
      if (!success) return null;
      const curExistingData = deepClone(
        Assessments.existingData
      ) as typeof Assessments.existingData;
      const existIds: string[] = [];
      if (
        newCharts[0].settings.assessments_ids &&
        newCharts[0].settings.assessments_ids[0]
      ) {
        setNewCustomChart(data);
        return dispatch(GetAllAssessmentsByTeam.success(curExistingData));
      }

      curExistingData.forEach((itm) => existIds.push(itm.id));
      if (!existIds.includes(newCharts[0].settings.assessment_id)) {
        const ass = Assessments.answered.find(
          (itm) => itm.id === newCharts[0].settings.assessment_id
        );
        if (ass)
          curExistingData.push({
            id: newCharts[0].settings.assessment_id,
            name: ass.name,
            is_archived: ass.is_archived,
            version: ass.version,
            questions: ass.questions,
          });
        dispatch(GetAllAssessmentsByTeam.success(curExistingData));
      }
      setNewCustomChart(data);
    };

    const getSettings = (): TypeCustomChartData['settings'] => {
      if (newCharts[0].settings.assessment_id) {
        return {
          assessment_id: newCharts[0].settings.assessment_id,
        };
      }

      return {
        x_axis_assessment_id: newCharts[0].settings.x_axis_assessment_id,
        y_axis_assessment_id: newCharts[0].settings.y_axis_assessment_id,
      };
    };

    return dispatch(
      CreateCustomChart.request({
        visualisationId: rowId!,
        data: {
          type: newCharts[0].type!,
          settings: getSettings(),
          params: Analytics.filters.params,
        },
        callBack,
      })
    );
  };

  const submitHandler = () => {
    if (!Workspaces.current)
      return dispatch(CreateCustomGlobalChart.error('No workspace'));
    if (!newCharts[0])
      return dispatch(CreateCustomGlobalChart.error('Choose Type of Graph'));

    if (rowId) return saveRowChart();

    let hasErrors = false;

    newCharts.forEach((ch) => {
      if (!ch.type) hasErrors = true;
      if (
        !ch.settings.assessment_id &&
        !ch.settings.x_axis_assessment_id &&
        !ch.settings.y_axis_assessment_id
      )
        hasErrors = true;
    });

    if (hasErrors) {
      return dispatch(CreateCustomGlobalChart.error('All fields are requered'));
    }

    const callBack = (success: boolean, respData: any) => {
      console.log('-> success 2', success);
      if (!success) return null;
      const curExistingData = deepClone(
        Assessments.existingData
      ) as typeof Assessments.existingData;
      const oldExistingData = deepClone(
        Assessments.existingData
      ) as typeof Assessments.existingData;

      if (
        newCharts[0].settings.assessments_ids &&
        newCharts[0].settings.assessments_ids[0]
      ) {
        closeHandler();
        return dispatch(GetAllAssessmentsByTeam.success(curExistingData));
      }

      const existIds: string[] = [];
      Assessments.existingData.forEach((itm) => existIds.push(itm.id));
      const newIds: string[] = [];
      newCharts.forEach((itm) => {
        if (
          !existIds.includes(itm.settings.assessment_id) &&
          !newIds.includes(itm.settings.assessment_id)
        )
          newIds.push(itm.settings.assessment_id);
      });
      if (!existIds[0])
        newIds.forEach((newId) => {
          const ass = Assessments.answered.find((itm) => itm.id === newId);
          if (ass) {
            curExistingData.push({
              id: newId,
              name: ass.name,
              is_archived: ass.is_archived,
              version: ass.version,
              questions: ass.questions,
            });
          }
        });
      else {
        existIds.forEach((existId) => {
          newIds.forEach((newId) => {
            if (
              newId !== existId &&
              oldExistingData.length + newIds.length > curExistingData.length
            ) {
              const ass = Assessments.answered.find((itm) => itm.id === newId);
              curExistingData.push({
                id: newId,
                name: ass ? ass.name : '',
                is_archived: ass ? ass.is_archived : false,
                version: ass ? ass.version : '',
                questions: ass ? ass.questions : null,
              });
            }
          });
        });
      }
      closeHandler();

      const newFilters = deepClone(Analytics.filters) as TChartParams;
      newFilters.opened_visualizations.push(respData.id);
      dispatch(SetChartsFilters(newFilters));

      dispatch(GetAllAssessmentsByTeam.success(curExistingData));
    };

    return dispatch(
      CreateCustomGlobalChart.request({
        workspaceId: Workspaces.current!.id,
        pageId: CULTURE_PAGE_ID,
        data: {
          name: form[FIELD_NAME].value,
          charts: newCharts,
          params: Analytics.filters.params,
        },
        callBack,
      })
    );
  };

  const getEmptyGraph = (
    type: ETypeGraph = '' as ETypeGraph,
    assessment_id: string = ''
  ): {
    settings: {
      assessment_id: string;
    };
    type: ETypeGraph;
  } => {
    return {
      settings: {
        assessment_id,
      },
      type,
    };
  };

  const selectHandler = (
    id:
      | TypeCustomChartData['settings']['assessment_id']
      | TypeCustomChartData['settings']['assessment_id'][],
    pos: number,
    type?: ETypeGraph
  ) => {
    const cloneNewCharts = [...newCharts];
    if (cloneNewCharts[pos]) {
      if (Array.isArray(id)) {
        cloneNewCharts[pos].settings.x_axis_assessment_id = id[0];
        cloneNewCharts[pos].settings.y_axis_assessment_id = id[1];
        delete cloneNewCharts[pos].settings.assessment_id;
      } else {
        cloneNewCharts[pos].settings.assessment_id = id;
        delete cloneNewCharts[pos].settings.assessments_ids;
      }

      return setNewCharts(cloneNewCharts);
    }

    return setNewCharts([
      getEmptyGraph('' as ETypeGraph, Array.isArray(id) ? id[0] : id),
    ]);
  };

  const clickNewChartHandler = (type: ETypeGraph, pos: number) => {
    const cloneNewCharts = [...newCharts];

    if (cloneNewCharts[pos]) {
      cloneNewCharts[pos].type = type;
      return setNewCharts(cloneNewCharts);
    }

    return setNewCharts([getEmptyGraph(type)]);
  };

  const addNewGraph = () => {
    const cloneNewCharts = newCharts[0] ? [...newCharts] : [getEmptyGraph()];

    cloneNewCharts.push(getEmptyGraph());

    return setNewCharts(cloneNewCharts);
  };

  const removeHandler = (pos: number) => {
    const cloneNewCharts = [...newCharts];
    cloneNewCharts.splice(pos, 1);
    return setNewCharts(cloneNewCharts);
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '916px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>Custom Graph</Styles.ModalTileS>
        </Styles.ModalHeaderS>

        <Styles.ModalBodyS>
          {!rowId && (
            <RowS style={{ margin: '0 0 20px' }}>
              <Input
                onChange={onChangeHandler}
                value={form[FIELD_NAME].value}
                label={"New block's name"}
                name={FIELD_NAME}
              />
            </RowS>
          )}
          {newCharts && newCharts[0] ? (
            newCharts.map((itm, i) => (
              <CustomGraphRow
                removeHandler={() => removeHandler(i)}
                key={itm.type + i}
                data={itm}
                selectDataAss={
                  itm.type !== ETypeGraph.type3 && itm.type !== ETypeGraph.type4
                    ? answeredDataAss
                    : answeredNotCloneAss
                }
                selectHandler={(id, type) => selectHandler(id, i, type)}
                clickHandler={(t) => clickNewChartHandler(t, i)}
              />
            ))
          ) : (
            <CustomGraphRow
              removeHandler={() => removeHandler(0)}
              data={{
                type: '' as ETypeGraph,
                settings: {
                  assessment_id: '',
                },
              }}
              selectDataAss={answeredDataAss}
              selectHandler={(id) => selectHandler(id, 0)}
              clickHandler={(t) => clickNewChartHandler(t, 0)}
              isFirst
            />
          )}
          {!rowId && (
            <div style={{ textAlign: 'center' }}>
              <ButtonAdd onClick={addNewGraph} title="Add Graph" />
            </div>
          )}

          {Analytics.createErrors && <Alert text={Analytics.createErrors} />}
        </Styles.ModalBodyS>

        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonSave
              onClick={submitHandler}
              title="Add"
              disabled={
                (!form[FIELD_NAME].value && !rowId) ||
                !newCharts[0] ||
                !newCharts[0].type
              }
            />
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};

const RowS = styled.div`
  margin-top: 20px;
`;
