import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { TRespUser } from '../../pages/Responding/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { CSS_MEDIA } from '../../utils/media';
import { RespTitle } from './components/RespTitle';
import {
  RespBtnS,
  RespInputSearchWrapperS,
  RespTextS,
  SelectListS,
} from './styles';
import { TLang } from '../../types/lang';
import { checkLang } from './assLang';
import { langIsAllingRight } from '../../utils/langIsEng';
import {DefaultSelect, SelectItemType} from "../ui/selects/DefaultSelect";
import {CollabIcon} from "../svgs";
import {COLORS_RGBA} from "../../utils/colors";

interface ScreenNameProps {
  pref:  {name: string, is_active_group_classification: boolean, group_classification: string, logo_url: string}
  nextFn: (user: TRespUser) => void;
  users: TRespUser[];
  lang: TLang;
}

export const ScreenName: React.FC<ScreenNameProps> = ({
  pref,
  nextFn,
  users,
  lang,
}) => {
  const [isListVisible, setIsListVisible] = React.useState(false);
  const [name, setName] = React.useState('');
  const { ref: refList } = useOutsideClick(() => setIsListVisible(false));
  const [user, setUser] = React.useState<TRespUser | null>(null);
  const [groupClassifications, setGroupClassifications] = React.useState<SelectItemType[]>([]);
  const [selectedGroupClassifications, setSelectedGroupClassifications] = React.useState<SelectItemType>(null);
  const [filteredUsersByGroupClassification, setFilteredUsersByGroupClassification] = React.useState<TRespUser[]>(users);
  const [isExistParticipantByGroupClass, setIsExistParticipantByGroupClass] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = React.useState<TRespUser[]>(users);

  React.useEffect(() => {
    setName('');
    if (pref && pref.is_active_group_classification) {
      let uniqueElements: SelectItemType[] = []
      let itm: SelectItemType = {
        id: 'none',
        title: checkLang(lang).groupClassifications['none'],
        value: checkLang(lang).groupClassifications['none']
      }

      uniqueElements.push(itm)

      setSelectedGroupClassifications(itm)

      let uniqueNames: string[] = []
          // getUniqueFieldValues(users, 'group_classification');

      for (let i = 0; i < users.length; i++) {
        let isExist: boolean = false
        for (let y = 0; y < uniqueNames.length; y++) {
          if (users[i].group_classification.toLowerCase().trim().replace(/\s+/g, ' ') ===
              uniqueNames[y].toLowerCase().trim().replace(/\s+/g, ' ')) {
            isExist = true
          }
        }
        if (!isExist)
          uniqueNames.push(users[i].group_classification)

      }

      if (uniqueNames.length === 0 || (uniqueNames.length === 1 && uniqueNames[0] === '')) {
        setIsExistParticipantByGroupClass(false)
      } else {
        setIsExistParticipantByGroupClass(true)
      }

      let tmpUniqueElements: SelectItemType[] = []
      for (let element of uniqueNames) {
        if (element !== '') {
          let itm: SelectItemType = {
            id: element,
            title: element,
            value: element,
          }
          tmpUniqueElements.push(itm);
        }
      }
      tmpUniqueElements.sort((a, b) => a.title.localeCompare(b.title));

      uniqueElements.push(...tmpUniqueElements)

      setGroupClassifications(uniqueElements)
      setFilteredUsersByGroupClassification(users)
      setFilteredUsers(users)

    }
  }, [users, pref, lang]);

  const groupSelectHandler = (data: SelectItemType) => {
    setSelectedGroupClassifications(data)
    setName('');
    let usersTmp: TRespUser[] = []
    users.forEach((user) => {
      if (data.id === 'none') {
        usersTmp.push(user)
        return
      } else if (user.group_classification.toLowerCase().trim().replace(/\s+/g, ' ') === data.title.toLowerCase().trim().replace(/\s+/g, ' ')) {
        usersTmp.push(user)
      }
    })

    setFilteredUsersByGroupClassification(usersTmp)
    setFilteredUsers(usersTmp)
  }

    const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
      if (pref && pref.is_active_group_classification && selectedGroupClassifications.id !== 'none') {
        setIsListVisible(true);
      }
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);

    const deepCheck = (text: string) =>
      text.toLowerCase().includes(value.toLowerCase());

    if (value.length > 1) {
      if ((pref && !pref.is_active_group_classification) ||
          !isExistParticipantByGroupClass ||
          (selectedGroupClassifications.id === 'none' &&(pref && pref.is_active_group_classification)))
      setIsListVisible(true);
      const newUsers = pref && pref.is_active_group_classification ? filteredUsersByGroupClassification.filter((u) =>
          deepCheck(u.first_name + ' ' + u.last_name)
      ) : users.filter((u) =>
        deepCheck(u.first_name + ' ' + u.last_name)
      );
      setFilteredUsers(newUsers);
    } else {
      if ((pref && !pref.is_active_group_classification) ||
          !isExistParticipantByGroupClass ||
          (selectedGroupClassifications.id === 'none' &&(pref && pref.is_active_group_classification)))
      setIsListVisible(false);
      setFilteredUsers(pref && pref.is_active_group_classification ? filteredUsersByGroupClassification: users);
    }
  };

  const clickListHandler = (data: typeof user) => {
    if (!data) return null;
    setUser(data);
    setName(`${data.first_name} ${data.last_name}`);
    setIsListVisible(false);
  };

  return (
    <WrapperS
      className={AnimatedClasses.zoomIn}
      tabIndex={0}
      alignRight={langIsAllingRight(lang)}
    >
      <RespTitle
        title={checkLang(lang).assessments.greeting}
        alignRight={langIsAllingRight(lang)}
      />
      <RespTextS alignRight={langIsAllingRight(lang)}>
        {checkLang(lang).assessments.descriptions}
      </RespTextS>
      <RespTextS alignRight={langIsAllingRight(lang)}>
        {checkLang(lang).assessments.provideInfo}
      </RespTextS>
      {pref && pref.is_active_group_classification && isExistParticipantByGroupClass?
          <div style={{display: 'flex', alignItems: 'center' , flexDirection: langIsAllingRight(lang)?'row-reverse':'row'}}>
            {langIsAllingRight(lang) ?
                <RespTextS style={{width: '70%', marginLeft: '10px', }} alignRight={langIsAllingRight(lang)}>{checkLang(lang).assessments.classificationFilter(checkLang(lang).groupClassifications[pref.group_classification])} </RespTextS>
            :
                <RespTextS style={{width: '70%', marginRight: '10px', }} alignRight={langIsAllingRight(lang)}>{checkLang(lang).assessments.classificationFilter(checkLang(lang).groupClassifications[pref.group_classification])} </RespTextS>
            }

            <DefaultSelect
                data={groupClassifications}
                icon={CollabIcon}
                selected={selectedGroupClassifications}
                onChange={groupSelectHandler}
            />
          </div>
          : null}
      <RespInputSearchWrapperS
        style={{ marginTop: '25px' }}
        alignRight={langIsAllingRight(lang)}
      >
        <input
          id="username"
          type="text"
          value={name}
          onClick={onClick}
          onChange={onChangeHandler}
          placeholder={checkLang(lang).assessments.inputs.name.placeholder}
          onKeyDown={(e: { keyCode: number; preventDefault: () => void }) => {
            if (e.keyCode === 13 && user) {
              e.preventDefault();
              nextFn(user!);
            }
          }}
          autoFocus
        />
        {isListVisible && (
          <SelectListS ref={refList}>
            {filteredUsers.map((itm, i) => (
              <li
                key={i + itm.first_name + itm.last_name}
                onClick={() => clickListHandler(itm)}
              >
                {itm.first_name} {itm.last_name} {itm.identifier !== '' ? '('+itm.identifier+')' : ''}
              </li>
            ))}
          </SelectListS>
        )}
      </RespInputSearchWrapperS>

      <RespBtnS
        disabled={!user}
        onClick={() => nextFn(user!)}
        alignRight={langIsAllingRight(lang)}
      >
        {checkLang(lang).assessments.btns.continue}
      </RespBtnS>
    </WrapperS>
  );
};
const WrapperS = styled.div<{ alignRight: boolean }>`
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  ${CSS_MEDIA.maxLg} {
    padding: 100px 0 30px;
  }
  :focus-visible {
    outline: none;
  }
  
  & > * {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }

  .ov-select-wr {
    height: 36px;
    padding: 3px 13px;
  }

  .svgIconSelect svg {
    transform: translateY(0) scale(-1, 1) !important;
    g {
      fill: ${COLORS_RGBA.default(0.3)};
      opacity: 1;
    }
  }
`;

