export const MEDIA = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1025,
  xl: 1200,
  xxl: 1400,
  extraXl: 1920,
};

const unit = 'px';

const n = 0.1;

export const PX_MEDIA = {
  minXs: MEDIA.xs + unit,
  maxXs: MEDIA.sm - n + unit,
  minSm: MEDIA.sm + unit,
  maxSm: MEDIA.md - n + unit,
  minMd: MEDIA.md + unit,
  maxMd: MEDIA.lg - n + unit,
  minLg: MEDIA.lg + unit,
  maxLg: MEDIA.xl - n + unit,
  minXl: MEDIA.xl + unit,
  maxXl: MEDIA.xxl - n + unit,
  minXxl: MEDIA.xxl + unit,
  maxXxl: MEDIA.extraXl - n + unit,
  extraXl: MEDIA.extraXl + unit,
};

const MEDIA_FN = (width: string, max?: boolean) => {
  let screen = 'min';
  if (max) screen = 'max';
  return `@media screen and (${screen}-width: ${width}) `;
};

export const CSS_MEDIA = {
  minXs: MEDIA_FN(PX_MEDIA.minXs),
  maxXs: MEDIA_FN(PX_MEDIA.maxXs, true),
  minSm: MEDIA_FN(PX_MEDIA.minSm),
  maxSm: MEDIA_FN(PX_MEDIA.maxSm, true),
  minMd: MEDIA_FN(PX_MEDIA.minMd),
  maxMd: MEDIA_FN(PX_MEDIA.maxMd, true),
  minLg: MEDIA_FN(PX_MEDIA.minLg),
  maxLg: MEDIA_FN(PX_MEDIA.maxLg, true),
  minXl: MEDIA_FN(PX_MEDIA.minXl),
  maxXl: MEDIA_FN(PX_MEDIA.maxXl, true),
  minXxl: MEDIA_FN(PX_MEDIA.minXxl),
  maxXxl: MEDIA_FN(PX_MEDIA.maxXxl, true),
  extraXl: MEDIA_FN(PX_MEDIA.extraXl),
};
