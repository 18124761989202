import React from 'react';

interface SendIconProps {
  color?: string;
  opacity?: string;
}

export const SendIcon: React.FC<SendIconProps> = ({
  color = '#000',
  opacity = '.3',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-1111 -408) translate(1099 302) translate(0 95) translate(12 11)">
                <path
                  fill={color}
                  d="M17.433 4.082c1.06-.312 2.174.295 2.486 1.357.108.368.108.76 0 1.129L16.394 18.56c-.311 1.062-1.424 1.67-2.486 1.357-.606-.178-1.092-.631-1.312-1.223l-1.976-5.316-5.315-1.975c-.987-.367-1.513-1.43-1.228-2.426l.049-.148c.22-.592.707-1.046 1.313-1.224zm-.034 3.956l-4.798 4.924 1.872 5.035 2.926-9.959zm-1.374-1.456L6.003 9.527l5.251 1.952 4.771-4.897z"
                  opacity={opacity}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
