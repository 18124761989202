import React from 'react';
import styled from 'styled-components';
import { ButtonBorderedS } from '../ui/buttons/styles';
import { useModalConsultants } from './hooks/useModalConsultants';
import * as Styles from './modalStyles';
import { ResetPassword } from './ResetPassword';

export interface IConsultants {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: Date;
}

export interface ITeams {
  id: string;
  name: string;
  created_at: Date;
  consultants: IConsultants[];
}
interface ModalConsultantsProps {
  team: ITeams;
  closeHandler: () => void;
}

export const ModalConsultants: React.FC<ModalConsultantsProps> = ({
  team,
  closeHandler,
}) => {
  const { isChangePass, setIsChangePass } = useModalConsultants();
  const [currentConsult, setCurrentConsult] = React.useState(null);
  return (
    <Styles.ModalOuterWrapperS>
      {isChangePass ? (
        <ResetPassword
          currentConsultant={currentConsult}
          closeHandler={() => {
            setIsChangePass(false);
          }}
        />
      ) : (
        <Styles.ModalInnerWrapperS style={{ maxWidth: '768px' }}>
          <Styles.ModalHeaderS
            style={{
              background: '#f6f8f6',
              marginBottom: '20px',
              paddingBottom: '20px',
            }}
          >
            <Styles.ModalTileS>Team - {team.name}</Styles.ModalTileS>
          </Styles.ModalHeaderS>
          <Styles.ModalBodyS>
            <TableS style={{ width: '100%' }}>
              <thead>
                <tr>
                  <td>#</td>
                  <td>First Name</td>
                  <td>Last Name</td>
                  <td>Email</td>
                  <td>Options</td>
                </tr>
              </thead>
              <tbody>
                {team.consultants[0] ? (
                  team.consultants.map((con, i) => (
                    <tr key={con.id}>
                      <td>{i + 1}</td>
                      <td>{con.first_name}</td>
                      <td>{con.last_name}</td>
                      <td>{con.email}</td>
                      <td style={{ padding: '0', width: '134px' }}>
                        <ResetButtonS
                          onClick={() => {
                            setCurrentConsult(con);
                            setIsChangePass(true);
                          }}
                        >
                          Reset Password
                        </ResetButtonS>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} style={{ padding: '40px 0' }}>
                      <strong>No members</strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </TableS>
          </Styles.ModalBodyS>
          <Styles.ModalFooterS>
            <Styles.BtnsWrapperS>
              <ButtonBorderedS isCancel onClick={closeHandler}>
                Close
              </ButtonBorderedS>
            </Styles.BtnsWrapperS>
          </Styles.ModalFooterS>
        </Styles.ModalInnerWrapperS>
      )}
    </Styles.ModalOuterWrapperS>
  );
};
const ResetButtonS = styled.button`
  background: transparent;
  text-decoration: underline;
  color: darkgray;
  border: 0;
  &:hover {
    color: lightgray;
  }
  &:active {
    color: gray;
  }
`;
const TableS = styled.table`
  thead td {
    background: grey;
    color: white;
    padding: 5px 10px;
  }
  tbody td {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
