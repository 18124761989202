import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../ui';

export type TSearchSettingsKind = 'def' | 'source' | 'target';

interface Props {
  isDef: boolean;
  isSource: boolean;
  isGlobal?: boolean;
  isTarget: boolean;
  onChange: (box: TSearchSettingsKind) => void;
}

export const SearchSettings: React.FC<Props> = ({
  isDef,
  isSource,
  isTarget,
  onChange,
  isGlobal,
}) => {
  return (
    <WrapperS
      style={
        isGlobal ? { top: 'initial', position: 'initial', padding: '0' } : {}
      }
    >
      <SectionTitleS>Connections filter:</SectionTitleS>
      <CheckBoxWrapperS>
        <CheckBox onChange={() => onChange('def')} isCheck={isDef} />
        <LabelS>both sent / received (default)</LabelS>
      </CheckBoxWrapperS>
      <CheckBoxWrapperS>
        <CheckBox onChange={() => onChange('source')} isCheck={isSource} />
        <LabelS>sent only</LabelS>
      </CheckBoxWrapperS>
      <CheckBoxWrapperS>
        <CheckBox onChange={() => onChange('target')} isCheck={isTarget} />
        <LabelS>received only</LabelS>
      </CheckBoxWrapperS>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: absolute;
  background: white;
  text-align: left;
  width: 100%;
  padding: 10px 20px;
  top: 100%;
  box-shadow: rgba(0, 0, 0, 0.2);

  & > * {
    margin-bottom: 0;
  }
`;

const CheckBoxWrapperS = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg path {
    fill: white !important;
  }
`;
const LabelS = styled.span`
  margin-left: 20px;
  font-size: 16px;
`;

const SectionTitleS = styled.h3`
  margin: 0 0 10px;
`;
