import React from 'react';
import { TGroup } from '../../store/groups/types';
import { TParticipant } from '../../store/participants/types';
import { COLORS } from '../../utils/colors';
import { convertToSelectItems } from '../../utils/convertToSelect';
import { VALUES } from '../../utils/valueConst';
import {
  ButtonMore,
  DeleteSelectItem,
  EditSelectItem,
  Loader,
  MoveSelectIcon,
} from '../ui';
import { MoreSelectItem } from '../ui/selects/MoreSelectItem';
import { BoxUserName } from './BoxUserName';
import { useParticipantRow } from './hooks/useParticipantRow';
import * as Styles from './styles/TableStyles';
import { colNames } from './types/columnNames';

interface ParticipantRowProps {
  allParticipants: TParticipant[];
  participant: TParticipant;
  groups: TGroup[];
  position: number;
  editParticipantHandler: () => void;
  isHrisConnected?: boolean;
  className?: string;
  moreHandler: (participant: TParticipant) => void;
  loadCurrentParticipantHandler?: () => void;
}

export const group_border_colors = {
  custom : 'custom',
  default: 'default',
  automatic: 'automatic'
}
export const month_names_short = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const ParticipantRow: React.FC<ParticipantRowProps> = ({
  participant,
  loadCurrentParticipantHandler,
  groups,
  position,
  allParticipants,
  editParticipantHandler,
  className,
  isHrisConnected,
  moreHandler,
}) => {
  const { deleteHandler, refRow, isLoading, moveToGroup } = useParticipantRow({
    groups,
    participants: allParticipants,
    loadCurrentParticipantHandler
  });

  // const selectItems = React.useMemo(() => {
  //   console.log(groups, 'groups');
  //   if (!Array.isArray(participant.groups)) return convertToSelectItems(groups);
  //   const filteredGroups = groups.filter((group) =>
  //     participant.groups.find((partGroup) => `${group.id}` !== `${partGroup.id}`)
  //   );
  //   return convertToSelectItems(filteredGroups);
  // }, [groups, participant.groups]);

  const selectItems = () => {
    if (!Array.isArray(participant.groups)) return convertToSelectItems(groups);
    let filteredGroups = [...groups];

    if (participant.groups[0]) {
      const ids: string[] = [];

      participant.groups.forEach((pg) => {
        ids.push(pg.id);
      });

      filteredGroups = groups.filter((group) => !ids.includes(`${group.id}`));
    }

    return convertToSelectItems(filteredGroups);
  };

  const renderGroups = () => {
    if (!participant.groups) return null;
    if (!Array.isArray(participant.groups)) return null;
    return participant.groups.map((group, i) => {
      return <Styles.GroupNameS key={group.id} colorB={group.origin}>{group.name}</Styles.GroupNameS>;
    });
  };
  const dateInfo =
    participant.hiring_date &&
    participant.hiring_date !== '0001-01-01T00:00:00Z'
      ? participant.hiring_date.split('T')[0].split('-')
      : null;
  return (
    <>
      <Styles.TrS ref={refRow} className={className}>
        <Styles.TdS
          name={colNames[1]}
          style={{ width: '35%', position: 'relative', cursor: 'pointer' }}
          onClick={() => {
            moreHandler(participant);
          }}
        >
          <BoxUserName
            position={position}
            name={`${participant.first_name} ${participant.last_name}`}
            id={participant.id}
          />
          {isLoading ? <Loader color={COLORS.accent} /> : null}
        </Styles.TdS>
        <Styles.TdS
          style={{ width: '35%', cursor: 'pointer' }}
          onClick={() => {
            moreHandler(participant);
          }}
        >
          {participant.email ? <p>{participant.email}</p> : null}
          {participant.phone ? (
            <p>
              {participant.phone_code}
              {participant.phone}
            </p>
          ) : null}
          {participant.payroll_id ? <p>{participant.payroll_id}</p> : null}
        </Styles.TdS>
        <Styles.TdS
          style={{ width: '30%', minWidth: '110px', cursor: 'pointer' }}
          onClick={() => {
            moreHandler(participant);
          }}
        >
          {dateInfo && (
            <p>{`${month_names_short[+dateInfo[1] - 1]} ${dateInfo[2]} ${
              dateInfo[0]
            }`}</p>
          )}
        </Styles.TdS>
        {/* <Styles.TdS
          style={{ width: '20%', cursor: 'pointer' }}
          onClick={() => {
            moreHandler(participant);
          }}
        >
          <p>{participant.ethnicity}</p>
        </Styles.TdS> */}
        {/* <Styles.TdS>-</Styles.TdS>
        <Styles.TdS>-</Styles.TdS> */}
        <Styles.TdS style={{ width: '24px' }}>
          <ButtonMore
            onClick={(val, groupId) => {
              if (val === VALUES.delete) {
                return deleteHandler({
                  id: participant.id,
                  position,
                  hasGroups:
                    participant.groups && Array.isArray(participant.groups)
                      ? !!participant.groups[0]
                      : false,
                });
              }
              if (val === VALUES.more) {
                return moreHandler(participant);
              }
              if (val === VALUES.move) {
                return moveToGroup({
                  id: participant.id,
                  groupId: `${groupId}`,
                  position,
                });
              }
              if (val === VALUES.edit) {
                return editParticipantHandler();
              }
            }}
            data={[
              {
                title: <EditSelectItem />,
                value: VALUES.edit,
                id: 1,
              },
              {
                title: <MoreSelectItem />,
                value: VALUES.more,
                id: 2,
              },
              {
                title: <MoveSelectIcon />,
                value: VALUES.move,
                id: 3,
                subItems: selectItems(),
              },
              {
                title: <DeleteSelectItem title="Delete" />,
                value: VALUES.delete,
                id: 4,
              },
            ]}
            disabled={isHrisConnected}
          />
        </Styles.TdS>
      </Styles.TrS>
      <Styles.TrS>
        <Styles.TdS colSpan={5} name={colNames[5]}>
          <Styles.GroupsWrapperS>{renderGroups()}</Styles.GroupsWrapperS>
        </Styles.TdS>
      </Styles.TrS>
    </>
  );
};
