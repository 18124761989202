import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { DropResult, ItemTypes } from '../types/columnNames';
import { useParticipantRow } from './useParticipantRow';

export const useDnd = (data: {
  name: string;
  id: number | string;
  position: number;
}) => {
  const { Participants, Groups } = useSelector((store: AppStore) => store);
  const { moveToGroup, isLoading } = useParticipantRow({
    participants: Participants.data || [],
    groups: Groups.data || []
  });

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: data,
      end(item: any, monitor) {
        const dropResult = monitor.getDropResult() as DropResult;
        if (item && dropResult) {
          let alertMessage = '';
          const isDropAllowed = true;

          if (isDropAllowed) {
            moveToGroup({
              id: `${item.id}`,
              position: item.position,
              groupId: dropResult.id,
            });
          } else {
            alertMessage = `You cannot ${dropResult.dropEffect} an item into the ${dropResult.id}`;
            alert(alertMessage);
          }
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
      }),
    }),
    [data]
  );

  return {
    drag,
    opacity,
    dragLoading: isLoading,
  };
};
