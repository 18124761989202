import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { COLORS } from './colors';

export const SELECT_TYPES: SelectItemType[] = [
  // {
  //   value: 'self',
  //   title: 'Self review',
  //   id: 1,
  // },
  // {
  //   value: 'peer',
  //   title: 'Peer review',
  //   id: 2,
  // },
  {
    value: 'organization',
    title: 'Organization review',
    id: 3,
  },
  // {
  //   value: 'collaboration',
  //   title: 'Organization review',
  //   id: 4,
  // },
];

export const SHORT_ID_CHARS =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ&$';

export const CULTURE_PAGE_ID = 'd62b0787-f3e1-48f0-a090-291facdf2562';

export const ALL_PARTICIPANTS = {
  title: 'All Participants',
  value: 'all',
  id: 'all',
};

export const ALL_GROUPS_TYPES = {
  title: 'All Group Types',
  value: 'all_group_types',
  id: 'all_group_types',
};

export const ALL_GROUPS = {
  title: 'All Groups',
  value: 'all_groups',
  id: 'all_groups',
};

export const ALL_CYCLES = {
  title: 'All Cycles',
  value: 'all_cycles',
  id: 'all_cycles',
};
export const ALL_ASS = {
  title: 'All Assessments',
  value: 'all_ass',
  id: 'all_ass',
};
export const ONE_LANG = {
  title: 'Only English',
  value: 'one_lang',
  id: 'one_lang',
};
export const ALL_PACKS = {
  title: 'All Cycles',
  value: 'all_pack',
  id: 'all_pack',
};
export const ALL_HIRING_TIME = {
  title: 'All tenures',
  value: 'all_time',
  id: 'all_time',
};
export const ALL_QUESTIONS = {
  title: 'All Questions',
  value: 'all_questions',
  id: 'all_questions',
};
export const ALL_X_QUESTIONS = {
  title: 'All X Questions',
  value: 'all_x_questions',
  id: 'all_x_questions',
};
export const ALL_Y_QUESTIONS = {
  title: 'All Y Questions',
  value: 'all_y_questions',
  id: 'all_y_questions',
};
export const MUTUAL = {
  isActive: {
    color: '#9acbab',
    name: 'mutual',
  },
  isUnActive: {
    color: COLORS.accent,
    name: 'unmutual',
  },
};
