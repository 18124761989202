import React from 'react';

interface TeamIconProps {}

export const TeamIcon: React.FC<TeamIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26px"
      height="18px"
      viewBox="0 0 26 18"
    >
      <title>icon_team</title>

      <g
        id="Artboard"
        transform="translate(-67.000000, -73.000000)"
        fill="#000000"
      >
        <path
          d="M82,82 C84.209139,82 86,83.790861 86,86 L86,87 C86,89.209139 84.209139,91 82,91 L78,91 C75.790861,91 74,89.209139 74,87 L74,86 C74,83.790861 75.790861,82 78,82 L82,82 Z M73,83 L73,85 L70.5,85 C69.6715729,85 69,85.6715729 69,86.5 C69,87.2796961 69.5948881,87.9204487 70.35554,87.9931334 L70.5,88 L73,88 L73,90 L70.5,90 C68.5670034,90 67,88.4329966 67,86.5 C67,84.5670034 68.5670034,83 70.5,83 L73,83 Z M89.5,83 C91.4329966,83 93,84.5670034 93,86.5 C93,88.4329966 91.4329966,90 89.5,90 L89.5,90 L87,90 L87,88 L89.5,88 L89.64446,87.9931334 C90.4051119,87.9204487 91,87.2796961 91,86.5 C91,85.6715729 90.3284271,85 89.5,85 L89.5,85 L87,85 L87,83 Z M82,84 L78,84 C76.9456382,84 76.0818349,84.8158778 76.0054857,85.8507377 L76,86 L76,87 C76,88.0543618 76.8158778,88.9181651 77.8507377,88.9945143 L78,89 L82,89 C83.0543618,89 83.9181651,88.1841222 83.9945143,87.1492623 L84,87 L84,86 C84,84.9456382 83.1841222,84.0818349 82.1492623,84.0054857 L82,84 Z M88,76 C89.6568542,76 91,77.3431458 91,79 C91,80.6568542 89.6568542,82 88,82 C86.3431458,82 85,80.6568542 85,79 C85,77.3431458 86.3431458,76 88,76 Z M72,76 C73.6568542,76 75,77.3431458 75,79 C75,80.6568542 73.6568542,82 72,82 C70.3431458,82 69,80.6568542 69,79 C69,77.3431458 70.3431458,76 72,76 Z M80,73 C82.209139,73 84,74.790861 84,77 C84,79.209139 82.209139,81 80,81 C77.790861,81 76,79.209139 76,77 C76,74.790861 77.790861,73 80,73 Z M88,78 C87.4477153,78 87,78.4477153 87,79 C87,79.5522847 87.4477153,80 88,80 C88.5522847,80 89,79.5522847 89,79 C89,78.4477153 88.5522847,78 88,78 Z M72,78 C71.4477153,78 71,78.4477153 71,79 C71,79.5522847 71.4477153,80 72,80 C72.5522847,80 73,79.5522847 73,79 C73,78.4477153 72.5522847,78 72,78 Z M80,75 C78.8954305,75 78,75.8954305 78,77 C78,78.1045695 78.8954305,79 80,79 C81.1045695,79 82,78.1045695 82,77 C82,75.8954305 81.1045695,75 80,75 Z"
          id="icon_team"
        ></path>
      </g>
    </svg>
  );
};
