import React from 'react';
import { TTabsDataItem } from '../components/MyAccount/Tabs';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import { Tabs } from '../components/MyAccount/Tabs';
import { MainWrapperS } from '../globalStyle';
import { InviteConsultants } from '../components/MyTeam/InviteConsultants';
import { TeamSettings } from '../components/MyTeam/TeamSettings';
import { AppStore } from '../store/applicationState';
import { useSelector } from 'react-redux';

interface ConsultingTeamProps {}

enum TABS_IDS {
  teamSettings = 1,
  inviteConsultants = 2,
}

const tabsData: TTabsDataItem[] = [
  {
    id: TABS_IDS.teamSettings,
    name: 'Settings',
  },
  // {
  //   id: TABS_IDS.inviteConsultants,
  //   name: 'Invite Consultants',
  // },
];

export const ConsultingTeam: React.FC<ConsultingTeamProps> = () => {
  const { Team } = useSelector((store: AppStore) => store);
  const [selectedTab, setSelectedTab] = React.useState<TTabsDataItem | null>(
    tabsData[0]
  );
  const renderTabs = () => {
    if (selectedTab?.id === TABS_IDS.teamSettings) {
      return <TeamSettings team={Team.data!} />;
    }
    if (selectedTab?.id === TABS_IDS.inviteConsultants) {
      return <InviteConsultants />;
    }
    return null;
  };
  return (
    <>
      <MainWrapperS>
        <PageTitleWrapperS>
          <h1>Team</h1>
        </PageTitleWrapperS>
        <div>
          <Tabs
            data={tabsData}
            activeId={selectedTab?.id || null}
            clickHandler={setSelectedTab}
          />
        </div>
        <div style={{ maxWidth: '416px', marginTop: '25px' }}>
          {renderTabs()}
        </div>
      </MainWrapperS>
    </>
  );
};
