import React from 'react';
import styled from 'styled-components';
import { checkLang } from '../../components/Responding/assLang';
import { RespBtnS, RespTextS } from '../../components/Responding/styles';
import { InputSearch } from '../../components/ui';
import { ButtonCollabChoose } from '../../components/ui/buttons/ButtonCollabChoose';
import { TLang } from '../../types/lang';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { langIsAllingRight } from '../../utils/langIsEng';
import { CSS_MEDIA } from '../../utils/media';
import {
  TCollaborations,
  TRespFriendly,
  TRespIdentify,
} from '../Responding/types';

interface ScreenFriendsChooseProps {
  nextFn: () => void;
  identifyData: TRespIdentify;
  onClick: (curCollab: TRespFriendly) => void;
  activeFriendCollabs: TCollaborations[];
  friendCollab: TRespFriendly[];
  lang: TLang;
}
export const ScreenFriendsChoose: React.FC<ScreenFriendsChooseProps> = ({
  nextFn,
  identifyData,
  onClick,
  activeFriendCollabs,
  lang,
  friendCollab,
}) => {
  const { participant } = identifyData;
  const [searchValue, setSearchValue] = React.useState('');
  // const [error, setError] = React.useState('');
  // const isError = () => {
  //   if (activeFriendCollabs.length < 1) {
  //     setError('You have to choose at least one collaborator');
  //     return false;
  //   }
  //   return true;
  // };
  const isIn = (str: string) =>
    str.toLowerCase().includes(searchValue.toLowerCase());
  const isValid = false;
  // activeFriendCollabs.length > 0 &&
  // (activeFriendCollabs.length < 3 || activeFriendCollabs.length > 10);
  return (
    <AnimatedWrapperS className={AnimatedClasses.zoomIn}>
      <WrapperS>
        {friendCollab ? (
          <>
            <TitleS alignRight={langIsAllingRight(lang)}>
              {participant.first_name}
              {checkLang(lang).collaborations.friendCollaborators.pleaseName}
            </TitleS>
            <RespTextS alignRight={langIsAllingRight(lang)}>
              {checkLang(lang).collaborations.friendCollaborators.theseCan}
            </RespTextS>
            <InputSearch
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
              results={[]}
              placeholder={checkLang(lang).collaborations.collaborators.search}
              alignRight={langIsAllingRight(lang)}
            />
            <UlWrapperS>
              {friendCollab.map((curСollab) => {
                const isChoosen = !!activeFriendCollabs.find(
                  (itm) => itm.collaborator_id === curСollab.id
                );
                const isDisabled = false;
                // activeFriendCollabs.length >= 10 && !isChoosen;
                if (isIn(`${curСollab.first_name} ${curСollab.last_name}`))
                  return (
                    <li key={curСollab.id}>
                      <DivWrapperS>
                        <span>
                          {curСollab.first_name} {curСollab.last_name} {curСollab.identifier !== '' ? '('+curСollab.identifier+')' : ''}{' '}
                        </span>
                        <ButtonCollabChoose
                          lang={lang}
                          isChoosen={isChoosen}
                          handleClick={() => {
                            onClick(curСollab);
                          }}
                          disable={isDisabled}
                          isFriendCollab={true}
                        />
                      </DivWrapperS>
                      <DividerS />
                    </li>
                  );
                else return null;
              })}
            </UlWrapperS>
            {/* {error && (
              <div style={{ position: 'relative', top: 0 }}>
                <Alert type="warning" text={error} />
              </div>
            )} */}
            <div>
              <RespBtnS
                onClick={() => {
                  // if (isError())
                  nextFn();
                }}
                disabled={isValid}
                alignRight={langIsAllingRight(lang)}
              >
                {checkLang(lang).collaborations.collaborators.continue}
              </RespBtnS>
            </div>
          </>
        ) : (
          <NoParticipantsTextS>
            There is only one participant in this workspace. You can't choose
            your collaborators.
          </NoParticipantsTextS>
        )}
      </WrapperS>
    </AnimatedWrapperS>
  );
};
const AnimatedWrapperS = styled.div`
  ${CSS_MEDIA.maxLg} {
    padding: 150px 0 30px;
  }
  max-width: 800px;
`;
const WrapperS = styled.div`
  .input-search-wrapper {
    input {
      border-radius: 26px;
    }
  }
`;
const TitleS = styled.h2<{ alignRight: boolean }>`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434352;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  ${CSS_MEDIA.maxMd} {
    font-size: 22px;
  }
`;
const NoParticipantsTextS = styled.h2`
  font-size: 24px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #434352;
  ${CSS_MEDIA.maxMd} {
    font-size: 22px;
  }
`;
export const DivWrapperS = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const UlWrapperS = styled.ul`
  background: white;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  height: 390px;
  overflow: auto;
  z-index: 1;
  li {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434352;

    ${CSS_MEDIA.maxMd} {
      font-size: 14px;
    }
  }
`;
export const DividerS = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
`;
