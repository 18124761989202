import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, {
  TArchivedAssessment,
  TAssessment,
  TAssessmentsState,
} from './types';

function* createAss(
  action: ReturnType<typeof Actions.CreateAssessment.request>
): Generator {
  let success = true;
  let resp = null;
  const { data, callBack } = action.payload as Actions.TypeCreateAssessmentR;
  const { name, description, answer_type, type, category_id, questions } = data;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.assessments.path,
      data: {
        name: name,
        description: description,
        type: type,
        answer_type: answer_type,
        category_id: category_id,
        questions,
      },
    })) as TAssessment;
  } catch (e) {
    success = false;
    yield put(Actions.CreateAssessment.error(e));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}
function* editAss(
  action: ReturnType<typeof Actions.EditAssessment.request>
): Generator {
  let success = true;
  let resp = null;
  const { data, callBack, id } = action.payload as Actions.TypeEditR;
  const { name, description, type, code, category_id, questions } = data;
  console.log('editAssData');
  console.log(data);
  console.log('edit ass questions');
  console.log(questions);
  try {
    resp = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.assessments.path + '/' + id,
      data: {
        name,
        description,
        type,
        code,
        category_id,
        questions,
      },
    })) as TAssessment;
  } catch (e) {
    success = false;
    yield put(Actions.EditAssessment.error(e));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* archiveAss(
  action: ReturnType<typeof Actions.ArchiveAssessment.request>
): Generator {
  let success = true;
  const { id, callBack } = action.payload as Actions.TypeArchiveR;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.assessments.putArchived(id),
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.ArchiveAssessment.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* unarchiveAss(
  action: ReturnType<typeof Actions.UnarchiveAssessment.request>
): Generator {
  let success = true;
  const { assId, catId, callBack } = action.payload as Actions.TypeUnarchiveR;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.assessments.putUnarchived(assId, catId),
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.UnarchiveAssessment.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getAss(
  action: ReturnType<typeof Actions.GetOneAssessment.request>
): Generator {
  let success = true;
  const { id, catName, callBack } = action.payload as Actions.TypeGetOneR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.assessments.path + '/' + id,
    })) as TAssessment;
    yield put(Actions.GetOneAssessment.success({ ...resp, catName }));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetOneAssessment.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getArchivedAss(
  action: ReturnType<typeof Actions.GetArchivedAssessments.request>
): Generator {
  let success = true;
  const { teamId, callBack } = action.payload as Actions.TypeGetArchivedR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.assessments.gArchived + '/' + teamId,
    })) as TArchivedAssessment[];
    yield put(Actions.GetArchivedAssessments.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetArchivedAssessments.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteAss(
  action: ReturnType<typeof Actions.DeleteAssessment.request>
): Generator {
  let success = true;
  const { id, callBack } = action.payload as Actions.TypeDeleteR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.assessments.path + '/' + id,
    });
  } catch (e) {
    success = false;

    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeleteAssessment.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteArchived(
  action: ReturnType<typeof Actions.DeleteArchived.request>
): Generator {
  let success = true;
  const { id, callBack } = action.payload as Actions.TypeDeleteArchivedR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.assessments.path + '/' + id,
    });
  } catch (e) {
    success = false;

    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeleteArchived.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* addToPack(
  action: ReturnType<typeof Actions.AddAssToPack.request>
): Generator {
  let success = true;
  let resp = null;
  const { data, callBack } = action.payload as Actions.TypeAddAssToPackR;
  const { assessment_id, package_id, relation } = data;
  try {
    resp = yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.assessments.genUM2M(assessment_id, package_id),
      data: {
        relation: relation,
      },
    });
    yield put(Actions.AddAssToPack.success());
  } catch (e) {
    success = false;
    yield put(Actions.AddAssToPack.error(e));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getAllAnsweredAss(
  action: ReturnType<typeof Actions.GetAllAnsweredAssessments.request>
): Generator {
  let success = true;
  const { workspaceId, callBack } =
    action.payload as Actions.TypeGetAllAnsweredR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.assessments.getAnsweredExistingByWorkspace(
        workspaceId
      ),
    })) as TAssessmentsState['answered'];
    // console.log('assessments:');
    // console.log(resp);
    yield put(Actions.GetAllAnsweredAssessments.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllAnsweredAssessments.error(e));
  } finally {
    if (callBack) {
      yield call(callBack, success);
    }
  }
}

function* getExistingByWorkspace(
  action: ReturnType<typeof Actions.GetAllAssessmentsByTeam.request>
): Generator {
  let success = true;
  const { workspaceId, callBack } = action.payload as Actions.TypeGetAllByTeamR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.assessments.getExistingInVisualizationsByWorkspace(
        workspaceId
      ),
    })) as TAssessmentsState['existingData'];
    yield put(Actions.GetAllAssessmentsByTeam.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllAssessmentsByTeam.error(e));
  } finally {
    if (callBack) {
      yield call(callBack, success);
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.CREATE_R, createAss);
  yield takeEvery(ActionTypes.GET_ONE_R, getAss);
  yield takeEvery(ActionTypes.EDIT_R, editAss);
  yield takeEvery(ActionTypes.DELETE_R, deleteAss);
  yield takeEvery(ActionTypes.DELETE_ARCHIVED_R, deleteArchived);
  yield takeEvery(ActionTypes.ADD_TO_PACKAGE_R, addToPack);
  yield takeEvery(ActionTypes.GET_ALL_ANSWERED_R, getAllAnsweredAss);
  yield takeEvery(ActionTypes.GET_ALL_BY_TEAM_R, getExistingByWorkspace);
  yield takeEvery(ActionTypes.ARCHIVE_R, archiveAss);
  yield takeEvery(ActionTypes.UNARCHIVE_R, unarchiveAss);
  yield takeEvery(ActionTypes.GET_ARCHIVE_R, getArchivedAss);
}

export default function* assSaga() {
  yield all([fork(watchFetchRequest)]);
}
