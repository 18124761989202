import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { EditGroup } from '../../../store/groups/actions';
import { TGroup } from '../../../store/groups/types';
import { MoveToGroupParticipant } from '../../../store/participants/actions';
import { TParticipant } from '../../../store/participants/types';
import { AnimatedClasses } from '../../../utils/animatedStyles';
import { deepClone } from '../../../utils/deepClone';

export const useGroupSection = (payload: {
  groupId: string;
  groupPosition: number;
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [removedItems, setRemovedItems] = React.useState<string[]>([]);
  const userItmRefs: HTMLDivElement[] = [];
  const groupRef = React.useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { Groups, Participants } = useSelector((store: AppStore) => store);

  const deleteItem = (participant_id: string, position: number) => {
    const allGroups = deepClone(Groups.data) as TGroup[];
    const allParticipants = deepClone(Participants.data) as TParticipant[];

    if (!allGroups) return console.error('Could not clone Groups');
    if (!allParticipants) return console.error('Could not clone Participants');

    const group = Array.isArray(allGroups)
      ? allGroups[payload.groupPosition]
      : null;

    if (!group)
      return console.error(
        'Group does not exist deleteItem useGroupSection hook'
      );

    if (group.id !== payload.groupId)
      return console.error(
        'Group does not have the save id as deleteItem - useGroupSection hook'
      );

    const deletedParticipant = group.participants.splice(position, 1);

    if (!deletedParticipant)
      return console.error('Could not get deleted participant');

    const participant = allParticipants.find(
      (p) => `${p.id}` === `${deletedParticipant[0].id}`
    );

    if (!participant) return console.error('Could not get participant');
    setIsLoading(true);
    setRemovedItems((oldItems) => [...oldItems, participant_id]);
    participant.groups = participant.groups.filter(
      (participantGroup) => `${participantGroup.id}` !== `${group.id}`
    );

    dispatch(
      MoveToGroupParticipant.request({
        data: {
          relation: false,
          participant_id,
          group_id: payload.groupId,
        },
        callBack: (success) => {
          setIsLoading(false);

          if (success && userItmRefs[position]) {
            userItmRefs[position].classList.add(
              ...AnimatedClasses.fadeOutRight.split(' ')
            );
            setTimeout(() => {
              userItmRefs[position].style.display = 'none';
              dispatch(MoveToGroupParticipant.success(allParticipants));
              dispatch(EditGroup.success(allGroups));
            }, 500);
          }
        },
      })
    );
  };

  const deleteGroupAnimationHandler = (callBack: () => void) => {
    if (!groupRef || !(groupRef && groupRef.current)) return null;

    groupRef.current.classList.add(...AnimatedClasses.fadeOutRight.split(' '));
    setTimeout(() => {
      groupRef.current!.style.display = 'none';
      callBack();
    }, 500);
  };

  const setRefs = (ref: HTMLDivElement) => userItmRefs.push(ref);

  return {
    isVisible,
    setIsVisible,
    deleteItem,
    setRefs,
    groupRef,
    deleteGroupAnimationHandler,
    setIsLoading,
    isLoading,
    removedItems,
  };
};
