import React from 'react';

interface PackageIconMenuProps {}

export const PackageIconMenu: React.FC<PackageIconMenuProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <g>
            <g>
              <path
                d="M27 15.126v2.141c-.557.323-.945.906-.995 1.584L26 19v1h16v-1c0-.74-.402-1.386-1-1.732v-2.142c1.726.445 3 2.01 3 3.874v11c0 2.21-1.79 4-4 4H28c-2.21 0-4-1.79-4-4V19c0-1.864 1.275-3.43 3-3.874zM42 22H26v8c0 1.054.816 1.918 1.85 1.995L28 32h12c1.054 0 1.918-.816 1.995-1.85L42 30v-8zm-12 6c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm4 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm4 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8-4c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm4 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm4 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-9-10c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1zm10 0c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1zm-2 1v2h-6v-2h6z"
                transform="translate(-24 -295) translate(0 64) translate(0 217)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
