import React from 'react';

interface WrappedLinkIconProps {}

export const WrappedLinkIcon: React.FC<WrappedLinkIconProps> = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#4CAF50"
      >
        <path d="M28 14.5c0-7.732-6.268-14-14-14S0 6.768 0 14.5s6.268 14 14 14 14-6.268 14-14zm-26.6 0C1.4 7.541 7.041 1.9 14 1.9s12.6 5.641 12.6 12.6S20.959 27.1 14 27.1 1.4 21.459 1.4 14.5z" />
        <path d="M14.806 17.66a.702.702 0 0 1 .992.991l-1.614 1.617a4.21 4.21 0 0 1-5.951 0 4.204 4.204 0 0 1 0-5.948l1.613-1.617a.702.702 0 0 1 .992.991l-1.614 1.617a2.803 2.803 0 0 0 0 3.966 2.807 2.807 0 0 0 3.968 0l1.614-1.617zm-1.62-6.313a.702.702 0 0 1-.991-.991l1.62-1.624a4.21 4.21 0 0 1 5.952 0 4.204 4.204 0 0 1 0 5.948l-1.62 1.624a.702.702 0 0 1-.993-.992l1.621-1.623a2.803 2.803 0 0 0 0-3.966 2.807 2.807 0 0 0-3.967 0l-1.621 1.624z" />
        <path d="M15.603 11.907a.7.7 0 1 1 .99.99l-4.195 4.194a.7.7 0 0 1-.99-.99l4.195-4.194z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
