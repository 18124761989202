import React from 'react';
import { WrapperS } from './styles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';

export const Groups: React.FC = () => {
  const { Collaborations } = useSelector((store: AppStore) => store);

  if (!Collaborations.participantInfo)
    return (
      <WrapperS>
        <div style={{padding: '20px'}}>No data</div>
      </WrapperS>
    );

  const { groups } = Collaborations.participantInfo;

  const renderGroups = () => {
    if (!groups || !groups[0]) {
      return <div style={{padding: '10px'}}>No groups yet</div>;
    }

    return groups.map((g) => <GroupS key={g.id}>{g.name}</GroupS>);
  };

  return (
    <WrapperS>
      <GroupsWrapperS>{renderGroups()}</GroupsWrapperS>
    </WrapperS>
  );
};

const GroupsWrapperS = styled.div`
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
  overflow-x: hidden;
`;

const GroupS = styled.div`
  height: 30px;
  margin: 5px 5px;
  padding: 6px 14px 5px;
  border-radius: 6px;
  border: solid 1px #47b04b;
`;
