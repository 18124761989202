import { TAssessmentsState } from '../../../../store/assessments/types';

export const EMPTY_ASS_TEXT = 'No description';

export const getDescription = (
  assIds: string[],
  assessments: TAssessmentsState['answered']
): string => {
  const asses = assessments.filter((ass) => assIds.includes(ass.id));

  let desc = '';

  asses.forEach((ass) => (desc += `${ass.description}\n`));
  const newDesc = desc.replace('\n', '');
  if (newDesc) return desc;
  else return EMPTY_ASS_TEXT;
};
