import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from '../components/MyAccount';
import { Password } from '../components/MyAccount/Password';
import { Profile } from '../components/MyAccount/Profile';
import { TTabsDataItem } from '../components/MyAccount/Tabs';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import { Alert } from '../components/ui';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { TEXT } from '../utils/textConst';

interface MyAccountProps {}

enum TABS_IDS {
  profile = 1,
  password = 2,
}

const tabsData: TTabsDataItem[] = [
  {
    id: TABS_IDS.profile,
    name: 'Profile',
  },
  {
    id: TABS_IDS.password,
    name: 'Password',
  },
];

export const MyAccount: React.FC<MyAccountProps> = () => {
  const { User } = useSelector((store: AppStore) => store);
  const [selectedTab, setSelectedTab] = React.useState<TTabsDataItem | null>(
    tabsData[0]
  );

  if (!User.data) return <Alert text={TEXT.someError} />;

  const renderTabs = () => {
    if (selectedTab?.id === TABS_IDS.profile) {
      return <Profile user={User.data!} />;
    }
    if (selectedTab?.id === TABS_IDS.password) {
      return <Password user={User.data!} />;
    }
    return null;
  };

  return (
    <>
      <MainWrapperS>
        <PageTitleWrapperS>
          <h1>My account</h1>
        </PageTitleWrapperS>
        <div>
          <Tabs
            data={tabsData}
            activeId={selectedTab?.id || null}
            clickHandler={setSelectedTab}
          />
        </div>
        <div style={{ maxWidth: '416px', marginTop: '25px' }}>
          {renderTabs()}
        </div>
      </MainWrapperS>
    </>
  );
};
