export const TypeOfGroupClassification = {
    none: 'None',
    job    : 'Job',
    work_location_name: 'Work Location Name',
    work_location_country: 'Work Location Country',
    work_location_state : 'Work Location State',
    work_location_city  :'Work Location City',
    pay_group        :'Pay Group',
    department     :'Department',
    department_segment   :'Department Segment',
    division      :'Division',

}