import React from 'react';
import styled from 'styled-components';
import { SIDE_BAR_WIDTH } from '../../utils/config';
import { Menu } from './Menu';
import { WorkSpaceSelect } from './WorkSpaceSelect';

interface LeftSideBarProps {
  createNewFn: () => void;
}

export const LeftSideBar: React.FC<LeftSideBarProps> = React.memo(({ createNewFn }) => {
  return (
    <WrapperS>
      <WorkSpaceSelect createNewFn={createNewFn} />
      <Menu />
    </WrapperS>
  );
});

const headerHeight = '69px';

const WrapperS = styled.aside`
  z-index: 1;
  position: fixed;
  left: 0;
  width: ${SIDE_BAR_WIDTH};
  bottom: 0;
  height: calc(100vh - ${headerHeight});
  max-height: calc(100vh - ${headerHeight});
`;
