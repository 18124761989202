import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TPackage } from './types';

function* getAll(
  action: ReturnType<typeof Actions.GetAllPackages.request>
): Generator {
  let success = true;
  let resp = null;
  const { workspaceId, callBack } = action.payload as Actions.TypeGetAllR;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.packages.gAll + '/' + workspaceId,
    })) as TPackage[];
    yield put(Actions.GetAllPackages.success(resp));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetAllPackages.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}

function* deleteOne(
  action: ReturnType<typeof Actions.DeletePackage.request>
): Generator {
  let success = true;
  let resp = null;
  const { id, callBack } = action.payload as Actions.TypeDeleteR;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.packages.path + '/' + id,
    });
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.DeletePackage.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}

function* edit(
  action: ReturnType<typeof Actions.EditPackage.request>
): Generator {
  let success = true;
  let resp = null;
  const { id, callBack, data } = action.payload as Actions.TypeEditR;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.packages.path + '/' + id,
      data,
    });
  } catch (e) {
    success = false;
    yield put(Actions.EditPackage.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}

function* create(
  action: ReturnType<typeof Actions.CreatePackage.request>
): Generator {
  let success = true;
  let resp = null;
  const { callBack, data } = action.payload as Actions.TypeCreateR;
  const { name, description, workspace_id, languages_tags } = data;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.packages.cOne + '/' + workspace_id,
      data: {
        name,
        description,
        languages_tags,
      },
    })) as TPackage;
    yield put(Actions.CreatePackage.success({ ...resp, assessments: [] }));
  } catch (e) {
    success = false;
    yield put(Actions.CreatePackage.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, resp);
  }
}
function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_R, getAll);
  yield takeEvery(ActionTypes.DELETE_R, deleteOne);
  yield takeEvery(ActionTypes.EDIT_R, edit);
  yield takeEvery(ActionTypes.CREATE_R, create);
}

export default function* packagesSaga() {
  yield all([fork(watchFetchRequest)]);
}
