import React from 'react';
import styled from 'styled-components';
import { DEFAULT_PADDING } from '../../utils/config';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { UserSelect } from './UserSelect';

interface HeaderProps {
  onlyLogout?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ onlyLogout }) => {
  React.useEffect(() => {
    return () => {};
  }, []);
  return (
    <WrapperS>
      <LogoMenuWrapperS>
        <Logo />
        <Menu />
      </LogoMenuWrapperS>
      <UserSelect onlyLogout={onlyLogout} />
    </WrapperS>
  );
};

const WrapperS = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px ${DEFAULT_PADDING};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;
const LogoMenuWrapperS = styled.div`
  display: flex;
  align-items: center;
`;
