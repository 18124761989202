import React from 'react';

export const MinusIcon = () => {
  return (
    <svg width="16" height="2" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="84"
        y="24"
        width="16"
        height="2"
        rx="1"
        transform="translate(-84 -24)"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
};
