import React from 'react';

interface DataStoryIconProps {}

export const DataStoryIcon: React.FC<DataStoryIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <g
          id="analytics_culture_2"
          transform="translate(-24.000000, -775.000000)"
          fill="#000000"
        >
          <g id="nav_menu" transform="translate(0.000000, 64.000000)">
            <g id="data_story" transform="translate(0.000000, 697.000000)">
              <g
                id="icon_data_story"
                transform="translate(24.000000, 14.000000)"
              >
                <path d="M17,0 C18.6568542,0 20,1.34314575 20,3 L20,17 C20,18.5976809 18.75108,19.9036609 17.1762728,19.9949073 L17,20 L17,20 L3,20 C1.34314575,20 1.24344979e-13,18.6568542 1.24344979e-13,17 L1.24344979e-13,14 C1.24344979e-13,13.4477153 0.44771525,13 1,13 L4,13 L4,3 C4,1.34314575 5.34314575,0 7,0 L17,0 Z M14,15 L2,15 L2,17 C2,17.5128358 2.38604019,17.9355072 2.88337887,17.9932723 L3,18 L14.1710242,18.0009007 C14.0602698,17.6878673 14,17.350972 14,17 L14,15 Z M17,2 L7,2 C6.48716416,2 6.06449284,2.38604019 6.00672773,2.88337887 L6,3 L6,13 L15,13 C15.5128358,13 15.9355072,13.3860402 15.9932723,13.8833789 L16,14 L16,17 C16,17.5128358 16.3860402,17.9355072 16.8833789,17.9932723 L17,18 C17.5128358,18 17.9355072,17.6139598 17.9932723,17.1166211 L18,17 L18,3 C18,2.48716416 17.6139598,2.06449284 17.1166211,2.00672773 L17,2 Z M15,8 C15.5522847,8 16,8.44771525 16,9 C16,9.55228475 15.5522847,10 15,10 L9,10 C8.44771525,10 8,9.55228475 8,9 C8,8.44771525 8.44771525,8 9,8 L15,8 Z M15,4 C15.5522847,4 16,4.44771525 16,5 C16,5.55228475 15.5522847,6 15,6 L9,6 C8.44771525,6 8,5.55228475 8,5 C8,4.44771525 8.44771525,4 9,4 L15,4 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
