import React from 'react';
import { WrapperS } from './styles';
import styled from 'styled-components';
import { Alert, ButtonAdd, ButtonCancel, ButtonSave, TextArea } from '../../ui';
import { NoteItem } from './NoteItem';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { AddCollabParticipantNote } from '../../../store/collaborations/actions';
import { Counter } from '../../ui/Counter';

interface Props {
  participantId: string;
}

export const Notes: React.FC<Props> = ({ participantId }) => {
  const [textAreaIsVisible, setTextAreaIsVisible] = React.useState(false);
  const { Collaborations } = useSelector((store: AppStore) => store);
  const [note, setNote] = React.useState('');
  const maxCommentLength = 501;
  const dispatch = useDispatch();
  if (!Collaborations.participantInfo)
    return (
      <WrapperS>
        <div>No data</div>
      </WrapperS>
    );

  const { notes } = Collaborations.participantInfo;
  const renderNotes = () => {
    if (!notes || !notes[0]) {
      return <NoNotesS>this participant has no notes</NoNotesS>;
    }

    return notes.map((n, pos) => (
      <NoteItem
        maxCommentLength={maxCommentLength}
        note={n}
        key={n.id}
        notes={notes}
        pos={pos}
      />
    ));
  };

  const submitHandler = () => {
    if (!note) {
      dispatch(AddCollabParticipantNote.error('Note is required'));
      setTimeout(() => {
        dispatch(AddCollabParticipantNote.error(''));
      }, 10000);
      return null;
    }

    return dispatch(
      AddCollabParticipantNote.request({
        participantId,
        body: note,
        callBack: (success) => {
          if (success) {
            setNote('');
            setTextAreaIsVisible(false);
          }
        },
      })
    );
  };

  return (
    <WrapperS>
      {textAreaIsVisible ? (
        <>
          <div style={{ position: 'relative' }}>
            <TextArea
              placeholder={'Type here notes…'}
              autoFocus
              onChange={(e) => {
                if (maxCommentLength <= e.target.value.length) return null;
                if (Collaborations.errors)
                  dispatch(AddCollabParticipantNote.error(''));
                setNote(e.target.value);
              }}
              value={note}
            />
            <Counter length={note.length} max={maxCommentLength - 1} />
          </div>

          <WrapperBtns>
            <ButtonCancel onClick={() => setTextAreaIsVisible(false)} />
            <ButtonSave
              onClick={submitHandler}
              loading={Collaborations.notesLoading}
            />
          </WrapperBtns>
        </>
      ) : (
        <ButtonAdd
          title={'Add notes'}
          onClick={() => setTextAreaIsVisible(true)}
        />
      )}
      <Alert text={Collaborations.errors} type={'danger'} />
      {renderNotes()}
    </WrapperS>
  );
};

const WrapperBtns = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    margin: 0 0 0 16px;
    max-width: 100px;
  }
`;

const NoNotesS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
`;
