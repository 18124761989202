import React from 'react';
import { MoveIcon } from '../../svgs';
import { WrapperItemSelectS } from './styles';

interface MoveSelectIconProps {
  title?: string;
}

export const MoveSelectIcon: React.FC<MoveSelectIconProps> = ({ title = 'Move' }) => {
  return (
    <WrapperItemSelectS>
      <MoveIcon />
      {title}
    </WrapperItemSelectS>
  );
};
