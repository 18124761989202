export function checkDomain(): 'dev' | 'prod' | 'stage' {
  const { origin } = window.location;

  if (origin.includes('dev.') || origin.includes('localhost')) {
    return 'dev';
  }
  if (origin.includes('stage.')) {
    return 'stage';
  }

  return 'prod';
}
