import { Reducer } from 'redux';
import ActionTypes, { TPackagesState } from './types';

export const initialState: TPackagesState = {
  data: null,
  editObj: null,
  loading: true,
  errors: undefined,
  current: null,
};

const reducer: Reducer = (state = initialState, action): TPackagesState => {
  switch (action.type) {
    case ActionTypes.CREATE_R:
    case ActionTypes.EDIT_R:
    case ActionTypes.DELETE_R:
    case ActionTypes.GET_ALL_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ActionTypes.DELETE_S:
    case ActionTypes.EDIT_S:
    case ActionTypes.GET_ALL_S:
    case ActionTypes.UPDATE_ASS:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: action.payload,
      };

    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: state.data ? [action.payload, ...state.data] : [action.payload],
      };

    case ActionTypes.SET_EDIT_MODE:
      return {
        ...state,
        editObj: action.payload,
      };
    case ActionTypes.SET_PACK:
      return {
        ...state,
        current: action.payload,
      };

    case ActionTypes.CREATE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.DELETE_E:
    case ActionTypes.GET_ALL_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as PackagesReducer };
