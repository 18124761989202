import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { IParticipantsInputs } from './types/AddParticipantsTypes';

export const SubtitleS = styled.p`
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 5px 0 0;
`;
export const TableS = styled.table`
  width: 100%;
  margin-left: -8px;
  margin-right: -8px;
  td,
  th {
    text-align: left;
    padding: 8px 8px;
    padding-right: 12px;

    &:last-child {
      padding-right: 0;
    }
  }

  td {
    button {
      svg {
        opacity: 0.3;
        transition: 0.2s;

        path {
          transition: 0.2s;
        }
      }

      &:hover {
        svg {
          opacity: 1;
          path {
            fill: ${COLORS.danger};
          }
        }
      }
    }
  }
`;
export const THeadS = styled.thead``;
export const TBodyS = styled.tbody``;

type Keys = keyof typeof IParticipantsInputs;

export const TTh = styled.th<{ tdType: typeof IParticipantsInputs[Keys] }>`
  ${({ tdType }) => {
    switch (tdType) {
      case 'payroll_id':
        return `
        min-width: 132px;
      `;
      case 'phone':
        return `
        min-width: 210px;
      `;
      case 'phone_code':
        return `
        min-width: 110px;
      `;
      case 'first_name':
        return `
        min-width: 150px;
      `;
      case 'last_name':
        return `
        min-width: 150px;
      `;
      case 'email':
        return `
        min-width: 125px;
      `;
      case 'hiring_date':
        return `
        min-width: 150px;
      `;
      case 'ethnicity':
        return `
        min-width: 150px;
      `;
      case 'gender':
        return `
        min-width: 150px;
      `;
      default:
        return ``;
    }
  }}
`;

export const BtnCSVWrapperS = styled.div`
  flex: 1;
`;

export const AddParticipantBtnWrapperS = styled.div`
  margin-top: 15px;
  button {
    border: 0;
    /* display: inline-flex; */
    width: auto;
    color: ${COLORS.accent};
    span {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${COLORS.accent};
      border-radius: 50%;
      transition: 0.1s;
      svg {
        g {
          fill: white;
        }
      }
    }

    &:hover {
      background: transparent;
      border: 0;
      color: ${COLORS.accentHover};

      span {
        background: ${COLORS.accentHover};
      }
    }
    &:active {
      background: transparent;
      border: 0;
      color: ${COLORS.accentActive};

      span {
        background: ${COLORS.accentActive};
      }
    }
  }
`;
