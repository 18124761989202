import jwt_decode from 'jwt-decode';
import { API_ROUTE_PATH } from './api_routes';
import { callApi } from './callApi';
import { TOKEN } from './config';
import { ThrowError } from './throwError';

type TSuccess = {
  access_token?: string;
  refresh_token?: string;
  detail?: string;
  status?: number;
  title?: string;
  usrId: string;
};

export const getToken = async (
  email: string,
  password: string
): Promise<TSuccess> => {
  try {
    const data: TSuccess = await callApi({
      path: API_ROUTE_PATH.authentication.authUser,
      subPath: '/api/v1/auth',
      data: {
        email,
        password,
      },
      method: 'post',
    });

    if (!data.access_token || !data.refresh_token) {
      ThrowError.detail = 'Data does not have token';
      return ThrowError.init();
    }

    if (data.access_token && data.refresh_token) {
      localStorage.setItem(TOKEN.access, data.access_token);
      localStorage.setItem(TOKEN.refresh, data.refresh_token);
    }

    const decodeToken: {
      at_id?: string;
      authorized?: boolean;
      exp?: number;
      iat?: number;
      usr_id?: string;
    } = jwt_decode(data.access_token || '');

    if (!decodeToken.usr_id) {
      ThrowError.detail = 'Token does not have usr_id';
      return ThrowError.init();
    }

    return {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      usrId: decodeToken.usr_id,
    };
  } catch (e) {
    if (e.status) {
      return e as TSuccess;
    }
    return {
      detail: e,
      status: 401,
    } as TSuccess;
    // console.log(e);
  }
};
