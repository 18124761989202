import React from "react";
import styled from "styled-components";
import {IMissedParticipantInfo} from "../../store/participants/types";
import {COLORS} from "../../utils/colors";
import {ArrowIcon} from "../svgs";
import {ParticipantRow} from "./ParticipantRow";
import {CheckBox} from "../ui";

interface MissedParticipantsWrapperProps {
    participants: IMissedParticipantInfo[];
    selectMissedParticipantHandler: (isCheck: boolean, id: string) => void;
    checkOrUncheckAllMissedParticipantsHandler: (isCheck: boolean) => void;
    deleteParticipantsID: string[];
}

export const MissedParticipantsWrapper: React.FC<MissedParticipantsWrapperProps> = ({
    participants,
    selectMissedParticipantHandler,
    checkOrUncheckAllMissedParticipantsHandler,
    deleteParticipantsID
}) => {
    const [isShowMissedParticipants, setIsShowMissedParticipants] = React.useState(false)
    const participantOrParticipants = (): string => {
        if (participants.length === 1) {
            return`Show missed participant (${participants.length})`
        } else  {
            return`Show missed participants (${participants.length})`
        }
    }

    return (
        <>
            <BodyWrapperS>
                <WrapperS>
                    <ButtonDefS onClick={() => {setIsShowMissedParticipants(!isShowMissedParticipants)}}>
                        <span> <ArrowIcon /></span>
                        {participantOrParticipants()}
                    </ButtonDefS>
                </WrapperS>

                {isShowMissedParticipants && participants.length > 1&&<WrapperSmallS>
                    <ParticipantNameWrapperS></ParticipantNameWrapperS>
                    <ParticipantNameWrapperS></ParticipantNameWrapperS>
                    <CheckboxDefS>
                        <LabelDefS>
                            Select all
                        </LabelDefS>
                        <CheckBox
                            isCheck={participants.length === deleteParticipantsID.length}
                            onChange={(e) => {
                                checkOrUncheckAllMissedParticipantsHandler(participants.length === deleteParticipantsID.length)
                            }}
                        />
                    </CheckboxDefS>
                </WrapperSmallS>}

                {isShowMissedParticipants && participants.map((participant) => {
                        return (
                            <ParticipantRow  deleteParticipantsID={deleteParticipantsID} participant={participant} selectMissedParticipantHandler={selectMissedParticipantHandler} />
                        );
                    })
                }
            </BodyWrapperS>
        </>
    );
};

const B_RAD = '6px';
const PAD_DEF = '14px';
const HEIGHT = '42px';

export const WrapperS = styled.div`
  min-height: 63px;
  max-height: 63px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  width: 75%;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const WrapperSmallS = styled.div`
  min-height: 25px;
  max-height: 25px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  width: 75%;
  margin-bottom: 15px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const BodyWrapperS = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const BtnsWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  button {
    width: auto;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
`;
export const DangerS = styled(WrapperS)`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
`;

const ParticipantNameWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  min-width: 33.33%;
`;

export const ButtonDefS = styled.button`
  
  min-width: 33.33%;
  background-color: transparent;
  color: ${COLORS.default};
  border: 0;
  border-radius: ${B_RAD};
  padding: 0 ${PAD_DEF};
  position: relative;
  border: solid 1px ${COLORS.secondary};
  height: ${HEIGHT};
  transition: 0.2s;
  display: flex;
  min-height: ${HEIGHT};
  align-items: center;
  justify-content: center;
  
  span {
    display: flex;
    height: 14px;
    margin-right: 8px;
    align-items: center;
  }
  
  .orgvitals-loader {
    transform: scale(0.6) !important;
  }

  &:hover {
    border: solid 1px ${COLORS.accentHover};
  }

  &:active {
    border: solid 1px ${COLORS.accentActive};
  }

  &:disabled {
    opacity: 0.3;
    cursor: pointer;
  }
`;

export const LabelDefS = styled.label`
  margin-right: 10px;
`;

export const CheckboxDefS = styled.button`
  width: 20%;
  background-color: transparent;
  color: ${COLORS.default};
  border: 0;
  border-radius: ${B_RAD};
  padding: 0 ${PAD_DEF};
  position: relative;
  height: ${HEIGHT};
  transition: 0.2s;
  display: flex;
  min-height: ${HEIGHT};
  align-items: center;
  justify-content: space-around;
`;
