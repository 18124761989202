import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { AppStore } from '../../store/applicationState';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { TEXT } from '../../utils/textConst';
import { Alert, Input } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';

interface NewTeamProps {
  closeHandler: () => void;
  saveHandler: (title: string) => void;
}

const teamName = 'Team Name';
const WS_NAME = teamName.replace(/ /g, '_').toLowerCase();
export const NewTeam: React.FC<NewTeamProps> = ({ closeHandler, saveHandler }) => {
  useSelector((store: AppStore) => store);
  const { form, updateForm, onChangeHandler } = useForm({
    [WS_NAME]: {
      value: '',
    },
  });
  // const dispatch = useDispatch();
  const refInnWrapper = React.useRef<HTMLDivElement>(null);

  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  const submitHandler = () => {
    const name = form[WS_NAME].value;
    if (!name) {
      return updateForm(WS_NAME, {
        value: '',
        errorText: TEXT.isRequired,
      });
    }
    saveHandler(name);
    return closeHandler();
    // if (!User.data)
    //   return dispatch(CreateWorkspace.error('User does not exist, try to reload page'));
    // if (!User.data.team_id)
    //   return dispatch(CreateWorkspace.error('User does not have team_id, try to reload page'));
    // if (!User.data.id)
    //   return dispatch(CreateWorkspace.error('User does not have id, try to reload page'));
    // return null;
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '416px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>New Team</Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <form>
            <Input
              onChange={onChangeHandler}
              value={form[WS_NAME].value}
              name={WS_NAME}
              label={teamName}
              placeholder={teamName}
            />
            <Alert text="" />
          </form>
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>

            <ButtonDefS isSave onClick={submitHandler}>
              {' '}
              Save
              {/* {Workspaces.loading ? <Loader /> : 'Save'} */}
            </ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
