import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, {
  TDataStory,
  TDataStoryLink,
  TSnapshot,
  TSnapshotData,
} from './types';

const GetStoriesActions = new Actions('GET_STORIES', ActionTypes);

export interface TypeGetStoriesR extends TDefRequest {
  workspaceId: string;
}

export const GetStories = {
  request: (payload: TypeGetStoriesR) => GetStoriesActions.request(payload),
  success: (payload: TDataStory) => GetStoriesActions.success(payload),
  error: (message: string) => GetStoriesActions.error(message),
};

const GetStoriesByLinkActions = new Actions('GET_STORIES_BY_LINK', ActionTypes);

export interface TypeGetStoriesByLinkR extends TDefRequest {
  link: string;
}

export const GetStoriesBylink = {
  request: (payload: TypeGetStoriesByLinkR) =>
    GetStoriesByLinkActions.request(payload),
  success: (payload: TDataStory) => GetStoriesByLinkActions.success(payload),
  error: (message: string) => GetStoriesByLinkActions.error(message),
};

const EditStoriesActions = new Actions('EDIT_STORIES', ActionTypes);

export interface TypeEditSrotiesR extends TDefRequest {
  id: string;
  data: {
    logo_team: boolean;
    logo_workspace: boolean;
    frame_color: string;
    snapshots_ids_position: string[];
  };
}

export const EditStories = {
  request: (payload: TypeEditSrotiesR) => EditStoriesActions.request(payload),
  success: (payload: TDataStory) => EditStoriesActions.success(payload),
  error: (message: string) => EditStoriesActions.error(message),
};

const CreateLinkActions = new Actions('CREATE_LINK', ActionTypes);

export interface TypeCreateLinkR extends TDefRequest {
  workspaceId: string;
  link_expiration: number;
}

export const CreateLink = {
  request: (payload: TypeCreateLinkR) => CreateLinkActions.request(payload),
  success: (payload: TDataStoryLink) => CreateLinkActions.success(payload),
  error: (message: string) => CreateLinkActions.error(message),
};

const GetLinkActions = new Actions('GET_LINK', ActionTypes);

export interface TypeGetLinkR extends TDefRequest {
  workspaceId: string;
}

export const GetLink = {
  request: (payload: TypeGetLinkR) => GetLinkActions.request(payload),
  success: (payload: TDataStoryLink) => GetLinkActions.success(payload),
  error: (message: string) => GetLinkActions.error(message),
};

const DeactivateLinkActions = new Actions('DEACTIVATE_LINK', ActionTypes);

export interface TypeDeactivateLinkR extends TDefRequest {
  workspaceId: string;
}

export const DeactivateLink = {
  request: (payload: TypeDeactivateLinkR) =>
    DeactivateLinkActions.request(payload),
  success: (payload: TDataStoryLink) => DeactivateLinkActions.success(payload),
  error: (message: string) => DeactivateLinkActions.error(message),
};

const CreateSnapshotActions = new Actions('CREATE_SNAPSHOT', ActionTypes);

export interface TypeCreateSnapshotR extends TDefRequest {
  id: string;
  snapshot: TSnapshotData;
}

export const CreateSnapshot = {
  request: (payload: TypeCreateSnapshotR) =>
    CreateSnapshotActions.request(payload),
  success: (payload: TDataStory) => CreateSnapshotActions.success(payload),
  error: (message: string) => CreateSnapshotActions.error(message),
};

const CreateFileSnapshotActions = new Actions(
  'CREATE_FILE_SNAPSHOT',
  ActionTypes
);

export interface TypeCreateFileSnapshotR extends TDefRequest {
  workspaceId: string;
  data: {
    name: string;
    file: File | Blob;
    data?: {
      settings: {};
    };
  };
}

export const CreateFileSnapshot = {
  request: (payload: TypeCreateFileSnapshotR) =>
    CreateFileSnapshotActions.request(payload),
  success: (payload: TSnapshot) => CreateFileSnapshotActions.success(payload),
  error: (message: string) => CreateFileSnapshotActions.error(message),
};

const EditSnapshotActions = new Actions('EDIT_SNAPSHOT', ActionTypes);

export interface TypeEditSnapshotR extends TDefRequest {
  snapshotId: string;
  comment: string;
}

export const EditSnapshot = {
  request: (payload: TypeEditSnapshotR) => EditSnapshotActions.request(payload),
  success: (payload: TDataStory) => EditSnapshotActions.success(payload),
  error: (message: string) => EditSnapshotActions.error(message),
};

const DeleteSnapshotActions = new Actions('DELETE_SNAPSHOT', ActionTypes);

export interface TypeDeleteSnapshotR extends TDefRequest {
  snapshotId: string;
}

export const DeleteSnapshot = {
  request: (payload: TypeDeleteSnapshotR) =>
    DeleteSnapshotActions.request(payload),
  success: (payload: TDataStory) => DeleteSnapshotActions.success(payload),
  error: (message: string) => DeleteSnapshotActions.error(message),
};

export const StoriesUpdate = (payload: TDataStory) =>
  action(ActionTypes.STORIES_UPDATE, payload);
