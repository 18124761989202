import { TLang } from '../types/lang';

export const langIsEng = (lang: TLang) => lang === 'en_US';
export const langIsSpan = (lang: TLang) => lang === 'es_ES';
export const langIsFrench = (lang: TLang) => lang === 'fr_FR';
export const langIsHebrew = (lang: TLang) => lang === 'he_IL';

export const langIsAllingRight = (lang: TLang) => {
  switch (lang) {
    case 'he_IL':
      return true;
    default:
      return false;
  }
};
