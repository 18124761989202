import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChangeWorkspace } from '../../../hooks/useChangeWorkspace';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { AppStore } from '../../../store/applicationState';
import { DeleteWorkspace } from '../../../store/workspaces/actions';
import { TWorkspace } from '../../../store/workspaces/types';
import { AnimatedClasses, SelectClasses } from '../../../utils/animatedStyles';
import { deepClone } from '../../../utils/deepClone';
import { delay } from '../../../utils/helpers';

export const useWorkspaceSelect = () => {
  const { Workspaces, User } = useSelector((store: AppStore) => store);
  const [isShown, setIsShown] = React.useState(false);
  const [removedItems, setRemovedItems] = React.useState<{
    [key: string]: boolean;
  }>({});
  const { changeWorkspace } = useChangeWorkspace();
  const dispatch = useDispatch();
  const modalRef = React.useRef<HTMLDivElement>(null);
  const liRefs: HTMLLIElement[] = [];
  const hideList = async () => {
    if (modalRef && modalRef.current) {
      modalRef.current.classList.remove(SelectClasses.in);
      modalRef.current.classList.add(SelectClasses.out);
      await delay(100);
      setIsShown(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    if (isShown) {
      hideList();
    }
  });

  const userWorkspaces = User.data?.workspaces_ids || [];

  const listHandler = (workspace: TWorkspace) => {
    if (!userWorkspaces.includes(workspace.id)) return null;

    changeWorkspace(workspace);
    return hideList();
  };

  const deleteWorkspaceHandler = (position: number, id: string) => {
    if (!userWorkspaces.includes(id)) return null;
    const allWorkspaces = deepClone(Workspaces.data) as TWorkspace[];
    const workspace = allWorkspaces[position];

    if (`${workspace.id}` !== `${id}`)
      return dispatch(DeleteWorkspace.error('Workspace id does not equal id'));
    allWorkspaces.splice(position, 1);
    const newRemovedItems = { ...removedItems };

    newRemovedItems[id] = true;
    setRemovedItems(newRemovedItems);

    const callBack = (success: boolean): boolean => {
      newRemovedItems[id] = false;
      console.log(newRemovedItems, 2);
      setRemovedItems(newRemovedItems);
      if (success && liRefs && liRefs[position]) {
        liRefs[position].classList.add(
          ...AnimatedClasses.fadeOutLeft.split(' ')
        );
        setTimeout(() => {
          liRefs[position].style.display = 'none';
          dispatch(DeleteWorkspace.success(allWorkspaces));
        }, 500);
      }
      return true;
    };

    return dispatch(
      DeleteWorkspace.request({
        workspaceId: id,
        callBack,
      })
    );
  };

  const setRefs = (ref: HTMLLIElement) => liRefs.push(ref);

  return {
    ref,
    listHandler,
    isShown,
    Workspaces,
    setIsShown,
    modalRef,
    hideList,
    deleteWorkspaceHandler,
    setRefs,
    removedItems,
    userWorkspaces,
  };
};
