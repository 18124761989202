import React from 'react';
import { useDispatch } from 'react-redux';
import { EditGroup } from '../../../store/groups/actions';
import { TGroup } from '../../../store/groups/types';
import {
  DeleteParticipant,
  MoveToGroupParticipant,
} from '../../../store/participants/actions';
import { TParticipant } from '../../../store/participants/types';
import { AnimatedClasses } from '../../../utils/animatedStyles';
import { deepClone } from '../../../utils/deepClone';

export const useParticipantRow = (payload: {
  participants: TParticipant[];
  groups: TGroup[];
  loadCurrentParticipantHandler?:() => void;
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const refRow = React.useRef<HTMLTableRowElement | null>(null);

  React.useEffect(() => {
    return () => {};
  }, []);

  const moveToGroup = (data: {
    id: string;
    position: number;
    groupId: string;
  }) => {
    const { position, id, groupId } = data;
    const allParticipants = deepClone(payload.participants) as TParticipant[];

    let participant: TParticipant | undefined = allParticipants[position];

    if (!participant || (participant && `${participant.id}` !== `${id}`)) {
      participant = allParticipants.find(
        (payloadParticipant) => `${payloadParticipant.id}` === `${id}`
      );
    }
    if (!participant)
      return console.error(
        'Participant does no exist, error in moveToGroup function'
      );

    const allGroups = deepClone(payload.groups) as TGroup[];
    const group = allGroups.find(
      (payloadGroup) => `${payloadGroup.id}` === `${groupId}`
    );

    if (!group)
      return console.error(
        'Group does no exist, error in moveToGroup function'
      );

    setIsLoading(true);

    const newGroupParticipants: TGroup['participants'] = Array.isArray(
      group.participants
    )
      ? [...group.participants]
      : [];

    newGroupParticipants.push({
      id,
      first_name: participant.first_name,
      last_name: participant.last_name,
      add_source: participant.add_source,
    });

    group.participants = newGroupParticipants;

    const updateGroupObj = {
      id: groupId,
      name: group.name,
      description: group.description,
      origin: group.origin
    };

    if (participant.groups && Array.isArray(participant.groups)) {
      const groupsInParticipant = [...participant.groups];
      groupsInParticipant.push(updateGroupObj);

      participant.groups = groupsInParticipant;
    } else {
      participant.groups = [updateGroupObj];
    }

    dispatch(
      MoveToGroupParticipant.request({
        data: {
          participant_id: id,
          group_id: group.id,
          relation: true,
        },
        callBack: (success) => {
          setIsLoading(false);
          if (success) {
            dispatch(MoveToGroupParticipant.success(allParticipants));
            dispatch(EditGroup.success(allGroups));
          }
        },
      })
    );
  };

  const deleteHandler = (data: {
    id: string;
    position: number;
    hasGroups: boolean;
  }) => {
    setIsLoading(true);
    const { id, position, hasGroups } = data;
    const newParticipants = [...payload.participants];
    newParticipants.splice(position, 1);

    let newGroups: TGroup[] = [];
    if (hasGroups) {
      newGroups = deepClone(payload.groups) as TGroup[];
      Array.isArray(newGroups) &&
        newGroups.forEach((group) => {
          group.participants = group.participants.filter(
            (groupParticipant) => `${groupParticipant.id}` !== `${id}`
          );
        });
    }

    // return dispatch(
    //   EditParticipant.request({
    //     data: {
    //       first_name: participant[0].first_name,
    //       last_name: participant[0].last_name,
    //       email: participant[0].email,
    //       phone: participant[0].phone,
    //       payroll_id: participant[0].payroll_id,
    //       is_active: false,
    //     },
    //     participantId: participant[0].id,
    //     callBack: (success) => {
    //       setIsLoading(false);
    //       if (!success || !refRow || !(refRow && refRow.current)) return null;
    //       refRow.current!.style.display = 'none';
    //       dispatch(DeleteParticipant.success(newParticipants));
    //       dispatch(EditGroup.success(hasGroups ? newGroups : payload.groups));
    //     },
    //   })
    // );

    dispatch(
      DeleteParticipant.request({
        id,
        newGroups: hasGroups ? newGroups : payload.groups,
        newParticipants: newParticipants,
        callBack: (success) => {
          setIsLoading(false);
          if (!success || !refRow || !(refRow && refRow.current)) return null;

          refRow.current.classList.add(...AnimatedClasses.fadeOut.split(' '));
          refRow.current!.style.display = 'none';
          // dispatch(DeleteParticipant.success(newParticipants));
          payload.loadCurrentParticipantHandler()
          dispatch(EditGroup.success(hasGroups ? newGroups : payload.groups));
        },
      })
    );
    // console.log('remove', id, position);
  };

  return {
    deleteHandler,
    refRow,
    isLoading,
    moveToGroup,
  };
};
