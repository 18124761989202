import React from 'react';
import styled from 'styled-components';
import { DefaultSelect } from '../../ui';
import { AssessmentIcon, CollabIcon, TimePeriodIcon } from '../../svgs';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';

import { useFilter } from './hooks/useFilter';
import { useGroups } from '../../../hooks/useGroups';
import { SelectWithRange } from '../../ui/selects/SelectWithRange';
// import { getIds } from '../utils/getIds';
import { RangeFilter } from './Range';
import { ButtonDefS } from '../../ui/buttons/styles';
import { AssesmQuestionSelect } from '../../ui/selects/AssesmQuestionSelect';

interface FiltersProps {
  range: number[];
  setRange: React.Dispatch<React.SetStateAction<number[]>>;
  setFirstLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Filters: React.FC<FiltersProps> = ({ range, setRange,setFirstLoading }) => {
  const filterUseData = useFilter();
  useGroups();

  return (
    <WrapperS>
      <DefaultSelect
        data={filterUseData.selectDataGroups}
        label="Group"
        icon={CollabIcon}
        selected={filterUseData.selectDataGroups[0]}
        onChange={filterUseData.groupSelectHandler}
        activeSelects={filterUseData.activeGroupIds}
        isMultiple
      />
      <AssesmQuestionSelect
        data={filterUseData.selectDataAss}
        icon={AssessmentIcon}
        onAssessmentChange={filterUseData.assSelectHandler}
        onQuestionChange={filterUseData.questionSelectHandler}
        selected={filterUseData.selectDataAss[0]}
        label="Assessment"
        isMultiple
        activeSelects={filterUseData.activeAssIds}
        activeQuestionSelects={filterUseData.activeQuestionIds}
        setQuestionActiveSelects={filterUseData.setActiveQuestionIds}
        isAllQuestionsSelected={filterUseData.isAllQuestionsSelected}
        setIsAllQuestionsSelected={filterUseData.setIsAllQuestionsSelected}
      />{' '}
      <SelectWithRange
        icon={TimePeriodIcon}
        placeHolder="Choose time"
        selected={filterUseData.selectedRange()}
        onSave={(data) => {
          // filterUseData.getCollab({
          //   period_of_answer_date: {
          //     is_all_time: data === 'all_time',
          //     is_last_quarter: data === 'last_quarter',
          //     is_last_month: data === 'last_month',
          //     is_last_year: data === 'last_year',
          //     start_date: '',
          //     end_date: '',
          //   },
          //   period_of_hiring_date: {},
          //   groups_ids,
          //   is_all_participants: !groups_ids[0],
          //   assessments_ids: ass_ids,
          //   is_all_assessments: !ass_ids[0],
          // });
          filterUseData.setActivePeriod(data);
          filterUseData.setRangeDates({
            start_date: '',
            end_date: '',
          });
        }}
        onCustomSave={({ start_date, end_date }) => {
          filterUseData.setActivePeriod('');
          filterUseData.setRangeDates({
            start_date,
            end_date,
          });
          // filterUseData.getCollab({
          //   period_of_answer_date: {
          //     is_all_time: false,
          //     is_last_quarter: false,
          //     is_last_month: false,
          //     is_last_year: false,
          //     start_date,
          //     end_date,
          //   },
          //   period_of_hiring_date: {},
          //   groups_ids,
          //   is_all_participants: !groups_ids[0],
          //   assessments_ids: ass_ids,
          //   is_all_assessments: !ass_ids[0],
          // });
        }}
        label="Time Period"
      />
      <ButtonWrapperS>
        <ButtonDefS onClick={()=>{
            setFirstLoading(false)
            filterUseData.applyHandler()
        }

        }>Apply</ButtonDefS>
      </ButtonWrapperS>
      <RangeFilter range={range} setRange={setRange} />
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  .ov-select {
    max-width: 250px;
    .dateRangeWrapper {
      left: 0;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    g {
      fill: ${COLORS_RGBA.accent()};
    }
  }
  .svgIconSelect svg {
    transform: translateY(0) scale(-1, 1) !important;
    g {
      fill: ${COLORS.accent};
      opacity: 1;
    }
  }
`;
// const InnerWrapperS = styled.div`
//   display: flex;
//   border: solid 1px #d4d5d4;
//   border-radius: 8px;
//   padding: 8px;
//   & > * {
//     margin-right: 16px;
//     &:last-child {
//       margin-right: 0px;
//     }
//   }
// `;

const ButtonWrapperS = styled.div`
  padding-top: 22px;
  button {
    height: 48px;
  }
`;
