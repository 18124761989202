import { TGroup } from '../../../store/groups/types';
import { TEditGroupInfo } from '../../participants/types/groupTypes';

export const updateGroup = (groups: TGroup[], data: TEditGroupInfo): TGroup[] => {
  const newGroups = [...groups];

  if (!data.title || !data.id) return newGroups;

  const updateObj = (group: TGroup) => {
    group.name = data.title;
    group.description = data.description;
    return group;
  };

  if (`${newGroups[data.position].id}` === `${data.id}`) updateObj(newGroups[data.position]);
  else {
    const group = newGroups.find((g) => `${g.id}` === `${data.id}`);
    group && updateObj(group);
  }

  return newGroups;
};
