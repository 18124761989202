import React from 'react';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { MainWrapperS } from '../../globalStyle';

interface PeopleProps {}

export const People: React.FC<PeopleProps> = () => {
  return (
    <MainWrapperS>
      <PageTitleWrapperS>
        <h1>People</h1>
      </PageTitleWrapperS>
    </MainWrapperS>
  );
};
