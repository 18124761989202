import React from 'react';
import styled from 'styled-components';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { Alert, DefaultSelect, Loader } from '../../components/ui';
import {
    ButtonBorderedS,
    ButtonDefS,
} from '../../components/ui/buttons/styles';
import { SelectItemType } from '../../components/ui/selects/DefaultSelect';
import { TWorkspace } from '../../store/workspaces/types';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { TEXT } from '../../utils/textConst';
import {CSVLink} from "react-csv";
import {ROUTE_PATH} from "../../utils/routes";
import {useHistory} from "react-router-dom";

interface NetworkMapCsv {
    data: [];
}

export const NetworkMapReport: React.FC<NetworkMapCsv> = () => {
    const [errors, setErrors] = React.useState('');
    const [teams, setTeams] = React.useState<SelectItemType[]>([]);
    const [currentTeam, setCurrentTeam] = React.useState<SelectItemType | null>(
        null
    );
    const [worksapces, setWorkspaces] = React.useState<SelectItemType[]>([]);
    const [currentWorkspace, setCurrentWorkspace] =
        React.useState<SelectItemType | null>(null);
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [headers, setHeaders] = React.useState(null);
    const refLinkCvs = React.useRef<any>(null);

    const history = useHistory();
    const genCSV = async () => {
        if (!worksapces) return null;
        setIsLoading(true);
        try {
            const respData = {
                workspace_id: currentWorkspace.id,
            };
            const { workspace_id } = respData;
            const resp = await callApi({
                method: 'post',
                path: API_ROUTE_PATH.admins.csvPath,
                data: {
                    workspace_id
                }
            });
            setHeaders(resp.headers);
            setData(resp.data);
            if (refLinkCvs && refLinkCvs.current) {
                refLinkCvs.current.link.click();
            }

        } catch (e) {
            console.log(e);
            alert(JSON.stringify(e));
        } finally {
            setIsLoading(false);
        }
    };

    const getTeams = React.useCallback(async () => {
        interface ITeamsData {
            id: string;
            name: string;
        }

        try {
            setErrors('');
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.teamsPath,
                method: 'get',
            })) as ITeamsData[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrors(TEXT.someError);
            }

            const newTeams: typeof teams = [];

            data.forEach((itm) => {
                newTeams.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });

            return setTeams(newTeams);
        } catch (e) {
            setErrors(`${e}`);
            window.location.href = '/';
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getWorkspaces = React.useCallback(async () => {
        try {
            setErrors('');
            const data = (await callApi({
                path: API_ROUTE_PATH.admins.workspacesPath(currentTeam.id as string),
                method: 'get',
            })) as TWorkspace[];

            if (
                !(data && Array.isArray(data) && data[0] && data[0].id && data[0].name)
            ) {
                console.log(data, 'error data');
                return setErrors(TEXT.someError);
            }

            const newWorkspaces: typeof worksapces = [];

            data.forEach((itm) => {
                newWorkspaces.push({
                    title: itm.name,
                    value: itm.id,
                    id: itm.id,
                });
            });

            return setWorkspaces(newWorkspaces);
        } catch (e) {
            setErrors(`${e}`);
        } finally {
            setIsLoading(false);
        }
    }, [currentTeam]);

    React.useEffect(() => {
        setIsLoading(true);
        getTeams();
    }, [getTeams]);

    React.useEffect(() => {
        if (currentTeam) {
            setCurrentWorkspace(null);
            getWorkspaces();
        }
    }, [currentTeam, getWorkspaces]);

    const renderBody = () => {
        // if (permissionError) return <Loader isFixed isGreen />;
        // if (isLoading) return <Loader isFixed isGreen />;
        // if ('') return <Alert text={''} />;
       return (
           <div>
               <MainBodyWrapper>
                   <FirstSelectGroupWrapper>
                       <DefaultSelect
                           data={teams}
                           onChange={setCurrentTeam}
                           selected={currentTeam}
                           label={'Team'}
                           placeHolder={'Select Team'}
                           isSearch={true}
                       />

                       <DefaultSelect
                           data={worksapces}
                           onChange={setCurrentWorkspace}
                           selected={currentWorkspace}
                           label={'Workspace'}
                           placeHolder={'Select Workspace'}
                           isSearch={true}
                       />
                   </FirstSelectGroupWrapper>
               </MainBodyWrapper>
               <ButtonDefS
                   onClick={!isLoading ? () => genCSV() : () => null}
                   style={{ maxWidth: '97px' }}
                   disabled={false}
               >
                   {isLoading ? <Loader size={0.5} /> : 'Export'}
               </ButtonDefS>
               <CSVLink
                   data={data || ''}
                   headers={headers || []}
                   ref={refLinkCvs}
                   style={{ display: 'none' }}
                   filename={
                       currentWorkspace &&
                       `${currentWorkspace.title}.csv`
                   }

                   // enclosingCharacter={`'`}
               >
                   Download me
               </CSVLink>
           </div>
       )
    };

    return (

        <>
            <div style={{ padding: '100px 20px', maxWidth: '900px', margin: 'auto' }}>
                <TitleWrapperS>
                    <h1>Network Map CSV</h1>
                    <ButtonBorderedS
                        style={{ maxWidth: '200px' }}
                        onClick={() => history.push(ROUTE_PATH.admin)}
                    >
                        Go to the main page
                    </ButtonBorderedS>
                </TitleWrapperS>
                {renderBody()}
                {errors && <Alert text={errors} />}
            </div>
        </>
    );
};

const TitleWrapperS = styled(PageTitleWrapperS)`
  justify-content: space-between;
  width: 100%;
`;

const MainBodyWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FirstSelectGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;