import styled from 'styled-components';
import { COLORS_RGBA } from '../../../utils/colors';

export const WrapperS = styled.div<{ isDragging?: boolean }>`
  height: 95px;
  padding: 12px;
  border-radius: 10px;
  border: solid 1px ${COLORS_RGBA.default(0.06)};
  width: 31%;
  display: flex;
  background: white;

  & > div {
    &:nth-child(2) {
      height: 24px;
    }
  }

  ${({ isDragging }) =>
    isDragging
      ? `
  background: #f4f4f4;
  border-color: #f4f4f4;
  & > * {
  opacity: 0;
}
`
      : `
background: white;
`}
`;
export const TitleWrapperS = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  @media screen and (max-width: 1400px) {
    max-width: 90%;
  }
  h5 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3c3c3c;

    @media screen and (max-width: 1400px) {
      white-space: nowrap;
      overflow: hidden;
      max-width: 90%;
      text-overflow: ellipsis;
      font-size: 1.4rem;
    }
  }
`;
export const QuestionsCountS = styled.span`
  flex: 1;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #111111;
  opacity: 0.5;
  height: auto;
`;

export const VersionS = styled.span`
  color: ${COLORS_RGBA.default(0.5)};
  position: absolute;
  bottom: 5px;
  right: 10px;
`;
