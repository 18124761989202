import { Reducer } from 'redux';
import ActionTypes, { TAssessmentCardsState } from './types';

export const initialState: TAssessmentCardsState = {
  loading: true,
  data: null,
  errors: undefined,
};

const reducer: Reducer = (
  state = initialState,
  action
): TAssessmentCardsState => {
  switch (action.type) {
    case ActionTypes.CREATE_CARDS_R:
    case ActionTypes.DELETE_CARDS_R:
    case ActionTypes.GET_ALL_CARDS_R:
      return { ...state };
    case ActionTypes.CREATE_CARDS_S:
      return {
        ...state,
        data: [...state.data, ...action.payload],
        loading: false,
      };
    case ActionTypes.DELETE_CARDS_S:
    case ActionTypes.GET_ALL_CARDS_S:
      return { ...state, data: action.payload, loading: false };
    case ActionTypes.CREATE_CARDS_E:
    case ActionTypes.DELETE_CARDS_E:
    case ActionTypes.GET_ALL_CARDS_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
export { reducer as AssessmentCardsReducer };
