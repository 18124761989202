import React, { useCallback } from 'react';
import { useFinchConnect, SuccessEvent } from "@tryfinch/react-connect";
import { callApi } from '../../utils/callApi';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import {
  CreateFinchIntegration,
  GetAllFinchIntegrations,
} from '../../store/finchIntegrations/actions';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { IntegrationItem } from './IntegrationItem';
import { ButtonAdd, Loader } from '../ui';
import { MessageModal } from '../modals';
import {EditWorkspace} from "../../store/workspaces/actions";
import {deepClone} from "../../utils/deepClone";

interface FinchIntegrationsProps {}
export const FinchIntegrations: React.FC<FinchIntegrationsProps> = () => {

  const { Workspaces, FinchIntegrations } = useSelector(
      (store: AppStore) => store
  );
  const [clientId, setClientId] = React.useState<string>('');
  const [products, setProducts] = React.useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!Workspaces.loading && Workspaces.current) {
      dispatch(
        GetAllFinchIntegrations.request({ workspaceId: Workspaces.current.id })
      );
    }
  }, [dispatch, Workspaces]);

    React.useEffect(() => {
        // eslint-disable-next-line
        FinchIntegrations.data?.map((integration) => {
            const d_next = new Date(integration.sync_scheduled_at);

            if (integration.local_sync_completed_at === '0001-01-01T00:00:00Z' ||
                integration.local_sync_status === 'in_progress' ||
                d_next <= new Date()) {
                setIsModalOpen(true)
                // eslint-disable-next-line
                return
            }

            if ( integration.sync_completed_at === '0001-01-01T00:00:00Z' ||
                integration.sync_status === 'in_progress'||
                d_next <= new Date()) {
                setIsModalOpen(true)
                // eslint-disable-next-line
                return
            }
        })
    }, [Workspaces, FinchIntegrations]);

  const onSuccess = useCallback(
    (code: SuccessEvent) => {
      const callBack = (success: boolean) => {
        if (!success) return null;
        if (!Workspaces.loading && Workspaces.current) {
            let workspace = deepClone(
                Workspaces.current
            ) as typeof Workspaces.current;
            workspace.finch_integration = []
            workspace.finch_integration.push(...FinchIntegrations.data)
            setIsModalOpen(true)
            dispatch(EditWorkspace.setWorkspace(workspace));
        }
      };
      // Send public_token to server (Step 3)
      dispatch(
        CreateFinchIntegration.request({
          workspaceId: Workspaces.current.id,
          data: {
            code: code.code,
          },
          callBack
        } )
      );
      // setIsModalOpen(true);
    },
      // eslint-disable-next-line
    [Workspaces, dispatch]
  );

  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log("User exited Finch Connect");

  const { open } = useFinchConnect({
    clientId: clientId,
    products: products,
    onSuccess,
    onError,
    onClose,
  });

  const onClick = async () => {
   let resp: {client_id: string, products: string[], redirect_uri: string}
    try {
      resp = (await callApi({
        method: 'get',
        path: API_ROUTE_PATH.finchIntegrations.genLinkToken(
          Workspaces.current.id
        ),
      })) as {
        client_id: string;
        products: string[];
        redirect_uri: string;
      };

      setClientId(resp.client_id);
      setProducts(resp.products);
    } catch (e) {
      console.log(e);
    } finally {
      open({ clientId: resp.client_id, products:resp.products, onSuccess, onError, onClose });
    }
  };
  if (FinchIntegrations.loading) return <Loader isGreen />;
  return (
    <>
      <div className={AnimatedClasses.fadeIn}>
        <WrapperS>
          {FinchIntegrations.data?.map((integration) => {
            return (
              <IntegrationItem integration={integration} key={integration.id} />
            );
          })}
          {/*nooooooooooo*/}
          {/*<IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'red',
            created_at: new Date(),
            id: '1',
            image_url: '',
            square_image_url: '',
            name: 'Integration',
            slug: 'a',
            status: 'is_working',
            merge_models: [
              {
                id: '2',
                name: 'name',
                slug: 'name',
                status: 'SYNCING',
                is_initial_sync: false,
                is_last_sync_completed: true,
                last_sync: '0001-01-01T23:00:00Z',
                last_sync_start: '0001-01-01T00:00:00Z',
                next_sync_start: '0001-01-01T00:00:00Z',
              },
              {
                id: '3',
                name: 'name2',
                slug: 'name2',
                status: 'DONE',
                is_initial_sync: false,
                is_last_sync_completed: false,
                last_sync: '0001-01-01T23:00:00Z',
                last_sync_start: '0001-01-01T23:00:00Z',
                next_sync_start: '0001-01-01T23:00:00Z',
              },
            ],
          }}
        />
        <IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'blue',
            created_at: new Date(),
            id: '2',
            image_url: '',
            square_image_url: '',
            name: 'Integration2',
            slug: 'a',
            status: 'is_working',
          }}
        />
        <IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'yellow',
            created_at: new Date(),
            id: '3',
            image_url: '',
            square_image_url: '',
            name: 'Integration3',
            slug: 'a',
            status: 'is_working',
          }}
        />
        <IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'red',
            created_at: new Date(),
            id: '4',
            image_url: '',
            square_image_url: '',
            name: 'Integration4',
            slug: 'a',
            status: 'is_working',
          }}
        />
        <IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'red',
            created_at: new Date(),
            id: '5',
            image_url: '',
            square_image_url: '',
            name: 'Integration5',
            slug: 'a',
            status: 'is_working',
          }}
        />
        <IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'red',
            created_at: new Date(),
            id: '6',
            image_url: '',
            square_image_url: '',
            name: 'Integration6',
            slug: 'a',
            status: 'is_working',
          }}
        />
        <IntegrationItem
          integration={{
            categories: ['', ''],
            color: 'red',
            created_at: new Date(),
            id: '7',
            image_url: '',
            square_image_url: '',
            name: 'Integration7',
            slug: 'a',
            status: 'is_working',
          }}
        /> */}
          {FinchIntegrations.data && !FinchIntegrations.data[0] &&<ButtonAdd
            title="Add new integration"
            onClick={onClick}
            // disabled={!isReady}
          />}
        </WrapperS>
      </div>
      {isModalOpen && (
        <MessageModal
          title={
            'OrgVitals is currently synchronizing data. This can take up to 8 hours, depending on the number of participants.'
          }
          closeHandler={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
const WrapperS = styled.ul`
  margin-top: 16px;
  max-width: 504px;
  padding: 0;
  list-style: none;
  button {
    width: 100%;
  }
`;
