import React from 'react';
import styled from 'styled-components';
import { AnimatedClasses } from '../../utils/animatedStyles';

interface AlertProps {
  type?: 'danger' | 'warning' | 'success';
  text: string;
}

export const Alert: React.FC<AlertProps> = ({ type = 'danger', text }) => {
  const renderAlert = () => {
    switch (type) {
      case 'warning':
        return (
          <WarningS
            className={AnimatedClasses.bounceIn}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        );
      case 'success':
        return (
          <SuccessS
            className={AnimatedClasses.bounceIn}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        );
      default:
        return (
          <DangerS
            className={AnimatedClasses.bounceIn}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        );
    }
  };

  if (!text) return null;

  return renderAlert();
};

export const WrapperS = styled.div`
  position: relative;
  padding: 1rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1.4rem;
  line-height: 1.2em;
  margin: 10px 0;
  white-space: break-spaces;
`;
export const DangerS = styled(WrapperS)`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
`;
export const SuccessS = styled(WrapperS)`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
`;
export const WarningS = styled(WrapperS)`
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
`;
