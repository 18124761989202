import React from 'react';
import { DeleteIcon } from '../../svgs';
import { WrapperItemSelectS } from './styles';

interface DeleteSelectItemProps {
  title?: string;
}

export const DeleteSelectItem: React.FC<DeleteSelectItemProps> = ({ title = 'Delete' }) => {
  return (
    <WrapperItemSelectS isRed>
      <DeleteIcon />
      {title}
    </WrapperItemSelectS>
  );
};
