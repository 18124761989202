import React from 'react';

export const TimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#4CAF50">
          <g>
            <g>
              <path
                d="M20 10c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 2c-3.314 0-6 2.686-6 6s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm0 2c.552 0 1 .448 1 1v2.42l2.102 1.214c.478.276.642.888.366 1.366-.276.478-.888.642-1.366.366l-2.558-1.476C19.22 18.724 19 18.388 19 18v-3c0-.552.448-1 1-1z"
                transform="translate(-500 -438) translate(464 259) translate(24 169)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
