import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { AppStore } from '../../store/applicationState';
import { CreateGroup, EditGroup } from '../../store/groups/actions';
import { EditParticipant } from '../../store/participants/actions';
import { TParticipant } from '../../store/participants/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { deepClone } from '../../utils/deepClone';
import { removeSpaces } from '../../utils/print';
import { TEXT } from '../../utils/textConst';
import { TEditGroupInfo } from '../participants/types/groupTypes';
import { Alert, Input, Loader, TextArea } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import { updateGroup } from './utils/updateGroup';

interface NewGroupProps {
  data: TEditGroupInfo | null;
  closeHandler: () => void;
}

const InputNameTitle = 'Group Name',
  InputDescriptionTitle = 'Description',
  NAME = removeSpaces(InputNameTitle),
  DESCRIPTION = removeSpaces(InputDescriptionTitle);

export const NewGroup: React.FC<NewGroupProps> = ({ closeHandler, data }) => {
  const { Groups, Workspaces, Participants } = useSelector(
    (store: AppStore) => store
  );
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const { form, updateForm, onChangeHandler } = useForm({
    [NAME]: { value: (data && data.title) || '' },
    [DESCRIPTION]: { value: (data && data.description) || '' },
  });

  const dispatch = useDispatch();
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  const updateParticipantGroup = (group: TEditGroupInfo) => {
    if (!Participants.data) return console.error('Could not find groups');
    const newParticipants = deepClone(Participants.data) as TParticipant[];

    newParticipants.forEach((participant) => {
      const participantGroup =
        participant.groups &&
        participant.groups[0] &&
        participant.groups.find((pg) => `${pg.id}` === `${group.id}`);
      if (!participantGroup) return null;
      participantGroup.name = group.title;
      participantGroup.description = group.description;
    });

    return dispatch(EditParticipant.success(newParticipants));
  };

  const submitHandler = () => {
    if (Groups.loading) return null;
    if (!form[NAME].value) {
      return updateForm(NAME, {
        value: '',
        errorText: InputNameTitle + ' is required',
      });
    }

    if (data?.id) {
      return dispatch(
        EditGroup.request({
          data: {
            description: form[DESCRIPTION].value,
            name: form[NAME].value,
            id: data.id,
            newGroups: updateGroup(deepClone(Groups.data!), {
              ...data,
              description: form[DESCRIPTION].value,
              title: form[NAME].value,
            }),
          },
          callBack: (success) => {
            if (success) {
              if (data && data.id && data.participants[0]) {
                updateParticipantGroup({
                  ...data,
                  title: form[NAME].value,
                  description: form[DESCRIPTION].value,
                });
              }
              closeModalHandler();
            }
          },
        })
      );
    }

    return dispatch(
      CreateGroup.request(
        {
          description: form[DESCRIPTION].value,
          name: form[NAME].value,
          workspace_id: Workspaces.current?.id || '',
        },
        (success) => {
          if (success) {
            closeModalHandler();
          }
        }
      )
    );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '416px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>
            {data?.id ? 'Edit Group' : 'New Group'}{' '}
          </Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <form
            onKeyDown={(e: {
              keyCode: number;
              preventDefault: () => void;
              shiftKey: any;
            }) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                submitHandler();
              }
            }}
          >
            <Input
              onChange={onChangeHandler}
              value={form[NAME].value}
              name={NAME}
              label={InputNameTitle}
              placeholder={InputNameTitle}
              errorText={form[NAME].errorText}
              autoFocus
            />
            <TextArea
              stylesWrapper={{ marginTop: '20px' }}
              onChange={onChangeHandler}
              value={form[DESCRIPTION].value}
              name={DESCRIPTION}
              label={InputDescriptionTitle}
              placeholder={InputDescriptionTitle}
            />
          </form>

          {Groups.errors ? (
            <Alert type="danger" text={Groups.errors || TEXT.someError} />
          ) : null}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonDefS isSave onClick={submitHandler}>
              {Groups.loading ? <Loader size={0.5} /> : 'Save'}
            </ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
