import 'animate.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { GlobalStyle } from './globalStyle';
import reportWebVitals from './reportWebVitals';
import store from './store';
console.log(`app version: ${process.env.REACT_APP_VERSION}`);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <App />
      </Router>{' '}
    </Provider>
  </React.StrictMode>,
  document.getElementById('orgvitalsApp')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
