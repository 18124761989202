import React from 'react';
import {
  TCollaborations,
  TRespCollaborators,
  TRespFriendly,
} from '../../Responding/types';

export const useCollabResponding = () => {
  // const [step, setStep] = React.useState(1);
  // const [errorText, setErrorText] = React.useState('');
  // const [users, setUsers] = React.useState<TRespUser[] | null>(null);
  // const [user, setUser] = React.useState<TRespUser | null>(null);
  // const { id } = useParams<{ id: string }>();
  // const [identifyData, setIdentifyData] = React.useState<TRespIdentify | null>(
  //   null
  // );
  const [collab, setCollab] = React.useState<TRespCollaborators[] | null>(null);
  const [friendСollab, setFriendCollab] = React.useState<
    TRespFriendly[] | null
  >(null);
  const [activeCollabs, setActiveCollabs] = React.useState<TCollaborations[]>(
    []
  );
  const [activeFriendCollabs, setActiveFriendCollabs] = React.useState<
    TCollaborations[]
  >([]);

  React.useEffect(() => {
    if (collab) {
      const newActiveCollabs: TCollaborations[] = [];
      collab.forEach((current) => {
        if (current.is_collaborator)
          newActiveCollabs.push({
            collaborator_id: current.id,
            collaboration_type: current.type_collaboration,
          });
      });
      setActiveCollabs(newActiveCollabs);
    }
  }, [collab]);

  React.useEffect(() => {
    if (friendСollab) {
      const newActiveFriendCollabs: TCollaborations[] = [];
      friendСollab.forEach((current) => {
        if (current.is_friendly)
          newActiveFriendCollabs.push({
            collaborator_id: current.id,
            collaboration_type: 'friendly',
          });
      });
      setActiveFriendCollabs(newActiveFriendCollabs);
    }
  }, [friendСollab]);

  // React.useEffect(() => {
  //   (async () => {
  //     const resp = await getUsers({ id, linkType: ELinkType.collaboration });
  //     setLoading(false);
  //     if (resp.success) {
  //       setUsers(resp.users);
  //     }
  //     if (!resp.success && resp.errorText) {
  //       setErrorText(resp.errorText);
  //     }
  //   })();
  // }, [id]);

  const selectManager = (collab: TRespCollaborators) => {
    const newActiveCollabs = [...activeCollabs];
    const activeCollab = newActiveCollabs.find(
      (itm) => itm.collaborator_id === collab.id
    );
    if (!activeCollab) return null;
    if (activeCollab.collaboration_type === 'default')
      activeCollab.collaboration_type = 'manager';
    else activeCollab.collaboration_type = 'default';
    setActiveCollabs(newActiveCollabs);
  };

  const setDefault = () => {
    const newActiveCollabs = [...activeCollabs];
    newActiveCollabs.forEach((curCollab) => {
      if (curCollab.collaboration_type === '')
        curCollab.collaboration_type = 'default';
    });
    setActiveCollabs(newActiveCollabs);
  };

  // const submitHandler = async () => {
  //   console.log('submit handler');
  //   // setLoading(true);
  //   // try {
  //   //   await callApi({
  //   //     path: API_ROUTE_PATH.participants.collaboration,
  //   //     method: 'post',
  //   //     data: {
  //   //       link: id,
  //   //       participant_id: identifyData.participant.id,
  //   //       collaboration: activeCollabs,
  //   //     },
  //   //   });
  //   //   setStep(5);
  //   //   return true;
  //   // } catch (e) {
  //   //   setErrorText(`${e}`);
  //   //   return false;
  //   // } finally {
  //   //   setLoading(false);
  //   // }
  // };

  return {
    // step,
    // setStep,
    // users,
    // user,
    // setUser,
    // errorText,
    setCollab,
    collab,
    setFriendCollab,
    friendСollab,
    activeFriendCollabs,
    setActiveFriendCollabs,
    // setIdentifyData,
    // identifyData,
    selectManager,
    // submitHandler,
    setDefault,
    activeCollabs,
    setActiveCollabs,
  };
};
