import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { CreateAssessmentCards } from '../../store/assessmentCards/actions';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { deepClone } from '../../utils/deepClone';
import { ButtonCancel, ButtonSave, Loader } from '../ui';
import { DefaultSelect, SelectItemType } from '../ui/selects/DefaultSelect';
import * as Styles from './modalStyles';

interface AddMonitorCardsProps {
  closeHandler: () => void;
}

export const AddMonitorCards: React.FC<AddMonitorCardsProps> = ({
  closeHandler,
}) => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [list, setList] = React.useState<SelectItemType[]>([]);
  const [activeItms, setActiveItms] = React.useState<SelectItemType[]>([]);
  const [activeAssIds, setActiveAssIds] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (Workspaces.current)
      (async () => {
        try {
          const resp = (await callApi({
            method: 'get',
            path: API_ROUTE_PATH.assessments.getAllByWorkspace(
              Workspaces.current.id
            ),
          })) as { id: string; name: string; version: string }[];
          const newList: SelectItemType[] = [];

          resp.forEach((itm) => {
            let name = itm.name;
            if (
              resp.find(
                (ass) =>
                  ass.name === itm.name && ass.id !== itm.id && ass.version
              )
            )
              name = `${itm.name} ${itm.version}`;
            newList.push({ title: name, value: name, id: itm.id });
          });
          setList(newList);
        } catch (e) {
          console.warn(e);
        } finally {
          console.log('finally');
        }
      })();
  }, [Workspaces]);

  const selectHandler = (data: SelectItemType) => {
    const newData = deepClone(activeItms) as SelectItemType[];
    const ass_ids = [];
    let hasVal = false;
    newData.forEach((itm) => {
      if (itm.id === data.id) hasVal = true;
    });
    if (hasVal) {
      const index = newData.findIndex((itm) => itm.id === data.id);
      newData.splice(index, 1);
    } else {
      newData.push(data);
    }
    newData.forEach((itm) => {
      ass_ids.push(itm.id);
    });
    setActiveAssIds(ass_ids);
    setActiveItms(newData);
    return null;
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS style={{ maxWidth: '768px', height: '650px' }}>
        <Styles.ModalHeaderS>
          <h1 style={{ fontSize: '20px' }}>Сhoose assessments to monitor</h1>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          {list[0] ? (
            <SelectWrapperS>
              <DefaultSelect
                placeHolder="Assessment name"
                data={list}
                selected={activeItms[0]}
                onChange={selectHandler}
                activeSelects={activeItms}
                isMultiple
              />
            </SelectWrapperS>
          ) : (
            <Loader isGreen />
          )}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonCancel onClick={closeHandler} />
            <ButtonSave
              onClick={async () => {
                if (activeAssIds[0]) {
                  setLoading(true);
                  try {
                    dispatch(
                      CreateAssessmentCards.request({
                        workspaceId: Workspaces.current.id,
                        data: { assessment_ids: activeAssIds },
                      })
                    );
                  } catch (e) {
                    console.log(e);
                  } finally {
                    setLoading(false);
                    closeHandler();
                  }
                }
              }}
              title={'Add monitor'}
              loading={loading}
            />
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const SelectWrapperS = styled.div`
  span {
    max-width: 100% !important;
  }
`;
