import React from 'react';

interface VisualizationIconProps {}

export const VisualizationIcon: React.FC<VisualizationIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <g>
            <g>
              <path
                d="M27 29c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1s-1-.448-1-1v-3c0-.552.448-1 1-1zm5-4c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1s-1-.448-1-1v-7c0-.552.448-1 1-1zm10-3c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1s-1-.448-1-1V23c0-.552.448-1 1-1zm-5 4c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1s-1-.448-1-1v-6c0-.552.448-1 1-1zm6.433-12c.313 0 .567.254.567.567v4.866c0 .313-.254.567-.567.567-.15 0-.294-.06-.4-.166l-1.721-1.721-5.196 5.213c-.549.55-1.417.585-2.006.103l-.114-.103-1.962-1.967-6.328 6.347c-.36.362-.927.39-1.32.084l-.093-.084c-.36-.361-.388-.93-.083-1.323l.083-.094 6.681-6.703c.55-.55 1.418-.584 2.007-.103l.114.103 1.96 1.967 4.841-4.856-1.73-1.73c-.221-.22-.221-.58 0-.801.106-.106.25-.166.4-.166h4.867z"
                transform="translate(-24 -400) translate(0 64) translate(0 322)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
