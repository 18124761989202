import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import { useUploadImage } from '../../../hooks/useUplodImage';
import { AppStore } from '../../../store/applicationState';
import { UpdateTeam } from '../../../store/team/actions';
import { TEXT } from '../../../utils/textConst';
const NAME = 'name';
export const useMyTeamInfo = () => {
  const dispatch = useDispatch();
  const {
    image,
    onChangeUploadHandler,
    removeImageHandler,
    imageChanged,
    imageError,
    refInputUpload,
    setImage,
  } = useUploadImage();
  const [warning, setWarning] = React.useState('');
  const { Team } = useSelector((store: AppStore) => store);
  const myTeam = Team.data;
  const [errors, setErrors] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { form, onChangeHandler, updateForm } = useForm({
    [NAME]: {
      value: myTeam?.name || '',
    },
  });
  React.useEffect(() => {
    setErrors(`${imageError}`);
  }, [imageError]);
  React.useEffect(() => {
    if (myTeam?.logo_url)
      setImage({
        src: myTeam.logo_url,
        file: null,
      });
    // eslint-disable-next-line
  }, []);
  const getVal = (name: string) => {
    return form[name].value;
  };
  const getError = (name: string) => {
    return form[name].errorText;
  };
  const updateImageHandler = (file: File | null, src: string) => {
    setImage({ src: '', file: null });
    dispatch(
      UpdateTeam.request({
        data: {
          name: getVal(NAME),
          is_logo_changed: true,
          file,
        },
        id: myTeam.id,
        src,
      })
    );
    setImage({ src, file });
  };
  const saveHandler = () => {
    const name = getVal(NAME);
    let hasErrors = false;
    if (!name) {
      hasErrors = true;
      updateForm(NAME, { value: '', errorText: TEXT.isRequired });
    }

    if (hasErrors) return null;

    setLoading(true);

    editUserHandler(name, false, image.file);
    setLoading(false);
  };
  const clickUploadHandler = () => {
    if (!refInputUpload.current) return null;
    dispatch(UpdateTeam.error(''));
    refInputUpload.current!.click();
  };
  const editUserHandler = (
    name: string,
    is_logo_changed: boolean,
    file: File | null
  ) => {
    setWarning('');
    if (!myTeam) return setWarning('No current team');

    myTeam.name = name;
    myTeam.logo_url = image.src;

    const callBack = (success: boolean) => {
      if (success) {
        dispatch(UpdateTeam.success(myTeam));
      }
    };
    return dispatch(
      UpdateTeam.request({
        data: {
          name,
          is_logo_changed,
          file,
        },
        id: myTeam.id,
        src: image.src,
        callBack,
      })
    );
  };
  return {
    NAME,
    warning,
    imageChanged,
    refInputUpload,
    onChangeUploadHandler,
    updateImageHandler,
    onChangeHandler,
    clickUploadHandler,
    removeImageHandler,
    getError,
    getVal,
    saveHandler,
    loading,
    image,
    errors,
  };
};
