import React from 'react';
import styled from 'styled-components';
import { LogoIcon } from '../components/svgs';

interface WelcomeScreenMobileProps {}

export const WelcomeScreenMobile: React.FC<WelcomeScreenMobileProps> = () => {
  return (
    <WrapperS>
      <LogoIcon />
      <br />
      <h1>
        Your screen width is too narrow – please try widening your browser or
        zooming out.
      </h1>
      <span>Mobile version is under development</span>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    white-space: normal;
    max-width: 320px;
  }
  span {
    font-size: 16px;
  }
`;
