import React from 'react';

interface DeleteIconProps {}

export const DeleteIcon: React.FC<DeleteIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g fill="#FC5B5B">
          <g>
            <g>
              <path
                d="M25 15c1.105 0 2 .895 2 2v1h3c.552 0 1 .448 1 1s-.448 1-1 1h-1v8c0 1.657-1.343 3-3 3h-6c-1.657 0-3-1.343-3-3v-8h-1c-.552 0-1-.448-1-1s.448-1 1-1h3v-1c0-1.105.895-2 2-2h4zm2 5h-8v8c0 .552.448 1 1 1h6c.552 0 1-.448 1-1v-8zm-6 2c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1s-1-.448-1-1v-3c0-.552.448-1 1-1zm4 0c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1s-1-.448-1-1v-3c0-.552.448-1 1-1zm0-5h-4v1h4v-1z"
                transform="translate(-1036 -340) translate(1020 229) translate(1 96)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
