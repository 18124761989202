import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import { COLORS_RGBA } from '../../../utils/colors';
import {ALL_GROUPS, ALL_PARTICIPANTS} from '../../../utils/consts';
import { delay } from '../../../utils/helpers';
import { ArrowIcon, CheckIcon } from '../../svgs';
import { SelectItemType } from './DefaultSelect';
import {TGroupSelect} from "../../../store/groups/types";
import {HashTableActiveGroup, IsActiveObject} from "../../Analytics/useCharts";

interface ClassGroupSelectProps {
  data: SelectItemType[];
  selected: SelectItemType | null;
  placeHolder?: string;
  icon?: React.FC;
  label?: string;
  isMultiple?: boolean;
  activeOriginSourceSelects?: IsActiveObject[];
  activeGroupSelects?:  HashTableActiveGroup;
  onOriginSourceChange?: (item: SelectItemType) => void;
  onGroupChange?: (item: SelectItemType, parentId: string) => void;
  isOpenHandler?: (isOpen: boolean) => void;
  isOpenGroupCategoryHandler?: (isOpen: boolean) => void;
  openToTop?: boolean;
    onCloseHandler?: () => void;
}

export const OriginSourceGroupSelect: React.FC<ClassGroupSelectProps> = ({
    data,
    selected,
    onOriginSourceChange,
    onGroupChange,
    label,
    placeHolder,
    icon: Icon,
    isOpenHandler,
    isOpenGroupCategoryHandler,
    isMultiple,
    activeOriginSourceSelects = [],
    activeGroupSelects = [],
    onCloseHandler,
    openToTop,
  }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [selectTitle, setSelectTitle] = React.useState('');

  const [currentRawQList, setCurrentRawQList] =
      React.useState<TGroupSelect[]>(null);
  const [currentGroupParentId, setCurrentGroupParentId] =
      React.useState<string>(null);
  const modalRef = React.useRef<HTMLUListElement>(null);
  const questionsRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  const hideList = async () => {
    if (modalRef && modalRef.current) {
      modalRef.current.classList.remove(SelectClasses.in);
      modalRef.current.classList.add(SelectClasses.out);
      await delay(100);
      setCurrentRawQList(null);
      setCurrentGroupParentId(null);
      setIsOpen(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    if (isOpen) {
        isOpenGroupCategoryHandler(false)
      hideList();
        if (onCloseHandler) {
            onCloseHandler();
        }
    }
  });

    React.useEffect(() => {
        if (!Array.isArray(activeOriginSourceSelects)) return null;
        if (activeOriginSourceSelects.length < 3) return null;
        if (!activeGroupSelects) return null;

        if (activeOriginSourceSelects[0].is_active) {
            setSelectTitle(activeOriginSourceSelects[0].title)
        } else if (activeOriginSourceSelects[1].is_active) {
            setSelectTitle(activeOriginSourceSelects[1].title)
        } else {
            let sel: string = ''
            activeOriginSourceSelects.forEach((itm) => {
                if (itm.is_active && activeGroupSelects[itm.id][0].is_active && itm.id !== 'default') {
                    if (sel === '') {
                        sel = itm.title
                    } else {
                        sel = sel + ', ' + itm.title
                    }
                } else if (itm.is_active && !activeGroupSelects[itm.id][0].is_active && itm.id !== 'default') {
                    activeGroupSelects[itm.id].forEach((i) => {
                        if (sel === '') {
                            i.is_active && (sel = i.title)
                        } else {
                            i.is_active && (sel = sel + ', ' + i.title)
                        }
                    })
                } else if (itm.id === 'default') {
                    if (activeGroupSelects[itm.id] &&  Array.isArray(activeGroupSelects[itm.id])) {
                        activeGroupSelects[itm.id].forEach((i, idx) => {
                            if (sel === '') {
                                i.is_active && idx > 0 && (sel = i.title)
                            } else {
                                i.is_active && idx > 0 && (sel = sel + ', ' + i.title)
                            }
                        })
                    }
                }
            })
            setSelectTitle(sel)
        }
    } ,[activeOriginSourceSelects, activeGroupSelects])

  React.useEffect(() => {
    if (currentRawQList) {
      const newQuestionsSelect: SelectItemType[] = [];
      //   newQuestionsSelect.push(ALL_QUESTIONS);
      currentRawQList.forEach((group) => {
        newQuestionsSelect.push({
          value: group.id,
          title: group.name,
          id: group.id,
        });
      });
    }
  }, [currentRawQList]);

  return (
      <WrapperS className="ov-select" ref={ref}>
        {label ? <LabelS>{label}</LabelS> : null}

        <SelectBtnS
            isOpen={isOpen}
            className="ov-select-wr"
            onClick={() => {
              setIsOpen(true);
              if (isOpenHandler) isOpenHandler(true);
            }}
        >
          {Icon && (
              <span className="svgIconSelect">
            <Icon />
          </span>
          )}
          <SelectSpanS active={!!selected}>
            {selected ? (
                isMultiple && activeOriginSourceSelects[0] ? (
                    <span className="multiSpan">
                        {selectTitle}
                {' '}
              </span>
                ) : (
                    selected.title
                )
            ) : (
                placeHolder
            )}
          </SelectSpanS>
          {data[1] && <ArrowIcon />}
        </SelectBtnS>

        {!isOpen ? null : (
            <>
              <ItemsWrapperS
                  top={openToTop}
                  ref={modalRef}
                  className={`${mainClass} ${SelectClasses.in}`}
              >
                {data && data[0] ? (
                    data.map((itm) => {
                       if (itm.id === 'default')
                           return null;
                      if (!isMultiple && selected && selected.id === itm.id )
                          return null;
                      return (
                          <ItemS
                              key={itm.id}
                              selected={
                                  isMultiple &&
                                  !!activeOriginSourceSelects.find((it) => it.id === itm.id && it.is_active)
                              }
                          >
                    <span
                        onClick={() => {
                          if (!isMultiple) {
                            hideList();
                          }

                          isOpenGroupCategoryHandler(false)
                          onOriginSourceChange(itm);
                          setCurrentRawQList(null);
                          setCurrentGroupParentId(null);
                        }}
                    >
                      <span>{itm.title}</span>
                    </span>
                            {isMultiple &&
                                activeOriginSourceSelects.find((it) => it.id === itm.id && it.is_active) &&
                                itm.value !== ALL_GROUPS.value &&
                                itm.value !== ALL_PARTICIPANTS.value &&(
                                    <ArrowWrapperS
                                        onClick={() => {
                                          isOpenGroupCategoryHandler(true)
                                          setCurrentRawQList(itm.groups);
                                          setCurrentGroupParentId(itm.id as string);
                                        }}
                                        isActive={
                                            currentRawQList &&
                                            itm.groups &&
                                            itm.groups === currentRawQList
                                        }
                                    >
                                      <ArrowIcon />
                                    </ArrowWrapperS>
                                )}
                          </ItemS>
                      );
                    })
                ) : (
                    <ItemS noItems>No items</ItemS>
                )}
                  {activeGroupSelects['default'] && activeGroupSelects['default'].map((group, i) => {
                      if (group.id === ALL_GROUPS.id)
                          return null;
                              return (
                                  <QuestionS key={group.id}>
                    <span
                        onClick={() => {
                            onGroupChange(group, 'default');
                        }}
                    >
                      <span>{`${group.title}`}</span>
                        {activeGroupSelects['default'].find(
                            (it) => it.id === group.id && group.is_active
                        ) && (
                            <CheckIcon />
                        )}
                    </span>
                                  </QuestionS>
                              );
                          })}
              </ItemsWrapperS>
              {currentGroupParentId && (
                  <QuestionsWrapperS
                      ref={questionsRef}
                      className={`${mainClass} ${SelectClasses.in}`}
                  >
                {/*    <QuestionS>*/}
                {/*<span*/}
                {/*    onClick={() => {*/}
                {/*        onGroupChange(ALL_GROUPS, currentGroupParentId);*/}
                {/*    }}*/}
                {/*>*/}
                  {/*<span>{ALL_GROUPS.title}</span>*/}
                  {/*{activeGroupSelects[currentGroupParentId] && (*/}
                  {/*    <CheckIcon />*/}
                  {/*)}*/}
                {/*</span>*/}
                {/*    </QuestionS>*/}
                    {activeGroupSelects[currentGroupParentId].map((group, i) => {
                      return (
                          <QuestionS key={group.id}>
                    <span
                        onClick={() => {
                            onGroupChange(group, currentGroupParentId);
                        }}
                    >
                      <span>{`${group.title}`}</span>
                      {activeGroupSelects[currentGroupParentId].find(
                              (it) => it.id === group.id && group.is_active
                          ) && (
                              <CheckIcon />
                          )}
                    </span>
                          </QuestionS>
                      );
                    })}
                  </QuestionsWrapperS>
              )}
            </>
        )}
      </WrapperS>
  );
};

export const WrapperS = styled.div`
  position: relative;
  width: 100%;
`;
export const InnerWrapperS = styled.div`
  display: flex;
  justify-content: center;
`;
export const QuestionsWrapperS = styled.div`
  max-height: 300px;
  width: 300px;
  position: absolute;
  overflow: auto;
  background: white;
  top: calc(100% + 10px);
  left: calc(100% + 10px);
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgb(42 47 42 / 16%);
  margin: 0;
  padding: 0;
  z-index: 2;
`;
export const SelectBtnS = styled.div<{ isOpen?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  border: solid 1px ${COLORS_RGBA.default(0.2)};
  border-radius: 6px;
  height: 48px;
  align-items: center;
  padding: 13px 16px;
  cursor: pointer;

  .svgIconSelect {
    margin-right: 10px;
  }

  &:hover {
    border-color: ${COLORS_RGBA.accent(1)};
  }

  svg {
    transition: 0.2s;
    ${({ isOpen }) =>
    isOpen
        ? `
   transform: translateY(1px) scale(1, -1);
  `
        : ''}
  }
`;

export const SelectSpanS = styled.span<{ active?: boolean }>`
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ active }) => (active ? `  opacity: 1;` : `  opacity: 0.6;`)}

  .multiSpan {
    max-width: 200px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
`;

export const LabelS = styled.div`
  span {
    font-size: 1.4rem;
    display: block;
    color: ${COLORS_RGBA.default(0.8)};
    font-weight: 400;
  }
  margin-bottom: 5px;
`;

export const ItemsWrapperS = styled.ul<{ top?: boolean }>`
  position: absolute;

  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.16);
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  z-index: 2;
  ${({ top }) =>
    top
        ? `
    bottom: 100%;
  `
        : `
    top: 100%;
  `}
`;
export const ArrowWrapperS = styled.div<{ isActive?: boolean }>`
  height: 100%;
  padding: 13px 5px;

  &:hover {
    background: ${COLORS_RGBA.accent(0.13)};
  }

  &:active {
    background: ${COLORS_RGBA.accent(1)};
    svg {
      g {
        path {
          fill: white !important;
        }
      }
    }
  }

  svg {
    transform: rotate(270deg);
    width: 15px !important;
    height: 15px !important;
    min-width: 8px !important;
    min-height: 8px !important;

    g {
      opacity: 1;
      path {
        fill: ${({ isActive }) =>
    isActive ? `white` : `${COLORS_RGBA.accent()}`};
      }
    }
  }
`;
export const ItemS = styled.li<{ noItems?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  & > span {
    flex: 1;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding: 13px 16px;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: ${COLORS_RGBA.accent()};
      width: 25px;
      height: 25px;
      min-width: 18px;
      min-height: 18px;
    }

    & > * {
      &:first-child {
        flex: 1;
      }
    }

    &:hover {
      background: ${COLORS_RGBA.accent(0.13)};
    }

    &:active {
      background: ${COLORS_RGBA.accent(1)};
      color: white;
    }
  }

  ${({ selected }) =>
    selected ? `background: ${COLORS_RGBA.accent(0.3)};` : ``}

  ${({ noItems }) =>
    noItems
        ? `
    padding: 10px;
  `
        : ``}
`;
export const QuestionS = styled.li<{ noItems?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  & > span {
    flex: 1;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding: 13px 16px;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: ${COLORS_RGBA.accent()};
      width: 25px;
      height: 25px;
      min-width: 18px;
      min-height: 18px;
    }

    & > * {
      &:first-child {
        flex: 1;
      }
    }

    &:hover {
      background: ${COLORS_RGBA.accent(0.13)};
    }

    &:active {
      background: ${COLORS_RGBA.accent(1)};
      color: white;
    }
  }

  ${({ selected }) =>
    selected ? `background: ${COLORS_RGBA.accent(0.3)};` : ``}

  ${({ noItems }) =>
    noItems
        ? `
    padding: 10px;
  `
        : ``}
`;
