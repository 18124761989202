import { Reducer } from 'redux';
import ActionTypesUser from '../user/types';
import ActionTypes, { TWorkspacesState } from './types';

export const initialState: TWorkspacesState = {
  data: null,
  loading: true,
  errors: undefined,
  current: null,
};

const reducer: Reducer<TWorkspacesState> = (
  state = initialState,
  action
): TWorkspacesState => {
  switch (action.type) {
    case ActionTypes.DELETE_R:
    case ActionTypes.CREATE_R:
    case ActionTypes.GET_BY_TEAM_R:
    case ActionTypes.EDIT_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
        data: state.data ? [...state.data] : null,
      };

    case ActionTypes.DELETE_S:
    case ActionTypes.GET_BY_TEAM_S:
    case ActionTypes.EDIT_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        data: state.data ? [action.payload, ...state.data] : [action.payload],
        current: action.payload,
      };
    case ActionTypes.SET_WORKSPACE:
      return {
        ...state,
        current: action.payload,
      };

    case ActionTypesUser.LOG_OUT_S:
      return {
        data: null,
        current: null,
        loading: false,
        errors: undefined,
      };

    case ActionTypes.DELETE_E:
    case ActionTypes.CREATE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.GET_BY_TEAM_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { reducer as WorkspacesReducer };
