import React from 'react';
import styled from 'styled-components';
import { PlusIcon } from '../svgs';
import { COLORS } from '../../utils/colors';

interface Props {
  onClick: () => void;
}
export const AddCustomGraph: React.FC<Props> = ({ onClick }) => {
  return (
    <WrapperS onClick={onClick}>
      <PlusIcon />
      <span>Add Custom Graph</span>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;

  span {
    color: rgba(128, 128, 128, 0.42);
    font-size: 20px;
    transition: 0.2s;
  }
  svg {
    width: 30px;
    height: 30px;
    margin-right: 20px;

    path {
      transition: 0.2s;
      fill: rgba(128, 128, 128, 0.42);
    }
  }

  &:hover {
    border-color: ${COLORS.accent};
    span {
      color: ${COLORS.accent};
    }
    svg path {
      fill: ${COLORS.accent};
    }
  }
  &:active {
    border-color: ${COLORS.accentActive};
    span {
      color: ${COLORS.accentActive};
    }
    svg path {
      fill: ${COLORS.accentActive};
    }
  }
`;
