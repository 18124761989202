import React from 'react';
import { useDropzone } from 'react-dropzone';
import { readString } from 'react-papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import {EditGroup, getGroupsS} from '../../store/groups/actions';
import {
  BulkDeleteParticipant
} from '../../store/participants/actions';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { MAX_CSV_FILE_SIZE } from '../../utils/config';
import { countryDial } from '../../utils/countryDial';
import { deepClone } from '../../utils/deepClone';
import { TEXT } from '../../utils/textConst';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import {EExistOrDoesntExistOrMissing, ISendData, TSendGroup} from './types';
import {IMatchingTemplateData} from "../../store/matchingTemplates/types";
import {AlphabetLetters} from "../../utils/alphabet";
import {IMissedParticipantInfo} from "../../store/participants/types";
import {TGroup} from "../../store/groups/types";
import {setInfoModal} from "../../store/modal/actions";

export type HashTable = Record<string, any>;

interface TDefSelectValues extends TSendGroup {
  isGroup: boolean;
}

export interface TSelectedField extends TDefSelectValues {
  name: string;
  label: string;
  title: string;
}

const DEF_SELECT_VALUES: TDefSelectValues = {
  title: '',
  input_value: '',
  single: true,
  multiple: false,
  input_value_delimiter_char: '',
  column_idx: '',
  group_id: '',
  isGroup: false,
};

export const NAMES_SELECT_FIELDS = {
  FIRST_NAME_IDX: 'first_name_col_idx',
  LAST_NAME_IDX : 'last_name_col_idx',
  EMAIL_IDX : 'email_col_idx',
  PHONE_IDX : 'phone_col_idx',
  PHONE_CODE_IDX : 'phone_code_col_idx',
  PAYROLL_ID_IDX : 'payroll_id_col_idx',
  FULL_NAME_IDX : 'full_name_col_idx',
  HIRING_DATE_IDX : 'hiring_date_col_idx',
  ETHNICITY_IDX : 'ethnicity_col_idx',
  GENDER_IDX : 'gender_col_idx',
  WL_NAME_IDX : 'work_location_name_col_idx',
  WL_CITY_IDX : 'work_location_city_col_idx',
  WL_COUNTRY_IDX : 'work_location_country_col_idx',
  WL_STATE_IDX : 'work_location_state_col_idx',
  PAY_GROUP_IDX : 'pay_group_col_idx',
  BIRTH_DATE_IDX : 'birth_date_col_idx',
  JOB_IDX : 'job_col_idx',
  DEPARTMENT_IDX : 'department_col_idx',
  SEGMENT_IDX : 'segment_col_idx',
  HOME_BASE_IDX : 'home_base_col_idx',
}

export const DEFAULT_FIELDS: TSelectedField[] = [
  {
    name: NAMES_SELECT_FIELDS.FIRST_NAME_IDX,
    label: 'First Name',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.LAST_NAME_IDX,
    label: 'Last Name',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.FULL_NAME_IDX,
    label: 'Full Name',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.EMAIL_IDX,
    label: 'Email',
    title: '',
    ...DEF_SELECT_VALUES,
  },

  {
    name: NAMES_SELECT_FIELDS.PHONE_IDX,
    label: 'Phone Number',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.PHONE_CODE_IDX,
    label: 'Country Code',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.PAYROLL_ID_IDX,
    label: 'ID	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.JOB_IDX,
    title: '',
    label: 'Job	',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.HIRING_DATE_IDX,
    label: 'Start Date	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.ETHNICITY_IDX,
    label: 'Ethnicity	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.GENDER_IDX,
    label: 'Gender	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.PAY_GROUP_IDX,
    title: '',
    label: 'Pay Group	',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.BIRTH_DATE_IDX,
    label: 'Date of birth	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.DEPARTMENT_IDX,
    title: '',
    label: 'Department	',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.SEGMENT_IDX,
    title: '',
    label: 'Department Segment	',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.HOME_BASE_IDX,
    title: '',
    label: 'Division	',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.WL_NAME_IDX,
    label: 'Name	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.WL_CITY_IDX,
    label: 'City	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.WL_STATE_IDX,
    label: 'State	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: NAMES_SELECT_FIELDS.WL_COUNTRY_IDX,
    label: 'Country	',
    title: '',
    ...DEF_SELECT_VALUES,
  },
  {
    name: 'group-1',
    label: 'Select Group',
    title: '',
    ...DEF_SELECT_VALUES,
    isGroup: true,
  },
];

export const useDropFile = (closeHandler?: () => void, loadItemHandler?: () => void) => {
  const { Workspaces, MatchingTemplates, Participants, Groups } = useSelector((store: AppStore) => store);
  const [missedParticipants, setMissedParticipants] = React.useState<IMissedParticipantInfo[]>([]);
  const [deleteParticipantsID, setDeleteParticipantsID] = React.useState<string[]>([]);
  const [isFirstRowHeader, setIsFirstRowHeader] = React.useState(MatchingTemplates.current?MatchingTemplates.current.first_row_is_header:true);
  const [isSaveMatchingTemplate, setIsSaveMatchingTemplate] = React.useState(true);
  const [isUseMatchingTemplate, setIsUseMatchingTemplate] = React.useState(!!MatchingTemplates.current);
  const [isFileLoaded, setIsFileLoaded] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [successText, setSuccessText] = React.useState('');
  const [csvColumns, setCsvColumns] = React.useState<string[]>([]);
  const [csvData, setScvData] = React.useState<string[][]>([]);
  const [delimiter, setDelimiter] = React.useState('');
  const [phoneCode, setPhoneCode] = React.useState(
    `${countryDial[234].flag} ${countryDial[234].dial_code}`
  );
  const  [isExistMatchingFields, setIsExistMatchingFields] = React.useState({})
  const [hiringDateType, setHiringDateType] = React.useState('mm/dd/yyyy');
  const [birthDateType, setBirthDateType] = React.useState('mm/dd/yyyy');
  const [selectFields, setSelectFields] = React.useState<TSelectedField[]>([]);
  const [commentChar, setCommentChar] = React.useState('');
  const [phoneType, setphoneType] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [groupsValue, setGroupsValue] = React.useState<HashTable>({})
  const dispatch = useDispatch();
  const [csvParsedStatus, setCsvParsedStatus] = React.useState<
    'success' | 'error' | 'inProcess'
  >('inProcess');
  const onDrop = React.useCallback((acceptedFiles) => {
    setErrorText('');
    const file = acceptedFiles[0] as File | null;

    if (!file) return setErrorText('File is undefined');
    if (file.size > MAX_CSV_FILE_SIZE * 1e6)
      return setErrorText(`File must be less than ${MAX_CSV_FILE_SIZE} Mb.`);
    if (!file.name.includes('.csv'))
      return setErrorText('Incorrect file format');

    const fileReader = new FileReader();

    const handleFileReader = () => {
      const content = fileReader.result;
      const results = readString(content as string, null) as any;
      setDelimiter(results.meta.delimiter);
      if (results.data && results.data[0] && Array.isArray(results.data[0])) {
        setScvData(results.data as string[][]);
        const columns = [...(results.data[0] as string[])];
        setCsvColumns(columns);
        setIsFileLoaded(true);
      } else {
        console.log('No data', results.data);
      }
    };

    fileReader.onloadend = handleFileReader;
    fileReader.readAsText(file);
    console.log('-> file', file);
    setFile(file);
    // Do something with the files
  }, []);

  const deleteHandler = () => {
    setErrorText('');
    setLoading(true)

    if (deleteParticipantsID.length === 0) return setErrorText('You have not selected any participants.');
    let newParticipants = [...Participants.data];
    let newGroups: TGroup[] = [];
    newGroups = deepClone(Groups.data) as TGroup[];
    let ids: string[] = [];

    deleteParticipantsID.forEach((participantID) => {

      Participants.data.forEach((participant, idx) => {
        if (participantID === participant.id) {
          let hasGroups: boolean = false;
          participant.groups.length > 0 ? hasGroups = true : hasGroups = false;
          ids.push(participant.id)
          newParticipants = newParticipants.filter(obj => obj.id !== participant.id);

          if (hasGroups) {
            Array.isArray(newGroups) &&
            newGroups.forEach((group) => {
              group.participants = group.participants.filter(
                  (groupParticipant) => `${groupParticipant.id}` !== `${participant.id}`
              );
            });
          }
        }
      })
    })
    dispatch(
        BulkDeleteParticipant.request({
          id : ids,
          newGroups: newGroups,
          newParticipants: newParticipants,
          callBack: (success) => {
            if (success) {
              closeHandler()
              setLoading(false);
              dispatch(
                  setInfoModal({
                    title: 'Success',
                    mess: 'Participants were successfully deleted',
                    isError: false
                  })
              );
              // dispatch(BulkDeleteParticipant.success(newParticipants));
              loadItemHandler()
              dispatch(EditGroup.success(newGroups));
              // dispatch(EditGroup.success(newGroups));
            } else {
              closeHandler()
              setLoading(false);
              dispatch(
                  setInfoModal({
                    mess: 'Participants not deleted, ' + TEXT.tryLater,
                    isError: true
                  })
              );
              console.error('Participants not deleted');
            }

          },
        })
    );

    // console.log('remove', id, position);
  };

  // const bulkDeleteHandler = React.useCallback(async () => {
  //
  //
  //   setLoading(true);
  //   try {
  //     const data = await callApi({
  //       path: API_ROUTE_PATH.participants.bulkDelete(Workspaces.current.id),
  //       method: 'delete',
  //       data: bodyFormData,
  //     });
  //     setCsvParsedStatus('success');
  //     if (data.groups) {
  //       dispatch(getGroupsS(data.groups));
  //     }
  //     if (data.participants) {
  //       dispatch(GetAllParticipants.success(data.participants));
  //     }
  //
  //     console.log(data)
  //     setSuccessText(data.logs || '');
  //     setMissedParticipants(data.missed_participants || [])
  //
  //     let tmpDeleteParticipantsID: string[] = [];
  //
  //     if (data.missed_participants.length > 0) {
  //       data.missed_participants.forEach((participant) => {
  //         tmpDeleteParticipantsID.push(participant.id)
  //       })
  //     }
  //
  //     setDeleteParticipantsID(tmpDeleteParticipantsID)
  //   } catch (error) {
  //     setCsvParsedStatus('error');
  //     setErrorText(`${error}`);
  //   } finally {
  //     setLoading(false);
  //   }
  //
  // },[deleteParticipantsID])

  const addHandler = React.useCallback(async () => {
    setErrorText('');
    if (!Workspaces.current) return setErrorText('Workspaces does not exist');
    if (!file) return setErrorText('File is empty ' + TEXT.tryLater);

    const sendData:
      | ISendData
      | {
          [key: string]: any;
        } = {
      is_save_template: isSaveMatchingTemplate,
      first_row_is_header: isFirstRowHeader,
      delimiter_char: delimiter,
      comment_char: commentChar,
      email_col_idx: '',
      phone_col_idx: '',
      phone_code_col_idx: '',
      payroll_id_col_idx: '',
      hiring_date_col_idx: '',
      ethnicity_col_idx: '',
      gender_col_idx: '',
      pay_group_col_idx: '',
      job_col_idx: '',
      department_col_idx: '',
      segment_col_idx: '',
      home_base_col_idx: '',
      birth_date_col_idx: '',
      work_location_name_col_idx: '',
      work_location_city_col_idx: '',
      work_location_country_col_idx: '',
      work_location_state_col_idx: '',
      first_name_col_idx: '',
      last_name_col_idx: '',
      full_name_col_idx: '',
      workspace_id: Workspaces.current.id,
      phone_code: phoneCode ? phoneCode.split(' ')[1] : '',
      hiring_date_format: hiringDateType,
      birth_date_format: birthDateType,
      groups: [],
      matching_template_data: {
        first_row_is_header: isFirstRowHeader,
        comment_char: commentChar,
        data_with_header: {
          groups: [],
        } as IMatchingTemplateData,
        hiring_date_format: hiringDateType,
        birth_date_format: birthDateType,
        phone_format: phoneType,
        phone_code: phoneCode ? phoneCode.split(' ')[1] : '',
        workspace_id: Workspaces.current.id,
      },
    };
    let errors = '';
    let oneOfFieldHasVal = false;

    const D_T = 'column is required; ';
    const fullNameFiled = selectFields.find((f) => f.name === NAMES_SELECT_FIELDS.FULL_NAME_IDX);

    selectFields.forEach((field) => {
      if (
        field.name === NAMES_SELECT_FIELDS.FIRST_NAME_IDX &&
        !field.column_idx &&
        !fullNameFiled?.column_idx
      ) {
        errors += 'First name ' + D_T;
      }
      if (field.name === NAMES_SELECT_FIELDS.PHONE_IDX && field.column_idx && phoneType === 0) {
        errors += 'Phone Type ' + D_T;
      }
      if (
        field.name === NAMES_SELECT_FIELDS.PHONE_CODE_IDX &&
        phoneType === 2 &&
        !field.column_idx
      ) {
        errors += 'Country Code ' + D_T;
      }
      if (
        field.name === NAMES_SELECT_FIELDS.LAST_NAME_IDX &&
        !field.column_idx &&
        !fullNameFiled?.column_idx
      ) {
        errors += 'Last name ' + D_T;
      }
      if (field.name === NAMES_SELECT_FIELDS.PAYROLL_ID_IDX && field.column_idx) {
        oneOfFieldHasVal = true;
      }

      if (field.name === NAMES_SELECT_FIELDS.EMAIL_IDX && field.column_idx) {
        oneOfFieldHasVal = true;
      }
      if (field.name === NAMES_SELECT_FIELDS.PHONE_IDX && field.column_idx) {
        oneOfFieldHasVal = true;
      }

      if (errors)  {
        console.error(errors)
        return null;
      }

      if (sendData.hasOwnProperty(field.name)) {
        sendData[field.name as keyof ISendData] = field.column_idx;
      }

      if (isFirstRowHeader) {
        if (field.name === NAMES_SELECT_FIELDS.FIRST_NAME_IDX) {
          sendData.matching_template_data.data_with_header.first_name_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.LAST_NAME_IDX) {
          sendData.matching_template_data.data_with_header.last_name_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.EMAIL_IDX) {
          sendData.matching_template_data.data_with_header.email_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.PHONE_IDX) {
          sendData.matching_template_data.data_with_header.phone_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.PHONE_CODE_IDX) {
          sendData.matching_template_data.data_with_header.phone_code_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.PAYROLL_ID_IDX) {
          sendData.matching_template_data.data_with_header.payroll_id_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.FULL_NAME_IDX) {
          sendData.matching_template_data.data_with_header.full_name_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.HIRING_DATE_IDX) {
          sendData.matching_template_data.data_with_header.hiring_date_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.ETHNICITY_IDX) {
          sendData.matching_template_data.data_with_header.ethnicity_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.GENDER_IDX) {
          sendData.matching_template_data.data_with_header.gender_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.WL_NAME_IDX) {
          sendData.matching_template_data.data_with_header.work_location_name_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.WL_CITY_IDX) {
          sendData.matching_template_data.data_with_header.work_location_city_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.WL_COUNTRY_IDX) {
          sendData.matching_template_data.data_with_header.work_location_country_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.WL_STATE_IDX) {
          sendData.matching_template_data.data_with_header.work_location_state_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.PAY_GROUP_IDX) {
          sendData.matching_template_data.data_with_header.pay_group_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.JOB_IDX) {
          sendData.matching_template_data.data_with_header.job_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.DEPARTMENT_IDX) {
          sendData.matching_template_data.data_with_header.department_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.SEGMENT_IDX) {
          sendData.matching_template_data.data_with_header.segment_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.HOME_BASE_IDX) {
          sendData.matching_template_data.data_with_header.home_base_col = field.title
        }
        if (field.name === NAMES_SELECT_FIELDS.BIRTH_DATE_IDX) {
          sendData.matching_template_data.data_with_header.birth_date_col = field.title
        }
      }

      if (
        field.isGroup &&
        field.input_value &&
        field.group_id &&
        field.column_idx
      ) {
        if (!groupsValue[field.group_id]) {
          sendData.groups.push({
            input_value: field.input_value,
            single: field.single,
            multiple: field.multiple,
            input_value_delimiter_char: field.input_value_delimiter_char,
            column_idx: field.column_idx,
            group_id: field.group_id,
          });

          if (isFirstRowHeader) {
            sendData.matching_template_data.data_with_header.groups.push({
              input_value: field.input_value,
              single: field.single,
              multiple: field.multiple,
              input_value_delimiter_char: field.input_value_delimiter_char,
              column: field.title,
              group_id: field.group_id,
            })
          }
        }

        if (groupsValue[field.group_id] && isFirstRowHeader) {
            sendData.matching_template_data.data_with_header.groups.push({
              input_value: field.input_value,
              single: field.single,
              multiple: field.multiple,
              input_value_delimiter_char: field.input_value_delimiter_char,
              column: field.title,
              group_id: field.group_id,
            })
        }
      }
    });

    if (!oneOfFieldHasVal) {
      errors += 'One of this fields (Email, Phone Number, ID) are required';
    }

    if (errors) return setErrorText(errors);

    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('configuration', JSON.stringify(sendData));
    setLoading(true);
    try {
      const data = await callApi({
        path: API_ROUTE_PATH.participants.cMultiplyCsv(Workspaces.current.id),
        method: 'post',
        data: bodyFormData,
      });
      setCsvParsedStatus('success');
      if (data.groups) {
        dispatch(getGroupsS(data.groups));
      }
      if (data.participants) {
        loadItemHandler()
      }

      setSuccessText(data.logs || '');
      setMissedParticipants(data.missed_participants || [])
      let tmpDeleteParticipantsID: string[] = [];

      if (data.missed_participants?.length > 0) {
        data.missed_participants.forEach((participant) => {
          tmpDeleteParticipantsID.push(participant.id)
        })
      }

    } catch (error) {
      setCsvParsedStatus('error');
      setErrorText(`${error}`);
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [
    selectFields,
    isSaveMatchingTemplate,
    delimiter,
    file,
    commentChar,
    hiringDateType,
    birthDateType,
    Workspaces,
    isFirstRowHeader,
    phoneType,
    phoneCode,
    hiringDateType,
    birthDateType,
    dispatch,
  ]);

  const deleteFieldGroup = (pos: number) => {
    const newArr = deepClone(selectFields);
    newArr.splice(pos, 1);
    setSelectFields(newArr);
  };

  const addNewGroupField = () =>{

    setSelectFields((oldFields) => [
      ...oldFields,
      {
        name: 'group-' + oldFields.length,
        label: 'Select Group',
        title: '',
        ...DEF_SELECT_VALUES,
        isGroup: true,
      },
    ]);
  }


  React.useEffect(() => {
    setCsvParsedStatus('inProcess');
    return () => {
      setCsvParsedStatus('inProcess');
    };
  }, []);

  React.useEffect(() => {
    if (!MatchingTemplates.current?.first_row_is_header) {
      if (MatchingTemplates.current?.first_row_is_header !== isFirstRowHeader) {
        setSelectFields(DEFAULT_FIELDS);
        return;
      }
    }

    if (isUseMatchingTemplate && MatchingTemplates.current ) {
      const matchTemplateFields = deepClone(DEFAULT_FIELDS) as TSelectedField[];

      const templateData = isFirstRowHeader
          ? MatchingTemplates.current.data_with_header
          : MatchingTemplates.current.data_without_header;

      if (templateData.groups?.length > 0) {
        templateData.groups.forEach((k,idx) => {
          if (idx > 0) {
            matchTemplateFields.push({
              name: 'group-' + matchTemplateFields.length,
              label: 'Select Group',
              title: '',
              ...DEF_SELECT_VALUES,
              isGroup: true,
            })
          }
        })
      }

      // const newItems = deepClone(isExistMatchingFields);
      for (const [fieldName, fieldValue] of Object.entries(templateData)) {

        if (fieldName === 'groups' && fieldValue?.length > 0) {
            fieldValue.forEach((item, idx) => {

              if (MatchingTemplates.current.first_row_is_header) {
                csvColumns.forEach((columnName, idxCol) => {
                matchTemplateFields.forEach((selectField) => {
                  if (isFirstRowHeader) {
                    if (item.column === csvColumns[idxCol]) {
                      matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].title = item.column
                    }
                  } else {
                    if (item.column === AlphabetLetters[idxCol]) {
                      matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].title = item.column
                    }
                  }

                  if ((selectField.name === 'group-1' || selectField.name === 'group-'+(DEFAULT_FIELDS.length+idx-1)) && item.column === csvColumns[idxCol]) {
                    if (matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)]?.column_idx !== "") {
                      return;
                    }
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].column_idx = (idxCol).toString()
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].group_id = item.group_id
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].input_value = item.input_value
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].single = item.single
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].multiple = item.multiple
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].input_value_delimiter_char = item.input_value_delimiter_char
                  }
                  })
                })
              } else {
                const key: keyof typeof AlphabetLetters = item.column;
                const value = AlphabetLetters[key];
                matchTemplateFields.forEach((selectField) => {
                  if (selectField.name === 'group-1' || selectField.name === 'group-'+((DEFAULT_FIELDS.length+idx-1))) {
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].column_idx = (value)?.toString()
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].group_id = item.group_id
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].input_value = item.input_value
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].single = item.single
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].multiple = item.multiple
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].input_value_delimiter_char = item.input_value_delimiter_char
                    matchTemplateFields[(DEFAULT_FIELDS.length+idx-1)].title = item.column
                  }
                })
              }
            })
        } else {
          const fieldValueToLowerCase: string = fieldValue?.toLowerCase()
          matchTemplateFields.forEach((item, idx) => {
            if (item.name === fieldName+'_idx') {
              if (MatchingTemplates.current.first_row_is_header) {
                csvColumns.forEach((title, idxCol) => {
                  if (isFirstRowHeader) {
                    if (fieldValueToLowerCase === csvColumns[idxCol].toLowerCase()) {
                      matchTemplateFields[idx].column_idx = (idxCol).toString()
                      matchTemplateFields[idx].title = fieldValue
                    }
                  } else {
                    if (fieldValueToLowerCase === AlphabetLetters[idxCol].toLowerCase()) {
                      matchTemplateFields[idx].column_idx = (idxCol).toString()
                      matchTemplateFields[idx].title = fieldValue
                    }
                  }
                })
              } else {
                const key: keyof typeof AlphabetLetters = fieldValue.toUpperCase();
                const value = AlphabetLetters[key];
                matchTemplateFields[idx].column_idx = (value)?.toString()
                matchTemplateFields[idx].title = fieldValue
              }
            }

          })
        }
      }
      setSelectFields(matchTemplateFields)
    } else {
      setSelectFields(DEFAULT_FIELDS);
    }
    //eslint-disable-next-line
  }, [isFirstRowHeader, isUseMatchingTemplate, csvColumns]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const selectHandler = (
    item: SelectItemType,
    field: TSelectedField,
    position: number
  ) => {
    const newItems = deepClone(selectFields) as TSelectedField[];
    if (!newItems[position])
      return console.error('selectFields does not exist');

    newItems[position] = { ...field, column_idx: item.value, title: item.title };
    setSelectFields(newItems);
  };
  const matchingHandler = (isUseMatchingTemplate: boolean) => {
    setIsFirstRowHeader(MatchingTemplates.current?.first_row_is_header)
    setIsUseMatchingTemplate(isUseMatchingTemplate);
  }

  const firstRowHeaderHandler = (isFirstRowHeader: boolean) => {
    if (MatchingTemplates.current?.first_row_is_header !== isFirstRowHeader) {
      if (!MatchingTemplates.current?.first_row_is_header) {
        setIsUseMatchingTemplate(false);
        setSelectFields(DEFAULT_FIELDS);
      }
    }

    setIsFirstRowHeader(isFirstRowHeader);
  }

  const selectExistFieldHandler = (fieldName: string) => {
    if (isExistMatchingFields[fieldName] === EExistOrDoesntExistOrMissing.doesntExist) {
      const Items = deepClone(isExistMatchingFields);
      const newItems = {};
      for (const [name, fieldValue] of Object.entries(Items)) {
        if (name !== fieldName) {
          newItems[name] = fieldValue
        }
      }
      setIsExistMatchingFields(newItems)
    }
  }

  const selectGroupHandler = (
    item: TSendGroup,
    field: TSelectedField,
    position: number
  ) => {
    const newItems = deepClone(selectFields) as TSelectedField[];
    if (!newItems[position])
      return console.error('selectFields does not exist');
    newItems[position] = { ...field, ...item };
    setSelectFields(newItems);
  };

  const checkOrUncheckAllMissedParticipantsHandler = (isChecked: boolean) => {

    if (isChecked) {
      setDeleteParticipantsID([])
    } else {
      const newDeleteParticipantsID = [];
      missedParticipants.forEach((missedParticipant) => {
        newDeleteParticipantsID.push(missedParticipant.id)
      })
      setDeleteParticipantsID(newDeleteParticipantsID)
    }
  }

  const selectMissedParticipantHandler = (
      isCheck: boolean,
      id: string,
  ) => {

    const newDeleteParticipantsID = deepClone(deleteParticipantsID) as string[];
    const index = newDeleteParticipantsID.indexOf(id);
    if (isCheck && index === -1) {
      newDeleteParticipantsID.push(id)
    } else if (!isCheck && index > -1){
      newDeleteParticipantsID.splice(index, 1);
    }

    setDeleteParticipantsID(newDeleteParticipantsID)
  };

  return {
    csvColumns,
    errorText,
    isFileLoaded,
    getRootProps,
    getInputProps,
    isDragActive,
    selectFields,
    csvData,
    addHandler,
    selectHandler,
    matchingHandler,
    selectGroupHandler,
    isFirstRowHeader,
    setIsFirstRowHeader,
    isSaveMatchingTemplate,
    setIsSaveMatchingTemplate,
    isUseMatchingTemplate,
    setIsUseMatchingTemplate,
    addNewGroupField,
    deleteFieldGroup,
    loading,
    setCommentChar,
    setPhoneCode,
    phoneCode,
    hiringDateType,
    setHiringDateType,
    birthDateType,
    setBirthDateType,
    commentChar,
    setphoneType,
    csvParsedStatus,
    successText,
    MatchingTemplates,
    firstRowHeaderHandler,
    setIsExistMatchingFields,
    isExistMatchingFields,
    selectExistFieldHandler,
    setGroupsValue,
    groupsValue,
    missedParticipants,
    selectMissedParticipantHandler,
    checkOrUncheckAllMissedParticipantsHandler,
    deleteParticipantsID,
    deleteHandler,
  };
};
