import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor, XYCoord } from 'react-dnd';
import styled from 'styled-components';
import { ETypeGraph } from '../../store/analytics/types';
import GraphCropped1 from '../../images/graph-type-1-cropped.png';
import GraphCropped2 from '../../images/graph-type-2-cropped.png';
import GraphCropped3 from '../../images/graph-type-3-cropped.png';
import GraphCropped4 from '../../images/graph-type-4-cropped.png';
import { TSnapshot } from '../../store/dataStory/types';
import { COLORS } from '../../utils/colors';

export interface DragCardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  type: ETypeGraph;
  onClick: (snapshot: TSnapshot) => void;
  snapshot: TSnapshot;
  selectedId: string;
  onDrop: () => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}
const ItemTypes = {
  CARD: 'card',
};
export const DragCard: React.FC<DragCardProps> = ({
  id,
  text,
  index,
  moveCard,
  onDrop,
  type,
  onClick,
  snapshot,
  selectedId,
}) => {
  const ref1 = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref1.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref1.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.top - hoverBoundingRect.bottom) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY =
        (clientOffset as XYCoord).y - hoverBoundingRect.bottom;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      onDrop();
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref1));

  React.useEffect(() => {
    if (selectedId === id) {
      ref1.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [id, selectedId]);
  return (
    <CardS
      ref={ref1}
      style={{ opacity }}
      data-handler-id={handlerId}
      onClick={() => {
        onClick(snapshot);
      }}
      selected={selectedId === id}
    >
      <TextS>{text}</TextS>
      {type === ETypeGraph.type1 && (
        <img src={GraphCropped1} alt="Graphic type 1" />
      )}
      {type === ETypeGraph.type2 && (
        <img src={GraphCropped2} alt="Graphic type 2" />
      )}
      {type === ETypeGraph.type3 && (
        <img src={GraphCropped3} alt="Graphic type 2" />
      )}
      {type === ETypeGraph.type4 && (
          <img src={GraphCropped4} alt="Graphic type 4" />
      )}
      {snapshot.type === 'file' && (
        <img src={snapshot.url.url} alt="File type 1" />
      )}
    </CardS>
  );
};
const CardS = styled.div<{ selected?: boolean }>`
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  border: ${({ selected }) =>
    selected ? `1px solid ${COLORS.accent}` : 'none'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 130px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 8px;
  cursor: move;
  margin: 10px 0px;
  img {
    width: 110px;
    height: 50px;
    align-self: center;
  }
`;
const TextS = styled.p`
  margin: 0;
  padding: 0;
  max-width: 120px;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
`;
