import React from 'react';
import styled from 'styled-components';
import Graph1 from '../../images/graph-type-1.png';
import Graph2 from '../../images/graph-type-2.png';
import Graph3 from '../../images/scatterPlot.png';
import Graph4 from '../../images/graph-type-4.png';
import { TypeCustomChartData } from '../../store/analytics/actions';
import { ETypeGraph } from '../../store/analytics/types';
import { COLORS_RGBA } from '../../utils/colors';
import { DeleteIcon } from '../svgs';
import { DefaultSelect } from '../ui';
import { ButtonS } from '../ui/buttons/ButtonArrow';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { XIcon } from '../svgs/XIcon';
import { YIcon } from '../svgs/YIcon';

interface CustomGraphRowProps {
  selectHandler: (
    id:
      | TypeCustomChartData['settings']['assessment_id']
      | TypeCustomChartData['settings']['assessment_id'][],
    type?: ETypeGraph
  ) => void;
  clickHandler: (type: ETypeGraph) => void;
  selectDataAss: SelectItemType[];
  data: TypeCustomChartData;
  removeHandler: () => void;
  isFirst?: boolean;
}

export const CustomGraphRow: React.FC<CustomGraphRowProps> = ({
  selectHandler,
  clickHandler,
  selectDataAss,
  data,
  removeHandler,
  isFirst,
}) => {
  // const [scatterIds, setScatterIds] = React.useState<{
  //   x: string;
  //   y: string;
  // }>({
  //   x: '',
  //   y: '',
  // });
  const [scatterXId, setScatterXId] = React.useState<string>('');
  const [scatterYId, setScatterYId] = React.useState<string>('');

  // React.useEffect(() => {
  //   const { y, x } = scatterIds;
  //   if (x && y) {
  //     selectHandler([x, y], ETypeGraph.type3);
  //   }
  //   // eslint-disable-next-line
  // }, [scatterIds]);
  // const scatterSelectHandler = (id: string, isX?: boolean) => {
  //   const newScatter = { ...scatterIds };

  //   if (isX) {
  //     setScatterIds({ ...newScatter, x: id });
  //   } else {
  //     setScatterIds({ ...newScatter, y: id });
  //   }
  // };
  // const checkType = (type: ETypeGraph) => {
  //   if (type === ETypeGraph.type3) {
  //     const newSelData = [];
  //     selectDataAss.forEach((itm) => {});
  //   }
  // };
  return (
    <WrapperS>
      <RowS>
        <p>Choose graph type:</p>
        <WrapperGraphsS>
          <GraphS
            isActive={data.type === ETypeGraph.type1}
            onClick={() => clickHandler(ETypeGraph.type1)}
          >
            <img src={Graph1} alt="Graphic type 1" />
          </GraphS>
          <GraphS
            isActive={data.type === ETypeGraph.type2}
            onClick={() => clickHandler(ETypeGraph.type2)}
          >
            <img src={Graph2} alt="Graphic type 2" />
          </GraphS>
          <GraphS
            isActive={data.type === ETypeGraph.type3}
            onClick={() => clickHandler(ETypeGraph.type3)}
          >
            <img src={Graph3} alt="Graphic type 3" />
          </GraphS>
          <GraphS
              isActive={data.type === ETypeGraph.type4}
              onClick={() => clickHandler(ETypeGraph.type4)}
          >
            <img src={Graph4} alt="Graphic type 4" />
          </GraphS>
        </WrapperGraphsS>
      </RowS>

      {!isFirst && (
        <BtnRremoveS onClick={removeHandler}>
          <DeleteIcon />
        </BtnRremoveS>
      )}

      {data.type && data.type !== ETypeGraph.type3 && (
        <div style={{ marginTop: '20px' }}>
          <DefaultSelect
            openToTop
            data={selectDataAss}
            placeHolder="Choose assessments"
            onChange={(ass) => selectHandler(ass.value)}
            selected={
              selectDataAss.find(
                (itm) => itm.id === data.settings.assessment_id
              ) || null
            }
            label="Assessment"
          />
        </div>
      )}

      {data.type === ETypeGraph.type3 && (
        <>
          <WrapperXYS>
            <YIcon />
            <DefaultSelect
              openToTop
              data={selectDataAss}
              placeHolder="Choose assessments"
              onChange={(ass) => {
                setScatterYId(ass.value);
                selectHandler([scatterXId, ass.value], ETypeGraph.type3);
              }}
              selected={
                selectDataAss.find((itm) => itm.id === scatterYId) || null
              }
              label=""
            />
          </WrapperXYS>
          <WrapperXYS>
            <XIcon />
            <DefaultSelect
              openToTop
              data={selectDataAss}
              placeHolder="Choose assessments"
              onChange={(ass) => {
                setScatterXId(ass.value);
                selectHandler([ass.value, scatterYId], ETypeGraph.type3);
              }}
              selected={
                selectDataAss.find((itm) => itm.id === scatterXId) || null
              }
              label=""
            />
          </WrapperXYS>
        </>
      )}
    </WrapperS>
  );
};

const BtnRremoveS = styled(ButtonS)`
  position: absolute;
  right: 10px;
  top: 10px;
  svg path {
    fill: grey;
  }

  &:hover {
    svg path {
      fill: ${COLORS_RGBA.accent()};
    }
  }
`;

const WrapperS = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #eeeeeedc;
  position: relative;
`;

const WrapperGraphsS = styled.div`
  display: flex;
`;

const GraphS = styled.div<{ isActive: boolean }>`
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isActive }) =>
    !isActive ? '#eeee' : COLORS_RGBA.accent()};
  border-radius: 10px;
  padding: 40px;
  margin-top: 0;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    border-color: ${COLORS_RGBA.accent()};
  }

  &:last-child {
    margin-right: 0;
  }
  img {
    max-width: 100%;
  }
`;

const RowS = styled.div`
  margin-top: 20px;
`;

const WrapperXYS = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  & > svg {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
`;
