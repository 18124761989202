import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import {
    Input,
} from '../ui';

interface LinkSurveyRowProps {
    position: number;
    onChange: (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    value: string;
    placeHolder;
}

export const LinkSurveyRow: React.FC<LinkSurveyRowProps> = ({
        position,
        onChange,
        value,
        placeHolder,
    }) => {
    return (
        <WrapperS>
            <PositionS>
                <span>Link: </span>
            </PositionS>
            <BlockS>
                <div style={{ position: 'relative' }}>
                    <Input
                        value={value}
                        name={`question-${position}`}
                        onChange={onChange}
                        placeholder={placeHolder}
                    />
                </div>
            </BlockS>
        </WrapperS>
    );
};

const WrapperS = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

const BlockS = styled.div`
  flex: 1;

  input {
    background-color: #f8f8f8;
    border-color: transparent;
  }
`;

const PositionS = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  display: flex;
  padding-left: 35px;
  padding-right: 35px;

  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.accent};
  color: ${COLORS.accent};
  font-size: 1.6rem;
  margin: 6px 12px 0 0;
`;


