import React from 'react';
import styled from 'styled-components';
import { checkLang } from '../../components/Responding/assLang';
import { RespTitle } from '../../components/Responding/components/RespTitle';
import { RespBtnS } from '../../components/Responding/styles';
import { CollabSelect } from '../../components/ui/selects/CollabSelect';
import { TLang } from '../../types/lang';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { langIsAllingRight } from '../../utils/langIsEng';
import { CSS_MEDIA } from '../../utils/media';
import {
  TCollaborations,
  TRespCollaborators,
  TRespIdentify,
} from '../Responding/types';
import { DividerS, DivWrapperS } from './ScreenCollabChoose';
interface ScreenCollabQuestionProps {
  nextFn: () => void;
  identifyData: TRespIdentify;
  onClick: (curCollab: TRespCollaborators) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  activeCollabs: TCollaborations[];
  lang: TLang;
  collab: TRespCollaborators[];
}
export const ScreenCollabQuestion: React.FC<ScreenCollabQuestionProps> = ({
  nextFn,
  identifyData,
  onClick,
  onChange,
  activeCollabs,
  lang,
  collab,
}) => {
  const { participant } = identifyData;
  return (
    <AnimatedWrapperS className={AnimatedClasses.zoomIn}>
      <RespTitle
        title={`${participant.first_name}${
          checkLang(lang).collaborations.collaborators.managerSelect
        }`}
        alignRight={langIsAllingRight(lang)}
      />

      <WrapperS>
        {collab.map((curСollab) => {
          if (activeCollabs.find((itm) => itm.collaborator_id === curСollab.id))
            return (
              <li key={curСollab.id}>
                <DivWrapperS>
                  <span>
                    {curСollab.first_name} {curСollab.last_name} {curСollab.identifier !== '' ? '('+curСollab.identifier+')' : ''}
                  </span>
                  <CollabSelect
                    handleClick={() => onClick(curСollab)}
                    isCheck={
                      !!activeCollabs.find(
                        (itm) =>
                          itm.collaborator_id === curСollab.id &&
                          itm.collaboration_type === 'manager'
                      )
                    }
                    onChange={onChange}
                  />
                </DivWrapperS>
                <DividerS />
              </li>
            );
          else return null;
        })}
      </WrapperS>
      <RespBtnS
        type={'button'}
        onClick={() => {
          nextFn();
        }}
        alignRight={langIsAllingRight(lang)}
      >
        {checkLang(lang).collaborations.collaborators.continue}
      </RespBtnS>
    </AnimatedWrapperS>
  );
};
const WrapperS = styled.ul`
  background: white;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  max-height: 390px;
  overflow: auto;
  z-index: 1;
  li {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434352;

    ${CSS_MEDIA.maxMd} {
      font-size: 14px;
    }
  }
`;
const AnimatedWrapperS = styled.div`
  ${CSS_MEDIA.maxLg} {
    padding: 150px 0 30px;
  }
`;
