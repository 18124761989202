import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LogoIcon } from '../svgs/LogoIcon';

interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  return (
    <LogoLinkS to="/">
      <LogoIcon />
    </LogoLinkS>
  );
};

const LogoLinkS = styled(Link)``;
