import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { CSS_MEDIA } from '../../utils/media';
import { InfoIcon } from '../svgs';

interface AboutPackageInfoProps {
  name: string;
  date: string;
  text: string;
}

export const AboutPackageInfo: React.FC<AboutPackageInfoProps> = ({
  text,
  name,
  date,
}) => {
  function createMarkup() {
    return {
      __html: text,
    };
  }
  return (
    <WrapperS>
      <InfoIcon />
      {text && (
        <InfoBoxS>
          <TopTextWrapperS>
            <h4>{name}</h4>
            <h4>{date}</h4>
          </TopTextWrapperS>
          <TextS dangerouslySetInnerHTML={createMarkup()}></TextS>
        </InfoBoxS>
      )}
    </WrapperS>
  );
};
const TextS = styled.p`
  font-size: 14px;
  margin: 0;
  ${CSS_MEDIA.maxLg} {
    font-size: 12px;
  }
`;
const TopTextWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0 0 20px 0;
  }
`;
const InfoBoxS = styled.div`
  min-width: 400px;
  padding: 12px 16px;
  display: none;
  z-index: 99999;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 29px;
  right: -25px;
  //right:50%;
  /* transform: translateX(50%); */
  color: #3c3c3c;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.24);
  &::before {
    content: ' ';
    position: absolute;
    right: 19px;
    //right:50%;
    //transform: translateX(50%);
    top: -7px;
    border-bottom: 7px solid white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: none;
    ${CSS_MEDIA.maxLg} {
      transform: none;
      right: 0;
      border-right: none;
    }
  }
  ${CSS_MEDIA.maxLg} {
    min-width: 230px;
    transform: none;
    border-radius: 10px 10px 0 10px;
    right: 50%;
    bottom: 0;
  }
`;
const ElementS = styled.span`
  font-size: 12px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: rgba(151, 151, 151, 0.7);
  color: white;
`;
const WrapperS = styled.div`
  position: relative;
  width: auto;
  cursor: help;
  &:hover {
    svg {
      fill: ${COLORS.accent};
    }
    ${InfoBoxS} {
      display: block;
    }
    ${ElementS} {
      background: ${COLORS.accent};
    }
  }
`;
