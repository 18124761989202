import { TTeam } from '../team/types';

export interface TConsultant {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_picture_url: string;
  team_id: string;
  team: TTeam;
  created_at: Date;
  updated_at: Date;
  workspaces_ids: string[];
}

export interface TConsultantsState {
  readonly loading: boolean;
  readonly data: TConsultant[] | null;
  readonly errors?: string | undefined;
}

export const ACTION_CONST = {
  getByTeam: 'GET_BY_TEAM',
  create: 'CREATE',
  delete: 'DELETE',
  move: 'MOVE',
  edit: 'EDIT',
  setWorkspace: 'SET_WORKSPACE',
};

enum ActionTypes {
  GET_BY_TEAM_R = '@@consultants/GET_BY_TEAM_R',
  GET_BY_TEAM_S = '@@consultants/GET_BY_TEAM_S',
  GET_BY_TEAM_E = '@@consultants/GET_BY_TEAM_E',

  SET_WORKSPACE_R = '@@consultants/SET_WORKSPACE_R',
  SET_WORKSPACE_S = '@@consultants/SET_WORKSPACE_S',
  SET_WORKSPACE_E = '@@consultants/SET_WORKSPACE_E',

  CREATE_R = '@@consultants/CREATE_R',
  CREATE_S = '@@consultants/CREATE_S',
  CREATE_E = '@@consultants/CREATE_E',

  DELETE_R = '@@consultants/DELETE_R',
  DELETE_S = '@@consultants/DELETE_S',
  DELETE_E = '@@consultants/DELETE_E',

  EDIT_R = '@@consultants/EDIT_R',
  EDIT_S = '@@consultants/EDIT_S',
  EDIT_E = '@@consultants/EDIT_E',

  MOVE_R = '@@consultants/MOVE_R',
  MOVE_S = '@@consultants/MOVE_S',
  MOVE_E = '@@consultants/MOVE_E',

  CLEAN_UP = '@@consultants/CLEAN_UP',
}

export default ActionTypes;
