import React from 'react';
import styled from 'styled-components';
import { PngIcon } from '../svgs/PngIcon';
import { DocxIcon } from '../svgs/DocxIcon';
import { PdfIcon } from '../svgs/PdfIcon';
import { XlsxIcon } from '../svgs/XlsxIcon';
import { JpgIcon } from '../svgs/JpgIcon';
import { DocIcon } from '../svgs/DocIcon';
import { XlsIcon } from '../svgs/XlsIcon';
import { CsvMaterialIcon, GifIcon } from '../svgs';
interface FileIconsProps {
  fileName: string;
  extention?: string;
}
export const FileIcons: React.FC<FileIconsProps> = ({
  fileName,
  extention,
}) => {
  const getExtension = (fileName: string, extention: string) => {
    if (extention) return extention;
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };
  const renderBody = () => {
    const currentExtention = getExtension(fileName, extention);
    switch (currentExtention) {
      case 'pdf':
        return <PdfIcon />;
      case 'xlsx':
        return <XlsxIcon />;
      case 'xls':
        return <XlsIcon />;
      case 'docx':
        return <DocxIcon />;
      case 'doc':
        return <DocIcon />;
      case 'png':
        return <PngIcon />;
      case 'jpg':
        return <JpgIcon />;
      case 'jpeg':
        return <JpgIcon />;
      case 'csv':
        return <CsvMaterialIcon />;
      case 'gif':
        return <GifIcon />;
      default:
        return null;
    }
  };
  return <WrapperS>{renderBody()}</WrapperS>;
};
const WrapperS = styled.div``;
