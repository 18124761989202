import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NewTeam } from '../../components';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import {
  Alert,
  ButtonAdd,
  DefaultSelect,
  Input,
  Loader,
} from '../../components/ui';
import { ButtonDefS } from '../../components/ui/buttons/styles';
import { COLORS } from '../../utils/colors';
import { ROUTE_PATH } from '../../utils/routes';
import { useLoadData } from './hooks/useLoadData';
import { useGenCsv } from './hooks/useGenCsv';
import {CSVLink} from "react-csv";

interface AdminHomeProps {}

// interface IConsultant {
//   first_name: string;
//   email: string;
//   last_name: string;
//   id: number;
// }

export const AdminHome: React.FC<AdminHomeProps> = () => {
  const loadData = useLoadData();
    const useGen = useGenCsv();
  const history = useHistory();
  const [isCopied, setIsCopied] = React.useState(false);
  const [isNewTeamVisible, setIsNewTeamVisible] = React.useState(false);


  if (loadData.permissionError) return <Loader isFixed isGreen />;
  return (
    <div style={{ padding: '100px 20px', maxWidth: '900px', margin: 'auto' }}>
      <HeaderWrapperS>
        <h1>Admin account</h1>
      </HeaderWrapperS>
        <ButtonsWrapperS>
            <ButtonWrapper>
                <ButtonDefS
                    onClick={() => history.push(ROUTE_PATH.adminConsultants)}
                    style={{
                        maxWidth: '100px',
                        height: '34px',
                        minHeight: '34px',
                        fontSize: '15px',
                        marginRight: '15px',
                    }}
                >
                    Teams
                </ButtonDefS>
                <ButtonDefS
                    onClick={() => history.push(ROUTE_PATH.adminAreasOfFocus)}
                    style={{
                        maxWidth: '200px',
                        width: '175px',
                        height: '34px',
                        minHeight: '34px',
                        fontSize: '15px',
                        marginRight: '15px',
                    }}
                >
                    Areas Of Focus
                </ButtonDefS>
                <ButtonDefS
                    onClick={() => history.push(ROUTE_PATH.adminNetworkMap)}
                    style={{
                        maxWidth: '300px',
                        width: '175px',
                        height: '34px',
                        minHeight: '34px',
                        fontSize: '15px',
                        marginRight: '15px',
                    }}
                >
                    Network Map CSV
                </ButtonDefS>
                <ButtonDefS
                    onClick={() => history.push(ROUTE_PATH.adminGroupsCsvReport)}
                    style={{
                        maxWidth: '200px',
                        width: '175px',
                        height: '34px',
                        minHeight: '34px',
                        fontSize: '15px',
                        marginRight: '15px',
                    }}
                >
                    Group Report
                </ButtonDefS>
                <ButtonDefS
                    onClick={() => useGen.genCSV()}
                    style={{
                        maxWidth: '200px',
                        width: '175px',
                        height: '34px',
                        minHeight: '34px',
                        fontSize: '15px',
                    }}
                >
                    Benchmark Report
                </ButtonDefS>
                <CSVLink
                    data={useGen.data || ''}
                    headers={useGen.headers || []}
                    ref={useGen.refLinkCvs}
                    style={{ display: 'none' }}
                    filename={
                        'benchmarks.csv'
                    }

                    // enclosingCharacter={`'`}
                >
                    Download me
                </CSVLink>
            </ButtonWrapper>
        </ButtonsWrapperS>
      <WrapperS>
        <ButtonAdd
          title="Add new team"
          onClick={() => setIsNewTeamVisible(true)}
        />
        {!loadData.teams[0] ? null : (
          <>
            <span>or</span>
            <div style={{ maxWidth: '300px', minWidth: '220px' }}>
              <DefaultSelect
                data={loadData.teams}
                onChange={loadData.setCurrentTeam}
                selected={loadData.currentTeam}
                placeHolder="Select team"
                isSearch={true}
              />
            </div>
          </>
        )}
      </WrapperS>

      {loadData.currentTeam?.title ? (
        <h2>Team: {loadData.currentTeam.title}</h2>
      ) : null}
      {loadData.currentTeam?.title && !loadData.createdConsultant ? (
        <>
          <h3>New member</h3>
          <FormWrapperS>
            <Input
              type="email"
              label="Email"
              onChange={loadData.onChangeHandler}
              name={loadData.FORM_DATA_KEYS.email}
              placeholder="Member email"
              value={loadData.form[loadData.FORM_DATA_KEYS.email].value}
              errorText={loadData.form[loadData.FORM_DATA_KEYS.email].errorText}
            />
            <Input
              label="First name"
              onChange={loadData.onChangeHandler}
              placeholder="Member first name"
              name={loadData.FORM_DATA_KEYS.firstName}
              value={loadData.form[loadData.FORM_DATA_KEYS.firstName].value}
              errorText={
                loadData.form[loadData.FORM_DATA_KEYS.firstName].errorText
              }
            />
            <Input
              label="Last name"
              onChange={loadData.onChangeHandler}
              placeholder="Member last name"
              name={loadData.FORM_DATA_KEYS.lastName}
              value={loadData.form[loadData.FORM_DATA_KEYS.lastName].value}
              errorText={
                loadData.form[loadData.FORM_DATA_KEYS.lastName].errorText
              }
            />
            <Input
              label="Password"
              onChange={loadData.onChangeHandler}
              placeholder="Member password"
              name={loadData.FORM_DATA_KEYS.password}
              value={loadData.form[loadData.FORM_DATA_KEYS.password].value}
              errorText={
                loadData.form[loadData.FORM_DATA_KEYS.password].errorText
              }
            />
            <Input
              label="Confirm Password"
              onChange={loadData.onChangeHandler}
              placeholder="Member password"
              name={loadData.FORM_DATA_KEYS.confirmPass}
              value={loadData.form[loadData.FORM_DATA_KEYS.confirmPass].value}
              errorText={
                loadData.form[loadData.FORM_DATA_KEYS.confirmPass].errorText
              }
            />
            <ButtonDefS
              type={'button'}
              onClick={loadData.saveConsultantHandler}
            >
              Add member
            </ButtonDefS>
          </FormWrapperS>
        </>
      ) : null}

      <Alert text={loadData.errors} />

      {loadData.createdConsultant ? (
        <WrapperClipS>
          <h2>New member was successfully created</h2>
          <p>Email: {loadData.createdConsultant.email}</p>
          <p> Password: {loadData.createdConsultant.password}</p>

          {!isCopied ? (
            <BtnsWrapperS>
              {' '}
              <ButtonDefS onClick={() => loadData.setCreatedConsultant(null)}>
                Close
              </ButtonDefS>
              <ButtonDefS
                onClick={() => {
                  setIsCopied(true);
                  navigator.clipboard.writeText(
                    `email: ${loadData.createdConsultant!.email}, password: ${
                      loadData.createdConsultant!.password
                    }`
                  );
                }}
              >
                Copy
              </ButtonDefS>
            </BtnsWrapperS>
          ) : (
            <>
              <p
                style={{
                  color: COLORS.accent,
                  fontWeight: 'bold',
                  height: '32px',
                  marginTop: '20px',
                }}
              >
                Email and password was copied
              </p>
              <ButtonDefS
                onClick={() => {
                  setIsCopied(false);
                  loadData.setCreatedConsultant(null);
                }}
              >
                Close
              </ButtonDefS>
            </>
          )}
        </WrapperClipS>
      ) : null}

      {isNewTeamVisible ? (
        <NewTeam
          closeHandler={() => setIsNewTeamVisible(false)}
          saveHandler={loadData.saveTeamHandler}
        />
      ) : null}
      {loadData.loading ? <Loader isGreen isFixed /> : null}
    </div>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  button {
    height: 48px;
  }
  & > * {
    margin-right: 15px;
  }
`;

const ButtonsWrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  button {
    height: 48px;
  }
  & > * {
    margin-right: 15px;
  }
`;

const FormWrapperS = styled.form`
  /* max-width: 600px; */
  & > * {
    margin-bottom: 20px;
  }
  button {
    max-width: 200px;
  }
`;

const HeaderWrapperS = styled(PageTitleWrapperS)`
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const WrapperClipS = styled.div`
  background: #0f513217;
  padding: 20px;
  max-width: 500px;
  margin-top: 30px;
  p {
    margin: 0;
  }
  h2 {
    margin: 0 0 20px;
    font-size: 20px;
  }
  button {
    width: 100px;
    min-height: 30px;
    height: 30px;
    font-size: 14px;
    margin-top: 20px;
  }
`;

const BtnsWrapperS = styled.div`
  display: flex;
  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
