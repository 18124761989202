import {callApi} from "../../../utils/callApi";
import {API_ROUTE_PATH} from "../../../utils/api_routes";
import React from "react";



export const useGenCsv = () => {
    const [data, setData] = React.useState(null);
    const [headers, setHeaders] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const refLinkCvs = React.useRef<any>(null);
    const genCSV = async () => {
        setIsLoading(true);
        try {
            const resp = await callApi({
                method: 'get',
                path: API_ROUTE_PATH.admins.benchmarkCsvPath
            });
            setHeaders(resp.headers);
            setData(resp.data);

            if (refLinkCvs && refLinkCvs.current) {
                refLinkCvs.current.link.click();
            }

        } catch (e) {
            console.log(e);
            alert(JSON.stringify(e));
        } finally {
            setIsLoading(false);
        }
    };
    return {
        genCSV,
        data,
        headers,
        isLoading,
        refLinkCvs
    }
}