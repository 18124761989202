import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../utils/colors';

const MOD_PADDING = '24px';
export const ModalOuterWrapperS = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

export const ModalInnerWrapperS = styled.div`
  border-radius: 12px;
  background-color: #ffffff;
  position: relative;
  height: auto;
  max-height: 95vh;
  overflow: auto;
  width: 100%;
  max-width: 416px;
  display: flex;
  flex-direction: column;
`;

export const ModalHeaderS = styled.header`
  padding: 26px ${MOD_PADDING};

  input {
    border: 0;
    border-bottom: 1px solid #d8d8d8;
    background: transparent;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    border-radius: 0;
    &::placeholder {
      opacity: 0.8;
    }

    &:hover,
    &:focus {
      border-bottom: 1px solid ${COLORS.accent};
    }
  }
`;

export const WrapperLangTitleS = styled.div`
  display: flex;
`;
export const LangAssWrapperS = styled.div`
  display: flex;
  align-items: center;

  & > * {
    display: flex;
    align-items: center;
    margin-left: 10px;
    img {
      margin-right: 3px;
      width: 15px;
      height: 10px;
    }
  }
`;

export const ModalTileS = styled.h2`
  font-size: 2rem;
  flex: 1;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #171717;
  margin: 0;
`;
export const ModalSubtitleTileS = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: 10px;
`;
export const ModalFooterS = styled.footer`
  padding: 16px ${MOD_PADDING};
  background: rgba(217, 225, 217, 0.24);
`;
export const ModalBodyS = styled.div`
  padding: 0 ${MOD_PADDING} 32px;
  overflow: auto;
  position: relative;
  flex: 1;
  /* max-height: 56vh; */
  hr {
    opacity: 0.12;
  }

  .btn-add {
    margin-top: 20px;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3c3c3c;
  }
`;
export const BtnsWrapperS = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
`;
export const ErrorInfoS = styled.div`
  padding: 10px ${MOD_PADDING} 0;
`;
export const RowWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  & > * {
    width: 48% !important;
  }
`;

export const DescTitleS = styled.p`
  margin: 0;
  color: ${COLORS_RGBA.default(0.5)};
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
`;
export const ModalBodyTextS = styled.p`
  font-size: 1.6rem;
`;

export const RadiosRowS = styled.div`
  margin-top: 26px;
  .ov-radio {
    margin-bottom: 26px;
  }
`;
export const LangWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  label {
    transform: scale(0.8);
  }
  & > span {
    margin-left: 10px;
    font-size: 13px;
    color: grey;
  }
`;
export const SelectWrapperS = styled.div`
  min-width: 165px;
  max-width: 165px;
  div {
    height: 38px;
  }
  li {
    & > span {
      padding: 10px 12px;
    }
  }
`;
export const FlagS = styled.span`
  display: flex;
  img {
    margin-right: 3px;
    width: 15px;
    height: 10px;
  }
  span {
    margin-right: 10px;
    font-size: 14px;
    color: ${COLORS.default};
  }
`;
