import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, {
  TCollab,
  TCollaborationsState,
  TConnectednessScore,
  TParticipantInfo,
} from './types';
import { action } from 'typesafe-actions';

const GetCollabsActions = new Actions('GET_COLLAB', ActionTypes);

interface IData {
  workspace_id: string;
  is_all_assessments: boolean;
  is_all_participants: boolean;
  period_of_answer_date: {
    is_all_time: boolean;
    is_last_month: boolean;
    is_last_quarter: boolean;
    is_last_year: boolean;
    start_date: string;
    end_date: string;
  };
  period_of_hiring_date: {
    from_0_to_30_days: boolean;
    from_31_to_60_days: boolean;
    from_61_to_90_days: boolean;
    from_0_to_90_days: boolean;
    from_91_to_180_days: boolean;
    from_0_to_1_years: boolean;
    from_1_to_3_years: boolean;
    from_3_to_6_years: boolean;
    from_6_years: boolean;
  };
  assessments: {
    assessment_id: string;
    all_questions: boolean;
    questions_ids: string[];
  }[];
  groups_ids: string[];
  questions_ids?:
    | { x_axis_questions: string[]; y_axis_questions: string[] }
    | string[];
}

export interface TGet_COLLAB_R extends TDefRequest {
  data: IData;
}
export const GetCollaborations = {
  request: (payload: TGet_COLLAB_R) => GetCollabsActions.request(payload),
  success: (payload: TCollab[]) => GetCollabsActions.success(payload),
  error: (message: string) => GetCollabsActions.error(message),
  loading: (payload: boolean) => GetCollabsActions.loading(payload)
};

const GetConnectednessScoreActions = new Actions('GET_SCORE', ActionTypes);

export interface TGet_SCORE_R extends TDefRequest {
  data: IData;
}
export const GetConnectednessScore = {
  request: (payload: TGet_SCORE_R) =>
    GetConnectednessScoreActions.request(payload),
  success: (payload: TConnectednessScore) =>
    GetConnectednessScoreActions.success(payload),
  error: (message: string) => GetConnectednessScoreActions.error(message),
};

const GetCollabParticipantInfoActions = new Actions(
  'GET_COLLAB_PARTICIPANT_INFO',
  ActionTypes
);

export interface TGet_GetCollabParticipantInfo_R extends TDefRequest {
  participantId: string;
  body: {
    is_all_assessments: boolean;
    is_all_participants: boolean;
    period_of_answer_date: {
      is_all_time: boolean;
      is_last_month: boolean;
      is_last_quarter: boolean;
      is_last_year: boolean;
      start_date: string;
      end_date: string;
    };
    assessments_ids: string[];
    groups_ids: string[];
  };
}

export const GetCollabParticipantInfo = {
  request: (payload: TGet_GetCollabParticipantInfo_R) =>
    GetCollabParticipantInfoActions.request(payload),
  success: (payload: TParticipantInfo) =>
    GetCollabParticipantInfoActions.success(payload),
  error: (message: string) => GetCollabParticipantInfoActions.error(message),
};

const AddCollabParticipantNoteActions = new Actions(
  'ADD_COLLAB_PARTICIPANT_NOTE',
  ActionTypes
);

export interface TAdd_ParticipantNote_R extends TDefRequest {
  participantId: string;
  body: string;
}

export const AddCollabParticipantNote = {
  request: (payload: TAdd_ParticipantNote_R) =>
    AddCollabParticipantNoteActions.request(payload),
  success: (payload: TParticipantInfo['notes'][0]) =>
    AddCollabParticipantNoteActions.success(payload),
  error: (message: string) => AddCollabParticipantNoteActions.error(message),
};

const DeleteCollabParticipantNoteActions = new Actions(
  'DELETE_COLLAB_PARTICIPANT_NOTE',
  ActionTypes
);

export interface TDelete_ParticipantNote_R extends TDefRequest {
  noteId: string;
  newNotes: TParticipantInfo['notes'];
}

export const DeleteCollabParticipantNote = {
  request: (payload: TDelete_ParticipantNote_R) =>
    DeleteCollabParticipantNoteActions.request(payload),
  success: (payload: TParticipantInfo['notes']) =>
    DeleteCollabParticipantNoteActions.success(payload),
  error: (message: string) => DeleteCollabParticipantNoteActions.error(message),
};

const UpdateCollabParticipantNoteActions = new Actions(
  'UPDATE_COLLAB_PARTICIPANT_NOTE',
  ActionTypes
);

export interface TUpdate_ParticipantNote_R extends TDefRequest {
  noteId: string;
  body: string;
  newNotes: TParticipantInfo['notes'];
}

export const UpdateCollabParticipantNote = {
  request: (payload: TUpdate_ParticipantNote_R) =>
    UpdateCollabParticipantNoteActions.request(payload),
  success: (payload: TParticipantInfo['notes']) =>
    UpdateCollabParticipantNoteActions.success(payload),
  error: (message: string) => UpdateCollabParticipantNoteActions.error(message),
};

export const SetCollabFilters = (payload: TCollaborationsState['filters']) =>
  action(ActionTypes.SET_COLLAB_FILTERS, payload);
