import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { setInfoModal } from '../../store/modal/actions';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { TEXT } from '../../utils/textConst';
import { Alert } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';

export const InfoModal: React.FC = () => {
  const { Modal } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  if (!Modal.data) return null;
  const { mess, title, isError } = Modal.data;

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '416px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>{title || 'Error'}</Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          {!isError ? <Alert type="success" text={mess || TEXT.someError} />:<Alert type="danger" text={mess || TEXT.someError} />}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS
              isCancel
              onClick={() => dispatch(setInfoModal(null))}
            >
              Ok
            </ButtonBorderedS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
