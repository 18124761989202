import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TAssessmentCards } from './types';

const GetAllAssessmentCardsActions = new Actions('GET_ALL_CARDS', ActionTypes);

export interface TypeGetAllAssessmentCardsR extends TDefRequest {
  workspaceId: string;
}

export const GetAllAssessmentCards = {
  request: (payload: TypeGetAllAssessmentCardsR) =>
    GetAllAssessmentCardsActions.request(payload),
  success: (payload: TAssessmentCards[]) =>
    GetAllAssessmentCardsActions.success(payload),
  error: (message: string) => GetAllAssessmentCardsActions.error(message),
};

const DeleteAssessmentCardsActions = new Actions('DELETE_CARDS', ActionTypes);

export interface TypeDeleteAssessmentCardR extends TDefRequest {
  assessmentCardId: string;
}

export const DeleteAssessmentCard = {
  request: (payload: TypeDeleteAssessmentCardR) =>
    DeleteAssessmentCardsActions.request(payload),
  success: (payload: TAssessmentCards[]) =>
    DeleteAssessmentCardsActions.success(payload),
  error: (message: string) => DeleteAssessmentCardsActions.error(message),
};

const CreateAssessmentCardActions = new Actions('CREATE_CARDS', ActionTypes);

export interface TypeCreateAssessmentCardR extends TDefRequest {
  workspaceId: string;
  // assessmentId: string;
  data: {
    assessment_ids: string[];
  };
}

export const CreateAssessmentCards = {
  request: (payload: TypeCreateAssessmentCardR) =>
    CreateAssessmentCardActions.request(payload),
  success: (payload: TAssessmentCards) =>
    CreateAssessmentCardActions.success(payload),
  error: (message: string) => CreateAssessmentCardActions.error(message),
};
