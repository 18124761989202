import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ITeams,
  ModalConsultants,
} from '../../components/modals/ModalConsultants';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { Alert, Loader } from '../../components/ui';
import { ButtonBorderedS } from '../../components/ui/buttons/styles';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { ROUTE_PATH } from '../../utils/routes';
interface ConsultantsProps {
  data: [];
}

export const Consultants: React.FC<ConsultantsProps> = () => {
  const [teams, setTeams] = React.useState<ITeams[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState('');
  const [currentTeam, setCurrentTeam] = React.useState<ITeams | null>(null);

  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      if (teams[0]) return null;
      try {
        const resp = await callApi({
          path: API_ROUTE_PATH.admins.teamsPath,
          method: 'get',
        });
        setTeams(resp);
      } catch (e) {
        setErrors(`${e}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [teams]);

  const renderBody = () => {
    if (loading) return <Loader isFixed isGreen />;
    if (errors) return <Alert text={errors} />;
    if (!teams[0])
      return <h4 style={{ margin: '30px 0' }}>Go Back and create team</h4>;

    return (
      <TableS cellSpacing={0}>
        <thead>
          <tr>
            <th style={{ width: '40px', textAlign: 'center' }}>#</th>
            <th>Name</th>
            <th style={{ width: '250px' }}>Created Date</th>
            <th style={{ width: '200px', textAlign: 'center' }}>
              Number of people
            </th>
          </tr>
        </thead>
        <tbody>
          {teams.map((t, i) => {
            const toDN = (num: number) => (num > 9 ? `${num}` : `0${num}`);
            const date = new Date(`${t.created_at}`);
            const y = date.getFullYear();
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const h = date.getHours();
            const min = date.getMinutes();

            const outputDate = `${y}-${toDN(m)}-${toDN(d)} ${toDN(h)}:${toDN(
              min
            )}`;

            return (
              <tr key={t.id} onClick={() => setCurrentTeam(t)}>
                <td style={{ width: '30px' }}>{i + 1}</td>
                <td>{t.name}</td>
                <td style={{ width: '250px', fontSize: '12px' }}>
                  {outputDate}
                </td>
                <td style={{ width: '200px', textAlign: 'center' }}>
                  {t.consultants.length}
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableS>
    );
  };

  return (
    <>
      <div style={{ padding: '100px 20px', maxWidth: '900px', margin: 'auto' }}>
        <PageTitleWrapperS>
          <h1>Admin / Teams</h1>
        </PageTitleWrapperS>

        {renderBody()}

        {loading ? null : (
          <WrapperBtnS>
            <ButtonBorderedS
              style={{ maxWidth: '150px' }}
              onClick={() => history.push(ROUTE_PATH.admin)}
            >
              Go Back
            </ButtonBorderedS>
          </WrapperBtnS>
        )}
      </div>
      {currentTeam ? (
        <ModalConsultants
          team={currentTeam}
          closeHandler={() => setCurrentTeam(null)}
        />
      ) : null}
    </>
  );
};

const TableS = styled.table`
  width: 100%;
  margin-bottom: 40px;
  th,
  td {
    text-align: left;
    padding: 5px 10px;
  }

  th {
    background: grey;
    color: white;
  }
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px;
    transition: 0.1s;
  }

  tbody tr {
    cursor: pointer;
    &:hover td {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

const WrapperBtnS = styled.div`
  display: flex;
  justify-content: flex-end;
`;
