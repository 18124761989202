import React from 'react';
import { WrapperS } from './styles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { EAxisAnswerType } from '../../../store/analytics/types';

export const Data: React.FC = () => {
  const { Collaborations } = useSelector((store: AppStore) => store);

  if (!Collaborations.participantInfo)
    return (
      <WrapperS>
        <div>No data</div>
      </WrapperS>
    );

  const { data } = Collaborations.participantInfo;

  const renderData = () => {
    if (!data || !data[0]) {
      return <div>No data yet</div>;
    }

    return data.map((d) => (
      <RowS key={d.assessment_id}>
        <div>{d.assessment_name}</div>
        <div>
          Average Score: {d.average_score}/
          {d.answer_type === EAxisAnswerType.five ? '5' : '10'}
        </div>
        <div>Last Score: {d.last_score}</div>
      </RowS>
    ));
  };

  return <WrapperS>{renderData()}</WrapperS>;
};

const RowS = styled.div`
  display: flex;
  margin: 10px 0 0;
  padding: 15px 12px;
  border-radius: 8px;
  background-color: #fbfbfb;
  align-items: center;

  div {
    &:first-child {
      width: 40%;
      padding-right: 5px;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
    }
    &:nth-child(2) {
      width: 30%;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #67a84c;
    }
    &:last-child {
      text-align: right;
      flex: 1;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #000;
    }
  }
`;
