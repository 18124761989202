import styled from 'styled-components';

export const WrapperS = styled.div`
  padding: 20px 0;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 200px;
`;

export const BlocksWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const BlockS = styled.div`
  flex-grow: 0;
  margin: 12px 0 0;
  padding: 12px 14px 12px 14px;
  border-radius: 8px;
  width: 32%;
  background-color: #fbfbfb;
`;

export const BlockTitleS = styled.h4`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 0.8;
  margin: 0;
`;

export const BlockTextS = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  overflow-x: auto;
  font-style: normal;
`;
