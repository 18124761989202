import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import {
  GetConsultantsByTeam,
  SetConsultantWorkspace,
} from '../../../store/consultants/actions';
import { TConsultant } from '../../../store/consultants/types';
import { deepClone } from '../../../utils/deepClone';
import { TEXT } from '../../../utils/textConst';

export const useWorkspaceConsultants = () => {
  const { Workspaces, Consultants, User } = useSelector(
    (store: AppStore) => store
  );
  const [loadingObj, setLoadingObj] = React.useState<{
    [key: string]: boolean;
  }>({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (User.data && User.data.team_id) {
      dispatch(
        GetConsultantsByTeam.request({
          teamId: User.data.team_id,
        })
      );
    } else if (User.data && !User.data.team_id) {
      dispatch(GetConsultantsByTeam.error('User does not have team_id'));
    }
  }, [dispatch, User.data]);

  const checkBoxHandler = ({
    relation,
    consultant_id,
    position,
  }: {
    relation: boolean;
    consultant_id: string;
    position: number;
  }) => {
    const newLoadingObj = { ...loadingObj };
    if (!Workspaces.current || (Workspaces.current && !Workspaces.current.id))
      return dispatch(
        SetConsultantWorkspace.error(
          'Could not find workspace, ' + TEXT.tryLater
        )
      );
    if (
      !Consultants.data ||
      (Consultants.data && !Array.isArray(Consultants.data))
    )
      return dispatch(SetConsultantWorkspace.error(TEXT.someError));
    const allConsultants = deepClone(Consultants.data) as TConsultant[];
    let consultant: TConsultant | undefined = allConsultants[position];

    const errorText = 'Could not find member';

    if (!consultant) return dispatch(SetConsultantWorkspace.error(errorText));

    if (`${consultant_id}` !== `${consultant.id}`)
      consultant = allConsultants.find(
        (cons) => `${cons.id}` === `${consultant_id}`
      );

    if (!consultant) return dispatch(SetConsultantWorkspace.error(errorText));

    const { id: workspace_id } = Workspaces.current;
    if (relation) {
      consultant.workspaces_ids.push(workspace_id);
    } else {
      consultant.workspaces_ids = consultant.workspaces_ids.filter(
        (itm) => `${itm}` !== `${workspace_id}`
      );
    }

    const callBack = (success: boolean) => {
      delete newLoadingObj[consultant_id];
      setLoadingObj(newLoadingObj);
      if (!success) return null;

      return dispatch(SetConsultantWorkspace.success(allConsultants));
    };

    newLoadingObj[consultant_id] = true;
    setLoadingObj(newLoadingObj);
    dispatch(
      SetConsultantWorkspace.request({
        data: {
          relation,
          consultant_id,
          workspace_id,
        },
        callBack,
      })
    );
  };

  return {
    checkBoxHandler,
    Consultants,
    User,
    Workspaces,
    loadingObj,
  };
};
