import React from 'react';
import styled from 'styled-components';
import { TAnalyticsRow } from '../../store/analytics/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { ButtonSave, Input } from '../ui';
import { ButtonBorderedS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
interface EditGraphProps {
  closeHandler: () => void;
  saveHandler: (name: string) => void;
  row: TAnalyticsRow;
}

export const EditGraph: React.FC<EditGraphProps> = ({
  closeHandler,
  row,
  saveHandler,
}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [nameValue, setNameValue] = React.useState(row.name);
  const [textError, setTextError] = React.useState('');
  return (
    <>
      <Styles.ModalOuterWrapperS>
        <Styles.ModalInnerWrapperS
          style={{ maxWidth: '500px' }}
          ref={refInnWrapper}
          className={AnimatedClasses.zoomIn}
        >
          <Styles.ModalHeaderS>
            <Styles.ModalTileS>Edit {row.name} block</Styles.ModalTileS>
          </Styles.ModalHeaderS>
          <Styles.ModalBodyS>
            <InputWrapperS>
              <Input
                onChange={(e) => {
                  setTextError('');
                  if (e.currentTarget.value.length < 3) {
                    setTextError('Block Name may contain minimum 3 characters');
                  }
                  setNameValue(e.currentTarget.value);
                }}
                value={nameValue}
                errorText={textError}
              />
            </InputWrapperS>
          </Styles.ModalBodyS>
          <Styles.ModalFooterS>
            <Styles.BtnsWrapperS>
              <ButtonBorderedS isCancel onClick={closeHandler}>
                Cancel
              </ButtonBorderedS>
              <ButtonSave
                onClick={() => {
                  saveHandler(nameValue);
                  closeHandler();
                }}
                disabled={!!textError}
              />
            </Styles.BtnsWrapperS>
          </Styles.ModalFooterS>
        </Styles.ModalInnerWrapperS>
      </Styles.ModalOuterWrapperS>
    </>
  );
};
const InputWrapperS = styled.div`
  display: flex;
  justify-content: center;
  input {
    width: 300px;
  }
`;
