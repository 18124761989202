import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, {
  EChartType,
  ETypeGraph,
  TAnalyticsRow,
  TChartFilter,
  TChartParams,
} from './types';

const GetActions = new Actions('GET_CHARTS', ActionTypes);

interface IData {
  workspace_id: string;
  page_id: string;
  params: {
    is_all_assessments: boolean;
    is_all_participants: boolean;
    period_of_answer_date: {
      is_all_time: boolean;
      is_last_month: boolean;
      is_last_quarter: boolean;
      is_last_year: boolean;
      start_date: string;
      end_date: string;
    };
    assessments_ids: string[];
    groups_ids: string[];
  };
  opened_visualizations: string[];
}

export interface TypeGetChartsR extends TDefRequest {
  data: IData;
}

export const GetCharts = {
  request: (payload: TypeGetChartsR) => GetActions.request(payload),
  success: (payload: TAnalyticsRow[]) => GetActions.success(payload),
  error: (message: string) => GetActions.error(message),
};

const GetChartsBlockActions = new Actions('GET_CHARTS_BLOCK', ActionTypes);

export interface TypeGetChartsBlockR extends TDefRequest {
  workspace_id: string;
  block_id: string;
  data: TChartFilter;
}

export const GetChartsBlock = {
  request: (payload: TypeGetChartsBlockR) =>
    GetChartsBlockActions.request(payload),
  success: (payload: TAnalyticsRow[]) => GetChartsBlockActions.success(payload),
  error: (message: string) => GetChartsBlockActions.error(message),
};

export const SetChartsFilters = (payload: TChartParams) =>
  action(ActionTypes.SET_CHART_FILTERS, payload);

// export const SetOpenedCharts=(payload: )

const CreateCustomGlobalChartActions = new Actions(
  'CREATE_CUSTOM_GLOBAL_GRAPH',
  ActionTypes
);

export interface TypeCustomChartData {
  type: ETypeGraph;
  settings: {
    assessment_id?: string;
    assessments_ids?: string[];
    x_axis_assessment_id?: string;
    y_axis_assessment_id?: string;
  };
}

export interface TypeCustomScatterChartData {
  type: ETypeGraph.type3;
  settings: {
    assessments_ids: string[];
  };
}

export interface TypeCreateCustomGlobalChartR extends TDefRequest {
  workspaceId: string;
  pageId: string;
  data: {
    name: string;
    charts: TypeCustomChartData[];
    params: TChartFilter;
  };
}

export const CreateCustomGlobalChart = {
  request: (payload: TypeCreateCustomGlobalChartR) =>
    CreateCustomGlobalChartActions.request(payload),
  success: (payload: TAnalyticsRow) =>
    CreateCustomGlobalChartActions.success(payload),
  error: (message: string) => CreateCustomGlobalChartActions.error(message),
};

const CreateCustomChartActions = new Actions(
  'CREATE_CUSTOM_GRAPH',
  ActionTypes
);

interface TSendDataChart extends TypeCustomChartData {
  params: TChartFilter;
}

export interface TypeCreateCustomChartR extends TDefRequest {
  visualisationId: string;
  data: TSendDataChart;
}

export const CreateCustomChart = {
  request: (payload: TypeCreateCustomChartR) =>
    CreateCustomChartActions.request(payload),
  success: (payload: TAnalyticsRow[]) =>
    CreateCustomChartActions.success(payload),
  error: (message: string) => CreateCustomChartActions.error(message),
};

const EditGraphActions = new Actions('EDIT_GRAPH', ActionTypes);

export interface TypeEditGraphR extends TDefRequest {
  id: string;
  name: string;
}

export const EditOneGraph = {
  request: (payload: TypeEditGraphR) => EditGraphActions.request(payload),
  success: (payload: TAnalyticsRow[]) => EditGraphActions.success(payload),
  error: (message: string) => EditGraphActions.error(message),
};

const DeleteGraphActions = new Actions('DELETE_GRAPH', ActionTypes);

export interface TypeDeleteGraphR extends TDefRequest {
  id: string;
}

export const DeleteGraph = {
  request: (payload: TypeDeleteGraphR) => DeleteGraphActions.request(payload),
  success: (payload: TAnalyticsRow[]) => DeleteGraphActions.success(payload),
  error: (message: string) => DeleteGraphActions.error(message),
};

const DeleteChartCurrentActions = new Actions(
  'DELETE_CHART_CURRENT',
  ActionTypes
);

export interface TypeDeleteChartCurrentR extends TDefRequest {
  id: string;
}

export const DeleteChartCurrent = {
  request: (payload: TypeDeleteChartCurrentR) =>
    DeleteChartCurrentActions.request(payload),
  success: (payload: TAnalyticsRow[]) =>
    DeleteChartCurrentActions.success(payload),
  error: (message: string) => DeleteChartCurrentActions.error(message),
};

const GetCurrentChartActions = new Actions('GET_CHART_CURRENT', ActionTypes);

export interface TGetCurrentChart extends TDefRequest {
  data: TChartFilter;
  uri: {
    type: EChartType;
    workspaceId: string;
    chartId: string;
  };
}

export const GetCurrentChart = {
  request: (payload: TGetCurrentChart) =>
    GetCurrentChartActions.request(payload),
  success: (payload: TAnalyticsRow[]) =>
    GetCurrentChartActions.success(payload),
  error: (message: string) => GetCurrentChartActions.error(message),
};

export const updateGraphs = (payload: TAnalyticsRow[]) =>
  action(ActionTypes.UPDATE_GRAPHS, payload);
