import React from 'react';
import styled from 'styled-components';
import { COLORS_RGBA } from '../../../utils/colors';
import { CheckIcon } from '../../svgs';

interface InfoProps {}

export const Info: React.FC<InfoProps> = () => {
  return (
    <WrapperS>
      <IconWrapperS>
        <CheckIcon />
      </IconWrapperS>
      <p>Check the packages you want to schedule for this workspace</p>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: ${COLORS_RGBA.accent(0.2)};
  border-radius: 8px;

  p {
    margin: 0;
    color: #348437;
    font-size: 1.2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
`;

const IconWrapperS = styled.div`
  min-width: 24px;
  min-height: 24px;
  border-radius: 8px;
  margin-right: 8px;
  background: ${COLORS_RGBA.accent(1)};

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: #fff;
    }
  }
`;
