import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TUser } from './types';

function* getMe(action: ReturnType<typeof Actions.getMeR>): Generator {
  try {
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.consultants.gCurrent,
    })) as TUser;

    yield put(Actions.getMeS(data));
  } catch (e) {
    yield put(Actions.getMeE(e));
    localStorage.clear();
  } finally {
    action.payload.callBack && action.payload.callBack();
  }
}
function* forgotPass(action: ReturnType<typeof Actions.forgotPassR>) {
  try {
    // yield call(delay, 5000);
    yield put(Actions.forgotPassS());
    yield call(action.payload.callBack);
  } catch (e) {
    yield put(Actions.forgotPassE(e));
  } finally {
  }
}

function* logOut() {
  try {
    yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.users.signOut,
    });
    localStorage.clear();
    yield put(Actions.logOutS());
  } catch (e) {
    // yield put(Actions.logOutE(e));
  } finally {
  }
}

function* update(action: ReturnType<typeof Actions.UpdateUser.request>) {
  const { data, callBack, src } = action.payload as Actions.TypUpdateUserR;
  let success = true;
  const sendData = {
    first_name: data.first_name,
    last_name: data.last_name,
    is_profile_picture_changed: data.is_profile_picture_changed,
  };
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file || '');
  bodyFormData.append('fields', JSON.stringify(sendData));
  try {
    const user = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.consultants.uMe,
      data: bodyFormData,
    })) as TUser;
    user.profile_picture_url = src;
    yield put(Actions.UpdateUser.success(user));
  } catch (e) {
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.UpdateUser.error(e));
    success = false;
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ME_R, getMe);
  yield takeEvery(ActionTypes.FORGOT_PASS_R, forgotPass);
  yield takeEvery(ActionTypes.UPDATE_R, update);
  yield takeEvery(ActionTypes.LOG_OUT_R, logOut);
}

export default function* userSaga() {
  yield all([fork(watchFetchRequest)]);
}
