import React from 'react';

interface PlusIconProps {}

export const PlusIcon: React.FC<PlusIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g fill="none" fillRule="evenodd">
        <g fill="#4CAF50">
          <g>
            <g>
              <path
                d="M23 11c.552 0 1 .448 1 1v5h5c.552 0 1 .448 1 1s-.448 1-1 1h-5v5c0 .552-.448 1-1 1s-1-.448-1-1v-5h-5c-.552 0-1-.448-1-1s.448-1 1-1h5v-5c0-.552.448-1 1-1z"
                transform="translate(-1206 -99) translate(1080 64) translate(110 24)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
