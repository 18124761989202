import React from 'react';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { ButtonBorderedS } from '../ui/buttons/styles';
import { TDelayedMessage } from '../../store/packages/types';
import styled from 'styled-components';
import { COLORS_RGBA } from '../../utils/colors';
import { LabelS } from '../ui/inputs/inputStyles';

interface Props {
  closeHandler: () => void;
  message: TDelayedMessage;
}

export const ViewSentAssPackage: React.FC<Props> = ({
  closeHandler,
  message,
}) => {
  const refInnWrapper = React.useRef<HTMLDivElement>(null);

  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '750px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <InnerWrapperS>
          <MainWrapperS>
            <MainInnerWrapperS>
              <Styles.ModalTileS>View Message</Styles.ModalTileS>
              <ViewLabelS>Subject:</ViewLabelS>
              <MessageTextS>{message.metadata.email_subject}</MessageTextS>
              <ViewLabelS>Message:</ViewLabelS>
              <MessageTextS
                dangerouslySetInnerHTML={{
                  __html: message.email_message,
                }}
              ></MessageTextS>
              <ViewLabelS>Signature:</ViewLabelS>
              <MessageTextS
                dangerouslySetInnerHTML={{
                  __html: message.email_signature,
                }}
              ></MessageTextS>
            </MainInnerWrapperS>
            <Styles.BtnsWrapperS>
              <ButtonAlternativeWrapperS>
                <ButtonBorderedS
                  isCancel
                  onClick={() => {
                    closeModalHandler();
                  }}
                >
                  Close
                </ButtonBorderedS>
              </ButtonAlternativeWrapperS>
            </Styles.BtnsWrapperS>
          </MainWrapperS>
        </InnerWrapperS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};

const InnerWrapperS = styled.div`
  display: flex;
  position: relative;
  max-height: 100%;
  overflow: auto;
`;

const MainWrapperS = styled.div`
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MainInnerWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 24px 12px;
`;

const ButtonAlternativeWrapperS = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  max-height: 60px;
`;

const MessageTextS = styled.p`
  margin: 0;
  display: block;
  border: 1px solid ${COLORS_RGBA.default(0.2)};
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 18px;
  p {
    margin: 0;
  }
`;

const ViewLabelS = styled(LabelS)`
  margin-top: 15px;
  margin-bottom: 5px;
`;
