import React from 'react';
import { SortIcon } from '../svgs';
import * as Styles from './styles/TableStyles';
import { colNames } from './types/columnNames';
import { TSortType, TSortBy } from './types/sort';

interface TableHeaderProps {
  sortHandler: (sortType: TSortType, sortBy: TSortBy) => void;
  sortedBy: TSortBy;
  sortType: TSortType;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  sortHandler,
  sortedBy,
  sortType: tableSortType,
}) => {
  const sortHandlerType = (sortType: TSortType) => {
    if (sortType === 'name') {
      let innerSort: TSortBy = 'ABC';

      if (sortedBy === 'ABC') {
        innerSort = 'CBA';
      } else if (sortedBy === 'CBA') {
        innerSort = '';
      } else {
        innerSort = 'ABC';
      }

      return sortHandler(sortType, innerSort);
    }
    let innerSort: TSortBy = 'ABC';

    if (sortedBy === 'ABC') {
      innerSort = 'CBA';
    } else if (sortedBy === 'CBA') {
      innerSort = '';
    } else {
      innerSort = 'ABC';
    }

    return sortHandler(sortType, innerSort);
  };
  return (
    <Styles.THeadS>
      <Styles.TrS>
        <Styles.ThS name={colNames[1]}>
          <Styles.ThTitleS>
            <span>Name</span>
            <Styles.SortBtnS
              active={sortedBy !== '' && tableSortType === 'name'}
              onClick={() => sortHandlerType('name')}
            >
              <SortIcon />
            </Styles.SortBtnS>
          </Styles.ThTitleS>
        </Styles.ThS>
        <Styles.ThS name={colNames[2]}>
          <Styles.ThTitleS>
            <span>Сredentials</span>
            <Styles.SortBtnS
              active={sortedBy !== '' && tableSortType === 'credentials'}
              onClick={() => sortHandlerType('credentials')}
            >
              <SortIcon />
            </Styles.SortBtnS>
          </Styles.ThTitleS>
        </Styles.ThS>
        <Styles.ThS name={colNames[6]}>
          <Styles.ThTitleS>
            <span>Start Date</span>
            <Styles.SortBtnS
              active={sortedBy !== '' && tableSortType === 'hiring_date'}
              onClick={() => sortHandlerType('hiring_date')}
            >
              <SortIcon />
            </Styles.SortBtnS>
          </Styles.ThTitleS>
        </Styles.ThS>
        {/* <Styles.ThS name={colNames[7]}>
          <Styles.ThTitleS>
            <span>Ethnicity</span>
            <Styles.SortBtnS
              active={sortedBy !== '' && tableSortType === 'ethnicity'}
              onClick={() => sortHandlerType('ethnicity')}
            >
              <SortIcon />
            </Styles.SortBtnS>
          </Styles.ThTitleS>
        </Styles.ThS> */}
        {/* <Styles.ThS name={colNames[3]}>
          <Styles.ThTitleS>
            <span>Participation</span>
          </Styles.ThTitleS>
        </Styles.ThS>
        <Styles.ThS name={colNames[4]}>
          <Styles.ThTitleS>
            <span>Last Participation</span>
          </Styles.ThTitleS>
        </Styles.ThS> */}
        <Styles.ThS />
      </Styles.TrS>
    </Styles.THeadS>
  );
};
