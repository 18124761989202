import React from 'react';
import styled from 'styled-components';
import { TFinchIntegrations } from '../../store/finchIntegrations/types';
import { COLORS_RGBA } from '../../utils/colors';
import { Loader } from '../ui';

interface IntegrationItemProps {
  integration: TFinchIntegrations;
}
export const IntegrationItem: React.FC<IntegrationItemProps> = ({
  integration,
}) => {
  const ref = React.useRef<HTMLLIElement>(null);
    const renderBody = () => {
        const d_last_loc = new Date(integration.local_sync_completed_at);
        const d_last_glob = new Date(integration.sync_completed_at);
        const d_next = new Date(integration.sync_scheduled_at);
        const ye_last_loc = new Intl.DateTimeFormat('en', {
            year: 'numeric',
        }).format(d_last_loc);
        const ye_last_glob = new Intl.DateTimeFormat('en', {
            year: 'numeric',
        }).format(d_last_glob);
        const ye_next = new Intl.DateTimeFormat('en', {
            year: 'numeric',
        }).format(d_next);
        const mo_last_loc = new Intl.DateTimeFormat('en', {
            month: '2-digit',
        }).format(d_last_loc);
        const mo_last_glob = new Intl.DateTimeFormat('en', {
            month: '2-digit',
        }).format(d_last_glob);
        const mo_next = new Intl.DateTimeFormat('en', {
            month: '2-digit',
        }).format(d_next);
        const da_last_loc = new Intl.DateTimeFormat('ru', {
            day: 'numeric',
        }).format(d_last_loc);
        const da_last_glob = new Intl.DateTimeFormat('ru', {
            day: 'numeric',
        }).format(d_last_glob);
        const da_next = new Intl.DateTimeFormat('ru', {
            day: 'numeric',
        }).format(d_next);
        const m_last_loc = d_last_loc.getMinutes();
        const m_last_glob = d_last_glob.getMinutes();
        const m_next = d_next.getMinutes();
        const h_last_loc = d_last_loc.getHours();
        const h_last_glob = d_last_glob.getHours();
        const h_next = d_next.getHours();
        return (
            <ModelWrapperS key={integration.id}>
                <ModelInnerWrapperS
                    isBlured={
                        integration.local_sync_completed_at === '0001-01-01T00:00:00Z' ||
                        integration.local_sync_status === 'in_progress' ||
                        d_next <= new Date()
                    }
                >
                    <p>
                        <b>OrgVitals synced status:{' '}</b>
                        { integration.local_sync_status }
                    </p>
                    <p>
                        <b>Last OrgVitals synced:{' '}</b>
                        {integration.local_sync_completed_at !== '0001-01-01T00:00:00Z'
                            ? `${`${da_last_loc}.${mo_last_loc}.${ye_last_loc}`}, ${
                                `${h_last_loc}`.length > 1 ? h_last_loc : '0' + h_last_loc
                            }:${`${m_last_loc}`.length > 1 ? m_last_loc : '0' + m_last_loc}`
                            : '---'}
                    </p>
                    {(integration.local_sync_completed_at === '0001-01-01T00:00:00Z' ||
                        integration.local_sync_status === 'in_progress' ||
                        d_next <= new Date()) && (
                        <LoaderWrapperS>
                            <span>Syncing</span>
                            <Loader isGreen size={0.75} />
                        </LoaderWrapperS>
                    )}
                </ModelInnerWrapperS>
                <ModelInnerWrapperS
                    isBlured={
                        integration.sync_completed_at === '0001-01-01T00:00:00Z' ||
                        integration.sync_status === 'in_progress'||
                        d_next <= new Date()
                    }
                >
                    <p>
                        <b>Finch synced status:{' '}</b>
                        { integration.sync_status }
                    </p>
                    <p>
                        <b>Last Finch synced:{' '}</b>
                        {integration.sync_completed_at !== '0001-01-01T00:00:00Z'
                            ? `${`${da_last_glob}.${mo_last_glob}.${ye_last_glob}`}, ${
                                `${h_last_glob}`.length > 1 ? h_last_glob : '0' + h_last_glob
                            }:${`${m_last_glob}`.length > 1 ? m_last_glob : '0' + m_last_glob}`
                            : '---'}
                    </p>
                    <p>
                        <b>Next Finch sync start at:{' '}</b>
                        {integration.sync_scheduled_at !== '0001-01-01T00:00:00Z'
                            ? `${`${da_next}.${mo_next}.${ye_next}`}, after ${
                                `${h_next}`.length > 1 ? h_next : '0' + h_next
                            }:${`${m_next}`.length > 1 ? m_next : '0' + m_next}`
                            : '---'}
                    </p>
                    {(integration.sync_completed_at === '0001-01-01T00:00:00Z' ||
                        integration.sync_status === 'in_progress' ||
                        d_next <= new Date()) && (
                        <LoaderWrapperS>
                            <span>Syncing</span>
                            <Loader isGreen size={0.75} />
                        </LoaderWrapperS>
                    )}
                </ModelInnerWrapperS>
            </ModelWrapperS>
        )};
  return (
    <WrapperS ref={ref}>
        <LogoWrapperS><img src={`${integration.logo_url}`} alt={'logo'}></img></LogoWrapperS>

      {/*<p style={{ color: `${integration.color}` }}>{integration.name}</p>*/}
      <InfoS>
        <InfoWrapperS>
            {renderBody()}
        </InfoWrapperS>
      </InfoS>
    </WrapperS>
  );
};
const WrapperS = styled.li`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px 24px;
  border: 1px solid ${COLORS_RGBA.default(0.12)};
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  img {
    height: 50px;
    width: 50px;
  }
  p {
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
  }
`;

const LogoWrapperS = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border: 1px solid ${COLORS_RGBA.default(0.12)};
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  img {
    height: 50px;
    width: 50px;
  }
  p {
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
  }
`;

const InfoS = styled.div`
  display: block;
  position: relative;
  min-width: 170px;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  background: white;
  width: 100%;
  // &:after {
  //   content: '';
  //   display: block;
  //   width: 0;
  //   height: 0;
  //   position: absolute;
  //   bottom: 75%;
  //   left: -5px;
  //   transform: translateY(50%);
  //   border-bottom: solid transparent 5px;
  //   border-top: solid transparent 5px;
  //   border-right: solid #fff 5px;
  // }
`;
const InfoWrapperS = styled.div``;
const ModelWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  text-align: left;
  gap: 15px;
  h3 {
    margin: 0;
  }
  p {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
`;
const ModelInnerWrapperS = styled.div<{ isBlured: boolean }>`
  position: relative;
  p {
    ${({ isBlured }) => (isBlured ? 'filter: blur(4px);' : null)};
  }
`;
const LoaderWrapperS = styled.div`
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
  width: 62%;
  display: flex;
  span {
    font-size: 16px;
  }
`;
