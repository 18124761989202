import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';

interface MenuProps {}

export const Menu: React.FC<MenuProps> = () => {
  return (
    <WrapperS>
      {/* <Link to="/">Get Started</Link>
      <Link to="/">Find Resources</Link>
      <Link to="/">Community</Link> */}
    </WrapperS>
  );
};

const WrapperS = styled.nav`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 30px;
    color: #111111;
    font-weight: 500;
  }
  a {
    font-size: 1.6rem;
    &:hover {
      color: ${COLORS.accentHover};
    }
    &:active {
      color: ${COLORS.accentActive};
    }
  }
`;
