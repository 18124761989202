import { TCatAssessment } from '../assessments/types';

export interface TCat {
  id: string;
  name: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  team_id: string;
  assessments: TCatAssessment[];
}

export interface TCatState {
  readonly loading: boolean;
  readonly data: TCat[] | null;
  readonly errors?: string | undefined;
}

enum ActionTypes {
  GET_ALL_R = '@@cats/GET_ALL_R',
  GET_ALL_S = '@@cats/GET_ALL_S',
  GET_ALL_E = '@@cats/GET_ALL_E',

  CREATE_R = '@@cats/CREATE_R',
  CREATE_S = '@@cats/CREATE_S',
  CREATE_E = '@@cats/CREATE_E',

  DELETE_R = '@@cats/DELETE_R',
  DELETE_S = '@@cats/DELETE_S',
  DELETE_E = '@@cats/DELETE_E',

  EDIT_R = '@@cats/EDIT_R',
  EDIT_S = '@@cats/EDIT_S',
  EDIT_E = '@@cats/EDIT_E',

  CLEAN_UP = '@@cats/CLEAN_UP',
}

export default ActionTypes;
