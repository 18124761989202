import React from 'react';
import styled from 'styled-components';
import {
  EChartType,
  ETypeGraph,
  TChart,
  TChartFilter,
  TStackedData,
} from '../../../store/analytics/types';
import { ChartHeader } from '../ChartHeader';
import { NoData } from '../NoData';
import { ChartWrapperS } from '../styles';
import { BlockChartFilter } from './BlockChartFilter';
import { CustomLines } from './CustomLines';
import { useChartRuns } from './hooks/useChartRuns';
import { genChartId } from '../../../utils/genChartId';
import { LinkS } from '../ParticipationRate';
import { useShowPassHandler } from './hooks/useShowPassHandler';
import { COLORS } from '../../../utils/colors';
import { useDescription } from './hooks/useDescription';

interface CustomChartProps {
  data: TChart;
  index: number;
  snapshotId: string | null;
  chartIndex: number;
  isStored: boolean;
  isPreview?: boolean;
  dataStoryChartSettings: TChartFilter | null;
  refHeader: React.MutableRefObject<HTMLDivElement>;
}

export const StackedHorizontalChart: React.FC<CustomChartProps> = ({
  data: chart,
  index,
  chartIndex,
  isStored,
  isPreview,
  dataStoryChartSettings,
  snapshotId,
  refHeader,
}) => {
  const { activeRuns, updateActiveRuns } = useChartRuns();
  const [isRuns, setIsRuns] = React.useState(false);
  const { showPassHandler } = useShowPassHandler(chartIndex, index);
  const hookDesc = useDescription();
  const desc = hookDesc.getAssDescription(chart.assessments_ids || []);
  const version = hookDesc.getAssVersion(chart.assessments_ids || []);
  const [activeQuestionItems, setActiveQuestionItems] = React.useState<
    | {
        x_axis_questions: string[];
        y_axis_questions: string[];
      }
    | string[]
  >();
  const renderBody = () => {
    if (!chart.data || !chart.data[0]) return <NoData />;

    return (
      <div>
        <BlockChartFilter
          isStackedChart
          disabledLabels={
            isStored ? dataStoryChartSettings.activeRuns : activeRuns
          }
          legendHandler={updateActiveRuns}
          setDefaultLabels={() => null}
          chartIndex={chartIndex}
          index={index}
          isRuns={isStored ? true : isRuns}
          setIsRuns={setIsRuns}
          tenureDisabled
          graphType={chart.type}
          questions={chart.select_group?.questions.options}
          activeQuestionItems={activeQuestionItems}
          setActiveQuestionItems={setActiveQuestionItems}
        />

        <div style={{ padding: '20px' }}>
          <div style={{ position: 'relative' }}>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '-8px 0 0',
                fontSize: '13px',
                color: '#5d5d5d',
              }}
            >
              {isRuns || (isStored && chart.view === EChartType.cycles)
                ? 'Cycles Separated: All Selected Groups'
                : 'Cycles Combined: All Selected Groups, Total Averages Per Participant'}
            </p>
            {isRuns && (
              <ShowPassesLinkS onClick={showPassHandler}>
                Show passes
              </ShowPassesLinkS>
            )}
          </div>

          {(chart.data as TStackedData[]).map((row, i) => {
            if (!row) return null;
            if (row.period_of_hiring_date) {
              return row.data.map((data, i) => {
                return (
                  <div key={data.question_id + data.value1 + i}>
                    <h6>{data.question_body}</h6>
                    {chart.view === EChartType.cycles &&
                      data.cycles.map((c) => {
                        if (
                          (isStored
                            ? dataStoryChartSettings.activeRuns
                            : activeRuns)[0] &&
                          !(
                            isStored
                              ? dataStoryChartSettings.activeRuns
                              : activeRuns
                          ).includes(
                            genChartId(
                              c.package_name,
                              c.package_id,
                              c.cycle_date,
                              '',
                              c.link
                            )
                          )
                        )
                          return null;

                        return (
                          <div
                            key={
                              c.package_id +
                              data.question_id +
                              c.cycle_date +
                              c.link
                            }
                          >
                            <p
                              style={{
                                color: 'grey',
                                margin: '10px 0 5px 0',
                                fontSize: '12px',
                              }}
                            >
                              Cycle - {c.package_name} ({c.cycle_date}){' '}
                              {typeof c.passes === 'number'
                                ? `( ${c.passes} - pass${
                                    c.passes === 1 ? '' : 'es'
                                  } )`
                                : ''}
                            </p>
                            <CustomLines
                              value1={c.value1}
                              value2={c.value2}
                              value3={c.value3}
                              value4={c.value4}
                              value5={c.value5}
                              answer_type={data.question_answer_types}
                            />
                          </div>
                        );
                      })}
                    {chart.view !== EChartType.cycles && (
                      <CustomLines
                        value1={data.value1}
                        value2={data.value2}
                        value3={data.value3}
                        value4={data.value4}
                        value5={data.value5}
                        answer_type={data.question_answer_types}
                      />
                    )}
                  </div>
                );
              });
            } else {
              return (
                <div key={row.question_id + row.value1 + i}>
                  <h6>{row.question_body}</h6>
                  {chart.view === EChartType.cycles &&
                    row.cycles.map((c) => {
                      if (
                        (isStored
                          ? dataStoryChartSettings.activeRuns
                          : activeRuns)[0] &&
                        !(
                          isStored
                            ? dataStoryChartSettings.activeRuns
                            : activeRuns
                        ).includes(
                          genChartId(
                            c.package_name,
                            c.package_id,
                            c.cycle_date,
                            '',
                            c.link
                          )
                        )
                      )
                        return null;

                      return (
                        <div
                          key={
                            c.package_id +
                            row.question_id +
                            c.cycle_date +
                            c.link
                          }
                        >
                          <p
                            style={{
                              color: 'grey',
                              margin: '10px 0 5px 0',
                              fontSize: '12px',
                            }}
                          >
                            Cycle - {c.package_name} ({c.cycle_date}){' '}
                            {typeof c.passes === 'number'
                              ? `( ${c.passes} - pass${
                                  c.passes === 1 ? '' : 'es'
                                } )`
                              : ''}
                          </p>
                          <CustomLines
                            value1={c.value1}
                            value2={c.value2}
                            value3={c.value3}
                            value4={c.value4}
                            value5={c.value5}
                            answer_type={row.question_answer_types}
                          />
                        </div>
                      );
                    })}
                  {chart.view !== EChartType.cycles && (
                    <CustomLines
                      value1={row.value1}
                      value2={row.value2}
                      value3={row.value3}
                      value4={row.value4}
                      value5={row.value5}
                      answer_type={row.question_answer_types}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <WrapperCustomChartS>
      <ChartHeader
        snapshotId={snapshotId}
        chartMetaData={chart.meta_data}
        chartView={chart.view}
        description={desc}
        version={version}
        title={chart.name}
        isCycles={isRuns}
        activeRuns={activeRuns}
        graphType={ETypeGraph.type2}
        id={chart.id}
        chartType={chart.origin}
        chartData={chart.data}
        chartSaved={chart.is_saved}
        chartPosition={chart.position}
        isStored={isStored}
        dataStoryChartSettings={dataStoryChartSettings}
        isPreview={isPreview}
        reference={refHeader}
        selectedСhartQuestions={activeQuestionItems}
        allChartQuestions={chart.select_group?.questions.options}
      />

      <ChartWrapperS
        style={{
          maxHeight: `${!isPreview ? '500px' : '380px'}`,
          height: `${!isPreview ? '500px' : ''}`,
        }}
      >
        {renderBody()}
      </ChartWrapperS>
    </WrapperCustomChartS>
  );
};

const WrapperCustomChartS = styled.div`
  h6 {
    margin: 20px 0 5px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const ShowPassesLinkS = styled(LinkS)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  &:hover {
    color: ${COLORS.accentHover};
  }
`;
