import React from 'react';

interface FolderIconProps {}

export const FolderIcon: React.FC<FolderIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g fill="#4CAF50">
          <g>
            <g>
              <path
                d="M18.35 10c.41 0 .794.193 1.04.52L20.5 12H25c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H15c-1.657 0-3-1.343-3-3V13c0-1.657 1.343-3 3-3h3.35zM18 12h-3c-.552 0-1 .448-1 1v10c0 .552.448 1 1 1h10c.552 0 1-.448 1-1v-8c0-.552-.448-1-1-1h-4.85c-.41 0-.794-.193-1.04-.52L18 12zm2 4c.552 0 1 .448 1 1v1h1c.552 0 1 .448 1 1s-.448 1-1 1h-1v1c0 .552-.448 1-1 1s-1-.448-1-1v-1h-1c-.552 0-1-.448-1-1s.448-1 1-1h1v-1c0-.552.448-1 1-1z"
                transform="translate(-1192 -98) translate(1080 64) translate(100 24)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
