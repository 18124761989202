import styled from 'styled-components';
import { COLORS_RGBA } from '../../utils/colors';

export const WrapperS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;
`;

export const HeaderS = styled.div`
  display: flex;
  align-items: center;
  background: ${COLORS_RGBA.accent(0.1)};
  padding: 10px 16px;
  border-radius: 8px;
  .ov-select {
    max-width: 200px;
  }
  .ov-select-wr {
    height: 30px;
    span {
      font-size: 12px;
    }
  }
  .btn-more {
    margin-right: 10px;
    padding: 2px 13px;
  }
`;

export const BodyWrapperS = styled.div<{ active?: boolean }>``;
export const HeaderTitleS = styled.div`
  margin: 0;
  flex: 1;

  h3 {
    margin: 0;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3c3c3c;
  }
`;

export const BodyS = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px -10px;
`;

export const GraphsS = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    /* flex: 50%; */
    min-width: 450px;
    width: calc(50% - 20px);
    margin: 10px;
    border-radius: 10px;
    border: solid 1px rgba(42, 47, 42, 0.12);
    min-height: 350px;
    /* max-height: 600px; */
    /* overflow: auto; */
  }
  & > h3 {
    margin: 10px 25px;
    display: block;
    /* border: 0; */
  }
`;
