import { NewParticipant } from '../../../store/participants/actions';
import { countryDial } from '../../../utils/countryDial';

export const IParticipantsInputs: NewParticipant = {
  first_name: 'first_name' as const,
  last_name: 'last_name' as const,
  email: 'email' as const,
  phone: 'phone' as const,
  phone_code: 'phone_code' as const,
  payroll_id: 'payroll_id' as const,
  hiring_date: 'hiring_date' as const,
  ethnicity: 'ethnicity' as const,
  gender: 'gender' as const,
  workspace_id: 'workspace_id' as const,
  work_location_name: 'work_location_name' as const,
  work_location_city: 'work_location_city' as const,
  work_location_state: 'work_location_state' as const,
  work_location_country: 'work_location_country' as const,
  pay_group: 'pay_group' as const,
  job: 'job' as const,
  department: 'department' as const,
  segment: 'segment' as const,
  home_base: 'home_base' as const,
  birth_date: 'birth_date' as const,
};

export const defFields = {
  [IParticipantsInputs.first_name as string]: '',
  [IParticipantsInputs.last_name as string]: '',
  [IParticipantsInputs.email as string]: '',
  [IParticipantsInputs.phone as string]: '',
  [IParticipantsInputs.phone_code as string]: `${countryDial[234].flag} ${countryDial[234].dial_code}`,
  [IParticipantsInputs.payroll_id as string]: '',
  [IParticipantsInputs.hiring_date as string]: null,
  [IParticipantsInputs.ethnicity as string]: '',
  [IParticipantsInputs.gender as string]: '',
  [IParticipantsInputs.workspace_id as string]: '',
  [IParticipantsInputs.work_location_name as string]: '',
  [IParticipantsInputs.work_location_city as string]: '',
  [IParticipantsInputs.work_location_state as string]: '',
  [IParticipantsInputs.work_location_country as string]: '',
  [IParticipantsInputs.pay_group as string]: '',
  [IParticipantsInputs.job as string]: '',
  [IParticipantsInputs.department as string]: '',
  [IParticipantsInputs.segment as string]: '',
  [IParticipantsInputs.home_base as string]: '',
  [IParticipantsInputs.birth_date as string]: '',
};
