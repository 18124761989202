import React from 'react';
import styled from 'styled-components';
import { AlphabetLetters } from '../../utils/alphabet';
import { ArrowRightIcon } from '../svgs';
import { DefaultSelect, Input } from '../ui';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { DateTypeSelectProps } from './types';
export const DateTypeSelect: React.FC<DateTypeSelectProps> = React.memo(
  ({
       selectHandler,
       label,
       name,
       columns,
       isFirstRowHeader,
       isUseMatchingTemplate,
       selectedItems,
       isExistField,
       selectExistFieldHandler,
       setDateType }) => {
    const selectDateType: SelectItemType[] = [
      { title: 'm/d/yyyy', value: 'm/d/yyyy', id: 1 },
      { title: 'mm/dd/yyyy', value: 'mm/dd/yyyy', id: 2 },
      { title: 'd/m/yyyy', value: 'd/m/yyyy', id: 3 },
      { title: 'd.m.yyyy', value: 'd.m.yyyy', id: 4 },
      { title: 'dd/mm/yyyy', value: 'dd/mm/yyyy', id: 5 },
      { title: 'dd.mm.yyyy', value: 'dd.mm.yyyy', id: 6 },
      { title: 'dd-Mon-yyyy', value: 'dd-Mon-yyyy', id: 7 },
      { title: 'yyyy/mm/dd', value: 'yyyy/mm/dd', id: 8 },
      { title: 'yyyy-mm-dd', value: 'yyyy-mm-dd', id: 9 },
    ];
    const [svgColumns, setSvgColumns] = React.useState<SelectItemType[]>([]);
    const [selectedSvgColumn, setSelectedSvgColumn] =
      React.useState<SelectItemType | null>(null);
    const [selectedDateType, setSelectedDateType] =
      React.useState<SelectItemType | null>(selectDateType[1]);

    React.useEffect(() => {
      const newColumns: SelectItemType[] = [
        { id: -1, value: '', title: 'Empty' },
      ];

      selectedItems ? setSelectedSvgColumn(selectedItems[name] as SelectItemType) : setSelectedSvgColumn(null);
      columns.forEach((c, i) => {
        newColumns.push({
          id: i,
          value: `${i}`,
          title: isFirstRowHeader ? c : AlphabetLetters[i],
        });
      });
      setSvgColumns(newColumns);
        //eslint-disable-next-line
    }, [columns, isFirstRowHeader, selectedItems]);

      React.useEffect(() => {
          if (isUseMatchingTemplate && selectedItems) {
              let format = name === 'hiring_date_format' ? selectedItems['hiring_date_format'] :
                  selectedItems['birth_date_format'];

              let numberType = selectDateType.find((itm) =>
                  itm.value === format)
              setSelectedDateType(numberType);
              setDateType(numberType.value);
          } else {
              setDateType('mm/dd/yyyy');
          }
          //eslint-disable-next-line
      }, [selectedSvgColumn]);
    return (
      <WrapperS>
        <Input value={label} name={name} readOnly onChange={() => null} />
        <ArrowRightIcon />
        <DefaultSelect
          placeHolder="Column name"
          isOpenHandler={() => null}
          data={svgColumns}
          selected={selectedSvgColumn}
          isExistField={isExistField}
          onChange={(e) => {
            setSelectedSvgColumn(e);
            selectHandler(e);
            selectExistFieldHandler(name);
          }}
        />
        {selectedSvgColumn && selectedSvgColumn.title !== 'Empty' && (
          <>
            <ArrowRightIcon />
            <DefaultSelect
              placeHolder="Select type"
              isOpenHandler={() => null}
              data={selectDateType}
              selected={selectedDateType}
              onChange={(e) => {
                setSelectedDateType(e);
                setDateType(e.value);
              }}
            />
          </>
        )}
      </WrapperS>
    );
  }
);
const WrapperS = styled.div`
  display: flex;
  align-items: center;
  div,
  svg {
    margin-right: 10px;
  }
`;
