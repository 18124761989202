import {
  EChartType,
  ETypeGraph,
  TChart,
  TChartFilter,
  TDatum,
} from '../analytics/types';
import { EOrigin } from '../assessments/types';
import { TTeam } from '../team/types';
import { TWorkspace } from '../workspaces/types';

export interface TDataStoryLink {
  story_id: string;
  story_link: string;
  workspace_id: string;
  story_link_expiration: number;
  days_difference: number;
}
export interface TSnapshotData {
  id: string;
  data: TDatum[];
  name: string;
  type: ETypeGraph;
  origin: EOrigin;
  view: EChartType;
  settings: TChartFilter;
  is_saved: boolean;
  position: number;
  assessments_ids: string[];
}
export interface TSnapshot {
  id: string;
  name: string;
  type: 'chart' | 'file';
  created_at: string;
  comment: string;
  position: string;
  data: TChart;
  url: {
    url: string;
    url_expiration: string;
  };
}
export interface TDataStory {
  id: string;
  logo_team: boolean;
  logo_workspace: boolean;
  frame_color: string;
  link: string;
  link_expiration: string;
  workspace: TWorkspace;
  team: TTeam;
  snapshots: TSnapshot[];
}
export interface TDataStoryState {
  loading: boolean;
  link: TDataStoryLink | null;
  data: TDataStory | null;
  errors?: string | undefined;
  linkErrors?: string | undefined;
  linkLoading: boolean;
}

enum ActionTypes {
  GET_STORIES_R = '@@dataStory/GET_STORIES_R',
  GET_STORIES_S = '@@dataStory/GET_STORIES_S',
  GET_STORIES_E = '@@dataStory/GET_STORIES_E',

  GET_STORIES_BY_LINK_R = '@@dataStory/GET_STORIES_BY_LINK_R',
  GET_STORIES_BY_LINK_S = '@@dataStory/GET_STORIES_BY_LINK_S',
  GET_STORIES_BY_LINK_E = '@@dataStory/GET_STORIES_BY_LINK_E',

  EDIT_STORIES_R = '@@dataStory/EDIT_STORIES_R',
  EDIT_STORIES_S = '@@dataStory/EDIT_STORIES_S',
  EDIT_STORIES_E = '@@dataStory/EDIT_STORIES_E',

  CREATE_LINK_R = '@@dataStory/CREATE_LINK_R',
  CREATE_LINK_S = '@@dataStory/CREATE_LINK_S',
  CREATE_LINK_E = '@@dataStory/CREATE_LINK_E',

  GET_LINK_R = '@@dataStory/GET_LINK_R',
  GET_LINK_S = '@@dataStory/GET_LINK_S',
  GET_LINK_E = '@@dataStory/GET_LINK_E',

  DEACTIVATE_LINK_R = '@@dataStory/DEACTIVATE_LINK_R',
  DEACTIVATE_LINK_S = '@@dataStory/DEACTIVATE_LINK_S',
  DEACTIVATE_LINK_E = '@@dataStory/DEACTIVATE_LINK_E',

  CREATE_SNAPSHOT_R = '@@dataStory/CREATE_SNAPSHOT_R',
  CREATE_SNAPSHOT_S = '@@dataStory/CREATE_SNAPSHOT_S',
  CREATE_SNAPSHOT_E = '@@dataStory/CREATE_SNAPSHOT_E',

  CREATE_FILE_SNAPSHOT_R = '@@dataStory/CREATE_FILE_SNAPSHOT_R',
  CREATE_FILE_SNAPSHOT_S = '@@dataStory/CREATE_FILE_SNAPSHOT_S',
  CREATE_FILE_SNAPSHOT_E = '@@dataStory/CREATE_FILE_SNAPSHOT_E',

  EDIT_SNAPSHOT_R = '@@dataStory/EDIT_SNAPSHOT_R',
  EDIT_SNAPSHOT_S = '@@dataStory/EDIT_SNAPSHOT_S',
  EDIT_SNAPSHOT_E = '@@dataStory/EDIT_SNAPSHOT_E',

  DELETE_SNAPSHOT_R = '@@dataStory/DELETE_SNAPSHOT_R',
  DELETE_SNAPSHOT_S = '@@dataStory/DELETE_SNAPSHOT_S',
  DELETE_SNAPSHOT_E = '@@dataStory/DELETE_SNAPSHOT_E',

  STORIES_UPDATE = '@@dataStory/STORIES_UPDATE',
}

export default ActionTypes;
