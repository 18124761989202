import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TAttachment } from './types';

const GetAllAttachmentsActions = new Actions('GET_ALL_ATTACH', ActionTypes);

export interface TypeGetAllAttachR extends TDefRequest {
  workspaceId: string;
}

export const GetAllAttachments = {
  request: (payload: TypeGetAllAttachR) =>
    GetAllAttachmentsActions.request(payload),
  success: (payload: null) => GetAllAttachmentsActions.success(payload),
  error: (message: string) => GetAllAttachmentsActions.error(message),
};

const GetGetPresignedUrlActions = new Actions('GET_PRESIGNED_URL', ActionTypes);

export interface TypeGetPresignedUrlR extends TDefRequest {
  attachmentId: string;
}

export const GetPresignedUrl = {
  request: (payload: TypeGetPresignedUrlR) =>
    GetGetPresignedUrlActions.request(payload),
  success: (payload: null) => GetGetPresignedUrlActions.success(payload),
  error: (message: string) => GetGetPresignedUrlActions.error(message),
};

const DeleteAttachmentActions = new Actions('DELETE_ATTACH', ActionTypes);

export interface TypeDeleteAttachmentR extends TDefRequest {
  attachmentId: string;
}

export const DeleteAttachment = {
  request: (payload: TypeDeleteAttachmentR) =>
    DeleteAttachmentActions.request(payload),
  success: (payload: TAttachment[]) => DeleteAttachmentActions.success(payload),
  error: (message: string) => DeleteAttachmentActions.error(message),
};

const CreateAttachmentActions = new Actions('CREATE_ATTACH', ActionTypes);

export interface TypeCreateAttachmentR extends TDefRequest {
  workspaceId: string;
  data: {
    name: string;
    type: 'link' | 'file';
    link_url?: string;
    file?: File | null;
  };
}

export const CreateAttachment = {
  request: (payload: TypeCreateAttachmentR) =>
    CreateAttachmentActions.request(payload),
  success: (payload: null) => CreateAttachmentActions.success(payload),
  error: (message: string) => CreateAttachmentActions.error(message),
};
