export interface TTeam {
  id: string;
  name: string;
  logo_url: string;
  created_at: Date;
  updated_at: Date;
}
export interface TTeamState {
  readonly loading: boolean;
  readonly data: TTeam | null;
  readonly errors?: string | undefined;
}
export const ACTION_CONST = {
  getMy: 'GET_MY',
  create: 'CREATE',
  delete: 'DELETE',
  edit: 'EDIT',
};
enum ActionTypes {
  GET_MY_R = '@@team/GET_MY_R',
  GET_MY_S = '@@team/GET_MY_S',
  GET_MY_E = '@@team/GET_MY_E',

  CREATE_R = '@@team/CREATE_R',
  CREATE_S = '@@team/CREATE_S',
  CREATE_E = '@@team/CREATE_E',

  DELETE_R = '@@team/DELETE_R',
  DELETE_S = '@@team/DELETE_S',
  DELETE_E = '@@team/DELETE_E',

  EDIT_R = '@@team/EDIT_R',
  EDIT_S = '@@team/EDIT_S',
  EDIT_E = '@@team/EDIT_E',
}

export default ActionTypes;
