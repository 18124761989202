import React from 'react';

interface ActiveIconProps {}

export const ActiveIcon: React.FC<ActiveIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
      width="10"
      height="8"
      viewBox="0 0 10 8"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M51.24 15.35c.357-.418.988-.469 1.41-.114.388.329.461.888.19 1.302l-.076.1-4.988 5.836c-.066.077-.138.146-.218.208-.61.476-1.478.407-2.005-.137l-.1-.116-2.24-2.836c-.34-.432-.265-1.057.17-1.395.4-.313.967-.273 1.32.075l.084.093 1.86 2.357 4.593-5.373z"
                transform="translate(-844 -335) translate(435 306) translate(366 14)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
