
interface ILang {
    none:                   string;
    job:                    string;
    work_location_name:     string;
    work_location_country:  string;
    work_location_state:    string;
    work_location_city:     string;
    pay_group:              string;
    department:             string;
    department_segment:     string;
    division:               string;
}
export const LangGroupClassifications: {
    en: ILang;
    es: ILang;
    fr: ILang;
    he: ILang;
} = {
    en: {
        none:                   'None',
        job:                    'Job',
        work_location_name:     'Work Location Name',
        work_location_country:  'Work Location Country',
        work_location_state:    'Work Location State',
        work_location_city:     'Work Location City',
        pay_group:              'Pay Group',
        department:             'Department',
        department_segment:     'Department Segment',
        division:               'Division',
    },
    es: {
        none:                   'Ninguno',
        job:                    'Trabajo',
        work_location_name:     'Nombre del lugar de trabajo',
        work_location_country:  'País de ubicación de trabajo',
        work_location_state:    'Estado del lugar de trabajo',
        work_location_city:     'Ciudad de ubicación de trabajo',
        pay_group:              'Grupo de pago',
        department:             'Departamento',
        department_segment:     'Segmento de departamento',
        division:               'División',
    },
    fr: {
        none:                   'Aucun',
        job:                    'Emploi',
        work_location_name:     'Nom du lieu de travail',
        work_location_country:  'Pays du lieu de travail',
        work_location_state:    'État du lieu de travail',
        work_location_city:     'Ville du lieu de travail',
        pay_group:              'Groupe Payant',
        department:             'Département',
        department_segment:     'Segment de département',
        division:               'Division',
    },
    he: {
        none:                   'אף אחד',
        job:                    'העבודה',
        work_location_name:     'שם מיקום העבודה',
        work_location_country:  'ארץ מיקום העבודה',
        work_location_state:    'מצב מיקום העבודה',
        work_location_city:     'עיר מיקום העבודה',
        pay_group:              'קבוצת התשלום',
        department:             'המחלקה',
        department_segment:     'קטע המחלקה',
        division:               'החטיבה',
    },
}