import React from 'react';
import styled from 'styled-components';
import { mainClass } from '../../utils/animatedStyles';
import { COLORS_RGBA } from '../../utils/colors';
import { CheckBox, Loader, UserImage } from '../ui';

interface ConsultantItemProps {
  src: string;
  name: string;
  removeHandler?: () => void;
  checkHandler?: (isChecked: boolean) => void;
  isNew?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
}

export const ConsultantItem: React.FC<ConsultantItemProps> = ({
  src,
  name,
  isNew,
  isActive,
  isLoading,
  checkHandler,
}) => {
  const ref = React.useRef<HTMLLIElement>(null);

  // const [loading, setLoading] = React.useState(false);
  // const removeBtnHandler = async () => {
  //   const wr = { ...classController };
  //   wr.el = ref.current;
  //   wr.addClass('animate__flipOutX');
  //   await delay(500);
  //   console.log('delete only in DOM');
  //   ref.current?.remove();
  // };

  return (
    <WrapperS ref={ref} className={mainClass}>
      <UserImage src={src} />
      <ConsultantNameS style={isLoading ? { opacity: 0.2 } : {}}>
        <span>{name}</span>
      </ConsultantNameS>
      <CheckBox
        isCheck={isActive}
        onChange={(e) => (checkHandler ? checkHandler(e.target.checked) : null)}
      />
      {/* {isNew ? (
        <CheckBox
          isCheck={isActive}
          onChange={(e) => (checkHandler ? checkHandler(e.target.checked) : null)}
        />
      ) : (
        <ButtonMore
          onClick={removeBtnHandler}
          data={[
            {
              title: <DeleteSelectItem />,
              id: 1,
              value: VALUES.delete,
            },
          ]}
        />
      )} */}

      {isLoading ? <Loader isGreen size={0.8} /> : null}
    </WrapperS>
  );
};

const WrapperS = styled.li`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid ${COLORS_RGBA.default(0.12)};
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
`;

const ConsultantNameS = styled.div`
  flex: 1;
  position: relative;
  height: 100%;

  span {
    position: absolute;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    white-space: nowrap;
    display: block;
    max-width: 90%;
    text-overflow: ellipsis;
  }
`;
