import React from 'react';
import {SelectItemType} from "../../../components/ui/selects/DefaultSelect";
import {ALL_GROUPS_TYPES} from "../../../utils/consts";
import {callApi} from "../../../utils/callApi";
import {API_ROUTE_PATH} from "../../../utils/api_routes";


export const useGroupReport = () => {
    const [selectTeams, setSelectTeams] = React.useState<SelectItemType[]>([]);
    const [selectedTeam, setSelectedTeam] = React.useState<SelectItemType | null>(
        null
    );

    const [selectWorksapces, setSelectWorksapces] = React.useState<SelectItemType[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] =
        React.useState<SelectItemType | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const refLinkCvs = React.useRef<any>(null);
    const [selectOriginSource, setSelectOriginSource] = React.useState<SelectItemType[]>([ALL_GROUPS_TYPES]);
    const [selectedOriginSource, setSelectedOriginSource] = React.useState<SelectItemType[] | null>([ALL_GROUPS_TYPES]);
    const [selectedPackage, setSelectedPackage] = React.useState<SelectItemType | null>(null);
    const [selectedCycle, setSelectedCycle] = React.useState<SelectItemType | null>(null);

    const genXlsx = async () => {
        if (!selectedWorkspace || !setSelectedPackage || !selectedCycle || !selectedOriginSource) return null;
        setIsLoading(true);
        try {
            const reqData = {
                workspace_id: selectedWorkspace.id,
                package_id: selectedPackage.id,
                link: selectedCycle.id,
                is_all_groups: selectedOriginSource[0].id === ALL_GROUPS_TYPES.id,
                origin_sources: selectedOriginSource[0].id === ALL_GROUPS_TYPES.id ? [] : selectedOriginSource.map(originSource => originSource.id as string)
            };

            const { workspace_id,package_id, link, is_all_groups, origin_sources  } = reqData;
            const resp = await callApi({
                method: 'post',
                path: API_ROUTE_PATH.admins.groupCsvPath(
                    workspace_id as string,
                    package_id as string,
                ),
                data: {
                    link: link,
                    is_all_groups: is_all_groups,
                    origin_sources: origin_sources
                },
                responseType: 'blob',
            });
            const outputFilename = `Group_report_${selectedPackage.title}_${selectedCycle.title} .xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(resp);
            const linkDoc = document.createElement('a');
            linkDoc.href = url;
            linkDoc.setAttribute('download', outputFilename);
            document.body.appendChild(linkDoc);
            linkDoc.click();
        } catch (e) {
            console.log(e);
            alert(JSON.stringify(e));
        } finally {
            setIsLoading(false);
        }
    };

    // const genXlsx = async () => {
    //     if (!Workspaces.current) return null;
    //     if (!selectedPackage) return null;
    //     if(!selectedCycle) return null;
    //     if(!selectedOriginSource && !selectedOriginSource[0]) return null;
    //     setIsLoading(true);
    //
    //     try {
    //         const respData = {
    //             workspace_id: Workspaces.current.id,
    //             package_id: selectedPackage.id,
    //             link: selectedCycle.id,
    //             is_all_groups: selectedOriginSource[0].id === ALL_GROUPS_TYPES.id,
    //             origin_sources: selectedOriginSource[0].id === ALL_GROUPS_TYPES.id ? [] : selectedOriginSource.map(originSource => originSource.id as string)
    //         };
    //         const { workspace_id,package_id, link, is_all_groups, origin_sources  } = respData;
    //         const resp = await callApi({
    //             method: 'post',
    //             path: API_ROUTE_PATH.reports.groupReportPath(
    //                 workspace_id,
    //                 package_id as string,
    //             ),
    //             data: {
    //                 link: link,
    //                 is_all_groups: is_all_groups,
    //                 origin_sources: origin_sources
    //             },
    //             responseType: 'blob',
    //         });
    //         const outputFilename = `Group_report_${Workspaces.current?.name}_${selectedPackage.title}_${selectedCycle.title}.xlsx`;
    //
    //         // If you want to download file automatically using link attribute.
    //         const url = URL.createObjectURL(resp);
    //         const linkDoc = document.createElement('a');
    //         linkDoc.href = url;
    //         linkDoc.setAttribute('download', outputFilename);
    //         document.body.appendChild(linkDoc);
    //         linkDoc.click();
    //     } catch (e) {
    //         console.log(e);
    //         alert(JSON.stringify(e));
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    return {
        selectTeams, setSelectTeams,
        selectedTeam, setSelectedTeam,
        selectWorksapces, setSelectWorksapces,
        selectedWorkspace, setSelectedWorkspace,
        setSelectOriginSource,
        selectOriginSource,
        refLinkCvs,
        isLoading,
        genXlsx,
        selectedOriginSource,
        setSelectedOriginSource,
        selectedPackage,
        selectedCycle,
        setSelectedPackage,
        setSelectedCycle,
    };
};
