import React from 'react';
import * as d3 from 'd3';
import { MUTUAL } from '../../../utils/consts';

export const useNetworkMapSearch = () => {
  const svgState = React.useRef(null);
  const zoomState = React.useRef(null);

  const searchListenerCb = React.useCallback((svg: any, zoom: any) => {
    svgState.current = svg;
    zoomState.current = zoom;

    // setSvgState(svg);
    // setZoomState(zoom);
  }, []);

  const searchUserHandler = (id: string) => {
    if (!svgState.current) return null;
    if (!zoomState.current) return null;

    const d: HTMLElement = document.getElementById(id);
    const dText: HTMLElement = document.getElementById('text-' + id);
    if (!d) return null;
    const circles = document.querySelectorAll('.userCircle');
    const circlesText = document.querySelectorAll('.circle-text');
    const markers = document.querySelectorAll('marker path');
    const lines = document.querySelectorAll('.userLine');

    const lineSize = '4px';

    circles.forEach((element: HTMLElement, i) => {
      if (circlesText[i])
        circlesText[i].setAttribute('style', `fill: #919191 !important;`);
      element.setAttribute(
        'style',
        `fill: #d3d3d3 !important; stroke:  #d3d3d3 !important; filter: none !important;`
      );
    });
    lines.forEach((element: HTMLElement) => {
      if (element.id.includes(id) && element.classList.contains('or')) {
        element.setAttribute(
          'style',
          `stroke: #fbaf0d !important; stroke-width:  ${lineSize};`
        );
      } else if (element.id.includes(id)) {
        element.setAttribute(
          'style',
          `stroke: ${MUTUAL.isUnActive.color} !important; stroke-width: ${lineSize};`
        );
      } else {
        element.setAttribute('style', `stroke:  #d3d3d3 !important;`);
      }
    });
    markers.forEach((element: HTMLElement) => {
      element.setAttribute('refY', '-1');
      element.setAttribute(
        'style',
        `fill:${MUTUAL.isUnActive.color} !important;`
      );
      // element.setAttribute('style', `fill:  #d3d3d3 !important; `);
    });
    d.setAttribute(
      'style',
      `fill:${MUTUAL.isUnActive.color} !important; stroke: ${MUTUAL.isUnActive.color} !important;`
    );
    dText.setAttribute(
      'style',
      `fill:#fff !important; text-shadow: none !important;`
    );
    const ds = (d.parentNode as any)
      .getAttribute('transform')
      .match(/translate.*\((.+)\)/)[1];
    const numArr = ds.split(',');
    svgState.current
      .transition()
      .duration(750)
      .call(
        zoomState.current.transform,
        d3.zoomIdentity.scale(2).translate(-numArr[0], -numArr[1])
      );
  };

  return {
    searchListenerCb,
    searchUserHandler,
  };
};
