import { combineReducers } from 'redux';
import { AnalyticsReducer } from './analytics/reducer';
import { AssessmentsReducer } from './assessments/reducer';
import { CatsReducer } from './categories/reducer';
import { ConsultantsReducer } from './consultants/reducer';
import { DataStoryReducer } from './dataStory/reducer';
import { GroupsReducer } from './groups/reducer';
import { ModalReducer } from './modal/reducer';
import { PackagesReducer } from './packages/reducer';
import { ParticipantsReducer } from './participants/reducer';
import { TeamReducer } from './team/reducer';
import { UserReducer } from './user/reducer';
import { WorkspacesReducer } from './workspaces/reducer';
import { CollaborationsReducer } from './collaborations/reducer';
import { AttachmentsReducer } from './attachments/reducer';
import { AssessmentCardsReducer } from './assessmentCards/reducer';
import { FinchIntegrationsReducer } from './finchIntegrations/reducer';
import { MessageTemplatesReducer } from './messageTemplates/reducer';
import {MatchingTemplatesReducer} from "./matchingTemplates/reducer";

const rootReducer = combineReducers({
  Analytics: AnalyticsReducer,
  Assessments: AssessmentsReducer,
  AssessmentCards: AssessmentCardsReducer,
  Attachments: AttachmentsReducer,
  Cats: CatsReducer,
  Collaborations: CollaborationsReducer,
  Consultants: ConsultantsReducer,
  DataStory: DataStoryReducer,
  Groups: GroupsReducer,
  Modal: ModalReducer,
  Packages: PackagesReducer,
  MessageTemplates: MessageTemplatesReducer,
  Participants: ParticipantsReducer,
  Team: TeamReducer,
  User: UserReducer,
  Workspaces: WorkspacesReducer,
  FinchIntegrations: FinchIntegrationsReducer,
  MatchingTemplates: MatchingTemplatesReducer,
});

export default rootReducer;
