import { TLang } from '../../../types/lang';
import { checkLang } from '../assLang';

export enum DataValues {
  email = 0,
  phone_number = 1,
  payroll_id = 2,
}

export enum E_VALUES {
  email = 'email',
  phone = 'phone_number',
  payroll_id = 'payroll_id',
}

export const DATA = (lang: TLang) => [
  {
    title: checkLang(lang).assessments.inputs.email.placeholder,
    placeholder: checkLang(lang).assessments.inputs.email.placeholder,
    value: E_VALUES.email,
    id: DataValues.email,
  },
  {
    title: checkLang(lang).assessments.inputs.phone.placeholder,
    placeholder: '9999999999',
    value: E_VALUES.phone,
    id: DataValues.phone_number,
  },
  {
    title: checkLang(lang).assessments.inputs.payrollId.placeholder,
    placeholder: checkLang(lang).assessments.inputs.payrollId.placeholder,
    value: E_VALUES.payroll_id,
    id: DataValues.payroll_id,
  },
];
