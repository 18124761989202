import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { Actions } from '../utils/Action';
import ActionTypes, { TPackage } from './types';
import { TLang } from '../../types/lang';

const GetAllActions = new Actions('GET_ALL', ActionTypes);

export interface TypeGetAllR extends TDefRequest {
  workspaceId: string;
}

export const GetAllPackages = {
  request: (payload: TypeGetAllR) => GetAllActions.request(payload),
  success: (payload: TPackage[]) => GetAllActions.success(payload),
  error: (message: string) => GetAllActions.error(message),
};

const DeleteActions = new Actions('DELETE', ActionTypes);

export interface TypeDeleteR extends TDefRequest {
  id: string;
}

export const DeletePackage = {
  request: (payload: TypeDeleteR) => DeleteActions.request(payload),
  success: (payload: TPackage[]) => DeleteActions.success(payload),
  error: (message: string) => DeleteActions.error(message),
};

const EditActions = new Actions('EDIT', ActionTypes);

export interface TypeEditR extends TDefRequest {
  id: string;
  data: {
    name: string;
    description: string;
    is_scheduled: boolean;
    logo_team: boolean;
    logo_workspace: boolean;
    languages_tags: TLang[];
    welcome_screen_description: { en_US: string; es_ES?: string };
    welcome_screen_title: { en_US: string; es_ES?: string };
  };
}

export const EditPackage = {
  request: (payload: TypeEditR) => EditActions.request(payload),
  success: (payload: TPackage[]) => EditActions.success(payload),
  error: (message: string) => EditActions.error(message),
};

const CreateActions = new Actions('CREATE', ActionTypes);

export interface TypeCreateR extends TDefRequest {
  data: {
    workspace_id: string;
    name: string;
    description: string;
    languages_tags: TLang[];
  };
}

export const CreatePackage = {
  request: (payload: TypeCreateR) => CreateActions.request(payload),
  success: (payload: TPackage) => CreateActions.success(payload),
  error: (message: string) => CreateActions.error(message),
};

export const setPackageEditMode = (payload: TPackage | null) =>
  action(ActionTypes.SET_EDIT_MODE, payload || null);

export const updatePackageAss = (payload: TPackage[]) =>
  action(ActionTypes.UPDATE_ASS, payload);

export const setPackage = (payload: TPackage | null) =>
  action(ActionTypes.SET_PACK, payload || null);
