import React from 'react';
import styled from 'styled-components';
import { CheckBox } from '..';
import { ItemS } from './DefaultSelect';
import { TreeSelectProps } from './TreeSelect';
import { ALL_PACKS } from '../../../utils/consts';
interface InnerSelectItemProps {
  itm: TreeSelectProps['data'][0];
  clickHandler: (id: string | string[]) => void;
  activeIds: string[];
  allItms: string[];
}

export const InnerSelectItem: React.FC<InnerSelectItemProps> = React.memo(
  ({ itm, clickHandler, activeIds, allItms }) => {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
      (() => {
        if (!activeIds[0]) return setIsVisible(false);
        if (!itm.children[0]) return setIsVisible(false);
        let hasIn = false;
        itm.children.forEach((itm) => {
          if (activeIds.includes(itm.id)) {
            hasIn = true;
          }
        });
        setIsVisible(hasIn);
      })();
    }, [activeIds, itm.children]);

    const mainCheckboxHandler = () => {
      const myIds: string[] = itm.children.map((itm) => itm.id);

      if (itm.id === ALL_PACKS.id) return clickHandler(allItms);

      if (!isVisible) {
        return clickHandler([...activeIds, ...myIds]);
      }

      return clickHandler(activeIds.filter((itm) => !myIds.includes(itm)));
    };

    return (
      <ItemS key={itm.id} style={{ marginBottom: '20px' }}>
        <WrapperCheckBoxS>
          <CheckBox
            isCheck={
              ALL_PACKS.id === itm.id
                ? allItms.length === activeIds.length || !activeIds[0]
                : isVisible
            }
            onChange={mainCheckboxHandler}
          />
          <span>{itm.title}</span>
        </WrapperCheckBoxS>
        {itm.children[0] && isVisible && (
          <ul>
            {itm.children.map((innerItm, i) => (
              <li
                // onClick={() => clickHandler(innerItm.id)}
                key={innerItm.id + itm.id + i}
              >
                <WrapperCheckBoxS>
                  <CheckBox
                    isCheck={activeIds.includes(innerItm.id)}
                    onChange={() => clickHandler(innerItm.id)}
                  />
                  {innerItm.title}
                </WrapperCheckBoxS>
              </li>
            ))}
          </ul>
        )}
      </ItemS>
    );
  }
);

const WrapperCheckBoxS = styled.div`
  display: flex;
  align-items: center;
  white-space: normal;
  label {
    transform: scale(0.8);
    margin: 0 5px 0 0;
  }
`;
