import React from 'react';

export const YIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-312 -518) translate(288 75) translate(24 443)">
              <rect
                width="2"
                height="24"
                x="11"
                y="11"
                fill="#D8D8D8"
                rx="1"
                transform="rotate(90 12 23)"
              />
              <rect width="2" height="24" fill="#4CAF50" rx="1" />
              <path
                fill="#4CAF50"
                fillRule="nonzero"
                d="M11.744 16.072c.224 0 .408-.068.552-.204.144-.136.216-.328.216-.576v-3.324l2.724-3.324c.12-.144.18-.296.18-.456 0-.192-.072-.358-.216-.498-.144-.14-.308-.21-.492-.21-.2 0-.372.088-.516.264l-2.448 3.048-2.46-3.048c-.144-.176-.316-.264-.516-.264-.184 0-.348.07-.492.21-.144.14-.216.306-.216.498 0 .16.06.312.18.456l2.724 3.324v3.324c0 .248.072.44.216.576.144.136.332.204.564.204z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
