export interface TAttachmentsState {
  readonly loading: boolean;
  readonly data: TAttachment[] | null;
  readonly errors?: string | undefined;
}
export interface TAttachment {
  id: string;
  postition: number;
  object_label: string;
  object_type: TAttachmentType;
  object: {
    name?: string;
    extension?: string;
    url?: string;
  };
}
export type TAttachmentType = 'link' | 'file';
enum ActionTypes {
  GET_ALL_ATTACH_R = '@@attachments/GET_ALL_ATTACH_R',
  GET_ALL_ATTACH_S = '@@attachments/GET_ALL_ATTACH_S',
  GET_ALL_ATTACH_E = '@@attachments/GET_ALL_ATTACH_E',

  GET_PRESIGNED_URL_R = '@@attachments/GET_PRESIGNED_URL_R',
  GET_PRESIGNED_URL_S = '@@attachments/GET_PRESIGNED_URL_S',
  GET_PRESIGNED_URL_E = '@@attachments/GET_PRESIGNED_URL_E',

  CREATE_ATTACH_R = '@@attachments/CREATE_ATTACH_R',
  CREATE_ATTACH_S = '@@attachments/CREATE_ATTACH_S',
  CREATE_ATTACH_E = '@@attachments/CREATE_ATTACH_E',

  DELETE_ATTACH_R = '@@attachments/DELETE_ATTACH_R',
  DELETE_ATTACH_S = '@@attachments/DELETE_ATTACH_S',
  DELETE_ATTACH_E = '@@attachments/DELETE_ATTACH_E',
}

export default ActionTypes;
