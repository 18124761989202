import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { CurrentWorkspaceId } from '../../utils/config';
import { setInfoModal } from '../modal/actions';
import { setWorkspaceToUser } from '../user/actions';
import * as Actions from './actions';
import ActionTypes, { TWorkspace } from './types';

function* getByTeamId(
  action: ReturnType<typeof Actions.GetWorkspacesByTeam.request>
): Generator {
  const { callBack, teamId } = action.payload as Actions.TGet_By_Team_R;
  let success = true;
  try {
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.workspaces.pathByTeam + '/' + teamId,
    })) as TWorkspace[];
    yield put(Actions.GetWorkspacesByTeam.success(data));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetWorkspacesByTeam.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* edit(
  action: ReturnType<typeof Actions.EditWorkspace.request>
): Generator {
  const { callBack, workspaceId, data, src } =
    action.payload as Actions.TEdit_R;
  let success = true;
  const { name, is_logo_changed, additional_info, group_classification } = data;
  const sendData = {
    name,
    is_logo_changed,
    additional_info,
    group_classification,
  };
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file || '');
  bodyFormData.append('fields', JSON.stringify(sendData));

  try {
    const newData = (yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.workspaces.path + '/' + workspaceId,
      data: bodyFormData,
    })) as TWorkspace;
    newData.logo_url = src;
    yield put(Actions.EditWorkspace.setWorkspace(newData));
  } catch (e) {
    success = false;
    yield put(Actions.EditWorkspace.error(e));
  } finally {
    if (callBack) yield callBack(success);
  }
}

function* deleteWs(
  action: ReturnType<typeof Actions.DeleteWorkspace.request>
): Generator {
  const { callBack, workspaceId } = action.payload as Actions.TDelete_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.workspaces.path + '/' + workspaceId,
    });
  } catch (e) {
    success = false;
    yield put(Actions.DeleteWorkspace.error(e));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* create(
  action: ReturnType<typeof Actions.CreateWorkspace.request>
): Generator {
  const { callBack, data } = action.payload as Actions.TCreate_R;
  const { name, additional_info } = data;
  let success = true;
  try {
    const workspace = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.workspaces.path,
      data: {
        name,
        additional_info,
      },
    })) as TWorkspace;
    if (!workspace || (workspace && !workspace.id)) {
      yield put(Actions.CreateWorkspace.error('workspace does not have an id'));
      return null;
    }
    localStorage.setItem(CurrentWorkspaceId, workspace.id);
    yield put(Actions.CreateWorkspace.success(workspace));
    yield put(setWorkspaceToUser(workspace.id));
  } catch (e) {
    success = false;
    yield put(Actions.CreateWorkspace.error(e));
  } finally {
    if (callBack) yield call(callBack!, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_BY_TEAM_R, getByTeamId);
  yield takeEvery(ActionTypes.EDIT_R, edit);
  yield takeEvery(ActionTypes.CREATE_R, create);
  yield takeEvery(ActionTypes.DELETE_R, deleteWs);
}

export default function* workspacesSaga() {
  yield all([fork(watchFetchRequest)]);
}
