import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Snapshot } from '../components/DataStory/Snapshot';
import { ShareLink } from '../components/modals/ShareLink';
import { PageTitleWrapperS } from '../components/RightSideBar/RightSideBarStyles';
import { DownloadIcon } from '../components/svgs';
import {
  Alert,
  ButtonAdd,
  DefaultSelect,
  InputSearch,
  Loader,
} from '../components/ui';
import { LinkIcon, PreviewIcon } from '../components/svgs';
import { ButtonBorderedS, ButtonDefS } from '../components/ui/buttons/styles';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { MainWrapperS } from '../globalStyle';
import { AppStore } from '../store/applicationState';
import { GetStories, StoriesUpdate } from '../store/dataStory/actions';
import { TDataStory, TSnapshot } from '../store/dataStory/types';
import { COLORS } from '../utils/colors';
import { RenderImg } from './RenderImg';
import { ETypeGraph } from '../store/analytics/types';
import { AddSnapshot } from '../components';
import axios from 'axios';

interface DataStoryProps {}

export const DataStory: React.FC<DataStoryProps> = () => {
  const [shareOpen, setShareOpen] = React.useState(false);
  const [addSnapOpen, setAddSnapOpen] = React.useState(false);
  const [loadImg, setLoadImg] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [currentImg, setCurrentImg] = React.useState(1);
  const { DataStory, Workspaces } = useSelector((store: AppStore) => store);
  const selectData: SelectItemType[] = [
    {
      title: 'Presentation order',
      value: 'preview',
      id: 0,
    },
    {
      title: 'Sort by first added',
      value: 'first_added',
      id: 1,
    },
    {
      title: 'Sort by last added',
      value: 'last_added',
      id: 2,
    },
    { title: 'Sort by name (A-Z)', value: 'A-Z', id: 3 },
    { title: 'Sort by name (Z-A)', value: 'Z-A', id: 4 },
  ];
  const [selected, setSelected] = React.useState<SelectItemType>(selectData[0]);
  const [current, setCurrent] = React.useState<TSnapshot>(null);
  const [zip, setZip] = React.useState(JSZip);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Workspaces.current && Workspaces.current.id) {
      dispatch(GetStories.request({ workspaceId: Workspaces.current.id }));
    }
    // eslint-disable-next-line
  }, [dispatch, Workspaces.current]);
  React.useEffect(() => {
    if (
      DataStory &&
      DataStory.data &&
      DataStory.data.snapshots &&
      DataStory.data.snapshots[0]
    )
      setCurrent(DataStory.data.snapshots[0]);
    // eslint-disable-next-line
  }, [DataStory]);
  const getSortedStory = () => {
    if (!DataStory || !DataStory.data || !DataStory.data.snapshots) return null;
    const newCurrentStory: TDataStory = { ...DataStory.data };
    const { snapshots } = newCurrentStory;
    if (!snapshots && !snapshots[0]) return null;
    switch (selected.id) {
      case 0: {
        snapshots.sort((a, b) => (a.position > b.position ? 1 : -1));
        return dispatch(StoriesUpdate(newCurrentStory));
      }
      case 1: {
        snapshots.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
        return dispatch(StoriesUpdate(newCurrentStory));
      }
      case 2: {
        snapshots.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
        return dispatch(StoriesUpdate(newCurrentStory));
      }
      case 3: {
        snapshots.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        return dispatch(StoriesUpdate(newCurrentStory));
      }
      case 4: {
        snapshots.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
        );
        return dispatch(StoriesUpdate(newCurrentStory));
      }
      default:
        return null;
    }
  };
  const convertToCanvas = async (
    ref: React.MutableRefObject<HTMLDivElement>,
    zip: JSZip,
    snapshot: TSnapshot
  ) => {
    if (snapshot.type === 'file') {
      // fetch(snapshot.url.url, {
      //   method: 'GET',
      //   headers: new Headers({ Origin: 'https://satge.new.orgvitals.com/' }),
      // })
      //   .then((resp) => {
      //     if (!resp.ok) {
      //       console.log(resp.statusText);
      //     } else return resp.blob();
      //   })
      //   .then((blob) => {
      //     if (blob) zip.file(`${snapshot.name}_${snapshot.position}.png`, blob);
      //   })
      //   .finally(() => {
      //     if (
      //       DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1 <
      //       DataStory.data.snapshots.length
      //     ) {
      //       setCurrentImg(
      //         DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1
      //       );
      //       setCurrent(
      //         DataStory.data.snapshots[
      //           DataStory.data.snapshots.findIndex((itm) => itm === snapshot) +
      //             1
      //         ]
      //       );
      //     } else {
      //       zip.generateAsync({ type: 'blob' }).then((content) => {
      //         saveAs(content, 'Data_Story_Presentation.zip');
      //       });
      //       setLoadImg(false);
      //       setCurrent(DataStory.data.snapshots[0]);
      //       setCurrentImg(0);
      //       setZip(new JSZip());
      //     }
      //   });

      // fetch(snapshot.url.url, { method: 'GET' })
      //   .then((resp) => {
      //     if (!resp.ok) {
      //       console.log(resp.statusText);
      //     } else return resp.blob();
      //   })
      //   .then((blob) => {
      //     if (blob) zip.file(`${snapshot.name}_${snapshot.position}.png`, blob);
      //   })
      //   .finally(() => {
      //     if (
      //       DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1 <
      //       DataStory.data.snapshots.length
      //     ) {
      //       setCurrentImg(
      //         DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1
      //       );
      //       setCurrent(
      //         DataStory.data.snapshots[
      //           DataStory.data.snapshots.findIndex((itm) => itm === snapshot) +
      //             1
      //         ]
      //       );
      //     } else {
      //       zip.generateAsync({ type: 'blob' }).then((content) => {
      //         saveAs(content, 'Data_Story_Presentation.zip');
      //       });
      //       setLoadImg(false);
      //       setCurrent(DataStory.data.snapshots[0]);
      //       setCurrentImg(0);
      //       setZip(new JSZip());
      //     }
      //   });

      try {
        const resp = await axios.request({
          method: 'get',
          url: snapshot.url.url,
          responseType: 'blob',
        });
        const data1 = new Blob([resp.data], { type: 'image/jpg' });
        zip.file(`${snapshot.position}_${snapshot.name}.jpg`, data1);
      } catch (e) {
        console.log(e);
      } finally {
        if (
          DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1 <
          DataStory.data.snapshots.length
        ) {
          setCurrentImg(
            DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1
          );
          setCurrent(
            DataStory.data.snapshots[
              DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1
            ]
          );
        } else {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'Data_Story_Presentation.zip');
          });
          setLoadImg(false);
          setCurrent(DataStory.data.snapshots[0]);
          setCurrentImg(0);
          setZip(new JSZip());
        }
      }
    } else
      html2canvas(ref.current, { width: ref.current.offsetWidth, height: ref.current.offsetHeight }).then((canvas) => {
        // const dataUrl = canvas.toDataURL();
        // const idx = dataUrl.indexOf('base64,') + 'base64,'.length;
        // const content = dataUrl.substring(idx);
        // zip.file(`${snapshot.position}_${snapshot.name}.png`, content, {
        //   base64: true,
        // });

        const dpi = 300; // Желаемое значение DPI
        const desiredWidth = 1280;
        const desiredHeight = 720;

        const newCanvasWidth = (desiredWidth / 72) * dpi;
        const newCanvasHeight = (desiredHeight / 72) * dpi;

        const newCanvas = document.createElement('canvas');
        newCanvas.width = newCanvasWidth;
        newCanvas.height = newCanvasHeight;
        const ctx = newCanvas.getContext('2d');
        ctx.fillStyle = '#ffffff'; // Используйте нужный вам белый цвет
        ctx.fillRect(0, 0, newCanvasWidth, newCanvasHeight);
        const screenshotWidth = canvas.width;
        const screenshotHeight = canvas.height;

        const scale = Math.min(newCanvasWidth / screenshotWidth, newCanvasHeight / screenshotHeight);
        const scaledWidth = screenshotWidth * scale;
        const scaledHeight = screenshotHeight * scale;

        const x = (newCanvasWidth - scaledWidth) / 2;
        const y = (newCanvasHeight - scaledHeight) / 2;

        ctx.drawImage(canvas, 0, 0, screenshotWidth, screenshotHeight, x, y, scaledWidth, scaledHeight);

        const dataUrl = newCanvas.toDataURL();
        const idx = dataUrl.indexOf('base64,') + 'base64,'.length;
        const content = dataUrl.substring(idx);
        zip.file(`${snapshot.position}_${snapshot.name}.jpg`, content, {
          base64: true,
        });

        if (
          DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1 <
          DataStory.data.snapshots.length
        ) {
          setCurrentImg(
            DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1
          );
          setCurrent(
            DataStory.data.snapshots[
              DataStory.data.snapshots.findIndex((itm) => itm === snapshot) + 1
            ]
          );
        } else {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'Data_Story_Presentation.zip');
          });
          setLoadImg(false);
          setCurrent(DataStory.data.snapshots[0]);
          setCurrentImg(0);
          setZip(new JSZip());
        }
      });
  };

  const downloadImgHandler = (
    ref: React.MutableRefObject<HTMLDivElement>,
    zip: JSZip
  ) => {
    if (current) {

      setTimeout(
        () => convertToCanvas(ref, zip, current),
        current.data &&
          (current.data.type === ETypeGraph.type1 ||
              current.data.type === ETypeGraph.type4 ||
            current.data.type === ETypeGraph.type3)
          ? 1000
          : 0
      );
    }
  };
  React.useEffect(() => {
    getSortedStory();
    // eslint-disable-next-line
  }, [selected]);
  const isIn = (str: string) =>
    str.toLowerCase().includes(searchValue.toLowerCase());

  const renderBody = () => {
    if (DataStory && DataStory.loading) {
      return null;
    }
    if (DataStory && DataStory.errors) {
      return <Alert text={DataStory.errors} />;
    }
    if (
      DataStory &&
      DataStory.data &&
      DataStory.data.snapshots &&
      2 > searchValue.length
    ) {
      return DataStory.data.snapshots.map((itm) => {
        return (
          <Snapshot
            name={itm.name}
            key={itm.id}
            type={itm.type}
            chart={itm.data}
            comment={itm.comment}
            snapshotId={itm.id}
            settings={itm.data && itm.data.settings}
            link={itm.url}
          />
        );
      });
    }

    if (
      DataStory &&
      DataStory.data &&
      DataStory.data.snapshots &&
      searchValue &&
      2 <= searchValue.length
    ) {
      const sortedStory = DataStory.data.snapshots.filter((itm) => {
        return isIn(itm.name) || isIn(itm.comment);
      });
      if (sortedStory.length === 0)
        return <h2 style={{ marginTop: '40px' }}>No results</h2>;
      return sortedStory.map((itm) => {
        return (
          <Snapshot
            name={itm.name}
            chart={itm.data}
            type={itm.type}
            comment={itm.comment}
            snapshotId={itm.id}
            key={itm.id}
            settings={itm.data && itm.data.settings}
            link={itm.url}
          />
        );
      });
    }
    if (
      (DataStory && DataStory.data && !DataStory.data.snapshots) ||
      (DataStory && DataStory.data && DataStory.data.snapshots[0])
    )
      return (
        <h2 style={{ marginTop: '40px' }}>There are no charts in Data Story</h2>
      );
  };
  return (
    <MainWrapperS>
      <TitleWrapperS>
        <h1>Data Story</h1>
        <ButtonsWrapperS>
          <ButtonImportWrapperS>
            <ButtonAdd title="Add image" onClick={() => setAddSnapOpen(true)} />
          </ButtonImportWrapperS>
          {DataStory &&
            DataStory.data &&
            DataStory.data.snapshots &&
            DataStory.data.snapshots[0] && (
              <PresentationButtonS
                onClick={() => {
                  setLoadImg(true);
                }}
              >
                <DownloadIcon />
                <span>Download graphs</span>
              </PresentationButtonS>
            )}
        </ButtonsWrapperS>
      </TitleWrapperS>
      <FunctionBlockWrapperS>
        <DefaultSelectWrapperS>
          <DefaultSelect
            onChange={setSelected}
            selected={selected}
            data={selectData}
          />
        </DefaultSelectWrapperS>
        <ButtonsWrapperS>
          {DataStory &&
            DataStory.data &&
            DataStory.data.snapshots &&
            DataStory.data.snapshots[0] && (
              <ShareLinkButtonS onClick={() => setShareOpen(true)}>
                <div>
                  <LinkIcon />
                  Share link
                </div>
              </ShareLinkButtonS>
            )}
          {(DataStory && DataStory.data && !DataStory.data.snapshots) ||
            (DataStory && DataStory.data && DataStory.data.snapshots[0] && (
              <a
                href={
                  Workspaces.current && Workspaces.current.id
                    ? `${window.location.origin}/data-story-preview/${Workspaces.current.id}`
                    : null
                }
                style={{ marginRight: '12px' }}
              >
                <PreviewButtonS>
                  <PreviewIcon />
                  Preview
                </PreviewButtonS>
              </a>
            ))}
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            results={[]}
          />
        </ButtonsWrapperS>
      </FunctionBlockWrapperS>
      <WrapperS>{renderBody()}</WrapperS>
      {loadImg && (
        <RenderImg
          current={current}
          downloadHandler={downloadImgHandler}
          zip={zip}
          currentImg={currentImg}
          imgGap={DataStory.data.snapshots.length}
        />
      )}
      {DataStory.loading && <Loader isGreen isFixed />}
      {shareOpen && <ShareLink closeModal={() => setShareOpen(false)} />}
      {addSnapOpen && (
        <AddSnapshot
          closeHandler={() => {
            setAddSnapOpen(false);
          }}
        />
      )}
    </MainWrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //& > div {
  //  min-width: 450px;
  //  width: calc(50% - 20px);
  //  min-height: 350px;
  //}
`;
const ButtonImportWrapperS = styled.div`
  margin-right: 12px;
`;
const DefaultSelectWrapperS = styled.div`
  div {
    height: 36px;
    width: 256px;
  }
`;
const FunctionBlockWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonsWrapperS = styled.div`
  display: flex;
  input {
    height: 36px;
  }
`;
const TitleWrapperS = styled(PageTitleWrapperS)`
  justify-content: space-between;
`;
const PresentationButtonS = styled(ButtonDefS)`
  width: 249px;
  min-height: 36px;
  height: 36px;
  border-radius: 8px;
  svg {
    margin-right: 8px;
  }
`;
const ShareLinkButtonS = styled(ButtonBorderedS)`
  width: 130px;
  min-height: 36px;
  height: 36px;
  align-items: center;
  margin-right: 12px;
  div {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
      g {
        fill: ${COLORS.accent};
      }
    }
  }
`;
const PreviewButtonS = styled(ButtonDefS)`
  width: 114px;
  min-height: 36px;
  height: 36px;
  svg {
    margin-right: 8px;
  }
`;
