export const COLORS = {
  secondary: '#d8d8d8',
  default: '#2a2f2a',
  accent: '#4caf50',
  accentHover: '#4ac24f',
  accentActive: '#3c9c40',
  orangeHover: '#efa02f',
  orangeActive: '#EFA02FFF',
  danger: '#ff5e5e',
  dangerActive: '#b90404',
  magenta: '#FF00FF',

};
export const COLORS_RGBA = {
  secondary: (opacity: number = 1) => `rgba(216, 216, 216, ${opacity})`,
  default: (opacity: number = 1) => `rgba(42, 47, 42, ${opacity})`,
  accent: (opacity: number = 1) => `rgba(76, 175, 80, ${opacity})`,
  danger: (opacity: number = 1) => `rgba(255, 94, 94, ${opacity})`,
  violet: (opacity: number = 1) => `rgba(117, 66, 193, ${opacity})`,
  orange: (opacity: number = 1) => `rgba(255, 157, 2, ${opacity})`,
  magenta: (opacity: number = 1) => `rgba(255, 0, 255, ${opacity})`,
  lightBlue: (opacity: number = 1) => `rgba(28, 199, 248, ${opacity})`,
};

export const GRAPH_COLORS = [
  'rgba(255, 99, 132, 1)',
  '#fe6841',
  '#fda877',
  '#ffd6b2',
  '#adcf64',
  '#6fa051',
  '#00aa41',
  '#006a84',
  '#002c84',
  '#FF0000',
  '#800000',
  '#FFFF00',
  '#808000',
  '#00FF00',
  '#008000',
  '#00FFFF',
  '#008080',
  '#0000FF',
  '#000080',
  '#FF00FF',
  '#800080',
  '#FFA07A',
  '#FA8072',
  '#E9967A',
  '#F08080',
  '#CD5C5C',
  '#DC143C',
  '#B22222',
  '#FF0000',
  '#8B0000',
  '#FFFFE0',
  '#FFFACD',
  '#FAFAD2',
  '#FFEFD5',
  '#FFE4B5',
  '#FFDAB9',
  'rgba(255, 99, 132, 1)',
  '#fe6841',
  '#fda877',
  '#ffd6b2',
  '#adcf64',
  '#6fa051',
  '#00aa41',
  '#006a84',
  '#002c84',
  '#FF0000',
  '#800000',
  '#FFFF00',
  '#808000',
  '#00FF00',
  '#008000',
  '#00FFFF',
  '#008080',
  '#0000FF',
  '#000080',
  '#FF00FF',
  '#800080',
  '#FFA07A',
  '#FA8072',
  '#E9967A',
  '#F08080',
  '#CD5C5C',
  '#DC143C',
  '#B22222',
  '#FF0000',
  '#8B0000',
  '#FFFFE0',
  '#FFFACD',
  '#FAFAD2',
  '#FFEFD5',
  '#FFE4B5',
  '#FFDAB9',
  'rgba(255, 99, 132, 1)',
  '#fe6841',
  '#fda877',
  '#ffd6b2',
  '#adcf64',
  '#6fa051',
  '#00aa41',
  '#006a84',
  '#002c84',
  '#FF0000',
  '#800000',
  '#FFFF00',
  '#808000',
  '#00FF00',
  '#008000',
  '#00FFFF',
  '#008080',
  '#0000FF',
  '#000080',
  '#FF00FF',
  '#800080',
  '#FFA07A',
  '#FA8072',
  '#E9967A',
  '#F08080',
  '#CD5C5C',
  '#DC143C',
  '#B22222',
  '#FF0000',
  '#8B0000',
  '#FFFFE0',
  '#FFFACD',
  '#FAFAD2',
  '#FFEFD5',
  '#FFE4B5',
  '#FFDAB9',
];
