import React from 'react';
import styled from 'styled-components';
import { TRespIdentify } from '../../pages/Responding/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { TEXT } from '../../utils/textConst';
import { ArrowRightIcon } from '../svgs';
import { Alert } from '../ui';
import { RespTitle } from './components/RespTitle';
import { RespTextS } from './styles';
import { CSS_MEDIA } from '../../utils/media';
import { TLang } from '../../types/lang';
import { checkLang } from './assLang';
import {
  langIsAllingRight,
  langIsEng,
  langIsFrench,
  langIsHebrew,
  langIsSpan,
} from '../../utils/langIsEng';
import {EQuestionType} from "../../store/assessments/types";

interface ScreenAssessmentsProps {
  nextFn: (
    ass: TRespIdentify['package']['assessments'][0],
    isCollab?: boolean,
    isLinkedSurvey?: boolean,
  ) => void;
  lang: TLang;
  identifyData: TRespIdentify;
}

export const ScreenAssessments: React.FC<ScreenAssessmentsProps> = ({
  nextFn,
  identifyData,
  lang,
}) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  if (!identifyData)
    return <Alert text={`No identify data, ${TEXT.tryLater}`} />;
  if (!identifyData.package)
    return <Alert text={'No identify package, ' + TEXT.tryLater} />;
  if (!identifyData.participant)
    return <Alert text={'No identify participant, ' + TEXT.tryLater} />;
  const { package: pack, participant } = identifyData;
  const { first_name } = identifyData.participant;
  console.log('package:');
  console.log(pack);
  //
  if (!pack.assessments || !pack.assessments[0])
    return (
      <>
        <StyledDivS alignRight={langIsAllingRight(lang)}>
          <h1>{checkLang(lang).assessments.goodWork(first_name)}</h1>
          <h2>{checkLang(lang).assessments.completed}</h2>
          <ThanksTextS>
            {checkLang(lang).assessments.thanksForTime}{' '}
          </ThanksTextS>
          {/* {langIsEng(lang) && (
            <TextWrapperS>
              <LinkS
                href="https://www.readathena.com/welcome-orgvitals/"
                style={{ height: 100 }}
              >
                <ImageS src={AthenaLogo} alt="AthenaLogo" />
              </LinkS>
              <ParagraphS>
                As a thank you, <br />
                OrgVitals is happy to offer free access to Athena book
                summaries. <br /> Enjoy an assortment of the best professional
                development resources. <br />
                Each book is condensed into a 10 minute read!
                <br />
                Visit{' '}
                <LinkS href="https://www.readathena.com/welcome-orgvitals/">
                  ReadAthena.com
                </LinkS>
              </ParagraphS>
              <MobileParagraphS>
                As a thank you, OrgVitals is happy to offer free access to
                Athena book summaries. Enjoy an assortment of the best
                professional development resources. Each book is condensed into
                a 10 minute read!
                <br />
                Visit{' '}
                <LinkS href="https://www.readathena.com/welcome-orgvitals/">
                  ReadAthena.com
                </LinkS>
              </MobileParagraphS>
            </TextWrapperS>
          )} */}
        </StyledDivS>
      </>
    );

  const renderName = (name: typeof pack.assessments[0]['name']) => {
    if (langIsEng(lang)) {
      return name.en_US;
    }
    if (langIsSpan(lang) && name.es_ES) {
      return name.es_ES;
    }
    if (langIsFrench(lang) && name.fr_FR) {
      return name.fr_FR;
    }
    if (langIsHebrew(lang) && name.he_IL) {
      return name.he_IL;
    }
    return name.en_US;
  };
  return (
    <AnimatedWrapperS className={AnimatedClasses.zoomIn}>
      <RespTitle
        title={`${checkLang(lang).assessments.welcome}, ${
          participant.first_name
        } ${participant.last_name}!`}
        alignRight={langIsAllingRight(lang)}
      />
      <RespTextS alignRight={langIsAllingRight(lang)}>
        {checkLang(lang).assessments.respond(pack.assessments.length)}
      </RespTextS>
      <WrapperS alignRight={langIsAllingRight(lang)}>
        {pack.assessments.map((ass) => {
          if (ass.answer_type === EQuestionType.linked_survey) {
            return (
                <li key={ass.id} onClick={() => nextFn(ass, false, true)}>
                  <span>{renderName(ass.name)}</span> <ArrowRightIcon />
                </li>
            );
          }
          if (ass.answer_type === 'select_collaborations') {
            return (
              <li key={ass.id} onClick={() => nextFn(ass, true)}>
                <span>{renderName(ass.name)}</span> <ArrowRightIcon />
              </li>
            );
          }
          return (
            <li key={ass.id} onClick={() => nextFn(ass)}>
              <span>{renderName(ass.name)}</span> <ArrowRightIcon />
            </li>
          );
        })}
        {/* {isCollaborationInclude && (
          <li onClick={() => nextFn(null, true)}>
            <span>Collaboration</span> <ArrowRightIcon />
          </li>
        )} */}
      </WrapperS>
    </AnimatedWrapperS>
  );
};
const AnimatedWrapperS = styled.div`
  ${CSS_MEDIA.maxLg} {
    padding: 75px 0 30px;
  }
`;
const WrapperS = styled.ul<{ alignRight: boolean }>`
  margin: 35px 0 0;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
  padding: 0;
  list-style: none;
  li {
    padding: 14px 24px;
    border-radius: 30px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    backdrop-filter: blur(25px);
    border: solid 1px rgba(67, 67, 82, 0.2);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434352;

    span {
      flex: 1;
      padding-right: 20px;
    }
    /* svg {
      ${({ alignRight }) => (alignRight ? 'transform: rotate(180deg)' : '')};
    } */
    &:hover {
      border: solid 1px #4caf50;
    }
    &:active {
      border: solid 1px #3c9c40;
      color: #3c9c40;
    }
  }
`;
const ThanksTextS = styled.p`
  font-size: 18px;
  ${CSS_MEDIA.maxLg} {
    font-size: 16px;
  }
`;
// const ParagraphS = styled.p`
//   font-size: 16px;
//   text-align: left;
//   ${CSS_MEDIA.maxLg} {
//     display: none;
//   }
// `;
// const MobileParagraphS = styled.p`
//   font-size: 12px;
//   text-align: center;
//   display: none;
//   margin: 10px 0 0 0;
//   width: 400px;
//   ${CSS_MEDIA.maxLg} {
//     display: block;
//     width: 100%;
//     justify-self: center;
//   }
//   ${CSS_MEDIA.maxSm} {
//     margin: 0 0 25px 0;
//   }
// `;
const StyledDivS = styled.div<{ alignRight: boolean }>`
  text-align: center;
  direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
`;
// const TextWrapperS = styled.div`
//   position: absolute;
//   bottom: 5%;
//   left: 50%;
//   transform: translateX(-50%);
//   display: flex;
//   align-items: center;
//   max-width: 650px;
//   border-bottom: 5px solid #04dcbc;
//   ${CSS_MEDIA.maxSm} {
//     min-width: 90%;
//     margin-bottom: 15px;
//     height: 75px;
//   }
//   ${CSS_MEDIA.maxLg} {
//     margin-bottom: 15px;
//   }
// `;
// const LinkS = styled.a`
//   color: #04dcbc;

//   &:hover {
//     color: #04c4aa;
//   }
//   img {
//     ${CSS_MEDIA.maxLg} {
//       display: none;
//     }
//   }
// `;
// const ImageS = styled.img`
//   max-height: 100px;
//   margin-right: 20px;
// `;
