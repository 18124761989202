import { callApi } from '../../../utils/callApi';
import { API_ROUTE_PATH } from '../../../utils/api_routes';
import { TLang } from '../../../types/lang';

export interface ICbData {
  data: {
    team: any;
    welcome_screen_description: { en_US: string; es_ES?: string };
    welcome_screen_title: { en_US: string; es_ES?: string };
    workspace: {name: string, is_active_group_classification: boolean, group_classification: string, logo_url: string};
    languages_tags: TLang[];
  };
  success: boolean;
  errorText: string;
}
export const getPreferences = async (data: { id: string; cb?: () => void }) => {
  let success = true;
  let cbData: ICbData = {
    data: {
      team: null,
      welcome_screen_description: { en_US: '' },
      welcome_screen_title: { en_US: '' },
      workspace: null,
      languages_tags: ['en_US'],
    },
    success: false,
    errorText: '',
  };
  try {
    const respData = await callApi({
      method: 'get',
      path: `${API_ROUTE_PATH.packages.getPref(data.id)}`,
    });

    cbData = {
      data: respData,
      success,
      errorText: '',
    };
    return cbData;
  } catch (error) {
    console.log(error, 'error');
    success = false;
    cbData = {
      data: cbData.data,
      success,
      errorText: `${error}`,
    };
    return cbData;
  } finally {
    if (data.cb) data.cb();
  }
};
