import { Reducer } from 'redux';
import ActionTypes, { TTeamState } from './types';

export const initialState: TTeamState = {
  data: null,
  loading: true,
  errors: undefined,
};

const reducer: Reducer<TTeamState> = (
  state = initialState,
  action
): TTeamState => {
  switch (action.type) {
    case ActionTypes.DELETE_R:
    case ActionTypes.CREATE_R:
    case ActionTypes.GET_MY_R:
    case ActionTypes.EDIT_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
        data: state.data,
      };

    case ActionTypes.DELETE_S:
    case ActionTypes.GET_MY_S:
    case ActionTypes.EDIT_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: action.payload,
      };

    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        data: state.data ? state.data : action.payload,
      };
    case ActionTypes.DELETE_E:
    case ActionTypes.CREATE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.GET_MY_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { reducer as TeamReducer };
