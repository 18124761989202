import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ROUTE_PATH } from '../../utils/api_routes';
import { callApi } from '../../utils/callApi';
import { setInfoModal } from '../modal/actions';
import * as Actions from './actions';
import ActionTypes, { TConsultant } from './types';

function* getByTeamId(
  action: ReturnType<typeof Actions.GetConsultantsByTeam.request>
): Generator {
  const { callBack, teamId } = action.payload as Actions.TGet_By_Team_R;
  let success = true;
  try {
    const data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTE_PATH.consultants.gAll + '/' + teamId,
    })) as TConsultant[];
    yield put(Actions.GetConsultantsByTeam.success(data));
  } catch (e) {
    success = false;
    yield put(setInfoModal({ title: 'Error', mess: e }));
    yield put(Actions.GetConsultantsByTeam.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* edit(
  action: ReturnType<typeof Actions.EditConsultant.request>
): Generator {
  const { callBack, data } = action.payload as Actions.TEdit_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'put',
      path: API_ROUTE_PATH.consultants.uMe,
      data,
    });
  } catch (e) {
    success = false;
    yield put(Actions.EditConsultant.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}
function* setWorkspace(
  action: ReturnType<typeof Actions.SetConsultantWorkspace.request>
): Generator {
  const { callBack, data } = action.payload as Actions.TSetWorkspace_R;
  const { relation, workspace_id, consultant_id } = data;
  let success = true;
  try {
    yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.consultants.genUM2M(consultant_id, workspace_id),
      data: {
        relation: relation,
      },
    });
  } catch (e) {
    success = false;
    yield put(Actions.SetConsultantWorkspace.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* deleteWs(
  action: ReturnType<typeof Actions.DeleteConsultant.request>
): Generator {
  const { callBack, workspaceId } = action.payload as Actions.TDelete_R;
  let success = true;
  try {
    yield call(callApi, {
      method: 'delete',
      path: API_ROUTE_PATH.consultants.path + '/' + workspaceId,
    });
  } catch (e) {
    success = false;
    yield put(Actions.DeleteConsultant.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* create(
  action: ReturnType<typeof Actions.CreateConsultant.request>
): Generator {
  const { callBack, data } = action.payload as Actions.TCreate_R;
  let success = true;
  try {
    const workspace = (yield call(callApi, {
      method: 'post',
      path: API_ROUTE_PATH.consultants.path,
      data,
    })) as TConsultant;

    yield put(Actions.CreateConsultant.success(workspace));
  } catch (e) {
    success = false;
    yield put(Actions.CreateConsultant.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_BY_TEAM_R, getByTeamId);
  yield takeEvery(ActionTypes.EDIT_R, edit);
  yield takeEvery(ActionTypes.CREATE_R, create);
  yield takeEvery(ActionTypes.DELETE_R, deleteWs);
  yield takeEvery(ActionTypes.SET_WORKSPACE_R, setWorkspace);
}

export default function* consultantsSaga() {
  yield all([fork(watchFetchRequest)]);
}
