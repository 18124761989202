import React from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { deepClone } from '../../../utils/deepClone';
import { MUTUAL } from '../../../utils/consts';
import {EColFilterStatus} from '../../../store/collaborations/types';
import { useHoverCircle } from './useHoverCircle';
import { NETWORK_MAP_TEXT } from '../../../utils/textConst';
import { useNetworkMapSearch } from './useNetworkMapSearch';
import { COLORS } from '../../../utils/colors';
// declare const location: any;

enum SplitPos {
  id,
  first_name,
  last_name,
  // target,
  filtered_status,
  percentile,
  friendly_score,
}

export const useNetworkMap = () => {
  const [currentUser, setCurrentUser] = React.useState<{
    first_name: string;
    last_name: string;
    id: string;
  } | null>(null);
  const [d3Loaded, setD3Loaded] = React.useState(false);
  const { Collaborations } = useSelector((store: AppStore) => store);
  const svgRef = React.useRef<any>(null);
  const popUpRef = React.useRef(null);
  const { searchListenerCb, searchUserHandler } = useNetworkMapSearch();
  useHoverCircle(d3Loaded, Collaborations.data);
  const clickCircleHandler = React.useCallback(
    (circle: Element) => {
      if (!Collaborations.data || !Collaborations.data[0]) return null;
      const user = Collaborations.data.find((u) => {
        return u.source === circle.id || u.target === circle.id;
      });

      if (!user) return null;

      if (user.target === circle.id) {
        const {
          target_first_name: first_name,
          target_last_name: last_name,
          target: id,
        } = user;

        return setCurrentUser({
          first_name,
          last_name,
          id,
        });
      }

      const {
        source_first_name: first_name,
        source_last_name: last_name,
        source: id,
      } = user;

      return setCurrentUser({
        first_name,
        last_name,
        id,
      });
    },
    [Collaborations.data]
  );
  // click handler
  React.useEffect(() => {
    const fnCallBack = (e: MouseEvent) => {
      if (
        !e.target ||
        !(e.target as HTMLDivElement).classList.contains('userCircle')
      )
        return null;
      return clickCircleHandler(e.target as Element);
    };
    (() => {
      if (!d3Loaded) return null;
      document.addEventListener('click', fnCallBack);
    })();
    return () => {
      document.removeEventListener('click', fnCallBack);
    };
  }, [d3Loaded, clickCircleHandler]);

  const zoomOut = () => {
    const svg = d3.select(svgRef.current);

    let zoom = d3.zoom().scaleExtent([0, 8]).on('zoom', zoomed);

    function zoomed(event) {
      const { transform } = event;
      svg.select('g').attr('transform', transform);
      svg.select('g').attr('stroke-width', 1 / transform.k);
    }

    svg.transition().call(zoom.scaleBy, 0.5);
  };
  // d3
  // @ts-ignore
  React.useEffect(() => {
    const svg = d3.select(svgRef.current);

    if (!Collaborations.data || !Collaborations.data[0])
      return svg.selectAll('*').remove();
    const links = deepClone(Collaborations.data);
    svg.selectAll('*').remove();
    const zoom = d3.zoom().scaleExtent([0, 8]).on('zoom', zoomed);
    const drag = (simulation) => {
      function dragStarted(event, d) {
        if (!event.active) {
          // noinspection TypeScriptValidateJSTypes
          simulation.alphaTarget(0.02).restart();
        }
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragEnded(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      return d3
        .drag()
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded);
    };

    const nodes = Array.from(
      new Set(
        [...links].flatMap((data) => {
          return [
            `${data.source}:${data.source_first_name}:${data.source_last_name}:${data.source_filtered_status}:${data.source_percentile}:${data.source_friendly_score}`,
            `${data.target}:${data.target_first_name}:${data.target_last_name}:${data.target_filtered_status}:${data.target_percentile}:${data.target_friendly_score}`,
          ];
        })
      ),
      (id) => {
        const userArray = id.split(':');
        return {
          id: userArray[SplitPos.id],
          first_name: userArray[SplitPos.first_name] || '_',
          last_name: userArray[SplitPos.last_name] || '_',
          filtered_status: userArray[SplitPos.filtered_status] || '_',
          percentile: userArray[SplitPos.percentile] || '_',
          friendly_score: userArray[SplitPos.friendly_score] || '_',
        };
      }
    );

    const simulation = d3
      // @ts-ignore
      .forceSimulation(nodes)
      .force(
        'link',
        d3.forceLink(links).id((d: any) => {
          return d.id;
        })
      )
      // .force('x', d3.forceX())
      .force('collide', d3.forceCollide(80).iterations(5))
      .force('center', d3.forceCenter());
    // .force('y', d3.forceY())
    const getColor = (d: any) => {
      // if (d.source_filtered_status === EColFilterStatus.inactive)
      //   return '#d3d3d3';
      // if (d.source_filtered_status === EColFilterStatus.uncaptured)
      //   return '#919191';
      if (d.is_mutual) return MUTUAL.isActive.color;

      return MUTUAL.isUnActive.color;
    };

    const newW = svg.append('g');

    const defs = svg.append('defs');

    function marker(color) {
      defs
        .append('svg:marker')
        .attr('id', color.replace('#', ''))
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 29.2)
        .attr('refY', -2)
        .attr('transform', 'translate(110,122)')
        .attr('markerWidth', 5)
        .attr('markerHeight', 5)
        .attr('orient', 'auto-start-reverse')
        .append('svg:path')
        .attr('d', 'M0,-5L10,0L0,5')
        .style('fill', color);

      return 'url(' + color + ')';
    }

    const link = newW
      .append('g')
      .attr('fill', 'none')
      .attr('stroke-width', () => 2)
      .selectAll('path')
      .data(links)
      .join('path')
      .attr('stroke', getColor)

      .each(function (d: any) {
        // const color = 'red';
        // const color =
        //   d.source_filtered_status === EColFilterStatus.captured ||
        //   d.target_filtered_status === EColFilterStatus.captured
        //     ? 'red'
        //     : MUTUAL.isUnActive.color;
        // const getColor2 = (s: any) => {
        //   if (s.source_filtered_status === EColFilterStatus.inactive)
        //     return '#d3d3d3';
        //   if (s.source_filtered_status === EColFilterStatus.captured)
        //     return COLORS.accent;
        //   if (s.source_filtered_status === EColFilterStatus.uncaptured)
        //     return '#d3d3d3';
        //   if (s.is_mutual) return MUTUAL.isActive.color;
        //
        //   return MUTUAL.isUnActive.color;
        // };
        // const color = getColor(d);

        d3.select(this)
          .attr('class', (c: any) => {
            const or = () => {
              if (
                c.is_mutual &&
                (c.source_filtered_status === EColFilterStatus.captured ||
                  c.target_filtered_status === EColFilterStatus.captured)
              ) {
                return 'or';
              }

              if (
                Collaborations.filters &&
                Collaborations.filters &&
                Collaborations.filters.groups_ids &&
                Collaborations.filters.groups_ids.length &&
                (c.source_filtered_status === EColFilterStatus.captured ||
                  c.target_filtered_status === EColFilterStatus.captured)
              ) {
                return 'greenImp';
              }

              if (
                c.source_filtered_status === EColFilterStatus.captured ||
                c.target_filtered_status === EColFilterStatus.captured
              ) {
                return 'greenImp';
              }
              return '';
            };

            return `userLine ${
              Collaborations.filters.groups_ids &&
              Collaborations.filters.groups_ids.length
                ? 'withFilters'
                : ''
            } ${c.source_filtered_status} ${or()}`;
          })
          // .style('stroke', color)
          .attr('id', (c: any) => {
            return 'lineId-' + c.source.id + '-' + c.target.id;
          })
          .attr('data-sourceId', (c: any) => {
            return c.source.id;
          })
          .attr('data-targetId', (c: any) => {
            return c.target.id;
          })
          .attr('marker-end', (c: any) => {
            const or = () => {
              if (c.is_mutual) {
                return '#fbaf0d';
              }
              if (
                c.source_filtered_status === EColFilterStatus.captured ||
                c.target_filtered_status === EColFilterStatus.captured
              ) {
                return COLORS.accent;
              }
              return '#d3d3d3';
            };
            return d.type === 'manager' ? marker(or()) : null;
          });
      });

    const node = newW
      .append('g')
      .attr('fill', 'currentColor')
      .attr('class', 'currentColorWrapper')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('width', 200)
      .selectAll('g')
      .data(nodes)
      .join('g')
      // @ts-ignore
      .call(drag(simulation));

    node
      .append('circle')
      // @ts-ignore
      .attr('d', d3.geoPath())
      .attr('r', 20)
      .attr('class', (d) => `userCircle ${d.filtered_status}`)
      .attr('data-percentile', (d) => d.percentile)
      .attr('data-friendScore', (d) => d.friendly_score)
      .attr('data-userName', (d) => `${d.first_name} ${d.last_name}`)
      .attr('id', (d) => d.id);

    node
      // @ts-ignore
      .append('text', '')
      .attr('class', (d) => `circle-text ${d.filtered_status}`)
      .attr('data-percentile', (d) => d.percentile)
      .attr('data-friendScore', (d) => d.friendly_score)
      .text((d) => `${d.first_name[0]}${d.last_name[0]}`)
      .attr('text-anchor', 'middle')
      .attr('y', 4)
      .attr('id', (d) => 'text-' + d.id)
      .attr('fill', '#7e7e7e')
      .attr('stroke', 'none')
      .attr('stroke-width', 'none');

    const y = -95;
    const x = -100;
    const getText = (d: any) => {
      if (d.filtered_status === EColFilterStatus.inactive) {
        return NETWORK_MAP_TEXT.inactive;
      }
      if (d.filtered_status === EColFilterStatus.uncaptured) {
        return NETWORK_MAP_TEXT.uncaptured;
      }
      return NETWORK_MAP_TEXT.captured;
    };
    node
      .append('rect')
      .attr('class', 'svg-tooltip')
      .attr('stroke', 'none')
      .attr('y', y)
      .attr('x', x)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('stroke-width', 'none');

    node
      // @ts-ignore
      .append('foreignObject', '')
      .attr('class', 'svg-tooltip-text-wrapper')
      .attr('y', y)
      .attr('x', x)
      // @ts-ignore
      .append('xhtml:span', '')
      .attr('xmlns', 'http://www.w3.org/1999/xhtml')
      .text((d) => `${d.first_name} ${d.last_name} ${getText(d)}`);

    simulation.on('tick', tick);
    const gEl = svg.call(zoom);
    svg.node();
    // zoomOut();
    function zoomed(event) {
      const { transform } = event;
      newW.attr('transform', transform);
      newW.attr('stroke-width', 1 / transform.k);
    }
    function tick() {
      link.attr('d', linkArc);
      node.attr('transform', (d: any) => `translate(${d.x},${d.y})`);

      link
        .attr('x1', function (d: any) {
          return d.source.x;
        })
        .attr('y1', function (d: any) {
          return d.source.y;
        })
        .attr('x2', function (d: any) {
          return d.target.x;
        })
        .attr('y2', function (d: any) {
          return d.target.y;
        });
    }

    function linkArc(d) {
      const dx = d.target.x - d.source.x,
        dy = d.target.y - d.source.y,
        dr = Math.sqrt(dx * dx + dy * dy);
      return (
        'M' +
        (d.source.x - 0) +
        ',' +
        (d.source.y + 0) +
        'A' +
        dr +
        ',' +
        dr +
        ' 0 0,1 ' +
        d.target.x +
        ',' +
        d.target.y
      );
    }

    setD3Loaded(true);
    searchListenerCb(gEl, zoom);
    return () => {};
    //eslint-disable-next-line
  }, [Collaborations.data, searchListenerCb]);

  const zoomIn = () => {
    const svg = d3.select(svgRef.current);

    let zoom = d3.zoom().scaleExtent([0, 8]).on('zoom', zoomed);

    function zoomed(event) {
      const { transform } = event;
      svg.select('g').attr('transform', transform);
      svg.select('g').attr('stroke-width', 1 / transform.k);
    }
    svg.transition().call(zoom.scaleBy, 2);
  };

  return {
    svgRef,
    collaborationsData: Collaborations.data,
    loading: Collaborations.loading,
    currentUser,
    closeModal: () => setCurrentUser(null),
    popUpRef,
    zoomIn,
    zoomOut,
    searchUserHandler,
  };
};
