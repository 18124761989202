import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { CreateAttachment } from '../../store/attachments/actions';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS } from '../../utils/colors';
import { checkLinkForHttp, validateLink } from '../../utils/helpers';
import { DropWrapperS, WrapperCsvInfoS } from '../CsvAddParticipants/styles';
import { FileIcons } from '../Home/FileIcons';
import { useAttachFile } from '../Home/hooks/useAttachFile';
import { CheckIcon } from '../svgs';
import { Alert, ButtonCancel, ButtonSave, Input } from '../ui';
import { Radio, RadioItemType } from '../ui/inputs/Radio';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
interface AddMaterialsProps {
  closeHandler: () => void;
}

export const AddMaterials: React.FC<AddMaterialsProps> = ({ closeHandler }) => {
  const { Workspaces } = useSelector((store: AppStore) => store);
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const [activeType, setActiveType] = React.useState<RadioItemType['id']>(2);
  const [isLink, setIsLink] = React.useState(false);
  const [label, setLabel] = React.useState('');
  const [labelError, setLabelError] = React.useState('');
  const [webLink, setWebLink] = React.useState('');
  const [webLinkError, setWebLinkError] = React.useState('');
  const hookAttach = useAttachFile(isLink);
  const dispatch = useDispatch();
  const DataType: RadioItemType[] = [
    { id: 1, label: 'Link' },
    { id: 2, label: 'Documents' },
  ];
  React.useEffect(() => {
    setIsLink(activeType === 1);
    hookAttach.setFile(null);
    hookAttach.setErrorText('');
    setLabelError('');
    setWebLink('');
    setWebLinkError('');
    // eslint-disable-next-line
  }, [activeType]);
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };
  const saveHandler = (currentType: 'link' | 'file') => {
    hookAttach.setErrorText('');
    if (currentType === 'file' && !hookAttach.file)
      return hookAttach.setErrorText('File is empty ');
    if (!label) {
      setLabelError('Label is required');
      return null;
    }
    if (currentType === 'link' && !webLink) {
      setWebLinkError('Link is required');
      return null;
    }
    const callBack = (success: boolean) => {
      if (success) closeHandler();
    };
    dispatch(
      CreateAttachment.request({
        workspaceId: Workspaces.current.id,
        data: {
          name: label,
          type: currentType,
          link_url: checkLinkForHttp(webLink) ? webLink : 'https://' + webLink,
          file: hookAttach.file,
        },
        callBack,
      })
    );
    // const sendData: {
    //   name: string;
    //   type: TAttachmentType;
    //   link_url: string;
    // } = {
    //   name: label,
    //   type: currentType,
    //   link_url: webLink,
    // };
    // const bodyFormData = new FormData();
    // bodyFormData.append('file', hookAttach.file);
    // bodyFormData.append('fields', JSON.stringify(sendData));
    // hookAttach.setLoading(true);
    // try {
    //   const data = await callApi({
    //     path: API_ROUTE_PATH.attachments.cOne + '/' + Workspaces.current.id,
    //     method: 'post',
    //     data: bodyFormData,
    //   });
    //   console.log(data);
    // } catch (error) {
    //   return hookAttach.setErrorText(`${error}`);
    // } finally {
    //   return hookAttach.setLoading(false);
    // }
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '900px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <h1 style={{ fontSize: '20px' }}>Add materials</h1>
          <HeaderItemsWrapperS>
            <Radio
              data={DataType}
              activeId={activeType}
              clickHandler={setActiveType}
              horizontal
            />
          </HeaderItemsWrapperS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <div>
            <Alert text={hookAttach.errorText} />
          </div>
          <DropWrapperS {...hookAttach.getRootProps()} disabled={isLink}>
            <input {...hookAttach.getInputProps()} />
            {hookAttach.isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>
                Drag a document here or <span>browse</span> for a file…
              </p>
            )}
          </DropWrapperS>
          <div style={{ minHeight: '200px' }}>
            <BottomWrapperS>
              <InputWrapperS>
                <Input
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  value={label}
                  placeholder={`${
                    isLink ? 'Orgvitals website' : 'Psychological picture'
                  }`}
                  label={'Label*'}
                  errorText={labelError}
                />
                {isLink && (
                  <Input
                    onChange={(e) => {
                      setWebLink(e.target.value);
                      if (!validateLink(e.target.value)) {
                        setWebLinkError('Link is invalid');
                      } else setWebLinkError('');
                    }}
                    value={webLink}
                    label={'Web address*'}
                    placeholder={'https://www.orgvitals.com'}
                    errorText={webLinkError}
                  />
                )}
              </InputWrapperS>
            </BottomWrapperS>
            <TypeInfoRowS>
              <UploadedFileS>
                {!isLink && hookAttach.file && (
                  <>
                    <FileIcons fileName={hookAttach.file.name} />
                    <FileInfoS>
                      <span style={{ maxWidth: '100%' }}>
                        {hookAttach.file.name}
                      </span>
                      <ButtonDeleteS
                        onClick={() => {
                          hookAttach.setFile(null);
                        }}
                      >
                        Delete
                      </ButtonDeleteS>
                    </FileInfoS>
                  </>
                )}
              </UploadedFileS>
              {!isLink && (
                <FileCheckWrapperS>
                  <WrapperCsvInfoS>
                    <h4>Check your document </h4>
                    <ul>
                      <li>
                        <CheckIcon /> File extension must be .xls .xlsx .doc
                        .docx .gif .pdf .png .jpeg .jpg .csv
                      </li>
                      <li>
                        <CheckIcon /> Maximum upload file size: 15MB
                      </li>
                      <li>
                        <CheckIcon /> File label is required
                      </li>
                    </ul>
                  </WrapperCsvInfoS>
                </FileCheckWrapperS>
              )}
            </TypeInfoRowS>
          </div>
          {/* <DocxIcon />
          <XlsxIcon />
          <PdfIcon /> */}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonCancel onClick={closeModalHandler} />
            <ButtonSave
              onClick={() => saveHandler(activeType === 1 ? 'link' : 'file')}
              loading={hookAttach.loading}
            />
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const HeaderItemsWrapperS = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 28px;
  font-size: 16px;
`;
const InputWrapperS = styled.div`
  width: 50%;
  span {
    margin-top: 24px;
  }
`;
const BottomWrapperS = styled.div`
  display: flex;
  justify-content: space-between;

  /* min-height: 200px; */
`;
const TypeInfoRowS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin-top: 0;
  }
`;
const FileCheckWrapperS = styled.div`
  width: 40%;
  /* height: 150px; */
  /* position: absolute; */
  bottom: 0;
  right: 0;
`;
const UploadedFileS = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  svg {
    width: 40px;
    height: 50px;
    margin-right: 7px;
  }
`;
const FileInfoS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    font-weight: 600;
  }
`;
const ButtonDeleteS = styled.button`
  background: transparent;
  color: ${COLORS.danger};
  font-size: 14px;
  border: 0;
  margin: 0;
  padding: 0;
  &:hover {
    color: ${COLORS.dangerActive};
  }
  &:active {
    color: ${COLORS.danger};
  }
`;
