import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { TPackage } from '../../store/packages/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import {COLORS, COLORS_RGBA} from '../../utils/colors';
import { CheckIcon, CloseIcon, LinkIcon } from '../svgs';
import { Alert, Loader } from '../ui';
import { ButtonBorderedS, ButtonDefS, ButtonLinkS } from '../ui/buttons/styles';
import { CopySuccess } from '../ui/CopySuccess';
import { usePackageGenLink } from './hooks/usePackageGenLink';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';

interface PackageGenLinkProps {
  closeHandler: () => void;
  pack: TPackage;
}

export const PackageGenLink: React.FC<PackageGenLinkProps> = ({
  closeHandler,
  pack,
}) => {
  const { Workspaces } = useSelector((store: AppStore) => store);

    const {
      link,
      loading,
      genLink,
      errorText,
      expirationNum,
      setExpirationNum,
      oldExpirationNum,
      removeLink,
      pauseLink,
      isForever,
      isPaused,
      handleClick,
      isSelectDefaultRectangle,
      isSelectForeverRectangle
    } = usePackageGenLink(pack,  Workspaces.current?.id);



  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef<HTMLInputElement | null>(null);
  const [isCopied, setIsCopied] = React.useState(false);


  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  const copyToClipBoard = () => {
    if (!ref || !ref.current) return null;
    ref.current.select();
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderBody = () => {
    if (loading) {
      return <Loader isGreen />;
    }

    if (link) {
      return (
        <>
          <LinkWrapperS>
            <input
              type="text"
              ref={ref}
              value={link}
              name="link"
              readOnly
              onChange={() => null}
            />

            <ButtonDefS disabled={isForever && isPaused} onClick={copyToClipBoard}>Copy</ButtonDefS>
          </LinkWrapperS>
          <p style={{ marginTop: '20px' }}>The link will be available for</p>
          <DaysWrapperS>
            <DaysInputWrapperS isForever={isForever}>
              {isForever ? <span style={{marginRight: '10px', marginLeft: '10px', color: COLORS_RGBA.default(0.6)}}>Forever</span>
                  : <input
                      type="number"
                      value={expirationNum}
                      min={`${
                          pack.min_number_of_days !== 0 ? pack.min_number_of_days : 1
                      }`}
                      onChange={(e) => {
                        setExpirationNum(+e.target.value);
                      }}
                  />}
              <span style={{ color: COLORS_RGBA.default(0.6) }}>days</span>
            </DaysInputWrapperS>
            {oldExpirationNum.current !== expirationNum &&
            expirationNum !== 0 &&
            expirationNum >= pack.min_number_of_days ? (
              <>
                <ButtonBorderedS
                  className="btnCancel"
                  onClick={() => setExpirationNum(oldExpirationNum.current)}
                >
                  <CloseIcon />
                </ButtonBorderedS>
                <ButtonDefS
                  className="btnSave"
                  onClick={genLink}
                  style={{ height: '50px' }}
                >
                  <CheckIcon />
                </ButtonDefS>
              </>
            ) : null}


              <div
                style={{
                  flex: 1,
                  display: ' flex',
                  justifyContent: 'flex-start',
                  paddingLeft: '20px',
                }}
              >
                {!pack.is_has_delayed_message && (
                    <ButtonBorderedS
                    isDanger
                    style={{ width: 'initial', marginRight: '20px' }}
                    onClick={removeLink}
                >
                  Remove
                </ButtonBorderedS>
                )}

                {isForever && (<ButtonBorderedS
                    style={{ width: 'initial' }}
                    onClick={pauseLink}
                    isYellow={true}
                >
                  {isPaused ? 'Activate' : 'Temporary Disable'}
                </ButtonBorderedS>
                )}
              </div>

            {isCopied ? <CopySuccess /> : null}
          </DaysWrapperS>
        </>
      );
    }
    return (
        <>
      <ContainerS>
        <ContainerTitleS>Select the link type:</ContainerTitleS>
        <ContainerSelectRectangleS>
          <SelectRectangleS onClick={() => handleClick('default') } isSelect={isSelectDefaultRectangle}>
            <span style={{fontWeight: 'bold'}}>Date-Based Survey:</span> survey packages that have a start date and an end date.
          </SelectRectangleS>
          <SelectRectangleS onClick={() => handleClick('forever')} isSelect={isSelectForeverRectangle}>
            <span style={{fontWeight: 'bold'}}>Open Surveys:</span> survey packages that run forever until they are turned off.
            <br/> These are typically used for automation for events like new hire onboarding surveys.
          </SelectRectangleS>
        </ContainerSelectRectangleS>
      </ContainerS>
      <ButtonDefS disabled={isSelectDefaultRectangle || isSelectForeverRectangle ? null : true}  style={{ marginTop: '20px' }} onClick={genLink}>
        <span style={{ paddingRight: '12px' }}>
          <LinkIcon color="#fff" />
        </span>
        Generate a link
      </ButtonDefS>
        </>
    );
  };
  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '616px', minHeight: '289px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <BtnCloseS
          style={{ zIndex: 1 }}
          className="closeModalBtn"
          onClick={closeModalHandler}
        >
          <CloseIcon />
        </BtnCloseS>
        <Styles.ModalBodyS style={{ paddingTop: '20px' }}>
          <Styles.ModalTileS>Copy Link</Styles.ModalTileS>
          <p>{pack.name}</p>
          {Workspaces.errors ? <Alert text={Workspaces.errors} /> : null}
          {renderBody()}
          {errorText ? <Alert text={errorText} /> : null}
        </Styles.ModalBodyS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};

export const LinkWrapperS = styled.div`
  display: flex;
  align-items: center;
  & > div {
    &:first-child {
      flex: 1;
    }
  }
  input {
    font-size: 1.3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button {
    width: initial;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 48px;
  }
`;

export const DaysWrapperS = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  .btnCancel,
  .btnSave {
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
    min-width: 36px;
    min-height: 36px;
    padding: 0;
    margin-left: 10px;
  }

  .btnSave {
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
export const DaysInputWrapperS = styled.div<{ isForever?: boolean }>`
  display: flex;
  height: ${({ isForever }) => (isForever ? '42px' : '')};
  align-items: center;
  border: 1px solid ${COLORS_RGBA.default(0.2)};
  border-radius: 6px;
  padding-right: 16px;
  input {
    border: 0;
    width: 100px;
  }
`;

const BtnCloseS = styled(ButtonLinkS)`
  position: absolute;
  opacity: 0.5;
  right: 20px;
  top: 20px;
`;

const ContainerS = styled.div`
   display: flex;
   flex-direction: column;
   gap: 10px;
`;

const ContainerTitleS = styled.div`
    padding: 20px;
    width: 100%;
    height: 20px;
    background-color: white;
    display: inline;
    text-align: center;
`;

const SelectRectangleS = styled.div<{ isSelect: boolean }>`
    padding: 20px;
    width: 100%;
    // height: 100px;
    background-color: white;
    display: inline;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;
    border: 2px solid;
    border-radius: 6px;
    border-color: ${({ isSelect }) => (isSelect ? COLORS.accent : COLORS_RGBA.default(0.2))};
 
`;

const ContainerSelectRectangleS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`