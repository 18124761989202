import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { TParticipant } from '../../store/participants/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS } from '../../utils/colors';
import { Loader } from '../ui';
import { ParticipantRow } from './ParticipantRow';
import * as Styles from './styles/TableStyles';
import { TableHeader } from './TableHeader';
import { colNames } from './types/columnNames';
import { TSortBy, TSortType } from './types/sort';

interface TableProps {
  editParticipantHandler: (participant: TParticipant, position: number) => void;
  loadParticipantHandler: () => void;
    loadCurrentParticipantHandler: () => void;
  searchValue: string;
  moreHandler: (participant: TParticipant) => void;
  setSortType: (sortType: TSortType) => void;
  setSortBy: (sortBy: TSortBy) => void;
  sortType: TSortType;
  sortBy: TSortBy;
}

export const Table: React.FC<TableProps> = ({
    editParticipantHandler,
    loadParticipantHandler,
    loadCurrentParticipantHandler,
    searchValue,
    moreHandler,
    setSortType,
    setSortBy,
    sortType,
    sortBy
}) => {
  const {  Participants, Groups, Workspaces } = useSelector(
      (store: AppStore) => store
  );

    React.useEffect(() => {
        if (!Participants.loading && Participants.data.length > 0) {
            if (Participants.data.length >= 100) {
                // setRightKey(Participants.data[Participants.data.length-50].id)
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                loadParticipantHandler()
                                if (targetElement) {
                                    observer.unobserve(targetElement);
                                }
                            }
                        });
                    },
                    {
                        root: null,
                        rootMargin: '0px',
                        threshold: 1.0,
                    }
                );

                const targetElement = document.querySelector(`.target-${Participants.data[Participants.data.length-50].id}`);
                if (targetElement) {
                    observer.observe(targetElement);
                }

                return () => {
                    if (targetElement) {
                        observer.unobserve(targetElement);
                    }
                };
            }
        }
        // eslint-disable-next-line
    }, [Participants])

  const renderParticipants = React.useMemo(() => {
    const isEmpty =
        !Participants.data ||
        (Participants.data &&
            Array.isArray(Participants.data) &&
            !Participants.data[0]);

    if (Participants.loading && isEmpty) return null;

    if (isEmpty) {
      return (
          <Styles.TrS>
            <Styles.TdS>You don`t have participants yet</Styles.TdS>
          </Styles.TrS>
      );
    }

    if (searchValue.length > 2 && !Participants.data[0]) {
      return (
          <Styles.TrS>
            <Styles.TdS
                name={colNames[1]}
                style={{ width: '30%', position: 'relative' }}
            >
              <p>No results</p>
            </Styles.TdS>
          </Styles.TrS>
      );
    }

    return Participants.data.map((participant, i) => (
        <ParticipantRow
            className={'target-' + participant.id}
            groups={Groups.data || []}
            allParticipants={Participants.data || []}
            key={participant.id}
            participant={participant}
            editParticipantHandler={() => editParticipantHandler(participant, i)}
            position={i}
            isHrisConnected={
                Workspaces.current?.finch_integration &&
                !!Workspaces.current.finch_integration[0]
            }
            moreHandler={moreHandler}
            loadCurrentParticipantHandler={loadCurrentParticipantHandler}
        />
    ));
      // eslint-disable-next-line
  }, [
    Participants.data,
    Participants.loading,
    Groups.data,
    editParticipantHandler,
    searchValue,
    Workspaces,
    moreHandler,
  ]);

  return (
      <>
        <Styles.TableS cellSpacing={0} className={AnimatedClasses.fadeIn}>
          <TableHeader
              sortedBy={sortBy}
              sortType={sortType}
              sortHandler={(st, sb) => {
                setSortBy(sb);
                setSortType(st);
              }}
          />
          <Styles.TBodyS>{renderParticipants}</Styles.TBodyS>
        </Styles.TableS>

        {Participants.loading ? <Loader color={COLORS.accent} /> : null}
      </>
  );
};
