import React from 'react';
import { Alert, Input, Loader } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import { IConsultants } from './ModalConsultants';
import * as Styles from './modalStyles';
import { useModalConsultants } from './hooks/useModalConsultants';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
interface ResetPasswordProps {
  currentConsultant: IConsultants;
  closeHandler: () => void;
}
export const ResetPassword: React.FC<ResetPasswordProps> = ({
  currentConsultant,
  closeHandler,
}) => {
  const { changePassHandler, errorText, isDone, isLoading, setErrorText } =
    useModalConsultants();
  const [pass, setPass] = React.useState('');
  const [passRepeat, setPassRepeat] = React.useState('');
  const [isCopied, setIsCopied] = React.useState(false);
  return (
    <Styles.ModalInnerWrapperS style={{ maxWidth: '568px' }}>
      <Styles.ModalHeaderS
        style={{
          background: '#f6f8f6',
          marginBottom: '20px',
          paddingBottom: '20px',
        }}
      >
        <Styles.ModalTileS>Change member password</Styles.ModalTileS>
      </Styles.ModalHeaderS>
      <Styles.ModalBodyS>
        {!isDone ? (
          <InputWrapperS
            onKeyDown={(e: { keyCode: number; preventDefault: () => void }) => {
              if (e.keyCode === 13 && pass) {
                e.preventDefault();
                changePassHandler(currentConsultant, pass, passRepeat);
              }
            }}
          >
            <Input
              placeholder={'Member password'}
              label={`New Password`}
              value={pass}
              onChange={(e) => {
                setPass(e.currentTarget.value.replace(/\s/g, ''));
                if (errorText) {
                  setErrorText('');
                }
              }}
              autoFocus
            />
            <Input
              value={passRepeat}
              onChange={(e) => {
                setPassRepeat(e.currentTarget.value.replace(/\s/g, ''));
                if (errorText) {
                  setErrorText('');
                }
              }}
              label={'Confirm New Password'}
              placeholder={'Member password'}
            />
          </InputWrapperS>
        ) : (
          <WrapperClipS>
            <h2>Password was successfully updated</h2>
            <p>Email: {currentConsultant.email}</p>
            <p> Password: {pass}</p>

            {!isCopied ? (
              <BtnsWrapperS>
                <ButtonDefS
                  onClick={() => {
                    setIsCopied(true);
                    navigator.clipboard.writeText(
                      `email: ${currentConsultant.email}, password: ${pass}`
                    );
                  }}
                >
                  Copy
                </ButtonDefS>
              </BtnsWrapperS>
            ) : (
              <>
                <p
                  style={{
                    color: COLORS.accent,
                    fontWeight: 'bold',
                    height: '32px',
                    marginTop: '20px',
                  }}
                >
                  Email and password was copied
                </p>
              </>
            )}
          </WrapperClipS>
        )}
        {errorText && <Alert text={errorText} />}
      </Styles.ModalBodyS>
      <Styles.ModalFooterS>
        <Styles.BtnsWrapperS>
          <ButtonBorderedS isCancel onClick={closeHandler}>
            {!isDone ? 'Cancel' : 'Close'}
          </ButtonBorderedS>
          {!isDone && (
            <ButtonDefS
              onClick={() => {
                changePassHandler(currentConsultant, pass, passRepeat);
              }}
              style={{ minWidth: 100 }}
            >
              {isLoading ? <Loader /> : 'Reset'}
            </ButtonDefS>
          )}
        </Styles.BtnsWrapperS>
      </Styles.ModalFooterS>
    </Styles.ModalInnerWrapperS>
  );
};
const WrapperClipS = styled.div`
  background: #0f513217;
  padding: 20px;
  max-width: 500px;
  p {
    margin: 0;
  }
  h2 {
    margin: 0 0 20px;
    font-size: 20px;
  }
  button {
    width: 100px;
    min-height: 30px;
    height: 30px;
    font-size: 14px;
    margin-top: 20px;
  }
`;
const InputWrapperS = styled.div`
  width: 350px;
  margin: 0 auto;
  span {
    margin-top: 10px;
  }
`;
const BtnsWrapperS = styled.div`
  display: flex;
  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
