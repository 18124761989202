/* eslint-disable no-useless-escape */
export function validateEmail(str: string) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(str);
}
export function validatePhone(str: string) {
  return /^[\+][0-9]{6,15}$/im.test(str);
}
export function validateLink(str: string) {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
    str
  );
}
export function checkLinkForHttp(str: string) {
  return /^(http(s)?)/.test(str);
}
export function delay(ms: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
}

export function getCutText(text: string, size: number): string {
  if (!text || typeof text !== 'string') {
    return '';
  }
  if (text.length < size) {
    return text;
  }

  return text.slice(0, size) + '...';
}
