import React from 'react';
import { mainClass, SelectClasses } from '../../utils/animatedStyles';
import { COLORS } from '../../utils/colors';
import { ROUTE_PATH } from '../../utils/routes';
import { ButtonAdd, ButtonArrow, Loader } from '../ui';
import { useWorkspaceSelect } from './hooks/useWorkspaceSelect';
import * as Styles from './WSstyles';
import { WorkspaceAvatarIcon } from '../svgs/WorkspaceAvatarIcon';

interface WorkSpaceSelectProps {
  createNewFn: () => void;
}

export const WorkSpaceSelect: React.FC<WorkSpaceSelectProps> = ({
  createNewFn,
}) => {
  const {
    Workspaces,
    ref,
    setIsShown,
    isShown,
    modalRef,
    listHandler,
    hideList,
    // deleteWorkspaceHandler,
    userWorkspaces,
    removedItems,
    setRefs,
  } = useWorkspaceSelect();

  const HtmlWorkspaces = React.useMemo(() => {
    if (!Workspaces.data) return null;
    return Workspaces.data.map((workspace) => {
      if (`${workspace.id}` === `${Workspaces.current?.id || ''}`) return null;
      let isLoading = removedItems[workspace.id];
      const hasRights = userWorkspaces.includes(workspace.id);
      return (
        <li
          key={workspace.id}
          ref={setRefs}
          style={hasRights ? {} : { opacity: 0.2, cursor: 'default' }}
        >
          {isLoading ? <Loader color={COLORS.accent} size={0.5} /> : null}

          <span
            style={isLoading ? { opacity: 0.2 } : {}}
            onClick={() => listHandler(workspace)}
          >
            {workspace.name}
          </span>
          {/* {isLoading ? null : (
            <ButtonClose onClick={() => deleteWorkspaceHandler(i, workspace.id)} />
          )} */}
        </li>
      );
    });
  }, [Workspaces, removedItems, listHandler, setRefs, userWorkspaces]);

  return (
    <Styles.WSWrapperS ref={ref}>
      <Styles.WrapperS onClick={() => setIsShown(true)}>
        <Styles.NameImageWrapperS>
          <Styles.ImageS>
            <WorkspaceAvatarIcon />
            {Workspaces.current?.logo_url !== '' && (
              <Styles.ImageInnerS
                style={{
                  backgroundImage: `url("${
                    Workspaces.current?.logo_url || ''
                  }")`,
                }}
              />
            )}
          </Styles.ImageS>
          <span>
            {Workspaces.loading && !Workspaces.current ? (
              <Loader color={COLORS.accent} size={0.5} />
            ) : null}

            {Workspaces.current?.name || ''}
          </span>
        </Styles.NameImageWrapperS>
        <ButtonArrow onClick={() => null} active={isShown} />
      </Styles.WrapperS>

      {!isShown || !Workspaces.current ? null : (
        <Styles.WSModalS
          ref={modalRef}
          className={`${mainClass} ${SelectClasses.in}`}
        >
          <Styles.NameImageWrapperS>
            <Styles.ImageS>
              <WorkspaceAvatarIcon />
              {Workspaces.current?.logo_url !== '' && (
                <Styles.ImageInnerS
                  style={{
                    backgroundImage: `url("${
                      Workspaces.current?.logo_url || ''
                    }")`,
                  }}
                />
              )}
            </Styles.ImageS>
            <span>{Workspaces.current?.name || ''}</span>
          </Styles.NameImageWrapperS>

          <Styles.SettingS to={ROUTE_PATH.workspaceSettings} onClick={hideList}>
            Workspace Settings
          </Styles.SettingS>

          <Styles.ListItemsS>
            {HtmlWorkspaces}
            {/* {Workspaces.data &&
              Array.isArray(Workspaces.data) &&
              Workspaces.data.map((workspace, i) => {
                if (`${workspace.id}` === `${Workspaces.current?.id || ''}`) return null;
                const isLoading = removedItems[workspace.id];
                
                return (
                  <li key={workspace.id} ref={setRefs}>
                    {isLoading ? <Loader color={COLORS.accent} size={0.5} /> : null}

                    <span
                      style={isLoading ? { opacity: 0.2 } : {}}
                      onClick={() => listHandler(workspace)}
                    >
                      {workspace.name}
                    </span>
                    {isLoading ? null : (
                      <ButtonClose onClick={() => deleteWorkspaceHandler(i, workspace.id)} />
                    )}
                  </li>
                );
              })} */}
          </Styles.ListItemsS>
          {/* {Workspaces.errors ? (
            <div style={{ padding: '0 18px' }}>
              <Alert text={Workspaces.errors} />
            </div>
          ) : null} */}

          <ButtonAdd
            onClick={() => {
              createNewFn();
              hideList().then(() => null);
            }}
            title="Add new Workspace"
          />
        </Styles.WSModalS>
      )}
    </Styles.WSWrapperS>
  );
};
