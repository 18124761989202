import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../utils/colors';

export interface RadioItemType {
  label: string;
  id: number | string;
}

interface RadioProps {
  title?: string;
  horizontal?: boolean;
  data: RadioItemType[];
  activeId: RadioItemType['id'];
  clickHandler: (id: RadioItemType['id'], index: number) => void;
}

export const Radio: React.FC<RadioProps> = ({
  title,
  data,
  clickHandler,
  activeId,
  horizontal,
}) => {
  return (
    <WrapperS horizontal={horizontal} className="ov-radio">
      {title && <TitleS>{title}</TitleS>}
      {data &&
        data.map(({ label, id }, index) => (
          <RadioWrapperS
            key={id}
            onClick={() => clickHandler(id, index)}
            horizontal={horizontal}
          >
            <Circle isActive={id === activeId} />
            {label && <LabelS>{label}</LabelS>}
          </RadioWrapperS>
        ))}
    </WrapperS>
  );
};

const WrapperS = styled.div<{ horizontal: boolean }>`
  display: ${({ horizontal }) => (horizontal ? 'flex' : 'block')};
`;

const TitleS = styled.h3`
  font-weight: bold;
  margin: 0;
`;

const RadioWrapperS = styled.div<{ horizontal: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: ${({ horizontal }) => (horizontal ? '0 0 0 21px' : '10px 0')};

  &:hover {
    color: ${COLORS.accent};
  }
  &:active {
    color: ${COLORS.accentActive};
  }
`;
const LabelS = styled.div`
  flex: 1;
  margin-left: 12px;
`;
const Circle = styled.span<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
  border: 1px solid rgba(151, 151, 151, 0.3);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    width: 50%;
    background: ${COLORS.accent};
    height: 50%;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;
