import { TGroup } from '../../store/groups/types';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import {HashTable, TSelectedField} from './useDropFile';
import {IMatchingTemplates} from "../../store/matchingTemplates/types";

export interface IColumns {
  [key: string]: any;
  // [key: string]: SelectItemType | string | number | IMatchingTemplateGroupData[];
}
interface ISelectProps {
  label: string;
  name: string;
  columns: string[];
  groups: TGroup[];
  isFirstRowHeader: boolean;
  isUseMatchingTemplate?: boolean;
  isExistFields?: object;
  isExistField?: number;
  selectExistFieldHandler?: (name: string) => void;
  selectedItems?: IColumns;
  setIsExistMatchingFieldsHandler?: (fieldName: string, fieldValue: number) => void;
  openSelectHandler: () => void;
  groupsValue?: HashTable,
  setGroupsValue?: (hashTable : HashTable) => void;
}

export interface ColumnSelectProps extends ISelectProps {
  selectHandler: (e: SelectItemType) => void;
}
export interface PhoneSelectProps extends ISelectProps {
  selectHandler: (e: SelectItemType) => void;
  codeSelectHandler: (e: SelectItemType) => void;
  codeIsExistField?: number;
  codeSelectExistFieldHandler?: (name: string) => void;
  setphoneType: React.Dispatch<React.SetStateAction<number>>;
  codeField: TSelectedField;
  phoneCode: string;
  setPhoneCode: React.Dispatch<React.SetStateAction<string>>;
}
export interface DateTypeSelectProps extends ISelectProps {
  selectHandler: (e: SelectItemType) => void;
  codeSelectHandler: (e: SelectItemType) => void;
  codeField: TSelectedField;
  dateType: string;
  setDateType: React.Dispatch<React.SetStateAction<string>>;
}
export interface TSendGroup {
  title: string;
  single: boolean;
  multiple: boolean;
  input_value: string;
  input_value_delimiter_char: string;
  column_idx: string;
  group_id: string;
}

export interface IMatchingTemplateGroupData {
  single: boolean;
  multiple: boolean;
  input_value: string;
  input_value_delimiter_char: string;
  column: string;
  group_id: string;
}

export interface ISendData {
  is_save_template: boolean;
  delimiter_char: string;
  comment_char: string;
  first_row_is_header: boolean;
  first_name_col_idx: string;
  last_name_col_idx: string;
  full_name_col_idx: string;
  email_col_idx: string;
  phone_col_idx: string;
  phone_code_col_idx: string;
  payroll_id_col_idx: string;
  hiring_date_col_idx: string;
  hiring_date_format: string;
  ethnicity_col_idx: string;
  gender_col_idx: string;
  workspace_id: string;
  phone_code: string;
  groups: TSendGroup[];
  matching_template_data: IMatchingTemplates;
}

export interface GroupSelectProps extends ISelectProps {
  csvData: string[][];
  selectHandler: (data: TSendGroup) => void;
}

export enum EHeaderOrValue {
  value = 2,
  header = 1,
}
export enum ESingleOrMultiply {
  multiply = 2,
  single = 1,
}

export const selectHeaderOrValue: SelectItemType[] = [
  {
    title: 'Header',
    value: 'header',
    id: EHeaderOrValue.header,
  },
  {
    title: 'Value',
    value: 'value',
    id: EHeaderOrValue.value,
  },
];
export const selectSingleOrMultiply: SelectItemType[] = [
  {
    title: 'Single Value Per Cell',
    value: 'single',
    id: ESingleOrMultiply.single,
  },
  {
    title: 'Multiple Values Inside a Cell',
    value: 'multiple',
    id: ESingleOrMultiply.multiply,
  },
];

export enum EExistOrDoesntExistOrMissing {
  exist = 1,
  doesntExist = 2,
  missing = 3,
}