import React from 'react';
import styled from 'styled-components';
import { CSS_MEDIA } from '../../../utils/media';
import { BackBtn } from './BackBtn';
import { StepsTracking } from './StepsTracking';
import { TLang } from '../../../types/lang';
import { checkLang } from '../assLang';
import { langIsAllingRight } from '../../../utils/langIsEng';

interface HeaderProps {
  step?: number;
  backHandler: () => void;
  title?: string;
  stepsCount?: number;
  lang: TLang;
  isAnonymous: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  backHandler,
  title,
  step,
  stepsCount,
  lang,
  isAnonymous,
}) => {
  console.log(step)
  return (
    <WrapperS>
      <BtnTitleS alignRight={langIsAllingRight(lang)}>
        <BackBtn
          onClick={backHandler}
          title={checkLang(lang).assessments.btns.back}
        />
        {title && <h5>{title}</h5>}
      </BtnTitleS>
      {step && stepsCount && (
        <StepsTracking isAnonymous={isAnonymous} lang={lang} total={stepsCount} current={step} />
      )}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  padding: 25px 35px;
  z-index: 2;
  ${CSS_MEDIA.maxMd} {
    padding: 25px 10px;
    background: white;
  }
`;

const BtnTitleS = styled.div<{ alignRight: boolean }>`
  display: flex;
  align-items: center;
  h5 {
    margin: 0 0 0 27px;
    font-size: 20px;
    max-width: 250px;
    overflow-wrap: break-word;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434352;
    direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
    ${CSS_MEDIA.maxMd} {
      display: none;
    }
  }
`;
