import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { AppStore } from '../../store/applicationState';
import { logOutR } from '../../store/user/actions';
import { mainClass, SelectClasses } from '../../utils/animatedStyles';
import { delay } from '../../utils/helpers';
import { ROUTE_PATH } from '../../utils/routes';
import { LinkS } from '../LeftSideBar/MenuStyles';
import { TeamIcon } from '../svgs';
import { ButtonArrow, UserImage } from '../ui';
import * as Styles from './UserSelectStyles';

interface UserSelectProps {
  onlyLogout?: boolean;
}

export const UserSelect: React.FC<UserSelectProps> = ({ onlyLogout }) => {
  const { Team, User } = useSelector((store: AppStore) => store);
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {};
  }, []);

  const hideList = async () => {
    if (modalRef && modalRef.current) {
      modalRef.current.classList.remove(SelectClasses.in);
      modalRef.current.classList.add(SelectClasses.out);
      await delay(100);
      setIsOpen(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    hideList();
  });

  return (
    <Styles.WrapperS ref={ref}>
      <Styles.UserNameImageWrapperS onClick={() => setIsOpen(true)}>
        <UserImage src={Team.data?.logo_url || ''} />
        <span>{Team.data?.name}</span>
      </Styles.UserNameImageWrapperS>

      <ButtonArrow onClick={() => setIsOpen(true)} active={isOpen} />

      {/* <Styles.ArrowIconS onClick={() => setIsOpen(true)} active>
        <ArrowIcon />
      </Styles.ArrowIconS> */}

      {isOpen ? (
        <Styles.WrapperItemsS ref={modalRef} className={`${mainClass}`}>
          {!onlyLogout && (
            <>
              <Styles.UserNameImageWrapperS style={{ padding: '16px' }}>
                <UserImage src={User.data?.profile_picture_url || ''} />
                <span>
                  {User.data?.first_name} {User.data?.last_name} <br />
                  <span style={{ opacity: '0.5', fontSize: '14px' }}>
                    {' '}
                    {User.data?.email}
                  </span>
                </span>
              </Styles.UserNameImageWrapperS>
              <LinkS to={ROUTE_PATH.myAccount} onClick={hideList}>
                <span>My account</span>
              </LinkS>
              <DividerS />
              <LinkS
                to={ROUTE_PATH.consultingTeam}
                onClick={hideList}
                className="teamLink"
              >
                <TeamSpanS>
                  <TeamIcon />
                  Team
                </TeamSpanS>
              </LinkS>
              <DividerS />
            </>
          )}
          {/* <LinkS to="/">
            <span>Settings</span>
          </LinkS>
          <div style={{ padding: '0 16px' }}>
            <hr />
          </div> */}
          <LinkS
            to="/"
            className="logOutLink"
            onClick={() => {
              dispatch(logOutR());
              hideList();
            }}
          >
            {/* <LogOutIcon /> */}
            <span>Log out</span>
          </LinkS>
        </Styles.WrapperItemsS>
      ) : null}
    </Styles.WrapperS>
  );
};
const TeamSpanS = styled.div`
  display: flex;
  align-items: center;
`;
const DividerS = styled.div`
  width: 252px;
  height: 1px;
  margin: 4px 16px;
  border-radius: 0.5px;
  background-color: rgba(0, 0, 0, 0.12);
`;
