import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import {
  DeletePackage,
  setPackage,
  setPackageEditMode,
} from '../../store/packages/actions';
import { TPackage } from '../../store/packages/types';
import { AreYouShurePopUp } from '../modals';
import * as RSStyles from '../RightSideBar/RightSideBarStyles';
import { Alert, ButtonAdd, Loader } from '../ui';
import { Info, QuestionsBox } from './sideBarComponents';

interface RightSideBarProps {
  newPackageHandler: () => void;
}
export const RightSideBar: React.FC<RightSideBarProps> = ({
  newPackageHandler,
}) => {
  const { Packages } = useSelector((store: AppStore) => store);
  const [isDeletePopup, setIsDeletePopup] = React.useState(false);
  const [deletePack, setDeletePack] = React.useState<TPackage | null>(null);
  const dispatch = useDispatch();
  const loadingItems = React.useRef<{
    [key: string]: boolean;
  }>({});

  const EmptyHtml = (
    <RSStyles.RightSideBarEmptyTextS>
      Create a package and move there assessments you want to schedule for your
      workspaces
    </RSStyles.RightSideBarEmptyTextS>
  );
  const isEmpty = !Packages.data || !Packages.data[0];

  const deletePackageHandler = (pack: TPackage) => {
    loadingItems.current![pack.id] = true;
    const callBack = (success: boolean) => {
      delete loadingItems.current![pack.id];
      if (!success) return null;
      if (!Packages.data) return null;

      const newPackages = Packages.data.filter((p) => p.id !== pack.id);
      return dispatch(DeletePackage.success(newPackages));
    };

    dispatch(
      DeletePackage.request({
        id: pack.id,
        callBack,
      })
    );
  };

  const Body = (
    <>
      {Packages.errors ? <Alert text={Packages.errors} /> : null}

      <Info />

      {Packages.data &&
        Array.isArray(Packages.data) &&
        Packages.data.map((pack) => {
          return (
            <QuestionsBox
              pack={pack}
              key={pack.id}
              clickHandler={() => dispatch(setPackage(pack))}
              loading={loadingItems.current[pack.id]}
              deleteHandler={() => {
                if (!pack.is_has_delayed_message) {
                  deletePackageHandler(pack);
                } else {
                  setIsDeletePopup(true);
                  setDeletePack(pack);
                }
              }}
              editHandler={() => dispatch(setPackageEditMode(pack))}
            />
          );
        })}
    </>
  );

  const renderBody = () => {
    if (!Packages.data && Packages.loading) return <Loader isGreen />;
    if (Packages.data && !Packages.data[0] && Packages.loading)
      return <Loader isGreen />;
    if (isEmpty) return EmptyHtml;
    return Body;
  };
  return (
    <>
      <RSStyles.RightSideBarWrapperS>
        <HeaderS>
          <TitleS>Packages</TitleS>
          <ButtonAdd
            title="New Package"
            onClick={newPackageHandler}
            isIconFolder
          />
        </HeaderS>

        <BodyS isEmpty={isEmpty}>{renderBody()}</BodyS>
      </RSStyles.RightSideBarWrapperS>
      {isDeletePopup && deletePack && (
        <AreYouShurePopUp
          title={
            'Warning: you are about to delete a package with scheduled emails to participants. Are you sure you want to delete this package and terminate these emails?'
          }
          yesClick={() => {
            deletePackageHandler(deletePack);
            setIsDeletePopup(false);
            setDeletePack(null);
          }}
          noClick={() => {
            setIsDeletePopup(false);
            setDeletePack(null);
          }}
          agreeText={'Yes, delete'}
          disagreeText={'No, leave it'}
          width={500}
          height={130}
        />
      )}
    </>
  );
};

const HeaderS = styled(RSStyles.RightSideBarHeaderS)`
  button {
    max-width: 148px;
  }
`;

const TitleS = styled(RSStyles.RightSideBarTitleS)`
  margin-right: 5px;
`;
const BodyS = styled(RSStyles.RightSideBarBodyS)``;
