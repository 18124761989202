import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import { COLORS } from '../../../utils/colors';
import { delay } from '../../../utils/helpers';
import { VALUES } from '../../../utils/valueConst';
import { ArrowIcon } from '../../svgs';
import { Loader } from '../Loader';
import { ListItemsS, ItemListS } from './ButtonMore';
import { ISelectItemData } from './types';

interface ButtonWithOptionsProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  data: ISelectItemData[];
  onAdditionalClick: (
    itm: ISelectItemData['value'],
    subItem?: ISelectItemData['value']
  ) => void;
  title?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  disabled?: boolean;
}

export const ButtonWithOptions: React.FC<ButtonWithOptionsProps> = ({
  onClick,
  title = 'Schedule',
  type = 'submit',
  loading,
  disabled,
  data,
  onAdditionalClick,
}) => {
  const [isShow, setIsShown] = React.useState(false);
  const [countOfElements, setCountOfElements] = React.useState<number>(0);

  const listRef = React.useRef<HTMLUListElement>(null);

  React.useEffect(() => {
    setCountOfElements(data.length * 100)
  }, [data]);

  React.useEffect(() => {
    return () => {
      setIsShown(false);
    };
  }, []);

  const hideList = async () => {
    if (listRef && listRef.current) {
      listRef.current.classList.remove(SelectClasses.in);
      listRef.current.classList.add(SelectClasses.out);
      await delay(100);

      setIsShown(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    if (isShow) {
      hideList();
    }
  });

  return (
    <WrapperS ref={ref}>
      <ButtonFirstHalfS
        onClick={loading ? () => null : onClick}
        type={type}
        disabled={disabled}
      >
        {loading ? <Loader size={0.5} /> : null}
        <span style={loading ? { opacity: 0 } : {}}>{title}</span>
      </ButtonFirstHalfS>
      <ButtonSecondHalfS onClick={() => setIsShown(true)}>
        <ArrowIconWrapperS isShow={isShow}>
          <ArrowIcon />
        </ArrowIconWrapperS>
      </ButtonSecondHalfS>
      {isShow && (
        <OptionsListS  countElements={-countOfElements}
          ref={listRef}
          className={`${mainClass} ${SelectClasses.in}`}
        >
          {data.map((lItem) => (
            <ItemListS
              key={lItem.id}
              isRed={lItem.value === VALUES.delete}
              onClick={() => {
                if (lItem.value === VALUES.move) return null;
                onAdditionalClick(lItem.value);
                return hideList();
              }}
              disabled={
                (lItem.value === VALUES.edit && disabled) ||
                (lItem.value === VALUES.delete && disabled)
              }
            >
              {lItem.title}
              <OptionsListS countElements={-countOfElements}>
                {lItem.subItems &&
                  lItem.subItems.map((subItm) => (
                    <ItemListS
                      key={subItm.id}
                      onClick={() => {
                        onAdditionalClick(lItem.value, subItm.value);
                        hideList();
                      }}
                    >
                      {subItm.title}

                    </ItemListS>
                  ))}
              </OptionsListS>
            </ItemListS>
          ))}
        </OptionsListS>
      )}
    </WrapperS>
  );
};

const WrapperS = styled.div`
  position: relative;
  margin-left: 16px;
  display: flex;
`;

const ArrowIconWrapperS = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transform: ${({ isShow }) =>
      isShow ? 'rotate(180deg)' : 'rotate(270deg)'};
    g {
      opacity: 1;
      path {
        fill: white;
      }
    }
  }
`;

const OptionsListS = styled(ListItemsS)<{ countElements: number }>`
  top: ${({ countElements }) => `${countElements}%`};
`;

const ButtonFirstHalfS = styled.button`
  width: 100%;
  background: ${COLORS.accent};
  color: white;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 0 18px;
  position: relative;
  border: 1px solid ${COLORS.accent};
  height: 42px;
  transition: 0.2s;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  .orgvitals-loader {
    transform: scale(0.6) !important;
  }

  &:hover {
    background: ${COLORS.accentHover};
    border: solid 1px ${COLORS.accentHover};
  }

  &:active {
    background: ${COLORS.accentActive};
    border: solid 1px ${COLORS.accentActive};
  }

  &:disabled {
    opacity: 0.3;
    cursor: pointer;
  }
`;

const ButtonSecondHalfS = styled.button`
  width: 100%;
  margin-left: 0 !important;
  background: ${COLORS.accent};
  color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 10px;
  position: relative;
  border: 1px solid ${COLORS.accent};
  border-left: 1px solid ${COLORS.secondary};
  height: 42px;
  transition: 0.2s;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  .orgvitals-loader {
    transform: scale(0.6) !important;
  }

  &:hover {
    background: ${COLORS.accentHover};
    border: solid 1px ${COLORS.accentHover};
  }

  &:active {
    background: ${COLORS.accentActive};
    border: solid 1px ${COLORS.accentActive};
  }

  &:disabled {
    opacity: 0.3;
    cursor: pointer;
  }
`;
