import React from 'react';
import styled from 'styled-components';
import { EQuestionType, TQuestionType } from '../../../store/assessments/types';

interface CustomLinesProps {
  value1: number;
  value2: number;
  value3: number;
  value4: number;
  value5: number;
  answer_type: TQuestionType;
}
export const CustomLines: React.FC<CustomLinesProps> = ({
  value1,
  value2,
  value3,
  value4,
  value5,
  answer_type,
}) => {
  const add_s = (num: number) => (num > 1 ? 's' : '');
  const isReversed = answer_type === EQuestionType.five_reverse;
  return (
    <BlockLineWrapperS>
      <LineS fl={value1}>
        <InfoHoverS>
          {isReversed ? 'Strongly Agree' : 'Strongly Disagree'} <br /> {value1}{' '}
          response
          {add_s(value1)}
        </InfoHoverS>
      </LineS>
      <LineS fl={value2}>
        <InfoHoverS>
          {isReversed ? 'Somewhat Agree' : 'Somewhat Disagree'} <br /> {value2}{' '}
          response
          {add_s(value2)}
        </InfoHoverS>
      </LineS>
      <LineS fl={value3}>
        <InfoHoverS>
          Neither Agree nor Disagree <br /> {value3} response
          {add_s(value3)}
        </InfoHoverS>
      </LineS>
      <LineS fl={value4}>
        <InfoHoverS>
          {isReversed ? 'Somewhat Disagree' : 'Somewhat Agree'} <br /> {value4}{' '}
          response
          {add_s(value4)}
        </InfoHoverS>
      </LineS>
      <LineS fl={value5}>
        <InfoHoverS>
          {isReversed ? 'Strongly Disagree' : 'Strongly Agree'} <br /> {value5}{' '}
          response
          {add_s(value5)}
        </InfoHoverS>
      </LineS>
    </BlockLineWrapperS>
  );
};

const InfoHoverS = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 75px;
  min-height: 55px;
  padding: 5px 2px;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  bottom: 100%;
  border-radius: 5px;
  margin-bottom: 7px;
  font-size: 11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: none;

  &:after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    display: block;
    top: 100%;
    left: 50%;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
  }
`;
const BlockLineWrapperS = styled.div`
  background-color: rgba(0, 0, 0, 0.12);
  position: relative;
  border-radius: 10px;
  height: 15px;
  display: flex;
  /* overflow: hidden; */
  & > div {
    border-right: 2px solid white;
    position: relative;

    &:hover {
      ${InfoHoverS} {
        display: flex;
      }
    }
    &:nth-child(1) {
      background: #e01530;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
    &:nth-child(2) {
      background: #ff6c0a;
    }
    &:nth-child(3) {
      background: #ffcc01;
    }
    &:nth-child(4) {
      background: #4caf50;
    }
    &:nth-child(5) {
      background: #187a00;
      border-right: 0;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &:nth-child(2) {
    //background: red;
  }
`;

const LineS = styled.div<{ fl: number }>`
  flex: ${({ fl }) => fl + 1};
  ${({ fl }) =>
    !fl
      ? `
  background: #eee !important;
  
  `
      : ``}
`;
