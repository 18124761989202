import React from 'react';
import { useSelector } from 'react-redux';
import { SelectItemType } from '../components/ui/selects/DefaultSelect';
import { AppStore } from '../store/applicationState';
import { API_ROUTE_PATH } from '../utils/api_routes';
import { callApi } from '../utils/callApi';
import {ALL_GROUPS_TYPES} from "../utils/consts";

export const useGroupReport = () => {
    const { Workspaces } = useSelector((store: AppStore) => store);
    const [isLoading, setIsLoading] = React.useState(false);
    const refLinkCvs = React.useRef<any>(null);
    const [selectOriginSource, setSelectOriginSource] = React.useState<SelectItemType[]>([ALL_GROUPS_TYPES]);
    const [selectedOriginSource, setSelectedOriginSource] = React.useState<SelectItemType[] | null>([ALL_GROUPS_TYPES]);
    const [selectedPackage, setSelectedPackage] = React.useState<SelectItemType | null>(null);
    const [selectedCycle, setSelectedCycle] = React.useState<SelectItemType | null>(null);


    const genXlsx = async () => {
        if (!Workspaces.current) return null;
        if (!selectedPackage) return null;
        if(!selectedCycle) return null;
        if(!selectedOriginSource && !selectedOriginSource[0]) return null;
        setIsLoading(true);

        try {
            const reqData = {
                workspace_id: Workspaces.current.id,
                package_id: selectedPackage.id,
                link: selectedCycle.id,
                is_all_groups: selectedOriginSource[0].id === ALL_GROUPS_TYPES.id,
                origin_sources: selectedOriginSource[0].id === ALL_GROUPS_TYPES.id ? [] : selectedOriginSource.map(originSource => originSource.id as string)
            };
            const { workspace_id,package_id, link, is_all_groups, origin_sources  } = reqData;
            const resp = await callApi({
                method: 'post',
                path: API_ROUTE_PATH.reports.groupReportPath(
                    workspace_id,
                    package_id as string,
                ),
                data: {
                    link: link,
                    is_all_groups: is_all_groups,
                    origin_sources: origin_sources
                },
                responseType: 'blob',
            });
            const outputFilename = `Group_report_${Workspaces.current?.name}_${selectedPackage.title}_${selectedCycle.title}.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(resp);
            const linkDoc = document.createElement('a');
            linkDoc.href = url;
            linkDoc.setAttribute('download', outputFilename);
            document.body.appendChild(linkDoc);
            linkDoc.click();
        } catch (e) {
            console.log(e);
            alert(JSON.stringify(e));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        setSelectOriginSource,
        selectOriginSource,
        refLinkCvs,
        isLoading,
        genXlsx,
        selectedOriginSource,
        setSelectedOriginSource,
        selectedPackage,
        selectedCycle,
        setSelectedPackage,
        setSelectedCycle,
    };
};
