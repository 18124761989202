import { action } from 'typesafe-actions';
import { TDefRequest } from '../../types/actions';
import { TCat } from '../categories/types';
import { Actions } from '../utils/Action';
import ActionTypes, {
  TArchivedAssessment,
  TAssessment,
  TAssessmentCreate,
  TAssessmentsState,
} from './types';

const CreateActions = new Actions('CREATE', ActionTypes);

export interface TypeCreateAssessmentR extends TDefRequest {
  data: TAssessmentCreate;
}

export const CreateAssessment = {
  request: (payload: TypeCreateAssessmentR) => CreateActions.request(payload),
  success: (payload: TCat[]) => CreateActions.success(payload),
  error: (message: string) => CreateActions.error(message),
};

const GetOneActions = new Actions('GET_ONE', ActionTypes);

export interface TypeGetOneR extends TDefRequest {
  id: string;
  catName: string;
}

export const GetOneAssessment = {
  request: (payload: TypeGetOneR) => GetOneActions.request(payload),
  success: (payload: TAssessment | {}) => GetOneActions.success(payload),
  error: (message: string) => GetOneActions.error(message),
};

const GetAllAnsweredActions = new Actions('GET_ALL_ANSWERED', ActionTypes);

export interface TypeGetAllAnsweredR extends TDefRequest {
  workspaceId: string;
}

export const GetAllAnsweredAssessments = {
  request: (payload: TypeGetAllAnsweredR) =>
    GetAllAnsweredActions.request(payload),
  success: (payload: TAssessmentsState['answered']) =>
    GetAllAnsweredActions.success(payload || []),
  error: (message: string) => GetAllAnsweredActions.error(message),
};

const GetAllByTeamActions = new Actions('GET_ALL_BY_TEAM', ActionTypes);

export interface TypeGetAllByTeamR extends TDefRequest {
  workspaceId: string;
}

export const GetAllAssessmentsByTeam = {
  request: (payload: TypeGetAllByTeamR) => GetAllByTeamActions.request(payload),
  success: (payload: TAssessmentsState['existingData']) =>
    GetAllByTeamActions.success(payload || []),
  error: (message: string) => GetAllByTeamActions.error(message),
};

const EditActions = new Actions('EDIT', ActionTypes);

export interface TypeEditR extends TDefRequest {
  data: TAssessmentCreate;
  id: string;
}

export const EditAssessment = {
  request: (payload: TypeEditR) => EditActions.request(payload),
  success: (payload: TCat[]) => EditActions.success(payload),
  error: (message: string) => EditActions.error(message),
};

const ArchiveActions = new Actions('ARCHIVE', ActionTypes);

export interface TypeArchiveR extends TDefRequest {
  id: string;
}

export const ArchiveAssessment = {
  request: (payload: TypeArchiveR) => ArchiveActions.request(payload),
  success: (payload: TCat[]) => ArchiveActions.success(payload),
  error: (message: string) => ArchiveActions.error(message),
};

const UnarchiveActions = new Actions('UNARCHIVE', ActionTypes);

export interface TypeUnarchiveR extends TDefRequest {
  assId: string;
  catId: string;
}

export const UnarchiveAssessment = {
  request: (payload: TypeUnarchiveR) => UnarchiveActions.request(payload),
  success: (payload: TArchivedAssessment[]) =>
    UnarchiveActions.success(payload),
  error: (message: string) => UnarchiveActions.error(message),
};

const GetArchivedAssActions = new Actions('GET_ARCHIVE', ActionTypes);

export interface TypeGetArchivedR extends TDefRequest {
  teamId: string;
}

export const GetArchivedAssessments = {
  request: (payload: TypeGetArchivedR) =>
    GetArchivedAssActions.request(payload),
  success: (payload: TArchivedAssessment[]) =>
    GetArchivedAssActions.success(payload),
  error: (message: string) => GetArchivedAssActions.error(message),
};

const DeleteActions = new Actions('DELETE', ActionTypes);

export interface TypeDeleteR extends TDefRequest {
  id: string;
}

export const DeleteAssessment = {
  request: (payload: TypeDeleteR) => DeleteActions.request(payload),
  success: (payload: TCat[]) => DeleteActions.success(payload),
  error: (message: string) => DeleteActions.error(message),
};

const DeleteArchivedActions = new Actions('DELETE_ARCHIVED', ActionTypes);

export interface TypeDeleteArchivedR extends TDefRequest {
  id: string;
}

export const DeleteArchived = {
  request: (payload: TypeDeleteArchivedR) =>
    DeleteArchivedActions.request(payload),
  success: (payload: TArchivedAssessment[]) =>
    DeleteArchivedActions.success(payload),
  error: (message: string) => DeleteArchivedActions.error(message),
};

export const setAssEditMode = (payload: boolean) =>
  action(ActionTypes.SET_EDIT_MODE, payload);

const AddToPackActions = new Actions('ADD_TO_PACKAGE', ActionTypes);

export interface TypeAddAssToPackR extends TDefRequest {
  data: {
    relation: boolean;
    assessment_id: string;
    package_id: string;
  };
}

export const AddAssToPack = {
  request: (payload: TypeAddAssToPackR) => AddToPackActions.request(payload),
  success: () => AddToPackActions.success({}),
  error: (message: string) => AddToPackActions.error(message),
};

const GetAllAssesActions = new Actions('GET_ALL', ActionTypes);

export interface TypeGetAllR extends TDefRequest {
  id: string;
}

export const GetAllAsses = {
  request: (payload: TypeGetAllR) => GetAllAssesActions.request(payload),
  success: () => GetAllAssesActions.success({}),
  error: (message: string) => GetAllAssesActions.error(message),
};
