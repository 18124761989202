import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { DeleteAssessmentCard } from '../../store/assessmentCards/actions';
import {
  ECardStatus,
  TAssessmentCards,
  TCardStatus,
} from '../../store/assessmentCards/types';
import { EQuestionType, TQuestionType } from '../../store/assessments/types';
import { COLORS } from '../../utils/colors';
import { deepClone } from '../../utils/deepClone';
import { VALUES } from '../../utils/valueConst';
import { AssessmentIcon, CollectingDataIcon } from '../svgs';
import { ButtonMore, DeleteSelectItem } from '../ui';
import { AboutPackageInfo } from './AboutPackageInfo';

interface MonitorCardProps {
  card: TAssessmentCards;
}

export const MonitorCard: React.FC<MonitorCardProps> = ({ card }) => {
  const dispatch = useDispatch();
  const { AssessmentCards } = useSelector((store: AppStore) => store);
  const renderDynamic = (difference: number) => {
    if (difference === 0) return '';
    if (difference > 0) return '↑';
    return '↓';
  };
  const renderColor = (
    type: TQuestionType,
    average: number,
    status: TCardStatus
  ) => {
    if (status === ECardStatus.unused || status === ECardStatus.used)
      return 'white';
    if (type === EQuestionType.five) {
      if (average <= 2.99) return '#fbe0e1';
      if (average <= 3.99) return '#fdf0d0';
      if (average <= 5) return '#e5f2e3';
    }
    if (type === EQuestionType.ten) {
      if (average <= 5.99) return '#fbe0e1';
      if (average <= 7.99) return '#fdf0d0';
      if (average <= 10) return '#e5f2e3';
    }
  };
  return (
    <WrapperS
      bgColor={renderColor(
        card.assessment_info.answer_type,
        card.last_cycle_info.average_value,
        card.last_cycle_info.status
      )}
    >
      <CardHeaderS>
        <TextS isGray={card.last_cycle_info.status === ECardStatus.unused}>
          {card.assessment_info.name}
        </TextS>
        <ButtonMore
          onClick={(event) => {
            if (event === VALUES.delete) {
              const callBack = (success: boolean) => {
                if (!success) return null;
                if (!AssessmentCards.data) {
                  console.warn('AssessmentCards.data does not exist');
                  return null;
                }
                const data = deepClone(
                  AssessmentCards.data
                ) as TAssessmentCards[];
                const newData = data.filter((a) => a.id !== card.id);
                dispatch(DeleteAssessmentCard.success(newData));
              };
              dispatch(
                DeleteAssessmentCard.request({
                  assessmentCardId: card.id,
                  callBack,
                })
              );
            }
            return null;
          }}
          data={[
            {
              title: <DeleteSelectItem />,
              id: 1,
              value: VALUES.delete,
            },
          ]}
        />
      </CardHeaderS>
      <CardFooterS>
        {card.last_cycle_info.status === ECardStatus.used_and_displayed && (
          <>
            <NumberWrapperS>
              <BigTextS>{card.last_cycle_info.average_value}</BigTextS>
              <ArrowWrapperS isRed={card.last_cycle_info.difference < 0}>
                {renderDynamic(card.last_cycle_info.difference)}
              </ArrowWrapperS>
            </NumberWrapperS>
            <AboutPackageInfo
              text={card.assessment_info.description || '-'}
              name={card.last_cycle_info.package_name}
              date={`${card.last_cycle_info.link_create_at.split('T')[0]}`}
            />
          </>
        )}
        {card.last_cycle_info.status === ECardStatus.used && (
          <StartToUseFooterS>
            <CollectedIconWrapperS>
              <CollectingDataIcon />
            </CollectedIconWrapperS>
            <CollectedTextS>Data is being collected</CollectedTextS>
          </StartToUseFooterS>
        )}
        {card.last_cycle_info.status === ECardStatus.unused && (
          <StartToUseFooterS>
            <PleaseTextS>
              Please, start using assessment in the workspace
            </PleaseTextS>
            <ButtonLinkS href="/assessments/my-assessments">
              <AssessmentIcon /> Go to Library
            </ButtonLinkS>
          </StartToUseFooterS>
        )}
      </CardFooterS>
    </WrapperS>
  );
};
const WrapperS = styled.div<{ bgColor: string }>`
  height: 174px;
  width: 174px;
  border: 1px solid ${COLORS.secondary};
  border-radius: 12px;
  padding: 12px;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 24px 24px 0;
`;
const CardHeaderS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CardFooterS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const TextS = styled.p<{ isGray: boolean }>`
  font-size: 14px;
  font-weight: bold;
  width: 106px;
  padding: 0;
  margin: 0;
  color: ${({ isGray }) => (isGray ? '#b3b3b3' : 'black')};
`;
const PleaseTextS = styled.p`
  font-size: 12px;
  padding: 0;
  margin: 0;
  font-weight: 600;
`;
const CollectedIconWrapperS = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 12px;
  background: #e5f2e3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
`;
const CollectedTextS = styled.p`
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: ${COLORS.accent};
`;
const BigTextS = styled.p`
  font-size: 50px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: 1;
`;
const ArrowWrapperS = styled.p<{ isRed: boolean }>`
  font-size: 25px;
  line-height: 1;
  padding: 0;
  margin: 0 0 0 7px;
  color: ${({ isRed }) => (isRed ? COLORS.danger : COLORS.accent)};
`;
const NumberWrapperS = styled.div`
  display: flex;
  align-items: center;
`;
const StartToUseFooterS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonLinkS = styled.a`
  width: 100%;
  background: ${COLORS.accent};
  color: white;
  border: 0;
  border-radius: 6px;
  margin-top: 12px;
  padding: 0 14px;
  position: relative;
  border: solid 1px ${COLORS.accent};
  height: 35px;
  transition: 0.2s;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 14px;
    width: 14px;
    margin-right: 5px;
    g {
      fill: white;
    }
  }
  &:hover {
    color: white;
    background: ${COLORS.accentHover};
    border: solid 1px ${COLORS.accentHover};
  }

  &:active {
    background: ${COLORS.accentActive};
    border: solid 1px ${COLORS.accentActive};
  }

  &:disabled {
    opacity: 0.3;
    cursor: pointer;
  }
`;
