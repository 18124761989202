import React from 'react';
import { EColFilterStatus, TCollab } from '../../../store/collaborations/types';
import { NETWORK_MAP_TEXT } from '../../../utils/textConst';

export const useHoverCircle = (d3Loaded: boolean, data: TCollab[]) => {
  const [circeElement, setCirceElement] = React.useState<HTMLElement>(null);

  const listener = React.useCallback((circle: HTMLElement, hide?: boolean) => {
    if (!circle) return null;

    const getElements = (): {
      svgTooltip: HTMLElement;
      svgTooltipWrapper: HTMLElement;
    } => {
      const g = circle.parentElement;
      if (!g) return null;
      const svgTooltip: HTMLElement = g.querySelector('.svg-tooltip');
      const svgTooltipWrapper: HTMLElement = g.querySelector(
        '.svg-tooltip-text-wrapper'
      );

      return {
        svgTooltip,
        svgTooltipWrapper,
      };
    };

    const showElement = () => {
      const { svgTooltip, svgTooltipWrapper } = getElements();
      if (!svgTooltip) return null;
      if (!svgTooltipWrapper) return null;
      const textWrapper = svgTooltipWrapper.querySelector('span');
      const { userName } = circle.dataset;
      if (!textWrapper) return null;

      if (
        circle.classList.contains(EColFilterStatus.captured) &&
        circle.classList.contains(EColFilterStatus.matched)
      ) {
        textWrapper.innerText = `${userName} ${NETWORK_MAP_TEXT.matched}`;
      } else if (circle.classList.contains(EColFilterStatus.captured)) {
        textWrapper.innerText = `${userName} ${NETWORK_MAP_TEXT.captured}`;
      }

      svgTooltip.style.display = 'block';
      svgTooltipWrapper.style.display = 'block';
    };

    const hideElement = () => {
      const { svgTooltip, svgTooltipWrapper } = getElements();
      if (!svgTooltip) return null;
      if (!svgTooltipWrapper) return null;
      svgTooltip.style.display = 'none';
      svgTooltipWrapper.style.display = 'none';
    };

    hide ? hideElement() : showElement();
  }, []);
  React.useEffect(() => {
    window.addEventListener('mouseover', (e) => {
      if (
        e.target &&
        (e.target as HTMLElement).classList.contains('userCircle')
      ) {
        if (!data) return null;
        setCirceElement(e.target as HTMLElement);
        listener(e.target as HTMLElement);
      }
    });

    return () => {
      window.removeEventListener('mouseover', () => null);
    };
    // setTimeout(circleEvents, 2000);
  }, [d3Loaded, data, listener]);

  React.useEffect(() => {
    if (circeElement) {
      circeElement.addEventListener('mouseleave', () =>
        listener(circeElement, true)
      );
    }
    return () => {
      if (circeElement) {
        circeElement.removeEventListener('mouseover', () => null);
      }
    };
  }, [circeElement, listener]);
};
