import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../utils/colors';
import { DEFAULT_PADDING } from '../../utils/config';

const AvatarSize = '40px';

export const ArrowIconS = styled.div<{ isActive?: boolean }>``;

export const bRad = '8px';

export const WSWrapperS = styled.div`
  position: relative;
`;

export const WrapperS = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  padding: 0 ${DEFAULT_PADDING};
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    ${ArrowIconS} {
      svg {
        g {
          opacity: 1;
        }

        path {
          fill: ${COLORS.accentHover};
        }
      }
    }
  }
  &:active {
    color: ${COLORS.accentActive};

    ${ArrowIconS} {
      svg {
        g {
          opacity: 1;
        }
        path {
          fill: ${COLORS.accentActive};
        }
      }
    }
  }
`;

export const ImageS = styled.div`
  width: ${AvatarSize};
  height: ${AvatarSize};
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  background: #d3d3d3;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ImageInnerS = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const NameImageWrapperS = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
    font-size: 1.6rem;
    white-space: nowrap;
    font-weight: bold;
    flex: 1;
    color: #3c3c3c;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const defPad = '16px';

export const WSModalS = styled.div`
  position: absolute;
  top: 100%;
  left: 20px;
  background: white;
  border-radius: ${bRad};
  box-shadow: 0 11px 33px -9px rgba(42, 47, 42, 0.23);
  border: solid 1px rgba(162, 165, 162, 0.15);
  z-index: 2;
  width: 100%;
  min-width: 284px;
  padding: 16px 0 0;
  margin-top: 8px;
  max-height: 70vh;
  overflow: auto;
  ${NameImageWrapperS} {
    padding: 0 ${defPad};

    span {
      max-width: 93%;
    }
  }

  .btn-add {
    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 16px;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const SettingS = styled(Link)`
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 16px ${defPad};
  color: ${COLORS.default};
  display: block;
  margin-top: 10px;
  &:hover {
    background: ${COLORS_RGBA.accent(0.1)};
    color: ${COLORS.default};
  }
  &:active {
    background: ${COLORS_RGBA.accent(0.3)};
    color: ${COLORS.default};
  }
`;

export const ListItemsS = styled.ul`
  padding: 0;
  margin: 0;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - ${defPad} - ${defPad});
    background-color: rgba(0, 0, 0, 0.12);
    margin: auto;
  }

  li {
    font-size: 1.6rem;
    list-style: none;
    padding: 0 ${defPad};
    cursor: pointer;
    background: transparent;
    transition: 0.1s;
    display: flex;
    align-items: center;
    position: relative;

    span {
      padding: 16px 10px 16px 0;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn-close {
      opacity: 0.2;

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      background: ${COLORS_RGBA.accent(0.1)};
    }
    &:active {
      background: ${COLORS_RGBA.accent(0.3)};
    }

    &:first-child {
      border-top-left-radius: ${bRad};
      border-top-right-radius: ${bRad};
    }
    &:last-child {
      border-bottom-left-radius: ${bRad};
      border-bottom-right-radius: ${bRad};
    }
  }
`;
