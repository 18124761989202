import React from 'react';
import styled from 'styled-components';
import { Chart, ChartData, registerables } from 'chart.js';
import {
  EChartType,
  ETypeGraph,
  TChart,
  TChartFilter,
  TDatum,
} from '../../../store/analytics/types';
import { GRAPH_COLORS } from '../../../utils/colors';
import { ChartHeader } from '../ChartHeader';
import { NoData } from '../NoData';
import { BlockChartFilter } from './BlockChartFilter';
import { genChartId } from '../../../utils/genChartId';
import { useChartRuns } from './hooks/useChartRuns';
import { useDescription } from './hooks/useDescription';
import { EQuestionType } from '../../../store/assessments/types';
import { getCutText } from '../../../utils/helpers';
import {useSelector} from "react-redux";
import {AppStore} from "../../../store/applicationState";

interface ChartProps {
  widthLegend?: boolean;
  isStored: boolean;
  isPreview?: boolean;
  isDownload?: boolean;
  data: TChart;
  index: number;
  chartIndex: number;
  snapshotId: string | null;
  dataStoryChartSettings: TChartFilter | null;
  refHeader: React.MutableRefObject<HTMLDivElement>;
}

Chart.register(...registerables);

interface IChData {
  name: string;
  full_name?: string;
  color: string;
  val: number;
  id: string;
  // period_of_hiring_date?: string;
}

export const ChartBlock: React.FC<ChartProps> = React.memo(
  ({
    data: chart,
    index,
    chartIndex,
    isStored,
    dataStoryChartSettings,
    snapshotId,
    isPreview,
    refHeader,
    isDownload,
  }) => {
    const { Analytics } = useSelector(
        (store: AppStore) => store
    );
    const chartRef: any = React.createRef();
    const refLegend: any = React.createRef();
    const [dataForChart, setDataForChart] = React.useState<IChData[]>([]);
    const [chartHeight, setChartHeight] = React.useState(500);
    const [chartWrapperHeight, setChartWrapperHeight] = React.useState(520);
    const refData = React.useRef<IChData[]>([]);
    const { activeRuns, updateActiveRuns } = useChartRuns();
    const [isRuns, setIsRuns] = React.useState(false);
    const hookDesc = useDescription();
    const description = hookDesc.getAssDescription(chart.assessments_ids || []);
    const version = hookDesc.getAssVersion(chart.assessments_ids || []);
    const [activeQuestionItems, setActiveQuestionItems] = React.useState<
      | {
          x_axis_questions: string[];
          y_axis_questions: string[];
        }
      | string[]
    >();

    React.useEffect(() => {
      if (Analytics.filters.params.is_all_participants === true) {
        updateActiveRuns([])
      }
      // eslint-disable-next-line
    }, [Analytics.filters.params.is_all_participants])


    const plugin = {
      id: 'one_to_ten_bg_paint',
      beforeDraw(barChart, args, options) {
        if (
          chart.meta_data &&
          chart.meta_data.answer_types === EQuestionType.ten
        ) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = barChart;
          ctx.save();
          ctx.fillStyle = 'rgba(240,0,0,0.12)';
          ctx.fillRect(left, top, 5 * (width / 9), height);
          ctx.fillStyle = 'rgba(255,180,0,0.2)';
          ctx.fillRect(left + 5 * (width / 9), top, 2 * (width / 9), height);
          ctx.fillStyle = 'rgba(73,175,77,0.16)';
          ctx.fillRect(left + 7 * (width / 9), top, 2 * (width / 9), height);
          ctx.restore();
        }
      },
    };
    const renderBody = () => {
      return (
        <div>
          <BlockChartFilter
            disabledLabels={
              isStored ? dataStoryChartSettings.activeRuns : activeRuns
            }
            legendHandler={updateActiveRuns}
            setDefaultLabels={updateActiveRuns}
            chartIndex={chartIndex}
            index={index}
            isRuns={isStored ? true : isRuns}
            setIsRuns={setIsRuns}
            graphType={chart.type}
            questions={chart.select_group?.questions.options}
            activeQuestionItems={activeQuestionItems}
            setActiveQuestionItems={setActiveQuestionItems}
          />
          {!chart.data ||
          !chart.data[0] ||
          !refData.current ||
          !refData.current.length ? (
            <NoData />
          ) : (
            <div>
              <CanvasWrapperS
                preview={isPreview}
                wrapperHeight={chartWrapperHeight}
              >
                <ChartWrapperS chartHeight={chartHeight}>
                  <CanvasS ref={chartRef} />
                </ChartWrapperS>
              </CanvasWrapperS>
              <WrapperS style={{ paddingBottom: '20px' }} ref={refLegend}>
                {chart.view !== EChartType.cycles &&
                  refData.current[1] &&
                  refData.current.map((ch, i) => {
                    const tenure = ch.id.split('/')[5];
                    return (
                      <LegendS
                        key={ch.name + i}
                        disabled={(isStored
                          ? dataStoryChartSettings.activeRuns
                          : activeRuns
                        ).includes(ch.id)}
                        onClick={() => updateActiveRuns(ch.id)}
                        style={{ margin: '10px' }}
                      >
                        <LegendItemsS
                          style={{ background: ch.color, marginRight: '5px' }}
                        />
                        <LegendNameS>
                          {ch.name + ' '}
                          {tenure && tenure + ' '} <span>({ch.val})</span>
                        </LegendNameS>
                      </LegendS>
                    );
                  })}
              </WrapperS>
            </div>
          )}
        </div>
      );
    };
    React.useEffect(() => {
      const newData: IChData[] = [];
      const cashGroup: {
        [key: string]: IChData;
      } = {};
      chart.data &&
        chart.data[0] &&
        (chart.data as TDatum[]).forEach((itm, i) => {
          if (itm && !itm.period_of_hiring_date) {
            const existGroup =
              cashGroup[itm.group_name] ||
              newData.find((gr) => gr.name === itm.group_name);
            if (!cashGroup[itm.group_name])
              cashGroup[itm.group_name] = existGroup;
            newData.push({
              name: /[A-Z]{2,}/.test(itm.group_name)
                ? getCutText(itm.group_name, 10)
                : itm.group_name !== 'All participants' ?
                      getCutText(itm.group_name, 12) :
                      getCutText(itm.group_name, 17),
              full_name: itm.group_name,
              val: itm.value_avg,
              color: existGroup ? existGroup.color : GRAPH_COLORS[i] || 'grey',
              id: genChartId(
                itm.package_name,
                itm.package_id,
                itm.cycle_date,
                itm.group_name,
                itm.link
              ),
            });
          } else {
            itm.data.forEach((data, c) => {
              const existGroup =
                cashGroup[data.group_name] ||
                newData.find((gr) => gr.name === data.group_name);
              if (!cashGroup[data.group_name])
                cashGroup[data.group_name] = existGroup;

              newData.push({
                name: /[A-Z]{2,}/.test(data.group_name)
                  ? getCutText(data.group_name, 10)
                  : data.group_name !== 'All participants' ?
                        getCutText(data.group_name, 12) :
                        getCutText(data.group_name, 17),
                full_name: data.group_name,
                val: data.value_avg,
                color: existGroup
                  ? existGroup.color
                  : GRAPH_COLORS[c] || 'grey',
                id: genChartId(
                  data.package_name,
                  data.package_id,
                  data.cycle_date,
                  data.group_name,
                  data.link,
                  itm.period_of_hiring_date
                ),
              });
            });
          }

          if (isPreview) {
            setChartHeight(
              newData.length * 50 < 350 ? 350 : newData.length * 50
            );
          } else {
            setChartHeight(
              newData.length * 50 < 500 ? 500 : newData.length * 50
            );
          }
        });
      refData.current = newData;
      setDataForChart(newData);
      // eslint-disable-next-line
    }, [chart]);

    React.useEffect(() => {
      if (!isPreview && refHeader.current && refLegend.current) {
        setChartWrapperHeight(
          590 - refHeader.current.clientHeight - refLegend.current.clientHeight
        );
      } else if (
        isPreview &&
        !isDownload &&
        refHeader.current &&
        refLegend.current
      ) {
        setChartWrapperHeight(
          440 - refHeader.current.clientHeight - refLegend.current.clientHeight
        );
      } else {
        setChartWrapperHeight(chartHeight);
      }
      // eslint-disable-next-line
    }, [refHeader, refLegend, chartHeight]);

    React.useEffect(() => {
      let myChar: any = null;

      (() => {
        if (!chart.data) return null;
        if ((chart.data && !chart.data[0]) || !chartRef.current) return null;
        const myChartRef = chartRef.current.getContext('2d');
        const labels: string[] = [];
        const backgroundColor: string[] = [];
        const dataVal: number[] = [];

        dataForChart.forEach((d) => {
          if (
            (isStored
              ? dataStoryChartSettings.activeRuns
              : activeRuns
            ).includes(d.id) &&
            chart.view !== EChartType.cycles
          )
            return null;

          if (
            chart.view === EChartType.cycles &&
            (isStored ? dataStoryChartSettings.activeRuns : activeRuns)[0] &&
            !(
              isStored ? dataStoryChartSettings.activeRuns : activeRuns
            ).includes(d.id)
          )
            return null;
          const nameArray = d.id.split('/');
          if (chart.view === EChartType.cycles) {
            labels.push([
              `${
                /[A-Z]{2,}/.test(nameArray[3])
                  ? getCutText(nameArray[3], 10)
                  : nameArray[3] !== 'All participants' ?
                        getCutText(nameArray[3], 10) :
                        getCutText(nameArray[3], 17)
              }${nameArray[5] ? ', ' + nameArray[5] : ''}`,
              `\n${nameArray[0]}`,
              ` (${nameArray[2]})`,
            ] as any);
          } else {
            labels.push([
              `${d.name}`,
              ` ${nameArray[5] ? nameArray[5] : 'All tenures'}`,
            ] as any);
          }
          backgroundColor.push(d.color);
          return dataVal.push(d.val);
        });

        let data: ChartData<'bar', number[], string> = {
          labels,
          datasets: [
            {
              maxBarThickness: 15,
              data: dataVal,
              backgroundColor,
            },
          ],
        };

        myChar = new Chart(myChartRef, {
          type: 'bar',
          data,
          options: {
            maintainAspectRatio: false,
            indexAxis: 'y',
            responsive: true,
            scales: {
              y: {
                afterFit: function (scaleInstance) {
                  scaleInstance.width =
                    chart.view === EChartType.cycles ? 150 : 123;
                },
                ticks: {
                  // autoSkip: false,
                  font: {
                    size: chart.view === EChartType.cycles ? 10 : 13,
                  },
                },
              },
              x: {
                min: 1,
                max:
                  chart.meta_data &&
                  chart.meta_data.answer_types === EQuestionType.ten
                    ? 10
                    : 5,
                ticks: {
                  stepSize: 1,
                },
              },
            },

            plugins: {
              tooltip: {
                callbacks: {
                  title: (tooltipItem) => {
                    return tooltipItem[0].label.replace(
                      dataForChart[tooltipItem[0].dataIndex].name,
                      dataForChart[tooltipItem[0].dataIndex].full_name
                    );
                  },
                },
              },
              legend: {
                display: false,
                position: 'bottom',
                labels: {
                  font: {
                    size: 10,
                  },
                },
              },
              title: {
                display: true,
                text: chart.name.trim() === 'Employee Net Promoter Score (eNPS)' ? 'Employee Net Promoter Average' : 'Selected Groups’ Assessment Averages',
              },
            },
          },
          plugins: [plugin],
        });

        return myChar;
      })();

      return () => {
        myChar && myChar.destroy();
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef]);

    return (
      <div style={{ position: 'relative'}}>
        <ChartHeader
          chartView={chart.view}
          chartMetaData={chart.meta_data}
          isStored={isStored}
          description={description}
          version={version}
          title={chart.name}
          isInFav={chart.is_saved}
          id={chart.id}
          graphType={ETypeGraph.type1}
          isCycles={true}
          chartType={chart.origin}
          chartData={chart.data}
          chartSaved={chart.is_saved}
          chartPosition={chart.position}
          snapshotId={snapshotId}
          activeRuns={activeRuns}
          dataStoryChartSettings={dataStoryChartSettings}
          isPreview={isPreview}
          reference={refHeader}
          selectedСhartQuestions={activeQuestionItems}
          allChartQuestions={chart.select_group?.questions.options}
        />
        <div>{renderBody()}</div>
      </div>
    );
  }
);

const WrapperS = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* div {
    margin: 10px;
  } */
`;

const LegendS = styled.div<{ disabled?: boolean }>`
  /* margin: 10px; */
  display: flex;
  align-items: center;
  cursor: pointer;

  position: relative;

  ${({ disabled }) =>
    disabled
      ? `
    
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 1px;
    width: 110%;
    background: grey;
  }

  & > * {
    opacity: 0.5;
  }
  `
      : ``}
`;

const LegendItemsS = styled.div`
  width: 20px;
  height: 10px;
  /* margin-right: 5px; */
`;

const LegendNameS = styled.span`
  white-space: nowrap;
  font-size: 12px;
  span {
    font-weight: bold;
    font-size: 10px;
  }
`;

const CanvasWrapperS = styled.div<{ preview: boolean; wrapperHeight: number }>`
  overflow: auto;
  height: ${({ preview, wrapperHeight }) => `${wrapperHeight}px`};
  max-height: ${({ preview }) => (!preview ? '500px' : '350px')};
  padding: 0 20px;
  /* canvas {
    max-height: ${({ preview }) => (!preview ? '600px' : '350px')};
  } */
`;

const ChartWrapperS = styled.div<{ chartHeight: number }>`
  height: ${({ chartHeight }) => `${chartHeight}px`};
`;
const CanvasS = styled.canvas`
  //min-height: 300px;
  //min-height: ${({ height }) => height}px !important;
  //height: ${({ height }) => height}px !important;
`;
