import { LangAssessments } from './lang/assessments';
import { LangCollaborators } from './lang/collaborators';
import { ITranslation } from './lang/types';
import {LangGroupClassifications} from "./lang/groupsClassifications";

export const Translation: ITranslation = {
  en_US: {
    assessments: LangAssessments.en,
    collaborations: LangCollaborators.en,
    termsOfUse: 'Terms of use',
    privacyPolicy: 'Privacy Policy',
    groupClassifications: LangGroupClassifications.en,
  },
  es_ES: {
    assessments: LangAssessments.es,
    collaborations: LangCollaborators.es,
    termsOfUse: 'Condiciones de uso',
    privacyPolicy: 'Política de privacidad',
    groupClassifications: LangGroupClassifications.es,
  },
  fr_FR: {
    assessments: LangAssessments.fr,
    collaborations: LangCollaborators.fr,
    termsOfUse: `Conditions d'utilisation`,
    privacyPolicy: 'Politique de confidentialité',
    groupClassifications: LangGroupClassifications.fr,
  },
  he_IL: {
    assessments: LangAssessments.he,
    collaborations: LangCollaborators.he,
    termsOfUse: `תנאי שימוש`,
    privacyPolicy: 'מדיניות פרטיות',
    groupClassifications: LangGroupClassifications.he,
  },
};
