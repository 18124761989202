import React from 'react';
import styled from 'styled-components';
import { ButtonAdd } from '../ui';

interface SearchAndBtnsProps {
  newAssessmentHandler: () => void;
  linkedSurveyHandler: () => void;
  newCatHandler: () => void;
  isActiveBtnAss?: boolean;
}

export const SearchAndBtns: React.FC<SearchAndBtnsProps> = ({
  newAssessmentHandler,
  linkedSurveyHandler,
  newCatHandler,
  isActiveBtnAss,
}) => {
  return (
    <WrapperS>
      {/* <InputSearch value="" results={[]} onChange={(e) => console.log(e)} /> */}
      <BtnsWrapperS>
        <ButtonAdd
            onClick={linkedSurveyHandler}
            title="New Linked Survey"
            isGreen
            disabled={!isActiveBtnAss}
        />
        <ButtonAdd
          onClick={newAssessmentHandler}
          title="New Assessment"
          isGreen
          disabled={!isActiveBtnAss}
        />
        <ButtonAdd onClick={newCatHandler} title="New Category" />
      </BtnsWrapperS>
    </WrapperS>
  );
};

const WrapperS = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .input-search-wrapper {
    min-width: 220px;
    input {
      height: 36px;
    }
  }
`;

const BtnsWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  & > button {
    margin-left: 16px;
    /* height: 32px;
    min-height: 32px; */
  }
`;
