import React from 'react';

interface ScheduleIconProps {
  color?: string;
  opacity?: string;
}

export const ScheduleIcon: React.FC<ScheduleIconProps> = ({
  color = '#000',
  opacity = '1',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g opacity={opacity} fill="none" fillRule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <path
                d="M13 65c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V68c0-1.657 1.343-3 3-3v1c0 .552.448 1 1 1s1-.448 1-1v-1h6v1c0 .513.386.936.883.993L12 67c.552 0 1-.448 1-1v-1zm1 6H2v7c0 .552.448 1 1 1h10c.552 0 1-.448 1-1v-7zm-2 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                transform="translate(-224 -389) translate(200 213) translate(24 111)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
