import React from 'react';

interface PreviewIconProps {}

export const PreviewIcon: React.FC<PreviewIconProps> = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M7 1a1 1 0 1 1 2 0v1h3a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h3V1zm5 3H4a1 1 0 0 0-.993.883L3 5v4a1 1 0 0 0 .883.993L4 10h8a1 1 0 0 0 .993-.883L13 9V5a1 1 0 0 0-.883-.993L12 4z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          stroke="#FFF"
          strokeWidth="2"
          strokeLinecap="round"
          d="m8 11 3 4M8 11l-3 4"
        />
      </g>
    </svg>
  );
};
