import React from 'react';
import styled from 'styled-components';
import { COLORS, COLORS_RGBA } from '../../../utils/colors';
import { FolderIcon, PlusIcon } from '../../svgs';
import { ButtonDefS } from './styles';
import { ButtonsProps } from './types';

interface ButtonAddProps extends ButtonsProps {
  isGreen?: boolean;
  isIconFolder?: boolean;
  circle?: boolean;
  disabled?: boolean;
}

export const ButtonAdd: React.FC<ButtonAddProps> = ({
  onClick,
  title,
  type,
  isGreen,
  isIconFolder,
  circle,
  disabled,
}) => {
  return (
    <ButtonAddS
      circle={circle}
      type={type}
      onClick={onClick}
      className="btn-add"
      disabled={disabled}
      isGreen={isGreen}
    >
      <span>{isIconFolder ? <FolderIcon /> : <PlusIcon />}</span>
      {title}
    </ButtonAddS>
  );
};

export const ButtonAddS = styled(ButtonDefS)<{
  isGreen?: boolean;
  circle?: boolean;
}>`
  background: transparent;
  border: solid 1px ${COLORS_RGBA.default(0.2)};
  color: ${COLORS_RGBA.default(0.9)};
  display: inline-flex;
  width: auto;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  font-size: 1.6rem;
  padding: 0 16px;
  height: 36px;
  line-height: 0;
  min-height: 36px;
  white-space: nowrap;

  span {
    display: block;
    height: 14px;
    margin-right: 8px;
  }
  svg {
    g {
      transition: 0.1s;
    }
  }

  &:hover {
    color: white;
    border-color: solid 1px ${COLORS.accent};

    svg g {
      fill: white;
    }
  }

  ${({ isGreen }) =>
    isGreen
      ? `

    border: solid 1px ${COLORS.accent};
    background: ${COLORS.accent};
    color: white;

    svg g  {
      fill: white;
    }
  `
      : null}

  ${({ circle }) =>
    circle
      ? `
       border: 0;
       padding: 0;
    width: auto;
    color: ${COLORS.accent};
    span {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${COLORS.accent};
      border-radius: 50%;
      transition: 0.1s;
      svg {
        g {
          fill: white;
        }
      }
    }

    &:hover {
      background: transparent;
      border: 0;
      color: ${COLORS.accentHover};

      span {
        background: ${COLORS.accentHover};
      }
    }
    &:active {
      background: transparent;
      border: 0;
      color: ${COLORS.accentActive};

      span {
        background: ${COLORS.accentActive};
      }
    }
      
      `
      : null}

      &:disabled {
    background: ${COLORS_RGBA.default(0.1)};
    cursor: default;
    color: ${COLORS_RGBA.default(0.5)};
    border-color: transparent;
    svg {
      g {
        fill: ${COLORS_RGBA.default(0.5)};
      }
    }
  }
`;
