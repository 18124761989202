import React from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { TQuestionSelect } from '../../../store/assessments/types';
import { mainClass, SelectClasses } from '../../../utils/animatedStyles';
import {COLORS, COLORS_RGBA} from '../../../utils/colors';
import { delay } from '../../../utils/helpers';
import { ArrowIcon, CheckIcon } from '../../svgs';
import {EExistOrDoesntExistOrMissing} from "../../CsvAddParticipants/types";
import {Loader} from "./Loader";
import {TGroupSelect} from "../../../store/groups/types";


export interface SelectItemType {
  title: string;
  value: string;
  placeholder?: string;
  questions?: TQuestionSelect[];
  groups?: TGroupSelect[];
  is_origin_source?: boolean;
  id: number | string;
}

interface DefaultSelectProps {
  data: SelectItemType[];
  selected: SelectItemType | null;
  placeHolder?: string;
  icon?: React.FC;
  label?: string;
  isMultiple?: boolean;
  isExistFields?: object;
  isExistField?: number | string;
  activeSelects?: SelectItemType[];
  onChange: (item: SelectItemType) => void;
  isOpenHandler?: (isOpen: boolean) => void;
  openToTop?: boolean;
  selectExistFieldHandler?:() => void;
  onCloseHandler?: () => void;
  isLoading?: boolean;
  isSearch?: boolean;
  zIndex?: number;
  isDisabled?: boolean;
}

export const DefaultSelect: React.FC<DefaultSelectProps> = ({
  data,
  selected,
  onChange,
  label,
  placeHolder,
  icon: Icon,
  isOpenHandler,
  onCloseHandler,
  isMultiple,
  isExistField,
  activeSelects = [],
  openToTop,
    isLoading,
    isSearch,
    zIndex,
  isDisabled
}) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(data);
  const modalRef = React.useRef<HTMLUListElement>(null);
  React.useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  React.useEffect(() => {
    if (data && data[0]) {
      const filtered = data.filter((item) =>
          item.title.toLowerCase().includes('')
      );
      setSearchValue('')
      setFilteredOptions(filtered);
    }
  }, [data]);

  const hideList = async () => {
    if (modalRef && modalRef.current) {
      modalRef.current.classList.remove(SelectClasses.in);
      modalRef.current.classList.add(SelectClasses.out);
      await delay(100);
      setIsOpen(false);
    }
  };

  const { ref } = useOutsideClick(() => {
    if (isOpen) {
      hideList();
      if (onCloseHandler) {
        onCloseHandler();
      }
    }
  });

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    const filtered = data.filter((item) =>
        item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  if (isLoading) {
    return (
        <WrapperS className="ov-select" ref={ref}>
          {label ? <LabelS>{label}</LabelS> : null}
          <AreaOfLoading>
            {Icon && (
                <span className="svgIconSelect">
            <Icon />
          </span>
            )}
            <Loader size={0.7} isGreen={true}></Loader>
          </AreaOfLoading>
        </WrapperS>
    )
  }


  return (
    <WrapperS className="ov-select" ref={ref}>

      {label ? <LabelS>{label}</LabelS> : null}

      {isExistField === 2 ?
        <WarningLabelS>
          {placeHolder !== "Select group" ?
          "The field from the template is missing in the file."
          :
              "The Group has been deleted from the workspace."}
      </WarningLabelS> : null}

      <SelectBtnS
        disabled={isDisabled}
        isSearch={isSearch}
        isOpen={isOpen}
        isExistField={isExistField}
        className="ov-select-wr"
        onClick={() => {
          setIsOpen(true);
          if (isOpenHandler) isOpenHandler(true);
        }}
        zIndex={zIndex}
      >
        {Icon && (
          <span className="svgIconSelect">
            <Icon />
          </span>
        )}

        {isSearch ? (<SelectInputS  value={isOpen?searchValue: selected ? selected.title : placeHolder}
                                    active={!!selected}
                                    onChange={handleSearch}
                                    type="text"
                                    placeholder={placeHolder}/>)
        : (
            <SelectSpanS active={!!selected}>
                {selected ? (
                isMultiple && activeSelects[0] ? (
                    <span className="multiSpan">
              {activeSelects.map(
                  (s, i) =>
                      s.title + (activeSelects.length === i + 1 ? '' : ', ')
              )}{' '}
            </span>
                ) : (
                    selected.title
                )
            ) : (
                placeHolder
            )}
        </SelectSpanS>
            )
        }
        {!isLoading && data[1] && <ArrowIcon />}
      </SelectBtnS>

      {!isOpen ? null : (
        <ItemsWrapperS
          top={openToTop}
          ref={modalRef}
          className={`${mainClass} ${SelectClasses.in}`}
        >
          {filteredOptions && filteredOptions[0] ? (
              filteredOptions.map((itm) => {
              if (!isMultiple && selected && selected.id === itm.id)
                return null;
              return (
                <ItemS key={itm.id}>
                  <span
                    onClick={() => {
                      if (!isMultiple) {
                        hideList();
                      }
                      onChange(itm);
                    }}
                  >
                    <span>{itm.title}</span>

                    {isMultiple &&
                      activeSelects.find((it) => it.value === itm.value) && (
                        <CheckIcon />
                      )}
                  </span>
                </ItemS>
              );
            })
          ) : (
            <ItemS noItems>No items</ItemS>
          )}
        </ItemsWrapperS>
      )}
    </WrapperS>
  );
};

export const WrapperS = styled.div<{disabled?: boolean}>`
  position: relative;
  width: 100%;
`;

const AreaOfLoading = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 6px;
  height: 48px;
  align-items: center;
  padding: 13px 16px;
  border: solid 1px ${COLORS_RGBA.default(0.2)};
`;

export const SelectBtnS = styled.div<{disabled?: boolean, isOpen?: boolean, isExistField?: number | string, isSearch?: boolean, zIndex?: number}>`
  position: relative;
  width: 100%;
  display: flex;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : 'white')};
  svg {
   ${({zIndex}) => !zIndex ?
    `z-index: 2;` : `z-index: ${zIndex};`}
    
  }
  ${({isSearch}) => isSearch === true ?
      `justify-content: end;` : null}
  
  ${({ isExistField }) =>
      isExistField === EExistOrDoesntExistOrMissing.doesntExist
          ? `
   border: solid 1px ${COLORS_RGBA.orange(0.8)};
  `
          : `border: solid 1px ${COLORS_RGBA.default(0.2)};
          `}
  border-radius: 6px;
  height: 48px;
  align-items: center;
  padding: 13px 16px;
  cursor: pointer;

  .svgIconSelect {
    margin-right: 10px;
  }

  &:hover {
    ${({ isExistField }) =>
        isExistField === EExistOrDoesntExistOrMissing.doesntExist
            ? `
   border: solid 1px ${COLORS_RGBA.accent(1)};
  `
            : `border: solid 1px ${COLORS_RGBA.accent(1)};`}

  svg {
    transition: 0.2s;
    ${({ isOpen }) =>
      isOpen
        ? `
   transform: translateY(1px) scale(1, -1);
  `
        : ''}
  }
`;

export const SelectInputS = styled.input<{ active?: boolean }>`
  position: absolute;
  left: 0;
  height: 46px;
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 5px;
  flex: 1;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ active }) => (active ? `  opacity: 1;` : `  opacity: 0.6;`)}
`;

export const SelectSpanS = styled.span<{ active?: boolean }>`
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 5px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ active }) => (active ? `  opacity: 1;` : `  opacity: 0.6;`)}

  .multiSpan {
    max-width: 200px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
`;

export const LabelS = styled.div`
  span {
    font-size: 1.4rem;
    display: block;
    color: ${COLORS_RGBA.default(0.8)};
    font-weight: 400;
  }
  margin-bottom: 5px;
`;

export const ItemsWrapperS = styled.ul<{ top?: boolean }>`
  position: absolute;

  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 23px 0 rgba(42, 47, 42, 0.16);
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  z-index: 3;
  ${({ top }) =>
    top
      ? `
    bottom: 100%;
  `
      : `
    top: 100%;
  `}
`;
export const ItemS = styled.li<{ noItems?: boolean }>`
  & > span {
    flex: 1;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding: 13px 16px;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: ${COLORS_RGBA.accent()};
      width: 25px;
      height: 25px;
      min-width: 18px;
      min-height: 18px;
    }

    & > * {
      &:first-child {
        flex: 1;
      }
    }

    &:hover {
      background: ${COLORS_RGBA.accent(0.13)};
    }

    &:active {
      background: ${COLORS_RGBA.accent(1)};
      color: white;
    }
  }

  ${({ noItems }) =>
    noItems
      ? `
    padding: 10px;
  `
      : ``}
`;

const WarningLabelS = styled.span`
  position: absolute;
  font-size: 11px;
  left: 0;
  top: 100%;
  white-space: nowrap;
  color: ${COLORS.danger};
`;
