import React from 'react';

interface CloseIconProps {}

export const CloseIcon: React.FC<CloseIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fillRule="evenodd">
        <g fill="#000000">
          <g>
            <g>
              <g>
                <path
                  d="M219.628 12.18l.09.078v.001c.34.34.34.891 0 1.231l-4.491 4.499 4.511 4.52c.311.312.337.801.078 1.142l-.078.09h-.001c-.34.34-.889.339-1.227 0l-4.511-4.52-4.51 4.52c-.339.339-.888.34-1.228 0-.34-.34-.34-.891 0-1.231l4.509-4.52-4.49-4.5c-.311-.312-.337-.8-.078-1.142l.078-.089.001-.001c.34-.338.889-.338 1.227.001l4.491 4.5 4.491-4.5c.31-.31.798-.337 1.138-.079z"
                  transform="translate(-1304 -196) translate(1080 64) translate(16 76) translate(0 44)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
