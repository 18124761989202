import React from 'react';

interface EditIconProps {
  color?: string;
  opacity?: string;
}

export const EditIcon: React.FC<EditIconProps> = ({
  color = '#000',
  opacity = '.3',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-1032 -244) translate(1020 229) translate(1 4) translate(11 11)">
                <path
                  fill={color}
                  d="M17.86 4.51l.018.01c.179.116.349.252.505.409l.71.71c1.177 1.176 1.185 3.075.019 4.242l-2.172 2.171-.358.379-.01-.01-6.716 6.715c-.56.56-1.321.873-2.117.87l-1.706-.008c-1.105-.005-2.004-.904-2.009-2.008l-.007-1.707c-.004-.796.31-1.557.87-2.117l7.117-7.12 2.066-2.182c1.027-1.027 2.62-1.143 3.79-.355zm-4.84 4.36l-6.714 6.716c-.186.187-.29.44-.29.706l.008 1.706 1.707.008c.265.001.519-.103.705-.29L15.152 11 13.02 8.87zm2.542-2.54l-1.147 1.145 2.072 2.072 1.133-1.133c.39-.389.386-1.022-.006-1.414l-.71-.71c-.043-.044-.09-.082-.139-.116-.383-.22-.878-.168-1.203.156z"
                  opacity={opacity}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
