import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { useForm } from '../../hooks/useForm';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { AppStore } from '../../store/applicationState';
import { EditGroup, getGroupsR } from '../../store/groups/actions';
import { TGroup } from '../../store/groups/types';
import { EditParticipant } from '../../store/participants/actions';
import { TParticipant } from '../../store/participants/types';
import { AnimatedClasses } from '../../utils/animatedStyles';
import { COLORS_RGBA } from '../../utils/colors';
import { countryDial } from '../../utils/countryDial';
import { deepClone } from '../../utils/deepClone';
import { validateEmail, validatePhone } from '../../utils/helpers';
import { TEXT } from '../../utils/textConst';
import { SelectListS } from '../Responding/styles';
import { Alert, Input, Loader } from '../ui';
import { ButtonBorderedS, ButtonDefS } from '../ui/buttons/styles';
import * as Styles from './modalStyles';
import { closeModal } from './utils/closeModal';
import { getFormatDate } from '../../utils/getFormatDate';

interface EditParticipantProps {
  data: TParticipant;
  position: number;
  closeHandler: () => void;
}

const FIRST_NAME = 'first_name' as const,
  LAST_NAME = 'last_name' as const,
  EMAIL = 'email' as const,
  PHONE = 'phone' as const,
  PHONE_CODE = 'phone_code' as const,
  PAYROLL_ID = 'payroll_id' as const,
  HIRING_DATE = 'hiring_date' as const,
  ETHNICITY = 'ethnicity' as const,
  GENDER = 'gender' as const,
  WL_NAME = 'work_location_name' as const,
  WL_CITY = 'work_location_city' as const,
  WL_STATE = 'work_location_state' as const,
  WL_COUNTRY = 'work_location_country' as const,
  PAY_GROUP = 'pay_group' as const,
  JOB = 'job' as const,
  DEPARTMENT = 'department' as const,
  SEGMENT = 'segment' as const,
  HOME_BASE = 'home_base' as const,
  BIRTH_DATE = 'birth_date' as const;

export const EditParticipantModal: React.FC<EditParticipantProps> = ({
  closeHandler,
  data,
  position,
}) => {
  const { Participants, Groups, Workspaces } = useSelector(
    (store: AppStore) => store
  );
  const [warning, setWarning] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [isListVisible, setIsListVisible] = React.useState(false);
  const { ref: refList } = useOutsideClick(() => setIsListVisible(false));
  const refInnWrapper = React.useRef<HTMLDivElement>(null);
  const { form, updateForm, onChangeHandler } = useForm({
    [FIRST_NAME]: { value: (data && data[FIRST_NAME]) || '' },
    [LAST_NAME]: { value: (data && data[LAST_NAME]) || '' },
    [EMAIL]: { value: (data && data[EMAIL]) || '' },
    [PHONE]: { value: (data && data[PHONE]) || '' },
    [PHONE_CODE]: { value: (data && data[PHONE_CODE]) || '' },
    [PAYROLL_ID]: { value: (data && data[PAYROLL_ID]) || '' },
    [HIRING_DATE]: {
      value:
        (data &&
          data[HIRING_DATE] &&
          data[HIRING_DATE] !== '0001-01-01T00:00:00Z' &&
          data[HIRING_DATE].split('T')[0].replaceAll('-', '.')) ||
        '',
    },
    [ETHNICITY]: { value: (data && data[ETHNICITY]) || '' },
    [GENDER]: { value: (data && data[GENDER]) || '' },
    [PAY_GROUP]: {
      value: (data && data[PAY_GROUP]) || '',
    },
    [JOB]: {
      value: (data && data[JOB]) || '',
    },
    [DEPARTMENT]: {
      value: (data && data[DEPARTMENT]) || '',
    },
    [SEGMENT]: {
      value: (data && data[SEGMENT]) || '',
    },
    [HOME_BASE]: {
      value: (data && data[HOME_BASE]) || '',
    },
    [BIRTH_DATE]: {
      value:
        (data &&
          data[BIRTH_DATE] &&
          data[BIRTH_DATE] !== '0001-01-01T00:00:00Z' &&
          data[BIRTH_DATE].split('T')[0].replaceAll('-', '.')) ||
        '',
    },
    [WL_NAME]: {
      value: (data && data[WL_NAME]) || '',
    },
    [WL_CITY]: {
      value: (data && data[WL_CITY]) || '',
    },
    [WL_STATE]: {
      value: (data && data[WL_STATE]) || '',
    },
    [WL_COUNTRY]: {
      value: (data && data[WL_COUNTRY]) || '',
    },
  });
  const changePhoneHandler = (phone: string) => {
    updateForm(PHONE, {
      value: Array.from(phone)[0] === '0' ? phone.substr(1) : phone,
    });
  };
  const dispatch = useDispatch();
  const closeModalHandler = () => {
    if (refInnWrapper.current) closeModal(refInnWrapper.current, closeHandler);
  };

  const updateParticipantInGroup = (participant: TParticipant) => {
    if (!Groups.data) return console.error('Could not find groups');
    const newGroups = deepClone(Groups.data) as TGroup[];
    participant.groups.forEach((participantGroup) => {
      const group = newGroups.find(
        (gr) => `${gr.id}` === `${participantGroup.id}`
      );

      if (!group) return console.error('Could not find group');

      const groupParticipant = group.participants.find(
        (innerP) => `${innerP.id}` === `${participant.id}`
      );

      if (!groupParticipant)
        return console.error('Could not find participant in group');

      groupParticipant.first_name = participant.first_name;
      groupParticipant.last_name = participant.last_name;
    });

    dispatch(EditGroup.success(newGroups));
  };

  const submitHandler = () => {
    if (Participants.loading) return null;
    setWarning('');
    const firstName = form[FIRST_NAME].value;
    const lastName = form[LAST_NAME].value;
    const email = form[EMAIL].value;
    const phone = form[PHONE].value;
    const phone_code = form[PHONE_CODE].value.includes(' ')
      ? form[PHONE_CODE].value.split(' ')[1]
      : form[PHONE_CODE].value;
    const payroll_id = form[PAYROLL_ID].value;
    const hiring_date = form[HIRING_DATE].value;
    const ethnicity = form[ETHNICITY].value;
    const gender = form[GENDER].value;
    const wl_name = form[WL_NAME].value;
    const wl_city = form[WL_CITY].value;
    const wl_state = form[WL_STATE].value;
    const wl_country = form[WL_COUNTRY].value;
    const pay_group = form[PAY_GROUP].value;
    const job = form[JOB].value;
    const department = form[DEPARTMENT].value;
    const segment = form[SEGMENT].value;
    const home_base = form[HOME_BASE].value;
    const birth_date = form[BIRTH_DATE].value;

    if (!firstName) {
      return updateForm(FIRST_NAME, {
        value: '',
        errorText: 'First name is required',
      });
    }
    if (!lastName) {
      return updateForm(LAST_NAME, {
        value: '',
        errorText: 'Last name is required',
      });
    }

    if (email && !validateEmail(email)) {
      return updateForm(EMAIL, {
        value: email,
        errorText: 'Email is invalid',
      });
    }

    if (phone && !validatePhone(phone_code + phone)) {
      return updateForm(PHONE, {
        value: phone,
        errorText: 'Phone number is invalid',
      });
    }

    if (!email && !phone && !payroll_id) {
      return setWarning(
        'You need to fill one or all of the user credentials: email, phone, ID.'
      );
    }

    const errorText =
      'Could not find participant, try again later or contact with administrator';

    if (!Participants.data) return setWarning(errorText + ' code - 90');
    if (Participants.data && !Participants.data[position])
      return setWarning(errorText + ' code - 91');
    // if (Participants.data && `${Participants.data[position].id}` !== `${data.id}`)
    //   return setWarning(errorText + ' code - 92');

    const allParticipants = deepClone(Participants.data) as TParticipant[];

    let participant: TParticipant | undefined = allParticipants[
      position
    ] as TParticipant;

    if (participant.id !== data.id) {
      participant = allParticipants.find(
        (innerParticipant) => `${innerParticipant.id}` === `${data.id}`
      );
    }

    if (!participant)
      return dispatch(
        EditParticipant.error('Gould not find participant, ' + TEXT.tryLater)
      );

    participant.first_name = firstName;
    participant.last_name = lastName;
    participant.email = email;
    participant.phone = phone;
    participant.phone_code = phone ? phone_code : '';
    participant.payroll_id = payroll_id;
    participant.hiring_date = hiring_date.replaceAll('.', '-');
    participant.ethnicity = ethnicity;
    participant.gender = gender;
    participant.work_location_name = wl_name;
    participant.work_location_city = wl_city;
    participant.work_location_state = wl_state;
    participant.work_location_country = wl_country;
    participant.pay_group = pay_group;
    participant.job = job;
    participant.department = department;
    participant.segment = segment;
    participant.home_base = home_base;
    participant.birth_date = birth_date;

    return dispatch(
      EditParticipant.request({
        data: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          phone_code: phone ? phone_code : '',
          payroll_id,
          hiring_date: hiring_date,
          ethnicity: ethnicity,
          gender: gender,
          is_active: true,
          work_location_name: wl_name,
          work_location_city: wl_city,
          work_location_state: wl_state,
          work_location_country: wl_country,
          pay_group: pay_group,
          job: job,
          department: department,
          segment: segment,
          home_base: home_base,
          birth_date: birth_date,
        },
        participantId: participant.id,
        callBack: (success, data) => {
          if (success) {
            if (participant!.groups && participant!.groups[0]) {
              updateParticipantInGroup(participant!);
            }
            participant.groups = (data as TParticipant).groups;
            dispatch(EditParticipant.success(allParticipants));
            dispatch(getGroupsR(Workspaces.current.id));
            closeModalHandler();
          }
        },
      })
    );
  };

  return (
    <Styles.ModalOuterWrapperS>
      <Styles.ModalInnerWrapperS
        style={{ maxWidth: '616px' }}
        ref={refInnWrapper}
        className={AnimatedClasses.zoomIn}
      >
        <Styles.ModalHeaderS>
          <Styles.ModalTileS>
            Edit Participant {form[FIRST_NAME].value}
          </Styles.ModalTileS>
        </Styles.ModalHeaderS>
        <Styles.ModalBodyS>
          <form>
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[FIRST_NAME].value}
              name={FIRST_NAME}
              label="First Name"
              placeholder="First Name"
              errorText={form[FIRST_NAME].errorText}
            />
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[LAST_NAME].value}
              name={LAST_NAME}
              label="Last Name"
              placeholder="Last Name"
              errorText={form[LAST_NAME].errorText}
            />

            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[EMAIL].value}
              name={EMAIL}
              type="email"
              label="Email"
              placeholder="Email"
              errorText={form[EMAIL].errorText}
            />
            <div>
              <LabelS>Country Code</LabelS>
              <input
                name={PHONE_CODE}
                placeholder="Code"
                type="text"
                value={form[PHONE_CODE].value}
                onClick={() => {
                  setIsListVisible(true);
                }}
                readOnly
              />

              {isListVisible && (
                <SearchWrapperS ref={refList}>
                  <SearchInputS
                    type="text"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.currentTarget.value);
                    }}
                    placeholder={'Search'}
                    autoFocus
                  />
                  <SelectCountryListS>
                    {!searchValue
                      ? countryDial.map((itm, i) => (
                          <li
                            key={i + itm.name + itm.code}
                            onClick={() => {
                              updateForm(PHONE_CODE, {
                                value: `${itm.flag} ${itm.dial_code}`,
                              });
                              setIsListVisible(false);
                            }}
                          >
                            {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                          </li>
                        ))
                      : countryDial
                          .filter(
                            (itm) =>
                              itm.code.includes(searchValue.toUpperCase()) ||
                              itm.name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase()) ||
                              itm.dial_code.includes(searchValue)
                          )
                          .map((itm) => (
                            <li
                              key={
                                countryDial.findIndex(
                                  (country) => itm.name === country.name
                                ) +
                                itm.name +
                                itm.code
                              }
                              onClick={() => {
                                updateForm(PHONE_CODE, {
                                  value: `${itm.flag} ${itm.dial_code}`,
                                });
                                setIsListVisible(false);
                              }}
                            >
                              {`${itm.flag} ${itm.name} (${itm.dial_code})`}
                            </li>
                          ))}
                  </SelectCountryListS>
                </SearchWrapperS>
              )}

              <Input
                stylesWrapper={{ marginBottom: '20px', marginTop: '20px' }}
                onChange={(e) => changePhoneHandler(e.currentTarget.value)}
                value={form[PHONE].value}
                name={PHONE}
                label="Phone"
                placeholder="Phone"
                errorText={form[PHONE].errorText}
              />
            </div>
            <Input
              onChange={onChangeHandler}
              value={form[PAYROLL_ID].value}
              name={PAYROLL_ID}
              label="ID"
              placeholder="ID"
              errorText={form[PAYROLL_ID].errorText}
            />
            <Input
                stylesWrapper={{ marginBottom: '20px', marginTop: '20px', }}
                onChange={onChangeHandler}
                value={form[JOB].value}
                name={JOB}
                placeholder="Job"
                label="Job"
                errorText={form[JOB].errorText}
            />
            <LabelS>Start Date</LabelS>
            <DatePicker
              placeholderText="yyyy.mm.dd"
              name={HIRING_DATE}
              value={form[HIRING_DATE].value}
              onChange={(date, event) => {
                updateForm(HIRING_DATE, {
                  value: getFormatDate(date as Date)
                    .split('T')[0]
                    .replaceAll('-', '.'),
                });
              }}
              maxDate={new Date()}
              shouldCloseOnSelect
            />
            <Input
              stylesWrapper={{ marginBottom: '20px', marginTop: '20px' }}
              onChange={onChangeHandler}
              value={form[ETHNICITY].value}
              name={ETHNICITY}
              label="Ethnicity"
              placeholder="Ethnicity"
              errorText={form[ETHNICITY].errorText}
            />
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[GENDER].value}
              name={GENDER}
              label="Gender"
              placeholder="Gender"
              errorText={form[GENDER].errorText}
            />
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[PAY_GROUP].value}
              name={PAY_GROUP}
              placeholder="Pay Group"
              label="Pay Group"
              errorText={form[PAY_GROUP].errorText}
            />

            <LabelS>Date of Birth</LabelS>
            <DatePicker
              placeholderText="yyyy.mm.dd"
              name={BIRTH_DATE}
              value={form[BIRTH_DATE].value}
              onChange={(date, event) => {
                updateForm(BIRTH_DATE, {
                  value: getFormatDate(date as Date)
                    .split('T')[0]
                    .replaceAll('-', '.'),
                });
              }}
              maxDate={new Date()}
              shouldCloseOnSelect
              showYearDropdown
              dropdownMode="select"
            />

            <Input
                stylesWrapper={{ marginBottom: '20px', marginTop: '20px', }}
                onChange={onChangeHandler}
                value={form[DEPARTMENT].value}
                name={DEPARTMENT}
                placeholder="Department"
                label="Department"
                errorText={form[DEPARTMENT].errorText}
            />
            <Input
                stylesWrapper={{ marginBottom: '20px' }}
                onChange={onChangeHandler}
                value={form[SEGMENT].value}
                name={SEGMENT}
                placeholder="Department Segment"
                label="Department Segment"
                errorText={form[SEGMENT].errorText}
            />
            <Input
                stylesWrapper={{ marginBottom: '20px' }}
                onChange={onChangeHandler}
                value={form[HOME_BASE].value}
                name={HOME_BASE}
                placeholder="Division"
                label="Division"
                errorText={form[HOME_BASE].errorText}
            />

            <LabelS>Work Location</LabelS>
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[WL_NAME].value}
              name={WL_NAME}
              placeholder="Name"
              errorText={form[WL_NAME].errorText}
            />
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[WL_CITY].value}
              name={WL_CITY}
              placeholder="City"
              errorText={form[WL_CITY].errorText}
            />
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[WL_STATE].value}
              name={WL_STATE}
              placeholder="State"
              errorText={form[WL_STATE].errorText}
            />
            <Input
              stylesWrapper={{ marginBottom: '20px' }}
              onChange={onChangeHandler}
              value={form[WL_COUNTRY].value}
              name={WL_COUNTRY}
              placeholder="Country"
              errorText={form[WL_COUNTRY].errorText}
            />
          </form>

          {Participants.errors ? (
            <Alert type="danger" text={Participants.errors || TEXT.someError} />
          ) : null}
          {warning ? <Alert type="warning" text={warning} /> : null}
        </Styles.ModalBodyS>
        <Styles.ModalFooterS>
          <Styles.BtnsWrapperS>
            <ButtonBorderedS isCancel onClick={closeModalHandler}>
              Cancel
            </ButtonBorderedS>
            <ButtonDefS isSave onClick={submitHandler}>
              {Participants.loading ? <Loader size={0.5} /> : 'Save'}
            </ButtonDefS>
          </Styles.BtnsWrapperS>
        </Styles.ModalFooterS>
      </Styles.ModalInnerWrapperS>
    </Styles.ModalOuterWrapperS>
  );
};
const SearchInputS = styled(Input)``;
const SelectCountryListS = styled(SelectListS)``;
const SearchWrapperS = styled.div`
  position: absolute;
  width: 300px;
  z-index: 100;
`;
const LabelS = styled.span`
  font-size: 1.4rem;
  display: block;
  color: ${COLORS_RGBA.default(0.8)};
  margin-bottom: 5px;
  margin-top: 20px;
  font-weight: 400;
`;
