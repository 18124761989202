import React from 'react';
import { PageTitleWrapperS } from '../../components/RightSideBar/RightSideBarStyles';
import { MainWrapperS } from '../../globalStyle';
import { useNetworkMap } from './hooks/useNetworkMap';
import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import { Loader } from '../../components/ui';
import {
  ControlBtns,
  Filters,
  Score,
} from '../../components/Analytics/Collaboration';
import { Search } from '../../components/Analytics/Collaboration/Search';
import { ParticipationRate } from '../../components/Analytics/ParticipationRate';
import { CollabEmployeeOverlay } from '../../components/modals/CollabEmployeeOverlay';
import html2canvas from 'html2canvas';
import { ButtonDefS } from '../../components/ui/buttons/styles';
import { AddDataStoryIcon, CheckIcon } from '../../components/svgs';
import { useDispatch, useSelector } from 'react-redux';
import { CreateFileSnapshot } from '../../store/dataStory/actions';
import { AppStore } from '../../store/applicationState';
import { TQuestionSelect } from '../../store/assessments/types';
import { TCollabAssessment } from '../../store/collaborations/types';
import {GetCollaborations} from "../../store/collaborations/actions";

interface CollaborationProps {}

const width = window.innerWidth - 320;
const height = window.innerHeight - 280;

export const Collaboration: React.FC<CollaborationProps> = () => {
  const {
    svgRef,
    collaborationsData,
    loading,
    currentUser,
    closeModal,
    zoomIn,
    searchUserHandler,
    zoomOut,
  } = useNetworkMap();


  const { Workspaces, Groups, Assessments, Collaborations } = useSelector(
    (store: AppStore) => store
  );
  const [isGlobalSettings, setIsGlobalSettings] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>(null);
  const [addedToStory, setAddedToStory] = React.useState(false);
  const [storyLoading, setStoryLoading] = React.useState(false);
  const [percentilesRange, setPercentilesRange] = React.useState([1, 100]);
  //const [secondFilterRange, setSecondFilterRange] = React.useState([1, 100]);
  const [searchVal, setSearchVal] = React.useState('');
  const [firstLoading, setFirstLoading] = React.useState(true);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetCollaborations.success(null))
    dispatch(GetCollaborations.loading(true))
    setFirstLoading(true)
    //eslint-disable-next-line
  }, [Workspaces.current, dispatch])

  const renderBody = React.useMemo(() => {
    if (collaborationsData && collaborationsData[0]) {
      return (
        <div ref={ref}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            ref={svgRef}
            viewBox={`-${width / 2} -${height / 2} ${width} ${height}`}
            width={width}
            height={height}
          />
        </div>
      );
    }

    if (!loading) {
      return <div style={{ fontSize: '18px' }}>No data</div>;
    }

    if (firstLoading) {
      return <div style={{ fontSize: '18px' }}>Please, select all participants or group(s) and click «Apply» to view the collaboration map</div>;
    }

    return <></>;
  }, [loading, collaborationsData, svgRef, firstLoading]);

  const getGroupsNames = (runs: string[], activeIds: string[]) => {
    const groupNames: string[] = [];
    if (!activeIds[0]) {
      runs.forEach((itm) => {
        const itmArray = itm.split('/');
        if (!groupNames.includes(itmArray[3])) {
          groupNames.push(itmArray[3]);
        }
      });
    } else if (!runs[0]) {
      activeIds.forEach((itm) => {
        groupNames.push(Groups.data.find((group) => itm === group.id).name);
      });
    } else {
      runs.forEach((itm) => {
        const itmArray = itm.split('/');
        if (!groupNames.includes(itmArray[3])) {
          groupNames.push(itmArray[3]);
        }
      });
      return groupNames;
    }
    if (
      groupNames.length === Groups.data.length ||
      (activeIds[0] && activeIds.length === Groups.data.length)
    )
      return ['All Groups'];
    return groupNames;
  };

  const getAssesmNames = (assessments: TCollabAssessment[]) => {
    const activeIds: string[] = [];
    const questionIds: string[] = [];
    assessments.forEach((assessment) => {
      activeIds.push(assessment.assessment_id);
      if (assessment.questions_ids[0])
        assessment.questions_ids.forEach((id) => questionIds.push(id));
    });

    const assNames: string[] = [];
    if (
      assNames.length === Assessments.answered.length ||
      (activeIds[0] && activeIds.length === Assessments.answered.length)
    )
      return ['All Assessments'];

    Assessments.answered.forEach((assessment) => {
      if (activeIds.includes(assessment.id) && assessment.questions) {
        const includedQuestions: TQuestionSelect[] = [];

        assessment.questions.forEach((question) => {
          if (questionIds.includes(question.id)) {
            includedQuestions.push(question);
          }
        });
        if (
          questionIds.length === 1 &&
          includedQuestions.length === 1 &&
          includedQuestions.length !== assessment.questions.length
        ) {
          assNames.push(`${assessment.name}: ${includedQuestions[0].body}`);
        } else if (
          includedQuestions.length === 0 ||
          assessment.questions.length === includedQuestions.length ||
          assessment.questions.length === 1
        ) {
          assNames.push(assessment.name);
        } else {
          const indexes = [];
          includedQuestions.forEach((question) => {
            indexes.push(question.index);
          });

          assNames.push(
            `${assessment.name}, Item${
              indexes.length > 1 ? 's' : ''
            } ${indexes.toString()}`
          );
        }
      }
    });

    return assNames;
  };

  return (
    <MainWrapperS>
      <PageTitleWrapperS>
        <h1>Collaboration</h1>
        <RateWrapperS>
          <ParticipationRate
            chartId={''}
            isCollaborationRate={true}
            title={'Participation List'}
            isBlack
          />
        </RateWrapperS>
      </PageTitleWrapperS>

      <Filters range={percentilesRange} setRange={setPercentilesRange} setFirstLoading={setFirstLoading} />

      <WrapperS>
        {renderBody}
        {loading && !firstLoading &&<Loader isGreen />}
        {currentUser && (
          <CollabEmployeeOverlay closeHandler={closeModal} user={currentUser} />
        )}
        <Search
          setIsGlobalSettings={(d) => setIsGlobalSettings(d)}
          clickItem={searchUserHandler}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
        />
        <Score isDisplay={!searchVal && !firstLoading} />
        <ControlBtns
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          hideBtnSettings={!isGlobalSettings}
        />
        <CaptureButtonS
          onClick={() => {
            setStoryLoading(true);
            const callBack = () => {
              setStoryLoading(false);
              setAddedToStory(true);
              setTimeout(() => {
                setAddedToStory(false);
              }, 15000);
            };
            html2canvas(ref.current, { scale: 2 }).then((canvas) => {
              canvas.toBlob(
                (blob) => {
                  const time = new Date();
                  dispatch(
                    CreateFileSnapshot.request({
                      workspaceId: Workspaces.current.id,
                      data: {
                        name: `Collaboration ${time.getFullYear()}-${
                          time.getMonth() + 1
                        }-${time.getDate()}`,
                        file: blob,
                        data: {
                          settings: {
                            ...Collaborations.filters,
                            groupsNames: getGroupsNames(
                              [],
                              Collaborations.filters.groups_ids
                            ),
                            assesmNames: getAssesmNames(
                              Collaborations.filters.assessments
                            ),
                            questions: {},
                            percentiles: {
                              start_range: percentilesRange[0],
                              end_range: percentilesRange[1],
                            },
                            participantName: searchVal,
                            connectedness_score:
                              !searchVal &&
                              Collaborations?.additionalInfo
                                ?.connectedness_score,
                          },
                        },
                      },
                      callBack,
                    })
                  );
                },
                'image/png',
                1
              );
            });
          }}
        >
          {!storyLoading ? (
            addedToStory ? (
              <>
                <CheckIcon />
                <span>Added as image</span>
              </>
            ) : (
              <>
                <AddDataStoryIcon />
                Add to Data Story
              </>
            )
          ) : (
            <Loader />
          )}
        </CaptureButtonS>
      </WrapperS>
    </MainWrapperS>
  );
};
const RateWrapperS = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
const WrapperS = styled.div`
  width: ${width}px;
  height: ${height}px;
  border-radius: 8px;
  border: solid 1px rgba(42, 47, 42, 0.09);
  background-color: #f4f5f9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg g {
    position: relative;

    .svg-tooltip {
      //position: absolute;
      //bottom: 100%;
      //left: 50%;
      //transform: translateY(-50%);
      width: 200px;
      height: 70px;
      fill: white;
      border-radius: 15px;
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.1));
      display: none;
    }

    .svg-tooltip-text-wrapper {
      width: 200px;
      height: 70px;
      position: relative;
      display: none;
      span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 12px;
        padding: 2px 10px;
        display: block;
      }
    }
  }

  circle {
    cursor: pointer;
    position: relative;
    fill: white;
    stroke: ${COLORS.accent};

    &:hover {
      stroke: ${COLORS.accentHover};
    }

    &:active {
      stroke: ${COLORS.accentActive};
    }
  }
`;

const CaptureButtonS = styled(ButtonDefS)`
  position: absolute;
  width: 200px;
  right: 16px;
  top: 16px;
  height: 36px;
  min-height: 36px;
  svg {
    fill: white;
    margin-right: 5px;
    min-height: 20px;
    min-width: 20px;
  }
`;
// const WrapperCurrentS = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: rgba(255, 255, 255, 0.5);
// `;
//
// const InnerWrapperS = styled.div`
//   display: flex;
//   width: 360px;
//   padding: 20px;
//   background: white;
//   border-radius: 5px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//
//   h2 {
//     margin: 0;
//   }
// `;
//
// const WrapperHeaderS = styled.div`
//   display: flex;
//   width: 100%;
//   & > *:first-child {
//     flex: 1;
//     padding-right: 10px;
//   }
// `;
