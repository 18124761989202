import axios from 'axios';
import { API_ROUTE_PATH } from './api_routes';
import { checkToken, ReturnType } from './checkToken';
import { API_URI } from './config';

export async function callApi(obj: {
  subPath?: '/api/v1' | '/api/v1/auth';
  responseType?: 'json' | 'blob' | 'arraybuffer';
  method: 'get' | 'post' | 'put' | 'delete';
  path: string;
  refreshToken?: string;
  data?: Object | null;
  isFormData?: boolean;
}): Promise<any> {
  let { method, path, data, subPath, refreshToken, isFormData, responseType } =
    obj;
  const mustCheckToken =
    path !== API_ROUTE_PATH.authentication.authUser &&
    !refreshToken &&
    !path.includes(API_ROUTE_PATH.participants.gAllByLink) &&
    !path.includes(API_ROUTE_PATH.story.gByLink) &&
    !path.includes(API_ROUTE_PATH.packages.gByLink) &&
    !path.includes(API_ROUTE_PATH.answers.path) &&
    !path.includes(API_ROUTE_PATH.participants.identify) &&
    !path.includes(API_ROUTE_PATH.participants.collaboration);

  let userToken: ReturnType = {
    usrId: '',
    success: true,
    token: '',
  };

  if (mustCheckToken) {
    userToken = await checkToken();
  }

  if (!userToken) return null;
  if (!userToken.success) {
    // eslint-disable-next-line no-throw-literal
    window.location.href = '/';
    console.log('-> userToken', userToken);
    return null;
  }

  if (!subPath) subPath = '/api/v1';
  if (!responseType) responseType = 'json';

  if (refreshToken) userToken.token = refreshToken;

  try {
    const res = await axios.request({
      method,
      headers: {
        Authorization: 'bearer ' + userToken.token,
        'Content-Type': isFormData ? 'multipart/form-data ' : '',
      },
      url: API_URI + subPath + path,
      data: data ? data : null,
      responseType: responseType,
      // data: data ? JSON.stringify() : null,
    });
    return res.data;
  } catch (e) {
    console.log(e.response, 'Error Api Call');

    if (
      e.response.status === 401 &&
      e.response.data.detail !== 'incorrect email or password'
    ) {
      localStorage.clear();
      console.log('-> e.response', e.response);
      window.location.href = '/';
      return null;
    }
    let error = e.response;
    if (e.response && typeof e.response.data === 'string')
      error =
        e.response.data +
        `${e.response.status ? `; Status: ${e.response.status}` : ''} `;
    else if (e.response.data && e.response.data instanceof Blob) {
      error = JSON.parse(await e.response.data.text()).detail;
    } else if (e.response && e.response.data)
      error = e.response.data?.detail || 'Some Error';
    throw error;
  }
}
