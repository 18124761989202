import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { getGroupsR } from '../../../store/groups/actions';
import {
  CreateParticipants,
  NewParticipant,
} from '../../../store/participants/actions';
import { TParticipantsData } from '../../../store/participants/types';
import { getFormatDate } from '../../../utils/getFormatDate';
import { validateEmail, validatePhone } from '../../../utils/helpers';
import { getUniqueId } from '../../../utils/uniqueId';
import { defFields, IParticipantsInputs } from '../types/AddParticipantsTypes';
const getId = getUniqueId();

type TP = {
  [key: string]: string;
};

const DATA: TP[] = [];
for (let i = 0; i < 4; i++) {
  DATA.push({
    ...defFields,
    id: `${getId.next().value}`,
  });
}

export const useParticipants = (payload: {
  closeModal: () => void;
  defFieldsCount?: number;
  loadItemHandler?: () => void
}) => {
  const [newParticipants, setNewParticipants] = React.useState<TP[]>([]);
  const { Workspaces } = useSelector((store: AppStore) => store);
  const [newParticipantsWithErrors, setNewParticipantsWithErrors] =
    React.useState<TP[]>([]);
  const [checkAgain, setCheckAgain] = React.useState(false);

  const [warning, setWarning] = React.useState('');
  const dispatch = useDispatch();

  const addParticipant = () => {
    setNewParticipants(() => [
      ...newParticipants,
      {
        ...defFields,
        id: `${getId.next().value}`,
        workspace_id: Workspaces.current?.id || '',
      },
    ]);
  };
  React.useEffect(() => {
    dispatch(CreateParticipants.error(''));
  }, [dispatch]);
  React.useEffect(() => {
    const newParts: TP[] = [];
    const fc = payload.defFieldsCount ? payload.defFieldsCount : 5;
    for (let i = 0; i < fc; i++) {
      newParts.push({
        ...defFields,
        id: `${getId.next().value}`,
        workspace_id: Workspaces.current?.id || '',
      });
    }
    setNewParticipants(newParts);
  }, [Workspaces, payload.defFieldsCount]);

  const onChangeHandler = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>,
    withError?: boolean,
    date?: Date,
    label?: string
  ) => {
    if (date && label) {
      const objName = label.split('-')[0];
      const position = label.split('-')[1];
      let newData = [...newParticipants];
      if (withError) {
        newData = [...newParticipantsWithErrors];
      }
      newData.forEach((itm) => (itm.workspace_id = Workspaces.current.id));
      const participant = newData[Number(position)];
      participant[objName] = getFormatDate(date)
        .split('T')[0]
        .replaceAll('-', '.');

      if (withError) {
        return setNewParticipantsWithErrors(newData);
      } else {
        return setNewParticipants(newData);
      }
    }
    event.preventDefault();
    const { name, value } = event.currentTarget;
    let newData = [...newParticipants];
    if (withError) {
      newData = [...newParticipantsWithErrors];
    }
    newData.forEach((itm) => (itm.workspace_id = Workspaces.current.id));

    if (!name) return null;

    const objName = name.split('-')[0];
    const position = name.split('-')[1];

    const participant = newData[Number(position)];

    if (!participant) return null;

    if (!(objName in participant)) return null;

    participant[objName] = value;
    if (withError) {
      return setNewParticipantsWithErrors(newData);
    } else {
      return setNewParticipants(newData);
    }
  };

  const removeParticipant = (
    position: number,
    participant?: TP,
    withErrors?: boolean
  ) => {
    let data = withErrors
      ? [...newParticipantsWithErrors]
      : [...newParticipants];
    if (withErrors) {
      if (!data[1])
        return setNewParticipantsWithErrors([{ ...defFields, id: data[0].id }]);
      data.splice(position, 1);
      return setNewParticipantsWithErrors(data);
    }
    if (!data[1]) return setNewParticipants([{ ...defFields, id: data[0].id }]);
    data.splice(position, 1);
    return setNewParticipants(data);
  };

  const checkFields = () => {
    const invalidFields: TP[] = [];
    const findFillElement = [
      ...newParticipants.filter(
        (itm) => !newParticipantsWithErrors.includes(itm)
      ),
      ...newParticipantsWithErrors,
    ].filter((participant) => {
      const firstName = participant[IParticipantsInputs.first_name];
      const lastName = participant[IParticipantsInputs.last_name];
      const email = participant[IParticipantsInputs.email];
      const phone = participant[IParticipantsInputs.phone];
      const phone_code =
        participant[IParticipantsInputs.phone_code].split(' ')[1];
      const payroll_id = participant[IParticipantsInputs.payroll_id];
      if (email && !validateEmail(email)) {
        invalidFields.push(participant);
        return false;
      }

      if (phone && !validatePhone(phone_code + phone)) {
        invalidFields.push(participant);
        return false;
      }

      if (firstName && lastName && (email || phone || payroll_id)) {
        return true;
      }

      if (!firstName && !lastName && !email && !phone && !payroll_id)
        return false;
      invalidFields.push({ ...participant });
      return false;
    });
    return {
      validFields: findFillElement,
      invalidFields,
    };
  };

  const checkHandler = () => {
    const { invalidFields, validFields } = checkFields();
    if (!invalidFields[0] && !validFields[0])
      return setWarning('At least one participant should be added');

    setNewParticipantsWithErrors(invalidFields);
    setNewParticipants(validFields);
    setCheckAgain(!!invalidFields[0]);
  };

  const cleanFail = (patData: TParticipantsData) => {
    const current = [...newParticipants];
    current.forEach((itm, i) => {
      if (
        itm.first_name === '' &&
        itm.last_name === '' &&
        itm.email === '' &&
        itm.phone === '' &&
        itm.payroll_id === ''
      ) {
        current.splice(i);
      }
    });
    if (patData.success[0]) {
      current.forEach((itm, i) => {
        const isExist = patData.success.find(
          (success) =>
            itm.first_name === success.first_name &&
            itm.last_name === success.last_name &&
            itm.email === success.email &&
            itm.phone === success.phone &&
            itm.payroll_id === success.payroll_id
        );
        isExist && current.splice(i, 1);
      });
    }
    // if (patData.fail[0]) {
    //   current.forEach((itm, i) => {
    //     const isExist = patData.fail.find(
    //       (fail) =>
    //         itm.first_name === fail.first_name &&
    //         itm.last_name === fail.last_name &&
    //         itm.email === fail.email &&
    //         itm.phone === fail.phone &&
    //         itm.payroll_id === fail.payroll_id
    //     );
    //     !isExist &&
    //       current.push({
    //         first_name: isExist.first_name,
    //         last_name: isExist.last_name,
    //         email: isExist.email,
    //         phone: isExist.phone,
    //         payroll_id: isExist.payroll_id,
    //       });
    //   });
    // }
    console.log(current);
    console.log('CLEAN FAIL');
    return setNewParticipants(current);
  };
  const callBack = (success: boolean, data: TParticipantsData) => {
    if (success) {
      payload.loadItemHandler()
      dispatch(getGroupsR(Workspaces.current.id));
      return payload.closeModal();
    }
    // return checkAgain ? null : cleanFail(data);
    return cleanFail(data);
  };
  const saveHandler = (onlySend?: boolean) => {
    setWarning('');
    //dispatch(cleanFailSuccess());
    if (onlySend) {
      const { invalidFields, validFields } = checkFields();
      if (!invalidFields[0] && !validFields[0])
        return setWarning('At least one participant should be added');
      if (invalidFields[0] && !validFields[0]) {
        setNewParticipantsWithErrors(invalidFields);
        setNewParticipants(validFields);
        return setCheckAgain(!!invalidFields[0]);
      }
      const sendCheck = newParticipants;
      sendCheck.forEach((itm) => {
        if (
          itm[IParticipantsInputs.phone_code] &&
          !itm[IParticipantsInputs.phone]
        ) {
          itm[IParticipantsInputs.phone_code] = '';
        }
      });
      const sendData = sendCheck.filter(
        (itm) =>
          itm[IParticipantsInputs.first_name] &&
          itm[IParticipantsInputs.last_name] &&
          (itm[IParticipantsInputs.phone] ||
            itm[IParticipantsInputs.email] ||
            itm[IParticipantsInputs.payroll_id])
      ) as unknown;
      // ? (newParticipants.filter(
      //     (itm) =>
      //       itm[IParticipantsInputs.first_name] &&
      //       itm[IParticipantsInputs.last_name] &&
      //       (itm[IParticipantsInputs.phone] ||
      //         itm[IParticipantsInputs.email] ||
      //         itm[IParticipantsInputs.payroll_id])
      //   ) as unknown)
      // : (newParticipantsWithErrors.filter(
      //     (itm) =>
      //       itm[IParticipantsInputs.first_name] &&
      //       itm[IParticipantsInputs.last_name] &&
      //       (itm[IParticipantsInputs.phone] ||
      //         itm[IParticipantsInputs.email] ||
      //         itm[IParticipantsInputs.payroll_id])
      //   ) as unknown);
      return dispatch(
        CreateParticipants.request({
          newParticipants: sendData as NewParticipant[],
          callBack,
        })
      );
    }

    const { invalidFields, validFields } = checkFields();

    if (!invalidFields[0] && !validFields[0])
      return setWarning('At least one participant should be added');

    setNewParticipantsWithErrors(invalidFields);
    setNewParticipants(validFields);
    if (invalidFields[0]) {
      console.log(!!invalidFields[0]);
      return setCheckAgain(!!invalidFields[0]);
    }
    // checkHandler();

    const sendCheck = validFields;
    sendCheck.forEach((itm) => {
      if (
        itm[IParticipantsInputs.phone_code] &&
        !itm[IParticipantsInputs.phone]
      ) {
        console.log('detected phone code field');
        itm[IParticipantsInputs.phone_code] = '';
      }
    });
    const sendData = sendCheck as unknown;
    return dispatch(
      CreateParticipants.request({
        newParticipants: sendData as NewParticipant[],
        callBack,
      })
    );
  };

  return {
    newParticipants,
    addParticipant,
    removeParticipant,
    onChangeHandler,
    saveHandler,
    newParticipantsWithErrors,
    checkHandler,
    checkAgain,
    warning,
  };
};
