import React from 'react';
import styled from 'styled-components';
import * as Styles from './modalStyles';
import {ButtonDefS} from "../ui/buttons/styles";
import {TLang} from "../../types/lang";
import {checkLang} from "../Responding/assLang";
import {langIsAllingRight} from "../../utils/langIsEng";

interface PrivilegedAndConfidentialProps {
    lang: TLang;
    understand: () => void;
}

export const PrivilegedAndConfidential: React.FC<PrivilegedAndConfidentialProps> = ({
    lang,
    understand
}) => {
    const renderBody = () => {
            return (
                <>
                   <ContainerS alignRight={langIsAllingRight(lang)} style={{display: 'inline-block'}}>
                       {checkLang(lang).assessments.privilegedAndConfidential}
                   </ContainerS>
                </>
            );
        };

    return (
        <Styles.ModalOuterWrapperS style={{zIndex: '999999'}}>
            <Styles.ModalInnerWrapperS
                style={{ maxWidth: '616px', minHeight: '289px' }}
            >
                <Styles.ModalBodyS style={{ paddingTop: '24px' }}>
                    {langIsAllingRight(lang) ?
                        <Styles.ModalTileS style={{marginBottom: '20px', direction: 'rtl'}}>{checkLang(lang).assessments.privilegedAndConfidentialTitle}</Styles.ModalTileS>
                        :
                        <Styles.ModalTileS style={{marginBottom: '20px', direction: 'ltr'}}>{checkLang(lang).assessments.privilegedAndConfidentialTitle}</Styles.ModalTileS>
                    }

                    <WrapperS>{renderBody()}</WrapperS>
                </Styles.ModalBodyS>
                <ButtonDefS
                    className="btnSave"
                    onClick={understand}
                    style={{ height: '50px' }}
                >
                    {checkLang(lang).assessments.understandBtnText}
                </ButtonDefS>
            </Styles.ModalInnerWrapperS>
        </Styles.ModalOuterWrapperS>
    );
};

const WrapperS = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const ContainerS = styled.div<{ alignRight: boolean }>`
    display: inline-block;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    direction: ${({ alignRight }) => (alignRight ? 'rtl' : 'ltr')};
`;

