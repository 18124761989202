import { useDispatch } from 'react-redux';
import { setWorkspace } from '../store/workspaces/actions';
import { TWorkspace } from '../store/workspaces/types';
import { CurrentWorkspaceId } from '../utils/config';

export const useChangeWorkspace = () => {
  const dispatch = useDispatch();

  const changeWorkspace = (workspace: TWorkspace) => {
    localStorage.setItem(CurrentWorkspaceId, workspace.id);
    dispatch(setWorkspace(workspace));
  };

  return { changeWorkspace };
};
